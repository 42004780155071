import {
    DigitalBusinessCard,
    DigitalBusinessCardBulkModel,
    DigitalBusinessCardTemplateModel
} from './digital-business-card.model';

export enum TYPOGRAPHY_CUSTOMIZATIONS {
    PERSONAL_INFO = 'personal_info',
    COMPANY_DETAILS = 'company_details',
    BIO = 'bio',
    CONTACT_DETAILS = 'contact_details',
    BUTTON = 'button',
}

export enum CONTACT_DETAIL {
    PHONE_V2 = 'phone_v2',
    EMAIL_V2 = 'email_v2',
    WEBSITE_V2 = 'website_v2',
    CUSTOM_FIELDS = 'custom_fields',
}

export function getUserConsentAmplitudeValue(product: DigitalBusinessCard | DigitalBusinessCardBulkModel | DigitalBusinessCardTemplateModel) {
    if (product.lead_user_agreement) {
        product.lead_user_agreement_attribute.user_agreement_urls = product.lead_user_agreement_attribute.user_agreement_urls.filter(res => {
            return (res.url && res.label) ? res : null;
        });
        return product.lead_user_agreement_attribute.user_agreement_urls.length ? 'added' : 'not added';
    } else {
        return 'no';
    }
}


