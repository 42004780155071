import { NgModule } from '@angular/core';
import { BeaconstacModule } from 'kaizen-design-system';
import { CommonModule } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { IsOnHigherPlan, QrPricingComponent } from './qr-pricing.component';
import {FormsModule} from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { StrokeRadioButtonModule } from '../stroke-radio-button/stroke-radio-button.module';

@NgModule({
    declarations: [
        QrPricingComponent,
        IsOnHigherPlan
    ],
    exports: [
        QrPricingComponent
    ],
    imports: [
        CommonModule,
        BeaconstacModule,
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        StrokeRadioButtonModule,
        FormsModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot()
    ]
})
export class QRPricingModule { }
