import { Component, Input, OnInit, Output, EventEmitter, Pipe, PipeTransform } from '@angular/core';
import { PLAN_TYPES, User } from '../../user-account/user.model';
import { featureDetailsData } from './feature-details.data';
import { AuthService } from '../../global-services/auth.service';
import {Animations} from '../../shared/beaconstac-animations';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';
import {OrganizationCountModel, OrganizationCountService} from '../../global-services/organization-count.service';
import {PRICING_PAGE_TYPE} from '../../shared/utils';

enum FEATURE_TYPES {
    QR_CODE_ESSENTIALS = 'isQRCodeEssentialCollapsed',
    LINKPAGES = 'isLinkpageCollapsed',
    CAMPAIGN_ANALYTICS_AND_MEASUREMENT = 'isCompaignAnalyticsCollapsed',
    FIRST_PARTY_DATA = 'isFirstPartyDataCollapsed',
    DATA_SECURITY = 'isDataSecurityCollapsed',
    BRANDING = 'isBrandingCollapsed',
    CAMPAIGN_PERSONALIZATION = 'isCampaignPersonalizationCollapsed',
    COLLABORATION = 'isCollaborationCollapsed',
    SCALABILITY = 'isScalabilityCollapsed',
    INTEGRATIONS = 'isIntegrationsCollapsed',
    CUSTOMER_SUPPORT = 'isCustomerSupportCollapsed'
}
enum PLAN_TYPES_MOBILE {
    STARTER = 'starter',
    LITE = 'lite',
    PRO = 'pro',
    PLUS = 'plus',
    ENTERPRISE = 'enterprise'
}

enum PLAN_TYPES_INDEX {
    STARTER = 0,
    LITE = 1,
    PRO = 2,
    PLUS = 3,
    ENTERPRISE = 4
}

enum BEACONSTAC_PRICING_PAGE {
    RENEW_PLAN = 'renew-plan',
    USER_UPGRADE = 'upgrade-now'
}

@Pipe({name: 'isOnHigherPlan'})
export class IsOnHigherPlan implements PipeTransform {
    transform(user: User, plan: PLAN_TYPES): any {
        const isOnHigherPlan = user.isOnHigherPlan(plan) || !user.isOnQRPlan();
        return isOnHigherPlan && user.customer_plan !== plan;
    }
}

@Component({
    selector: 'beaconstac-feature-details',
    templateUrl: './feature-details.component.html',
    animations: [Animations.collapse, Animations.rotate],
    styleUrls: ['./feature-details.component.scss'],
})

export class FeatureDetailsComponent implements OnInit {
    @Input() pageType: string = ''
    @Input() showBusinessPlans: boolean;
    @Input() qrPlansData;
    @Output() onPlanTypeClicked ? = new EventEmitter();
    @Output() subscribeWithCheckout ? = new EventEmitter();
    @Output() onEnterpriseSelected = new EventEmitter();
    @Input() isUserSPUImpacted: boolean = false;

    constructor(private authService: AuthService, private organizationCountService: OrganizationCountService) {
        this.user = this.authService.getUser();
    }

    ngOnInit(): void {
        this.getResponseCount();
        this.organizationCountService.getCounts()
            .pipe().subscribe((organizationCount: OrganizationCountModel) => {
                this.qrUserCount = organizationCount.qr_user_count;
            });
    }

    // for collapsing details
    isFullCollapsed: boolean = true;
    isQRCodeEssentialCollapsed: boolean = false;
    isLinkpageCollapsed: boolean = true;
    isCompaignAnalyticsCollapsed: boolean = true;
    isFirstPartyDataCollapsed: boolean = true;
    isDataSecurityCollapsed: boolean = true;
    isBrandingCollapsed: boolean = true;
    isCampaignPersonalizationCollapsed: boolean = true;
    isCollaborationCollapsed: boolean = true;
    isScalabilityCollapsed: boolean = true;
    isIntegrationsCollapsed: boolean = true;
    isCustomerSupportCollapsed: boolean = true;

    user: User;
    PLAN_TYPES = PLAN_TYPES;
    planYearly: boolean = true;
    planTypeIndex: number = 0;
    responseCount: number = 0;
    featureDetailsData = featureDetailsData;
    BUTTON_TYPES = BUTTON_TYPES
    BUTTON_STYLES = BUTTON_STYLES;
    openDropdowns: Set<any> = new Set();
    iconClasses = ['fas fa-qrcode', 'fas fa-bars', 'fas fa-file-medical-alt', 'fas fa-shield-alt', 'far fa-copyright',
        'fas fa-users', 'fas fa-expand', 'fas fa-object-group', 'fas fa-headset'];
    qrUserCount: number = 0;

    onClickFeatureSubType(show: String) {
        switch (show) {
            case FEATURE_TYPES.QR_CODE_ESSENTIALS:
                this.isQRCodeEssentialCollapsed = !this.isQRCodeEssentialCollapsed;
                break;
            case FEATURE_TYPES.LINKPAGES:
                this.isLinkpageCollapsed = !this.isLinkpageCollapsed;
                break;
            case FEATURE_TYPES.CAMPAIGN_ANALYTICS_AND_MEASUREMENT:
                this.isCompaignAnalyticsCollapsed = !this.isCompaignAnalyticsCollapsed;
                break;
            case FEATURE_TYPES.FIRST_PARTY_DATA:
                this.isFirstPartyDataCollapsed = !this.isFirstPartyDataCollapsed;
                break;
            case FEATURE_TYPES.DATA_SECURITY:
                this.isDataSecurityCollapsed = !this.isDataSecurityCollapsed;
                break;
            case FEATURE_TYPES.BRANDING:
                this.isBrandingCollapsed = !this.isBrandingCollapsed;
                break;
            case FEATURE_TYPES.CAMPAIGN_PERSONALIZATION:
                this.isCampaignPersonalizationCollapsed = !this.isCampaignPersonalizationCollapsed;
                break;
            case FEATURE_TYPES.COLLABORATION:
                this.isCollaborationCollapsed = !this.isCollaborationCollapsed;
                break;
            case FEATURE_TYPES.SCALABILITY:
                this.isScalabilityCollapsed = !this.isScalabilityCollapsed;
                break;
            case FEATURE_TYPES.INTEGRATIONS:
                this.isIntegrationsCollapsed = !this.isIntegrationsCollapsed;
                break;
            case FEATURE_TYPES.CUSTOMER_SUPPORT:
                this.isCustomerSupportCollapsed = !this.isCustomerSupportCollapsed;
                break;
        }
    }

    handleSelectPlanButton(id: number) {
        if (this.pageType === BEACONSTAC_PRICING_PAGE.RENEW_PLAN) {
            switch (id) {
                case PLAN_TYPES_INDEX.STARTER:
                    this.subscribeWithCheckout.emit(PLAN_TYPES.Starter);
                    break;

                case PLAN_TYPES_INDEX.LITE:
                    this.subscribeWithCheckout.emit(PLAN_TYPES.Lite)
                    break;

                case PLAN_TYPES_INDEX.PRO:
                    this.subscribeWithCheckout.emit(PLAN_TYPES.Pro)
                    break;

                case PLAN_TYPES_INDEX.PLUS:
                    this.subscribeWithCheckout.emit(PLAN_TYPES.Plus)
                    break;

                case PLAN_TYPES_INDEX.ENTERPRISE:
                    this.onEnterpriseSelected.emit();
                    break;

            }
        } else if (this.pageType === BEACONSTAC_PRICING_PAGE.USER_UPGRADE) {
            switch (id) {
                case PLAN_TYPES_INDEX.STARTER:
                    this.onPlanTypeClicked.emit(PLAN_TYPES.Starter);
                    break;

                case PLAN_TYPES_INDEX.LITE:
                    this.onPlanTypeClicked.emit(PLAN_TYPES.Lite);
                    break;

                case PLAN_TYPES_INDEX.PRO:
                    this.onPlanTypeClicked.emit(PLAN_TYPES.Pro);
                    break;

                case PLAN_TYPES_INDEX.PLUS:
                    this.onPlanTypeClicked.emit(PLAN_TYPES.Plus);
                    break;

                case PLAN_TYPES_INDEX.ENTERPRISE:
                    this.onEnterpriseSelected.emit();
                    break;
            }
        }
    }

    switchMobilePlans(planType: string) {
        switch (planType) {
            case PLAN_TYPES_MOBILE.STARTER:
                this.planTypeIndex = 0;
                break;

            case PLAN_TYPES_MOBILE.LITE:
                this.planTypeIndex = 1;
                break;

            case PLAN_TYPES_MOBILE.PRO:
                this.planTypeIndex = 2;
                break;

            case PLAN_TYPES_MOBILE.PLUS:
                this.planTypeIndex = 3;
                break;

            case PLAN_TYPES_MOBILE.ENTERPRISE:
                this.planTypeIndex = 4;
                break;
        }
    }

    onDropdownToggle(dropdown: any) {
        if (this.openDropdowns.has(dropdown)) {
            this.openDropdowns.delete(dropdown); // Close the dropdown if it's already open
        } else {
            this.openDropdowns.add(dropdown); // Open the clicked dropdown
        }
    }

    isDropdownOpen(dropdown: any): boolean {
        return this.openDropdowns.has(dropdown);
    }

    isFeatureValueAnObject(value: any) {
        if (typeof value === 'object' && 'value' in value) {
            return true;
        } else {
            return false;
        }
    }

    getResponseCount() {
        this.organizationCountService.getResponseCount().pipe().subscribe((organizationCount: OrganizationCountModel) => {
            this.responseCount = organizationCount.response_count_in_cycle || 0;
        }, error => {
            this.responseCount = 0;
        });
    }

    showBuyOption(plan: PLAN_TYPES) {
        let isOnHigherPlan;
        const isOnTrialPlan = (this.user.customer_plan === PLAN_TYPES.Trial);
        const userPlan = this.user.subscription.qr.plan
        switch (plan) {
            case PLAN_TYPES.Starter:
                if ((isOnTrialPlan && (this.qrUserCount > 1 || this.responseCount)) || (userPlan !== PLAN_TYPES.Starter && this.pageType === PRICING_PAGE_TYPE.UPGRADE_NOW && this.isUserSPUImpacted)) {
                    return false;
                }
                isOnHigherPlan = this.user.isOnHigherPlan(PLAN_TYPES.Starter);
                return !(isOnHigherPlan && this.user.customer_plan !== PLAN_TYPES.Starter);
            case PLAN_TYPES.Lite:
                if ((isOnTrialPlan && this.qrUserCount > 1) || (userPlan !== PLAN_TYPES.Lite && this.pageType === PRICING_PAGE_TYPE.UPGRADE_NOW && this.isUserSPUImpacted)) {
                    return false;
                }
                isOnHigherPlan = this.user.isOnHigherPlan(PLAN_TYPES.Lite);
                return !(isOnHigherPlan && this.user.customer_plan !== PLAN_TYPES.Lite);
            case PLAN_TYPES.Pro:
                if (isOnTrialPlan && this.qrUserCount > 1) {
                    return false;
                }
                isOnHigherPlan = this.user.isOnHigherPlan(PLAN_TYPES.Pro);
                return !(isOnHigherPlan &&  this.user.customer_plan !== PLAN_TYPES.Pro);
            case PLAN_TYPES.Plus:
                isOnHigherPlan = this.user.isOnHigherPlan(PLAN_TYPES.Plus);
                return !(isOnHigherPlan && this.user.customer_plan !== PLAN_TYPES.Plus);
            case PLAN_TYPES.Enterprise:
                return true;
            default:
                return true;
        }
    }

    showBuyOptionOnRenewPage(plan: PLAN_TYPES) {
        const isOnTrialPlan = (this.user.customer_plan === PLAN_TYPES.Trial);
        switch (plan) {
            case PLAN_TYPES.Starter:
                return !(isOnTrialPlan && (this.responseCount || this.qrUserCount > 1));
            case PLAN_TYPES.Lite:
            case PLAN_TYPES.Pro:
                return !(isOnTrialPlan && this.qrUserCount > 1);
            default:
                return true;
        }
    }

    isMediaEmpty(mediaObject: any): boolean {
        return !mediaObject || Object.keys(mediaObject).length === 0;
    }

    toolTipHasPrices(tooltip: any): boolean {
        return tooltip && tooltip.prices && tooltip.prices.length > 0;
    }

    setDividerTopMargin(): string {
        const { plan } = this.user.subscription.qr;

        switch (plan) {
            case PLAN_TYPES.Trial:
            case PLAN_TYPES.Starter:
            case PLAN_TYPES.Plus:
                return '16px';
            case PLAN_TYPES.Lite:
                return this.showBusinessPlans ? '16px' : '56px';
            case PLAN_TYPES.Pro:
                return this.showBusinessPlans ? '56px' : '16px';
            default:
                // if user on enterprise or higher plan
                return '64px';
        }
    }
}
