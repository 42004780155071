export class CurrentPageStat {
    pageNumber: number;
    ascending: boolean;
    sortBy: number;
    ordering: string;
    pageSize: number;
    type: string;

    constructor() {
    }
}
