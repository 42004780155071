import {AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OverlayService} from '../../global-services/overlay.service';
import {AuthService} from '../../global-services/auth.service';
import {DBC_MAX_USER_SEATS, DBC_PRICE_RATES, PLAN_TYPES, User} from '../../user-account/user.model';
import {Animations} from '../../shared/beaconstac-animations';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {debounceTime, distinctUntilChanged, finalize, switchMap, takeUntil, tap} from 'rxjs/operators';
import {of, Subject, } from 'rxjs';
import {
    BULK_TEMPLATE_TYPE,
    DigitalBusinessCardBulkModel,
    DigitalBusinessCardTemplateModel
} from '../digital-business-card.model';
import {read, utils, WorkBook, WorkSheet} from 'xlsx';
import {DigitalBusinessCardBulkService} from './digital-business-card-bulk-service';
import {BUTTON_STYLES, BUTTON_TYPES, CARET_POSITION, TEXT_FIELD_TYPES, TOOLTIP_POSITION} from 'kaizen-design-system';
import {Papa} from 'ngx-papaparse';
import {BeaconstacFileDropComponent} from '../../global-components/beaconstac-file-drop/beaconstac-file-drop.component';
import {RouterReloadService} from '../../global-services/router-reload.service';
import {
    ADD_ON_PRODUCT_TYPE,
    ADDON_SOURCE,
    BeaconstacAddOnComponent
} from '../../global-components/beaconstac-add-on/beaconstac-add-on.component';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {
    CardsOrgSettingService,
    DigitalBusinessCardTemplateService,
    LEAD_COLLECT_TYPE,
} from '../digital-business-cards.service';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../digital-business-card-setting/digital-business-card-setting.model';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService, } from '../../global-services/amplitude.service';
import {OrganizationCountModel, OrganizationCountService} from '../../global-services/organization-count.service';
import {PLAN_TYPE, SubscriptionDetail, UserService} from 'app/user-account/user.service';
import {CONFIG_VALUES, PRODUCT_TYPES} from 'app/shared/utils';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../user-account/user-organization.model';
import {TEMPLATE_NAMES_MAP} from '../templates-utils';
import {FEATURE_FLAGS} from '../../shared/feature-flags';
import {SalesforceService} from '../../integrations/salesforce/salesforce.service';
import {getUserConsentAmplitudeValue} from '../digital-business-card-utils';

enum BULK_DBC_STEPPER {
    UPLOAD = 1,
    DESIGN = 2,
    LAUNCH = 3,
    FINISH = 4,
}

interface PLAN {
    planType: string;
    value: string;
    price: string;
}

@Component({
    selector: 'app-digital-business-card-bulk-upload',
    templateUrl: './digital-business-card-bulk-upload.component.html',
    styleUrls: [
        './digital-business-card-bulk-upload.component.scss',
        '../digital-business-card-detail/digital-business-card-detail.component.scss',
        '../../../scss/product.scss',
        '../../markdown-cards/markdown-card-detail.scss',
        '../../link-page/link-page-detail/link-page-detail.component.scss',
    ],
    animations: [Animations.collapse],
})
export class DigitalBusinessCardBulkUploadComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User;
    selectedStep: number = 1;
    isFrameLoading: boolean = false;
    hasWriteAccess: boolean = true;
    exampleCsvFileLink: string = '';
    exampleXLSXFileLink: string = '';
    skipErrorRows: boolean = false;
    dbcBulk: DigitalBusinessCardBulkModel = new DigitalBusinessCardBulkModel();
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    PLAN_TYPES = PLAN_TYPES;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;
    selectedDBCPlan: PLAN_TYPES;
    currentDBCPlan: string = '';

    dbcUserSeatsPrice: number;
    readablePlanName: string = '';
    planClassName: string = 'business-plan';
    isBillingUpgradeRequested: boolean = false;
    totalDbcSeats: number | string = 0;
    requiredUserSeats: number = 0;
    usersTooltipMessage: string = '';
    modalSubText: string = '';

    dbcUserSeats: number | string = 0;
    dbcSeatsPrice: number = 0;

    rangeInputBackground = '0%';

    isFrameLoadingSubject: Subject<boolean> = new Subject<boolean>();
    upcomingPriceSubject = new Subject();
    // CSV File
    csvFile: File | Blob;
    csvData: Array<object>;
    isCSVAdded: boolean = false;
    fileType: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'text/csv';
    errorRows = [];
    invalidFileError: string = '';
    csvValidationResponse = {};
    fileEdited = false;
    disableNextButton: boolean = true;

    newUserCount: number = 0;
    minUsersToBuy: number = 0;
    dbcUserSeatsLimit: number = 0;
    upcomingPrice: number = 0;
    inputBoxString: string = '';

    isTrialUser: boolean = false;
    planLimit: number | string = 0;
    planName: string = '';

    // User seats
    currentActiveUsers: number;
    currentTotalUsers: number;
    errorDetail: string = 'Your bulk operation exceeds your limit of users. You can add on more users to your plan.';

    lastBillingDate: any;
    nextBillingDate: any;

    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;
    PRODUCT_TYPES = PRODUCT_TYPES;
    DBC_MAX_USER_SEATS = DBC_MAX_USER_SEATS;

    cardSettings: DigitalBusinessCardSetting = new DigitalBusinessCardSetting();
    cardOrgSettings: CardsOrganizationRestrictedFields = new CardsOrganizationRestrictedFields();
    selectedCardTemplate: DigitalBusinessCardTemplateModel = null;
    checkOptions: string[] = ['Custom domain', 'White label', 'Entra ID & Salesforce integrations' , 'Multiple organizations']
    dbcUserSeatsChecked: boolean = false;
    buyUserSeats: number = 0;
    count: number = 0;
    fetchingUpcomingPrice: boolean = false;
    $resize: Subject<any> = new Subject<boolean>();
    isMobileScreen = false;
    plans: PLAN[] = [
        {
            planType: PLAN_TYPES.Solo,
            value: '1-9',
            price: '$6',
        },
        {
            planType: PLAN_TYPES.Team,
            value: '10-49',
            price: '$5',
        },
        {
            planType: PLAN_TYPES.Business,
            value: '50+',
            price: '$4',
        },
        {
            planType: PLAN_TYPES.BusinessPlus,
            value: '50+',
            price: 'Custom pricing',
        }
    ]
    incrementPressTimer: any;
    decrementPressTimer: any;
    longPressInterval: number = 100;
    private longPressThreshold: number = 500;
    pressStartTime: number;
    uploadedFileName: string = '';
    isGeneratingBatchDrawerOpen: boolean = false;
    launchDarkleyFlags: any = {
        [FEATURE_FLAGS.DBC_MOBILE_STEPPER]: true,
        [FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM]: true
    }
    showStickyButtons: boolean;
    isSalesforceConnected: boolean = false;

    ngUnsubscribe: Subject<any> = new Subject();
    private userSeatChangeSubject = new Subject<number>();
    private subscriptionDetail: SubscriptionDetail;

    @ViewChild('mobilePreviewModal', { static: false }) mobilePreviewModal: ModalDirective;
    @ViewChild('csvDrop', { static: false }) csvDrop: BeaconstacFileDropComponent;
    @ViewChild('addOns', { static: false }) addOns: BeaconstacAddOnComponent;
    @ViewChild('generatingBatchModal', { static: false }) generatingBatchModal: ModalDirective;
    @ViewChild('usersLimitReachedModal', { static: false }) usersLimitReachedModal: ModalDirective;

    constructor(
        private authService: AuthService,
        private overlay: OverlayService,
        private router: Router,
        private route: ActivatedRoute,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private routerReloadService: RouterReloadService,
        private messageModal: MessageModalService,
        private amplitude: AmplitudeService,
        private dbcBulkService: DigitalBusinessCardBulkService,
        private papa: Papa,
        private userService: UserService,
        private organizationCountService: OrganizationCountService,
        private cardsOrgSettingService: CardsOrgSettingService,
        private salesforceService: SalesforceService
    ) {
        this.user = this.authService.getUser();
        this.isTrialUser = this.user.isOnTrialPlan(PRODUCT_TYPES.DBC);
        this.currentDBCPlan = this.user.subscription.dbc.plan;
        this.goToNext = this.goToNext.bind(this);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.$resize.next(true);
        this.setDeviceType();
    }

    ngOnInit(): void {
        this.overlay.isLoading(false);
        const template = this.digitalBusinessCardTemplateService.getDBCTemplateAsBulk();
        this.selectedCardTemplate = this.digitalBusinessCardTemplateService.getDBCTemplate();
        if (!template) {
            this.router.navigate(['/digital-business-cards/my-cards/design-library'], {
                relativeTo: this.route,
                queryParams: { destination: 'bulk-upload' },
            });
            return;
        }
        this.dbcBulk = new DigitalBusinessCardBulkModel(template);
        this.dbcBulk.organization = this.authService.getCurrentOrgId();

        this.dbcBulk.card_template = this.selectedCardTemplate.id;
        this.fetchingUpcomingPrice = true;
        this.isFrameLoadingSubject.pipe(debounceTime(100)).subscribe((e) => (this.isFrameLoading = e));
        this.upcomingPriceSubject.pipe(debounceTime(300)).subscribe((e) => this.fetchUpcomingPrice());

        if (!this.isTrialUser && this.authService.getUser().isOwner()) {
            this.userService
                .getSubscription()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (subscriptionResult) => {
                        if (subscriptionResult !== null) {
                            for (const subscription of subscriptionResult) {
                                if (subscription.type === PLAN_TYPE.SUBSCRIPTION) {
                                    this.subscriptionDetail = subscription;
                                    const options = {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    } as Intl.DateTimeFormatOptions;
                                    this.nextBillingDate = this.subscriptionDetail.expires.toLocaleDateString(
                                        'en-US',
                                        options,
                                    );
                                    if (this.subscriptionDetail.interval === 'year') {
                                        this.lastBillingDate = moment(this.subscriptionDetail.expires)
                                            .subtract(1, 'years')
                                            .toDate()
                                            .toLocaleDateString('en-US', options);
                                    } else {
                                        this.lastBillingDate = moment(this.subscriptionDetail.expires)
                                            .subtract(1, 'months')
                                            .toDate()
                                            .toLocaleDateString('en-US', options);
                                    }
                                }
                            }
                        }
                    },
                    (error) => {
                        console.error(error);
                    },
                );
        }

        this.cardSettings = new DigitalBusinessCardSetting(this.selectedCardTemplate.restrictedfields);

        this.cardsOrgSettingService.getDetail(this.authService.getCurrentOrgId(), '?organization=' + this.authService.getCurrentOrgId()).subscribe(settings =>  {
            this.overlay.isLoading(false);
            this.cardOrgSettings = new CardsOrganizationRestrictedFields(settings);
        }, error => {
            this.cardOrgSettings = new CardsOrganizationRestrictedFields();
            this.overlay.isLoading(false);
            this.messageModal.show('Error in fetching organization settings', 'danger');
        })

        this.exampleCsvFileLink = '../../assets/files/template-digital-business-cards.csv';
        this.exampleXLSXFileLink = '../../assets/files/template-digital-business-cards.xlsx';

        // Mocking data
        this.csvValidationResponse = {};
        this.checkForDbcUserSeats();

        this.userSeatChangeSubject
            .pipe(debounceTime(3000))
            .subscribe(value => {
                this.handleMinValueOfUsers(this.dbcUserSeats);
            });

        this.setDeviceType();
        this.$resize.pipe(debounceTime(100), takeUntil(this.ngUnsubscribe)).subscribe((res) => {
            if (res) {
                this.setDeviceType();
            }
        })
        this.getLaunchDarkleyFlags();
        if (this.authService.getUser().isOnDBCEnterprisePlan()) {
            this.salesforceService.getAuthenticatedStatus().pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
                this.isSalesforceConnected = !!response.connected;
            })
        }
    }

    getLaunchDarkleyFlags() {
        Object.keys(this.launchDarkleyFlags).forEach(async key => {
            this.launchDarkleyFlags[key] = await this.authService.getFeatureFlag(key, this.launchDarkleyFlags[key])
        });
    }

    ngAfterViewInit() {
        document.querySelectorAll('.container-fluid')[0].classList.remove('hide-breadcrumb-container');
        document.querySelectorAll('.container-fluid')[0].classList.add('hide-breadcrumb-container-linkpage');
        const inputElement = document.getElementById('user-seats-counter') as HTMLInputElement;
        if (inputElement) {
            inputElement.oninput = () => {
                this.updateInputValue(inputElement);
            };
        }

        this.setDeviceType()
    }

    setDeviceType() {
        this.isMobileScreen = (window.innerWidth <= 672);
    }

    setPlanLimit() {
        if (this.currentDBCPlan === 'TM') {
            this.planLimit = DBC_MAX_USER_SEATS.Team;
            this.planName = 'team';
        } else if (this.currentDBCPlan === 'BN') {
            this.planLimit = DBC_MAX_USER_SEATS.Business;
            this.planName = 'business';
        } else {
            this.planLimit = '2500+';
            this.planName = 'business plus';
        }
    }

    onRangeChange(value, rangeInput) {
        const newValue = Number(value);
        if (this.isTrialUser) {
            this.requiredUserSeats > newValue
                ? (this.dbcUserSeats = this.minUsersToBuy)
                : (this.dbcUserSeats = newValue);
            rangeInput.value = this.dbcUserSeats;
        } else {
            this.dbcUserSeatsLimit + newValue < this.minUsersToBuy
                ? (this.dbcUserSeats = this.minUsersToBuy - this.dbcUserSeatsLimit)
                : (this.dbcUserSeats = newValue);
            rangeInput.value = this.dbcUserSeats + this.dbcUserSeatsLimit;
        }
        this.handleUserSeatChange(this.dbcUserSeats);
        if (!this.isTrialUser && this.selectedDBCPlan !== PLAN_TYPES.BusinessPlus) {
            this.upcomingPriceSubject.next();
        }
    }

    fetchUpcomingPrice() {
        let userSeatsToBeFetched;
        if (this.selectedDBCPlan === this.currentDBCPlan){
            userSeatsToBeFetched = ((this.dbcUserSeatsLimit + Number(this.dbcUserSeats)) - 10)
        } else if (this.selectedDBCPlan !== this.currentDBCPlan){
            userSeatsToBeFetched = ((this.dbcUserSeatsLimit + Number(this.dbcUserSeats)) - 50)
        } else{
            userSeatsToBeFetched = Number(this.dbcUserSeats);
        }
        const data = {
            plan: this.selectedDBCPlan,
            yearly: true,
            additionalUserSeats: userSeatsToBeFetched,
        };
        this.userService
            .getUpcomingInvoicePrice(data)
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(({ upcomingPrice }) => {
                    return of(upcomingPrice / 100);
                }),
            )
            .subscribe((res) => {
                this.upcomingPrice = res
                this.fetchingUpcomingPrice = false;
            },
            (error) => {
                console.error(error);
                this.fetchingUpcomingPrice = false;
            });
    }

    handleUserSeatChange(value) {

        this.dbcUserSeats = value;
        if (value >= CONFIG_VALUES.maxLimitCheck) {
            this.dbcUserSeatsChecked = true;
        }
        const userSeatsCount = this.isTrialUser
            ? Number(this.dbcUserSeats)
            : Number(this.dbcUserSeats) + this.dbcUserSeatsLimit;
        if (Number(userSeatsCount) < CONFIG_VALUES.minLimitCheck) {
            this.selectedDBCPlan = PLAN_TYPES.Team;
            this.readablePlanName = 'Team';
            this.dbcUserSeatsPrice = Number(this.dbcUserSeats) * DBC_PRICE_RATES.Team;
            this.planClassName = 'team-plan';
            this.dbcSeatsPrice = DBC_PRICE_RATES.Team;
        } else if (Number(userSeatsCount) >= CONFIG_VALUES.minLimitCheck && !this.dbcUserSeatsChecked) {
            this.selectedDBCPlan = PLAN_TYPES.Business;
            this.readablePlanName = 'Business';
            this.dbcUserSeatsPrice = Number(this.dbcUserSeats) * DBC_PRICE_RATES.Business;
            this.planClassName = 'business-plan';
            this.dbcSeatsPrice = DBC_PRICE_RATES.Business;
        } else if (Number(userSeatsCount) >= CONFIG_VALUES.maxLimitCheck || this.dbcUserSeatsChecked) {
            this.selectedDBCPlan = PLAN_TYPES.BusinessPlus;
            this.readablePlanName = 'Business+';
            this.planClassName = 'enterprise-plan';
            this.dbcSeatsPrice = DBC_PRICE_RATES.Business;
        }

        const userSeats = this.isTrialUser
            ? Math.abs(this.dbcUserSeatsLimit - Number(this.dbcUserSeats))
            : Number(this.dbcUserSeats);
        if (!this.isTrialUser && this.count < 1){
            this.count++;
            this.buyUserSeats =  userSeats;
            this.inputBoxString = `${this.dbcUserSeatsLimit} '+' ${this.dbcUserSeats}`;
        }

        if (!this.isTrialUser && this.selectedDBCPlan !== PLAN_TYPES.BusinessPlus){
            if (this.selectedDBCPlan === PLAN_TYPES.Team){
                this.usersTooltipMessage = `When your subscription renews, you'll pay $${(Number(this.dbcUserSeats) + this.dbcUserSeatsLimit) * 5 * 12} for the ${Number(this.dbcUserSeats) + this.dbcUserSeatsLimit} user seats as per the ${this.readablePlanName} plan`
            } else if (this.selectedDBCPlan === PLAN_TYPES.Business ){
                this.usersTooltipMessage = `When your subscription renews, you'll pay $${(Number(this.dbcUserSeats) + this.dbcUserSeatsLimit) * 4 * 12} for the ${Number(this.dbcUserSeats) + this.dbcUserSeatsLimit} user seats as per the ${this.readablePlanName} plan`
            }
        } else if (this.isTrialUser) {
            this.usersTooltipMessage = `You will be upgraded to the ${this.readablePlanName} plan with ${Number(this.dbcUserSeats)} user seats`
        }

        this.upcomingPriceSubject.next();

        this.fetchingUpcomingPrice = true;
        if (this.isTrialUser) {
            if (value > CONFIG_VALUES.maxLimitCheck) {
                this.handleMaxValueOfUsers(this.dbcUserSeats);
            } else if (value < this.minUsersToBuy) {
                this.userSeatChangeSubject.next()
            }
        } else if (!this.isTrialUser){
            if (value > CONFIG_VALUES.maxLimitCheck) {
                this.handleMaxValueOfUsers(this.dbcUserSeats);
            } else if (value < (this.minUsersToBuy - this.dbcUserSeatsLimit)) {
                this.userSeatChangeSubject.next()
            }
        }
    }

    onEnterpriseSelected() {
        window.open(
            'https://www.uniqode.com/schedule-demo?utm_source=Dashboard&utm_medium=Enterprise&utm_campaign=Talktous',
            '_blank',
        );
    }

    checkForDbcUserSeats() {
        this.organizationCountService
            .getCounts()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                ({ dbc_user_count = 0 }: OrganizationCountModel) => (this.currentActiveUsers = dbc_user_count),
                () => (this.currentActiveUsers = 0),
            );

        this.currentTotalUsers = this.authService.getCurrentOrganization().account_limits.dbc_user_seats;
    }

    updateDbcUserSeats() {
        this.isBillingUpgradeRequested = true;
        const  additionalUserSeats =  this.isTrialUser
            ? Number(this.dbcUserSeats) - this.dbcUserSeatsLimit
            : Number(this.dbcUserSeats);

        if (this.selectedDBCPlan === this.currentDBCPlan){
            this.updateAddonSeats(additionalUserSeats);
        } else {
            this.updateSubscription(additionalUserSeats)
        }
    }

    updateAddonSeats(additionalUserSeats){
        const body = {
            plan : this.currentDBCPlan,
            yearly: true,
            additionalUserSeats,
        }
        this.userService
            .updateAddOns(body)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => (this.isBillingUpgradeRequested = false)),
            )
            .subscribe(
                () => {
                    this.usersLimitReachedModal.hide();
                    this.invalidFileError = '';
                    this.csvDrop.clear();

                    if (this.user.subscription.dbc.plan !== this.selectedDBCPlan) {
                        const existingPlan = User.getReadablePlan(this.user.subscription.dbc.plan);
                        const newPlan = User.getReadablePlan(this.selectedDBCPlan);
                        this.messageModal.show(
                            `You were upgraded from ${existingPlan} plan to ${newPlan} plan successfully`,
                            'success',
                        );
                    } else {
                        this.messageModal.show('user seats upgraded successfully', 'success');
                        const data = {
                            source: ADDON_SOURCE.DBC_BULK_CREATE,
                            success: true,
                            type: ADD_ON_PRODUCT_TYPE.USER_SEATS
                        }
                        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.ADDONS, data)
                    }
                },
                (error) => {
                    this.messageModal.show(error.message, 'danger');
                },
            );
    }

    updateSubscription(additionalUserSeats){
        let requiredUserSeats = 0;
        if (this.selectedDBCPlan === PLAN_TYPES.Business){
            requiredUserSeats = Math.abs((additionalUserSeats + this.dbcUserSeatsLimit) - 50);
        } else if (this.selectedDBCPlan === PLAN_TYPES.BusinessPlus){
            requiredUserSeats = Math.abs((additionalUserSeats + this.dbcUserSeatsLimit) - 250);
        }
        this.userService
            .updateSubscription(this.selectedDBCPlan, true, null, '', requiredUserSeats)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => (this.isBillingUpgradeRequested = false)),
            )
            .subscribe(
                () => {
                    this.usersLimitReachedModal.hide();
                    this.invalidFileError = '';
                    this.csvDrop.clear();

                    if (this.user.subscription.dbc.plan !== this.selectedDBCPlan) {
                        const existingPlan = User.getReadablePlan(this.user.subscription.dbc.plan);
                        const newPlan = User.getReadablePlan(this.selectedDBCPlan);
                        this.messageModal.show(
                            `You were upgraded from ${existingPlan} plan to ${newPlan} plan successfully`,
                            'success',
                        );
                    } else {
                        this.messageModal.show('user seats upgraded successfully', 'success');
                    }
                },
                (error) => {
                    this.messageModal.show(error.message, 'danger');
                },
            );
    }

    goToNext(step: number | BULK_DBC_STEPPER) {
        switch (step) {
            case BULK_DBC_STEPPER.UPLOAD:
                this.selectedStep = BULK_DBC_STEPPER.UPLOAD;
                break;
            case BULK_DBC_STEPPER.DESIGN:
                if (this.isCSVAdded && this.errorRows.length === 0) {
                    this.selectedStep = BULK_DBC_STEPPER.DESIGN;
                }
                break;
            case BULK_DBC_STEPPER.LAUNCH:
                if (this.isCSVAdded && this.errorRows.length === 0) {
                    this.selectedStep = BULK_DBC_STEPPER.LAUNCH;
                }
                break;
            case BULK_DBC_STEPPER.FINISH:
                this.onSave();
        }
    }

    pressBack() {
        if (this.selectedStep !== 1) {
            this.selectedStep--;
        }
    }

    showPreview() {
        this.mobilePreviewModal.show();
        this.loadPreview(true);
    }

    loadPreview(event) {
    }

    onIFrameLoadingStateChange(value: boolean) {
        this.isFrameLoadingSubject.next(value);
    }

    downloadTemplates(event, type?) {
        event.preventDefault();
        if (type === BULK_TEMPLATE_TYPE.CSV) {
            window.open(this.exampleCsvFileLink);
        } else {
            window.open(this.exampleXLSXFileLink);
        }
    }

    onCSVChanged(data) {
        this.csvData = data;
        this.fileEdited = true;
    }

    // Function to validate csv data
    validateCSV() {
        this.overlay.isLoading(true);
        this.count = 0;
        if (this.fileEdited) {
            this.updateFile();
        }
        const formData: FormData = new FormData();
        formData.append('file', this.csvFile);
        formData.append('file_name', this.uploadedFileName);
        formData.append('organization', this.authService.getCurrentOrgId().toString());

        this.invalidFileError = '';
        this.csvValidationResponse = {};
        this.errorRows = [];

        this.dbcBulkService.validateCSV(formData).subscribe(
            (response) => {
                this.isCSVAdded = true;
                this.disableNextButton = false;
                this.overlay.isLoading(false);
                this.csvValidationResponse = response;
                this.invalidFileError = '';
                if (response.error_count === 0) {
                    this.errorRows = [];
                } else {
                    this.errorRows = response.error_rows;
                    this.disableNextButton = true;
                }
                if (!this.invalidFileError && this.errorRows.length === 0) {
                    this.goToNext(2);
                }
            },
            (error) => {
                this.disableNextButton = true;

                this.overlay.isLoading(false);
                if (error.status === 426) {
                    const {
                        detail,
                        existing_users = 0,
                        new_user_count = 0,
                        dbc_user_seats_limit = 0,
                    } = error?.error || {};

                    this.errorDetail = detail || this.errorDetail;
                    this.newUserCount = new_user_count;
                    this.minUsersToBuy = existing_users + new_user_count;
                    this.requiredUserSeats = existing_users + new_user_count;
                    this.dbcUserSeatsLimit = dbc_user_seats_limit;
                    this.totalDbcSeats = existing_users + new_user_count;
                    if (this.isTrialUser) {
                        this.modalSubText = `To add ${new_user_count} more users, you need to upgrade to ${
                            existing_users + new_user_count
                        } seats.
                        Your current plan has ${
    dbc_user_seats_limit - existing_users
}/${dbc_user_seats_limit} seats remaining.`;

                    } else {
                        this.modalSubText = `You have ${dbc_user_seats_limit - existing_users} out of ${dbc_user_seats_limit} user seats vacant. The file you are trying to upload has ${new_user_count} new users.
                        To proceed, please add ${(new_user_count) - (dbc_user_seats_limit - existing_users)} more user seats`
                    }

                    this.onUserSeatsLimitReached();
                    this.setPlanLimit();
                    this.csvDrop.clear();

                } else if (error?.detail || error?.error?.detail) {
                    this.messageModal.show(error?.detail || error?.error?.detail, 'danger');
                    this.resetFilePicker();
                    return;
                }
                this.csvValidationResponse = {};
                this.errorRows = [];
                if (error.status !== 426 && (error?.error?.error_type === 1)) {
                    this.messageModal.show(this.invalidFileError || 'Error validating data', 'danger');
                    this.resetFilePicker();
                    return;
                }
                console.error(error?.error?.detail);
            },
        );
    }

    onUserSeatsLimitReached() {
        const rangeInput = document.getElementById('user-seats-counter') as HTMLInputElement; ;
        const seats = this.isTrialUser ? this.requiredUserSeats : this.requiredUserSeats - this.dbcUserSeatsLimit;
        this.onRangeChange(seats, rangeInput);
        this.usersLimitReachedModal.show();
    }


    updateFile() {
        const unParsedFileData = this.unParseCSVData();
        this.csvFile = new Blob([unParsedFileData], { type: 'text/csv' });
    }

    unParseCSVData() {
        const data = this.csvData;
        let result, ctr, keys;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        if (data === null || !data.length) {
            return null;
        }

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) {
                    result += columnDelimiter;
                }

                result +=
                    typeof item[key] === 'string' && item[key].includes(columnDelimiter) ? `"${item[key]}"` : item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    onDataFileAdded(file: File) {
        this.csvFile = file;
        this.uploadedFileName = file?.name || '';
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.onXLSXAdded(file);
        } else {
            this.onCSVAdded(file);
        }
    }

    onXLSXAdded(file: File) {
        this.isCSVAdded = false;
        this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const reader: FileReader = new FileReader();
        const _this = this;
        reader.onload = (fileLoadedEvent: any) => {
            /* read workbook */
            const arrayBuffer: ArrayBuffer = fileLoadedEvent.target.result;
            const workBook: WorkBook = read(arrayBuffer);

            /* grab first sheet */
            const sheetName: string = workBook.SheetNames[0];
            const workSheet: WorkSheet = workBook.Sheets[sheetName];

            /* save data */
            const data = <Array<Array<any>>>utils.sheet_to_json(workSheet, { header: 1 });
            const keys = data[0];
            const objs = [];
            for (let i = 1; i < data.length; i++) {
                objs.push({});
                for (const [index, key] of keys.entries()) {
                    objs[i - 1][key] = (data[i][index] || '').toString();
                }
            }
            _this.csvData = objs;
            _this.validateCSV();
        };
        reader.readAsArrayBuffer(file);
        return;
    }

    onCSVAdded(file: File) {
        this.isCSVAdded = false;
        const reader: FileReader = new FileReader();
        reader.readAsText(file);
        const _this = this;
        reader.onload = function (fileLoadedEvent) {
            // @ts-ignore
            const csvContent = fileLoadedEvent.target.result;

            const options = {
                header: true,
                transform: (value, header) => {
                    return value.trim();
                },
                skipEmptyLines: 'greedy',
                complete: (results) => {
                    _this.csvData = results.data;
                    _this.validateCSV();
                },
            };
            // @ts-ignore
            _this.papa.parse(csvContent, options);
        };
        return;
    }
    private clearDataForSave() {
        const typography = this.dbcBulk.customizations.typography;
        if (typography.font_type === 'google') {
            for (const key in typography.personal_info) {
                if (!key.startsWith('google')) {
                    delete typography.personal_info[key];
                }
            }
            for (const key in typography.company_details) {
                if (!key.startsWith('google')) {
                    delete typography.company_details[key];
                }
            }
            for (const key in typography.contact_details) {
                if (!key.startsWith('google')) {
                    delete typography.contact_details[key];
                }
            }
            for (const key in typography.button) {
                if (!key.startsWith('google')) {
                    delete typography.button[key];
                }
            }
            for (const key in typography.bio) {
                if (!key.startsWith('google')) {
                    delete typography.bio[key];
                }
            }
        } else {
            for (const key in typography.personal_info) {
                if (key.startsWith('google')) {
                    delete typography.personal_info[key];
                }
            }
            for (const key in typography.company_details) {
                if (key.startsWith('google')) {
                    delete typography.company_details[key];
                }
            }
            for (const key in typography.contact_details) {
                if (key.startsWith('google')) {
                    delete typography.contact_details[key];
                }
            }
            for (const key in typography.button) {
                if (key.startsWith('google')) {
                    delete typography.button[key];
                }
            }
            for (const key in typography.bio) {
                if (key.startsWith('google')) {
                    delete typography.bio[key];
                }
            }
        }

        // remove __valid_url__ key from this.dbcBulk.user_agreement_urls Array list
        this.dbcBulk.lead_user_agreement_attribute.user_agreement_urls = this.dbcBulk.lead_user_agreement_attribute.user_agreement_urls.filter(res => {
            delete res.__valid_url__;
            return (res.url && res.label) ? res : null;
        })
    }

    getLeadAmplitudeValue() {
        let lead_collect_amplitude_val = '';
        if (this.cardOrgSettings.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.NO) { // If disabled lead in org card settings return NO
            return 'no';
        } else if (this.cardOrgSettings.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES) { // If enabled lead in org card settings return value from card template
            return this.selectedCardTemplate.lead_attribute.connection === LEAD_COLLECT_TYPE.COLLECT_FIRST ? 'collect_first' : 'share_first';
        }

        // If there is no restriction from ORG settings for lead. Then check from dbcBulk, Also dbcBulk will have value from template during initialization
        if ( this.dbcBulk.lead_collection && this.dbcBulk.lead_attribute.connection === LEAD_COLLECT_TYPE.COLLECT_FIRST) {
            lead_collect_amplitude_val = 'collect_first';
        } else if (this.dbcBulk.lead_collection && this.dbcBulk.lead_attribute.connection === LEAD_COLLECT_TYPE.SHARE_FIRST) {
            lead_collect_amplitude_val = 'share_first';
        } else {
            lead_collect_amplitude_val = 'no';
        }

        return lead_collect_amplitude_val;
    }

    getOrgSettingsRestrictionsValue(property: CARD_PROPERTY_TYPES) {
        if (this.cardOrgSettings) {
            if (property === CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2) {
                return this.cardOrgSettings.location_enabled_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false : this.cardOrgSettings.location_enabled_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : this.dbcBulk.location_enabled;
            } else if (property === CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2) {
                return this.getLeadAmplitudeValue();
            } else if (property === CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED) {
                return this.cardOrgSettings.ip_location_enabled === DBC_ORG_PERMISSION_OPTIONS.NO ? false : this.cardOrgSettings.ip_location_enabled === DBC_ORG_PERMISSION_OPTIONS.YES ? true : this.dbcBulk.ip_location_enabled;
            } else if (property === CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2) {
                return this.cardOrgSettings.autodownload_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false : this.cardOrgSettings.autodownload_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : this.dbcBulk.autodownload_v2;
            }
        }
        return false;
    }
    onSave(): void {
        this.clearDataForSave();
        if (this.hasWriteAccess) {
            this.overlay.isLoading(true);

            let request;
            if (this.fileEdited) {
                this.updateFile();
            }
            const formData = this.dbcBulk.getFormData();
            formData.append('file', this.csvFile); // TODO: unparse and send
            formData.append('file_name', this.uploadedFileName);
            if (this.skipErrorRows) {
                formData.append('skip_rows', '[' + this.errorRows.toString() + ']');
            }

            request = this.dbcBulkService.post(formData).pipe(
                takeUntil(this.ngUnsubscribe),
                tap((res) => {}),
            );

            request.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                (res) => {
                    if (!this.isMobileScreen) {
                        this.generatingBatchModal.show();
                    }
                    this.isGeneratingBatchDrawerOpen = true;
                    this.overlay.isLoading(false);
                    this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.BULK_CREATE_CARD, {
                        count: res.dbc_count,
                        ...(this.dbcBulk.layout && TEMPLATE_NAMES_MAP[this.dbcBulk.layout] ? {layout: TEMPLATE_NAMES_MAP[this.dbcBulk?.layout]} : {layout: null}),
                        '2-way_contact_sharing_enabled': this.getOrgSettingsRestrictionsValue(CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2),
                        gps_tracking_enabled: this.getOrgSettingsRestrictionsValue(CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2),
                        ip_tracking_enabled: this.getOrgSettingsRestrictionsValue(CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED),
                        download_on_scan_enabled: this.getOrgSettingsRestrictionsValue(CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2),
                        'user-consent-enabled': getUserConsentAmplitudeValue(this.dbcBulk)
                    });
                    this.authService.addDatadogAction('BulkDBCCreated');
                    this.digitalBusinessCardTemplateService.setDBCTemplate(null);
                },
                (error) => {
                    console.error(error);
                    this.overlay.isLoading(false);
                    this.messageModal.show('Error generating cards', 'danger');
                },
            );
        } else {
            this.router.navigate(['/digital-business-cards/my-cards'], {
                relativeTo: this.route,
                fragment: 'done',
                queryParams: { orgID: this.authService.getCurrentOrgId() },
            });
            this.digitalBusinessCardTemplateService.setDBCTemplate(null);
        }
    }

    reloadPage() {
        this.routerReloadService.reload();
    }

    finishBulkUpload() {
        if (!this.isMobileScreen) {
            this.generatingBatchModal.hide();
        }
        this.isGeneratingBatchDrawerOpen = false;
        this.router.navigate(['/digital-business-cards/my-cards'], {
            queryParams: { orgID: this.authService.getCurrentOrgId() },
        });
    }

    onTemplateSelect(event) {
        if (!event.template) {
            this.selectedCardTemplate = null
            this.dbcBulk.card_template = null;
            this.digitalBusinessCardTemplateService.setDBCTemplate(null);
            return;
        }
        this.selectedCardTemplate = new DigitalBusinessCardTemplateModel(event.template);
        this.dbcBulk.customizations = this.selectedCardTemplate.customizations;
        this.dbcBulk.card_template = this.selectedCardTemplate.id;
        this.cardSettings = new DigitalBusinessCardSetting(this.selectedCardTemplate.restrictedfields);
    }

    resetFilePicker() {
        this.invalidFileError = '';
        this.csvDrop.clear();
    }

    ngOnDestroy() {
        document.querySelectorAll('.container-fluid')[0].classList.remove('hide-breadcrumb-container');
        document.querySelectorAll('.container-fluid')[0].classList.remove('hide-breadcrumb-container-linkpage');
        this.digitalBusinessCardTemplateService.setDBCTemplate(null);
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isDecrementButtonDisabled(): boolean {
        const disableButtonTrial = this.minUsersToBuy >= Number(this.dbcUserSeats);
        const disableButtonPaid = this.buyUserSeats >= Number(this.dbcUserSeats);
        return (this.isTrialUser ? disableButtonTrial : disableButtonPaid);
    }



    startIncrementLongPress(event: any) {
        this.pressStartTime = Date.now();
        this.incrementPressTimer = setTimeout(() => {
            this.incrementCounter();
            this.startIncrementLongPressInterval();
        }, this.longPressThreshold);
    }

    // to start long press for decrementCounter
    startDecrementLongPress(event: any) {
        if (!this.isDecrementButtonDisabled()){
            this.pressStartTime = Date.now();
            this.decrementPressTimer = setTimeout(() => {
                this.decrementCounter();
                this.startDecrementLongPressInterval();
            }, this.longPressThreshold);
        }
    }

    // to start interval for continuous increment
    startIncrementLongPressInterval() {
        this.incrementPressTimer = setInterval(() => {
            this.incrementCounter();
        }, this.longPressInterval);
    }

    // to start interval for continuous decrementCounter
    startDecrementLongPressInterval() {
        this.decrementPressTimer = setInterval(() => {
            this.decrementCounter();
        }, this.longPressInterval);
    }

    // to clear long press timer for increment
    endIncrementLongPress() {
        clearTimeout(this.incrementPressTimer);
        clearInterval(this.incrementPressTimer);
    }

    // to clear long press timer for decrementCounter
    endDecrementLongPress() {
        clearTimeout(this.decrementPressTimer);
        clearInterval(this.decrementPressTimer);
    }

    incrementCounter() {

        this.dbcUserSeats = Number(this.dbcUserSeats) + 1;
        this.handleUserSeatChange(this.dbcUserSeats);

        if (!this.isTrialUser && this.selectedDBCPlan !== PLAN_TYPES.BusinessPlus) {
            this.upcomingPriceSubject.next();
        }
    }

    decrementCounter() {
        if (!this.isDecrementButtonDisabled()){
            if (Number(this.dbcUserSeats) > 0) {
                this.dbcUserSeats = Number(this.dbcUserSeats) - 1;
                this.handleUserSeatChange(this.dbcUserSeats);
            }

            if (Number(this.dbcUserSeats) < 50){
                this.dbcUserSeatsChecked = false;
            }

            this.handleUserSeatChange(this.dbcUserSeats);
        }

        if (!this.isTrialUser && this.selectedDBCPlan !== PLAN_TYPES.BusinessPlus) {
            this.upcomingPriceSubject.next();
        }
    }

    updateUserSeats(value, minSeats) {
        setTimeout(() => {
            this.dbcUserSeats = minSeats;
            const dbcPrice = (this.selectedDBCPlan === PLAN_TYPES.Solo) ? DBC_PRICE_RATES.Solo : (this.selectedDBCPlan === PLAN_TYPES.Team) ? DBC_PRICE_RATES.Team : DBC_PRICE_RATES.Business;
            this.dbcUserSeatsPrice = Number(this.dbcUserSeats) * dbcPrice;
            this.handleUserSeatChange(this.dbcUserSeats);
        }, 1000);
    }

    handleMinValueOfUsers(value) {
        if (this.isTrialUser && value < this.minUsersToBuy) {
            this.updateUserSeats(value, this.minUsersToBuy);
        } else if (!this.isTrialUser && value < (this.minUsersToBuy - this.dbcUserSeatsLimit)) {
            this.updateUserSeats(value, this.minUsersToBuy - this.dbcUserSeatsLimit);
        }
    }

    handleMaxValueOfUsers(value) {
        if (value > 100000) {
            this.updateUserSeats(value, 100000);
        }
    }

    checkboxHandler(){
        this.dbcUserSeatsChecked = !this.dbcUserSeatsChecked;
        this.handleUserSeatChange(this.dbcUserSeats);
        this.upcomingPriceSubject.next();
    }

    get isDisabled(): boolean {
        const trialUser = this.minUsersToBuy >= Number(this.dbcUserSeats);
        const regularUser = this.buyUserSeats >= Number(this.dbcUserSeats);
        return this.isTrialUser ? trialUser : regularUser;
    }

    get shouldShowSelector(): boolean {
        const trialUser = (Number(this.dbcUserSeats) >= CONFIG_VALUES.minLimitCheck) && (Number(this.dbcUserSeats) < CONFIG_VALUES.maxLimitCheck);
        const regularUser = (Number(this.dbcUserSeats) + this.dbcUserSeatsLimit >= CONFIG_VALUES.minLimitCheck) && (Number(this.dbcUserSeats) + this.dbcUserSeatsLimit < CONFIG_VALUES.maxLimitCheck);
        return this.isTrialUser ? trialUser : regularUser;
    }

    get showUpcomingPrice(): boolean {
        return !this.isTrialUser &&
               (this.selectedDBCPlan !== this.PLAN_TYPES.BusinessPlus) &&
               this.fetchingUpcomingPrice;
    }

    updateInputValue(inputElement: HTMLInputElement): void {
        const value = inputElement.value;
        const numericValue = Number(value);
        const isValidValue = value !== '' && !isNaN(numericValue) && numericValue >= 0;
        inputElement.value = isValidValue ? String(Math.abs(numericValue)) : '';
    }

    calculateMarginTop() {
        if (!this.isTrialUser && (this.selectedDBCPlan === PLAN_TYPES.Team || Number(this.dbcUserSeats) >= CONFIG_VALUES.maxLimitCheck || Number(this.dbcUserSeats) >= CONFIG_VALUES.maxLimitCheck - this.dbcUserSeatsLimit)) {
            return '146px';
        } else if (this.selectedDBCPlan === PLAN_TYPES.Team || Number(this.dbcUserSeats) >= CONFIG_VALUES.maxLimitCheck) {
            return '146px';
        } else if (this.selectedDBCPlan === PLAN_TYPES.BusinessPlus){
            return '10px';
        }
    }

    get getBulkUploadCTA(): boolean {
        if (this.isBillingUpgradeRequested || this.isTrialUser) { // disable when the billing upgrade is requested or the user is a trial user
            return this.minUsersToBuy > Number(this.dbcUserSeats);
        } else {
            return (this.buyUserSeats > Number(this.dbcUserSeats)) || (this.fetchingUpcomingPrice && this.selectedDBCPlan !== PLAN_TYPES.BusinessPlus);
            // disable when the user seats are less than the minimum required user seats
            // disable when th eincoming price is being fetched and the selected plan is not business plus
        }
    }

    getPrimaryCtaName(): string {
        if (this.selectedStep === BULK_DBC_STEPPER.DESIGN || this.selectedStep === BULK_DBC_STEPPER.UPLOAD) {
            return 'Next';
        } else if (this.selectedStep === BULK_DBC_STEPPER.LAUNCH) {
            return 'Finish'
        }
    }

    getSecondaryCtaAction() {
        if (this.selectedStep === BULK_DBC_STEPPER.DESIGN || this.selectedStep === BULK_DBC_STEPPER.LAUNCH) {
            this.pressBack();
        } else if (this.selectedStep === BULK_DBC_STEPPER.UPLOAD) {
            window.history.back();
        }
    }

    handleBottomStickyButtons(): boolean {
        if (this.launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]){
            if (this.isMobileScreen) {
                this.showStickyButtons = true;
            } else if (!this.isMobileScreen) {
                this.showStickyButtons = false;
            }
        } else {
            this.showStickyButtons = false;
        }
        return this.showStickyButtons
    }

    protected readonly FEATURE_FLAGS = FEATURE_FLAGS;
}
