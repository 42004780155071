import { Injectable } from '@angular/core';
import { BaseBackendService } from './base-backend.service';
import { BaseModel } from './base.model';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { MessageModalService } from '../shared/message-modal/message-modal.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class OrganizationCountModel extends BaseModel {
    place_count: number;
    beacon_count: number;
    nfc_count: number;
    qrcode_count: number;
    bulkqr_count: number;
    markdown_card_count: number;
    form_count: number;
    schedule_count: number;
    dynamic_qrcode_count: number;
    linkpage_count: number;
    dbc_user_count: number;
    qr_user_count: number;
    response_count_in_cycle: number;
    response_limit: number;
    custom_domain_count: number;
    dbc_earned_user_count: number;

    constructor(json) {
        super();
        if (!json) {
            return;
        }
        this.place_count = json['place_count'];
        this.nfc_count = json['nfc_count'];
        this.qrcode_count = json['qrcode_count'];
        this.bulkqr_count = json['bulkqr_count'];
        this.markdown_card_count = json['markdown_card_count'];
        this.form_count = json['form_count'];
        this.schedule_count = json['schedule_count'];
        this.dynamic_qrcode_count = json['dynamic_qrcode_count'];
        this.linkpage_count = json['linkpage_count'] || 0;
        this.dbc_user_count = json['dbc_user_count'];
        this.qr_user_count = json['qr_user_count'];
        this.response_count_in_cycle = json['response_count_in_cycle'] || 0;
        this.response_limit = json['response_limit'] || 0;
        this.custom_domain_count = json['custom_domain_count'] || 0;
        this.dbc_earned_user_count = json['dbc_earned_user_count'] || 0;
    }
}

@Injectable()
export class OrganizationCountService extends BaseBackendService<OrganizationCountModel> {
    place_count: number;
    beacon_count: number;
    nfc_count: number;
    qrcode_count: number;
    bulkqr_count: number;
    markdown_card_count: number;
    form_count: number;
    schedule_count: number;
    dynamic_qrcode_count: number;
    linkpage_count: number;
    dbc_user_count: number;
    qr_user_count: number;

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, OrganizationCountModel, 'organizations', messageModal);
    }

    getCounts(childOrgs: boolean = true): Observable<OrganizationCountModel> {
        let extraParam = '';
        if (childOrgs) {
            extraParam = this.authService.getCurrentOrganization().parent ? '' : '?child_orgs=True';
        }
        return this.getDetail(this.authService.getCurrentOrgId() + '/counts', extraParam).pipe(
            tap((data: OrganizationCountModel) => {
                this.place_count = data.place_count;
                this.beacon_count = data.beacon_count;
                this.nfc_count = data.nfc_count;
                this.qrcode_count = data.qrcode_count;
                this.bulkqr_count = data.bulkqr_count;
                this.markdown_card_count = data.markdown_card_count;
                this.form_count = data.form_count;
                this.schedule_count = data.schedule_count;
                this.dynamic_qrcode_count = data.dynamic_qrcode_count;
                this.linkpage_count = data.linkpage_count;
                this.dbc_user_count = data.dbc_user_count;
                this.qr_user_count = data.qr_user_count;
            }),
        );
    }

    getCurrentLinkpageCount() {
        return this.linkpage_count;
    }

    getResponseCount(): Observable<OrganizationCountModel> {
        return this.getDetail(this.authService.getCurrentOrgId() + '/response_count');
    }
}
