import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { OverlayService } from 'app/global-services/overlay.service';
import { BUTTON_TYPES, BUTTON_STYLES, TEXT_FIELD_TYPES, MESSAGE_TYPES } from 'kaizen-design-system';
import { COPY_TEXT_TYPES } from 'app/api/api.component';
import { MessageModalService } from 'app/shared/message-modal/message-modal.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AuthService } from 'app/global-services/auth.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SalesforceService } from './salesforce.service';
import { PRODUCT_TYPES } from 'app/shared/utils';

enum SALESFORCE_STEP {
    One,
    Two
}

@Component({
    templateUrl: './salesforce.component.html',
    styleUrls: ['../integrations.scss', './salesforce.component.scss']
})

export class SalesforceComponent implements OnInit {

    @ViewChild('disconnectSalesforceModal', { static: false }) disconnectSalesforceModal: ModalDirective;

    ngUnsubscribe: Subject<any> = new Subject();
    isSalesforceConnected: boolean;
    currentStep: number;
    SALESFORCE_STEP: any = SALESFORCE_STEP;
    callbackURL: string;
    currentOrganizationId: number;
    salesforceConnectError: boolean;
    salesforceConnectErrorMessage: string;
    salesforceConnectSuccessMessage: string = 'Leads collected are synced with Salesforce every 15 minutes.';
    leadsSynced: number;
    integrationID: number = -1;

    private consumer_key: string;
    private consumer_secret: string;
    private lead_source: string;

    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    COPY_TEXT_TYPES = COPY_TEXT_TYPES;
    MESSAGE_TYPES = MESSAGE_TYPES;

    constructor(private router: Router, private authService: AuthService, private overlayService: OverlayService,
        private messageModalService: MessageModalService, private route: ActivatedRoute, private salesforceService: SalesforceService,
        private location: Location) {
        this.authService.currentOrgId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((orgID) => {
            this.currentOrganizationId = orgID;
        });
    }

    ngOnInit(): void {
        if (!this.authService.getUser().hasSalesForceIntegrationAccess(PRODUCT_TYPES.DBC)) {
            this.router.navigate(['/integrations'], {queryParams: {orgID: this.currentOrganizationId}});
        }

        this.overlayService.isLoading(true);
        this.currentStep = this.SALESFORCE_STEP.One;
        this.callbackURL = `${window.location.href.split('?')[0]}/`;
        this.salesforceConnectError = false;
        this.salesforceConnectErrorMessage = 'Error connecting...';

        this.route.fragment.subscribe(fragment => {
            if (!this.isSalesforceConnected) {
                if (!fragment || fragment === 'step-one') {
                    this.currentStep = this.SALESFORCE_STEP.One;
                } else if (fragment === 'step-two') {
                    this.salesforceConnectError = false;
                    this.currentStep = this.SALESFORCE_STEP.Two;
                }
            }
        });

        this.route.queryParams.subscribe(params => {
            if (params['code']){
                const authCode = params['code']

                const body = {
                    code: authCode
                }

                this.salesforceService.postAuthorizationCode(body).pipe(
                    takeUntil(this.ngUnsubscribe),
                    finalize(() => {
                        this.overlayService.isLoading(false);
                    })
                ).subscribe(response => {
                    this.isSalesforceConnected = true;
                    this.messageModalService.show('Connection successful', 'success');
                    this.getAuthenticationStatus();
                }, httpErrorResponse => {
                    this.isSalesforceConnected = false;
                    this.salesforceConnectError = true;
                    const sferrorObject = httpErrorResponse.error.sf_errors;
                    this.salesforceConnectErrorMessage = `Unable to connect due to ${sferrorObject.error}: ${sferrorObject.error_description}. Please refer to our documentation for troubleshooting.`;
                    this.messageModalService.show('Connection failed', 'danger');
                });
            }else{
                this.getAuthenticationStatus();
            }
        });
    }

    getAuthenticationStatus() {
        this.salesforceService.getAuthenticatedStatus().pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.overlayService.isLoading(false);
            })
        ).subscribe(response => {
            this.integrationID = response.id;
            if (response.connected) {
                this.isSalesforceConnected = true;
                this.leadsSynced = response.leads_synced;
            } else {
                this.isSalesforceConnected = false;
                if (Object.keys(response.post_connection_errors).length !== 0){
                    this.salesforceConnectError = true;
                    const errorObject = response.post_connection_errors[0];
                    this.salesforceConnectErrorMessage = `Connection interrupted due to ${errorObject.errorCode}: ${errorObject.message}. Please refer to our documentation for troubleshooting.`;
                }
            }
        }, error => {
            if (error.status === 404) {
                this.isSalesforceConnected = false;
            }
        });
    }

    copyCallbackURL(type) {
        let value = '';
        if (type === COPY_TEXT_TYPES.SALESFORCE_CALLBACK_URL) {
            value = this.callbackURL;
        }
        navigator.clipboard.writeText(value);
        this.messageModalService.show('Copied to clipboard', 'success');
    }

    onNextStepClicked() {
        if (!this.isSalesforceConnected) {
            if (this.currentStep === this.SALESFORCE_STEP.One) {
                this.currentStep = this.SALESFORCE_STEP.Two;
                this.router.navigate(['/integrations/salesforce'], { relativeTo: this.route, fragment: 'step-two', queryParams: { orgID: this.currentOrganizationId }, queryParamsHandling: 'merge' })
            }
        }
    }

    onDocumentationClicked() {
        window.open('https://docs.beaconstac.com/en/articles/8061521-export-your-collected-leads-from-beaconstac-to-salesforce-crm', '_blank');
    }

    onSalesforceConnectClicked() {
        this.overlayService.isLoading(true);
        const consumer_data = {
            client_id: this.consumer_key,
            client_secret: this.consumer_secret,
            lead_source: this.lead_source
        }
        this.salesforceService.postSalesforceCredentials(consumer_data).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.overlayService.isLoading(false);
            })
        ).subscribe(response => {
            const salesforceAuthUrl = response.salesforce_auth_url;
            window.location.href = salesforceAuthUrl;
        });
    }

    onSalesforceDisconnectClicked() {
        this.salesforceService.disconnectSalesforce(this.integrationID).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.overlayService.isLoading(false);
                this.router.navigate(['/integrations'], {queryParams: {orgID: this.currentOrganizationId}});
            })
        ).subscribe(response => {
            this.isSalesforceConnected = false;
        });
    }

    onBackClicked() {
        this.location.back();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
