import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

import {PLAN_INTERVALS, UserSubscriptionService} from './user-subscription-service';
import {User} from '../user-account/user.model';

@Injectable()
export class SumoService {

    private planInterval: PLAN_INTERVALS;
    private user: User;

    constructor(private authService: AuthService, private userSubscriptionService: UserSubscriptionService) {
    // this.setupSumoBanner();
        this.loadSumoScript();
    }

    setupSumoBanner() {
        this.user = this.authService.getUser();
        this.userSubscriptionService.subscriptionSubject.subscribe(subscription => {
            if (!subscription) {
                return;
            }
            // @ts-ignore
            this.planInterval = subscription && subscription.plan && subscription.plan.id.indexOf('YEAR') !== -1 ? PLAN_INTERVALS.Yearly : PLAN_INTERVALS.Monthly;

            if (this.user) {
                const plan = this.user.getReadablePlan();
                if (plan === 'Trial' || plan === 'Starter' || plan === 'Lite' || ((this.planInterval === PLAN_INTERVALS.Monthly) && (plan === 'Pro'))) {
                    this.loadSumoScript();
                }
            }
        });
    }

    loadSumoScript() {
    // @ts-ignore
        (function(s, u, m, o, j, v){
            j = u.createElement(m); v = u.getElementsByTagName(m)[0]; j.async = 1; j.src = o; j.dataset.sumoSiteId = '00aeca25678a795afacc39603508d6dad83751bc349d63204faa8fc923c6bbf3'; v.parentNode.insertBefore(j, v)
        })(window, document, 'script', '//load.sumo.com/');
    }

}
