import { catchError, filter, map, share, tap } from 'rxjs/operators';
import { BaseBackendService } from 'app/global-services/base-backend.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../global-services/auth.service';
import {Observable, of} from 'rxjs';
import { PLAN_TYPES, User } from './user.model';
import { HttpClient } from '@angular/common/http';
import { MessageModalService } from '../shared/message-modal/message-modal.service';
class BeaconCost {
    perBeaconHardwareCharge: number;
    perBeaconShippingCharge: number;
    perBeaconTotalCharge: number;
    totalBeaconCharge: number;
    totalSoftwareCharge: number;
    softwareChargeDate: Date;
    promoCodeOff: number;

    constructor(json: any) {
        const hardware = json['hardware_charges'];
        const software = json['software_charges'];
        this.perBeaconHardwareCharge = hardware['beacon_charge'];
        this.perBeaconShippingCharge = hardware['shipping_charge'];
        this.perBeaconTotalCharge = hardware['beacon_bundle_charge'];
        this.totalBeaconCharge = hardware['total_charge'];
        this.promoCodeOff = hardware['promo_code_off'];
        this.totalSoftwareCharge = software['total_charge'];
        this.softwareChargeDate = new Date(software['charge_date']);
    }
}

export class Card {
    id: string;
    last4: string;
    brand: string;
    funding: string;
    exp_month: string;
    exp_year: string;
    default: boolean;

    constructor(json) {
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.last4 = json['last4'];
        this.brand = json['brand'];
        this.funding = json['funding'];
        this.exp_month = json['exp_month'];
        this.exp_year = json['exp_year'];
        this.default = json['default'];
    }
}

export enum PLAN_TYPE {
    PER_BEACON = 1,
    SUBSCRIPTION = 2,
    PER_QR = 3,
}

export class SubscriptionDetail {
    id: string;
    nickname: string;
    amount: number;
    expires: Date;
    interval: 'month' | 'year';
    type: PLAN_TYPE;
    quantity: number;
    status: string;
    trial_start: number;
    trial_end: number;
    subscription: string;
    productType: string;

    constructor(json: any) {
        this.id = json['id'];
        this.expires = new Date(json['current_period_end'] * 1000);
        this.nickname = json['nickname'];
        this.amount = json['amount'];
        this.interval = json['interval'];
        this.quantity = json['quantity'];
        this.status = json['status'];
        this.trial_start = json['trial_start'];
        this.trial_end = json['trial_end'];
        this.type =
      this.id.search('PB') !== -1
          ? PLAN_TYPE.PER_BEACON
          : this.id.search('PQ') !== -1
              ? PLAN_TYPE.PER_QR
              : PLAN_TYPE.SUBSCRIPTION;
        this.subscription = json['subscription'];
        this.productType = json['productType'] || '';
    }
}

export class PlanDetail {
    id: string;
    nickname: string;
    amount: number;
    interval: 'month' | 'year';
    type: PLAN_TYPE;

    constructor(json: any) {
        this.id = json['id'];
        this.nickname = json['nickname'];
        this.amount = json['amount'] / 100;
        this.interval = json['interval'];
        this.type =
      this.id.search('PB') !== -1
          ? PLAN_TYPE.PER_BEACON
          : this.id.search('PQ') !== -1
              ? PLAN_TYPE.PER_QR
              : PLAN_TYPE.SUBSCRIPTION;
    }
}

export interface UserPostBody {
    username: string;
    organization: number;
    customer_plan: PLAN_TYPES;
    user_group: string;
    permissions: {
        'qr': boolean,
        'cards':  boolean
    }
}

export enum BEACON_TYPE {
    BEACON_BUNDLE_STANDARD = 1,
    BEACON_BUNDLE_ALL_WEATHER = 2,
    BEACON_BUNDLE_MINEW_C6 = 3,
    BEACON_BUNDLE_MINEW_I7 = 4,
    BEACON_BUNDLE_LONG_RANGE = 5,
}

@Injectable()
export class UserService extends BaseBackendService<User> {
    BEACON_TYPE = BEACON_TYPE;
    PLAN_TYPES = PLAN_TYPES;

    constructor(
        protected http: HttpClient,
        protected authService: AuthService,
        messageModal: MessageModalService
    ) {
        super(http, authService, User, 'users', messageModal);
        // refresh user in authService after login
        this.authService.isLoggedIn$
            .pipe(filter((loggedIn) => loggedIn === true))
            .subscribe(() => this.getDetail());
    }

    post(user: any, custom?: string): Observable<any> {
        return super.post(user, custom);
    }

    bulkUserDelete(userIdList: Array<number>, allUsersSelected: boolean, filters?: any) {
        return super.post(
            {
                ids: userIdList,
                filters: filters,
                allUsersSelected: allUsersSelected
            },
            'bulk_delete/?force_delete=true'
        )
    }

    bulkResendInvite(userIdList: Array<number>, allUsersSelected: boolean, filters?: any) {
        return super.post(
            {
                ids: userIdList,
                filters: filters,
                allUsersSelected: allUsersSelected
            },
            'bulk_resend_invite/'
        )
    }
    invite(emails: Array<string>, message: string) {
        return super.post(
            {
                emails: emails,
                message: message,
            },
            'invite/'
        );
    }

    changeEmailAddress(newEmail: string, password: string) {
        return super
            .post(
                {
                    new_email: newEmail,
                    password: password,
                },
                'email_change/'
            )
            .pipe(tap((response) => this.getDetail()));
    }

    resendChangeEmailAddress() {
        return super.post({}, 'email_change_resend/');
    }

    put(body: User, id: number, custom?): Observable<any> {

        if (!body.subscription.dbc) {
            delete body.subscription.dbc
        }

        if (!body.subscription.qr) {
            delete body.subscription.qr
        }

        const sub = super.put(body, id, custom ? custom : '?organization=all').pipe(
            tap((res) => {
                if (id === this.authService.getUser().id) {
                    this.authService.setUser(new User(res));
                    this.authService.setCurrentOrgObjInLocalStorage(this.authService.getCurrentOrganization())
                }
            }),
            share()
        );
        sub.subscribe(
            (_) => {},
            (_) => {}
        );
        return sub;
    }

    delete(id: number, custom_body: string): Observable<any> {
        let custom = '?organization=all';
        if (custom_body) {
            custom += custom_body;
        }
        return super.delete(id, custom);
    }

    getDetail(id: number | 'me' = 'me'): Observable<User> {
        const sub = super.getDetail(id).pipe(
            tap((user) => {
                if (id === 'me' || id === this.authService.getUser().id) {
                    this.authService.setUser(user);
                    this.authService.setCurrentOrgObjInLocalStorage(this.authService.getCurrentOrganization())
                }
            }),
            share()
        );
        sub.subscribe(
            (_) => {},
            (_) => {}
        );
        return sub;
    }

    getCountryCallingCode(): Observable<User> {
        const url = this.apiURL + '/me/calling_code';
        return this.http
            .get(url, this.buildHeaders({}))
            .pipe(catchError((error) => this.handleError(error)));
    }


    getToken(): Observable<any> {
        const url = this.apiURL + 'token/';
        return this.http
            .get(url, this.buildHeaders({}))
            .pipe(catchError((error) => this.handleError(error)));
    }

    buyBeacons(
        beacon_type: number,
        bundle: number,
        quantity: number,
        promoCode?: string
    ): Observable<SubscriptionDetail> {
        return super
            .postToId(
                {
                    beacon_type: this.BEACON_TYPE[beacon_type],
                    beacon_bundle: bundle,
                    quantity: quantity,
                    buy: true,
                    promo_code: promoCode, // 'BKFREESHIP29'
                },
                this.authService.getUser().id + '/subscriptions/'
            )
            .pipe(
                map((response): SubscriptionDetail => {
                    const sub = response;
                    return new SubscriptionDetail(sub);
                }),
                tap(() => this.getDetail())
            );
    }

    getBeaconCost(
        beacon_type: number,
        bundle: number,
        quantity: number,
        promoCode?: string
    ): Observable<BeaconCost> {
        return super
            .postToId(
                {
                    beacon_type: this.BEACON_TYPE[beacon_type],
                    beacon_bundle: bundle,
                    quantity: quantity,
                    buy: false,
                    promo_code: promoCode, // 'BKFREESHIP29'
                },
                this.authService.getUser().id + '/subscriptions/'
            )
            .pipe(
                map((response): BeaconCost => {
                    const charges = response;
                    return new BeaconCost(charges);
                })
            );
    }

    getSubscription(): Observable<Array<SubscriptionDetail>> {
        const url = this.apiURL + this.authService.getUser().id + '/subscriptions/';
        return this.http.get(url, this.buildHeaders({})).pipe(
            map((response) => {
                const subscriptionResponse = response['subscriptions'];
                if (subscriptionResponse.length > 0) {
                    const subscriptions: Array<SubscriptionDetail> = [];
                    for (const subscription of subscriptionResponse) {
                        subscriptions.push(new SubscriptionDetail(subscription));
                    }
                    return subscriptions;
                } else {
                    return null;
                }
            }),
            catchError((error) => this.handleError(error))
        );
    }

    getCards(): Observable<Array<Card>> {
        const url = this.apiURL + this.authService.getUser().id + '/cards/';
        return this.http.get(url, this.buildHeaders({})).pipe(
            map((response) => {
                const cardsResponse = response['cards'];
                const cards = [];
                if (cardsResponse.length > 0) {
                    for (const card of cardsResponse) {
                        // if (card.default) {
                        cards.push(new Card(card));
                        // }
                    }
                    return cards;
                } else {
                    return [];
                }
            }),
            catchError((error) => this.handleError(error))
        );
    }

    markCardAsDefault(card: Card): Observable<object> {
        const url = this.apiURL + this.authService.getUser().id + '/cards/';
        return this.http
            .put(url, card, this.buildHeaders({}))
            .pipe(catchError((error) => this.handleError(error)));
    }

    updateSubscription(
        plan: string,
        yearly: boolean = false,
        couponCode = null,
        eventFromSource: string = 'unknown',
        userSeats?: number,
        plusPlanUserAddOn?: number,
        additionalFormResponses?: number,
        additionalParams: any = {}
    ): Observable<any> {
        const { additionalCustomDomains } = additionalParams || {};
        const body = {
            new_customer_plan: plan,
            yearly: yearly,
            coupon_code: couponCode,
            source: eventFromSource,
        }
        if (userSeats) {
            body['additionalUserSeats'] = userSeats;
        }
        // for qr plan
        if (plusPlanUserAddOn) {
            body['additionalUsers'] = plusPlanUserAddOn;
        }

        if (additionalFormResponses) {
            body['additionalFormResponses'] = additionalFormResponses;
        }

        if (additionalCustomDomains){
            body['additionalCustomDomains'] = 1;
        }

        return super
            .put(
                body,
                this.authService.getUser().id,
                'subscriptions/'
            )
            .pipe(tap((response) => this.getDetail()));
    }

    subscribeWithCheckout(
        plan: string,
        yearly: boolean = false,
        eventFromSource: string = 'unknown',
        coupon: string = '',
        userSeats?: number,
        plusPlanUserAddOn?: number,
        responseAddonCount?: number,
        additionalParams?: any
    ): Observable<any> {
        const { additionalCustomDomains } = additionalParams || {};
        const body = {
            new_customer_plan: plan,
            yearly: yearly,
            source: eventFromSource,
            use_checkout: true,
            coupon_code: coupon,
            addons: {}
        }
        // for dbc plan
        if (userSeats) {
            body['addons']['user_seat'] = userSeats;
        }
        // for qr plan
        if (plusPlanUserAddOn) {
            body['addons']['child_user'] = plusPlanUserAddOn;
        }
        if (responseAddonCount) {
            body['addons']['form_response'] = responseAddonCount;
        }

        if (additionalCustomDomains){
            body['addons']['custom_domain'] = 1;
        }

        return super
            .put(
                body,
                this.authService.getUser().id,
                'subscriptions/'
            )
            .pipe(tap((response) => this.getDetail()));
    }

    subscribeWithCheckoutId(checkout_id: string): Observable<any> {
        return super
            .post(
                {
                    checkout_id: checkout_id
                },
                this.authService.getUser().id + '/checkout/'
            )
            .pipe(tap(() => this.getDetail()));
    }

    createSubscription(
        plan: string,
        cardId,
        yearly: boolean = false,
        couponCode = null,
        eventFromSource: string = 'unknown'
    ): Observable<any> {
        return super
            .post(
                {
                    new_customer_plan: plan,
                    yearly: yearly,
                    coupon_code: couponCode,
                    card_id: cardId,
                    source: eventFromSource,
                },
                this.authService.getUser().id + '/subscriptions/'
            )
            .pipe(tap((response) => this.getDetail()));
    }

    verifyPromoCode(promoCode = null): Observable<any> {
        const url = this.apiURL + `subscriptions/coupons?code=${promoCode}`;
        return this.http.get(url, this.buildHeaders({})).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    cancelSubscription(userId: number, password: string, plan?: string): Observable<any> {
        return super.delete(userId, 'subscriptions/?password=' + password + '&plan=' + plan);
    }

    /**
   * Returns if customers plan if lower than specified plan
   * @param plan to compare
   */
    isOnLowerPlan(plan: 'ST' | 'BA' | 'PR' | 'RE' | 'WL' | 'PL'): boolean {
        const customerPlan = this.authService.getUser().customer_plan;
        let isOnLowerPlan = false;
        switch (customerPlan) {
            case 'TR':
                if (
                    plan === 'ST' ||
          plan === 'BA' ||
          plan === 'PR' ||
          plan === 'PL' ||
          plan === 'RE' ||
          plan === 'WL'
                ) {
                    isOnLowerPlan = true;
                }
                break;
            case 'ST':
                if (
                    plan === 'BA' ||
          plan === 'PR' ||
          plan === 'PL' ||
          plan === 'RE' ||
          plan === 'WL'
                ) {
                    isOnLowerPlan = true;
                }
                break;
            case 'BA':
                if (plan === 'PR' || plan === 'PL' || plan === 'RE' || plan === 'WL') {
                    isOnLowerPlan = true;
                }
                break;
            case 'PR':
                if (plan === 'PL' || plan === 'RE' || plan === 'WL') {
                    isOnLowerPlan = true;
                }
                break;
            case 'PL':
                if (plan === 'RE' || plan === 'WL') {
                    isOnLowerPlan = true;
                }
                break;
            case 'RE':
                if (plan === 'WL') {
                    isOnLowerPlan = true;
                }
                break;
        }
        return isOnLowerPlan;
    }

    getLastInvoice(): Observable<any> {
        const url = this.apiURL + this.authService.getUser().id + '/invoices/';
        return this.http.get(url, this.buildHeaders({})).pipe(
            map((response) => {
                // for last invoice until account section revamp is deployed
                const response_temp = response;
                try {
                    response = response['data'][0]['lines']['data'][0];
                } catch (e) {
                    response = response_temp;
                }
                const invoiceResponse = response['plan'];
                return invoiceResponse ? invoiceResponse : null;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    getInvoices(startingAfter?): Observable<any> {
        let url = this.apiURL + this.authService.getUser().id + '/invoices/';

        if (startingAfter) {
            url += '?start_after_id=' + startingAfter;
        }

        return this.http.get(url, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    getBillingDetails() {
        const url =
      this.apiURL + 'manage-billing/' + this.authService.getUser().id + '/';

        return this.http.get(url, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    updateBillingDetails(data) {
        const url =
      this.apiURL + 'manage-billing/' + this.authService.getUser().id + '/';

        return this.http.put(url, data, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    updateFullNameDetails(data) {
        const user = this.authService.getUser();
        const url = this.apiURL + user.id + '/';

        return this.http.put(url, data, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => this.handleError(error))
        );
    }

    updateAddOns(data): Observable<any> {
        return super
            .post(data, 'subscriptions/addons/' + this.authService.getUser().id + '/')
            .pipe(
                tap((response) => {
                    this.getDetail();
                })
            );
    }

    revokeUserAccess(data): Observable<any> {
        return super
            .post(data, `revoke/?organization=${this.authService.getUser().organization.id}`);
    }

    getUpcomingInvoicePrice(data): Observable<any> {
        return super
            .post(data, 'payments/upcoming/' + this.authService.getUser().id + '/')
            .pipe(tap((response) => {}));
    }

    getNextUpcomingInvoicePrice(): Observable<any> {
        const getNextUpcomingInvoicePriceUrl = this.apiURL + 'payments/upcoming/' + this.authService.getUser().id + '/';
        return this.http.get(getNextUpcomingInvoicePriceUrl, this.buildHeaders({}))
            .pipe(tap((response) => {}));
    }

    public getReferredList(custom: string): Observable<any> {
        const referredListUrl = this.apiURL + custom;
        return this.http.get(referredListUrl, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }

    public getEarnedQRcodes(): Observable<any> {
        const qr_earnedurl = this.buildURL(
            this.apiURL,
            null,
            null,
            null,
            true,
            null,
            'me/',
            null
        );
        return this.http.get(qr_earnedurl, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }

    public getSPUImpact(): Observable<any> {
        const url = this.buildURL(
            this.apiURL,
            null,
            null,
            null,
            true,
            null,
            'spu-impact/',
            null
        );
        return this.http.get(url, this.buildHeaders({})).pipe(
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }

    public getWatchlistID(qrID): Observable<any> {
        const userID = this.authService.getUser().id;
        const url = this.buildURL(
            this.apiURL,
            null,
            null,
            null,
            true,
            null,
            `${userID}/watchlists/qrcode/retrieve-by-qrcode/?organization=${this.authService.getOrganization().id}&qrcode_id=${qrID}`,
            null
        );
        return this.http.get(url, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }

    public deleteQRFromUserWatchlist(watchlistID): Observable<any> {
        const userID = this.authService.getUser().id;
        const url = this.buildURL(
            this.apiURL,
            null,
            null,
            null,
            true,
            null,
            `${userID}/watchlists/qrcode/${watchlistID}/?organization=${this.authService.getOrganization().id}`,
            null
        );
        return this.http.delete(url, this.buildHeaders({})).pipe(
            map((response) => {
                return response;
            }),
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }

    public isQRWatchlistedByUser(qrID: number): Observable<boolean> {
        return this.getWatchlistID(qrID).pipe(
            map(() => true),
            catchError(error => {
                if (error.status === 404) {
                    return of(false);
                } else {
                    this.messageModal.show('Failed to fetch watchlist status. Please contact support@uniqode.com.', 'danger');
                }
            })
        );
    }
}

@Injectable()
export class UserUpdateService extends BaseBackendService<User> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, User, 'users/update', messageModal);
    }

    put(body: User, id: number, custom?): Observable<any> {
        const sub = super.put(body, id, custom).pipe(
            tap((res) => {
                if (id === this.authService.getUser().id) {
                    this.authService.setUser(new User(res));
                    this.authService.setCurrentOrgObjInLocalStorage(this.authService.getCurrentOrganization())
                }
            }),
            share()
        );
        sub.subscribe(
            (_) => {},
            (_) => {}
        );
        return sub;
    }


}
