<div class="bx--row mb-4">
    <div class="bx--col">
        <p class="mb-2 font-weight-400" style="font-size: 20px;">Set-up Business Card</p>
    </div>
</div>
<div class="bx--row mt-1">
    <div class="bx--col">
                <span class="page-heading">
                  Advanced Settings
                </span>
    </div>
</div>

<div class="bx--row mt-4">
    <div class="bx--col">
                <span class="section-headline">
                  Auto-download contact
                    <span [ngClass]="cardSettings.autodownload | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2: cardSettings: orgCardSettings"></span>
                </span>
    </div>
</div>
<div class="bx--row mt-2">
    <div class="bx--col pt-1">
                <span class="section-sub-heading">
                  Automatically download contacts to phone on view
                </span>
    </div>
</div>
<div class="bx--row mt-2">
    <div class="bx--col d-flex pt-1">
        <beaconstac-toggle [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSettings : orgCardSettings: cardType: priortizeOrgSettings : CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2"
                           [checked]="orgCardSettings.autodownload_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                   orgCardSettings.autodownload_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true :dbcBulk.autodownload_v2"
                           (checkedChange)="dbcBulk.autodownload_v2 = $event"
        >
        </beaconstac-toggle>
        <label class="form-control-label dbc-toggle-label">Enable</label>
    </div>
</div>

<!-- LEADS NEW FORM -->

<div *ngIf="launchDarkleyFlags[FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM]" class="bx--row">
    <div class="bx--col">
        <app-dbc-lead-form [@collapse]
                           [product]="dbcBulk"
                           [hasWriteAccess]="hasWriteAccess"
                           [(cardSetting)]="cardSettings"
                           [orgCardSetting]="orgCardSettings"
                           [cardType]="cardType"
                           [priortizeOrgSettings]="priortizeOrgSettings"
                           [leadSettings]="leadSettings"
                           [isSalesforceConnected]="isSalesforceConnected"
        ></app-dbc-lead-form>
    </div>
</div>

<!-- LEADS -->
<div *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM]">
    <div class="bx--row mt-4">
      <div class="bx--col">
                  <span class="section-headline">
                    TWO-WAY CONTACT SHARING
                      <span [ngClass]="cardSettings.lead_collection | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2: cardSettings: orgCardSettings"></span>
                  </span>
      </div>
    </div>
    <div class="bx--row mt-2">
      <div class="bx--col pt-1">
                  <span class="section-sub-heading">
                    Collect contacts using their cards
                  </span>
      </div>
    </div>
    <div class="bx--row mt-2">
      <div class="bx--col d-flex pt-1">
          <beaconstac-toggle [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSettings : orgCardSettings: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2"
                             [checked]="orgCardSettings.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                       orgCardSettings.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : dbcBulk.lead_collection"
                             (checkedChange)="dbcBulk.lead_collection = $event">
          </beaconstac-toggle>
          <label class="form-control-label dbc-toggle-label">Enable</label>
      </div>
    </div>
    <div *ngIf="orgCardSettings.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES || dbcBulk.lead_collection" [@collapse] class="bx--col-lg-8 mt-2" style="background-color: #F4F5F7; border-radius: 3px;">
      <div class="pt-2 pb-2">
          <span class="section-sub-heading">
              Select what data you want to collect
          </span>
      </div>
      <div *ngFor="let leadSetting of leadSettings" class="d-flex mt-1">
          <beaconstac-toggle [disabled]="leadSetting.disable" [checked]="leadSetting.default || dbcBulk.lead_attribute[leadSetting.name]" (checkedChange)="toggleLeadSetting($event, leadSetting)">"
          </beaconstac-toggle>
          <label class="form-control-label dbc-toggle-label">{{leadSetting.label}}</label>
      </div>
    </div>
</div>

<!-- GPS -->
<div class="bx--row mt-4">
    <div class="bx--col">
                <span class="section-headline">
                  Gps Location
                    <span [ngClass]="cardSettings.location_enabled | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2: cardSettings: orgCardSettings"></span>
                </span>
    </div>
</div>
<div class="bx--row mt-2">
    <div class="bx--col pt-1">
                <span class="section-sub-heading">
                  Track location of people viewing cards
                </span>
    </div>
</div>
<div class="bx--row mt-2">
    <div class="bx--col d-flex pt-1">
        <beaconstac-toggle [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSettings : orgCardSettings: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2"
                           [checked]="orgCardSettings.location_enabled_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                   orgCardSettings.location_enabled_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : dbcBulk.location_enabled"
                           (checkedChange)="dbcBulk.location_enabled = $event"
        >
        </beaconstac-toggle>
        <label class="form-control-label dbc-toggle-label">Enable</label>
    </div>
</div>

<!-- IP -->
<div class="bx--row mt-4">
    <div class="bx--col">
                <span
                    class="section-headline">
                    Track IP Location
                    <span [ngClass]="cardSettings.ip_location_enabled | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED"></span>
                </span>
    </div>
</div>
<div class="bx--row mt-3">
    <div class="bx--col">
                <span class="section-sub-heading">
                  Analyse scan locations using the device's IP address
                </span>
    </div>
</div>
<div class="bx--row mt-3">
    <div class="bx--col d-flex">
        <beaconstac-toggle
            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSettings : orgCardSettings: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED"
            [checked]="orgCardSettings.ip_location_enabled === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                   orgCardSettings.ip_location_enabled === DBC_ORG_PERMISSION_OPTIONS.YES ? true : dbcBulk.ip_location_enabled"
            (checkedChange)="dbcBulk.ip_location_enabled = $event">
            >
        </beaconstac-toggle>
        <label class="form-control-label linkpage-toggle-label">Enable</label>
    </div>
</div>


<!-- SEND USER INVITE EMAIL -->
<div *ngIf="userIsAdmin">
  <div class="bx--row mt-4">
    <div class="bx--col">
              <span class="section-headline">
                SEND USER INVITE EMAIL
              </span>
    </div>
  </div>
  <div class="bx--row mt-2">
    <div class="bx--col pt-1">
              <span class="section-sub-heading">
                Send an email invite to new users to view and manage their personal cards.
              </span>
    </div>
  </div>
  <div class="bx--row mt-2">
    <div class="bx--col d-flex pt-1">
      <beaconstac-toggle [(checked)]="dbcBulk.send_user_invite_email">
      </beaconstac-toggle>
      <label class="form-control-label dbc-toggle-label">Enable</label>
    </div>
  </div>
</div>

<div class="bx--row mt-3 pl-1 overflow-hidden">
    <div class="bx--row d-flex mx-0 pl-2" style="max-width: 650px;">
        <!-- left scroller -->
        <div class="d-none cursor-pointer align-items-center justify-content-center template-horizontal-scroll" (click)="scrollTemplate('scroll-left-template')" id="scroll-left-template">
            <a class="float d-flex flex-wrap justify-content-center align-content-center"><i class="fas fa-solid fa-caret-left text-primary"></i></a>
        </div>

        <div class="bx--col px-0 w-100 d-flex" infiniteScroll [infiniteScrollDistance]="2"
             [infiniteScrollThrottle]="50" [horizontal]="true" (scrolled)="onTemplateScroll()"
             (scroll)="hideScroller()"
             [scrollWindow]="false" style="overflow: auto; white-space: nowrap; scroll-behavior: smooth;" id="template-list">
            <div *ngFor="let item of Templates; let i = index" class="template-container m-1"
                 [ngClass]="selectedTemplate === item.id ? 'selected-option' : ''"
                 (click)="hasWriteAccess && !cardSettings.template ? onSelectTemplate(item) : false"
                 [attr.id] = "'template-' + i">
                <div class="template p3 m-2">
                    <img data-id="{{ item.id }}" [ngClass]="dbcBulk.template?.id === item.id ? 'selected' : ''"
                         [src]="item.thumbnailUrl" height="120px" width="120px">
                    <div *ngIf="dbcBulk.template?.id === item.id" class="remove-template place-delete bold-on-hover"><i
                        class="fas fa-trash-alt"> </i> REMOVE
                    </div>
                </div>
                <div class="template-info text-center">
                    <div class="w-100 single-line-text">{{item.name}}</div>
                    <div class="small text-muted w-100 single-line-text"
                         *ngIf="item.meta['creator_email'] && user?.organization.feature_permissions.reseller_access"
                         [title]="'Created by:' + item.meta['creator_email']">
                        {{item.meta['creator_email']}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isFetchingTemplates" class="d-flex align-items-center justify-content-center" style="width: 30px;">
            <div class="spinner-border spinner-border-sm text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- right scroller -->
        <div class="align-items-center cursor-pointer justify-content-center template-horizontal-scroll" (click)="scrollTemplate('scroll-right-template')" id="scroll-right-template" [ngStyle]="{ 'display' : Templates?.length <= 4 ? 'none': 'flex' }">
            <a class="float d-flex flex-wrap justify-content-center align-content-center"><i class="fas fa-solid fa-caret-right text-primary"></i></a>
        </div>
    </div>
</div>

<!--<div class="bx&#45;&#45;row mt-4">-->
<!--    <div class="bx&#45;&#45;col">-->
<!--                <span class="section-headline">-->
<!--                  Wallet passes-->
<!--                </span>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="bx&#45;&#45;row mt-2">-->
<!--    <div class="bx&#45;&#45;col pt-1">-->
<!--                <span class="section-sub-heading">-->
<!--                  Auto-send wallet passes to owners-->
<!--                </span>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="bx&#45;&#45;row mt-2 pt-1">-->
<!--    <div class="bx&#45;&#45;col d-flex">-->
<!--        <beaconstac-toggle [disabled]="!hasWriteAccess" [(checked)]="dbcBulk.auto_send_wallet_pass">-->
<!--        </beaconstac-toggle>-->
<!--        <label class="form-control-label dbc-toggle-label">Enable</label>-->
<!--    </div>-->
<!--</div>-->
