<beaconstac-title style="margin-bottom: 32px;"
                  [title]="'Assign templates'"
                  [subtitle]="'This will help you view the business cards for each organisation'"
></beaconstac-title>

<div class="bx--row mt-4 mx-0" *ngIf="showNoTemplateWarning">
    <div class="bx--col-xlg-11 bx--col-lg-9 bx--col-md-4 px-3 d-flex" style="background: #DDEFFF; flex-direction: row; max-width: 758px;">
        <i class="fas fa-info-circle pr-2" style="color: #2595FF; font-size: 20px; padding-top: 18px;"></i>
        <span class="py-3 pb-2" style="display:inline-block;">KNOW THIS: We've assigned a default template for organisations with "No Template".</span>
    </div>
</div>

<div class="bx--row mt-4 mx-0" style="min-height: 200px;">
    <div class="bx--col-xlg-11 bx--col-lg-9 bx--col-md-4 bg-white p-3" style="max-width: 758px; max-height: 500px; overflow-y: scroll">
        <div class="bx--row px-3" style="min-height: 48px;">
            <div class="bx--col col-headline py-2">NAME</div>
            <div class="bx--col col-headline py-2">TEMPLATES</div>
        </div>
        <div class="bx--row pb-3 px-3" *ngFor="let org of organizationsList; let i = index" style="min-height: 48px;"
             (mouseenter)="loadPreview(org.id)"
             (mouseleave)="loadPreview(null)">
            <div class="bx--col py-2 d-flex align-items-start flex-column flex-md-row column-gap-2">
                <span class="mr-2">{{org.name}}</span>
                <span class="no-templates-flag overflow-hidden" *ngIf="!org['hasTemplates']">No template</span>
            </div>
            <div class="bx--col py-2">
                <app-digital-business-card-templates-dropdown
                    [organizationID]="org.id"
                    (onTemplateSelectChange)="onTemplateSelected($event, org.id)"
                    [id]="i"
                    [showHeading]="false"
                    [allowFetchingMasterOrgTemplates]="true"
                    [showNoneOption]="false"
                    [width]="'200px'"
                    (orgHasTemplates)="setOrgHasTemplates($event, org.id)"
                    [placeholder]="'Select Template'"
                    [selectSingleTemplateAsDefault]="true"
                ></app-digital-business-card-templates-dropdown>
            </div>
        </div>
    </div>
    <div [ngClass]="previewTemplate ? '' : 'd-none'" class="bx--col-xlg-5 bx--col-lg-7 bx--col-md-4 bx--col-sm-0 text-center" style="height: 0px; overflow: visible;">
        <div class="live-preview-iframe-container text-center position-sticky" style="top: 184px; margin-bottom: 30px" id="customPreview">
            <iframe class="live-preview-iframe mb-3" #digitalBusinessCardPreview></iframe>
        </div>
    </div>
</div>
