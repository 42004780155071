import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {OverlayService} from '../../global-services/overlay.service';
import {AuthService} from '../../global-services/auth.service';
import {BUTTON_STYLES, BUTTON_TYPES, ICON_POSITION, ICON_SIZE} from 'kaizen-design-system';
import {
    BULK_TEMPLATE_TYPE, DigitalBusinessCardMultiOrgBulkModel,
    DigitalBusinessCardTemplateModel
} from '../digital-business-card.model';
import {read, utils, WorkBook, WorkSheet} from 'xlsx';
import {DigitalBusinessCardBulkService} from '../digital-business-card-bulk-upload/digital-business-card-bulk-service';
import {BeaconstacFileDropComponent} from '../../global-components/beaconstac-file-drop/beaconstac-file-drop.component';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import { Papa } from 'ngx-papaparse';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil, tap} from 'rxjs/operators';
import {DigitalBusinessCardTemplateService} from '../digital-business-cards.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Animations} from '../../shared/beaconstac-animations';
import {TEMPLATE_SAVE_REDIRECT_TO} from '../digital-business-card-templates/digital-business-card-templates.component';

enum BULK_DBC_STEPPER {
    UPLOAD = 1,
    TEMPLATE_SELECT = 2,
}

@Component({
    selector: 'app-cards-multi-org-bulk-upload',
    templateUrl: './cards-multi-org-bulk-upload.component.html',
    styleUrls: ['./cards-multi-org-bulk-upload.component.scss'],
    animations: [Animations.collapse]
})
export class CardsMultiOrgBulkUploadComponent implements OnInit, OnDestroy {

    exampleCsvFileLink: string;
    exampleXLSXFileLink: string;
    invalidFileError: string = '';
    csvData: Array<object>;
    csvFile: File | Blob;
    isCSVAdded: boolean = false;
    fileEdited = false;
    csvValidationResponse = {};
    disableNextButton: boolean = true;
    errorRows = [];
    selectedStep: number = BULK_DBC_STEPPER.UPLOAD;
    errorDetail: string = 'Your bulk operation exceeds your limit of users. You can add on more users to your plan.';
    fileType: 'text/csv' | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'text/csv';
    hasWriteAccess: boolean = true;

    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;
    BULK_DBC_STEPPER = BULK_DBC_STEPPER;
    ICON_SIZE = ICON_SIZE;
    ICON_POSITION = ICON_POSITION;

    organizationsList = [];
    orgTemplateMap: Map <number, DigitalBusinessCardTemplateModel> = new Map();
    dbcBulk: DigitalBusinessCardMultiOrgBulkModel = new DigitalBusinessCardMultiOrgBulkModel();
    ngUnsubscribe: Subject<any> = new Subject();
    uploadedFileName: string = ''

    @ViewChild('csvDrop', { static: false }) csvDrop: BeaconstacFileDropComponent;
    @ViewChild('digitalBusinessCardPreview', { static: false }) digitalBusinessCardPreview: ElementRef;
    @ViewChild('generatingBatchModal', { static: false }) generatingBatchModal: ModalDirective;
    @ViewChild('noTemplatesWarnModal', { static: false }) noTemplatesWarnModal: ModalDirective;
    constructor(private overlay: OverlayService,
        private authService: AuthService,
        private dbcBulkService: DigitalBusinessCardBulkService,
        private messageModal: MessageModalService,
        private papa: Papa,
        private router: Router,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.overlay.isLoading(false);
        this.exampleCsvFileLink = '../../assets/files/template-bulk-cards-multi-org.csv';
        this.exampleXLSXFileLink = '../../assets/files/template-bulk-cards-multi-org.xlsx';
        this.dbcBulk.organization = this.authService.getCurrentOrgId();

        // TODO: Handle this
        if (!this.authService.getUser().isSuperAdmin()) {
            this.router.navigate([
                '/overview',
                {queryParams: {orgId: this.authService.getCurrentOrgId()}}
            ])
        }

        this.hasWriteAccess = this.authService.getUser().hasWriteAccess(this.authService.getCurrentOrgId());
    }

    downloadTemplates(event, type?) {
        event.preventDefault();
        if (type === BULK_TEMPLATE_TYPE.CSV) {
            window.open(this.exampleCsvFileLink);
        } else {
            window.open(this.exampleXLSXFileLink);
        }
    }

    onDataFileAdded(file: File) {
        this.csvFile = file;
        this.uploadedFileName = file?.name || 'csv/xlsx';
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.onXLSXAdded(file);
        } else {
            this.onCSVAdded(file);
        }
    }

    onCSVChanged(data) {
        this.csvData = data;
        this.fileEdited = true;
    }

    onXLSXAdded(file: File) {
        this.isCSVAdded = false;
        this.fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const reader: FileReader = new FileReader();
        const _this = this;
        reader.onload = (fileLoadedEvent: any) => {
            /* read workbook */
            const arrayBuffer: ArrayBuffer = fileLoadedEvent.target.result;
            const workBook: WorkBook = read(arrayBuffer);

            /* grab first sheet */
            const sheetName: string = workBook.SheetNames[0];
            const workSheet: WorkSheet = workBook.Sheets[sheetName];

            /* save data */
            const data = <Array<Array<any>>>utils.sheet_to_json(workSheet, { header: 1 });
            const keys = data[0];
            const objs = [];
            for (let i = 1; i < data.length; i++) {
                objs.push({});
                for (const [index, key] of keys.entries()) {
                    objs[i - 1][key] = (data[i][index] || '').toString();
                }
            }
            _this.csvData = objs;
            _this.validateCSV();
        };
        reader.readAsArrayBuffer(file);
        return;
    }

    onCSVAdded(file: File) {
        this.isCSVAdded = false;
        const reader: FileReader = new FileReader();
        reader.readAsText(file);
        const _this = this;
        reader.onload = function (fileLoadedEvent) {
            // @ts-ignore
            const csvContent = fileLoadedEvent.target.result;

            const options = {
                header: true,
                transform: (value, header) => {
                    return value.trim();
                },
                skipEmptyLines: 'greedy',
                complete: (results) => {
                    _this.csvData = results.data;
                    _this.validateCSV();
                },
            };
            // @ts-ignore
            _this.papa.parse(csvContent, options);
        };
        return;
    }

    updateFile() {
        const unParsedFileData = this.unParseCSVData();
        this.csvFile = new Blob([unParsedFileData], { type: 'text/csv' });
    }

    unParseCSVData() {
        const data = this.csvData;
        let result, ctr, keys;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        if (data === null || !data.length) {
            return null;
        }

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach((item) => {
            ctr = 0;
            keys.forEach((key) => {
                if (ctr > 0) {
                    result += columnDelimiter;
                }

                result +=
                    typeof item[key] === 'string' && item[key].includes(columnDelimiter) ? `"${item[key]}"` : item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    validateCSV() {
        if (this.fileEdited) {
            this.updateFile();
        }
        const formData: FormData = new FormData();
        formData.append('file', this.csvFile);
        formData.append('file_name', this.uploadedFileName);
        formData.append('organization', this.authService.getCurrentOrgId().toString());

        this.invalidFileError = '';
        this.csvValidationResponse = {};
        this.errorRows = [];

        this.dbcBulkService.validateCSV(formData).subscribe(
            (response) => {
                this.isCSVAdded = true;
                this.disableNextButton = false;
                this.overlay.isLoading(false);
                this.csvValidationResponse = response;
                this.invalidFileError = '';
                if (this.csvValidationResponse.hasOwnProperty('organizations_list')) {
                    this.organizationsList = Object.entries(this.csvValidationResponse['organizations_list']).map(([id, name]) => ({
                        id: parseInt(id, 10),
                        name: name.toString(),
                        hasTemplates: true
                    }));
                }

                if (this.csvValidationResponse.hasOwnProperty('has_templates') && !this.csvValidationResponse['has_templates']) {
                    this.noTemplatesWarnModal.show();
                    return;
                }


                if (response.error_count === 0) {
                    this.errorRows = [];
                } else {
                    this.errorRows = response.error_rows;
                    this.disableNextButton = true;
                }
                if (!this.invalidFileError && this.errorRows.length === 0) {
                    this.goToNext(2);
                }
            },
            (error) => {
                this.disableNextButton = true;
                this.overlay.isLoading(false);
                if (error.status === 426) {
                    const {
                        detail,
                    } = error?.error || {};

                    this.errorDetail = detail || this.errorDetail;
                    this.csvDrop.clear();
                } else if (error.detail || error?.error?.detail) {
                    this.messageModal.show(error.detail || error?.error?.detail, 'danger');
                    this.resetFilePicker();
                    return;
                }
                this.csvValidationResponse = {};
                this.errorRows = [];
                if (error.status !== 426 && (error?.error?.error_type === 1)) {
                    this.messageModal.show(this.invalidFileError, 'danger');
                    this.resetFilePicker();
                    return;
                }
                console.error(error?.error?.detail);
                this.messageModal.show(error?.error?.detail || 'Some information from the mandatory columns of your CSV is missing. Please check and try again.', 'danger');
                this.resetFilePicker();
            },
        );
    }

    goToNext(step: number | BULK_DBC_STEPPER) {
        switch (step) {
            case BULK_DBC_STEPPER.UPLOAD:
                this.selectedStep = BULK_DBC_STEPPER.UPLOAD;
                break;
            case BULK_DBC_STEPPER.TEMPLATE_SELECT:
                if (this.isCSVAdded && this.errorRows.length === 0) {
                    this.selectedStep = BULK_DBC_STEPPER.TEMPLATE_SELECT;
                }
                break;
            default:
                this.onSave();
        }
    }

    onSave(): void {
        if (this.hasWriteAccess) {
            this.overlay.isLoading(true);

            let request;
            if (this.fileEdited) {
                this.updateFile();
            }
            const formData = this.dbcBulk.getFormData();
            formData.append('file', this.csvFile); // TODO: unparse and send
            formData.append('file_name', this.uploadedFileName);
            formData.set('is_multi_org', 'true');

            const organization_template_map = {}

            for (const [key, value] of this.orgTemplateMap.entries()) {
                organization_template_map[key] = value.id;
            }

            formData.append('organization_template_map', JSON.stringify(organization_template_map));

            request = this.dbcBulkService.post(formData).pipe(
                takeUntil(this.ngUnsubscribe),
                tap((res) => {}),
            );

            request.pipe(takeUntil(this.ngUnsubscribe)).subscribe(
                (res) => {
                    this.generatingBatchModal.show();
                    this.overlay.isLoading(false);
                    // this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.BULK_CREATE_CARD, {
                    //     count: res.dbc_count,
                    // });
                    // this.authService.tagHotjarRecording('BulkDBCCreated');
                    this.digitalBusinessCardTemplateService.setDBCTemplate(null);
                },
                (error) => {
                    console.error(error);
                    this.overlay.isLoading(false);
                    this.messageModal.show('Error generating cards', 'danger');
                },
            );
        } else {
            this.router.navigate(['/digital-business-cards/my-cards'], {
                relativeTo: this.route,
                fragment: 'done',
                queryParams: { orgID: this.authService.getCurrentOrgId() },
            });
            this.digitalBusinessCardTemplateService.setDBCTemplate(null);
        }
    }

    finishBulkUpload() {
        this.generatingBatchModal.hide();
        this.router.navigate(['/digital-business-cards/team-cards'], {
            relativeTo: this.route,
            queryParams: { orgID: this.authService.getCurrentOrgId() },
        });
    }

    createNewTemplate(gotToBulkAfterCreate = false) {
        const queryParams = { orgID: this.authService.getUser().mainOrganization};

        // Conditionally add the 'bulk' parameter
        if (gotToBulkAfterCreate) {
            queryParams['post_save_redirect_to'] = TEMPLATE_SAVE_REDIRECT_TO.MULTI_ORG_BULK;
        }

        this.router.navigate(['digital-business-cards/templates/design-library'], { queryParams })
    }

    resetFilePicker() {
        this.invalidFileError = '';
        this.csvDrop.clear();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
