import {Injectable} from '@angular/core';
import {BaseBackendService} from '../../global-services/base-backend.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../global-services/auth.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {DigitalBusinessCardBulkModel} from '../digital-business-card.model';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class DigitalBusinessCardBulkService extends BaseBackendService<DigitalBusinessCardBulkModel> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, DigitalBusinessCardBulkModel, 'dbc', messageModal);
    }

    /**
     *
     * @param body
     * @param custom
     */
    public post(body: FormData): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        const url = this.apiURL + 'bulkcreate/'  + '?organization=' + this.authService.getCurrentOrgId();

        const argsObject = {};
        argsObject['headers'] = new HttpHeaders({
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });

        return this.http.post(url, body, argsObject).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    validateCSV(body: FormData) {

        const validationURL = this.apiURL + 'csv-validation/' + '?organization=' + this.authService.getCurrentOrgId();

        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }

        const argsObject = {};
        argsObject['headers'] = new HttpHeaders({
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });

        return this.http.post(validationURL, body, argsObject).pipe(
            map((response) => {
                return response;
            }), catchError(this.handleError));
    }

}
