import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Pipe,
    PipeTransform,
    ViewChild
} from '@angular/core';
import {Animations} from '../../shared/beaconstac-animations';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {Tag, TagAttachByFilterService, TagAttachService, TagsService} from '../../tags/tags.service';
import {AuthService} from '../../global-services/auth.service';
import {SearchParams} from '../../global-services/base-backend.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {BUTTON_STYLES, BUTTON_TYPES, TEXT_FIELD_VALIDATIONS} from 'kaizen-design-system';
import {DBCCardTypes, isBase64String} from 'app/shared/utils';

@Pipe({name: 'getTagCount', pure: true})
export class GetTagCount implements PipeTransform {
    transform(tag: Tag, productType: any): number {
        switch (productType) {
            case 'qr':
                return tag.qrcode_count || 0;
            case 'cards':
                return tag.digitalbusinesscard_count || 0;
            default:
                return 0;
        }
        return 0;
    }
}

@Component({
    selector: 'beaconstac-product-label',
    templateUrl: './beaconstac-product-label.component.html',
    styleUrls: ['./beaconstac-product-label.component.scss', '../../qr/qr.scss', '../../../scss/list.scss'],
    animations: [Animations.collapse],
})
export class BeaconstacProductLabelComponent implements AfterContentInit, OnDestroy {
    @Output() labelsApplied: EventEmitter<Array<Tag>> = new EventEmitter();
    @Output() cancelPressed: EventEmitter<Array<Tag>> = new EventEmitter();
    @Output() labelUpdated: EventEmitter<any> = new EventEmitter();
    @Output() deletedLabel: EventEmitter<any> = new EventEmitter();
    @Output() labelCreated: EventEmitter<any> = new EventEmitter();
    @Output() selectedLabels: EventEmitter<any> = new EventEmitter();

    @Input() tagList: Array<Tag> = [];
    @Input() qrcodes: Array<number> = [];
    @Input() nfctags: Array<number> = [];
    @Input() geofences: Array<number> = [];
    @Input() beacons: Array<number> = [];
    @Input() cards: Array<number> = [];

    @Input() qrcode_filter: object = {};
    @Input() nfctag_filter: object = {};
    @Input() geofence_filter: object = {};
    @Input() beacon_filter: object = {};
    @Input() message: string = '';
    @Input() productType: 'qr' | 'beacon' | 'nfc' | 'geofence' | 'cards' = 'qr';
    @Input() showMessage: boolean = false;
    @Input() cards_filter: any;

    @Input() operationMethod: number = -1;
    @Input() currentAppliedTags: Array<number> = [];
    @Input() cardType: DBCCardTypes

    @ViewChild('qrTagUntagModal') qrTagUntagModal: ModalDirective;
    @ViewChild('labelUpdateModal') labelUpdateModal: ModalDirective;

    tagSearchString = '';
    tagWithNameExists: boolean = false;
    addLabelApplyButtonDisabled = true;
    private tagSelectInputSubject = new Subject<any>();
    initialTagList: Array<Tag> = [];
    labelUpdateModalTitle = '';

    selectedTag: {
        id: number;
        name: string;
        color: string;
        isNew: boolean;
    } = {
            id: -1,
            name: '',
            color: null,
            isNew: false,
        };

    labelColors = [
        '#C9C9C9',
        '#62bd4f',
        '#c377e0',
        '#f2d600',
        '#51e898',
        '#00c2e0',
        '#ff9f1a',
        '#eb5a47',
        '#344563',
        '#ff78cb',
        '#0079bf',
    ];

    isUpdatingLabel: boolean = false;
    isDeletingLabel: boolean = false;

    isAttachingLabel: boolean = false;
    isFetchingLabels: boolean = false;
    currentUser: number;
    isEditor: boolean = false;
    ngUnsubscribe: Subject<any> = new Subject();
    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;
    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
    tagNameAvailable: boolean = true;
    isAllQRCodeSelected: boolean = false;

    constructor(
        private tagsService: TagsService,
        private tagAttachService: TagAttachService,
        private tagAttachByFilterService: TagAttachByFilterService,
        private authService: AuthService,
        private modalService: MessageModalService,
    ) {
        this.currentUser = this.authService.getUser().id;
        if (!this.authService.getUser().isSuperAdmin()) {
            this.isEditor = this.authService.getUser().isEditor(this.authService.getCurrentOrgId());
        }
    }

    ngOnDestroy(): void {
        if (this.tagSelectInputSubject) {
            this.tagSelectInputSubject.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngAfterContentInit(): void {
        this.tagSelectInputSubject.pipe(debounceTime(800)).subscribe((value) => {
            this.getTags(value);
        });
    }

    show(productList: Array<number> = [], isAllQRCodeSelected = false) {
        this.isAllQRCodeSelected = isAllQRCodeSelected;
        this.message = '';
        if (this.productType === 'cards') {
            this.cards = productList;
        } else {
            this.qrcodes = productList;
        }
        this.setTagStates();
        this.qrTagUntagModal.show();
    }

    hide() {
        this.qrTagUntagModal.hide();
    }

    emitSeleectedLabels() {
        const tagsToAdd = [];
        for (const tag of this.tagList) {
            if (tag['state'] === 1) {
                tagsToAdd.push(tag);
            }
        }

        this.selectedLabels.emit(tagsToAdd);
    }

    tagUntagQRCodes(selectedOperation: number) {
        if (selectedOperation === 3) {
            this.emitSeleectedLabels()
            this.qrTagUntagModal.hide();
            return;
        }
        const tagsToAdd = [],
            tagsToRemove = [];
        for (const tag of this.tagList) {
            if (tag['state'] === 0) {
                tagsToRemove.push(tag['id']);
            }
            if (tag['state'] === 1) {
                tagsToAdd.push(tag['id']);
            }
        }
        this.isAttachingLabel = true;
        const org = this.authService.getCurrentOrgId();

        if (selectedOperation === 1) {
            const filters: any = this.qrcode_filter;
            if (this.qrcode_filter['tags__name__in'] !== undefined) {
                filters['tags__name__in'] = [filters['tags__name__in']];
            }
            const filtersString = isBase64String(filters.filters) ? atob(filters.filters) : filters.filters;

            // In case of DBC we will be getting direct base64 encoded filters.
            const dbcFilterString = this.cards_filter?.filters ? this.cards_filter.filters : '';

            const requestBody = {
                tag__add_products: tagsToAdd,
                tag__remove_products: tagsToRemove,
                organization: org
            };
            if (this.productType === 'cards') {
                requestBody['digitalbusinesscard_filters'] = dbcFilterString;
                requestBody['card_owner'] = this.authService.getUser().id;
                requestBody['card_type'] = this.cardType
            } else {
                // requestBody['qrcode_set'] = this.qrcodes ? this.qrcodes : [];
                requestBody['qrcode_filters'] = btoa(filtersString);
            }
            this.tagAttachByFilterService
                .put(requestBody, 0, `?organization=${org}`)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (res) => {
                        this.addLabelApplyButtonDisabled = true;
                        this.onSuccessAttachingTags(res);
                    },
                    (error) => {
                        this.onErrorAttachingTags(error);
                    },
                );
        } else if (selectedOperation === 2) {
            const requestBody = {
                tag__add_products: tagsToAdd,
                tag__remove_products: tagsToRemove,
                organization: org,
                product: this.productType
            };
            if (this.productType === 'cards') {
                requestBody['digitalbusinesscard_set'] = this.cards;
                requestBody['card_owner'] = this.authService.getUser().id;
                requestBody['card_type'] = this.cardType
            } else {
                requestBody['qrcode_set'] = this.qrcodes ? this.qrcodes : [];
            }
            this.tagAttachService
                .put(requestBody, 0, `?organization=${org}`)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (res) => {
                        this.addLabelApplyButtonDisabled = true;
                        this.onSuccessAttachingTags(res);
                    },
                    (error) => {
                        this.onErrorAttachingTags(error);
                    },
                );
        }
    }

    onSuccessAttachingTags(responseTags) {
        this.isAttachingLabel = false;
        if (responseTags.detail) {
            this.modalService.show(responseTags.detail, 'success');
        } else {
            this.modalService.show(`Successfully applied label to your ${this.productType === 'qr' ? 'QR code(s)' : 'Card(s)' }`, 'success');
        }
        this.qrTagUntagModal.hide();
        this.setTagStates();
        this.labelsApplied.emit(responseTags);
    }

    onErrorAttachingTags(error) {
        this.isAttachingLabel = false;
        if (error.status === 403) {
            this.modalService.show('You are not allowed to access this resource', 'danger');
        } else {
            this.modalService.show(`Error labelling ${this.productType === 'qr' ? 'QR code(s)' : 'Card(s)' }. Please contact support@uniqode.com`, 'danger');
        }
        this.qrTagUntagModal.hide();
        this.setTagStates();
        this.labelsApplied.emit(this.tagList);
    }

    cancelQrTagUntagModal() {
        this.addLabelApplyButtonDisabled = true;
        this.tagSearchString = '';
        this.qrTagUntagModal.hide();
        this.cancelPressed.emit(this.tagList);
    }

    getTags(value) {
        return new Promise((resolve, reject) => {
            this.isFetchingLabels = true;
            const params: SearchParams = {};
            params['name__icontains'] = value;
            if (this.productType === 'qr') {
                params['label_type'] = 'qr'
                params['product_type'] = 'qr'
            } else if (this.productType === 'cards') {
                params['label_type'] = 'dbc'
                params['product_type'] = 'dbc'
            }
            this.tagWithNameExists = false;
            this.tagsService
                .getList(1, 20, params, true, 'updated')
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(
                    (result) => {
                        this.tagList = result.objects;
                        for (const tag of this.tagList) {
                            if (tag['name'] === value) {
                                this.tagWithNameExists = true;
                            }
                        }
                        setTimeout(() => {
                            this.setTagStates()
                        }, 500);
                        this.isFetchingLabels = false;
                        resolve(undefined);
                    },
                    (error) => {
                        this.modalService.show('Error fetching labels. Please try refreshing the page.', 'danger');
                        this.isFetchingLabels = false;
                        reject();
                    },
                );
        });
    }

    setTagStates() {
        if (this.productType === 'qr') {
            for (const tag of this.tagList) {
                const qrcodesWithTag = this.qrcodes.reduce((total, id) => {
                    if (tag['qrcode_set'].includes(id)) {
                        total = total + 1;
                    }
                    return total;
                }, 0);
                if (this.isAllQRCodeSelected) {
                    tag['state'] = 2;
                } else if (tag['qrcode_count'] === 0 || qrcodesWithTag === 0) {
                    tag['state'] = 0;
                    tag['initial_state'] = 0;
                } else if (qrcodesWithTag === this.qrcodes.length) {
                    tag['state'] = 1;
                    tag['initial_state'] = 1;
                } else {
                    tag['state'] = 2;
                    tag['initial_state'] = 2;
                }
            }
            this.tagList.sort(this.orderTagList);
        } else if (this.productType === 'cards') {
            for (const tag of this.tagList) {
                let cardsWithTag = 0;
                if (this.cards.length) {
                    cardsWithTag = this.cards.reduce((total, id) => {
                        if (tag['digitalbusinesscard_set'].includes(id)) {
                            total = total + 1;
                        }
                        return total;
                    }, 0);
                }
                if (this.isAllQRCodeSelected) {
                    tag['state'] = 2;
                } else if (tag['digitalbusinesscard_count'] === 0 || cardsWithTag === 0) {
                    tag['state'] = 0;
                    tag['initial_state'] = 0;
                } else if (cardsWithTag === this.cards.length) {
                    tag['state'] = 1;
                    tag['initial_state'] = 1;
                } else {
                    tag['state'] = 2;
                    tag['initial_state'] = 2;
                }

                // If the operation method is 3, then we need to set the state of the tags that are already applied
                if (this.operationMethod === 3) {
                    this.currentAppliedTags.forEach((tagId) => {
                        if (tag['id'] === tagId) {
                            tag['state'] = 1;
                            tag['initial_state'] = 1;
                        }
                    });
                }
            }
            this.tagList.sort(this.orderTagList);
        }
    }

    orderTagList = (tag1, tag2) => {
        if (tag1.state === tag2.state) {
            return 0;
        }
        /* Objects with "state" equal to 1 come first */
        if (tag1.state === 1) {
            return -1;
        }
        /* Objects with "state" other than 1 comes next */
        return 1;
    };

    onTagSearchInput(value) {
        this.tagSearchString = value;
        this.tagSelectInputSubject.next(this.tagSearchString);
    }

    showUpdateLabelModal(modalTitle, tag, disableEdit = false) {
        this.labelUpdateModalTitle = modalTitle;
        this.tagSearchString = tag.name ? tag.name : this.tagSearchString;
        if (disableEdit === true) {
            return;
        }
        this.qrTagUntagModal.hide();
        this.updateSelectedTag(tag);
        this.labelUpdateModal.show();
    }

    updateSelectedTag(tag) {
        if (tag['id']) {
            this.selectedTag.isNew = false;
            this.selectedTag.id = tag['id'];
            this.selectedTag.name = tag['name'];
        } else {
            this.selectedTag.isNew = true;
            this.selectedTag.name = this.tagSearchString;
        }
        this.selectedTag.color = tag['color'];
    }

    hideLabelUpdateModal() {
        this.tagNameAvailable = true;
        this.qrTagUntagModal.show();
        this.labelUpdateModal.hide();
    }

    tagStateChange(event, index) {
        this.addLabelApplyButtonDisabled = false;
        const { checked, indeterminate } = event;

        if (!checked && !indeterminate) {
            this.tagList[index]['state'] = 0;
        } else if (checked && !indeterminate) {
            this.tagList[index]['state'] = 1;
        } else if (!checked && indeterminate) {
            this.tagList[index]['state'] = 2;
        }
    }

    selectTagColor(color) {
        this.selectedTag.color = color;
    }

    updateLabel() {
        const label = this.tagList.filter((tag) => {
            return tag['id'] === this.selectedTag.id;
        })[0];
        label['name'] = this.selectedTag.name;
        label['color'] = this.selectedTag.color;
        const state = label['state'];
        const initial_state = label['initial_state'];
        this.isUpdatingLabel = true;
        this.tagSearchString = '';
        this.tagsService
            .put(label, this.selectedTag.id, `?organization=${this.authService.getCurrentOrgId()}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (result) => {
                    this.tagList = this.tagList.filter((tag) => {
                        return tag['id'] !== this.selectedTag.id;
                    });
                    result['state'] = state;
                    result['initial_state'] = initial_state;
                    this.tagList.unshift(result);
                    this.labelUpdated.emit(this.selectedTag);
                    this.isUpdatingLabel = false;
                    this.hideLabelUpdateModal();
                    this.getTags('')
                },
                (error) => {
                    this.isUpdatingLabel = false;
                    this.hideLabelUpdateModal();
                },
            );
    }

    createLabel() {
        const tag = {
            name: this.selectedTag.name,
            color: this.selectedTag.color,
            organization: this.authService.getCurrentOrgId(),
            qrcode_set: [],
            beacon_set: [],
            nfctag_set: [],
            geofence_set: [],
        };

        if (this.productType === 'cards') {
            tag['product_type'] = 'dbc'
        }
        this.isUpdatingLabel = true;
        this.tagsService
            .post(tag)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (result) => {
                    this.isUpdatingLabel = false;
                    this.hideLabelUpdateModal();
                    result['state'] = 0;
                    result['initial_state'] = 0;
                    this.tagList.unshift(result);
                    this.tagSearchString = '';
                    this.getTags('')
                    this.labelCreated.emit(result);
                },
                (error) => {
                    this.isUpdatingLabel = false;
                    this.hideLabelUpdateModal();
                },
            );
    }

    deleteLabel(tag, disableDelete = false) {
        if (disableDelete) {
            return;
        }
        this.isDeletingLabel = true;
        this.updateSelectedTag(tag);
        this.tagsService
            .delete(this.selectedTag.id, `?organization=${this.authService.getCurrentOrgId()}`)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (response) => {
                    this.tagList = this.tagList.filter((tags) => {
                        return tags['id'] !== this.selectedTag.id;
                    });
                    this.isDeletingLabel = false;
                    this.hideLabelUpdateModal();
                    this.getTags('')
                    this.deletedLabel.emit(this.selectedTag);
                },
                (error) => {
                    this.isDeletingLabel = false;
                    this.hideLabelUpdateModal();
                },
            );
    }

    getTagsWithName(name) {
        this.selectedTag.name = name;
        const params: SearchParams = {};
        params['name'] = name;
        this.tagsService
            .getList(1, 1, params, true, 'updated')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (result) => {
                    if (result.totalCount === 0 || name.length === 0) {
                        this.tagNameAvailable = true;
                    } else {
                        this.tagNameAvailable = false;
                    }
                },
                (error) => {
                    this.modalService.show('Error fetching labels. Please try refreshing the page.', 'danger');
                },
            );
    }
}
