<google-map *ngIf="addMarkerCluster" class="agm-map-container" [center]="center"
    [zoom]="4"
    height= "24.4rem"
    width="100%"
    [options] = "{
        mapTypeControl: false,
        fullscreenControl: true
    }">
    <map-marker-clusterer [styles]="agmClusterStyleGPS">
        <map-marker *ngFor="let point of gpsLocationData"
            [position]="point"
            [options]="markerOptions"
        ></map-marker>
    </map-marker-clusterer>
    <map-marker-clusterer [styles]="agmClusterStyleIP">
        <map-marker *ngFor="let point of ipLocationData"
            [position]="point"
            [options]="markerOptions"
            [icon] = "'../../../assets/img/map-marker/ip.png'">
        </map-marker>
    </map-marker-clusterer>
</google-map>

<google-map *ngIf="!addMarkerCluster" class="agm-map-container" [center]="center"
    [zoom]="16"
    [height]= "height"
    width="100%"
    (mapClick)="onMapClicked($event)"
    [options] = "{
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: true,
    }">
    <map-marker [position]="locationData"
            [options]="markerOptions"
            (mapDragend)="onMapDragEnded($event)"
    ></map-marker>
    <map-circle *ngIf="addCircle"
        [center]="center"
        [radius]="radius"
        [options] = "{
            'fillColor': '#2595ff',
            'strokeOpacity': 0.0
        }"
        ></map-circle>
</google-map>
