import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DigitalBusinessCardsService, DigitalBusinessCardTemplateService } from '../digital-business-cards.service';
import { SortTypes, getSortedTableValue, getFormattedDate, getProcessedDayDifference, getPreviousPayloadBody, getFormattedNumber, getDatesInRange, calculatePercentageChange, PageType } from 'app/global-components/common-analytics/common-analytics-utils';
import { AnalyticsService } from 'app/global-services/analytics.service';
import { SearchParams } from '../../global-services/base-backend.service';
import { AuthService } from 'app/global-services/auth.service';
import { OverlayService } from 'app/global-services/overlay.service';
import { MessageModalService } from 'app/shared/message-modal/message-modal.service';
import { Utils, Intervals, getTimeofDay, getWeekDayName, DATE_FORMAT, DBCCardTypes, PRODUCT_TYPES, CARDS_FILTER_OPTIONS } from 'app/shared/utils';
import { User } from 'app/user-account/user.model';
import { BUTTON_STYLES, ICON_SIZE, TOOLTIP_POSITION, CARET_POSITION, DROPDOWN_STYLES, DROPDOWN_TYPES, DROPDOWN_ALIGNMENT } from 'kaizen-design-system';
import * as moment from 'moment-timezone';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject, forkJoin, BehaviorSubject } from 'rxjs';
import { takeUntil, finalize, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DbcAnalyticsUtils } from '../../digital-business-card-analytics/common-utils';
import { AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService } from 'app/global-services/amplitude.service';
import { IntercomService } from 'app/global-services/intercom.service';
import { TagsService } from '../../tags/tags.service';


enum ANALYTICS_PAGE_EVENTS {
    ANALYTICS_PAGE_SCROLL_DBC = 'analytics page scrolled for dbc',
}
@Component({
    selector: 'app-digital-business-card-analytics',
    templateUrl: './digital-business-card-analytics.component.html',
    styleUrls: ['./digital-business-card-analytics.component.scss']
})


export class DigitalBusinessCardAnalyticsComponent implements OnInit {

    @ViewChild('osDistributionModal', { static: false }) osDistributionModal: ModalDirective;
    @ViewChild('mobileOptions', { static: false }) mobileModal: ModalDirective;

    BUTTON_STYLES = BUTTON_STYLES;
    ICON_SIZE = ICON_SIZE;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;

    DROPDOWN_STYLES = DROPDOWN_STYLES;
    DROPDOWN_TYPES = DROPDOWN_TYPES;
    DROPDOWN_ALIGNMENT = DROPDOWN_ALIGNMENT;
    ANALYTICS_EXPORT_OPTIONS = Utils.ANALYTICS_EXPORT_OPTIONS;
    isExportOpen: boolean = false;
    TableColumns = DbcAnalyticsUtils.TableColumns;
    cardType: DBCCardTypes;
    CARDS_FILTER_OPTIONS = CARDS_FILTER_OPTIONS;
    user: User;
    card: any = {};

    PRODUCT_TYPE = 'dbc'
    ngUnsubscribe: Subject<any> = new Subject();
    pageSize: number = 10;
    pagination: number = 0;
    clicksTableSortType = { sortBy: '', isAscending: SortTypes.ASC };
    sortType = { sortBy: '', isAscending: SortTypes.ASC };

    isMobile: boolean = false;
    Intervals = Intervals;
    upArrowIcon: string = '&uarr;'
    downArrowIcon: string = '&#8595;'

    timeZone: string;
    customDateStart: moment.Moment = moment().subtract(10, 'days').startOf('day');
    customDateEnd: moment.Moment = moment().startOf('day');
    dayDifference: number = 7;
    intervalDateData: Array<any>;
    scanByCityModalData: any = {};

    viewsActiveChartType: 'line' | 'bar' = 'line';
    usersActiveChartType: 'line' | 'bar' = 'line';
    downloadsActiveChartType: 'line' | 'bar' = 'line';

    startDate: Date = moment().subtract(10, 'days').startOf('day').toDate();
    endDate: Date = moment().endOf('day').toDate();
    customDateSelected: boolean = false;
    selectedDuration = Intervals.WEEK;
    singleDayData: boolean = true;

    marginTopViews: string;
    marginTopDownloads: string;
    marginTopPreviousDownloads: string;
    lastViewedAt: string;

    clicksDataPagination: number = 0;
    numUniqueVisitors: number = 0;
    totalDownloadsCount: number = 0;
    numUniqueVisitorsPrevious: number = 0;
    totalDownloadsCountPrevious: number = 0;
    usersComparisonPercentage: string = '0';
    impressionsComparisonPercentage: string = '0';
    currentPage: number = 1;
    totalResults: number = 0;

    lineChartOptions = DbcAnalyticsUtils.lineChartOptions;
    colorScheme = DbcAnalyticsUtils.colorScheme;

    deviceChartOptions = DbcAnalyticsUtils.deviceChartOptions;
    osDistributionData: Array<any> = [];

    userTimeOfWeekChartOptions = DbcAnalyticsUtils.userTimeOfWeekChartOptions;
    userTimeOfWeekData: any[];
    agmClusterStyleGPS: Array<any> = DbcAnalyticsUtils.agmClusterStyleGPS;
    agmClusterStyleIP: Array<any> = DbcAnalyticsUtils.agmClusterStyleIP;
    markerOptions: google.maps.MarkerOptions = {draggable: false};
    center: google.maps.LatLngLiteral = {lat: 0, lng: 0};
    addMarkerCluster: boolean = true;

    locationData = [];
    gpsLocationData = [];
    ipLocationData = [];
    performanceBody = {};
    isFetchingDownloads: boolean = true;
    downloadsError: any;
    globalDateString: string = '';
    isFetchingViews: boolean = true;
    viewsError: string = '';
    isFetchingLocationDetails: boolean = true;
    locationDetailsError: string = '';
    isFetchingOs: boolean = true;
    osDetailsError: string = '';
    isFetchingTimeByWeek: boolean = true;
    timeByWeekError: string = '';
    isFetchingViewsByCity: boolean = true;
    viewsByCityError: string = '';
    viewsOverTimeBarChartData: Array<any> = [];
    uniqueVisitsOverTimeBarChartData: Array<any> = [];
    downloadsBarChartData = [];

    defaultMobileIcons = DbcAnalyticsUtils.defaultMobileIcons
    pages = Utils.PAGINATION_VALUES;
    viewsOverTimeLineChartData = [
        { name: 'Views', series: [] },
        { name: 'Previous views', series: [] },
    ];
    uniqueVisitsOverTimeLineChartData = [
        { name: 'Present unique card views', series: [] },
        { name: 'Previous unique card views', series: [] },
    ];
    downloadsOverTimeLineChartData = [
        { name: 'Downloads', series: [] },
        { name: 'Previous Downloads', series: [] },
    ];

    downloadsOverTimeBarChartData: Array<any> = [];
    cityDataTableHeaders = DbcAnalyticsUtils.cityDataTableHeaders;
    citiesData: any = {
        header: [...this.cityDataTableHeaders],
        data: [],
    };
    citiesCSVData = [];
    citiesOriginalData = { ...this.citiesData };

    pastTotalViews: any = 0;
    presentTotalViews: any = 0;
    presentUniqueUsers: any = 0;
    pastUniqueUsers: any = 0;
    totalChangeInViews: number;
    totalChangeInUsers: number;

    dayOption: any = DbcAnalyticsUtils.dayOption;
    dayOptionSelected = this.dayOption[2];

    cardSeggregationsOptions = DbcAnalyticsUtils.cardSeggregationsOptions;
    selectedCardOption = this.cardSeggregationsOptions[0];
    hasExportAnalyticsPermission: boolean = true;
    hasWriteAccess: boolean = true;
    isIndividualCard: boolean = false;
    globalFromDate: moment.Moment;
    globalToDate: moment.Moment;
    organization: any;
    scansActiveChartType: string;
    productType = PRODUCT_TYPES.DBC;
    selectedTags = [];
    currentDateForPicker: Date = moment().startOf('day').toDate();
    isLoadingData: boolean = false;
    startDateForCustomDate: Date = moment().startOf('day').toDate();
    endDateForCustomDate: Date = moment().startOf('day').toDate();
    currentOrganizationId: number;
    productId = null;
    pageType: PageType.AGGREGATE | PageType.INDIVIDUAL;
    currentDate: moment.Moment = moment().endOf('day');
    isAdmin: boolean = true;

    queryParams: any = {};
    searchParams: SearchParams = {};
    orgHasTemplates: boolean = false;
    hasCardTemplateAccess: boolean = false;
    filteredProductIds: Array<number> = [];
    dbcTemplatesList: Array<any> = [];
    tagList = [];
    filtersChangeSubject: BehaviorSubject<any> = new BehaviorSubject('');


    constructor(
        private analyticsService: AnalyticsService,
        private authService: AuthService,
        private overlay: OverlayService,
        private router: Router,
        private digitalBusinessCardService: DigitalBusinessCardsService,
        private route: ActivatedRoute,
        private messageModal: MessageModalService,
        private amplitude: AmplitudeService,
        private intercom: IntercomService,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private tagService: TagsService,
    ) {
        this.currentOrganizationId = this.authService.getCurrentOrgId();
        this.user = this.authService.getUser();
        this.timeZone = this.user.timezone;
        if (!this.user.isSuperAdmin() && this.user.isEditor(this.currentOrganizationId)) {
            this.cardSeggregationsOptions = [{ id: '0', name: 'My Cards' }]
        }
        this.productId = route.snapshot.paramMap.get('id');
        this.organization = authService.getCurrentOrganization();
        // this.setChartSize();
        this.hasWriteAccess = authService.getUser().hasWriteAccess(this.currentOrganizationId);
        this.intercom.trackEvent('dbc-analytics-viewed');
        this.isAdmin = this.authService.getUser().isAdmin(this.authService.getCurrentOrgId());
        this.hasCardTemplateAccess = this.user.organization.dbc_feature_permissions.card_template;
        if (this.hasCardTemplateAccess) {
            this.getDigitalBusinessCardTemplateList();
        } else {
            this.CARDS_FILTER_OPTIONS = this.CARDS_FILTER_OPTIONS.filter(option => option.value !== 'card_template');
        }
    }

    ngOnInit(): void {
        this.isAnalyticsScrollEventCalled = false;
        this.route.data.subscribe(
            res => {
                if (res && res.data ) {
                    this.cardType = res.data.team ? DBCCardTypes.TEAM_CARDS : DBCCardTypes.MY_CARDS;
                } else {
                    this.cardType = DBCCardTypes.MY_CARDS;
                }
            }
        )
        this.route.params.subscribe(
            (params: Params) => {
                if ( params.id ){
                    this.isIndividualCard = true
                    this.getDBCWithId(params.id)
                } else {
                    this.isIndividualCard = false
                    this.selectDuration();
                    this.onFilterChange({ ...params })
                }
            }
        )
        this.isMobile = window.innerWidth < 1057;
        this.hasExportAnalyticsPermission = this.authService.getCurrentOrganization().hasAnalyticsExportAccess();
        if (this.cardType === DBCCardTypes.TEAM_CARDS && (!this.authService.getUser().isSuperAdmin() && this.authService.getUser().isEditor(this.currentOrganizationId))) {
            this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
        }
        this.overlay.isLoading(false);
        this.pageType = this.isIndividualCard ? PageType.INDIVIDUAL : PageType.AGGREGATE;
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Engagement, 'view card analytics', {
            page: this.pageType,
        });
        this.filtersChangeSubject.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(), debounceTime(500)).subscribe(res => {
            this.queryParams = {
                filters: res
            };
        })
        this.fetchLabels();
    }

    isAnalyticsScrollEventCalled: boolean = false;
    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
        const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight - 15 && !this.isAnalyticsScrollEventCalled) {
            this.analyticsPageEventToAmplitude(ANALYTICS_PAGE_EVENTS.ANALYTICS_PAGE_SCROLL_DBC);
            this.isAnalyticsScrollEventCalled = true;
        }
    }
    analyticsPageEventToAmplitude(source: ANALYTICS_PAGE_EVENTS) {
        const eventProperties = {
            source: source
        }
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.ANALYTICS_PAGE_SCROLL_DBC, eventProperties);
    }
    private getDBCWithId(id: any) {
        const owner = this.cardType === DBCCardTypes.MY_CARDS ? 'card_owner__is' : 'card_owner__not';
        this.digitalBusinessCardService.getDetail(id, `?organization=${this.currentOrganizationId}&${owner}=${this.user.id}`)
            .pipe().subscribe(res => {
                this.card['url'] = res.url;
                this.card['id'] = res.id;
                this.card['created'] = res.created;
                this.selectDuration();
            }, (err) => {
                const maintainerQuery = this.cardType === DBCCardTypes.MY_CARDS ? 'my-cards' : 'team-cards';
                this.messageModal.show('Error fetching analytics', 'danger')
                this.router.navigate(['/digital-business-cards/' + maintainerQuery], { relativeTo: this.route })
            });
    }


    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth < 1057;
        setTimeout(() => {
            this.setChartsSizes();
        }, 100)
    }

    fetchLabels() {
        const params: SearchParams = {
            'product_type': 'dbc',
            'label_type': 'dbc'
        };
        this.tagService
            .getList(1, 50, params,  true, 'updated')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (result) => {
                    this.tagList = result.objects;
                },
                (error) => {
                    this.messageModal.show('Error fetching labels. Please try refreshing the page.', 'danger');
                },
            );
    }

    getDigitalBusinessCardTemplateList() {
        this.digitalBusinessCardTemplateService.getList(1, 50).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.orgHasTemplates = res.totalCount > 0;
            this.dbcTemplatesList = res.objects;
            if (res.totalCount === 0) {
                this.CARDS_FILTER_OPTIONS = this.CARDS_FILTER_OPTIONS.filter(option => option.value !== 'card_template');
            }
        })
    }

    getFilterValues(filters): string[]{
        const filterNames = Object.keys(filters).map(filter => filter.split('_')[0]);
        return filterNames
    }

    onFilterChange(searchParams: SearchParams) {
        this.searchParams = searchParams;
        this.handleFilters(this.searchParams);
        this.addQueryParams(this.searchParams);
    }

    handleFilters(searchParams: SearchParams) {
        let filterValues;
        if (searchParams['filters']){
            filterValues = { ...filterValues, ...JSON.parse(atob(searchParams['filters'] as string)) };
        }
        if (this.selectedCardOption.id === String(DBCCardTypes.MY_CARDS)) {
            filterValues = { ...filterValues, 'card_owner__is': this.user.id };
        } else {
            delete filterValues['card_owner__is'];
        }
        this.searchParams = { ...searchParams, 'filters': btoa(JSON.stringify(filterValues)) }

        const params = `organization=${this.currentOrganizationId}&filters=${this.searchParams['filters']}`
        this.overlay.isLoading(true);
        this.analyticsService.getFilteredDBCIds(params)
            .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.overlay.isLoading(false)))
            .subscribe((response) => {
                this.filteredProductIds = response.map(object => object.id);
                this.filteredProductIds.length ? this.getAnalytics() : this.resetAnalyticsData();

                const filterNames = this.getFilterValues(filterValues);
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.ANALYTICS_FILTERS_DBC, {
                    filter_type: filterNames
                });
            }, (console.error));
    }

    addQueryParams(newParams) {
        const currentParams = { ...this.route.snapshot.queryParams };
        const filteredCurrentParams = Object.keys(currentParams)
            .filter((key) => !['filters'].includes(key))
            .reduce((obj, key) => {
                obj[key] = currentParams[key];
                return obj;
            }, {});
        const mergedParams = { ...filteredCurrentParams, ...newParams };

        const url = new URL(window.location.href);
        if (mergedParams.filters){
            url.searchParams.delete('filters');
            url.searchParams.append('filters', mergedParams.filters);
        }
        window.history.pushState(null, '', url.toString());
    }

    private setChartsSizes() {
        this.setPieChartSize()
        this.setHeatMapChartSize();
        this.setLineChartSize();
        this.setBarChartSize();
    }

    onChangeCardSeggregation(event) {
        this.selectedCardOption = event;
        this.onFilterChange(this.searchParams);
    }

    redirectToPreview() {
        window.open(this.card.url + '?preview=true', '_blank');
    }
    redirectToEdit() {
        const cardType = this.cardType === DBCCardTypes.MY_CARDS ? 'my-cards' : 'team-cards';
        this.router.navigate([`/digital-business-cards/${cardType}/edit/${this.card.id}`], { relativeTo: this.route })
    }
    copyToClipboard(link: string) {
        navigator.clipboard.writeText(link);
        this.messageModal.show('Link copied to clipboard', 'success');
    }

    selectDuration(duration?: any) {
        if (duration) {
            this.selectedDuration = duration.id;
        }
        const { TODAY, YESTERDAY, WEEK, MONTH, QUARTER, CUSTOM } = Intervals;
        if (this.selectedDuration !== CUSTOM ) {
            const date = moment().tz(this.timeZone);
            this.globalToDate = moment().tz(this.timeZone).endOf('day')

            switch (this.selectedDuration) {
                case TODAY:
                    this.globalFromDate = date.subtract(this.selectedDuration, 'day').startOf('day');
                    this.globalDateString = `${getFormattedDate(this.globalFromDate)}`;
                    break;
                case YESTERDAY:
                    this.globalFromDate = date.subtract(1, 'day').startOf('day');
                    this.globalToDate = moment().tz(this.timeZone).startOf('day');
                    this.globalDateString = `${getFormattedDate(this.globalFromDate)}`;
                    break;
                case WEEK:
                    this.globalFromDate = date.subtract(this.selectedDuration - 1, 'day').startOf('day');
                    this.globalDateString = `${getFormattedDate(this.globalFromDate)} to ${getFormattedDate(this.globalToDate)}`;
                    break;
                case MONTH:
                    this.globalFromDate = date.subtract(this.selectedDuration - 1, 'day').startOf('day');
                    this.globalDateString = `${getFormattedDate(this.globalFromDate)} to ${getFormattedDate(this.globalToDate)}`;
                    break;
                case QUARTER:
                    this.globalFromDate = date.subtract(this.selectedDuration - 1, 'day').startOf('day');
                    this.globalDateString = `${getFormattedDate(this.globalFromDate)} to ${getFormattedDate(this.globalToDate)}`;
                    break;
                default:
                    break;
            }

            this.getAnalytics();

        } else {
            this.customDateSelected = true;
        }
    }


    sortTable(param: string) {
        this.isFetchingViewsByCity = true
        if (this.sortType.sortBy === param) {
            this.sortType.isAscending = (this.sortType.isAscending === SortTypes.ASC ? SortTypes.DSC : SortTypes.ASC);
        } else {
            this.sortType.sortBy = param;
        }

        const citiesData = this.citiesData.data;
        this.citiesData.data = [];

        const sortTypes = {
            [this.TableColumns.CITY]: 'city',
            [this.TableColumns.STATE]: 'state',
            [this.TableColumns.COUNTRY]: 'country',
            [this.TableColumns.VIEWS]: 'views',
            [this.TableColumns.DOWNLOADS]: 'downloads',
            [this.TableColumns.PERCENTAGE]: 'percentage',
        }
        const key = sortTypes[param] || '';
        if (key) {
            this.citiesData.data = [...citiesData.sort((a, b) => getSortedTableValue(a, b, key, this.sortType.isAscending))];
        }
        this.isFetchingViewsByCity = false;
    }

    private getAnalytics(startDate?, endDate?) {
        this.isFetchingViews = true;
        this.viewsError = '';
        this.isFetchingLocationDetails = true;
        this.locationDetailsError = '';
        this.isFetchingOs = true;
        this.osDetailsError = '';
        this.isFetchingTimeByWeek = true;
        this.timeByWeekError = '';
        this.isFetchingViewsByCity = true;
        this.viewsByCityError = '';
        this.isFetchingDownloads = true;
        this.downloadsError = '';

        this.viewsOverTimeBarChartData = [];
        this.uniqueVisitsOverTimeBarChartData = [];
        this.downloadsBarChartData = [];

        this.viewsOverTimeLineChartData = [
            { name: 'Views', series: [] },
            { name: 'Previous views', series: [] },
        ];
        this.uniqueVisitsOverTimeLineChartData = [
            { name: 'Present unique card views', series: [] },
            { name: 'Previous unique card views', series: [] },
        ];
        this.downloadsOverTimeLineChartData = [
            { name: 'Downloads', series: [] },
            { name: 'Previous Downloads', series: [] },
        ];

        this.downloadsOverTimeBarChartData = [];

        let toDate;
        let fromDate;
        if (this.selectedDuration === Intervals.CUSTOM && this.globalFromDate && this.globalToDate) {
            fromDate = this.globalFromDate.clone().startOf('day')
            toDate = this.globalToDate.clone().endOf('day')
            this.singleDayData = this.globalToDate.diff(fromDate, 'days') < 1
        } else {
            const currentDate = moment().tz(this.user.timezone).endOf('day')
            toDate = this.selectedDuration === Intervals.YESTERDAY ?
                currentDate.clone().subtract(1, 'day').endOf('day') :
                currentDate.clone().endOf('day')
            fromDate = toDate.clone().subtract(this.selectedDuration - 1, 'day').startOf('day')
            this.singleDayData = this.selectedDuration === Intervals.TODAY || this.selectedDuration === Intervals.YESTERDAY;
        }
        switch (this.selectedDuration) {
            case Intervals.TODAY:
                fromDate = toDate.clone().subtract(this.selectedDuration, 'day').startOf('day');
                break
            case Intervals.YESTERDAY:
                fromDate = toDate.clone().startOf('day');
                break;
            case Intervals.WEEK:
                fromDate = toDate.clone().subtract(this.selectedDuration - 1, 'day').startOf('day');
                break;
            case Intervals.MONTH:
                fromDate = toDate.clone().subtract(this.selectedDuration - 1, 'day').startOf('day');
                break;
            case Intervals.QUARTER:
                fromDate = toDate.clone().subtract(this.selectedDuration - 1, 'day').startOf('day');
                break;
        }
        this.globalDateString = this.singleDayData ?
            getFormattedDate(fromDate.clone()) :
            getFormattedDate(fromDate.clone()) + ' - ' + getFormattedDate(toDate.clone());

        const performanceBody = {
            from: fromDate.clone().startOf('day').format('x'),
            interval: this.singleDayData ? '30m' : '1d',
            timezone: this.user.timezone,
            to: toDate.clone().endOf('day').format('x'),
            product_id: this.card.id, // TODO : remove for aggregate
            card_type: +this.selectedCardOption.id,
            card_owner: this.user.id,
            product_ids: this.filteredProductIds
        }

        const dayDifference = getProcessedDayDifference(this.selectedDuration, moment(toDate).clone(), moment(fromDate).clone(), this.PRODUCT_TYPE);
        const interval = dayDifference <= 1 ? '30m' : '1d';
        this.dayDifference = dayDifference;

        this.intervalDateData = [];
        const intervalData = [];
        const date = fromDate.clone().tz(this.user.timezone).startOf('day');

        if (dayDifference <= 1) {
            intervalData.push(date.format('HH:mm'));
            this.intervalDateData.push(Number.parseInt(date.format('x')));
            const duration = 48;
            for (let day = 1; day <= duration; day++) {
                day === duration ? date.add(.49, 'hour') : date.add(.5, 'hour');
                intervalData.push(date.format('HH:mm'));
                this.intervalDateData.push(Number.parseInt(date.format('x')));
            }
        } else {
            intervalData.push(getFormattedDate(date));
            this.intervalDateData.push(Number.parseInt(date.format('x')));
            for (let day = 1; day < dayDifference; day++) {
                date.add(1, 'day');
                intervalData.push(getFormattedDate(date));
                this.intervalDateData.push(Number.parseInt(date.format('x')));
            }
        }

        this.getDownloadsData(performanceBody, fromDate, toDate, interval, intervalData);
        this.getVisitorDistribution(performanceBody, fromDate, toDate, interval, intervalData);
        this.getOsDistribution(performanceBody);
        this.getCityDistribution(performanceBody);
        this.getTimeOfDayDistribution(performanceBody);
        this.getLocationDetail(performanceBody);
        this.performanceBody = performanceBody;
    }

    private resetAnalyticsData() {
        this.pastTotalViews = 0;
        this.pastUniqueUsers = 0;
        this.totalChangeInViews = Number.parseFloat('0');
        this.totalChangeInUsers = Number.parseFloat('0');
        this.timeByWeekError = 'No data to show';
        this.osDetailsError = 'No data to show';
        this.citiesOriginalData.data = [];
        this.osDistributionData = [];
        this.presentTotalViews = 0
        this.presentUniqueUsers = 0;
        this.isFetchingViews = false;
        this.isFetchingLocationDetails = false;
        this.locationDetailsError = 'No data to show';
        this.isFetchingOs = false;
        this.isFetchingTimeByWeek = false;
        this.isFetchingViewsByCity = false;
        this.viewsByCityError = '';
        this.isFetchingDownloads = false;
        this.downloadsError = '';
        this.clicksDataPagination = 0;
        this.numUniqueVisitors = 0;
        this.totalDownloadsCount = 0;
        this.numUniqueVisitorsPrevious = 0;
        this.totalDownloadsCountPrevious = 0;
        this.usersComparisonPercentage = '0';
        this.impressionsComparisonPercentage = '0';

        this.viewsOverTimeBarChartData = [];
        this.uniqueVisitsOverTimeBarChartData = [];
        this.downloadsBarChartData = [];

        this.viewsOverTimeLineChartData = [
            { name: 'Views', series: [] },
            { name: 'Previous views', series: [] },
        ];
        this.uniqueVisitsOverTimeLineChartData = [
            { name: 'Present unique card views', series: [] },
            { name: 'Previous unique card views', series: [] },
        ];
        this.downloadsOverTimeLineChartData = [
            { name: 'Downloads', series: [] },
            { name: 'Previous Downloads', series: [] },
        ];

        this.downloadsOverTimeBarChartData = [];

        let toDate;
        let fromDate;
        if (this.selectedDuration === Intervals.CUSTOM && this.globalFromDate && this.globalToDate) {
            fromDate = this.globalFromDate.clone().startOf('day')
            toDate = this.globalToDate.clone().endOf('day')
            this.singleDayData = this.globalToDate.diff(fromDate, 'days') < 1
        } else {
            const currentDate = moment().tz(this.user.timezone).endOf('day')
            toDate = this.selectedDuration === Intervals.YESTERDAY ?
                currentDate.clone().subtract(1, 'day').endOf('day') :
                currentDate.clone().endOf('day')
            fromDate = toDate.clone().subtract(this.selectedDuration - 1, 'day').startOf('day')
            this.singleDayData = this.selectedDuration === Intervals.TODAY || this.selectedDuration === Intervals.YESTERDAY;
        }

        const dayDifference = getProcessedDayDifference(this.selectedDuration, moment(toDate).clone(), moment(fromDate).clone(), this.PRODUCT_TYPE);
        const interval = dayDifference <= 1 ? '30m' : '1d';
        this.dayDifference = dayDifference;

        this.intervalDateData = [];
        const intervalData = [];
        const date = fromDate.clone().tz(this.user.timezone).startOf('day');

        if (dayDifference <= 1) {
            intervalData.push(date.format('HH:mm'));
            this.intervalDateData.push(Number.parseInt(date.format('x')));
            const duration = 48;
            for (let day = 1; day <= duration; day++) {
                day === duration ? date.add(.49, 'hour') : date.add(.5, 'hour');
                intervalData.push(date.format('HH:mm'));
                this.intervalDateData.push(Number.parseInt(date.format('x')));
            }
        } else {
            intervalData.push(getFormattedDate(date));
            this.intervalDateData.push(Number.parseInt(date.format('x')));
            for (let day = 1; day < dayDifference; day++) {
                date.add(1, 'day');
                intervalData.push(getFormattedDate(date));
                this.intervalDateData.push(Number.parseInt(date.format('x')));
            }
        }

        const { payload } = getPreviousPayloadBody(this.selectedDuration, {
            from: fromDate,
            to: toDate,
            timezone: this.timeZone,
            interval,
            format: 'x',
        });

        this.setupCurrentAnalytics([[], 0], fromDate, toDate, intervalData);
        this.setupPreviousAnalytics([[], 0], payload['from'], payload['to'], intervalData);
        this.setUpDBCPerformancePresent([], fromDate, toDate, intervalData);
        this.setUpDBCPerformancePrevious([], payload['from'], payload['to'], intervalData);
        this.setBarChartSize();
    }

    private  getDownloadsData(performanceBody, fromDate, toDate, interval, intervalData) {
        this.downloadsBarChartData = []
        const { payload } = getPreviousPayloadBody(this.selectedDuration, {
            from: fromDate,
            to: toDate,
            timezone: this.timeZone,
            interval,
            format: 'x',
            product_id: this.card.id,
            product: this.PRODUCT_TYPE,
            product_ids: this.filteredProductIds
        });
        payload['interval'] = this.singleDayData ? '30m' : '1d';
        payload['card_type'] = +this.selectedCardOption.id;
        payload['card_owner'] = this.user.id;
        payload['product_ids'] = this.filteredProductIds;

        const getDBCPerformance = this.analyticsService.getAnalytics('Dbc.getCardDownloadsDistributionV3', performanceBody, this.PRODUCT_TYPE)
        const getDBCPerformancePreviousPeriod = this.analyticsService.getAnalytics('Dbc.getCardDownloadsDistributionV3', payload, this.PRODUCT_TYPE)

        forkJoin([getDBCPerformance, getDBCPerformancePreviousPeriod])
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
                this.setUpDBCPerformancePresent(res[0]['points'], fromDate, toDate, intervalData);
                this.setUpDBCPerformancePrevious(res[1]['points'], payload['from'], payload['to'], intervalData);
                this.setBarChartSize();
            }, () => {
                this.downloadsError = 'Error fetching data.';
            });
    }

    private getVisitorDistribution(performanceBody, fromDate, toDate, interval, intervalData) {
        const { payload } = getPreviousPayloadBody(this.selectedDuration, {
            from: fromDate,
            to: toDate,
            timezone: this.timeZone,
            interval,
            format: 'x',
            product_id: this.card.id,
            product: this.PRODUCT_TYPE,
            product_ids: this.filteredProductIds
        });
        payload['interval'] = this.singleDayData ? '30m' : '1d';
        payload['card_type'] = +this.selectedCardOption.id;
        payload['card_owner'] = this.user.id;
        payload['product_ids'] = this.filteredProductIds;

        const currentDurationAnalytics = this.analyticsService.getAnalytics('Dbc.getVisitorDistributionV3', performanceBody, this.PRODUCT_TYPE);
        const previousDurationAnalytics = this.analyticsService.getAnalytics('Dbc.getVisitorDistributionV3', payload, this.PRODUCT_TYPE);

        forkJoin([currentDurationAnalytics, previousDurationAnalytics])
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.isFetchingViews = false;
                    this.isFetchingDownloads = false;
                }))
            .subscribe(res => {
                this.setupCurrentAnalytics(res[0]['points'], fromDate, toDate, intervalData);
                this.setupPreviousAnalytics(res[1]['points'], payload['from'], payload['to'], intervalData);
            }, () => this.viewsError = 'Error fetching data.');
    }

    private getOsDistribution(performanceBody) {
        this.osDistributionData = []
        this.analyticsService.getAnalytics('Dbc.getOsDistributionV3', performanceBody, this.PRODUCT_TYPE)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => this.isFetchingOs = false))
            .subscribe(({ columns, points }) => {
                const sum = points?.reduce((a, b) => +a + b, 0) || 0;
                this.osDistributionData = points?.map((point, index) => {
                    return {
                        name: columns[index],
                        value: +point,
                        percentage: ((+point / sum) * 100).toFixed(2),
                    }
                }).sort((a, b) => a.value - b.value) || [];

                this.osDistributionData = this.osDistributionData.map((item, index) => {
                    return { ...item, color: this.colorScheme.domain[index], }
                });

                if (this.osDistributionData.length === 0) {
                    this.osDetailsError = 'No data to show';
                }
                this.setPieChartSize();
            }, () => {
                this.osDetailsError = 'Error fetching data.';
            });
    }

    /**
   * method : Dbc.getCityDistribution
   * response :
   * {
      "columns": [  "Bengaluru, Karnataka, India" ],
      "points": [ [ 12, 8 ] ]
      }
       "Bengaluru, Karnataka, India" has 12 views and 8 downloads
   */
    private getCityDistribution(performanceBody) {
        this.analyticsService.getAnalytics('Dbc.getCityDistributionV3', performanceBody, this.PRODUCT_TYPE)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => this.isFetchingViewsByCity = false))
            .subscribe(res => {
                this.citiesOriginalData.data = [];
                const totalViews = res['points'].map(arr => arr[0])?.reduce((a, b) => a + b, 0) || 0;
                this.totalResults = res['points'].length;

                for (const city in res['columns']) {
                    const locationInfo = res['columns'][city].split(', ');
                    if (locationInfo[0] === 'None') {
                        locationInfo[0] = null;
                    }
                    if (locationInfo[1] === 'None') {
                        locationInfo[1] = null;
                    }
                    if (locationInfo[2] === 'None') {
                        locationInfo[2] = null;
                    }

                    const dataItems = {
                        city: {
                            type: 'single',
                            mobile: true,
                            value: { text: locationInfo[0] || 'Unknown' },
                        },
                        state: {
                            type: 'single',
                            value: { text: locationInfo[1] || 'Unknown' },
                        },
                        country: {
                            type: 'single',
                            value: { text: locationInfo[2] || 'Unknown' },
                        },
                        views: {
                            type: 'single',
                            value: { text: res['points'][city][0].toLocaleString() },
                        },
                        downloads: {
                            type: 'single',
                            value: { text: res['points'][city][1].toLocaleString() },
                        },
                        percentage: {
                            type: 'single',
                            value: { text: `${((res['points'][city][0] / totalViews) * 100).toFixed(1)}%` },
                        },
                    }
                    this.citiesOriginalData.data.push({
                        dataObject: dataItems,
                        dataItems, toolbarIcons: {
                            mobile: this.defaultMobileIcons
                        }
                    });
                }
                this.citiesOriginalData.data = this.citiesOriginalData.data.sort((a, b) =>
                    a.dataObject.views.value.text > b.dataObject.views.value.text ? -1 : a.dataObject.views.value.text < b.dataObject.views.value.text ? 1 : 0
                );

                this.pagination = Math.ceil(this.citiesOriginalData.data.length / this.pageSize) || 0;
                this.totalResults = this.citiesOriginalData.data.length;

                this.onPageChange();
            }, () => {
                this.viewsByCityError = 'Error fetching data.'
            })

    }

    private getTimeOfDayDistribution(performanceBody) {
        this.userTimeOfWeekData = []
        this.analyticsService.getAnalytics('Dbc.getTimeOfDayDistributionV3', performanceBody, this.PRODUCT_TYPE)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => this.isFetchingTimeByWeek = false))
            .subscribe(res => {
                let userWeekTimedata = [];
                const userTimedata = [];
                let timeOfTheDayDataExists = false;

                for (let j = 0; j <= 6; j++) {
                    userWeekTimedata = [];
                    for (let i = 0; i <= 23; i++) {
                        if (res.points[j][i]) {
                            timeOfTheDayDataExists = true
                            userWeekTimedata.push({ 'name': getTimeofDay(i), 'value': res.points[j][i] })
                        } else {
                            userWeekTimedata.push({ 'name': getTimeofDay(i), 'value': 0 })
                        }
                    }
                    userTimedata.push({ 'name': getWeekDayName(j), 'series': userWeekTimedata });
                }

                this.userTimeOfWeekData = userTimedata;
                if (timeOfTheDayDataExists) {
                    this.setHeatMapChartSize()
                } else {
                    this.timeByWeekError = 'No data to show'
                }
                this.overlay.isLoading(false);
            }, () => {
                this.timeByWeekError = 'Error fetching data'
            });
    }

    private getLocationDetail(performanceBody) {
        this.locationData = []
        this.gpsLocationData = []
        this.ipLocationData = []
        this.analyticsService.getAnalytics('Dbc.getLocationDetailV3', performanceBody, this.PRODUCT_TYPE)
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => this.isFetchingLocationDetails = false))
            .subscribe(res => {
                res['points'].forEach(position => {
                    if (position[1][0]) {
                        const coordinates = { lat: position[1][0], lng: position[1][1] };
                        this.locationData.push(coordinates);
                        this.gpsLocationData.push(coordinates);
                    } else if (position[0][0]) {
                        const coordinates = { lat: position[0][0], lng: position[0][1] };
                        this.locationData.push(coordinates);
                        this.ipLocationData.push(coordinates);
                    }
                });
                this.locationDetailsError = !this.locationData.length ? 'No data to show' : null;

                if ( this.locationData.length ) {
                    this.center = {
                        lat: this.locationData[0]['lat'],
                        lng: this.locationData[0]['lng'],
                    }
                }
            }, () => {
                this.locationDetailsError = 'Error fetching data.';
            })

    }

    private setupCurrentAnalytics(points, fromDate, toDate, intervalData) {
        let presentTotalViews = 0
        let presentUniqueUsers = 0;
        presentUniqueUsers = points[1]
        points[0]?.forEach((_, i) => {
            presentTotalViews += points[0][i][1];
        });

        this.presentTotalViews = getFormattedNumber(presentTotalViews);
        this.presentUniqueUsers = getFormattedNumber(presentUniqueUsers);
        const viewsData = [];
        const userData = [];

        const dates = getDatesInRange({
            startDate: fromDate,
            endDate: toDate,
            timeZone: this.user.timezone,
            dayDifference: this.dayDifference,
            product: this.PRODUCT_TYPE
        })

        const intervalsFromServer = points[0]?.map((int => int[0])) || [];
        dates.forEach((d) => {
            const pos = intervalsFromServer.indexOf(Number.parseInt(d))
            if (pos === -1) {
                viewsData.push(0)
                userData.push(0)
            } else {
                viewsData.push(points[0][pos][1])
                userData.push(points[0][pos][2])
            }
        })

        const barChartData = [];
        const uniqueData = [];

        for (const point of points) {
            for (let i = 0; i < point.length; i++) {
                barChartData[this.intervalDateData.indexOf(point[i][0])] = point[i][1];
                uniqueData[this.intervalDateData.indexOf(point[i][0])] = point[i][2];
            }
        }

        for (let i = 0; i < viewsData.length; i++) {
            this.viewsOverTimeLineChartData[0]['series'].push({ 'name': intervalData[i], 'value': viewsData[i] });
            this.uniqueVisitsOverTimeLineChartData[0]['series'].push({ 'name': intervalData[i], 'value': userData[i] });
        }
        intervalData.forEach((name, index) => {
            this.viewsOverTimeBarChartData.push({ name, series: [{ name: 'present', value: barChartData[index] || 0 }] });
            this.uniqueVisitsOverTimeBarChartData.push({ name, series: [{ name: 'present', value: uniqueData[index] || 0 }] });
        });
    }

    private setupPreviousAnalytics(points, fromDate, toDate, intervalData) {
        let [pastTotalViews, pastUniqueUsers] = [0, 0];
        pastUniqueUsers = points[1]
        points[0]?.forEach((_, i) => {
            pastTotalViews += points[0][i][1];
        });

        this.pastTotalViews = getFormattedNumber(pastTotalViews);
        this.pastUniqueUsers = getFormattedNumber(pastUniqueUsers);

        this.totalChangeInViews = Number.parseFloat(calculatePercentageChange(this.presentTotalViews, this.pastTotalViews) + '');
        this.totalChangeInUsers = Number.parseFloat(calculatePercentageChange(this.presentUniqueUsers, this.pastUniqueUsers) + '');

        const viewsData = [];
        const userData = [];
        const dates = getDatesInRange({
            startDate: moment.unix(Math.floor(fromDate / 1000)),
            endDate: moment.unix(Math.floor(toDate / 1000)),
            timeZone: this.timeZone,
            dayDifference: this.dayDifference,
            product: this.PRODUCT_TYPE
        });

        const intervalsFromServer = points[0]?.map((int => int[0])) || [];
        dates.forEach((date) => {
            const pos = intervalsFromServer.indexOf(Number.parseInt(date))
            if (pos === -1) {
                viewsData.push(0)
                userData.push(0)
            } else {
                viewsData.push(points[0][pos][1])
                userData.push(points[0][pos][2])
            }
        })

        for (let i = 0; i < this.intervalDateData.length; i++) {
            this.viewsOverTimeLineChartData[1]['series'].push({ name: intervalData[i], value: viewsData[i] || 0 });
            this.uniqueVisitsOverTimeLineChartData[1]['series'].push({ name: intervalData[i], value: userData[i] || 0 });
        }

        intervalData.forEach((_, i) => {
            this.viewsOverTimeBarChartData[i].series.push({ name: 'previous', value: viewsData[i] });
            this.uniqueVisitsOverTimeBarChartData[i].series.push({ name: 'previous', value: userData[i] });
        });
        this.isFetchingViews = false;
        this.setLineChartSize();
    }

    private setUpDBCPerformancePresent(getDBCPerformanceData: any, fromDate, toDate, intervalData) {
        let totalDownloads = 0;
        getDBCPerformanceData.forEach(a => totalDownloads += a[1]);
        this.totalDownloadsCount = totalDownloads;

        const downloadsData = [];
        const currentDates = getDatesInRange({
            startDate: fromDate,
            endDate: toDate,
            timeZone: this.user.timezone,
            dayDifference: this.dayDifference,
            product: this.PRODUCT_TYPE
        });

        const curentIntervalsFromServer = getDBCPerformanceData?.map((int => int[0])) || [];
        currentDates.forEach((d) => {
            const pos = curentIntervalsFromServer.indexOf(Number.parseInt(d))
            pos === -1 ? downloadsData.push(0) : downloadsData.push(getDBCPerformanceData[pos][1]);
        })

        for (let i = 0; i < downloadsData.length; i++) {
            this.downloadsOverTimeLineChartData[0]['series'].push({ 'name': intervalData[i], 'value': downloadsData[i] || 0 });
        }
        intervalData.forEach((name, index) => {
            this.downloadsBarChartData.push({ name, series: [{ name: 'present', value: downloadsData[index] || 0 }] });
        });
    }

    private setUpDBCPerformancePrevious(getDBCPerformanceData: any, fromDate, toDate, intervalData) {
        let totalDownloads = 0;
        getDBCPerformanceData.forEach(a => totalDownloads += a[1]);
        this.totalDownloadsCountPrevious = totalDownloads;
        this.impressionsComparisonPercentage = calculatePercentageChange(this.totalDownloadsCount, this.totalDownloadsCountPrevious) + '';

        const downloadsData = [];
        const dates = getDatesInRange({
            startDate: moment.unix(Math.floor(fromDate / 1000)),
            endDate: moment.unix(Math.floor(toDate / 1000)),
            timeZone: this.timeZone,
            dayDifference: this.dayDifference,
            product: this.PRODUCT_TYPE
        });

        const intervalsFromServer = getDBCPerformanceData?.map((int => int[0])) || [];
        dates.forEach((d) => {
            const pos = intervalsFromServer.indexOf(Number.parseInt(d))
            pos === -1 ? downloadsData.push(0) : downloadsData.push(getDBCPerformanceData[pos][1]);
        })

        for (let i = 0; i < downloadsData.length; i++) {
            this.downloadsOverTimeLineChartData[1]['series'].push({ 'name': intervalData[i], 'value': downloadsData[i] || 0 });
        }

        intervalData.forEach((_, i) => {
            this.downloadsBarChartData[i].series.push({ name: 'previous', value: downloadsData[i] || 0 });
        });
    }

    applyCustomDate() {
        if (this.startDate > this.currentDate) {
            this.messageModal.show('Start date cannot be greater than current date', 'warning');
        } else if (this.endDate > this.currentDate) {
            this.messageModal.show('End date cannot be greater than current date', 'warning');
        } else if (this.startDate >= this.endDate) {
            this.messageModal.show('Start cannot be greater than or equal to end date', 'warning');
        } else {
            this.customDateSelected = false;
            this.globalFromDate = moment(this.startDate).tz(this.timeZone).startOf('day');
            this.globalToDate = moment(this.endDate).tz(this.timeZone).endOf('day');
            const dayDifference = this.globalToDate.diff(this.globalFromDate, 'days');
            if (dayDifference >= 365) {
                this.usersActiveChartType = 'line';
                this.scansActiveChartType = 'line';
            }

            if (dayDifference >= 1) {
                this.globalDateString = `${getFormattedDate(this.globalFromDate)} to ${getFormattedDate(this.globalToDate)}`;
            } else {
                this.globalDateString = getFormattedDate(this.globalFromDate);
            }

            this.getAnalytics(moment(this.startDate).tz(this.timeZone).endOf('day'), moment(this.endDate).tz(this.timeZone).endOf('day'));
        }
    }

    onPageChange(pageNumber: number = 1) {
        const { pageSize, citiesOriginalData } = this;
        const trimStart = (pageNumber - 1) * pageSize;
        const trimEnd = trimStart + +pageSize;
        this.citiesData.data = citiesOriginalData.data.slice(trimStart, trimEnd);
        this.isFetchingViewsByCity = false;
        this.currentPage = pageNumber;
    }

    changePageSize(pageSize: number) {
        this.isFetchingViewsByCity = true
        pageSize = pageSize['value'];
        this.pageSize = pageSize;
        this.onPageChange(1);
    }

    private setLineChartSize() {
        const lineChart = document.getElementById('line-chart');
        if (lineChart) {
            this.lineChartOptions.view = [lineChart.offsetWidth - 40, 300]
            this.marginTopViews = (document.getElementById('views-section').offsetHeight - document.getElementById('views-section-tag').offsetHeight - 5) + 'px';

        } else {
            setTimeout(() => {
                this.setLineChartSize()
            }, 100)
        }
    }

    private setPieChartSize() {
        const devicesChart = document.getElementById('devices-chart');
        if (devicesChart) {
            this.deviceChartOptions.view = [devicesChart.offsetWidth - 40, 300]
        } else {
            setTimeout(() => {
                this.setPieChartSize()
            }, 100)
        }
    }

    private setHeatMapChartSize() {
        const timeOfTheWeek = document.getElementById('time-of-the-week');
        if (timeOfTheWeek) {
            this.userTimeOfWeekChartOptions.view = [timeOfTheWeek.offsetWidth - 40, 580];
        } else {
            setTimeout(() => {
                this.setHeatMapChartSize()
            }, 100)
        }
    }

    private setBarChartSize() {
        const barChart = document.getElementById('bar-chart');
        const clicksSection = document.getElementById('clicks-section');
        if (barChart && clicksSection) {
            this.lineChartOptions.view = [barChart.offsetWidth - 40, 300];
            this.marginTopDownloads = (clicksSection.offsetHeight - document.getElementById('clicks-section-tag').offsetHeight - 5) + 'px';
            this.marginTopPreviousDownloads = (document.getElementById('unique-clicks-period').offsetHeight - document.getElementById('unique-clicks-period-tag').offsetHeight - 5) + 'px';
        } else {
            setTimeout(() => {
                this.setBarChartSize()
            }, 100)
        }
    }

    handleToolbarClick({ dataObject }): void {
        this.scanByCityModalData = dataObject;
        this.mobileModal.show();
    }

    getPercentChange(current: number, previous: number) {
        const result = calculatePercentageChange(current, previous)
        return result;
    }

    getDate(date: string) {
        if (this.selectedDuration === Intervals.YESTERDAY) {
            return date;
        }
        let newDate: moment.Moment = moment(date, DATE_FORMAT.CHART_INTERVAL_FORMAT);
        if (this.selectedDuration === Intervals.TODAY) {
            const difference = this.customDateEnd.diff(this.customDateStart, 'days');
            if (difference !== 1) {
                newDate = newDate.subtract(difference, 'days');
            } else {
                return date;
            }
        } else {
            newDate = newDate.subtract(this.selectedDuration, 'day');
        }
        return newDate.format(DATE_FORMAT.CHART_INTERVAL_FORMAT);
    }


    downloadDownloadsCSV() {
        const downloadsCSV = [];
        for (const download of this.downloadsOverTimeLineChartData[0]['series']) {
            downloadsCSV.push({ 'Date': download['name'].split(',').join(' '), 'Contact saves': download['value'] })
        }
        Utils.downloadCSV(downloadsCSV, `Digital business card saves aggregate report - (${this.startDate} to ${this.endDate}).csv`);
    }

    downloadViewsCSV() {
        const viewsCSV = [];
        for (const day of this.viewsOverTimeLineChartData[0]['series']) {
            viewsCSV.push({ 'Date': day['name'].split(',').join(' '), 'Card views': day['value'] });
        }
        Utils.downloadCSV(viewsCSV, `Digital business card views aggregate report - (${this.startDate} to ${this.endDate}).csv`);
    }

    downloadUsersCSV() {
        const usersCSV = [];
        for (const user of this.uniqueVisitsOverTimeLineChartData[0]['series']) {
            usersCSV.push({ 'Date': user['name'].split(',').join(' '), 'Unique card views': user['value'] });
        }
        Utils.downloadCSV(usersCSV, `Digital business card unique card views aggregate report - (${this.startDate} to ${this.endDate}).csv`);
    }

    downloadOSCSV() {
        const osCSV = [];
        for (const os of this.osDistributionData) {
            osCSV.push({ 'OS': os['name'], 'Card views': os['value'] });
        }
        Utils.downloadCSV(osCSV, `Digital business card views by device aggregate report - (${this.startDate} to ${this.endDate}).csv`);
    }

    downloadCityCSV() {
        const cityCSV = [];
        for (const city of this.citiesOriginalData.data) {
            cityCSV.push({
                City: city.dataObject.city.value.text,
                State: city.dataObject.state.value.text,
                Country: city.dataObject.country.value.text,
                ['Card views']: city.dataObject.views.value.text,
                ['Card saves']: city.dataObject.downloads.value.text,
                Percentage: city.dataObject.percentage.value.text
            });
        }
        Utils.downloadCSV(cityCSV, `Digital business card views by cities aggregate report - (${this.startDate} to ${this.endDate}).csv`);
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    handleExportClick(event) {
        switch (event.value) {
            case 'pdf':
                this.sendPDF();
                break;
            case 'csv':
                this.sendData();
                break;
        }
    }

    sendPDF() {
        const { username: email, timezone } = this.user;
        const dayDifference = getProcessedDayDifference(this.selectedDuration, this.globalToDate, this.globalFromDate);
        const interval = dayDifference <= 1 ? '30m' : '1d';
        const productId = this.card.id || [];
        const card_type = Number(this.selectedCardOption.id);
        const card_owner = this.user.id;

        // Send card_type and card_owner
        const body = {
            from: +this.globalFromDate.format('x'),
            to: +this.globalToDate.format('x'),
            product_id: productId,
            with_tags: this.selectedTags,
            organization_id: [this.organization.id],
            is_aggregate: !this.isIndividualCard,
            interval: interval,
            product_type: this.productType,
            product_namespace: 'DBC',
            email,
            timezone,
            card_owner,
            card_type,
            product_ids: this.filteredProductIds
        };

        this.analyticsService.getAnalytics('Exports.getAnalyticsData', body, this.productType)
            .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
                this.isExportOpen = false;
            })).subscribe(() => {
                this.messageModal.show(`An email to ${email} will be sent once we process your request`, 'success');
            }, () => {
                this.messageModal.show('Error processing request. Please try again', 'danger');
            });
    }

    sendData() {
        const { username: email, timezone } = this.user;
        const dayDifference = getProcessedDayDifference(this.selectedDuration, this.globalToDate, this.globalFromDate);
        const interval = dayDifference <= 1 ? '30m' : '1d';
        const productId = this.card.id || [];
        const card_type = Number(this.selectedCardOption.id);
        const card_owner = this.user.id;

        const body = {
            from: +this.globalFromDate.format('x'),
            to: +this.globalToDate.format('x'),
            product_id: productId,
            organization_id: [this.organization.id],
            is_aggregate: !this.isIndividualCard,
            interval: interval,
            product_type: this.productType,
            product_namespace: 'DBC',
            email,
            timezone,
            card_owner,
            card_type,
            product_ids: this.filteredProductIds
        };
        this.analyticsService.getAnalytics('Dbc.getCSVExportV3', body, this.productType)
            .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
                this.isExportOpen = false;
            })).subscribe(data => {
                this.messageModal.show(`An email to ${email} will be sent once we process your request`, 'success');
            }, error => {
                this.messageModal.show('Error processing request. Please try again', 'danger');
            });
    }


    onCustomDateChange(target, date) {
        if (!date) {
            return;
        }
        if (target === 'from') {
            this.startDate = moment(date).tz(this.timeZone).startOf('day');
        } else {
            this.endDate = moment(date).tz(this.timeZone).endOf('day');
        }
    }

}
