<!-- <div class="bx--row" style="background-color: #f4f5f7; margin-left: -1.9rem; margin-right: -1rem; height: 1rem">
    <div class="bx--col"></div>
</div> -->
<div class="bx--row mt-2">
    <div class="bx--col">
        <hr style="border: 1px dashed #DBDFE3">
    </div>
</div>
<div class="bx--row mt-4">
    <div class="bx--col cursor-pointer" (click)="collapseSection('fontStyle')">
        <span class="text-uppercase font-13 text-left font-weight-bold line-height-15 letter-spacing-1 text-gray">
            TYPOGRAPHY
            <span
                [ngClass]="
                    cardSetting.customizations
                        | inputIconPipe
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                            : cardSetting
                            : orgCardSetting
                "
                (click)="handleIconClick(CARD_PROPERTY_TYPES.CUSTOMIZATIONS); $event.stopPropagation()"
            ></span>
        </span>
        <span class="float-right">
            <beaconstac-button
                [disabled]="!hasWriteAccess || cardSetting.customizations"
                [type]="BUTTON_TYPES.SECONDARY"
                [label]="''"
                [icon]="sections.fontStyle ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                [style]="BUTTON_STYLES.TEXT"
            >
            </beaconstac-button>
        </span>
    </div>
</div>

<div class="bx--row mt-3" *ngIf="!sections.fontStyle" [@collapse]>
    <div class="bx--col-lg-14 mb-4">
        <beaconstac-filter-dropdown
            [label]="'Font'"
            [disabled]="
                hasWriteAccess
                    | disableFieldCheckPipe
                        : cardSetting
                        : orgCardSetting
                        : cardType
                        : priortizeOrgSettings
                        : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
            "
            [title]="'font'"
            [placeholder]="'Search font'"
            [value]="{ name: this.product.customizations.typography.font_family, value: this.product.customizations.typography.font_family}"
            [specificOutput]="'name'"
            (valueChange)="setFontFamily($event)"
            [listItems]="fontList"
            [optionLabelField]="'name'"
            [optionValueField]="'value'"
            [width]="'347px'"
            (inputChangeEvent)="changeFontList($event)"
        ></beaconstac-filter-dropdown>
    </div>
    <div class="bx--col-lg-14 mb-4" *ngIf="isManageCustomfontVisible()">
        <beaconstac-button
            [type]="BUTTON_TYPES.PRIMARY"
            [label]="'Manage Custom Fonts'"
            [icon]="!notOnEnterprisePlan ? '' : 'fas fa-crown text-warning'"
            [iconPosition]="'right'"
            [style]="BUTTON_STYLES.STROKE"
            [width]="isMobileScreenSize ? '100%' : '240px'"
            (onClick)="handleClickForManageCustomFontsModal()"
        >
        </beaconstac-button>
    </div>
    <div class="bx--col-lg-14" *ngIf="hasDBCAdvancedCustomization">
        <span class="text-left" style="font-size:15px; line-height: 24px; color:#5E6980;">
            Customize font styles for card sections
        </span>
    </div>

    <!-- Personal Info -->
    <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap font-specifics pt-3 pl-3">
        <div class="pr-3 font-specifics-child">
            <beaconstac-filter-dropdown
                    [label]="'Personal info'"
                    [disabled]="
                        hasWriteAccess
                            | disableFieldCheckPipe
                                : cardSetting
                                : orgCardSetting
                                : cardType
                                : priortizeOrgSettings
                                : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                    "
                    [title]="'personal info'"
                    [placeholder]="'Select personal info font'"
                    [value]="{
                        name: this.product.customizations.typography.personal_info.hasOwnProperty('custom_font_style') && this.product.customizations.typography.personal_info.custom_font_style !== ''? this.product.customizations.typography.personal_info.custom_font_style  :this.product.customizations.typography.personal_info.google_font_style,
                        value: this.product.customizations.typography.personal_info.hasOwnProperty('custom_font_style') && this.product.customizations.typography.personal_info.custom_font_style !== ''? this.product.customizations.typography.personal_info.custom_font_style  :this.product.customizations.typography.personal_info.google_font_style,
                    }"
                    [specificOutput]="'name'"
                    [listItems]="fontOptionsListProfileInfo"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)"
                ></beaconstac-filter-dropdown>
        </div>

        <div class="pl-0 d-flex font-specifics-child pr-3">
            <div class="pr-3 typography-font-size-dropdown">
                <beaconstac-filter-dropdown
                    [label]="' '"
                    [disabled]="
                        hasWriteAccess
                            | disableFieldCheckPipe
                                : cardSetting
                                : orgCardSetting
                                : cardType
                                : priortizeOrgSettings
                                : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                    "
                    [title]="'profile info font size'"
                    [placeholder]="'select profile info font size'"
                    [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                        product.customizations.typography.personal_info.custom_font_size :
                        product.customizations.typography.personal_info.google_font_size
                        , value: product.customizations.typography.font_type === 'custom' ?
                        product.customizations.typography.personal_info.custom_font_size :
                        product.customizations.typography.personal_info.google_font_size }"
                    [specificOutput]="'name'"
                    [listItems]="Utils.available_profile_info_font_size"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)"
                ></beaconstac-filter-dropdown>
            </div>

            <div class="typography-color-picker color-picker-no-label">
                <beaconstac-color-picker
                    [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.personal_info.custom_font_colour :
                                   product.customizations.typography.personal_info.google_font_colour"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                    (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)"
                    [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'personal_info_color'"
                    [cpWidth]="'100%'"
                ></beaconstac-color-picker>
            </div>
        </div>

    </div>

    <!-- Company Details -->

    <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 pl-3 font-specifics">
        <div class="pr-3 font-specifics-child">
            <beaconstac-filter-dropdown
                [label]="'Company details'"
                [disabled]="
                hasWriteAccess
                    | disableFieldCheckPipe
                        : cardSetting
                        : orgCardSetting
                        : cardType
                        : priortizeOrgSettings
                        : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
            "
                [title]="'company details'"
                [placeholder]="'Select company details font'"
                [value]="{
                name: this.product.customizations.typography.company_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.company_details.custom_font_style !== ''? this.product.customizations.typography.company_details.custom_font_style  :this.product.customizations.typography.company_details.google_font_style,
                value: this.product.customizations.typography.company_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.company_details.custom_font_style !== ''? this.product.customizations.typography.company_details.custom_font_style  :this.product.customizations.typography.company_details.google_font_style,
                }"
                [specificOutput]="'name'"
                [listItems]="fontOptionsListCompanyDetails"
                [optionLabelField]="'name'"
                [optionValueField]="'value'"
                [width]="'100%'"
                (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)"
            ></beaconstac-filter-dropdown>
        </div>
        <div class="pl-0 d-flex font-specifics-child pr-3">
            <div class="pr-3 typography-font-size-dropdown">
                <beaconstac-filter-dropdown
                    [label]="' '"
                    [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                    [title]="'company detail font size'"
                    [placeholder]="'select company detail font size'"
                    [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.company_details.custom_font_size :
                            product.customizations.typography.company_details.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.company_details.custom_font_size :
                            product.customizations.typography.company_details.google_font_size }"
                    [specificOutput]="'name'"
                    [listItems]="Utils.typography_customization_font_size"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="typography-color-picker color-picker-no-label">
                <beaconstac-color-picker
                    [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.company_details.custom_font_colour :
                                   product.customizations.typography.company_details.google_font_colour"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                    (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)"
                    [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'company_details_id'"
                    [cpWidth]="'100%'"
                ></beaconstac-color-picker>
            </div>
        </div>
    </div>

    <!-- bio -->

    <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 pl-3 font-specifics">
        <div class="pr-3 font-specifics-child">
            <beaconstac-filter-dropdown
                [label]="'Bio'"
                [disabled]="
                hasWriteAccess
                    | disableFieldCheckPipe
                        : cardSetting
                        : orgCardSetting
                        : cardType
                        : priortizeOrgSettings
                        : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
            "
                [title]="'bio'"
                [placeholder]="'Select bio font'"
                [value]="{
                name: this.product.customizations.typography.bio.hasOwnProperty('custom_font_style') && this.product.customizations.typography.bio.custom_font_style !== ''? this.product.customizations.typography.bio.custom_font_style  :this.product.customizations.typography.bio.google_font_style,
                value: this.product.customizations.typography.bio.hasOwnProperty('custom_font_style') && this.product.customizations.typography.bio.custom_font_style !== ''? this.product.customizations.typography.bio.custom_font_style  :this.product.customizations.typography.bio.google_font_style,
                }"
                [specificOutput]="'name'"
                [listItems]="fontOptionsListBio"
                [optionLabelField]="'name'"
                [optionValueField]="'value'"
                [width]="'100%'"
                (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BIO)"
            ></beaconstac-filter-dropdown>
        </div>

        <div class="pl-0 d-flex font-specifics-child pr-3">
            <div class="pr-3 typography-font-size-dropdown">
                <beaconstac-filter-dropdown
                    [label]="' '"
                    [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                    [title]="'bio font size'"
                    [placeholder]="'select bio font size'"
                    [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.bio.custom_font_size :
                            product.customizations.typography.bio.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.bio.custom_font_size :
                            product.customizations.typography.bio.google_font_size }"
                    [specificOutput]="'name'"
                    [listItems]="Utils.typography_customization_font_size"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.BIO)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="typography-color-picker color-picker-no-label">
                <beaconstac-color-picker
                    [(color)]="product.customizations.typography.font_type === 'custom' ?
                                       product.customizations.typography.bio.custom_font_colour :
                                       product.customizations.typography.bio.google_font_colour"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                    (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BIO)"
                    [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'bio_color'"
                    [cpWidth]="'100%'"
                ></beaconstac-color-picker>
            </div>
        </div>

    </div>

    <!-- Contact Details -->

    <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 pl-3 font-specifics">
        <div class="pr-3 font-specifics-child">
            <beaconstac-filter-dropdown
                [label]="'Contact details'"
                [disabled]="
                hasWriteAccess
                    | disableFieldCheckPipe
                        : cardSetting
                        : orgCardSetting
                        : cardType
                        : priortizeOrgSettings
                        : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
            "
                [title]="'contact details'"
                [placeholder]="'Select contact details font'"
                [value]="{
                name: this.product.customizations.typography.contact_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.contact_details.custom_font_style !== ''? this.product.customizations.typography.contact_details.custom_font_style  :this.product.customizations.typography.contact_details.google_font_style,
                value: this.product.customizations.typography.contact_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.contact_details.custom_font_style !== ''? this.product.customizations.typography.contact_details.custom_font_style  :this.product.customizations.typography.contact_details.google_font_style,
            }"
                [specificOutput]="'name'"
                [listItems]="fontOptionsListContactDetails"
                [optionLabelField]="'name'"
                [optionValueField]="'value'"
                [width]="'100%'"
                (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)"
            ></beaconstac-filter-dropdown>
        </div>

        <div class="pl-0 d-flex font-specifics-child pr-3">
            <div class="pr-3 typography-font-size-dropdown">
                <beaconstac-filter-dropdown
                    [label]="' '"
                    [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                    [title]="'contact detail font size'"
                    [placeholder]="'select contact details font size'"
                    [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.contact_details.custom_font_size :
                            product.customizations.typography.contact_details.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.contact_details.custom_font_size :
                            product.customizations.typography.contact_details.google_font_size }"
                    [specificOutput]="'name'"
                    [listItems]="Utils.typography_customization_font_size"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="typography-color-picker color-picker-no-label">
                <beaconstac-color-picker
                    [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.contact_details.custom_font_colour :
                                   product.customizations.typography.contact_details.google_font_colour"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                    (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)"
                    [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'contact_details'"
                    [cpWidth]="'100%'"
                ></beaconstac-color-picker>
            </div>
        </div>
    </div>

    <!-- Button -->
    <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 pl-3 font-specifics">
        <div class="pr-3 font-specifics-child">
            <beaconstac-filter-dropdown
                [label]="'Button'"
                [disabled]="
                hasWriteAccess
                    | disableFieldCheckPipe
                        : cardSetting
                        : orgCardSetting
                        : cardType
                        : priortizeOrgSettings
                        : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
            "
                [title]="'button'"
                [placeholder]="'Select button font'"
                [value]="{
                name: this.product.customizations.typography.button.hasOwnProperty('custom_font_style') && this.product.customizations.typography.button.custom_font_style !== ''? this.product.customizations.typography.button.custom_font_style  :this.product.customizations.typography.button.google_font_style,
                value:this.product.customizations.typography.button.hasOwnProperty('custom_font_style') && this.product.customizations.typography.button.custom_font_style !== ''? this.product.customizations.typography.button.custom_font_style  :this.product.customizations.typography.button.google_font_style,
                }"
                [specificOutput]="'name'"
                [listItems]="fontOptionsListButton"
                [optionLabelField]="'name'"
                [optionValueField]="'value'"
                [width]="'100%'"
                (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)"
            ></beaconstac-filter-dropdown>
        </div>

        <div class="pl-0 d-flex font-specifics-child pr-3">
            <div class="pr-3 typography-font-size-dropdown">
                <beaconstac-filter-dropdown
                    [label]="' '"
                    [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                    [title]="'button font size'"
                    [placeholder]="'select button font size'"
                    [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.button.custom_font_size :
                            product.customizations.typography.button.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.button.custom_font_size :
                            product.customizations.typography.button.google_font_size }"
                    [specificOutput]="'name'"
                    [listItems]="Utils.typography_customization_font_size"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="typography-color-picker color-picker-no-label">
                <beaconstac-color-picker
                    [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.button.custom_font_colour :
                                   product.customizations.typography.button.google_font_colour"
                    [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                    (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)"
                    [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'button_text_color'"
                    [cpWidth]="'100%'"
                ></beaconstac-color-picker>
            </div>
        </div>
    </div>
    <div class="bx--col-lg-14 mb-4"></div>
</div>

<div bsModal #handleShowModalForEnterprisePlan="bs-modal" *ngIf="isComputerScreenSize" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 479px;">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">

                <h4 class="create-card-heading font-weight-bold" style="font-style: italic;">
                    <span style="color: black;">Style with </span>
                    <span style="color: #2495ff;">Custom Fonts</span>
                </h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    Upgrade to the BUSINESS+ plan to use your brand's custom fonts.
                </div>

            </div>
            <div class="modal-footer border-0" style="padding: 24px;">

                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="handleShowModalForEnterprisePlan.hide()"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Upgrade Now'"
                  [style]="BUTTON_STYLES.FILLED"
                  (onClick)="routeToChangePlan()"
                  ></beaconstac-button>
                </span>
            </div>
        </div>
    </div>
</div>
<div bsModal #handleShowModalForEnterprisePlan="bs-modal" *ngIf="isMobileScreenSize" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 388px; max-height: 316px;">
        <div class="modal-content modal-wrapper">

            <div class="modal-body" style="padding: 24px">
                <h4 class="create-card-heading font-weight-bold" style="font-style: italic;">
                    <span style="color: black;">Style with </span>
                    <span style="color: #2495ff;">Custom Fonts</span>
                </h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    Upgrade to the BUSINESS+ plan to use your brand's custom fonts.
                </div>
            </div>

            <div class="custom-modal-footer border-0">
                <div class="bx--row">
                    <div class="bx--col-lg-16 d-flex justify-content-center">
                        <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.FILLED" [label]="'Upgrade Now'" class="btn-flex" [width]="'324px'" (onClick)="routeToChangePlan()"></beaconstac-button>
                    </div>
                </div>
                <div class="bx--row pt-3" style="display:block; justify-content: center;">
                    <div class="bx--col-lg-16 d-flex justify-content-center">
                        <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                           [style]="BUTTON_STYLES.TEXT"
                                           (onClick)="handleShowModalForEnterprisePlan.hide()"
                                           [label]="'Cancel'"
                                           [width]="'324px'"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
