/**
 * Created by Sourobrata Sarkar on 17/06/21.
 */
import {DataPattern, EyeBallShape, EyeFrameShape, GradientType, QRCodeFrame} from 'mobstac-awesome-qr/lib/Enums';
import {BaseModel} from '../global-services/base.model';

export enum QR_TEMPLATE_SCOPE {
    Public = 1,
    Private = 2,
    Shared = 3
}

export class QRCodeTemplate extends BaseModel {

    id: number;
    name: string;
    organization: number;
    dataPattern: DataPattern;
    colorLight: string;
    colorDark: string;
    gradientType: GradientType;
    eyeBallShape: EyeBallShape;
    eyeBallColor: string;
    eyeFrameShape: EyeFrameShape;
    eyeFrameColor: string;
    logoScale: number;
    logoImage: string;
    backgroundImage: string;
    backgroundColor: string;
    frameStyle: QRCodeFrame;
    frameColor: string;
    frameText: string;
    frameTextColor: string;
    logoBackground: boolean;
    thumbnailUrl: string;
    default: boolean;
    scope: QR_TEMPLATE_SCOPE;
    meta: any = {};
    maintainer: number;
    qr_codes_count: number;
    logoMargin: number;

    constructor(json: any) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.name = json['name'];
        this.organization = json['organization'];
        this.dataPattern = json['dataPattern'] || DataPattern.SQUARE;
        this.colorDark = json['colorDark'] || '#000000';
        this.colorLight = json['colorLight'] || '#8f00ff';
        this.gradientType = json['gradientType'] || GradientType.NONE;
        this.eyeBallShape = json['eyeBallShape'] || EyeBallShape.SQUARE;
        this.eyeBallColor = json['eyeBallColor'] || '';
        this.eyeFrameShape = json['eyeFrameShape'] || EyeFrameShape.SQUARE;
        this.eyeFrameColor = json['eyeFrameColor'] || '';
        this.logoScale = json['logoScale'] || 0.2;
        this.logoMargin = json['logoMargin'] || 10;
        this.logoImage = json['logoImage'] || '';
        this.backgroundImage = json['backgroundImage'] || '';
        this.backgroundColor = json['backgroundColor'] || '';
        this.frameStyle = json['frameStyle'] || QRCodeFrame.NONE;
        this.frameColor = json['frameColor'] || '#000000';
        this.frameText = json['frameText'] || 'SCAN ME';
        this.frameTextColor = json['frameTextColor'] || '#ffffff';
        this.logoBackground = json['logoBackground'] !== false;
        this.thumbnailUrl = json['thumbnail_url'];
        this.default = json['default'] === true;
        this.scope = json['scope'] || QR_TEMPLATE_SCOPE.Private;
        this.meta = json['meta'] || {};
        this.maintainer = json['maintainer'];
        this.qr_codes_count = json['qr_codes_count'] || 0;
        if (!this.eyeBallColor) {
            if (!this.colorDark) {
                this.eyeBallColor = '#000000'
            } else {
                this.eyeBallColor = this.colorDark
            }
        }
        if (!this.eyeFrameColor) {
            if (!this.colorDark) {
                this.eyeFrameColor = '#000000'
            } else {
                this.eyeFrameColor = this.colorDark
            }
        }
    }

    resetAttributes() {
        this.backgroundColor = '';
        this.logoBackground = true;
        this.backgroundImage = '';
        this.colorDark = '#000000';
        this.colorLight = '#8f00ff';
        this.dataPattern = DataPattern.SQUARE;
        this.eyeBallColor = '#000000';
        this.eyeBallShape = EyeBallShape.SQUARE;
        this.eyeFrameColor = '#000000';
        this.eyeFrameShape = EyeFrameShape.SQUARE;
        this.frameColor = '#000000';
        this.frameStyle = QRCodeFrame.NONE;
        this.frameText = '';
        this.frameTextColor = '';
        this.gradientType = GradientType.NONE;
        this.logoImage = '';
        this.logoScale = 0.2;
        this.logoMargin = 10
    }

    getAttributes() {
        return {
            backgroundColor: this.backgroundColor,
            logoBackground: this.logoBackground,
            backgroundImage: this.backgroundImage,
            colorDark: this.colorDark,
            colorLight: this.colorLight,
            color: this.colorDark,
            dataPattern: this.dataPattern,
            eyeBallColor: this.eyeBallColor,
            eyeBallShape: this.eyeBallShape,
            eyeFrameColor: this.eyeFrameColor,
            eyeFrameShape: this.eyeFrameShape,
            frameColor: this.frameColor,
            frameStyle: this.frameStyle,
            frameText: this.frameText,
            frameTextColor: this.frameTextColor,
            gradientType: this.gradientType,
            logoImage: this.logoImage,
            logoScale: this.logoScale,
            logoMargin: this.logoMargin,
            margin: 80,
            dotScale: 1,
            isVCard: false
        }
    };
}
