import {
    Component,
    EventEmitter, HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    Pipe,
    PipeTransform, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import {BUTTON_STYLES, BUTTON_TYPES, CARET_POSITION, TEXT_FIELD_TYPES, TOOLTIP_POSITION} from 'kaizen-design-system';
import {PLAN_TYPES, User} from 'app/user-account/user.model';
import {qrPricingData} from './qr-pricing.data';
import {Animations} from '../../shared/beaconstac-animations';
import {OrganizationCountModel, OrganizationCountService} from '../../global-services/organization-count.service';
import {TooltipDirective} from 'ngx-bootstrap/tooltip';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import {PRICING_PAGE_TYPE} from '../../shared/utils';

enum PLAN_COLUMN_TYPES {
    STARTER_INDIVIDUAL = 'SI',
    LITE_INDIVIDUAL_RECOMMENDED = 'LIR',
    LITE_BUSINESS = 'LB',
    PRO_INDIVIDUAL = 'PI',
    PRO_BUSINESS_RECOMMENDED = 'PBR',
    PLUS_BOTH = 'PB',
    ENTERPRISE_BUSINESS = 'EB'
}

@Pipe({name: 'isOnHigherPlan'})
export class IsOnHigherPlan implements PipeTransform {
    transform(user: User, plan: PLAN_TYPES): any {
        const isOnHigherPlan = ( user.isOnHigherPlan(plan) || !user.isOnQRPlan() );
        return isOnHigherPlan && (user.customer_plan !== plan);
    }
}
@Component({
    selector: 'beaconstac-qr-pricing',
    templateUrl: './qr-pricing.component.html',
    styleUrls: ['./qr-pricing.component.scss'],
    animations: [Animations.collapse, Animations.rotate],
})
export class QrPricingComponent implements OnInit, OnChanges {
    @Output() onQRPlanTypeClicked = new EventEmitter<{plan: PLAN_TYPES, plusPlanUserAddOn?: number, additionalCustomDomains?: boolean}>();
    @Output() onBuyButtonClicked = new EventEmitter<{plan: PLAN_TYPES, responseAddOnCount?: number, fromPage?: string, additionalCustomDomains?: boolean }>();
    @Output() onEnterpriseSelected = new EventEmitter();
    @Output() plusPlanAddOnChange = new EventEmitter();
    @Input() user: User;
    @Input() qrPlansData: any;
    @Input() showBusinessPlans: boolean;
    @Input() pageType: string;
    @Input() exchangeRates;
    @Input() isUserSPUImpacted: boolean = false;
    @Input() isEligibleForDiscount
    qrPricingData = qrPricingData;
    BUTTON_TYPES = BUTTON_TYPES
    BUTTON_STYLES = BUTTON_STYLES;
    PLAN_TYPES = PLAN_TYPES;
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    plusPlanUserAddOn: number = 3;
    selectedCurrency: string = 'usd';
    clickedFormFrom: string = '';
    currencies: { code: string, symbol: string }[] = [
        { code: 'usd', symbol: '$ ' },
        { code: 'aud', symbol: '$ ' },
        { code: 'eur', symbol: '€ ' },
        { code: 'gbp', symbol: '£ ' },
        { code: 'inr', symbol: '₹ ' }
    ];
    responseAddonOptions = [
        {name: ' responses for $120 per year', selected: true, disabled: false, value: 1, price: 120},
        {name: ' responses for $200 per year', selected: false, disabled: false, value: 2, price: 200},
        {name: ' responses for $500 per year', selected: false, disabled: false, value: 10, price: 500},
        {name: ' responses for $800 per year', selected: false, disabled: false, value: 20, price: 800},
        {name: 'I do not need additional responses', selected: false, disabled: false, value: 0, price: 0}
    ];
    planTypesForAddonModal = [
        {name: 'LITE', key: 'LT', price: 15},
        {name: 'PRO', key: 'PO', price: 49},
        {name: 'PLUS', key: 'PL', price: 99}
    ];
    responseAddonCount = ['+5,000', '+10,000', '+50,000', '+100,000', ''];
    selectedAddonPrice: number = 120;
    selectedAddonValue: number = 1;
    showFormAddonModal: boolean = false;
    selectedPlan: any = {name: 'LITE', key: 'LT', price: 15};
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;
    isDropdownOpen: boolean = false;
    myHTML = '';
    PLAN_COLUMN_TYPES = PLAN_COLUMN_TYPES;
    firstRenderedDiv: string = null;
    qrUserCount: number = 0;
    responseCount: number = 0;
    plusPlanPrice: number;
    customDomainAddonMonthlyPrice: number = 167;
    userSeatPrice: number = 0;
    additionalCustomDomainPrice: number | string = 0;
    plusPlanInitialPrice: Object = {};
    showIncludes: boolean[] = Array(8).fill(false);
    showExcludes: boolean[] = Array(8).fill(false);
    isComponentInitialized: boolean = false;
    isMobile: boolean = false;
    openedTooltips = [];

    additionalCustomDomains: boolean = false;


    @ViewChildren('pop') public allTooltips: QueryList<TooltipDirective> ;
    @ViewChild('responseAddOnModal', {static: true}) responseAddOnModal: ModalDirective;


    private queryParamSubscription: Subscription;

    protected readonly PRICING_PAGE_TYPE = PRICING_PAGE_TYPE;

    constructor(private organizationCountService: OrganizationCountService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.isMobile = (window.innerWidth < 1335);
        this.organizationCountService.getCounts()
            .pipe().subscribe((organizationCount: OrganizationCountModel) => {
                this.qrUserCount = organizationCount.qr_user_count;
                this.determineFirstRenderedDiv();
            }, (error) => {
                console.error(error);
                this.determineFirstRenderedDiv();
            });
        this.getResponseCount();
        for (const currency of this.currencies) {
            if (this.showBusinessPlans) {
                this.plusPlanInitialPrice[currency.code] = this.qrPlansData[2].price[currency.code].value;
            } else {
                this.plusPlanInitialPrice[currency.code] = this.qrPlansData[3].price[currency.code].value;
            }
        }
        if (this.showBusinessPlans) {
            this.plusPlanPrice = this.qrPlansData[2].price['usd'].value;
        } else {
            this.plusPlanPrice = this.qrPlansData[3].price['usd'].value;
        }
        this.isComponentInitialized = true;
        // reset the value of plusPlanUserAddOn to 3
        this.handlePlusPlanUserAddOn(3);
    }

    ngOnChanges() {
        if (this.isComponentInitialized) {
            this.firstRenderedDiv = null;
            this.determineFirstRenderedDiv();
        }
    }

    ngAfterViewInit() {
        this.queryParamSubscription = this.route.queryParams.subscribe((params: Params) => {

            for (const key in params) {
                if (key === 'source' && params[key] === 'Upgrade_Forms') {
                    this.showFormAddonModal = true;
                } else if (key === 'frompage') {
                    this.clickedFormFrom = params[key];
                }
            }
            if (this.showFormAddonModal && !this.clickedFormFrom) {
                this.clickedFormFrom = 'Formspage';
            }
        });
    }

    ngOnDestroy() {
        if (this.queryParamSubscription) {
            this.queryParamSubscription.unsubscribe();
        }
    }

    getResponseCount() {
        this.organizationCountService.getResponseCount().pipe().subscribe((organizationCount: OrganizationCountModel) => {
            this.responseCount = organizationCount.response_count_in_cycle || 0;
        }, error => {
            this.responseCount = 0;
        });
    }

    setUpResponseAddonModal(event, index) {
        this.responseAddonOptions.forEach((item, i) => {
            item.selected = (i === index);
        });
        this.selectedAddonPrice = event.price + this.userSeatPrice * 12 + Number(this.additionalCustomDomainPrice) * 12 ;
        this.selectedAddonValue = event.value;
    }

    upgradePlanForForms() {
        this.responseAddOnModal.hide();
        this.onBuyButtonClicked.emit({plan: this.selectedPlan.key, responseAddOnCount: this.selectedAddonValue, fromPage: this.clickedFormFrom, additionalCustomDomains: this.additionalCustomDomains});
        if (this.userSeatPrice) {
            this.plusPlanAddOnChange.emit({ plusPlanUserAddOn : this.plusPlanUserAddOn, additionalCustomDomains: this.additionalCustomDomains });
        }
    }

    updatePrice(choice: string) {
        this.selectedCurrency = choice;
        // updatePlusPrice

        const exchangeRate = this.selectedCurrency === 'usd' ? 1 : this.exchangeRates[this.selectedCurrency.toUpperCase()];
        const initialPrice = this.plusPlanInitialPrice[this.selectedCurrency];
        const customDomainPrice = this.additionalCustomDomains ?  (this.customDomainAddonMonthlyPrice * exchangeRate) : 0;
        this.additionalCustomDomainPrice = customDomainPrice.toFixed(1);

        this.plusPlanPrice = ((initialPrice) + (this.plusPlanUserAddOn - 3) * (Math.round(5 * exchangeRate)) + customDomainPrice).toFixed(0);
    }

    getSelectedCurrencySymbol(): string | undefined {
        const selectedCurrencyObject = this.currencies.find(c => c.code === this.selectedCurrency);
        return selectedCurrencyObject?.symbol;
    }

    handleQRPlanClicked(plan: PLAN_TYPES) {
        switch (plan) {
            case PLAN_TYPES.Starter:
                this.onQRPlanTypeClicked.emit({plan: PLAN_TYPES.Starter})
                break;
            case PLAN_TYPES.Lite:
                this.onQRPlanTypeClicked.emit({plan: PLAN_TYPES.Lite})
                break;
            case PLAN_TYPES.Pro:
                this.onQRPlanTypeClicked.emit({plan: PLAN_TYPES.Pro})
                break;
            case PLAN_TYPES.Plus:
                if (this.plusPlanUserAddOn >= 3) {
                    this.onQRPlanTypeClicked.emit({ plan: PLAN_TYPES.Plus, plusPlanUserAddOn: this.plusPlanUserAddOn, additionalCustomDomains: this.additionalCustomDomains });
                }
                break;
        }
    }

    handleBuyButtonClick(plan: PLAN_TYPES) {
        if (plan !== PLAN_TYPES.Starter && this.showFormAddonModal) {
            this.selectedPlan = this.planTypesForAddonModal.find(obj => plan === obj.key);
            this.responseAddOnModal.show();
        } else {
            this.onBuyButtonClicked.emit({plan: plan, additionalCustomDomains: this.additionalCustomDomains});

        }
    }

    handlePlusPlanUserAddOn(noOfUser: number) {
        const exchangeRate = this.selectedCurrency === 'usd' ? 1 : this.exchangeRates[this.selectedCurrency.toUpperCase()];
        const customDomainPrice = this.additionalCustomDomains ?  (this.customDomainAddonMonthlyPrice * exchangeRate) : 0;

        if (noOfUser < 3 || noOfUser > 140) {
            setTimeout(() => {
                this.plusPlanUserAddOn = 3;
                this.plusPlanPrice = (this.plusPlanInitialPrice[this.selectedCurrency] + customDomainPrice).toFixed(0);

            }, 800)
            return;
        }
        this.plusPlanUserAddOn = noOfUser;
        const initialPrice = this.plusPlanInitialPrice[this.selectedCurrency];
        this.userSeatPrice = (this.plusPlanUserAddOn - 3) * (Math.round(5 * exchangeRate));
        this.additionalCustomDomainPrice = customDomainPrice.toFixed(1);
        this.plusPlanPrice = ((initialPrice) + this.userSeatPrice + customDomainPrice).toFixed(0);
        this.plusPlanAddOnChange.emit({ plusPlanUserAddOn : this.plusPlanUserAddOn, additionalCustomDomains: this.additionalCustomDomains });
        this.setUpResponseAddonModal({value: 1, price: 120}, 0);
    }

    handlePlusPlanCustomDomainAddOn($event) {
        this.additionalCustomDomains = $event;
        this.handlePlusPlanUserAddOn(this.plusPlanUserAddOn);
        this.plusPlanAddOnChange.emit({ plusPlanUserAddOn : this.plusPlanUserAddOn, additionalCustomDomains: $event });
    }

    isOpenChange(): void {
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    showDropdownOnTop(plan: PLAN_COLUMN_TYPES) {
        return this.firstRenderedDiv === plan;
    }

    shouldRenderPlan(plan: string) {
        let isOnHigherPlan;
        const isOnTrialPlan = (this.user.subscription.qr.plan === PLAN_TYPES.Trial);
        const userPlan = this.user.subscription.qr.plan
        switch (plan) {
            case PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL:
                if ((isOnTrialPlan && (this.qrUserCount > 1 || this.responseCount)) || (userPlan !== PLAN_TYPES.Starter && this.pageType === PRICING_PAGE_TYPE.UPGRADE_NOW && this.isUserSPUImpacted)) {
                    return false;
                }
                isOnHigherPlan = (this.user.isOnHigherPlan(PLAN_TYPES.Starter) || !this.user.isOnQRPlan() );
                return !(isOnHigherPlan && (this.user.customer_plan !== PLAN_TYPES.Starter)) && (!this.showBusinessPlans);
            case PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED:
                if ((isOnTrialPlan && this.qrUserCount > 1) || (userPlan !== PLAN_TYPES.Lite && this.pageType === PRICING_PAGE_TYPE.UPGRADE_NOW && this.isUserSPUImpacted)) {
                    return false;
                }
                isOnHigherPlan = (this.user.isOnHigherPlan(PLAN_TYPES.Lite) || !this.user.isOnQRPlan() );
                return !(isOnHigherPlan && (this.user.customer_plan !== PLAN_TYPES.Lite)) && !this.showBusinessPlans;
            case PLAN_COLUMN_TYPES.LITE_BUSINESS:
                if ((isOnTrialPlan && this.qrUserCount > 1) || (userPlan !== PLAN_TYPES.Lite && this.pageType === PRICING_PAGE_TYPE.UPGRADE_NOW && this.isUserSPUImpacted)) {
                    return false;
                }
                isOnHigherPlan = (this.user.isOnHigherPlan(PLAN_TYPES.Lite) || !this.user.isOnQRPlan() );
                return !(isOnHigherPlan && (this.user.customer_plan !== PLAN_TYPES.Lite)) && this.showBusinessPlans;
            case PLAN_COLUMN_TYPES.PRO_INDIVIDUAL:
                if (isOnTrialPlan && this.qrUserCount > 1) {
                    return false;
                }
                isOnHigherPlan = (this.user.isOnHigherPlan(PLAN_TYPES.Pro) || !this.user.isOnQRPlan() );
                return !(isOnHigherPlan && (this.user.customer_plan !== PLAN_TYPES.Pro)) && !this.showBusinessPlans;
            case PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED:
                if (isOnTrialPlan && this.qrUserCount > 1) {
                    return false;
                }
                isOnHigherPlan = (this.user.isOnHigherPlan(PLAN_TYPES.Pro) || !this.user.isOnQRPlan() );
                return !(isOnHigherPlan && (this.user.customer_plan !== PLAN_TYPES.Pro)) && this.showBusinessPlans;
            case PLAN_COLUMN_TYPES.PLUS_BOTH:
                isOnHigherPlan = (this.user.isOnHigherPlan(PLAN_TYPES.Plus) || !this.user.isOnQRPlan() );
                return !(isOnHigherPlan && (this.user.customer_plan !== PLAN_TYPES.Plus));
            case PLAN_COLUMN_TYPES.ENTERPRISE_BUSINESS:
                return this.showBusinessPlans;
        }
    }

    determineFirstRenderedDiv() {
        if (!this.showBusinessPlans) {
            for (const planType of Object.values(PLAN_COLUMN_TYPES)) {
                if (this.shouldRenderPlan(planType) && this.firstRenderedDiv === null) {
                    this.firstRenderedDiv = planType;
                }
            }
        } else {
            const businessPlanTypes = [PLAN_COLUMN_TYPES.LITE_BUSINESS, PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED, PLAN_COLUMN_TYPES.PLUS_BOTH, PLAN_COLUMN_TYPES.ENTERPRISE_BUSINESS];
            for (const planType of businessPlanTypes) {
                if (this.shouldRenderPlan(planType) && this.firstRenderedDiv === null) {
                    this.firstRenderedDiv = planType;
                }
            }
        }
    }

    toggleIncludes(index: number) {
        this.showIncludes[index] = !this.showIncludes[index];
    }

    toggleExcludes(index: number) {
        this.showExcludes[index] = !this.showExcludes[index];
    }

    determinePlusPlanMarginTop() {
        if (!this.showBusinessPlans) {
            if (this.showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)) {
                return '0px';
            } else {
                if (this.showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)) {
                    return '-48px';
                } else {
                    return '0px';
                }
            }
        } else {
            if (this.showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)) {
                return '0px';
            } else {
                if (this.showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED)) {
                    return '-96px';
                } else {
                    return '-48px';
                }
            }
        }
    }

    isObjectEmpty = (objectName) => {
        return (
            objectName &&
            Object.keys(objectName).length === 0 &&
            objectName.constructor === Object
        );
    };

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent | TouchEvent) {
        if (this.isMobile) {
            let clickedOutside = true;
            for (let i = 0; i < 7; i++) {
                // @ts-ignore
                const clickedInsideTooltipContent = document.querySelector(`.tooltip-content-mobile-click${i}`)?.contains(event.target);
                // @ts-ignore
                const clickedInsideInfoIcon = (document.querySelector(`.tooltip-icon-mobile-click${i}`) === event.target);
                // @ts-ignore
                const clickedInsideInfoButton = document.querySelector(`.tooltip-button-mobile-click${i}`)?.contains(event.target);
                if (clickedInsideTooltipContent || clickedInsideInfoIcon || clickedInsideInfoButton) {
                    clickedOutside = false;
                }
            }
            if (clickedOutside) {
                this.openedTooltips = [];
                this.allTooltips.forEach((tooltip: TooltipDirective ) => {
                    tooltip.hide();
                });
            } else {
                this.openedTooltips.forEach((tooltip: TooltipDirective ) => {
                    // @ts-ignore
                    if (tooltip._elementRef.nativeElement.children[0] !== event.target) {
                        tooltip.hide();
                    }
                });
                this.openedTooltips = [];
                this.allTooltips.forEach((tooltip: TooltipDirective ) => {
                    // @ts-ignore
                    if (tooltip._elementRef.nativeElement.children[0] === event.target) {
                        this.openedTooltips = [tooltip];
                    }
                });
            }
        } else {
            let clickedOutside = true;
            for (let i = 0; i < 7; i++) {
                // @ts-ignore
                const clickedInsideTooltipContent = document.querySelector(`.tooltip-content-click${i}`)?.contains(event.target);
                // @ts-ignore
                const clickedInsideInfoIcon = (document.querySelector(`.tooltip-icon-click${i}`) === event.target);
                // @ts-ignore
                const clickedInsideInfoButton = document.querySelector(`.tooltip-button-click${i}`)?.contains(event.target);
                if (clickedInsideTooltipContent || clickedInsideInfoIcon || clickedInsideInfoButton) {
                    clickedOutside = false;
                }
            }
            if (clickedOutside) {
                this.openedTooltips = [];
                this.allTooltips.forEach((tooltip: TooltipDirective ) => {
                    tooltip.hide();
                });
            } else {
                this.openedTooltips.forEach((tooltip: TooltipDirective ) => {
                    // @ts-ignore
                    if (tooltip._elementRef.nativeElement.children[0] !== event.target) {
                        tooltip.hide();
                    }
                });
                this.openedTooltips = [];
                this.allTooltips.forEach((tooltip: TooltipDirective ) => {
                    // @ts-ignore
                    if (tooltip._elementRef.nativeElement.children[0] === event.target) {
                        this.openedTooltips = [tooltip];
                    }
                });
            }
        }
    }
}
