<div class="filter-component-container">
    <div class="filter-component-body d-flex justify-content-between">
        <div class="filters-and-button-container">
             <!-- APPLIED FILTER -->
             <div class="filter-chip" *ngFor="let filter of appliedFilters" [ngClass]="{ 'selected-filter'  : filter.id === selectedFilter?.id }" [ngStyle]="{'display': (!isMobileScreenSize || !mobileDrawer) ? 'block' : 'none' }">
                <div *ngIf="filter.type === FILTER_OPERATOR_TYPE.NUMBER_OF_PRODUCTS_SELECTED; else filtersChipsBlock" class="btn no-caret no-box-shadow no-outline">
                    <span class="filter-chip-text">{{filter.input.length || 0 }} {{ filter.name }}{{filter.input.length > 1 ? 's': '' }}  </span>
                    <span class="filter-chip-remove-icon" (click)="onRemoveFilterClicked(filter)">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                </div>
                <ng-template #filtersChipsBlock>
                    <div class="dropdown">
                        <button
                            class="btn dropdown-toggle no-caret no-box-shadow no-outline"
                            type="button" id="{{ 'dropdownMenuButton-' + filter.id }}"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            (click)="onSelectFilter(filter)"
                            [ngClass]="{ 'selected-filter-text'  : filter.id === selectedFilter?.id }">
                            <span class="filter-chip-text"> {{ filter.name }} </span>
                            <span *ngIf="filter.operators" class="filter-chip-operator">
                                {{filter.selectedOperator.name}}
                            </span>
                            <span class="filter-chip-input">
                                <span *ngIf="filter.type == FILTER_OPERATOR_TYPE.String || filter.type == FILTER_OPERATOR_TYPE.Number">"{{filter.input}}"</span>
                                <span *ngIf="filter.type == FILTER_OPERATOR_TYPE.Dropdown">
                                    <ng-container *ngIf="filter.name === 'Owner'; else selectedNonOwnerFilter">
                                        <ng-container *ngIf="(filter.selectedOperator?.name !== 'is'); else nonOwnerFilter">
                                            "{{filter.input}}"
                                        </ng-container>
                                        <ng-template #nonOwnerFilter>
                                            "{{filter.dropDownChoice?.name}}"
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #selectedNonOwnerFilter>
                                        "{{filter.dropDownChoice?.text}}"
                                    </ng-template>
                                </span>
                                <span *ngIf="filter.type == FILTER_OPERATOR_TYPE.Date">
                                    <span *ngIf="filter.input != 'Custom'">in {{filter.selectedRadioOption?.name}}</span>
                                    <span *ngIf="filter.input == 'Custom'">between {{filter.date.__startDate__}} to {{filter.date.__endDate__}}</span>
                                </span>
                            </span>
                        <span class="filter-chip-remove-icon" (click)="onRemoveFilterClicked(filter)">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </span>
                        </button>

                        <div class="dropdown-menu" attr.aria-labelledby="{{ 'dropdownMenuButton-' + filter.id }}">
                            <div *ngIf="selectedFilter?.id" class="unique-filter-dropdown">
                                <div> {{ filter.name }} </div>
                                <div *ngIf="filter.operators">
                                    <beaconstac-filter-dropdown
                                        (click)="$event.stopPropagation()"
                                        [value]="selectedFilter.selectedOperator"
                                        [style]="DROPDOWN_STYLES.STROKE"
                                        [title]="'applied-operator-' + filter.id | generateRandomId"
                                        [listItems]="filter.operators"
                                        [optionLabelField]="'name'"
                                        [optionValueField]="'value'"
                                        (valueChange)="onSelectedFilterTypeChange($event)">
                                    </beaconstac-filter-dropdown>
                                </div>
                                <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.String">
                                    <beaconstac-textfield
                                        [label]="false"
                                        [title]="'applied-text-textfield-'+selectedFilter.id"
                                        [placeholder]="selectedFilter?.placeholder || selectedFilter.name"
                                        [maxWidth]="'650px'"
                                        [text]="selectedFilter.input"
                                        (textChange)="selectedFilter.input = $event.trim();">
                                    </beaconstac-textfield>
                                </div>
                                <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.Number">
                                    <beaconstac-textfield
                                        [label]="false"
                                        [title]="'applied-number-textfield'+selectedFilter.id"
                                        [placeholder]="selectedFilter?.placeholder || selectedFilter.name"
                                        [maxWidth]="'650px'"
                                        [(text)]="selectedFilter.input"
                                        (textChange)="selectedFilter.input = $event;"
                                        [type]="TEXT_FIELD_TYPES.NUMBER">
                                    </beaconstac-textfield>
                                </div>
                                <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.Dropdown">
                                    <beaconstac-filter-dropdown
                                        *ngIf="selectedFilter.name !== 'Owner'; else ownerFilterBlockToBeApplied"
                                        (click)="$event.stopPropagation()"
                                        [listItems]="selectedFilter.dropdownOptions"
                                        [title]="'Applied-dropdown-' + selectedFilter.id | generateRandomId"
                                        [value]="selectedFilter.dropDownChoice"
                                        [optionLabelField]="'text'"
                                        [optionValueField]="'value'"
                                        [disableInput]="selectedFilter.value !== 'tags'"
                                        [dynamicSearch]="false"
                                        [isFetchingData]="isFetachingTags"
                                        (inputChangeEvent)="onFilterDropwdownChange(selectedFilter, $event)"
                                        (valueChange)="onDropdownInputChange(selectedFilter, $event);"
                                        [placeholder]="selectedFilter?.placeholder || ''">
                                    </beaconstac-filter-dropdown>
                                   
                                    <ng-template #ownerFilterBlockToBeApplied>
                                        <beaconstac-textfield
                                            *ngIf="selectedFilter.selectedOperator?.name !== 'is'; else ownersListBlockToBeApplied"
                                            [label]="false"
                                            [title]="'tobeApplied-text-textfield-owner'+ selectedFilter.id"
                                            [placeholder]="selectedFilter?.placeholder || selectedFilter.name"
                                            [maxWidth]="'650px'"
                                            [valid]="validationErrors[selectedFilter.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                            [errorMessage]="validationErrors[selectedFilter.id]"
                                            [text]="selectedFilter.input"
                                            (textChange)="onInputChange(selectedFilter, $event)">
                                        </beaconstac-textfield>
                                        <ng-template #ownersListBlockToBeApplied>
                                            <beaconstac-filter-dropdown
                                                (click)="$event.stopPropagation()"
                                                [placeholder]="selectedFilter.placeholder || ''"
                                                [listItems]="activeUsersList"
                                                [title]="'Applied-dropdown-owner-' + selectedFilter.id | generateRandomId"
                                                [value]="selectedFilter.dropDownChoice"
                                                [optionLabelField]="'name'"
                                                [optionValueField]="'value'"
                                                [dynamicSearch]="true"
                                                [disableInput]="false"
                                                [enableInfiniteScrolling]="true"
                                                (fetchMoreData)="handleFetchActiveUsers(activeUsersPageNumber)"
                                                [isFetchingData]="isFetchingActiveUserList"
                                                [valid]="validationErrors[selectedFilter.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                                [errorMessage]="validationErrors[selectedFilter.id]"
                                                (inputChangeEvent)="handleChangesActiveUsersInput($event)"
                                                (valueChange)="onDropdownInputChange(selectedFilter, $event)">
                                            </beaconstac-filter-dropdown>
                                        </ng-template>
                                    </ng-template>
                                </div>
                                <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.Date" style="margin-left: 20px">
                                    <beaconstac-radio-button
                                        (click)="$event.stopPropagation()"
                                        [disabled]="false"
                                        (onClick)="onDateRadioOptionClicked($event);"
                                        [options]="filter.radioOptions"
                                    ></beaconstac-radio-button>
                                    <div *ngIf="selectedFilter.input == selectedFilter.radioOptions[4].name" id="date-pick-{{selectedFilter.id}}">
                                        <!--Angular Datepicker-->
                                        <div class="d-flex flex-column datepicker-parent">
                                            <beaconstac-textfield
                                                (keydown)="$event.preventDefault(); datepicker.open()"
                                                type="text"
                                                [placeholder]="'MM/DD/YYYY'"
                                                [maxWidth]="'650px'"
                                                [icon]="'fas fa-calendar-alt'"
                                                iconPosition = "left"
                                                (click)="datepicker.open()"
                                                [text]="selectedFilter.date.__startDate__ + ' - ' + selectedFilter.date.__endDate__"
                                                [label]="true"
                                                [title]="'Select '+ selectedFilter.name + ' range'"
                                            >
                                            </beaconstac-textfield>
                                            <mat-date-range-input
                                                [rangePicker]="datepicker"
                                            >
                                                <input #dateRangeStart matStartDate>
                                                <input #dateRangeEnd matEndDate (dateChange)="onDateRangeEdit(dateRangeStart, dateRangeEnd, selectedFilter)">
                                            </mat-date-range-input>
                                            <mat-date-range-picker #datepicker></mat-date-range-picker>
                                        </div>

                                    </div>
                                </div>
                                <div class="flex-row-reverse d-flex">
                                    <beaconstac-button
                                        [style]="BUTTON_STYLES.FILLED"
                                        type="primary"
                                        [disabled]="false"
                                        label="Apply"
                                        [useCustomImage]="false"
                                        width=""
                                        (onClick)="onApplyFilterClicked(selectedFilter)">
                                    </beaconstac-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>

            <!-- FILTERED TO BE APPLIED -->
            <div class="filter-chip selected-filter" *ngIf="filterToBeApplied" id="filterToBeApplied">
                <div class="dropdown show">
                    <button class="btn dropdown-toggle no-caret no-box-shadow no-outline selected-filter-text" type="button" id="{{ 'dropdownMenuButton-' + filterToBeApplied.id }}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      <span class="filter-chip-text">
                        {{filterToBeApplied.name}}
                      </span>
                      <span *ngIf="filterToBeApplied.operators" class="filter-chip-operator">
                        {{filterToBeApplied.selectedOperator.name}}
                      </span>
                      <span class="filter-chip-input">
                        <span *ngIf="filterToBeApplied.type == FILTER_OPERATOR_TYPE.String || filterToBeApplied.type == FILTER_OPERATOR_TYPE.Number">"{{filterToBeApplied.input}}"</span>
                        <span *ngIf="filterToBeApplied.type == FILTER_OPERATOR_TYPE.Dropdown">"{{filterToBeApplied.dropDownChoice?.text}}"</span>
                        <span *ngIf="filterToBeApplied.type == FILTER_OPERATOR_TYPE.Date">
                            <span *ngIf="filterToBeApplied.input != 'Custom'">in {{filterToBeApplied.selectedRadioOption?.name}}</span>
                            <span *ngIf="filterToBeApplied.input == 'Custom'">between {{filterToBeApplied.date.__startDate__}} to {{filterToBeApplied.date.__endDate__}}</span>
                        </span>
                    </span>
                    <span class="filter-chip-remove-icon" (click)="onRemoveFilterClicked(filterToBeApplied,true)">
                        <i class="fa fa-times" aria-hidden="true"></i>
                    </span>
                    </button>
                    <div class="dropdown-menu show" attr.aria-labelledby="{{ 'dropdownMenuButton-' + filterToBeApplied.id }}">
                        <div class="unique-filter-dropdown">
                            <div>{{filterToBeApplied.name}}</div>
                            <div *ngIf="filterToBeApplied.operators">
                                <beaconstac-filter-dropdown
                                    (click)="$event.stopPropagation()"
                                    [style]="DROPDOWN_STYLES.STROKE"
                                    [title]="'toBeApplied-operators-'+filterToBeApplied.id | generateRandomId"
                                    [listItems]="filterToBeApplied.operators"
                                    [value]="filterToBeApplied.selectedOperator"
                                    [optionLabelField]="'name'"
                                    [optionValueField]="'value'"
                                    [disableInput]="true"
                                    (valueChange)="onSelectedFilterTypeChange($event,true)">
                                </beaconstac-filter-dropdown>
                            </div>
                            <div *ngIf="filterToBeApplied.type == FILTER_OPERATOR_TYPE.String">
                                <beaconstac-textfield
                                    [label]="false"
                                    [title]="'tobeApplied-text-textfield-'+filterToBeApplied.id"
                                    [placeholder]="filterToBeApplied?.placeholder || filterToBeApplied.name"
                                    [maxWidth]="'650px'"
                                    [valid]="validationErrors[filterToBeApplied.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                    [errorMessage]="validationErrors[filterToBeApplied.id]"
                                    [text]="filterToBeApplied.input"
                                    (textChange)="onInputChange(filterToBeApplied, $event)">
                                </beaconstac-textfield>
                            </div>
                            <div *ngIf="filterToBeApplied.type == FILTER_OPERATOR_TYPE.Number">
                                <beaconstac-textfield
                                    [label]="false"
                                    [title]="'toBeApplied-number-textfield-'+filterToBeApplied.id"
                                    [placeholder]="filterToBeApplied?.placeholder || filterToBeApplied.name"
                                    [maxWidth]="'650px'"
                                    [text]="filterToBeApplied.input"
                                    [valid]="validationErrors[filterToBeApplied.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                    [errorMessage]="validationErrors[filterToBeApplied.id]"
                                    (textChange)="onInputChange(filterToBeApplied, $event)"
                                    [type]="TEXT_FIELD_TYPES.NUMBER">
                                </beaconstac-textfield>
                            </div>
                            <div class="campaign-dropdown" *ngIf="filterToBeApplied.type == FILTER_OPERATOR_TYPE.Dropdown">
                                <beaconstac-filter-dropdown
                                    *ngIf="filterToBeApplied.name !== 'Owner'; else ownerFilterBlock"
                                    (click)="$event.stopPropagation()"
                                    [placeholder]="filterToBeApplied.placeholder || ''"
                                    [listItems]="filterToBeApplied.dropdownOptions"
                                    [title]="'toBeApplied-dropdown-' + filterToBeApplied.id | generateRandomId"
                                    [optionLabelField]="'text'"
                                    [optionValueField]="'value'"
                                    [disableInput]="filterToBeApplied.value !== 'tags'"
                                    [dynamicSearch]="false"
                                    [isFetchingData]="isFetachingTags"
                                    [valid]="validationErrors[filterToBeApplied.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                    [errorMessage]="validationErrors[filterToBeApplied.id]"
                                    (inputChangeEvent)="onFilterDropwdownChange(filterToBeApplied, $event)"
                                    (valueChange)="onDropdownInputChange(filterToBeApplied, $event)">
                                </beaconstac-filter-dropdown>

                                <ng-template #ownerFilterBlock>
                                    <beaconstac-textfield
                                        *ngIf="filterToBeApplied.selectedOperator?.name !== 'is'; else ownersListBlock"
                                        [label]="false"
                                        [title]="'tobeApplied-text-textfield-owner'+ filterToBeApplied.id"
                                        [placeholder]="filterToBeApplied?.placeholder || filterToBeApplied.name"
                                        [maxWidth]="'650px'"
                                        [valid]="validationErrors[filterToBeApplied.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                        [errorMessage]="validationErrors[filterToBeApplied.id]"
                                        [text]="filterToBeApplied.input"
                                        (textChange)="onInputChange(filterToBeApplied, $event)">
                                    </beaconstac-textfield>
                                    <ng-template #ownersListBlock>
                                        <beaconstac-filter-dropdown
                                            (click)="$event.stopPropagation()"
                                            [placeholder]="filterToBeApplied.placeholder || ''"
                                            [listItems]="activeUsersList"
                                            [title]="'toBeApplied-dropdown-owner-' + filterToBeApplied.id | generateRandomId"
                                            [optionLabelField]="'name'"
                                            [optionValueField]="'value'"
                                            [dynamicSearch]="true"
                                            [disableInput]="false"
                                            [enableInfiniteScrolling]="true"
                                            (fetchMoreData)="handleFetchActiveUsers(activeUsersPageNumber)"
                                            [isFetchingData]="isFetchingActiveUserList"
                                            [valid]="validationErrors[filterToBeApplied.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                            [errorMessage]="validationErrors[filterToBeApplied.id]"
                                            (inputChangeEvent)="handleChangesActiveUsersInput($event)"
                                            (valueChange)="onDropdownInputChange(filterToBeApplied, $event)">
                                        </beaconstac-filter-dropdown>
                                    </ng-template>
                                </ng-template>
                            </div>  
                            <div *ngIf="filterToBeApplied.type == FILTER_OPERATOR_TYPE.Date" style="margin-left: 20px">
                                <beaconstac-radio-button
                                    (click)="$event.stopPropagation()"
                                    [disabled]="false"
                                    (onClick)="onDateRadioOptionClicked($event,true);"
                                    [options]="filterToBeApplied.radioOptions"
                                ></beaconstac-radio-button>
                                <div *ngIf="filterToBeApplied.input == filterToBeApplied.radioOptions[4].name" id="date-pick-{{filterToBeApplied.id}}">
                                    <!--Angular Datepicker-->
                                    <div class="d-flex flex-column datepicker-parent">
                                        <beaconstac-textfield
                                            (keydown)="$event.preventDefault(); datepicker.open()"
                                            type="text"
                                            [placeholder]="'MM/DD/YYYY'"
                                            [maxWidth]="'650px'"
                                            [icon]="'fas fa-calendar-alt'"
                                            iconPosition = "left"
                                            (click)="datepicker.open()"
                                            [text]="filterToBeApplied.date.__startDate__ + ' - ' + filterToBeApplied.date.__endDate__"
                                            [label]="true"
                                            [title]="'Select Date Range'"
                                        >
                                        </beaconstac-textfield>
                                        <mat-date-range-input
                                            [rangePicker]="datepicker"
                                        >
                                            <input #dateRangeStart matStartDate>
                                            <input #dateRangeEnd matEndDate (dateChange)="onDateRangeSelected(dateRangeStart, dateRangeEnd, filterToBeApplied)">
                                        </mat-date-range-input>
                                        <mat-date-range-picker #datepicker></mat-date-range-picker>
                                    </div>

                                </div>
                            </div>
                            <div class="flex-row-reverse d-flex">
                                <beaconstac-button
                                    [style]="BUTTON_STYLES.FILLED"
                                    type="primary"
                                    [disabled]="isApplyButtonDisabled"
                                    label="Apply"
                                    [useCustomImage]="false"
                                    width=""
                                    (onClick)="onApplyFilterClicked(filterToBeApplied, true)">
                                </beaconstac-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isShowFiltersComponent && isMobileScreenSize && appliedFilters.length">
                <div  class="bx--row modal-wrapper bg-white" style="padding:40px 32px 24px 32px; border-radius: 12px 12px 0px 0px; width:-webkit-fill-available; margin:0px;">
                    <div class="bx--col">
                        <div class="bx--row" style="margin-bottom:32px;">
                            <div class="bx--col p-0">
                                <div style="color:#5E6980; text-transform: uppercase; font-weight:600; font-size:13px; line-height:15.25px; letter-spacing: 1px;">Filters</div>
                            </div>
                        </div>
                        <div class="bx--row" style="padding:12px 16px 0px 16px; border: 1px solid #2595FF; border-radius:4px; max-height: 376px; overflow-y: auto;">
                            <div class="bx--col p-0">
                                <div>
                                    <div class="bx-row mb-3" *ngFor="let filter of appliedFilters">
                                        <div class="p-0">
                                            <button
                                                (click)="onSelectFilter(filter)"
                                                class="btn dropdown-toggle no-caret no-box-shadow no-outline filter-chip"
                                                style="padding: 8px 12px !important;"
                                                type="button" id="{{ 'dropdownMenuButton-' + filter.id }}"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                [ngClass]="{ 'selected-filter-text'  : filter.id === selectedFilter?.id }">
                                                <span class="filter-chip-text"> {{ filter.name }} </span>
                                                <span *ngIf="filter.operators" class="filter-chip-operator">
                                                    {{filter.selectedOperator.name}}
                                                </span>
                                                <span class="filter-chip-input">
                                                    <span *ngIf="filter.type == FILTER_OPERATOR_TYPE.String || filter.type == FILTER_OPERATOR_TYPE.Number">"{{filter.input}}"</span>
                                                    <span *ngIf="filter.type == FILTER_OPERATOR_TYPE.Dropdown">"{{filter.dropDownChoice?.text}}"</span>
                                                    <span *ngIf="filter.type == FILTER_OPERATOR_TYPE.Date">
                                                        <span *ngIf="filter.input != 'Custom'">in {{filter.selectedRadioOption?.name}}</span>
                                                        <span *ngIf="filter.input == 'Custom'">between {{filter.date.__startDate__}} to {{filter.date.__endDate__}}</span>
                                                    </span>
                                                </span>
                                                <span class="filter-chip-remove-icon" (click)="onRemoveFilterClicked(filter)">
                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bx--row" style="margin-bottom: 32px;">
                            <div id = "add-filter-button-for-mobile-view">
                                <beaconstac-button
                                [style]="BUTTON_STYLES.TEXT"
                                [type]="BUTTON_TYPES.PRIMARY"
                                [icon]="'fas fa-plus'"
                                [iconPosition]="'left'"
                                label="Add Filter"
                                [useCustomImage]="false"
                                [width]="'100%'"
                                (onClick)="onAddFilterClickedForMobile()"
                                >
                                </beaconstac-button>
                            </div>
                        </div>
                        <div class="bx--row">
                            <div class="bx--col" style="padding-left:0px; padding-right:6px;">
                                <beaconstac-button
                                [type]="BUTTON_TYPES.SECONDARY"
                                [style]="BUTTON_STYLES.STROKE"
                                label="Reset Filters"
                                [width]="'100%'"
                                (onClick)="onResetFiltersClicked()"
                                >
                                </beaconstac-button>
                            </div>
                            <div class="bx--col" style="padding-right:0px; padding-left:6px;">
                                <beaconstac-button
                                [type]="BUTTON_TYPES.PRIMARY"
                                [style]="BUTTON_STYLES.FILLED"
                                label="Show Results"
                                [width]="'100%'"
                                (onClick)="isShowFiltersComponent = false"
                                >
                                </beaconstac-button>
                            </div>
                        </div> 
                    </div>
                    
                </div>
            </div>
            <div class="bx--row modal-wrapper bg-white" *ngIf="selectedFilter?.id && isMobileScreenSize && mobileDrawer" id="filterToBeAppliedForMobile" style="padding:40px 32px 24px 32px; border-radius: 12px 12px 0px 0px; margin:0px; width:-webkit-fill-available;">
                <div class="bx--col" style="padding:0px;">
                    <div class="bx--row" style="margin-bottom:32px;">
                        <div class="bx--col">
                            <div style="color:#5E6980; text-transform: uppercase; font-weight:600; font-size:13px; line-height:15.25px; letter-spacing: 1px;">{{selectedFilter.name}}</div>
                        </div>
                    </div>
                    <div class="bx--row" style="margin-bottom:32px;">
                        <div class="bx--col">
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="selectedFilter.operators" class="mb-4">
                                        <beaconstac-filter-dropdown
                                            (click)="$event.stopPropagation()"
                                            [value]="selectedFilter.selectedOperator"
                                            [style]="DROPDOWN_STYLES.STROKE"
                                            [title]="'applied-mobile-operator-' + selectedFilter.id | generateRandomId"
                                            [listItems]="selectedFilter.operators"
                                            [width]="'100%'"
                                            [optionLabelField]="'name'"
                                            [optionValueField]="'value'"
                                            (valueChange)="onSelectedFilterTypeChange($event)">
                                        </beaconstac-filter-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.String">
                                        <beaconstac-textfield
                                            [label]="false"
                                            [title]="'applied-text-textfield-'+selectedFilter.id"
                                            [placeholder]="selectedFilter?.placeholder || selectedFilter.name"
                                            [maxWidth]="'650px'"
                                            [text]="selectedFilter.input"
                                            (textChange)="selectedFilter.input = $event.trim();"
                                            (click)="$event.stopPropagation()"
                                            >
                                        </beaconstac-textfield>
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.Number">
                                        <beaconstac-textfield
                                            [label]="false"
                                            [title]="'applied-number-textfield'+selectedFilter.id"
                                            [placeholder]="selectedFilter?.placeholder || selectedFilter.name"
                                            [maxWidth]="'650px'"
                                            [(text)]="selectedFilter.input"
                                            (textChange)="selectedFilter.input = $event;"
                                            [type]="TEXT_FIELD_TYPES.NUMBER"
                                            (click)="$event.stopPropagation()"
                                        >
                                        </beaconstac-textfield>
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.Dropdown">
                                        <beaconstac-filter-dropdown
                                            (click)="$event.stopPropagation()"
                                            [listItems]="selectedFilter.dropdownOptions"
                                            [title]="'applied-mobile-dropdown-' + selectedFilter.id | generateRandomId"
                                            [value]="selectedFilter.dropDownChoice"
                                            [optionLabelField]="'text'"
                                            [optionValueField]="'value'"
                                            [disableInput]="selectedFilter.value !== 'tags'"
                                            [dynamicSearch]="false"
                                            [isFetchingData]="isFetachingTags"
                                            [width]="'100%'"
                                            (inputChangeEvent)="onFilterDropwdownChange(selectedFilter, $event)"
                                            (valueChange)="onDropdownInputChange(selectedFilter, $event);"
                                            [placeholder]="selectedFilter?.placeholder || ''"
                                        >
                                        </beaconstac-filter-dropdown>
        
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="selectedFilter.type == FILTER_OPERATOR_TYPE.Date" style="margin-left: 20px">
                                        <beaconstac-radio-button
                                            (click)="$event.stopPropagation()"
                                            [disabled]="false"
                                            (onClick)="onDateRadioOptionClickedForMobile($event);"
                                            [options]="selectedFilter.radioOptions"
                                        ></beaconstac-radio-button>
                                        <div *ngIf="selectedFilter.input == selectedFilter.radioOptions[4].name" id="date-pick-{{selectedFilter.id}}">
                                            <!--Angular Datepicker-->
                                            <div class="d-flex flex-column datepicker-parent">
                                                <beaconstac-textfield
                                                    (keydown)="$event.preventDefault(); datepicker.open()"
                                                    type="text"
                                                    [placeholder]="'MM/DD/YYYY'"
                                                    [maxWidth]="'650px'"
                                                    [icon]="'fas fa-calendar-alt'"
                                                    iconPosition = "left"
                                                    (click)="datepicker.open(); $event.stopPropagation();"
                                                    [text]="selectedFilter.date.__startDate__ + ' - ' + selectedFilter.date.__endDate__"
                                                    [label]="true"
                                                    [title]="'Select '+ selectedFilter.name + ' range'"
                                                >
                                                </beaconstac-textfield>
                                                <mat-date-range-input
                                                    [rangePicker]="datepicker"
                                                >
                                                    <input #dateRangeStart matStartDate>
                                                    <input #dateRangeEnd matEndDate (dateChange)="onDateRangeEdit(dateRangeStart, dateRangeEnd, selectedFilter)">
                                                </mat-date-range-input>
                                                <mat-date-range-picker #datepicker></mat-date-range-picker>
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="bx--row">
                        <div class="bx--col" style="padding-right:6px;">
                            <beaconstac-button
                                [style]="BUTTON_STYLES.STROKE"
                                type="primary"
                                label="Cancel"
                                [useCustomImage]="false"
                                [width]="'100%'"
                                (onClick)="selectedFilter = undefined; isShowFiltersComponent = true;">
                            </beaconstac-button>
                        </div>
                        <div class="bx--col" style="padding-left:6px;">
                            <beaconstac-button
                                [style]="BUTTON_STYLES.FILLED"
                                type="primary"
                                label="Apply"
                                [useCustomImage]="false"
                                [width]="'100%'"
                                (onClick)="onApplyFilterClicked(selectedFilter); isShowFiltersComponent = true;">
                            </beaconstac-button>
                        </div>
                    </div>
                            
                </div>
            </div>
            <div class="overlay" *ngIf="(filterToBeAppliedForMobile || isShowFiltersComponent || selectedFilter) && isMobileScreenSize && mobileDrawer"></div>
            <div class="bx--row modal-wrapper bg-white" *ngIf="filterToBeAppliedForMobile && isMobileScreenSize" id="filterToBeAppliedForMobile" style="padding:40px 32px 24px 32px; border-radius: 12px 12px 0px 0px; margin:0px; width:-webkit-fill-available;">
                <div class="bx--col" style="padding:0px;">
                    <div class="bx--row" style="margin-bottom:32px;">
                        <div class="bx--col">
                            <div style="color:#5E6980; text-transform: uppercase; font-weight:600; font-size:13px; line-height:15.25px; letter-spacing: 1px;">{{filterToBeAppliedForMobile.name}}</div>
                        </div>
                    </div>
                    <div class="bx--row" style="margin-bottom:32px;">
                        <div class="bx--col">
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="filterToBeAppliedForMobile.operators" class="mb-4">
                                        <beaconstac-filter-dropdown
                                            (click)="$event.stopPropagation()"
                                            [style]="DROPDOWN_STYLES.STROKE"
                                            [title]="'toBeApplied-operators-mobile-'+filterToBeAppliedForMobile.id | generateRandomId"
                                            [listItems]="filterToBeAppliedForMobile.operators"
                                            [value]="filterToBeAppliedForMobile.selectedOperator"
                                            [optionLabelField]="'name'"
                                            [optionValueField]="'value'"
                                            [width]="'100%'"
                                            [disableInput]="true"
                                            (valueChange)="onSelectedFilterTypeChange($event,true)"
                                        >
                                        </beaconstac-filter-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="filterToBeAppliedForMobile.type == FILTER_OPERATOR_TYPE.String">
                                        <beaconstac-textfield
                                            [label]="false"
                                            [title]="'tobeApplied-text-textfield-'+filterToBeAppliedForMobile.id"
                                            [placeholder]="filterToBeAppliedForMobile?.placeholder || filterToBeAppliedForMobile.name"
                                            [maxWidth]="'650px'"
                                            [valid]="validationErrors[filterToBeAppliedForMobile.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                            [errorMessage]="validationErrors[filterToBeAppliedForMobile.id]"
                                            [text]="filterToBeAppliedForMobile.input"
                                            (textChange)="onInputChange(filterToBeAppliedForMobile, $event)">
                                        </beaconstac-textfield>
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="filterToBeAppliedForMobile.type == FILTER_OPERATOR_TYPE.Number">
                                        <beaconstac-textfield
                                            [label]="false"
                                            [title]="'toBeApplied-number-textfield-'+filterToBeAppliedForMobile.id"
                                            [placeholder]="filterToBeAppliedForMobile?.placeholder || filterToBeAppliedForMobile.name"
                                            [maxWidth]="'650px'"
                                            [text]="filterToBeAppliedForMobile.input"
                                            [valid]="validationErrors[filterToBeAppliedForMobile.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                            [errorMessage]="validationErrors[filterToBeAppliedForMobile.id]"
                                            (textChange)="onInputChange(filterToBeAppliedForMobile, $event)"
                                            [type]="TEXT_FIELD_TYPES.NUMBER">
                                        </beaconstac-textfield>
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div class="campaign-dropdown" *ngIf="filterToBeAppliedForMobile.type == FILTER_OPERATOR_TYPE.Dropdown">
                                        <beaconstac-filter-dropdown
                                            (click)="$event.stopPropagation()"
                                            [placeholder]="filterToBeAppliedForMobile.placeholder || ''"
                                            [listItems]="filterToBeAppliedForMobile.dropdownOptions"
                                            [title]="'toBeApplied-dropdown-mobile-' + filterToBeAppliedForMobile.id | generateRandomId"
                                            [optionLabelField]="'text'"
                                            [optionValueField]="'value'"
                                            [disableInput]="filterToBeAppliedForMobile.value !== 'tags'"
                                            [dynamicSearch]="false"
                                            [isFetchingData]="isFetachingTags"
                                            [width]="'100%'"
                                            [valid]="validationErrors[filterToBeAppliedForMobile.id] ? TEXT_FIELD_VALIDATIONS.ERROR : TEXT_FIELD_VALIDATIONS.VALID"
                                            [errorMessage]="validationErrors[filterToBeAppliedForMobile.id]"
                                            (inputChangeEvent)="onFilterDropwdownChange(filterToBeAppliedForMobile, $event)"
                                            (valueChange)="onDropdownInputChange(filterToBeAppliedForMobile, $event)"
                                            >
        
                                        </beaconstac-filter-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="bx--row">
                                <div class="bx--col">
                                    <div *ngIf="filterToBeAppliedForMobile.type == FILTER_OPERATOR_TYPE.Date" style="margin-left: 20px">
                                        <beaconstac-radio-button
                                            (click)="$event.stopPropagation()"
                                            [disabled]="false"
                                            (onClick)="onDateRadioOptionClickedForMobile($event,true);"
                                            [options]="filterToBeAppliedForMobile.radioOptions"
                                        ></beaconstac-radio-button>
                                        <div *ngIf="filterToBeAppliedForMobile.input == filterToBeAppliedForMobile.radioOptions[4].name" id="date-pick-{{filterToBeAppliedForMobile.id}}">
                                            <!--Angular Datepicker-->
                                            <div class="d-flex flex-column datepicker-parent">
                                                <beaconstac-textfield
                                                    (keydown)="$event.preventDefault(); datepicker.open()"
                                                    type="text"
                                                    [placeholder]="'MM/DD/YYYY'"
                                                    [maxWidth]="'650px'"
                                                    [icon]="'fas fa-calendar-alt'"
                                                    iconPosition = "left"
                                                    (click)="datepicker.open()"
                                                    [text]="filterToBeAppliedForMobile.date.__startDate__ + ' - ' + filterToBeAppliedForMobile.date.__endDate__"
                                                    [label]="true"
                                                    [title]="'Select Date Range'"
                                                >
                                                </beaconstac-textfield>
                                                <mat-date-range-input
                                                    [rangePicker]="datepicker"
                                                >
                                                    <input #dateRangeStart matStartDate>
                                                    <input #dateRangeEnd matEndDate (dateChange)="onDateRangeSelected(dateRangeStart, dateRangeEnd, filterToBeAppliedForMobile)">
                                                </mat-date-range-input>
                                                <mat-date-range-picker #datepicker></mat-date-range-picker>
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="bx--row">
                        <div class="bx--col pl-2">
                            <beaconstac-button
                                [style]="BUTTON_STYLES.STROKE"
                                type="primary"
                                label="Cancel"
                                [useCustomImage]="false"
                                [width]="'100%'"
                                (onClick)="filterToBeAppliedForMobile = undefined">
                            </beaconstac-button>
                        </div>
                        <div class="bx--col pl-2">
                            <beaconstac-button
                                [style]="BUTTON_STYLES.FILLED"
                                type="primary"
                                [disabled]="filterToBeAppliedForMobile.type === FILTER_OPERATOR_TYPE.Dropdown ? !filterToBeAppliedForMobile.dropDownChoice : validationErrors[filterToBeAppliedForMobile.id] ?  true : false"
                                label="Apply"
                                [useCustomImage]="false"
                                [width]="'100%'"
                                (onClick)="onApplyFilterClicked(filterToBeAppliedForMobile, true)">
                            </beaconstac-button>
                        </div>
                    </div>
                            
                </div>
            </div>

            <!-- ADD FILTER BUTTON -->
            <beaconstac-dropdown
                *ngIf="qrCodeCount > 0 && (!mobileDrawer || !isMobileScreenSize)"
                [style]="DROPDOWN_STYLES.STROKE"
                [type]="DROPDOWN_TYPES.PRIMARY"
                [showIconAndTitle]="true"
                [iconClass]="'fas fa-filter'"
                [title]="'Add Filters'"
                [listItems]="localFilterOptions"
                [optionLabelField]="'name'"
                [optionValueField]="'value'"
                [showCaret]="false"
                (valueChange)="onAddFilterClicked($event);"
            ></beaconstac-dropdown>
            <beaconstac-button
                *ngIf = "isMobileScreenSize && mobileDrawer && appliedFilters.length === 0"
                [style]="DROPDOWN_STYLES.STROKE"
                [type]="DROPDOWN_TYPES.PRIMARY"
                [icon]="'fas fa-filter'"
                label ="Add Filter"
                [width]="'139px'"
                [iconSize]="ICON_SIZE.SMALL"
                (click)="isShowFilterMobileDrawer = true"
                [id]="'filterToBeAppliedButton'"
            >
            </beaconstac-button>
            <beaconstac-button
                *ngIf = "isMobileScreenSize && mobileDrawer && appliedFilters.length !== 0"
                [style]="DROPDOWN_STYLES.STROKE"
                [type]="DROPDOWN_TYPES.PRIMARY"
                [icon]="'fas fa-filter'"
                [label] ="'Filters (' + appliedFilters.length +')'"
                [width]="'139px'"
                [iconSize]="ICON_SIZE.SMALL"
                (click)="isShowFiltersComponent = true"
                [id]="'filterToBeAppliedButton'"
            >
            </beaconstac-button>
        </div>
        <!-- RESET FILTER BUTTON -->
        <div class="d-none d-md-block" >
            <beaconstac-button
                *ngIf="appliedFilters.length || filterToBeApplied"
                [style]="BUTTON_STYLES.TEXT"
                [type]="BUTTON_TYPES.SECONDARY"
                [disabled]="false"
                label="Reset Filters"
                [useCustomImage]="false"
                (onClick)="onResetFiltersClicked()"
                [id]="'filter-reset-button'">
            </beaconstac-button>
        </div>
    </div>
    <div class="d-md-none">
        <beaconstac-button
            *ngIf="(appliedFilters.length || filterToBeApplied) && !mobileDrawer"
            [style]="BUTTON_STYLES.TEXT"
            [type]="BUTTON_TYPES.SECONDARY"
            [disabled]="false"
            label="Reset Filters"
            [useCustomImage]="false"
            width=""
            [id]="'filter-reset-button-mobile'"
            (onClick)="onResetFiltersClicked()">
            </beaconstac-button>
    </div>
</div>
<beaconstac-mobile-drawer *ngIf="isMobileScreenSize" [id]="'filterToBeAppliedButton'" [isDrawerOpen]="isShowFilterMobileDrawer" (onOutsideClick)="isShowFilterMobileDrawer = !isShowFilterMobileDrawer">
    <div 
    class="p-0 pl-0 mb-2"
    style="color:#5E6980; opacity: 50%; text-transform: uppercase; line-height: 15.25px; letter-spacing: 1px; font-weight: 600; font-size:13px;  display: block;"
        >
      Filter by
    </div>
    <div 
    *ngFor="let option of localFilterOptions"       
    (click) = "onAddFilterClicked(option)"
    >
    <span
    class = "mb-2"
    style="color:#5E6980; line-height: 24px; letter-spacing: 1px; font-weight: 400; font-size:15px; display: block;"
    >
        {{option.name}}
    </span>

    </div>
    <div>
        <span style="color:#2595FF;" (click)="handleClickGoBack()">
            Go Back
        </span>
    </div>
  </beaconstac-mobile-drawer>

