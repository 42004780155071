<div class="sticky-container d-flex flex-column align-items-center">
    <div class="tertiary-button bx--row" (click)="tertiaryCtaAction()" (keyup)="tertiaryCtaAction()" (keydown)="tertiaryCtaAction()" [style.display]="isTertiaryButtonActive ? 'none' : ''" *ngIf="showTertiaryButton">
        <div class="tertiary-icon">
            <i class="fa fa-eye"></i>
        </div>

        <div class="tertiary-text">
            {{tertiaryButtonText}}
        </div>
    </div>
    <div class="sticky-button-container bg-white d-flex">
        <div class="sticky-buttons secondary-cta" *ngIf="secondaryCtaName">
            <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                               [style]="BUTTON_STYLES.STROKE"
                               [label]="secondaryCtaName"
                               [width]="'100%'"
                               (onClick)="secondaryCtaAction()">
            </beaconstac-button>
        </div>

        <div class="sticky-buttons primary-cta" *ngIf="primaryCtaName">
            <beaconstac-button [type]="BUTTON_TYPES.PRIMARY"
                               [style]="BUTTON_STYLES.FILLED"
                               [label]="primaryCtaName"
                               [width]="'100%'"
                               (onClick)="primaryCtaAction()"
                               [disabled]="primaryButtonDisabled">
            </beaconstac-button>
        </div>
    </div>
</div>
