import { NgModule } from '@angular/core';
import { FilterProductsComponent } from './filter-products.component';
import { BeaconstacModule } from 'kaizen-design-system';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {PipesModule} from '../pipes/pipes.module';
import {BeaconstacDatepickerModule} from '../beaconstac-datepicker/beaconstac-datepicker.module';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { BeaconstacMobileDrawerModule } from '../beaconstac-mobile-drawer/beaconstac-mobile-drawer.module';
@NgModule({
    declarations: [
        FilterProductsComponent
    ],
    imports: [
        CommonModule,
        BeaconstacModule,
        BsDropdownModule,
        FormsModule,
        BsDatepickerModule,
        PipesModule,
        BeaconstacDatepickerModule,
        MatInputModule,
        MatDatepickerModule,
        BeaconstacMobileDrawerModule
    ],
    exports: [
        FilterProductsComponent
    ]
})

export class FilterProductModule { }
