import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BeaconstacDatepickerComponent} from './beaconstac-datepicker.component';
import {BeaconstacModule} from 'kaizen-design-system';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule} from '@angular/forms';



@NgModule({
    declarations: [BeaconstacDatepickerComponent],
    exports: [
        BeaconstacDatepickerComponent
    ],
    imports: [
        CommonModule,
        BeaconstacModule,
        MatDatepickerModule,
        FormsModule
    ]
})
export class BeaconstacDatepickerModule { }
