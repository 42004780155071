<div class="bx--row mb-4">
    <div class="bx--col">
        <div>
            <span class="mb-2 font-weight-400" style="font-size: 20px;">Design Your Card</span>
            <span *ngIf="!hasDBCAdvancedCustomization" [ngClass]="isMobileDevice ? 'mt-2' : 'ml-3'">
                <beaconstac-button
                    [type]="BUTTON_TYPES.PRIMARY"
                    [style]="BUTTON_STYLES.STROKE"
                    [label]="'Advanced Customization'"
                    [icon]="'fas fa-crown text-warning'"
                    [iconPosition]="ICON_POSITION.RIGHT"
                    (onClick)="showAdvancedCustomizationUpsell()"
                >
          </beaconstac-button>
            </span>
        </div>

    </div>
</div>
<div class="bx--row">
    <div class="bx--col cursor-pointer" (click)="collapseSection(COLLAPSE_SECTION.LOGO)">
                <span
                    class="section-headline">
                  UPLOAD COMPANY LOGO
                    <span [ngClass]="cardSettings.logo_url | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOGO_URL: cardSettings"></span>
                </span>
        <span class="float-right">
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                     [icon]="sections.logo ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                                     [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                </span>
    </div>
</div>

<div *ngIf="!sections.logo" class="bx--row" [@collapse]>
    <div class="bx--col pl-2 my-4">
        <div style="max-width: 400px;">
            <beaconstac-upload
                [width]="'webkit-fill-available'"
                [isUploading]="isLogoUploading"
                [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']" [maxSize]="5e+6"
                [height]="'auto'"
                [preview]="dbcBulk.logo_url" [overrideClick]="hasWriteAccess || !cardSettings.logo_url"
                (onClick)="updateLogo(IMAGE_TYPE.Logo)" [disabled]="!hasWriteAccess || cardSettings.logo_url" [showRemoveButton]="true"
            >
            </beaconstac-upload>
        </div>
    </div>
</div>

<div class="bx--row mt-2">
    <div class="bx--col">
        <hr style="border: 1px dashed #DBDFE3">
    </div>
</div>

<div class="bx--row" style="margin-top: 12px">
    <div class="bx--col cursor-pointer" (click)="collapseSection(COLLAPSE_SECTION.COLOR)">
                <span
                    class="section-headline">
                  COLOR
                    <span [ngClass]="cardSettings.customizations | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS: cardSettings"></span>
                </span>
        <span class="float-right">
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                     [icon]="sections.color ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                                     [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                </span>
    </div>
</div>
<div *ngIf="!sections.color" [@collapse]>
    <div class="bx--row mt-2">
        <div class="bx--col">
            <beaconstac-color-picker
                [label]="true"
                [title]="'Primary color'"
                [(color)]="dbcBulk.background_color"
                [disabled]="!hasWriteAccess || cardSettings.customizations"
                (colorPickerChange)="onColorPickerChange($event, DBC_CUSTOMIZATION.PrimaryColor)"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [id]="'primary_color'"
                [cpWidth]="'300px'"
            ></beaconstac-color-picker>
        </div>
    </div>

    <div class="bx--row mt-2" [@collapse]>
        <div class="bx--col">
            <beaconstac-color-picker
                [label]="true"
                [title]="'Profile text color'"
                [(color)]="dbcBulk.user_info_color"
                [disabled]="!hasWriteAccess || cardSettings.customizations"
                (colorPickerChange)="onColorPickerChange($event, DBC_CUSTOMIZATION.UserInfoColor)"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [id]="'user_info_color'"
                [cpWidth]="'300px'"
            ></beaconstac-color-picker>
        </div>
    </div>

    <div class="bx--row mt-2">
        <div class="bx--col">
            <beaconstac-color-picker
                [label]="true"
                [title]="'Secondary color'"
                [(color)]="dbcBulk.secondary_color"
                [disabled]="!hasWriteAccess || cardSettings.customizations"
                (colorPickerChange)="onColorPickerChange($event, DBC_CUSTOMIZATION.SecondaryColor)"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [id]="'secondary_color'"
                [cpWidth]="'300px'"
            ></beaconstac-color-picker>
        </div>
    </div>

    <div class="bx--row mt-2" *ngIf="hasDBCAdvancedCustomization">
        <div class="bx--col">
            <beaconstac-color-picker
                [label]="true"
                [title]="'Button color'"
                [(color)]="dbcBulk.customizations.button_color"
                [disabled]="!hasWriteAccess || cardSettings.customizations"
                (colorPickerChange)="onColorPickerChange($event, DBC_CUSTOMIZATION.ButtonColor)"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [id]="'button_color'"
                [cpWidth]="'300px'"
            ></beaconstac-color-picker>
        </div>
    </div>

    <div class="bx--row mt-2" *ngIf="hasDBCAdvancedCustomization">
        <div class="bx--col">
            <beaconstac-color-picker
                [label]="true"
                [title]="'Icon color'"
                [(color)]="dbcBulk.customizations.icon_color"
                [disabled]="!hasWriteAccess || cardSettings.customizations"
                (colorPickerChange)="onColorPickerChange($event, DBC_CUSTOMIZATION.IconColor)"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [id]="'icon_color'"
                [cpWidth]="'300px'"
            ></beaconstac-color-picker>
        </div>
    </div>

    <div *ngIf="hasDBCAdvancedCustomization">
        <div class="font-fs-13 pt-2">Card background</div>
        <div class="bx--row p-0 mx-1 my-2 fs-13">
            <beaconstac-radio-button
                [disabled]="!hasWriteAccess || cardSettings.customizations"
                (onClick)="setBackgroundType(DBC_BACKGROUND_TYPE.Color)"
                [options]="[{name: 'Color', state: !dbcBackgroundIsImg, disabled: false}]"
            ></beaconstac-radio-button>

            <beaconstac-radio-button
                [disabled]="!hasWriteAccess || cardSettings.customizations"
                (onClick)="setBackgroundType(DBC_BACKGROUND_TYPE.Image)"
                [options]="[{name: 'Image', state: dbcBackgroundIsImg, disabled: false}]"
            ></beaconstac-radio-button>
        </div>

        <div *ngIf="!dbcBackgroundIsImg">
            <beaconstac-color-picker
                [(color)]="dbcBulk.customizations.background.value"
                [disabled] = "!hasWriteAccess || cardSettings.customizations"
                (colorPickerChange)="onBackgroundColorChange($event)"
                [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                [cpPosition]="'bottom'"
                [id]="'dbc_background_color'"
                [cpWidth]="'300px'"
            ></beaconstac-color-picker>
        </div>

        <div *ngIf="dbcBackgroundIsImg" style="max-width: 400px;">
            <beaconstac-upload #BgImgDrop
                               [width]="'webkit-fill-available'"
                               (fileChange)="onBgImgAdded($event)"
                               [isUploading]="bgImgUpload.isUploading"
                               [acceptTypes]="['image/png', 'image/jpeg']"
                               [maxSize]="5e+6"
                               [height]="'auto'"
                               [preview]="bgImgUpload.mediaUrl"
                               [overrideClick]="hasWriteAccess"
                               (onClick)="bgImgSelectorModal.show()"
                               [disabled] = "!hasWriteAccess || cardSettings.customizations"
                               [showRemoveButton]="true"
                               (onFileRemove)="onBgImgRemoved()">
            </beaconstac-upload>
        </div>
    </div>

    <div class="bx--col-lg-14 mb-3"></div>
</div>
<app-typography-fonts
[cardSetting]="cardSettings"
[cardType]="cardType"
[hasWriteAccess]="hasWriteAccess"
[orgCardSetting]="orgCardSettings"
[priortizeOrgSettings]="priortizeOrgSettings"
[(product)]="dbcBulk"
>
</app-typography-fonts>

<div bsModal #imageSelectModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}"
     (onShow)="setupImageSelectModal()" (onHide)="imageSelectGallery.onHide(); isLogoUploading = false">
    <div class="modal-dialog modal-info modal-lg" role="document">
        <div class="modal-content modal-content-kz">

            <div class="modal-header-kz">
                <h4 class="modal-title">Select {{ isLogoUploading ? 'a Logo' : 'an image'}}</h4>
            </div>

            <div class="modal-body modal-body-kz">
                <div class="form-group">
                    <app-media-gallery #imageSelectGallery (fileUploaded)="loadCropper($event)"
                                       (mediaSelector)="imageSelectedInGallery($event)" [addMedia]="updatedMedia"
                                       [dimensions]="imageSelectModel.type === IMAGE_TYPE.Logo ? '192x192' : '1024x1024'"
                                       [isLogo]="imageSelectModel.type === IMAGE_TYPE.Logo" (uploadToFolderId)="setUploadToFolder($event)"
                                       [searchParams]="{'content_type__nin':'image/gif'}"
                                       [acceptedTypes]="['image/jpeg', 'image/png', 'image/svg+xml']"
                                       [isFooterVisible]="true"
                                       [isSelectDisabled]="!imageSelectModel.media"
                                       (onSelect)="selectImage()"
                                       (onClose)="imageSelectModal.hide()"></app-media-gallery>
                </div>
            </div>
        </div>
    </div>
</div>


<app-image-cropper #imageCropper *ngIf="imageCropperFile" [imageFile]="imageCropperFile"
                   (cropperClose)="closeImageCropperModal()" [squareDragMode]="true" [folderId]="uploadToFolder"
                   (imageUpdated)="updateMedia($event)" [imageAspectRatio]="imageSelectModel.type === IMAGE_TYPE.Logo?'': '1'"
                   [showUploadOriginal]="imageSelectModel.type === IMAGE_TYPE.Logo"></app-image-cropper>


<div bsModal #bgImgSelectorModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" (onShow)="setupBgImgSelectModal()" (onHide)="bgImgSelectGallery.onHide(); bgImgUpload.isUploading = false">
    <div class="modal-dialog modal-info modal-lg" role="document">
        <div class="modal-content modal-content-kz">

            <div class="modal-header-kz">
                <h4 class="modal-title">Select Background Image</h4>
            </div>

            <div class="modal-body modal-body-kz">
                <div class="form-group">
                    <app-media-gallery #bgImgSelectGallery
                                       (fileUploaded)="loadBgImageCropper($event)"
                                       (mediaSelector)="bgImageSelectedInGallery($event)"
                                       [addMedia]="bgImgUpload.media"
                                       [dimensions]="'1024x1024'"
                                       (uploadToFolderId)="setUploadToFolderForBgImg($event)"
                                       [searchParams]="{'content_type__nin':'image/gif'}"
                                       [acceptedTypes]="['image/jpeg', 'image/png']"
                                       [isFooterVisible]="true"
                                       [isSelectDisabled]="!bgImgUpload.media"
                                       (onSelect)="onSelectBgImg()"
                                       (onClose)="onCloseBgImgSelectGallery();">
                    </app-media-gallery>
                </div>
            </div>
        </div>
    </div>
</div>

<app-image-cropper #bgImageCropper *ngIf="bgImageCropperFile"
                   [imageFile]="bgImageCropperFile"
                   (cropperClose)="closeBgImageCropperModal()"
                   [squareDragMode]="true"
                   [folderId]="uploadToFolderForBgImg"
                   (imageUpdated)="updateBgMedia($event)"
                   [imageAspectRatio]="'1'">
</app-image-cropper>

