import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ModalModule } from 'ngx-bootstrap/modal';
import { LowerCaseTextInputModule } from '../shared/lower-case-text-input/lower-case-text-input.module';
import { MessageModalModule } from '../shared/message-modal/message-modal.module';
import { RenewPlanComponent, IsOnHigherPlan } from './renew-plan.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {BeaconstacModule} from 'kaizen-design-system';
import {PipesModule} from '../global-components/pipes/pipes.module';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import { DbcRenewComponentModule } from 'app/global-components/dbc-renew-component/dbc-renew-component.module';
import { QRPricingModule } from 'app/global-components/qr-pricing/qr-pricing.module';
import { FeatureDetailsModule } from 'app/global-components/feature-details/feature-details.module';



@NgModule({
    declarations: [
        RenewPlanComponent,
        IsOnHigherPlan
    ],
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        FormsModule,
        TooltipModule.forRoot(),
        LowerCaseTextInputModule,
        MessageModalModule,
        BeaconstacModule,
        PipesModule,
        CollapseModule,
        DbcRenewComponentModule,
        QRPricingModule,
        FeatureDetailsModule,
    ]
})
export class RenewPlanModule { }
