import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BulkActionComponent} from './bulk-action.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import { BeaconstacModule} from 'kaizen-design-system';


@NgModule({
    declarations: [
        BulkActionComponent
    ],
    imports: [
        CommonModule,
        ModalModule,
        BeaconstacModule
    ],
    exports: [
        BulkActionComponent
    ]
})
export class BulkActionModule { }
