import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BeaconstacProductLabelComponent} from './beaconstac-product-label.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FormsModule} from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import {BeaconstacModule} from 'kaizen-design-system';
import {GetTagCount} from './beaconstac-product-label.component';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        PipesModule,
        FormsModule,
        BeaconstacModule
    ],
    declarations: [
        BeaconstacProductLabelComponent,
        GetTagCount
    ],
    exports: [BeaconstacProductLabelComponent],
})
export class BeaconstacProductLabelModule {
}
