<div bsModal #languageModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
  <div class="modal-dialog modal-info" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h4 class="modal-title">Add language / locale</h4>
        <button type="button" class="close" (click)="resetLanguage()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->

      <div class="modal-body p-4">
        <div class="bx--row">
          <p class="bx--col" *ngIf="listType === NotificationListType.LANGUAGE"
             style="font-size: 20px; line-height: 23px;">Add language / locale</p>
          <p class="bx--col" *ngIf="listType === NotificationListType.LOCATION"
             style="font-size: 20px; line-height: 23px;">Add country</p>
        </div>
        <div class="bx--row mt-2">
          <div class="bx--col">
            <beaconstac-filter-dropdown
              [value] = "{ 'name': selectedItem, 'value': selectedItem }"
              title="languageSelector"
              [label]="listType === NotificationListType.LANGUAGE ? 'Select language' : 'Select Country'"
              [listItems]="allLanguagesList"
              [placeholder]="listType === NotificationListType.LANGUAGE ? 'Select language' : 'Select Country'"
              optionLabelField="name"
              optionValueField="value"
              (valueChange) = "selectedItem = $event.value"
              [dynamicSearch]="false"
              [width]="'100%'"
            ></beaconstac-filter-dropdown>
          </div>
        </div>
        <div *ngIf="!noTitle" class="bx--row mt-3">
          <div class="bx--col mt-1">
            <beaconstac-textfield
              [label]="true"
              [disabled]="false"
              [(text)]="title"
              placeholder="Your notification title"
              title="Title*"
              [maxWidth]="'100%'"
              [id]="'multi-title'"
            ></beaconstac-textfield>
          </div>
        </div>
        <div *ngIf="!noTitle" class="bx--row mt-3">
          <div class="bx--col mt-1">
            <beaconstac-textfield
              [label]="true"
              [disabled]="false"
              [(text)]="description"
              placeholder="Your notification description"
              title="Description*"
              [maxWidth]="'100%'"
              [id]="'multi-description'"
            ></beaconstac-textfield>
          </div>
        </div>
      </div>

      <div class="modal-body p-4">
        <div class="mt-2">
          <span style="float: right; display: flex; flex-direction: row; margin-top: -6px;">
            <span class="pt-0">
              <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" (onClick)="resetLanguage()" [label]="'Cancel'"></beaconstac-button>
            </span>
            <span class="pt-0" style="padding-left: 16px">
              <beaconstac-button (onClick)="addLanguage()" [label]="'Add'"
                                 [id]="listType === NotificationListType.LOCATION ? 'add-location' : 'add-language'"
                                 [disabled]="!noTitle && title.length == 0"></beaconstac-button>
            </span>
          </span>
        </div>
        <!-- <button type="button" class="btn btn-secondary" (click)="resetLanguage()">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!noTitle && title.length == 0" (click)="addLanguage()" id = "add-language">Add</button> -->
      </div>

    </div>
  </div>
</div>
