<div class="d-block d-md-none bg-white">
  <img class="login-page-logo" [src]="brandLogo">
</div>
<div>
  <div class="main-container d-md-flex" [ngStyle]="{ 'background-image': 'url(' + backgroundImage + ')'}">
    <div class="form-container d-md-flex" *ngIf ="showModal">
      <div class="left-container position-relative">
        <h3 style="font-size: 26px">Tell us a little about yourself.</h3>
        <p class="subheading">Help us recommend the best layouts and industry trends for you.</p>
        <div class="form-group w-100 mt-4">
          <beaconstac-filter-dropdown [width]="'100%'" [label]="'Company Size'" [title]="'Company Size'" [value]="'companySize'"
            [placeholder]="'Select your company size'" (valueChange)="selectCompanySize($event)" [optionValueField]="'value'"
            [optionLabelField]="'name'" [listItems]="companySizeList"></beaconstac-filter-dropdown>
        </div>
        <div class="form-group w-100">
          <beaconstac-filter-dropdown [width]="'100%'"
                                      [label]="'Industry'"
                                      [title]="'Industry'"
                                      [value]="'industryName'"
                                      [placeholder]="'Select an industry'"
                                      (valueChange)="selectIndustry($event)"
                                      [optionValueField]="'value'"
                                      [optionLabelField]="'name'"
                                      [listItems]="industryList"></beaconstac-filter-dropdown>

          <div class="mt-2" *ngIf="industryOther" [@collapse]>
            <beaconstac-textfield [label]="false" [placeholder]="'Enter Industry'" [(text)]="industryName"
              [type]="TEXT_FIELD_TYPES.TEXT" [required]="true" [maxWidth]="'100%'"></beaconstac-textfield>
          </div>
        </div>
        <div *ngIf="showPhoneNumberField" class="form-group d-flex align-items-end">
        <div class="flex-shrink-1">
        <beaconstac-filter-dropdown [width]="'85px'"
                                    [label]="'Phone Number'"
                                    [title]="'Phone Number'"
                                    [(value)]="userCountryDialingCode"
                                    (valueChange)="selectCountryCode($event)"
                                    [optionValueField]="'value'"
                                    [optionLabelField]="'name'"
                                    [listItems]="countryDialingCodesList"></beaconstac-filter-dropdown></div>
          <div class="user-segmentation-phone-number flex-grow-1"><beaconstac-textfield [placeholder]="'Enter phone number'"
            [type]="TEXT_FIELD_TYPES.NUMBER" [(text)]="userSegmentPhoneNumber"
            [valid]="isUserSegmentPhoneNumberValid ? TEXT_FIELD_VALIDATIONS.VALID: TEXT_FIELD_VALIDATIONS.ERROR"
            [errorMessage]="'Invalid mobile number'" [disabled]="false" [width]="'100%'" [maxWidth]="'100%'"
            (textChange)="checkPhoneValidation($event)"></beaconstac-textfield></div>
        </div>
        <button class="btn btn-primary submit-btn text-uppercase" (click)="submitDetails()"
                [disabled]="!industryName || !companySize">START CREATING
        </button>
        <p class="mb-0 text-center text-secondary skip-mobile d-none" style="font-weight: 600;cursor: pointer" (click)="cancelPressed()">
          <a>I’ll do it later</a>
        </p>
      </div>

      <div class="right-container">
        <i (click)="cancelPressed()" class="fas fa-times fa-lg position-absolute cursor-pointer cancel-button" aria-hidden="true"></i>
        <img src="../../assets/img/user-detail-logos.png">
        <p class="mb-0 text-center text-secondary d-none" style="font-weight: 600;cursor: pointer" (click)="cancelPressed()">
          <a>I’ll do it later</a></p>
      </div>
    </div>
  </div>
</div>
