import { Component, Input } from '@angular/core';

@Component({
    selector: 'beaconstac-new-dbc-stepper',
    templateUrl: './dbc-stepper-new.component.html',
    styleUrls: ['./dbc-stepper-new.component.scss']
})

export class NewDbcStepperComponent {
    @Input() goToNext: (step: number) => void;
    @Input() selectedStep: number;
}
