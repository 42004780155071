import {Component, Input, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {BUTTON_STYLES, BUTTON_TYPES, CARET_POSITION, ICON_POSITION, TOOLTIP_POSITION} from 'kaizen-design-system';
import {Animations} from '../../../shared/beaconstac-animations';
import {DBCCardTypes, Utils} from '../../../shared/utils';
import {DBC_BACKGROUND_TYPE, DigitalBusinessCardBulkModel} from '../../digital-business-card.model';
import {Media} from '../../../places/media.model';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {MediaGalleryComponent} from '../../../global-components/media-gallery/media-gallery.component';
import {ImageCropperComponent} from '../../../global-components/image-cropper/image-cropper.component';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../../digital-business-card-setting/digital-business-card-setting.model';
import {CardsOrganizationRestrictedFields} from '../../../user-account/user-organization.model';
import {finalize, takeUntil} from 'rxjs/operators';
import {MediaFolderService} from '../../../media-folders/media-folder.service';
import {MediaService} from '../../../places/media-service';
import {MessageModalService} from '../../../shared/message-modal/message-modal.service';
import {Subject} from 'rxjs';
import {AuthService} from '../../../global-services/auth.service';
import {FEATURES} from '../../../user-account/organization-permission-model';
import {isMobileDevice} from '../../../link-page/linkpage-utils';
import {environment} from 'environments/environment';
import {PLAN_TYPES} from '../../../user-account/user.model';
import {UpsellService} from '../../../global-services/upsell.service';


enum COLLAPSE_SECTION {
    LOGO,
    COLOR,
    FONT,
    LAYOUT
}

enum IMAGE_TYPE {
    Logo= 1,
}

enum DBC_CUSTOMIZATION {
    PrimaryColor,
    UserInfoColor,
    SecondaryColor,
    ButtonColor,
    IconColor
}

@Component({
    selector: 'app-digital-business-card-bulk-design',
    templateUrl: './digital-business-card-bulk-design.component.html',
    styleUrls: ['./digital-business-card-bulk-design.component.scss'],
    animations: [Animations.collapse]
})

export class DigitalBusinessCardBulkDesignComponent implements OnInit, OnDestroy {

    @Input() dbcBulk: DigitalBusinessCardBulkModel;
    @Input() cardSettings: DigitalBusinessCardSetting;
    @Input() orgCardSettings: CardsOrganizationRestrictedFields;
    @Input() hasWriteAccess: boolean = true;
    @Input() priortizeOrgSettings: boolean = false;
    @Input() launchDarkleyFlags: {};
    @ViewChild('bgImageCropper', {static: false}) private bgImageCropper: ImageCropperComponent;
    @ViewChild('advancedCustomizationsUpsell', {static: false}) advancedCustomizationsUpsell: ModalDirective;

    tempColor: string = '#2595FF';
    sections = {
        logo: false,
        color: false,
        font: false,
        layout: true,
    }

    Utils = Utils;
    fontList: Array<any> = Utils.available_fonts;
    layoutData: Array<any> = Utils.DBC_LAYOUTS;

    BUTTON_STYLES = BUTTON_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;
    COLLAPSE_SECTION = COLLAPSE_SECTION;
    DBC_CUSTOMIZATION = DBC_CUSTOMIZATION;
    IMAGE_TYPE = IMAGE_TYPE;
    isIconUploading: boolean = false;
    isBackgroundUploading: boolean = false;
    imageSelectModel = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
        type: this.IMAGE_TYPE.Logo
    };
    imageCropperFile: File;
    updatedMedia: Media = null;
    isLogoUploading: boolean = false;
    uploadToFolder = null;
    cardType: DBCCardTypes = DBCCardTypes.BULK_CARDS;
    dbcBackgroundIsImg: boolean = false;
    /* Asset Library variables for bgImgUpload */
    bgImgUpload: {
        media: Media,
        mediaUrl: string,
        isUploading: boolean,
    } = {   media: null,
            mediaUrl: '',
            isUploading: false,
        }
    bgImageCropperFile: File
    uploadToFolderForBgImg = null;

    ngUnsubscribe: Subject<any> = new Subject();
    hasDBCAdvancedCustomization: boolean = false;
    isMobileDevice = isMobileDevice;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;
    environment: any = environment;

    protected readonly DBC_BACKGROUND_TYPE = DBC_BACKGROUND_TYPE;
    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;
    protected readonly ICON_POSITION = ICON_POSITION;

    @ViewChild('imageSelectModal', {static: false}) imageSelectModal: ModalDirective;
    @ViewChild('imageSelectGallery', {static: false}) imageSelectGallery: MediaGalleryComponent;
    @ViewChild('imageCropper', {static: false}) private imageCropper: ImageCropperComponent;
    @ViewChild('bgImgSelectGallery', {static: false}) bgImgSelectGallery: MediaGalleryComponent;
    @ViewChild('bgImgSelectorModal', {static: false}) bgImgSelectorModal: ModalDirective;

    constructor(private authService: AuthService, private mediaFolderService:  MediaFolderService,
        private mediaService: MediaService, private messageModalService: MessageModalService, private upsellService: UpsellService, ) {
    }

    ngOnInit(): void {
        this.hasDBCAdvancedCustomization = this.authService.getUser().hasDbcPermissionFor(FEATURES.advanced_customization_options)
        this.dbcBackgroundIsImg = (this.dbcBulk.customizations.background.type === DBC_BACKGROUND_TYPE.Image)
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    collapseSection(event) {
        switch (event) {
            case COLLAPSE_SECTION.LOGO:
                this.sections.logo = !this.sections.logo;
                break;
            case COLLAPSE_SECTION.COLOR:
                this.sections.color = !this.sections.color;
                break;
            case COLLAPSE_SECTION.FONT:
                this.sections.font = !this.sections.font;
                break;
            case COLLAPSE_SECTION.LAYOUT:
                this.sections.layout = !this.sections.layout;
                break;
        }
    }

    updateLogo(type: IMAGE_TYPE) {
        this.imageSelectModel.type = type;
        this.imageSelectModal.show();
    }

    toggleColorPicker(element) {
        document.getElementById(element).click();
    }

    selectLayout(event, conditional) {
        this.dbcBulk.layout = event.name;
    }
    setupImageSelectModal() {
        if (!this.imageCropperFile) {
            this.imageSelectModel = {
                media: null,
                link: '',
                alt: '',
                isUploading: false,
                type: this.imageSelectModel.type
            };
            this.updatedMedia = null;
            this.imageSelectGallery.fetchFolders();
        }
    }


    loadCropper(file) {
        this.imageCropperFile = file;
        if (this.imageCropperFile.type !== 'image/gif') {
            this.imageSelectModal.hide();
            this.showImageCropper();
        }
    }

    showImageCropper() {
        if (!this.imageCropper) {
            setTimeout(() => {
                this.showImageCropper();
            }, 100);
            return;
        }
        this.imageCropper.show();
    }

    closeImageCropperModal() {
        this.setUpImageCropperModal();
    }

    imageSelectedInGallery({media, clicked}: any) {
        if (media) {
            this.imageSelectModel.media = media;
            if (!clicked) {
                this.imageSelectModal.hide();
                this.selectImage();
            }
        } else {
            this.imageSelectModel.media = null;
        }
    }

    setUpImageCropperModal() {
        this.imageCropperFile = null;
    }


    selectImage() {
        this.imageSelectModal.hide();
        if (this.imageSelectModel.type === this.IMAGE_TYPE.Logo) {
            this.dbcBulk.logo_url = this.imageSelectModel.media.url;
        }
    }

    updateMedia(media) {
        if (this.imageCropper) {
            this.imageCropper.hide();
            this.setUpImageCropperModal()
        }
        this.updatedMedia = media;
    }


    setUploadToFolder(folder) {
        this.uploadToFolder = folder
    }

    onColorPickerChange(event, attribute) {
        switch (attribute) {
            case DBC_CUSTOMIZATION.PrimaryColor:
                this.dbcBulk.background_color = event;
                break;
            case DBC_CUSTOMIZATION.UserInfoColor:
                this.dbcBulk.user_info_color = event;
                break;
            case DBC_CUSTOMIZATION.SecondaryColor:
                this.dbcBulk.secondary_color = event;
                break;
            case DBC_CUSTOMIZATION.ButtonColor:
                this.dbcBulk.customizations.button_color = event;
                break;
            case DBC_CUSTOMIZATION.IconColor:
                this.dbcBulk.customizations.icon_color = event;
        }
    }

    setBackgroundType(type: DBC_BACKGROUND_TYPE): void {
        if (type === this.DBC_BACKGROUND_TYPE.Color) {
            this.dbcBackgroundIsImg = false;
            // this will change
            this.setupBgImgSelectModal();
            this.updateBackgroundImg('')
            this.updateBackgroundColor('#FFFFFF')
        } else {
            this.dbcBackgroundIsImg = true;
            this.updateBackgroundColor('#FFFFFF')
            this.updateBackgroundImg('')
        }
    }

    setUpBgImageCropperModal() {
        this.bgImageCropperFile = null;
    }

    updateBackgroundColor(color: string): void {
        this.dbcBulk.customizations.background.type = this.DBC_BACKGROUND_TYPE.Color;
        this.dbcBulk.customizations.background.value = color
    }

    onBackgroundColorChange($event): void {
        this.updateBackgroundColor($event)
    }

    onBgImgAdded(file: File): void {
        this.bgImgUpload.isUploading = true;
        this.mediaFolderService.getList(1, 1).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((folder) => {
            this.mediaService.uploadFile(file, 'image', null, null, folder.objects[0].id).pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.bgImgUpload.isUploading = false;
                })).subscribe(media => {
                Object.assign(this.bgImgUpload, { media, mediaUrl: media.url });
                this.updateBackgroundImg(this.bgImgUpload.mediaUrl);
            }, error => {
                console.error(error);
                this.messageModalService.show('Image upload failed', 'danger');
            });
        });
    }

    updateBackgroundImg(mediaUrl: string): void {
        this.dbcBulk.customizations.background.type = this.DBC_BACKGROUND_TYPE.Image;
        this.dbcBulk.customizations.background.value = mediaUrl;
    }

    onBgImgRemoved(): void {
        this.updateBackgroundImg('')
    }

    setupBgImgSelectModal(): void {

        if (!this.bgImageCropperFile) {
            this.bgImgUpload = {
                media: null,
                mediaUrl: '',
                isUploading: false,
            };
            this.bgImgSelectGallery.fetchFolders();
        }
    }

    loadBgImageCropper(file: File): void {
        this.bgImageCropperFile = file;
        if (this.bgImageCropperFile.type !== 'image/gif') {
            this.bgImgSelectorModal.hide();
            this.showBgImageCropper();
        }
    }

    showBgImageCropper(): void {
        if (!this.bgImageCropper) {
            setTimeout(() => {
                this.showBgImageCropper();
            }, 100);
            return;
        }
        this.bgImageCropper.show()
    }

    bgImageSelectedInGallery({media}: {media: Media}): void {
        Object.assign(this.bgImgUpload, { media, mediaUrl: media.url });
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl);
    }

    onSelectBgImg(): void {
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl)
        this.bgImgSelectorModal.hide()
    }

    onCloseBgImgSelectGallery(): void {
        this.bgImgUpload.media = null;
        this.bgImgUpload.mediaUrl = '';
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl)
        this.bgImgSelectorModal.hide()
    }

    closeBgImageCropperModal(): void {
        this.setUpBgImageCropperModal();
    }

    updateBgMedia(media: Media): void {
        if (this.bgImageCropper) {
            this.bgImageCropper.hide();
            this.setUpBgImageCropperModal()
        }

        Object.assign(this.bgImgUpload, { media, mediaUrl: media.url });
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl);
    }
    openUpgradePage(source: string): void {
        window.open(
            `/account/upgrade?source=${source}&orgId=${this.authService.getCurrentOrgId()}`,
            '_blank',
            'noopener noreferrer',
        );
    }
    showAdvancedCustomizationUpsell(): void {
        const user = this.authService.getUser();
        const isOnTrial = user.isOnSoloTrial() || user.isOnTeamTrial();
        const upsellFeature = { feature: 'advanced_customization', source: 'advanced_customization_upsell' };

        if ( isOnTrial ) {
            this.upsellService.isTrialSwitchModal.next(PLAN_TYPES.Business);
        } else {
            this.upsellService.eventSource.next('customisations-upgrade-cta')
        }

        if (!this.isMobileDevice) {
            this.upsellService.showV2(upsellFeature)
        } else {
            this.upsellService.showUpsellModalOnMobile(upsellFeature)
        }
    }

    setUploadToFolderForBgImg(folder) {
        this.uploadToFolderForBgImg = folder
    }

}
