import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/global-services/auth.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageModalService } from 'app/shared/message-modal/message-modal.service';
import { environment } from 'environments/environment';


@Injectable()
export class SalesforceService {

    protected apiURL: string;

    constructor(private http: HttpClient, private authService: AuthService, private messageModalService: MessageModalService) {
        this.apiURL = environment.baseURL + environment.apiEndpoint + environment.apiVersion + '/lead/salesforce/';
    }

    protected buildHeaders(argsObject: object): object {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });

        argsObject['headers'] = headers;
        return argsObject;
    }

    protected handleError(error: any): Observable<any> {
        switch (error.status) {
            case 401:
                this.authService.logout();
                break;
            case 403:
                this.messageModalService.show('You are not allowed to access this resource', 'danger');
                break;
        }

        return observableThrowError(error);
    }

    postSalesforceCredentials(body: object): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }

        const url = `${this.apiURL}credentials/?organization=${this.authService.getCurrentOrgId()}`

        return this.http.post(url, JSON.stringify(body), this.buildHeaders({})).pipe(
            map(response => response),
            catchError(error => this.handleError(error))
        );
    }

    postAuthorizationCode(body: object): Observable<any> {
        const url = `${this.apiURL}token/?organization=${this.authService.getCurrentOrgId()}`

        return this.http.post(url, JSON.stringify(body), this.buildHeaders({})).pipe(
            map(response => response),
            catchError(error => this.handleError(error))
        );

    }

    getAuthenticatedStatus(): Observable<any> {
        const url = `${this.apiURL}salesforce-attribute/?organization=${this.authService.getCurrentOrgId()}`

        return this.http.get(url, this.buildHeaders({})).pipe(
            map(response => response),
            catchError(error => this.handleError(error))
        )
    }

    disconnectSalesforce(integrationID: number): Observable<any> {
        const url = `${this.apiURL}salesforce-attribute/${integrationID}/?organization=${this.authService.getCurrentOrgId()}`
        const body = {
            organization: this.authService.getCurrentOrgId(),
            connected: 'false'
        }
        return this.http.put(url, JSON.stringify(body), this.buildHeaders({})).pipe(
            map(response => response),
            catchError(error => this.handleError(error))
        )
    }
}
