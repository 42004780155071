<div class="desktop-container"
     [ngStyle]="{'margin-top': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED) || showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED)? '7rem' : '5rem'}">

    <div class="bx--row m-0 justify-content-center mobile-qr-pricing">
        <!--Starter-Individual-->
        <div class="width-25" [ngClass]="{'dropdown-on-top': showDropdownOnTop(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)}" *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div class="qr-plan-name" style="background: linear-gradient(90deg, #2595FF 0%, #25CBFF 100%);">
                        {{qrPlansData[0].name}}
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate1>
                            <div class="tooltip-content d-flex flex-column justify-content-center align-items-start tooltip-content-click0"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{qrPlansData[0].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{qrPlansData[0].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-click0"
                                [tooltip]="popTemplate1"
                                placement="auto"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-click0" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" style="margin-bottom: 32px">
                    {{qrPlansData[0].description}}
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{qrPlansData[0].price[selectedCurrency].symbol}}{{qrPlansData[0].price[selectedCurrency].value}}
                    </div>
                    <div class="qr-plan-price-description">
                        {{qrPlansData[0].price[selectedCurrency].message}}
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Starter)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Starter)"
                                       [type]="
                                                  user.customer_plan == PLAN_TYPES.Starter
                                                  ? BUTTON_TYPES.SECONDARY
                                                  : BUTTON_TYPES.PRIMARY
                                              "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Starter
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE"
                                       [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Starter
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                       [disabled]="user.customer_plan == PLAN_TYPES.Starter ? true : false"
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.userSeats.value}}</b> {{qrPlansData[0].details.userSeats.suffix}}
                    </div>
                </div>

                <!--section division line-->
                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">

                <div class="d-flex flex-column qr-include-exclude-section">
                    <div>
                        <div class="qr-includes-heading mb-2">{{qrPlansData[0].details.includes.message}}</div>
                        <ng-container *ngFor="let data of qrPlansData[0].details.includes.items;">
                            <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                <div class="qr-includes-features">{{data.name}}</div>
                                <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                    <ng-template #popTemplateInclude>
                                        <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{data.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{data.tooltip.media.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplateInclude"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="!isObjectEmpty(qrPlansData[0].details?.excludes)">
                        <div class="qr-includes-heading mb-2">Does not include</div>
                        <ng-container *ngFor="let data of qrPlansData[0].details.excludes.items;">
                            <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                <i class="fas fa-times fa-xs" style="color: #16212f;"></i>
                                <div class="qr-includes-features">{{data.name}}</div>
                                <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                    <ng-template #popTemplateExclude>
                                        <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{data.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplateExclude"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
        <!--Lite-Individual-Recommended-->
        <div class="width-25 recommended-banner-extended"
             style="background: #f4f5f7"
             [ngStyle]="{'transform': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED) ? 'translateY(-96px)' : 'translateY(-48px)'}"
             *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div style="background: #182D44; border-radius: 16px 16px 0px 0px">
                <div class="d-flex flex-row flex-start recommended-plan">
                    <i class="far fa-bolt" style="color: #ffffff;"></i>
                    <span class="recommended-plan-heading">Best Value</span>
                </div>
                <div class="" style="padding: 24px; max-height: 825px">
                    <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                        <div class="qr-plan-name recommend-plan-color" style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                            {{qrPlansData[1].name}}
                        </div>
                        <div class="tooltip-parent">
                            <ng-template #popTemplate2>
                                <div class="tooltip-content d-flex flex-column justify-content-center align-items-start tooltip-content-click1"
                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                    <video src="{{qrPlansData[1].tooltip.media.src}}"
                                           width="100%"
                                           height="100%"
                                           controls
                                           autoplay
                                           style="border-radius: 16px"
                                    >
                                    </video>
                                    <div class="tooltip-content-text">{{qrPlansData[1].tooltip.description}}</div>
                                </div>
                            </ng-template>
                            <button type="button"
                                    class="btn tooltip-button tooltip-button-click1"
                                    [tooltip]="popTemplate2"
                                    placement="auto"
                                    triggers="click"
                                    container="body"
                                    #pop="bs-tooltip"
                            >
                                <img class="tooltip-icon-click1" src="../../../assets/img/user-upgrade/info-icon.svg">
                            </button>
                        </div>
                    </div>
                    <div class="qr-plan-description recommend-plan-color" style="margin-bottom: 32px">
                        {{qrPlansData[1].description}}
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                        <div class="qr-plan-price recommend-plan-color mr-1">
                            {{qrPlansData[1].price[selectedCurrency].symbol}}{{qrPlansData[1].price[selectedCurrency].value}}
                        </div>
                        <div class="qr-plan-price-description recommend-plan-color">
                            {{qrPlansData[1].price[selectedCurrency].message}}
                        </div>
                    </div>

                    <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                        <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Lite)" [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'" [style]="BUTTON_STYLES.FILLED" [label]="'BUY NOW'"></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                        <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Lite)"
                                           [type]="
                              user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                           [width]="'100%'"
                                           [style]="user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.FILLED"
                                           [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Lite
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                           [disabled]="
                              user.customer_plan == PLAN_TYPES.Lite ? true : false
                            "
                        ></beaconstac-button>
                    </div>

                    <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px; background:#16212F">
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                        </div>
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.userSeats.value}}</b> {{qrPlansData[1].details.userSeats.suffix}}
                        </div>
                    </div>

                    <!--section division line-->
                    <hr style="border-top: 1px solid #52565B; margin-bottom: 32px">

                    <div class="d-flex flex-column qr-include-exclude-section">
                        <div>
                            <div class="qr-includes-heading recommend-plan-color mb-2">{{qrPlansData[1].details.includes.message}}</div>
                            <ng-container *ngFor="let data of qrPlansData[1].details.includes.items;">
                                <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                    <i class="fas fa-check fa-xs recommend-plan-color"></i>
                                    <div class="qr-includes-features recommend-plan-color">{{data.name}}</div>
                                    <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                        <ng-template #popTemplateInclude>
                                            <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <video src="{{data.tooltip.media?.src}}"
                                                       width="100%"
                                                       height="100%"
                                                       autoplay
                                                       style="border-radius: 16px"
                                                >
                                                </video>
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <img src="{{data.tooltip.media.src}}"
                                                     width="100%"
                                                     height="100%"
                                                     style="border-radius: 16px"
                                                >
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                        </ng-template>
                                        <button type="button"
                                                class="btn tooltip-button"
                                                [tooltip]="popTemplateInclude"
                                                placement="auto"
                                                triggers="hover"
                                                container="body"
                                        >
                                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--Lite-Business-->
        <div class="width-25" [ngClass]="{'dropdown-on-top': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_BUSINESS)}" *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.LITE_BUSINESS)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_BUSINESS)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_BUSINESS)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div class="qr-plan-name" style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                        {{qrPlansData[0].name}}
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate3>
                            <div class="tooltip-content d-flex flex-column justify-content-center align-items-start tooltip-content-click2"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{qrPlansData[0].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{qrPlansData[0].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-click2"
                                [tooltip]="popTemplate3"
                                placement="auto"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-click2" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" style="margin-bottom: 32px">
                    {{qrPlansData[0].description}}
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{qrPlansData[0].price[selectedCurrency].symbol}}{{qrPlansData[0].price[selectedCurrency].value}}
                    </div>
                    <div class="qr-plan-price-description">
                        {{qrPlansData[0].price[selectedCurrency].message}}
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Lite)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Lite)"
                                       [type]="
                              user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE
"
                                       [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Lite
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                       [disabled]="
                              user.customer_plan == PLAN_TYPES.Lite ? true : false
                            "
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.userSeats.value}}</b> {{qrPlansData[0].details.userSeats.suffix}}
                    </div>
                </div>

                <!--section division line-->
                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">

                <div class="d-flex flex-column qr-include-exclude-section">
                    <div>
                        <div class="qr-includes-heading mb-2">{{qrPlansData[0].details.includes.message}}</div>
                        <ng-container *ngFor="let data of qrPlansData[0].details.includes.items;">
                            <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                <div class="qr-includes-features">{{data.name}}</div>
                                <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                    <ng-template #popTemplateInclude>
                                        <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{data.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{data.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplateInclude"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>

        </div>
        <!--Pro-Individual-->
        <div class="width-25"
             *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)"
             [ngClass]="{'dropdown-on-top': showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)}"
             [ngStyle]="{'border-left': showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL) ? 'none' : '1px solid #E4E5E7'}"
        >
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div>
                        <span class="qr-plan-name" style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%); color:#16212f">{{qrPlansData[2].name}}</span>
                        <span *ngIf="isEligibleForDiscount && pageType === PRICING_PAGE_TYPE.UPGRADE_NOW" class="discount-percent">
                            at <span style="">50% OFF</span>
                        </span>
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate4>
                            <div class="tooltip-content d-flex flex-column justify-content-center align-items-start tooltip-content-click3"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{qrPlansData[2].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{qrPlansData[2].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-click3"
                                [tooltip]="popTemplate4"
                                placement="top"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-click3" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" [style.margin-bottom.px]="qrPlansData[2].price[selectedCurrency].discountedValue && isEligibleForDiscount? 8 : 32">
                    {{qrPlansData[2].description}}
                </div>
                <div class="qr-plan-price-discount" style="color: #16212f" *ngIf="qrPlansData[2].price[selectedCurrency].discountedValue && isEligibleForDiscount">
                    <s>{{ qrPlansData[2].price[selectedCurrency].symbol }}{{qrPlansData[2].price[selectedCurrency].value}}</s>
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{qrPlansData[2].price[selectedCurrency].symbol}}{{ qrPlansData[2].price[selectedCurrency].discountedValue && isEligibleForDiscount? qrPlansData[2].price[selectedCurrency].discountedValue : qrPlansData[2].price[selectedCurrency].value }}
                    </div>
                    <div class="qr-plan-price-description">
                        {{ qrPlansData[2].price[selectedCurrency].discountedMessage  && isEligibleForDiscount? qrPlansData[2].price[selectedCurrency].discountedMessage : qrPlansData[2].price[selectedCurrency].message}}
                        <span *ngIf="qrPlansData[2].price[selectedCurrency]?.tooltip  && isEligibleForDiscount">
                                <ng-template #discountTooltip>
                                    <div class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <div class="tooltip-content-text">{{qrPlansData[2].price[selectedCurrency]?.tooltip?.description}}</div>
                                    </div>
                                </ng-template>
                                <button type="button"
                                        class="btn tooltip-button"
                                        [tooltip]="discountTooltip"
                                        placement="auto"
                                        triggers="hover"
                                        container="body"
                                >
                                    <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                </button>
                            </span>
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Pro)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Pro)"
                                       [type]="
                              user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE
"
                                       [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Pro
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                       [disabled]="
                              user.customer_plan == PLAN_TYPES.Pro ? true : false
                            "
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail">
                        <b>{{qrPlansData[2].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="qr-detail">
                        <b>{{qrPlansData[2].details.userSeats.value}}</b> {{qrPlansData[2].details.userSeats.suffix}}
                    </div>
                </div>

                <!--section division line-->
                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">

                <div class="d-flex flex-column qr-include-exclude-section">
                    <div>
                        <div class="qr-includes-heading mb-2">{{qrPlansData[2].details.includes.message}}</div>
                        <ng-container *ngFor="let data of qrPlansData[2].details.includes.items;">
                            <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                <div class="qr-includes-features">{{data.name}}</div>
                                <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                    <ng-template #popTemplateInclude>
                                        <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{data.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{data.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplateInclude"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!--Pro-Business-Recommended-->
        <div class="width-25 recommended-banner-extended"
            *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED)"
            style="background: #f4f5f7"
             [ngStyle]="{'transform': showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED) ? 'translateY(-96px)' : 'translateY(-48px)'}">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div style="background: #182D44; border-radius: 16px 16px 0px 0px">
                <div class="d-flex flex-row flex-start recommended-plan">
                    <i class="far fa-bolt" style="color: #ffffff;"></i>
                    <span class="recommended-plan-heading">Best Value
                        <ng-container *ngIf="isEligibleForDiscount && pageType === PRICING_PAGE_TYPE.UPGRADE_NOW">
                            at <span style="color: #FEDD55;">50% OFF</span>
                        </ng-container>
                    </span>
                </div>
                <div class="" style="padding: 24px; max-height: 825px">
                    <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                        <div class="qr-plan-name recommend-plan-color" style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%);  color:#16212f">
                            {{qrPlansData[1].name}}
                        </div>
                        <div class="tooltip-parent">
                            <ng-template #popTemplate5>
                                <div class="tooltip-content d-flex flex-column justify-content-center align-items-start tooltip-content-click4"
                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                    <video src="{{qrPlansData[1].tooltip.media.src}}"
                                           width="100%"
                                           height="100%"
                                           controls
                                           autoplay
                                           style="border-radius: 16px"
                                    >
                                    </video>
                                    <div class="tooltip-content-text">{{qrPlansData[1].tooltip.description}}</div>
                                </div>
                            </ng-template>
                            <button type="button"
                                    class="btn tooltip-button tooltip-button-click4"
                                    [tooltip]="popTemplate5"
                                    placement="auto"
                                    triggers="click"
                                    container="body"
                                    #pop="bs-tooltip"
                            >
                                <img class="tooltip-icon-click4" src="../../../assets/img/user-upgrade/info-icon.svg">
                            </button>
                        </div>
                    </div>
                    <div class="qr-plan-description recommend-plan-color" [style.margin-bottom.px]="qrPlansData[1].price[selectedCurrency].discountedValue && isEligibleForDiscount? 8 : 32">
                        {{qrPlansData[1].description}}
                    </div>
                    <div class="qr-plan-price-discount" *ngIf="qrPlansData[1].price[selectedCurrency].discountedValue && isEligibleForDiscount">
                        <s>{{ qrPlansData[1].price[selectedCurrency].symbol }}{{qrPlansData[1].price[selectedCurrency].value}}</s>
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                        <div class="qr-plan-price recommend-plan-color mr-1">
                            {{qrPlansData[1].price[selectedCurrency].symbol}}{{ qrPlansData[1].price[selectedCurrency].discountedValue && isEligibleForDiscount? qrPlansData[1].price[selectedCurrency].discountedValue : qrPlansData[1].price[selectedCurrency].value }}
                        </div>
                        <div class="qr-plan-price-description recommend-plan-color">
                            {{ qrPlansData[1].price[selectedCurrency].discountedMessage && isEligibleForDiscount? qrPlansData[1].price[selectedCurrency].discountedMessage : qrPlansData[1].price[selectedCurrency].message}}
                            <span *ngIf="qrPlansData[1].price[selectedCurrency]?.tooltip && isEligibleForDiscount">
                                <ng-template #discountTooltip>
                                    <div class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <div class="tooltip-content-text">{{qrPlansData[1].price[selectedCurrency]?.tooltip?.description}}</div>
                                    </div>
                                </ng-template>
                                <button type="button"
                                        class="btn tooltip-button"
                                        [tooltip]="discountTooltip"
                                        placement="auto"
                                        triggers="hover"
                                        container="body"
                                >
                                    <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                </button>
                            </span>
                        </div>
                    </div>

                    <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                        <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Pro)" [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'" [style]="BUTTON_STYLES.FILLED" [label]="'BUY NOW'"></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                        <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Pro)"
                                           [type]="
                              user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                           [width]="'100%'"
                                           [style]="user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.FILLED
"
                                           [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Pro
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                           [disabled]="
                              user.customer_plan == PLAN_TYPES.Pro ? true : false
                            "
                        ></beaconstac-button>
                    </div>

                    <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px; background:#16212F">
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                        </div>
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.userSeats.value}}</b> {{qrPlansData[1].details.userSeats.suffix}}
                        </div>
                    </div>

                    <!--section division line-->
                    <hr style="border-top: 1px solid #52565B; margin-bottom: 32px">

                    <div class="d-flex flex-column qr-include-exclude-section">
                        <div>
                            <div class="qr-includes-heading recommend-plan-color mb-2">{{qrPlansData[1].details.includes.message}}</div>
                            <ng-container *ngFor="let data of qrPlansData[1].details.includes.items;">
                                <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                    <i class="fas fa-check fa-xs recommend-plan-color"></i>
                                    <div class="qr-includes-features recommend-plan-color">{{data.name}}</div>
                                    <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                        <ng-template #popTemplateInclude>
                                            <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <video src="{{data.tooltip.media?.src}}"
                                                       width="100%"
                                                       height="100%"
                                                       autoplay
                                                       style="border-radius: 16px"
                                                >
                                                </video>
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <img src="{{data.tooltip.media.src}}"
                                                     width="100%"
                                                     height="100%"
                                                     style="border-radius: 16px"
                                                >
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                        </ng-template>
                                        <button type="button"
                                                class="btn tooltip-button"
                                                [tooltip]="popTemplateInclude"
                                                placement="auto"
                                                triggers="hover"
                                                container="body"
                                        >
                                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--Plus-->
        <div class="width-25"
             [ngStyle]="{'border-left': showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH) ? 'none' : '1px solid #E4E5E7'}"
             [ngClass]="{'dropdown-on-top': showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)}"
             *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.PLUS_BOTH)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>

            <div class="" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div class="qr-plan-name" style="background: linear-gradient(90deg, #2EE3EA 0%, #8AE962 100%);  color:#16212f">
                        {{showBusinessPlans? qrPlansData[2].name: qrPlansData[3].name}}
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate6>
                            <div class="tooltip-content d-flex flex-column justify-content-center align-items-start tooltip-content-click5"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{showBusinessPlans ? qrPlansData[2].tooltip.media.src : qrPlansData[3].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{showBusinessPlans ? qrPlansData[2].tooltip.description : qrPlansData[3].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-click5"
                                [tooltip]="popTemplate6"
                                placement="auto"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-click5" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" style="margin-bottom: 32px">
                    {{showBusinessPlans? qrPlansData[2].description: qrPlansData[3].description}}
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{showBusinessPlans ?
                        qrPlansData[2].price[selectedCurrency].symbol + plusPlanPrice :
                        qrPlansData[3].price[selectedCurrency].symbol + plusPlanPrice
                        }}
                    </div>
                    <div class="qr-plan-price-description">
                        {{showBusinessPlans? qrPlansData[2].price[selectedCurrency].message: qrPlansData[3].price[selectedCurrency].message}}
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Plus)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Plus)"
                                       [type]="
                              user.customer_plan == PLAN_TYPES.Plus
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Plus
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE
"
                                       [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Plus
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                       [disabled]="
                              user.customer_plan == PLAN_TYPES.Plus ? true : false
                            "
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail pt-3">
                        <b>{{showBusinessPlans? qrPlansData[2].details.dynamicQRCodes.value: qrPlansData[3].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-center" style="column-gap: 8px;">
                        <beaconstac-textfield
                            [(text)]="plusPlanUserAddOn"
                            [maxWidth]="'60px'"
                            [type]="TEXT_FIELD_TYPES.NUMBER"
                            class="plus-plan-user-add"
                            (textChange)="handlePlusPlanUserAddOn($event)"
                            [placeholder]="'Users'"
                        > </beaconstac-textfield>
                        <span class="qr-detail" style="padding-bottom: 7px"> User seats</span>
                    </div>
                    <div class="mt-1">
                        <beaconstac-checkbox
                            [label]="'Add Custom Domain'"
                            [disabled]="user.customer_plan === PLAN_TYPES.Plus"
                            [checked]="additionalCustomDomains"
                            (checkedChange)="handlePlusPlanCustomDomainAddOn($event)">
                        </beaconstac-checkbox>
                    </div>
                </div>

                <!--section division line-->
                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">

                <div class="d-flex flex-column qr-include-exclude-section">
                    <div>
                        <div class="qr-includes-heading mb-2">{{showBusinessPlans ? qrPlansData[2].details.includes.message : qrPlansData[3].details.includes.message}}</div>
                        <ng-container *ngFor="let data of (showBusinessPlans ? qrPlansData[2].details.includes.items : qrPlansData[3].details.includes.items) let i = index ">
                            <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                <div class="qr-includes-features" [style.min-width.px]="i === 0 ? 180 : null">{{data.name}}</div>
                                <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                    <ng-template #popTemplateInclude>
                                        <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{data.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{data.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplateInclude"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!--Business+-->
        <div class="width-25" style="padding: 24px; border-left: 1px solid #E4E5E7" *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.ENTERPRISE_BUSINESS)">
            <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                <div class="qr-plan-name" style="background: linear-gradient(90deg, #6A52FF 0%, #A96CE6 100%);">
                    {{qrPlansData[3].name}}
                </div>
                <div class="tooltip-parent">
                    <ng-template #popTemplate7>
                        <div class="tooltip-content d-flex flex-column justify-content-center align-items-start tooltip-content-click6"
                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                            <video src="{{qrPlansData[3].tooltip.media.src}}"
                                   width="100%"
                                   height="100%"
                                   controls
                                   autoplay
                                   style="border-radius: 16px"
                            >
                            </video>
                            <div class="tooltip-content-text">{{qrPlansData[3].tooltip.description}}</div>
                        </div>
                    </ng-template>
                    <button type="button"
                            class="btn tooltip-button tooltip-button-click6"
                            [tooltip]="popTemplate7"
                            placement="auto"
                            triggers="click"
                            container="body"
                            #pop="bs-tooltip"
                    >
                        <img class="tooltip-icon-click6" src="../../../assets/img/user-upgrade/info-icon.svg">
                    </button>
                </div>
            </div>
            <div class="qr-plan-description" style="margin-bottom: 32px">
                {{qrPlansData[3].description}}
            </div>
            <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                <div class="qr-plan-price mr-2">
                    <i class="far fa-comments" style="color: #16212f;"></i>
                </div>
                <div class="qr-plan-price-description">
                    {{qrPlansData[3].price.message}}
                </div>
            </div>

            <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                <beaconstac-button (onClick)="onEnterpriseSelected.emit()" [type]="BUTTON_TYPES.PRIMARY"
                                   [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'SCHEDULE DEMO'"></beaconstac-button>
            </div>
            <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                <beaconstac-button (onClick)="onEnterpriseSelected.emit()" [type]="BUTTON_TYPES.PRIMARY"
                                   [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'SCHEDULE DEMO'"></beaconstac-button>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center qr-details-container" style="margin-bottom: 32px">
                <div class="qr-detail">
                    <b>{{qrPlansData[3].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                </div>
                <div class="qr-detail">
                    <b>{{qrPlansData[3].details.userSeats.value}}</b> {{qrPlansData[3].details.userSeats.suffix}}
                </div>
                <div class="d-flex flex-row align-items-center justify-content-center qr-detail" style="column-gap: 4px">
                    <b>{{qrPlansData[3].details.organizations.value}}</b> Organizations
                    <div class="tooltip-parent">
                        <ng-template #popTemplateOrganization>
                            <div class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <div class="tooltip-content-text">{{qrPlansData[3].details.organizations.tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button"
                                [tooltip]="popTemplateOrganization"
                                placement="auto"
                                triggers="hover"
                                container="body"
                        >
                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!--section division line-->
            <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">

            <div class="d-flex flex-column qr-include-exclude-section">
                <div>
                    <div class="qr-includes-heading mb-2">{{qrPlansData[3].details.includes.message}}</div>
                    <ng-container *ngFor="let data of qrPlansData[3].details.includes.items; let last = last;">
                        <div class="d-flex flex-row align-items-center" [style.justify-content]="last ? 'start' : 'initial'" style="column-gap:8px">
                            <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                            <div class="qr-includes-features" [style.width.px]="last ? 120 : 'auto'">{{data.name}}</div>
                            <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                <ng-template #popTemplateInclude>
                                    <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <video src="{{data.tooltip.media?.src}}"
                                               width="100%"
                                               height="100%"
                                               autoplay
                                               style="border-radius: 16px"
                                        >
                                        </video>
                                        <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                    </div>
                                    <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <img src="{{data.tooltip.media.src}}"
                                             width="100%"
                                             height="100%"
                                             style="border-radius: 16px"
                                        >
                                        <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                    </div>
                                    <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                    </div>
                                </ng-template>
                                <button type="button"
                                        class="btn tooltip-button"
                                        [tooltip]="popTemplateInclude"
                                        placement="auto"
                                        triggers="hover"
                                        container="body"
                                >
                                    <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <ng-template #currency_converter_dropdown>
        <div class="d-flex flex-start dropdown-parent">
        <span dropdown (isOpenChange)="isOpenChange()" class="currency-converter">
            <a href id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown" class="currency-select">
                {{getSelectedCurrencySymbol()}}{{selectedCurrency}}
                <i class="fas fa-chevron-down" [@rotate]="isDropdownOpen ? 'open' : 'closed'" style="color: #16212f; font-size: 12px"></i>
            </a>
            <ul
                id="basic-link-dropdown"
                *dropdownMenu
                class="dropdown-menu currency-options-container"
                role="menu"
                aria-labelledby="basic-link"
            >
                <li *ngFor="let currency of currencies; let i = index">
                    <a class="dropdown-item currency-options" (click)="updatePrice(currency.code)">{{currency.symbol}}{{currency.code}}</a>
                </li>
            </ul>
        </span>
        </div>
    </ng-template>
</div>

<div class="mobile-container mb-4"
     [ngStyle]="{'margin-top': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED) || showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED)? '8rem' : '5rem'}">

    <div class="m-0 d-flex flex-column justify-content-center align-items-center">
        <!--Starter-Individual-->
        <div class="width-25-mobile mb-3" [ngClass]="{'dropdown-on-top-mobile': showDropdownOnTop(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)}" *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="plan-details-container" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.STARTER_INDIVIDUAL)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div class="qr-plan-name" style="background: linear-gradient(90deg, #2595FF 0%, #25CBFF 100%);">
                        {{qrPlansData[0].name}}
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate1>
                            <div class="tooltip-content-mobile d-flex flex-column justify-content-center align-items-start tooltip-content-mobile-click0"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{qrPlansData[0].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{qrPlansData[0].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-mobile-click0"
                                [tooltip]="popTemplate1"
                                placement="auto"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-mobile-click0" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" style="margin-bottom: 32px">
                    {{qrPlansData[0].description}}
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{qrPlansData[0].price[selectedCurrency].symbol}}{{qrPlansData[0].price[selectedCurrency].value}}
                    </div>
                    <div class="qr-plan-price-description">
                        {{qrPlansData[0].price[selectedCurrency].message}}
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Starter)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Starter)"
                                       [type]="
                                                  user.customer_plan == PLAN_TYPES.Starter
                                                  ? BUTTON_TYPES.SECONDARY
                                                  : BUTTON_TYPES.PRIMARY
                                              "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Starter
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE"
                                       [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Starter
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                       [disabled]="user.customer_plan == PLAN_TYPES.Starter ? true : false"
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.userSeats.value}}</b> {{qrPlansData[0].details.userSeats.suffix}}
                    </div>
                </div>

                <!--section division line-->
<!--                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">-->

                <div class="d-flex flex-column qr-include-exclude-section-mobile">
                    <div>
                        <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile mb-2" (click)="toggleIncludes(0)">
                            {{qrPlansData[0].details.includes.message}}
                            <i class="far" [ngClass]="{'fa-chevron-up': showIncludes[0], 'fa-chevron-down': !showIncludes[0]}" style="color: #16212f;"></i>
                        </div>
                        <ng-container *ngIf="showIncludes[0]">
                            <ng-container *ngFor="let data of qrPlansData[0].details.includes.items;">
                                <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                    <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                    <div class="qr-includes-features">{{data.name}}</div>
                                    <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                        <ng-template #popTemplateInclude>
                                            <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <video src="{{data.tooltip.media?.src}}"
                                                       width="100%"
                                                       height="100%"
                                                       autoplay
                                                       style="border-radius: 16px"
                                                >
                                                </video>
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <img src="{{data.tooltip.media.src}}"
                                                     width="100%"
                                                     height="100%"
                                                     style="border-radius: 16px"
                                                >
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                        </ng-template>
                                        <button type="button"
                                                class="btn tooltip-button"
                                                [tooltip]="popTemplateInclude"
                                                placement="auto"
                                                triggers="hover"
                                                container="body"
                                        >
                                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div *ngIf="!isObjectEmpty(qrPlansData[0].details?.excludes)">
                        <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile mb-2" (click)="toggleExcludes(0)">
                            Does not include
                            <i class="far" [ngClass]="{'fa-chevron-up': showExcludes[0], 'fa-chevron-down': !showExcludes[0]}" style="color: #16212f;"></i>
                        </div>
                        <ng-container *ngIf="showExcludes[0]">
                            <ng-container *ngFor="let data of qrPlansData[0].details.excludes.items;">
                                <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                    <i class="fas fa-times fa-xs" style="color: #16212f;"></i>
                                    <div class="qr-includes-features">{{data.name}}</div>
                                    <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                        <ng-template #popTemplateInclude>
                                            <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <video src="{{data.tooltip.media?.src}}"
                                                       width="100%"
                                                       height="100%"
                                                       autoplay
                                                       style="border-radius: 16px"
                                                >
                                                </video>
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <img src="{{data.tooltip.media.src}}"
                                                     width="100%"
                                                     height="100%"
                                                     style="border-radius: 16px"
                                                >
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                        </ng-template>
                                        <button type="button"
                                                class="btn tooltip-button"
                                                [tooltip]="popTemplateInclude"
                                                placement="auto"
                                                triggers="hover"
                                                container="body"
                                        >
                                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
        <!--Lite-Individual-Recommended-->
        <div class="width-25-mobile recommended-banner-extended-mobile mb-3"
             style="background: #f4f5f7"
             [ngStyle]="{'transform': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED) ? 'translateY(-96px)' : 'translateY(-48px)'}"
             *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="plan-details-container" style="background: #182D44; border-radius: 16px">
                <div class="d-flex flex-row flex-start recommended-plan">
                    <i class="far fa-bolt" style="color: #ffffff;"></i>
                    <span class="recommended-plan-heading">Best Value</span>
                </div>
                <div class="" style="padding: 24px">
                    <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                        <div class="qr-plan-name recommend-plan-color" style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                            {{qrPlansData[1].name}}
                        </div>
                        <div class="tooltip-parent">
                            <ng-template #popTemplate2>
                                <div class="tooltip-content-mobile d-flex flex-column justify-content-center align-items-start tooltip-content-mobile-click1"
                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                    <video src="{{qrPlansData[1].tooltip.media.src}}"
                                           width="100%"
                                           height="100%"
                                           controls
                                           autoplay
                                           style="border-radius: 16px"
                                    >
                                    </video>
                                    <div class="tooltip-content-text">{{qrPlansData[1].tooltip.description}}</div>
                                </div>
                            </ng-template>
                            <button type="button"
                                    class="btn tooltip-button tooltip-button-mobile-click1"
                                    [tooltip]="popTemplate2"
                                    placement="auto"
                                    triggers="click"
                                    container="body"
                                    #pop="bs-tooltip"
                            >
                                <img class="tooltip-icon-mobile-click1" src="../../../assets/img/user-upgrade/info-icon.svg">
                            </button>
                        </div>
                    </div>
                    <div class="qr-plan-description recommend-plan-color" style="margin-bottom: 32px">
                        {{qrPlansData[1].description}}
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                        <div class="qr-plan-price recommend-plan-color mr-1">
                            {{qrPlansData[1].price[selectedCurrency].symbol}}{{qrPlansData[1].price[selectedCurrency].value}}
                        </div>
                        <div class="qr-plan-price-description recommend-plan-color">
                            {{qrPlansData[1].price[selectedCurrency].message}}
                        </div>
                    </div>

                    <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                        <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Lite)" [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'" [style]="BUTTON_STYLES.FILLED" [label]="'BUY NOW'"></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                        <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Lite)"
                                           [type]="
                              user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                           [width]="'100%'"
                                           [style]="user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.FILLED"
                                           [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Lite
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                           [disabled]="
                              user.customer_plan == PLAN_TYPES.Lite ? true : false
                            "
                        ></beaconstac-button>
                    </div>

                    <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px; background:#16212F">
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                        </div>
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.userSeats.value}}</b> {{qrPlansData[1].details.userSeats.suffix}}
                        </div>
                    </div>

                    <!--section division line-->
<!--                    <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">-->

                    <div class="d-flex flex-column qr-include-exclude-section-mobile">
                        <div>
<!--                            <div class="qr-includes-heading recommend-plan-color mb-2">Includes</div>-->
                            <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile-recommended recommend-plan-color-mobile mb-2" (click)="toggleIncludes(1)">
                                {{qrPlansData[1].details.includes.message}}
                                <i class="far" [ngClass]="{'fa-chevron-up': showIncludes[1], 'fa-chevron-down': !showIncludes[1]}"></i>
                            </div>
                            <ng-container *ngIf="showIncludes[1]">
                                <ng-container *ngFor="let data of qrPlansData[1].details.includes.items;">
                                    <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                        <i class="fas fa-check fa-xs recommend-plan-color"></i>
                                        <div class="qr-includes-features recommend-plan-color">{{data.name}}</div>
                                        <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                            <ng-template #popTemplateInclude>
                                                <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                    <video src="{{data.tooltip.media?.src}}"
                                                           width="100%"
                                                           height="100%"
                                                           autoplay
                                                           style="border-radius: 16px"
                                                    >
                                                    </video>
                                                    <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                                </div>
                                                <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                    <img src="{{data.tooltip.media.src}}"
                                                         width="100%"
                                                         height="100%"
                                                         style="border-radius: 16px"
                                                    >
                                                    <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                                </div>
                                                <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                    <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                                </div>
                                            </ng-template>
                                            <button type="button"
                                                    class="btn tooltip-button"
                                                    [tooltip]="popTemplateInclude"
                                                    placement="auto"
                                                    triggers="hover"
                                                    container="body"
                                            >
                                                <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--Lite-Business-->
        <div class="width-25-mobile mb-3" [ngClass]="{'dropdown-on-top-mobile': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_BUSINESS)}" *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.LITE_BUSINESS)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_BUSINESS)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="plan-details-container" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_BUSINESS)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div class="qr-plan-name" style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                        {{qrPlansData[0].name}}
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate3>
                            <div class="tooltip-content-mobile d-flex flex-column justify-content-center align-items-start tooltip-content-mobile-click2"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{qrPlansData[0].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{qrPlansData[0].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-mobile-click2"
                                [tooltip]="popTemplate3"
                                placement="auto"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-mobile-click2mob" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" style="margin-bottom: 32px">
                    {{qrPlansData[0].description}}
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{qrPlansData[0].price[selectedCurrency].symbol}}{{qrPlansData[0].price[selectedCurrency].value}}
                    </div>
                    <div class="qr-plan-price-description">
                        {{qrPlansData[0].price[selectedCurrency].message}}
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Lite)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Lite)"
                                       [type]="
                              user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Lite
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE
"
                                       [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Lite
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                       [disabled]="
                              user.customer_plan == PLAN_TYPES.Lite ? true : false
                            "
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="qr-detail">
                        <b>{{qrPlansData[0].details.userSeats.value}}</b> {{qrPlansData[0].details.userSeats.suffix}}
                    </div>
                </div>

                <!--section division line-->
<!--                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">-->

                <div class="d-flex flex-column qr-include-exclude-section-mobile">
                    <div>
                        <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile mb-2" (click)="toggleIncludes(2)">
                            {{qrPlansData[0].details.includes.message}}
                            <i class="far" [ngClass]="{'fa-chevron-up': showIncludes[2], 'fa-chevron-down': !showIncludes[2]}" style="color: #16212f;"></i>
                        </div>
                        <ng-container *ngIf="showIncludes[2]">
                            <ng-container *ngFor="let data of qrPlansData[0].details.includes.items;">
                                <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                    <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                    <div class="qr-includes-features">{{data.name}}</div>
                                    <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                        <ng-template #popTemplateInclude>
                                            <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <video src="{{data.tooltip.media?.src}}"
                                                       width="100%"
                                                       height="100%"
                                                       autoplay
                                                       style="border-radius: 16px"
                                                >
                                                </video>
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <img src="{{data.tooltip.media.src}}"
                                                     width="100%"
                                                     height="100%"
                                                     style="border-radius: 16px"
                                                >
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                        </ng-template>
                                        <button type="button"
                                                class="btn tooltip-button"
                                                [tooltip]="popTemplateInclude"
                                                placement="auto"
                                                triggers="hover"
                                                container="body"
                                        >
                                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

            </div>

        </div>
        <!--Pro-Individual-->
        <div class="width-25-mobile mb-3" [ngStyle]="{'margin-top': showDropdownOnTop(PLAN_COLUMN_TYPES.LITE_INDIVIDUAL_RECOMMENDED) ? '-96px' : showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL) ? '0px': '-48px'}"
             [ngClass]="{'dropdown-on-top-mobile': showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)}" *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="plan-details-container" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_INDIVIDUAL)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div>
                        <span class="qr-plan-name" style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%); color:#16212f">{{qrPlansData[2].name}}</span>
                        <span class="discount-percent" *ngIf="isEligibleForDiscount && pageType === PRICING_PAGE_TYPE.UPGRADE_NOW">
                            at <span style="">50% OFF</span>
                        </span>
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate4>
                            <div class="tooltip-content-mobile d-flex flex-column justify-content-center align-items-start tooltip-content-mobile-click3"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{qrPlansData[2].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{qrPlansData[2].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-mobile-click3"
                                [tooltip]="popTemplate4"
                                placement="auto"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-mobile-click3" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" [style.margin-bottom.px]="qrPlansData[2].price[selectedCurrency].discountedValue && isEligibleForDiscount? 8 : 32">
                    {{qrPlansData[2].description}}
                </div>
                <div class="qr-plan-price-discount" style="color: #16212f" *ngIf="qrPlansData[2].price[selectedCurrency].discountedValue && isEligibleForDiscount">
                    <s>{{ qrPlansData[2].price[selectedCurrency].symbol }}{{qrPlansData[2].price[selectedCurrency].value}}</s>
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{qrPlansData[2].price[selectedCurrency].symbol}}{{ qrPlansData[2].price[selectedCurrency].discountedValue && isEligibleForDiscount? qrPlansData[2].price[selectedCurrency].discountedValue : qrPlansData[2].price[selectedCurrency].value }}
                    </div>
                    <div class="qr-plan-price-description">
                        {{ qrPlansData[2].price[selectedCurrency].discountedMessage && isEligibleForDiscount? qrPlansData[2].price[selectedCurrency].discountedMessage : qrPlansData[2].price[selectedCurrency].message}}
                        <span *ngIf="qrPlansData[2].price[selectedCurrency]?.tooltip && isEligibleForDiscount">
                                <ng-template #discountTooltip>
                                    <div class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <div class="tooltip-content-text">{{qrPlansData[2].price[selectedCurrency]?.tooltip?.description}}</div>
                                    </div>
                                </ng-template>
                                <button type="button"
                                        class="btn tooltip-button"
                                        [tooltip]="discountTooltip"
                                        placement="auto"
                                        triggers="hover"
                                        container="body"
                                >
                                    <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                </button>
                            </span>
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Pro)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Pro)"
                                       [type]="
                              user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE
"
                                       [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Pro
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                       [disabled]="
                              user.customer_plan == PLAN_TYPES.Pro ? true : false
                            "
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail">
                        <b>{{qrPlansData[2].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="qr-detail">
                        <b>{{qrPlansData[2].details.userSeats.value}}</b> {{qrPlansData[2].details.userSeats.suffix}}
                    </div>
                </div>

                <!--section division line-->
<!--                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">-->

                <div class="d-flex flex-column qr-include-exclude-section-mobile">
                    <div>
                        <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile mb-2" (click)="toggleIncludes(3)">
                            {{qrPlansData[2].details.includes.message}}
                            <i class="far" [ngClass]="{'fa-chevron-up': showIncludes[3], 'fa-chevron-down': !showIncludes[3]}" style="color: #16212f;"></i>
                        </div>
                        <ng-container *ngIf="showIncludes[3]">
                            <ng-container *ngFor="let data of qrPlansData[2].details.includes.items;">
                                <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                    <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                    <div class="qr-includes-features">{{data.name}}</div>
                                    <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                        <ng-template #popTemplateInclude>
                                            <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <video src="{{data.tooltip.media?.src}}"
                                                       width="100%"
                                                       height="100%"
                                                       autoplay
                                                       style="border-radius: 16px"
                                                >
                                                </video>
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <img src="{{data.tooltip.media.src}}"
                                                     width="100%"
                                                     height="100%"
                                                     style="border-radius: 16px"
                                                >
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                        </ng-template>
                                        <button type="button"
                                                class="btn tooltip-button"
                                                [tooltip]="popTemplateInclude"
                                                placement="auto"
                                                triggers="hover"
                                                container="body"
                                        >
                                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!--Pro-Business-Recommended-->
        <div class="width-25-mobile recommended-banner-extended-mobile mb-3"
             *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED)"
             style="background: #f4f5f7"
             [ngStyle]="{'transform': showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED) ? 'translateY(-96px)' : 'translateY(-48px)'}">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.PRO_BUSINESS_RECOMMENDED)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>
            <div class="plan-details-container" style="background: #182D44; border-radius: 16px">
                <div class="d-flex flex-row flex-start recommended-plan">
                    <i class="far fa-bolt" style="color: #ffffff;"></i>
                    <span class="recommended-plan-heading">Best Value
                        <ng-container *ngIf="isEligibleForDiscount && pageType === PRICING_PAGE_TYPE.UPGRADE_NOW">
                            at <span style="color: #FEDD55;">50% OFF</span>
                        </ng-container>
                    </span>
                </div>
                <div class="" style="padding: 24px">
                    <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                        <div class="qr-plan-name recommend-plan-color" style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%);  color:#16212f">
                            {{qrPlansData[1].name}}
                        </div>
                        <div class="tooltip-parent">
                            <ng-template #popTemplate5>
                                <div class="tooltip-content-mobile d-flex flex-column justify-content-center align-items-start tooltip-content-mobile-click4"
                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                    <video src="{{qrPlansData[1].tooltip.media.src}}"
                                           width="100%"
                                           height="100%"
                                           controls
                                           autoplay
                                           style="border-radius: 16px"
                                    >
                                    </video>
                                    <div class="tooltip-content-text">{{qrPlansData[1].tooltip.description}}</div>
                                </div>
                            </ng-template>
                            <button type="button"
                                    class="btn tooltip-button tooltip-button-mobile-click4"
                                    [tooltip]="popTemplate5"
                                    placement="auto"
                                    triggers="click"
                                    container="body"
                                    #pop="bs-tooltip"
                            >
                                <img class="tooltip-icon-mobile-click4" src="../../../assets/img/user-upgrade/info-icon.svg">
                            </button>
                        </div>
                    </div>
                    <div class="qr-plan-description recommend-plan-color" [style.margin-bottom.px]="qrPlansData[1].price[selectedCurrency].discountedValue && isEligibleForDiscount ? 8 : 32">
                        {{qrPlansData[1].description}}
                    </div>
                    <div class="qr-plan-price-discount" *ngIf="qrPlansData[1].price[selectedCurrency].discountedValue && isEligibleForDiscount">
                        <s>{{ qrPlansData[1].price[selectedCurrency].symbol }}{{qrPlansData[1].price[selectedCurrency].value}}</s>
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                        <div class="qr-plan-price recommend-plan-color mr-1">
                            {{qrPlansData[1].price[selectedCurrency].symbol}}{{ qrPlansData[1].price[selectedCurrency].discountedValue && isEligibleForDiscount? qrPlansData[1].price[selectedCurrency].discountedValue : qrPlansData[1].price[selectedCurrency].value }}
                        </div>
                        <div class="qr-plan-price-description recommend-plan-color">
                            {{ qrPlansData[1].price[selectedCurrency].discountedMessage  && isEligibleForDiscount? qrPlansData[1].price[selectedCurrency].discountedMessage : qrPlansData[1].price[selectedCurrency].message}}
                            <span *ngIf="qrPlansData[1].price[selectedCurrency]?.tooltip && isEligibleForDiscount">
                                <ng-template #discountTooltip>
                                    <div class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <div class="tooltip-content-text">{{qrPlansData[1].price[selectedCurrency]?.tooltip?.description}}</div>
                                    </div>
                                </ng-template>
                                <button type="button"
                                        class="btn tooltip-button"
                                        [tooltip]="discountTooltip"
                                        placement="auto"
                                        triggers="hover"
                                        container="body"
                                >
                                    <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                </button>
                            </span>
                        </div>
                    </div>

                    <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                        <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Pro)" [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'" [style]="BUTTON_STYLES.FILLED" [label]="'BUY NOW'"></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                        <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Pro)"
                                           [type]="
                              user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                           [width]="'100%'"
                                           [style]="user.customer_plan == PLAN_TYPES.Pro
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.FILLED
"
                                           [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Pro
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                           [disabled]="
                              user.customer_plan == PLAN_TYPES.Pro ? true : false
                            "
                        ></beaconstac-button>
                    </div>

                    <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px; background:#16212F">
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                        </div>
                        <div class="qr-detail recommend-plan-color">
                            <b>{{qrPlansData[1].details.userSeats.value}}</b> {{qrPlansData[1].details.userSeats.suffix}}
                        </div>
                    </div>

                    <!--section division line-->
<!--                    <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">-->

                    <div class="d-flex flex-column qr-include-exclude-section-mobile">
                        <div>
                            <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile-recommended recommend-plan-color-mobile mb-2" (click)="toggleIncludes(4)">
                                {{qrPlansData[1].details.includes.message}}
                                <i class="far" [ngClass]="{'fa-chevron-up': showIncludes[4], 'fa-chevron-down': !showIncludes[4]}"></i>
                            </div>
                            <ng-container *ngIf="showIncludes[4]">
                                <ng-container *ngFor="let data of qrPlansData[1].details.includes.items;">
                                    <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                        <i class="fas fa-check fa-xs recommend-plan-color"></i>
                                        <div class="qr-includes-features recommend-plan-color">{{data.name}}</div>
                                        <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                            <ng-template #popTemplateInclude>
                                                <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                    <video src="{{data.tooltip.media?.src}}"
                                                           width="100%"
                                                           height="100%"
                                                           autoplay
                                                           style="border-radius: 16px"
                                                    >
                                                    </video>
                                                    <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                                </div>
                                                <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                    <img src="{{data.tooltip.media.src}}"
                                                         width="100%"
                                                         height="100%"
                                                         style="border-radius: 16px"
                                                    >
                                                    <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                                </div>
                                                <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                                     style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                    <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                                </div>
                                            </ng-template>
                                            <button type="button"
                                                    class="btn tooltip-button"
                                                    [tooltip]="popTemplateInclude"
                                                    placement="auto"
                                                    triggers="hover"
                                                    container="body"
                                            >
                                                <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!--Plus-->
        <div class="width-25-mobile mb-3"
             [ngStyle]="{'margin-top': determinePlusPlanMarginTop()}"
             [ngClass]="{'dropdown-on-top-mobile': showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)}"
             *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.PLUS_BOTH)">
            <ng-container *ngIf="showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)">
                <div *ngTemplateOutlet="currency_converter_dropdown"></div>
            </ng-container>

            <div class="plan-details-container" style="padding: 24px" [ngClass]="{'alter-max-height': showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH)}">
                <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                    <div class="qr-plan-name" style="background: linear-gradient(90deg, #2EE3EA 0%, #8AE962 100%);  color:#16212f">
                        {{showBusinessPlans? qrPlansData[2].name: qrPlansData[3].name}}
                    </div>
                    <div class="tooltip-parent">
                        <ng-template #popTemplate6>
                            <div class="tooltip-content-mobile d-flex flex-column justify-content-center align-items-start tooltip-content-mobile-click5"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <video src="{{showBusinessPlans ? qrPlansData[2].tooltip.media.src : qrPlansData[3].tooltip.media.src}}"
                                       width="100%"
                                       height="100%"
                                       controls
                                       autoplay
                                       style="border-radius: 16px"
                                >
                                </video>
                                <div class="tooltip-content-text">{{showBusinessPlans ? qrPlansData[2].tooltip.description : qrPlansData[3].tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button tooltip-button-mobile-click5"
                                [tooltip]="popTemplate6"
                                placement="auto"
                                triggers="click"
                                container="body"
                                #pop="bs-tooltip"
                        >
                            <img class="tooltip-icon-mobile-click5" src="../../../assets/img/user-upgrade/info-icon.svg">
                        </button>
                    </div>
                </div>
                <div class="qr-plan-description" style="margin-bottom: 32px">
                    {{showBusinessPlans? qrPlansData[2].description: qrPlansData[3].description}}
                </div>
                <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                    <div class="qr-plan-price mr-1">
                        {{showBusinessPlans ?
                        qrPlansData[2].price[selectedCurrency].symbol + plusPlanPrice:
                        qrPlansData[3].price[selectedCurrency].symbol + plusPlanPrice
                        }}
                    </div>
                    <div class="qr-plan-price-description">
                        {{showBusinessPlans? qrPlansData[2].price[selectedCurrency].message: qrPlansData[3].price[selectedCurrency].message}}
                    </div>
                </div>

                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                    <beaconstac-button (onClick)="handleQRPlanClicked(PLAN_TYPES.Plus)" [type]="BUTTON_TYPES.PRIMARY"
                                       [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'BUY NOW'"></beaconstac-button>
                </div>
                <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                    <beaconstac-button (onClick)="handleBuyButtonClick(PLAN_TYPES.Plus)"
                                       [type]="
                              user.customer_plan == PLAN_TYPES.Plus
                                ? BUTTON_TYPES.SECONDARY
                                : BUTTON_TYPES.PRIMARY
                            "
                                       [width]="'100%'"
                                       [style]="user.customer_plan == PLAN_TYPES.Plus
                                ? BUTTON_STYLES.FILLED
                                : BUTTON_STYLES.STROKE
"
                                       [label]="
                              user.customer_plan == PLAN_TYPES.Trial
                                ? 'BUY NOW'
                                : user.customer_plan == PLAN_TYPES.Plus
                                ? 'CURRENT PLAN'
                                : 'UPGRADE'
                            "
                                       [disabled]="
                              user.customer_plan == PLAN_TYPES.Plus ? true : false
                            "
                    ></beaconstac-button>
                </div>

                <div class="d-flex flex-column align-items-center justify-content-center qr-details-container" style="margin-bottom: 32px">
                    <div class="qr-detail pt-3">
                        <b>{{showBusinessPlans? qrPlansData[2].details.dynamicQRCodes.value: qrPlansData[3].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                    </div>
                    <div class="d-flex flex-row align-items-center justify-content-center" style="column-gap: 8px;">
                        <beaconstac-textfield
                            [(text)]="plusPlanUserAddOn"
                            [maxWidth]="'60px'"
                            [type]="TEXT_FIELD_TYPES.NUMBER"
                            class="plus-plan-user-add"
                            (textChange)="handlePlusPlanUserAddOn($event)"
                            [placeholder]="'Users'">
                        </beaconstac-textfield>
                        <span class="qr-detail" style="padding-bottom: 7px"> User seats</span>
                    </div>
                    <div class="mt-1">
                        <beaconstac-checkbox
                            [label]="'Add Custom Domain'"
                            [disabled]="user.customer_plan === PLAN_TYPES.Plus"
                            [checked]="additionalCustomDomains"
                            (checkedChange)="handlePlusPlanCustomDomainAddOn($event)">
                        </beaconstac-checkbox>
                    </div>
                </div>

                <!--section division line-->
<!--                <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">-->

                <div class="d-flex flex-column qr-include-exclude-section-mobile">
                    <div>
                        <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile mb-2" (click)="toggleIncludes(5)">
                            {{showBusinessPlans ? qrPlansData[2].details.includes.message : qrPlansData[3].details.includes.message}}
                            <i class="far" [ngClass]="{'fa-chevron-up': showIncludes[5], 'fa-chevron-down': !showIncludes[5]}" style="color: #16212f;"></i>
                        </div>
                        <ng-container *ngIf="showIncludes[5]">
                            <ng-container *ngFor="let data of (showBusinessPlans ? qrPlansData[2].details.includes.items : qrPlansData[3].details.includes.items) let i = index ">
                                <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                    <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                    <div class="qr-includes-features">{{data.name}}</div>
                                    <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                        <ng-template #popTemplateInclude>
                                            <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <video src="{{data.tooltip.media?.src}}"
                                                       width="100%"
                                                       height="100%"
                                                       autoplay
                                                       style="border-radius: 16px"
                                                >
                                                </video>
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <img src="{{data.tooltip.media.src}}"
                                                     width="100%"
                                                     height="100%"
                                                     style="border-radius: 16px"
                                                >
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                            <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                            </div>
                                        </ng-template>
                                        <button type="button"
                                                class="btn tooltip-button"
                                                [tooltip]="popTemplateInclude"
                                                placement="auto"
                                                triggers="hover"
                                                container="body"
                                        >
                                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <!--Business+-->
        <div class="width-25-mobile mb-3 plan-details-container" style="padding: 24px; border-left: 1px solid #E4E5E7"
              [ngStyle]="showDropdownOnTop(PLAN_COLUMN_TYPES.PLUS_BOTH) ? {'margin-top': '-48px'} : {'margin-top': '0px'}"
             *ngIf="shouldRenderPlan(PLAN_COLUMN_TYPES.ENTERPRISE_BUSINESS)"
        >
            <div class="d-flex flex-row justify-content-between align-items-center mb-3">
                <div class="qr-plan-name" style="background: linear-gradient(90deg, #6A52FF 0%, #A96CE6 100%);">
                    {{qrPlansData[3].name}}
                </div>
                <div class="tooltip-parent">
                    <ng-template #popTemplate7>
                        <div class="tooltip-content-mobile d-flex flex-column justify-content-center align-items-start tooltip-content-mobile-click6"
                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                            <video src="{{qrPlansData[3].tooltip.media.src}}"
                                   width="100%"
                                   height="100%"
                                   controls
                                   autoplay
                                   style="border-radius: 16px"
                            >
                            </video>
                            <div class="tooltip-content-text">{{qrPlansData[3].tooltip.description}}</div>
                        </div>
                    </ng-template>
                    <button type="button"
                            class="btn tooltip-button tooltip-button-mobile-click6"
                            [tooltip]="popTemplate7"
                            placement="auto"
                            triggers="click"
                            container="body"
                            #pop="bs-tooltip"
                    >
                        <img class="tooltip-icon-mobile-click6" src="../../../assets/img/user-upgrade/info-icon.svg">
                    </button>
                </div>
            </div>
            <div class="qr-plan-description" style="margin-bottom: 32px">
                {{qrPlansData[3].description}}
            </div>
            <div class="d-flex flex-row justify-content-start align-items-center" style="margin-bottom: 32px">
                <div class="qr-plan-price mr-2">
                    <i class="far fa-comments" style="color: #16212f;"></i>
                </div>
                <div class="qr-plan-price-description">
                    {{qrPlansData[3].price.message}}
                </div>
            </div>

            <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'renew-plan'">
                <beaconstac-button (onClick)="onEnterpriseSelected.emit()" [type]="BUTTON_TYPES.PRIMARY"
                                   [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'SCHEDULE DEMO'"></beaconstac-button>
            </div>
            <div class="buy-btn" style="margin-bottom: 32px" *ngIf = "pageType == 'upgrade-now'">
                <beaconstac-button (onClick)="onEnterpriseSelected.emit()" [type]="BUTTON_TYPES.PRIMARY"
                                   [width]="'100%'" [style]="BUTTON_STYLES.STROKE" [label]="'SCHEDULE DEMO'"></beaconstac-button>
            </div>

            <div class="d-flex flex-column justify-content-center align-items-center qr-details-container" style="margin-bottom: 32px">
                <div class="qr-detail">
                    <b>{{qrPlansData[3].details.dynamicQRCodes.value}}</b> Dynamic QR Codes
                </div>
                <div class="qr-detail">
                    <b>{{qrPlansData[3].details.userSeats.value}}</b> {{qrPlansData[3].details.userSeats.suffix}}
                </div>
                <div class="d-flex flex-row align-items-center justify-content-center qr-detail" style="column-gap: 4px">
                    <b>{{qrPlansData[3].details.organizations.value}}</b> Organizations
                    <div class="tooltip-parent">
                        <ng-template #popTemplateOrganization>
                            <div class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                 style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                <div class="tooltip-content-text">{{qrPlansData[3].details.organizations.tooltip.description}}</div>
                            </div>
                        </ng-template>
                        <button type="button"
                                class="btn tooltip-button"
                                [tooltip]="popTemplateOrganization"
                                placement="auto"
                                triggers="hover"
                                container="body"
                        >
                            <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!--section division line-->
<!--            <hr style="border-top: 1px solid #E4E5E7; margin-bottom: 32px">-->

            <div class="d-flex flex-column qr-include-exclude-section-mobile">
                <div>
                    <div class="d-flex flex-row justify-content-between align-items-center qr-includes-heading-mobile mb-2" (click)="toggleIncludes(6)">
                        {{qrPlansData[3].details.includes.message}}
                        <i class="far" [ngClass]="{'fa-chevron-up': showIncludes[6], 'fa-chevron-down': !showIncludes[6]}" style="color: #16212f;"></i>
                    </div>
                    <ng-container *ngIf="showIncludes[6]">
                        <ng-container *ngFor="let data of qrPlansData[3].details.includes.items;">
                            <div class="d-flex flex-row align-items-center" style="column-gap:8px">
                                <i class="fas fa-check fa-xs" style="color: #16212f;"></i>
                                <div class="qr-includes-features">{{data.name}}</div>
                                <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                    <ng-template #popTemplateInclude>
                                        <div *ngIf = "data.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{data.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "data.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{data.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplateInclude"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <ng-template #currency_converter_dropdown>
        <div class="d-flex flex-start dropdown-parent">
        <span dropdown (isOpenChange)="isOpenChange()" class="currency-converter">
            <a href id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown" class="currency-select">
                {{getSelectedCurrencySymbol()}}{{selectedCurrency}}
                <i class="fas fa-chevron-down" [@rotate]="isDropdownOpen ? 'open' : 'closed'" style="color: #16212f; font-size: 12px"></i>
            </a>
            <ul
                id="basic-link-dropdown"
                *dropdownMenu
                class="dropdown-menu currency-options-container"
                role="menu"
                aria-labelledby="basic-link"
            >
                <li *ngFor="let currency of currencies; let i = index">
                    <a class="dropdown-item currency-options" (click)="updatePrice(currency.code)">{{currency.symbol}}{{currency.code}}</a>
                </li>
            </ul>
        </span>
        </div>
    </ng-template>
</div>

<div bsModal #responseAddOnModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered custom-modal-width" role="document">
      <div class="modal-content">

        <div class="modal-body p-4">
            <div class="d-flex justify-content-left align-items-center mb-2 modal-plan-name-font">
                <div class="modal-qr-plan-name" [ngClass]="'modal-qr-' + selectedPlan.name">
                    {{selectedPlan.name}}
                </div><span class="neutral-color">PLAN</span>
            </div>

            <div class="d-flex flex-row justify-content-start align-items-center pb-4">
                <div class="modal-qr-plan-price mr-1">
                    ${{selectedPlan.price}}
                </div>
                <div class="modal-qr-plan-price-description">
                    USD/month, billed yearly
                </div>
            </div>

            <div class="add-on-title pb-2">Add-ons</div>
            <div class="response-addon-description neutral-color pb-2">Boost your plan with additional responses on Forms</div>

            <form class="pt-1 mb-4">
                <div *ngFor="let option of responseAddonOptions; let i = index">
                    <beaconstac-stroke-radio-button
                        [button]="option"
                        (onClick)="setUpResponseAddonModal($event, i)"
                    ><span class="addon-form-bold-title">{{ responseAddonCount[i] }}</span></beaconstac-stroke-radio-button>
                </div>
            </form>

            <div class="d-flex flex-column modal-qr-details-container">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="modal-items">{{selectedPlan.name}} PLAN</span>
                    <span class="modal-items-price">${{selectedPlan.price*12}}</span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <!-- add-ons price -->
                    <span class="modal-items">ADD-ONS</span>
                    <span class="modal-items-price">${{selectedAddonPrice}}</span>
                </div>
                <div class="modal-total-price-seperator"></div>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="modal-items">TOTAL PAYABLE</span>
                    <span class="modal-items-price">${{selectedPlan.price*12 + selectedAddonPrice}}</span>
                </div>
            </div>

        </div>
        <div class="modal-footer px-4 pb-4 pt-0 border-0">
            <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                (onClick)="responseAddOnModal.hide();"
                                [label]="'Cancel'"
            ></beaconstac-button>
            <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Pay & Upgrade'"
                                (onClick)="upgradePlanForForms()"
                                [style]="BUTTON_STYLES.FILLED"
            ></beaconstac-button>
        </div>

      </div>
    </div>
</div>


