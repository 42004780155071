import {Injectable} from '@angular/core';
import {BaseBackendService} from './base-backend.service';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {BaseModel} from './base.model';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';


class ExternalUrl extends BaseModel {
    constructor() {
        super();
    }
}

@Injectable()
export class ExternalUrlService extends BaseBackendService<ExternalUrl> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, ExternalUrl, 'validate_url', messageModal);
    }

    public getURL(url: string, responseType = 'text', observe = 'body'): Observable<ExternalUrl> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        return this.http
            .post(this.apiURL, {url: url}, this.buildHeaders({responseType: responseType, observe: observe})).pipe(
                map(response => {
                    return response;
                }),
                catchError(error => {
                    return this.handleError(error);
                }));
    }

    get URL() {
        return this.apiURL;
    }
}
