import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../global-services/auth.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseBackendService} from '../global-services/base-backend.service';
import {User} from '../user-account/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserDetailService extends BaseBackendService<User> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, User, 'users/intercom/tag', messageModal);
    }

    /**
   *
   * @param body
   * @param custom
   */
    public post(body: object, custom?: string): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        let url = this.apiURL;
        if (custom) {
            url += custom;
        }

        const argsObject = {};
        argsObject['headers'] = new HttpHeaders({
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });

        return this.http.post(url, body, argsObject).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }
}
