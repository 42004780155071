<div class="global-search-wrapper icon-margin--right" role="search" aria-label="Global search" (click)="$event.stopPropagation()">
  <!-- If mobile device -->

  <div class="search-bar d-md-none d-flex">
    <i class="text-white fas fa-search"></i>
    <input 
    type="text"
    id="searchInputMobile"
    autofocus="false"
    class="text-white border-0"
    (click)="onInputFocus()"
    placeholder="Search by name or paste the link for best results..." 
    [(ngModel)]="searchText"
    (input)="onSearchInput()" 
    (keydown)="onKeydownHandler($event)">
     <i class="text-white fas fa-times" (click)="clearSearch(true)"></i>
  </div>

  <!-- If not a mobile device -->
  <div *ngIf="showSearch" class="search-bar d-none d-md-block">
    <i class="text-white fas fa-search"></i>
    <input type="text"
    autofocus="true"
    id="searchInputDesktop"
    class="text-white border-0"
    (focus)="onInputFocus()"
    placeholder="Search by name or paste the link for best results..." 
    [(ngModel)]="searchText"
    (input)="onSearchInput()" 
    (keydown)="onKeydownHandler($event)">
    <i *ngIf="showSearch" class="text-white fas fa-times" (click)="clearSearch()"></i>
  </div>
  <div *ngIf="!showSearch" style="height: 55px;" (click)="onInputFocus()" class="cursor-pointer d-none d-md-flex align-items-center justify-content-center">
    <i class="ml-auto text-white fa fa-search"  title="Search"></i>
  </div>

  <div 
    *ngIf="showSearch" 
    (click)="closeDropdowns($event)" 
    [@collapse] 
    class="search-dropdown"
    [ngClass]="isMobileDevice ? 'overflow-y-scroll' : '' ">
    <!-- Initial search state -->
    <div *ngIf="!searchError" class="text-left w-100" >
      <section *ngIf="!searchText" class="p-3 text-white w-100">
        <div *ngIf="recentSearches.length" class="font-weight-600">RECENT SEARCHES</div> 
        <div *ngIf="!recentSearches.length" class="font-weight-600">NO RECENT SEARCHES FOUND</div> 
        <ul  *ngIf="recentSearches.length" class="p-0 search-list-conatiner">
          <li 
            *ngFor="let recentSearch of recentSearches" 
            class="cursor-pointer d-flex align-items-center"
            (click)="onClickRecentSearch(recentSearch)">
            <i class="mr-2 text-white fas fa-search"></i> 
            {{ recentSearch }}
          </li>
        </ul>
      </section>
      <hr *ngIf="!searchText" class="text-white"/>
      <section *ngIf="!searchText" class="p-3 text-white w-100">
        <div class="font-weight-600">NEED MORE HELP? CHECK THESE OUT</div> 
        <ul class="p-0 search-list-conatiner">
          <li *ngFor="let item of helpDocsStatic" title="{{ item.title }}" class="cursor-pointer d-flex align-items-center">
            <a class="text-white text-decoration-none" target="_blank" [href]="item.url">
              <i class="mr-2 far fa-question-circle"></i>
              {{ item.title }}
            </a>
          </li>
        </ul>
      </section>
    </div>

    <!-- States after user starts search -->
    <div *ngIf="searchText">
      <section class="text-white w-100">
        <!-- Dropdowns -->
        <div *ngIf="searchResults?.length" class="p-3 d-flex flex-column flex-md-row justify-content-between align-items-center flex-gap-4">
          <div class="d-flex flex-column flex-item flex-gap-3 w-100">
            <div class="text-left">Filter by</div>
            <div class="dropdown" (click)="toggleFilterByDropdown($event)">
              <button 
                class="px-3 py-2 text-left text-white dropdown-btn w-100 d-flex align-items-center justify-content-between">
                {{ filterBy.name }}
                <i [@rotate]="isFilterByDropdownOpen ? 'open' : 'closed'" class="float-right fas fa-caret-down fa-2x"></i>
              </button>
              <div *ngIf="isFilterByDropdownOpen" [@collapse] [ngClass]="isFilterByDropdownOpen ? 'd-block' : 'd-none' " class="dropdown-content w-100">
                <div 
                  *ngFor="let filterOption of filterByOptions"
                  (click)="onSelectFilterOption(filterOption)"
                  class="py-2 text-white cursor-pointer dropdown-item d-flex align-items-center"
                  > <i [ngClass]="filterBy.value === filterOption.value ? 'd-block' : 'd-hidden' " class="text-white fas fa-check"></i>
                  {{ filterOption.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column flex-item flex-gap-3 w-100">
            <div class="text-left">Sort by</div>
            <div class="dropdown" (click)="toggleSortByDropdown($event)">
              <button 
                class="px-3 py-2 text-left text-white dropdown-btn w-100 d-flex align-items-center justify-content-between">
                {{ sortBy.name }}
                <i [@rotate]="isSortByDropdownOpen ? 'open' : 'closed'" class="float-right fas fa-caret-down fa-2x"></i>
              </button>
              <div *ngIf="isSortByDropdownOpen" [@collapse] [ngClass]="isSortByDropdownOpen ? 'd-block' : 'd-none' " class="dropdown-content w-100">
                <div 
                  *ngFor="let sortByOption of sortByOptions" 
                  (click)="onSelectSortByOption(sortByOption)"
                  class="py-2 text-white cursor-pointer dropdown-item d-flex align-items-center"
                  > <i [ngClass]="sortBy.value === sortByOption.value ? 'd-block' : 'd-hidden' " class="text-white fas fa-check"></i>
                  {{ sortByOption.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="searchResults?.length && !isProcessingSearch" class="d-flex justify-content-between" [ngClass]="searchError || isProcessingSearch ? 'pt-3' : !searchResults?.length  && 'pt-3'">
          <div class="px-3 text-left font-weight-600">SEARCH RESULTS</div>
          <div class="pr-3 text-secondary">showing {{searchResults.length}} out of {{ totalSearchResults }} results</div>
        </div>
        <div *ngIf="isProcessingSearch && !searchError && !seeMoreClicked" [ngClass]=" isProcessingSearch ? 'p-3' :'' " class="d-flex align-items-center justify-content-center w-100">
          <spinner class="custom-spinner-analytics" [size]="20" [tickness]="2"></spinner>
        </div>
        <div *ngIf="searchResults?.length" class="my-3 results-container hide-scrollbar" [ngClass]="seeMoreClicked && 'overflow-y-scroll'">
          <div 
            *ngFor="let item of searchResults; let last = last" 
            #lastItem
            class="px-3 py-2 search-item" 
            [routerLink]="item.link"
            (click)="onSelectSearchItem(item)"
            [queryParams]="item.queryParams"
            [state]="{name: item.name}">
          <div class="d-flex align-items-center">
            <i class="icon text-muted" [ngClass]="item.icon"></i>
            <div class="w-100">
              <div class="pb-8 d-flex w-100 justify-content-between align-items-center">
                <div class="result-name" [innerHTML]="item.name | highlight : searchText"></div>
                <span class="d-none d-md-block">{{ item.updated | getReadableDate}}</span>
              </div>
              
              <div class="additional-info--grid">
                <div title="{{ item.object_type | getObjectNameFromType }}" class="additional-info text-muted">
                  {{ item.object_type | getObjectNameFromType }}
                </div>
                <div title="{{item.organization.name}}" *ngIf="isReseller" class="additional-info text-muted">
                  <i class="mr-2 far fa-building text-muted"></i> {{item.organization.name}}
                </div>
                <div 
                  *ngIf="item?.maintainer_v2?.name" 
                  title="{{item.maintainer_v2.name}} ({{item.maintainer_v2.email}})" 
                  class="ml-2 additional-info text-muted">
                  <i class="fas fa-user-alt mr-2 text-muted"></i>
                  <span [innerHTML]="item.maintainer_v2.name | highlight : searchText"></span>
                </div>
                <div 
                  class="additional-info text-muted" 
                  *ngFor="let field of item.additionalFields"
                  title="{{ field.text }}">
                  <i class="mr-2 text-muted" [ngClass]="field.icon"></i> <span
                  [innerHTML]="field.text | highlight : searchText"></span>
                </div>
              </div>
              <div *ngIf="item.notes" class="additional-info-notes w-100 text-muted text-truncate mt-1" >
                <i class="mr-2 text-muted fas fa-sticky-note"></i>
                <span [innerHTML]="item.notes | highlight : searchText"></span>
              </div>
            </div>
          </div>
          </div>
          <div id="spinner" *ngIf="seeMoreClicked && isProcessingSearch" [ngClass]="isProcessingSearch ? 'p-3' :'' " class="d-flex align-items-center justify-content-center w-100">
            <spinner class="custom-spinner-analytics" [size]="20" [tickness]="2"></spinner>
          </div>
        </div>
        <div 
          *ngIf="totalSearchResults > searchResults?.length" 
          class="px-3 text-left cursor-pointer result-name text-primary" 
          (click)="fetchMore()"
          title="See more search results">
            See more
        </div>
        <div *ngIf="searchError" class="py-3 text-center w-100">{{ searchError }}</div>
        <!-- Empty state when both search results & help docs are empty -->
        <div *ngIf="!searchResults?.length && !isProcessingSearch && hasFirstSearchDone" class="p-3 text-center text-white w-100">
          <h5>Uh-oh! No results found <i class="fas fa-sad-tear"></i></h5>
          <div>Please double-check your spelling or use a different keyword.</div>
        </div>
      </section>
      <hr [ngClass]="totalSearchResults > searchResults?.length ? 'mt-3' : ''" class="text-white"/>
      <!-- Help docs section -->
      <section class="py-3 pl-3 text-left text-white w-100" [ngClass]="isProcessingDocs && 'pr-3'">
        <div *ngIf="!isProcessingDocs && helpDocResults?.length" class="font-weight-600">NEED MORE HELP? CHECK THESE OUT</div> 
        <div *ngIf="isProcessingDocs" class="p-3 d-flex align-items-center justify-content-center w-100">
          <spinner class="custom-spinner-analytics" [size]="20" [tickness]="2"></spinner>
        </div>
        <ul *ngIf="!isProcessingDocs && helpDocResults?.length" class="p-0 search-list-conatiner overflow-y-scroll">
          <li *ngFor="let doc of helpDocResults" title="{{ doc.title }}" class="cursor-pointer d-flex align-items-center">
            <a class="text-white text-decoration-none" target="_blank" [href]="doc.url">
              <i class="mr-2 far fa-question-circle"></i>
              {{ doc.title }}
            </a>
          </li>
        </ul>
        <ul *ngIf="!isProcessingDocs && !helpDocResults?.length" class="p-0 search-list-conatiner overflow-y-scroll">
          <li *ngFor="let doc of helpDocsStatic" title="{{ doc.title }}" class="cursor-pointer d-flex align-items-center">
            <a class="text-white text-decoration-none" target="_blank" [href]="doc.url">
              <i class="mr-2 far fa-question-circle"></i>
              {{ doc.title }}
            </a>
          </li>
        </ul>
        <div *ngIf="!isProcessingDocs && searchText && helpDocResults.length" class="pt-2 cursor-pointer result-name text-primary">
          <a 
            href="{{ 'https://docs.beaconstac.com/en/?q='+ searchText }}"
            class="text-decoration-none"
            target="_blank">
              See all related articles
          </a>
      </div>
      </section>
    </div>
  </div>
</div>