<div style="background-color: #f4f5f7; margin-left: -1rem; margin-right: -1rem; padding-bottom:24px;">
   <div style="margin:0px 15px;">
    <div class="font-weight-600" style="color:#16212F; font-size: 20px;">
        {{ cardType === DBCCardTypes.TEMPLATE_CARDS ? 'Set-up Template' : 'Optimize Your Card' }}
    </div>
      <div class="" [ngStyle]="{'color': '#979797', 'font-size.px':15}">
        Discover and add advanced features provided by Uniqode
      </div>
   </div>
</div>
<div class="bx--row" style="margin-top:0px!important; padding:0px 15px; background-color: #f4f5f7;" *ngIf="product">
    <div class="bx--col bg-white">
        <div class="bx--row mt-3"  *ngIf="cardType === DBCCardTypes.TEMPLATE_CARDS">
            <div class="bx--col cursor-pointer" (click)="collapseSection('templateProperties')" [ngStyle]="{ 'padding-bottom':sections.templateProperties ? '  16px' : '0px' }">
                <span
                    class="font-fs-15 text-left font-weight-600 text-uppercase line-height-15 letter-spacing-1 text-gray">
                    Properties
                </span>
                <span class="float-right">
                    <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                       [icon]="sections.templateProperties ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                                       [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                  </span>
            </div>
        </div>
        <div class="bx--row" *ngIf="!sections.templateProperties && cardType === DBCCardTypes.TEMPLATE_CARDS" [@collapse]>
            <div class="bx--col">
                <hr class="horizontal-divider"/>
            </div>
        </div>

        <div class="bx--row mt-3" style="margin-bottom:20px;" *ngIf="!sections.templateProperties && cardType === DBCCardTypes.TEMPLATE_CARDS" [@collapse]>
            <div class="bx--col-lg-8" id="template_name_input">
                <beaconstac-textfield name="Template_name" [label]="true"
                                      [placeholder]="''"
                                      [title]="'Template name*'"
                                      [type]="TEXT_FIELD_TYPES.TEXT"
                                      [(text)]="product.template_name"
                                      (textChange)="validations.template_name = true; onVCardContentChange()"
                                      [required]="true"
                                      [disabled]="!hasWriteAccess"
                                      [ngStyle]="{'display':'block','width':'100%'}"
                                      [valid]="validations.template_name  ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR"
                                      [errorMessage]="'Template name is required'"
                ></beaconstac-textfield>
            </div>
        </div>
        <div class="bx--row" style="background-color: #f4f5f7;" *ngIf="cardType === DBCCardTypes.TEMPLATE_CARDS">
            <div class="bx--col">
                <div style="height:16px;"></div>
            </div>
        </div>

        <div class="bx--row" style="border:1px solid #E4E5E7;">
            <div class="bx--col">
            <div class="bx--row mt-3">
                <div class="bx--col cursor-pointer" (click)="collapseSection('urlDomain')" [ngStyle]="{ 'padding-bottom':sections.urlDomain ? '  16px' : '0px' }" >
                    <span
                        class="font-fs-15 text-left font-weight-600 text-uppercase line-height-15 letter-spacing-1 text-gray">
                      URL Domain
                    </span>
                    <span class="float-right">
                      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                         [icon]="sections.urlDomain ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                                         [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                    </span>
                </div>
            </div>
            <div class="bx--row" *ngIf="!sections.urlDomain" [@collapse]>
                <div class="bx--col">
                    <span class="font-13 line-height-15 font-normal font-weight-400 letter-spacing-q text-custom-gray">
                        Select a domain for your card.
                    </span>
                </div>
            </div>
            <div class="bx--row" *ngIf="!sections.urlDomain" [@collapse]>
                <div class="bx--col">
                    <hr class="horizontal-divider"/>
                </div>
            </div>

            <div class="bx--row mt-3" *ngIf="!sections.urlDomain" [@collapse]>
                <div class="bx--col d-flex">

                    <beaconstac-toggle
                        [disabled]="!isNewDigitalBusinessCard"
                        (checkedChange)="handleShortUrlToggle();"
                        [(checked)]="customSlugDisabled"
                    ></beaconstac-toggle>

                    <label class="form-control-label linkpage-toggle-label">Auto-generated</label>
                    <img  class="icon-left my-1" *ngIf="!hasShortUrlAccess" style="margin-left:10px; width:15.83px; height:12.67px;"  src="../../../../assets/logo/crown-solid.svg">
                </div>
            </div>

            <div *ngIf="isComputerScreenSize && !sections.urlDomain" class="bx--row mt-3" style="padding-bottom:20px;" [@collapse]>
                <div class="bx--col">
                    <beaconstac-textfield
                        [disabled]="( domains.length === 1 ) || !hasWriteAccess"
                        [title]="'url_domain'"
                        [text]="domains[0].name"
                        >
                    </beaconstac-textfield>
                </div>
                <span class="hide-after-md" style="font-size: 24px; margin-bottom: 10px;">/</span>
                <div class="bx--col slug-section" id="custom-slug">
                    <beaconstac-textfield [disabled]="customSlugDisabled"
                                            [valid]="slugValidation"
                                            [errorMessage]="slugValidationError"
                                            (textChange)="onCustomSlugContentChange($event)"
                                            [text]="isNewDigitalBusinessCard ? product.slug : (product.url | getSlugFromUrl)"
                                            [placeholder]="customSlugDisabled ? '(Auto generated)'  : 'Enter shortname'">
                    </beaconstac-textfield>
                </div>
            </div>
            <div *ngIf="isMobileScreenSize && !sections.urlDomain" class="bx--row mt-3" style="padding-bottom:20px;" [@collapse]>
                <div class="bx--col ml-3 mr-3">
                <div class="bx--row">
                    <beaconstac-textfield
                        [disabled]="( domains.length === 1 ) || !hasWriteAccess"
                        [title]="'url_domain'"
                        [text]="domains[0].name"
                        style="width:100%; padding:0px;"
                        >
                    </beaconstac-textfield>
                </div>
                <div class="bx--row slug-section" id="custom-slug">
                    <beaconstac-textfield [disabled]="customSlugDisabled"
                                            [valid]="slugValidation"
                                            [errorMessage]="slugValidationError"
                                            (textChange)="onCustomSlugContentChange($event)"
                                            [text]="isNewDigitalBusinessCard ? product.slug : (product.url | getSlugFromUrl)"
                                            [placeholder]="customSlugDisabled ? '(Auto generated)'  : 'Enter shortname'"
                                            style="width:100%; margin-top:8px; padding:0px;"
                                            >
                    </beaconstac-textfield>
                </div>
            </div>
            </div>
            </div>
        </div>

        <!--   Separator   -->
        <div *ngIf="launchDarkleyFlags[FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM]" class="bx--row section-separator">
            <div class="bx--col">
                <div class="separator-height"></div>
            </div>
        </div>

        <div *ngIf="launchDarkleyFlags[FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM]" class="bx--row section-border">
            <div class="bx--col">
                <div class="bx--row mt-3">
                    <div class="bx--col cursor-pointer d-flex justify-content-between align-items-center" (click)="collapseSection('lead_generation')" [ngStyle]="{ 'padding-bottom':sections.lead_generation? '  16px' : '0px' }">
                            <span
                                class="font-fs-15 text-left font-weight-600 text-uppercase line-height-15 letter-spacing-1 text-gray">
                              Lead generation
                            </span>
                        <span class="float-right">
                              <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                                 [icon]="sections.lead_generation ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                                                 [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                            </span>
                    </div>
                </div>
                <div class="bx--row" *ngIf="!sections.lead_generation" [@collapse]>
                    <div class="bx--col">
                        <hr class="horizontal-divider"/>
                    </div>
                </div>
                <app-dbc-lead-form *ngIf="!sections.lead_generation" [@collapse]
                                   [product]="product"
                                   [hasWriteAccess]="hasWriteAccess"
                                   [(cardSetting)]="cardSetting"
                                   [orgCardSetting]="orgCardSetting"
                                   [cardType]="cardType"
                                   [priortizeOrgSettings]="priortizeOrgSettings"
                                   [leadSettings]="leadSettings"
                                   [isSalesforceConnected]="isSalesforceConnected"
                                   (productChange)="onVCardContentChange()"
                                   (lockIconClickEmit)="handleIconClick($event)"
                ></app-dbc-lead-form>
            </div>
        </div>

        <!--   Separator   -->
        <div class="bx--row" style="background-color: #f4f5f7;">
            <div class="bx--col">
                <div style="height:16px;"></div>
            </div>
        </div>

        <div class="bx--row" style="border:1px solid #E4E5E7;">
            <div class="bx--col">
                <div class="bx--row mt-3">
                    <div class="bx--col cursor-pointer" (click)="collapseSection('labels')" [ngStyle]="{ 'padding-bottom':sections.labels? '  16px' : '0px' }">
                        <span
                            class="font-fs-15 text-left font-weight-600 text-uppercase line-height-15 letter-spacing-1 text-gray">
                          Labels
                        </span>
                        <span class="float-right">
                          <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                             [icon]="sections.labels ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                                             [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                        </span>
                    </div>
                </div>
                <div class="bx--row" *ngIf="!sections.labels" [@collapse]>
                    <div class="bx--col">
                        <hr class="horizontal-divider"/>
                    </div>
                </div>

                <div class="bx--col-sm-4 pl-0 mt-3 mb-4" *ngIf="!sections.labels" [@collapse]>
                    <div class="mt-2 d-flex labels-container" style="column-gap: 8px; margin-bottom: 24px;">
                        <div class="flex-wrap d-flex" *ngIf="this.product?.tags_data.length">
                            <div *ngFor="let tag of this.product?.tags_data" [ngStyle]="{'background-color' : tag.color}" class="px-2 mb-2 mr-2 border-radius-2px" style="color: #FAFAFA; height: 24px;" >{{tag.name}}</div>
                        </div>
                        <div *ngIf="!this.product?.tags_data.length" class="none-label-text">None</div>
                    </div>
                    <beaconstac-button
                        [type]="BUTTON_TYPES.PRIMARY"
                        [style]="BUTTON_STYLES.TEXT"
                        [label]="'Manage Labels'"
                        [icon]="'fas fa-tag'"
                        (click)="openTagModal()"
                    ></beaconstac-button>
                </div>
            </div>
        </div>

        <!--   Separator   -->
        <div class="bx--row" style="background-color: #f4f5f7;">
            <div class="bx--col">
                <div style="height:16px;"></div>
            </div>
        </div>

        <div class="bx--row" style="border:1px solid #E4E5E7;">
            <div class="bx--col">
                <div class="bx--row mt-3">
                    <div class="bx--col cursor-pointer" (click)="collapseSection('advancedSettings')" [ngStyle]="{ 'padding-bottom':sections.advancedSettings? '  16px' : '0px' }">
                        <span
                            class="font-fs-15 text-left font-weight-600 text-uppercase line-height-15 letter-spacing-1 text-gray">
                          Advanced settings
                        </span>
                        <span class="float-right">
                          <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                             [icon]="sections.advancedSettings ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                                             [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                        </span>
                    </div>
                </div>
                <div class="bx--row" *ngIf="!sections.advancedSettings" [@collapse]>
                    <div class="bx--col">
                        <hr class="horizontal-divider"/>
                    </div>
                </div>
                <div *ngIf="!sections.advancedSettings" [@collapse]>

                    <div class="bx--row mt-3">
                        <div class="d-flex ml-3">
                            <beaconstac-toggle
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2"
                                [checked]="orgCardSetting.autodownload_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                           orgCardSetting.autodownload_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : product.autodownload_v2"
                                (checkedChange)="handleAutoDownloadToggle($event)">
                            >
                            </beaconstac-toggle>
                            <div style="margin-top:-10px; margin-left: 12px; line-height: 1.2 !important;">
                                <span class="font-weight-400 mr-2" style="font-size:15px; color:#16212F; line-height: 24px;">Download on scan</span>
                                <span [ngClass]="cardSetting.autodownload | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2)"></span>
                                <br>
                                <span class="font-weight-400;" style="color:#5E6980; font-size:13px; line-height: 15.25px;">Directly download contact on scan</span>
                            </div>
                        </div>

                    </div>

                    <div class="bx--row" style="margin-top:30px;">
                        <div class="d-flex ml-3">
                            <beaconstac-toggle
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2"
                                [checked]="orgCardSetting.location_enabled_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                           orgCardSetting.location_enabled_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : product.location_enabled"
                                (checkedChange)="product.location_enabled = $event; onVCardContentChange()">
                                >
                            </beaconstac-toggle>
                            <div style="margin-top:-10px; margin-left: 12px; line-height: 1.2 !important;">
                                <span class="font-weight-400 mr-2" style="font-size:15px; color:#16212F; line-height: 24px;">GPS Location</span>
                                <span [ngClass]="cardSetting.location_enabled | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2)"></span>
                                <br>
                                <span class="font-weight-400;" style="color:#5E6980; font-size:13px; line-height: 15.25px;">Track location of viewers</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM]" class="bx--row" style="margin-top:30px;">
                        <div class="d-flex ml-3">
                            <beaconstac-toggle
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2"
                                [checked]="orgCardSetting.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                           orgCardSetting.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : product.lead_collection"
                                (checkedChange)="toggleLeadCollection($event)">
                                >
                            </beaconstac-toggle>
                            <div style="margin-top:-10px; margin-left: 12px; line-height: 1.2 !important;">
                                <span class="font-weight-400 mr-2" style="font-size:15px; color:#16212F; line-height: 24px;">Two way contact sharing</span>
                                <span [ngClass]="cardSetting.lead_collection | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2)"></span>
                                <br>
                                <span class="font-weight-400;" style="color:#5E6980; font-size:13px; line-height: 15.25px;">Collect contact information from potential leads</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM] && (product.lead_collection || orgCardSetting.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES)" [@collapse] class="bx--col-lg-8 mt-4 px-0" style="background-color: #F4F5F7; border-radius: 3px; max-width: 100%;">
                        <div class="pt-2 pb-2">
                            <span class="font-13 line-height-15 pl-3 font-normal font-weight-400 letter-spacing-q text-custom-gray">
                                Select what data you want to collect
                            </span>
                        </div>
                        <div *ngFor="let leadSetting of leadSettings" class="d-flex mt-1" style="margin-left:16px;">
                            <beaconstac-checkbox
                            [label]="leadSetting.label"
                            [disabled]="leadSetting.disable" [checked]="leadSetting.default || product.lead_attribute[leadSetting.name]" (checkedChange)="toggleLeadSetting($event, leadSetting)"
                          ></beaconstac-checkbox>
                        </div>
                        <div class="pb-2"></div>
                    </div>


                    <div class="bx--row" style="margin-top:30px; margin-bottom:24px;">
                        <div class="d-flex ml-3">
                            <beaconstac-toggle
                                [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED"
                                [checked]="orgCardSetting.ip_location_enabled === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                           orgCardSetting.ip_location_enabled === DBC_ORG_PERMISSION_OPTIONS.YES ? true : product.ip_location_enabled"
                                (checkedChange)="product.ip_location_enabled = $event; onVCardContentChange()">
                                >
                            </beaconstac-toggle>
                            <div style="margin-top:-10px; margin-left: 12px; line-height: 1.2 !important;">
                                <span class="font-weight-400 mr-2" style="font-size:15px; color:#16212F; line-height: 24px;">Track IP Location</span>
                                <span [ngClass]="cardSetting.ip_location_enabled | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED)"></span>
                                <br>
                                <span class="font-weight-400;" style="color:#5E6980; font-size:13px; line-height: 4px;">Analyze scan locations using the device's IP address</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="bx--row" style="background-color: #f4f5f7;">
            <div class="bx--col">
                <div style="height:16px;"></div>
            </div>
        </div>

        <div class="bx--row" style="border: 1px solid #E4E5E7;">
            <div class="bx--col">
                <div class="bx--row mt-3">
                    <div class="bx--col cursor-pointer" (click)="collapseSection('qrCodeTemplates')" [ngStyle]="{ 'padding-bottom':sections.qrCodeTemplates? '12px' : '0px' }">
                        <span
                            class="font-fs-15 text-left font-weight-600 text-uppercase line-height-15 letter-spacing-1 text-gray">
                          QR CODE TEMPLATES
                            <span [ngClass]="cardSetting.template | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.TEMPLATE: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.TEMPLATE);$event.stopPropagation()"></span>
                        </span>

                        <span class="float-right">
                          <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="''"
                                             [icon]="sections.qrCodeTemplates? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                                             [style]="BUTTON_STYLES.TEXT"></beaconstac-button>
                        </span>
                    </div>
                </div>
                <div class="bx--row" *ngIf="!sections.qrCodeTemplates" [@collapse]>
                    <div class="bx--col">
                        <span class="font-13 line-height-15 font-normal font-weight-400 letter-spacing-q text-custom-gray">
                            Choose a QR code for sharing your card
                        </span>
                    </div>
                </div>
                <div class="bx--row" *ngIf="!sections.qrCodeTemplates" [@collapse]>
                    <div class="bx--col">
                        <hr class="horizontal-divider"/>
                    </div>
                </div>
                <div class="bx--row mt-3 overflow-hidden" style="margin-bottom:24px;" *ngIf="!sections.qrCodeTemplates" [@collapse]>
                    <div class="bx--row d-flex mx-0" style="max-width: 100%; padding-right:16px; padding-left:16px;">
                        <!-- left scroller -->
                        <div class="d-none cursor-pointer align-items-center justify-content-center template-horizontal-scroll" (click)="scrollTemplate('scroll-left-template')" id="scroll-left-template">
                          <a class="float d-flex flex-wrap justify-content-center align-content-center"><i class="fas fa-solid fa-caret-left text-primary"></i></a>
                        </div>

                        <div class="bx--col px-0 w-100 d-flex" infiniteScroll [infiniteScrollDistance]="2"
                          [infiniteScrollThrottle]="50" [horizontal]="true" (scrolled)="onTemplateScroll()"
                          (scroll)="hideScroller()"
                          [scrollWindow]="false" style="overflow: auto; white-space: nowrap; scroll-behavior: smooth;" id="template-list">
                          <div *ngFor="let item of Templates; let i = index" class="template-container m-1"
                            [ngClass]="selectedTemplate === item.id ? 'selected-option': ''"
                            [ngStyle]="(cardSetting.template && (cardType !== DBCCardTypes.TEMPLATE_CARDS)) ? {cursor: 'not-allowed'} : {cursor: 'pointer'}"
                            (click)="hasWriteAccess ? onSelectTemplate(item) : false"
                            [attr.id] = "'template-' + i">
                            <div class="template p3 m-2">
                              <img data-id="{{ item.id }}" [ngClass]="product.template?.id === item.id ? 'selected' : ''"
                              [src]="item.thumbnailUrl + '?' + timestamp" height="120px" width="120px">
                              <div *ngIf="product.template?.id === item.id" class="remove-template place-delete bold-on-hover"><i
                                class="fas fa-trash-alt"> </i> REMOVE
                              </div>
                            </div>
                            <div class="template-info text-center">
                              <div class="w-100 single-line-text">{{item.name}}</div>
                              <div class="small text-muted w-100 single-line-text"
                                *ngIf="item.meta['creator_email'] && user?.organization.feature_permissions.reseller_access"
                                [title]="'Created by:' + item.meta['creator_email']">
                                {{item.meta['creator_email']}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="isFetchingTemplates" class="d-flex align-items-center justify-content-center" style="width: 30px;">
                          <div class="spinner-border spinner-border-sm text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                        <!-- right scroller -->
                        <div class="align-items-center cursor-pointer justify-content-center template-horizontal-scroll" (click)="scrollTemplate('scroll-right-template')" id="scroll-right-template" [ngStyle]="{ 'display' : Templates?.length <= 4 ? 'none': 'flex' }">
                          <a class="float d-flex flex-wrap justify-content-center align-content-center"><i class="fas fa-solid fa-caret-right text-primary"></i></a>
                        </div>
                      </div>
                </div>
            </div>
        </div>

    </div>
</div>

<beaconstac-product-label #tagUntagModal [productType]="'cards'" [tagList]="tagList" [currentAppliedTags]="product.tags"
                          [operationMethod]="3" (selectedLabels)="attachSelectedLabels($event)">
</beaconstac-product-label>
