<beaconstac-title
    [title]="'Business Card Templates'"
    [subtitle]="'Create and manage your business card templates here'"
    [primaryAction]="true"
    [primaryActionTitle]="'New Template'"
    [primaryActionIcon]="'fas fa-plus'"
    [primaryActionButtonType]="BUTTON_TYPES.PRIMARY"
    (onClickPrimaryAction)="addNewTempalte()"
></beaconstac-title>
<div class="bg-white bx--col">
    <div class="mt-4 bx--row">
            <app-dbc-designs-grid-view class="bx--col-max-16" [type]="DBC_DESIGN_TYPE.TEMPLATES" *ngIf="!isMobileScreen"
                                    [digitalBusinessCardTemplates]="digitalBusinessCardTemplates"
                                    (selectedTemplate)="editTemplate($event)"
                                    (cardOperation)="handleCardOperation($event)"
                                    [cardType]="cardType"
            ></app-dbc-designs-grid-view>

            <app-dbc-designs-grid-view class="bx--col-max-16 p-0" [type]="DBC_DESIGN_TYPE.TEMPLATES" *ngIf="isMobileScreen"
                                    [digitalBusinessCardTemplates]="digitalBusinessCardTemplates"
                                    (selectedTemplate)="editTemplate($event)"
                                    (cardOperation)="handleCardOperation($event)"
                                    [cardType]="cardType"
            ></app-dbc-designs-grid-view>
    </div>
    <div class="bx--row mb-4 bg-white" *ngIf="digitalBusinessCardTemplates?.length !== 0 && !isMobileScreen">
        <div class="bx--col pagination-footer">
            <beaconstac-pagination
                [pageCount]="currentPage"
                [totalResults]="totalResults"
                [pageSize]="12"
                (onPageChange)="onPageChange($event)"
            ></beaconstac-pagination>
        </div>
    </div>
</div>

<div bsModal #renameCardTemplateModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div *ngIf="selectedCardTempalte" class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body p-4">
                <h4 class="modal-heading mb-2">{{currentCardOperation === 'rename' ? 'Rename template' : 'Enter template name'}}</h4>

                <beaconstac-textfield
                    [label]="true"
                    [title]="'Name'"
                    [(text)]="newTemplateName"
                    [placeholder]="'Name'"
                    (textChange)="renameErrorMessage='';"
                    [errorMessage]="newTemplateName?.length > 255 ? 'Name cannot be more than 255 characters' : renameErrorMessage"
                    [valid]="renameErrorMessage === '' ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR"
                    [maxWidth]="'webkit-fill-available'"
                ></beaconstac-textfield>

            </div>
            <div class="modal-footer pt-0 px-4 pb-4 border-0">
        <span>
          <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                             (onClick)="renameCardTemplateModal.hide();"
                             [label]="'Cancel'"
          ></beaconstac-button>
        </span>

                <span class="ml-3">
          <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Save'"
                             (onClick)="onTemplateNameSave();"
                             [disabled]="selectedCardTempalte.name?.length === 0 || !!renameErrorMessage"
                             [style]="BUTTON_STYLES.FILLED"
          ></beaconstac-button>
        </span>
            </div>
        </div>
    </div>
</div>

<div bsModal #createNewTempalteWarningModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div *ngIf="selectedCardTempalte" class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body p-4">
                <h4 class="modal-heading mb-2">Template in use</h4>
                <span class="modal-desc" >{{selectedCardTempalte?.meta?.dbc_count}} cards are linked to this template. Before deleting this template create and assign a new one to your cards.</span>

            </div>
            <div class="modal-footer pt-0 px-4 pb-4 border-0">
        <span>
          <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                             (onClick)="createNewTempalteWarningModal.hide();"
                             [label]="'Cancel'"
          ></beaconstac-button>
        </span>

                <span class="ml-3">
          <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Create new template'"
                             (onClick)="addNewTempalte()"
                             [disabled]="selectedCardTempalte.name?.length === 0"
                             [style]="BUTTON_STYLES.FILLED"
          ></beaconstac-button>
        </span>
            </div>
        </div>
    </div>
</div>

<div bsModal #selectReplacementTemplateModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div *ngIf="selectedCardTempalte" class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body p-4 d-flex flex-column">
                <h4 class="modal-heading">Delete this template?</h4>
                <span class="modal-desc mt-3">{{selectedCardTempalte.meta['dbc_count']}} Business cards are linked to this template. Assign a different template before we delete this one permanently.</span>

                <div *ngIf="selectedCardTempalte.meta['is_ad_integration_template']" class="bx--col d-flex mt-3" style="background: #FFF0C8; flex-direction: row; margin-bottom: 26px;">
                    <i class="fas fa-info-circle pr-2" style="color: #FCBF49; font-size: 20px; padding-top: 18px;"></i>
                    <span class="py-3 pb-2">This template is currently being used for card creation via Microsoft Entra ID.</span>
                </div>

                <div class="mt-3 mb-4 pb-1">
                    <app-digital-business-card-templates-dropdown
                        [labelText]="'Assign different template'"
                        [showHeading]="false"
                        [width]="'100%'"
                        (onTemplateSelectChange)="selectReplaceTempalte($event)"
                        [showNoneOption]="false"
                    ></app-digital-business-card-templates-dropdown>
                </div>
            </div>

            <div class="modal-footer pt-0 px-4 pb-4 border-0">
                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="selectReplacementTemplateModal.hide();"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>

                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.DANGER" [label]="'Confirm & delete'"
                                     (onClick)="confirmDeleteTemplate(selectedCardTempalte.id, replacementTemplate_id)"
                                     [disabled]="replacementTemplate_id === null || replacementTemplate_id === selectedCardTempalte.id"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>
        </div>
    </div>
</div>
