import { environment } from 'environments/environment';

const BASE_URL = environment.baseURL;

export const layoutsDefaultData = {
    '1': {
        user_image_url: `${BASE_URL}/static/images/dbc/max.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/38025/f3e3a2887c2d4f8fb36cea3578bcc320?v=1719468051.85683',
        first_name: 'Max',
        last_name: 'McGonagall',
        pronouns_v2: 'he/him',
        designation: 'Automotive Engineer',
        company: 'Aryam Motors',
        summary: 'I handle production and maintenance of our new line of electric cars here at Aryam Motors.',
        email_v2 : [
            {
                label: 'Personal',
                valid: 'valid',
                value: 'max.m@briskmotors.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Personal',
                'valid': 'valid',
                'value': '91139 74687'
            }
        ],
        website_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': 'www.briskmotors.com'
            }
        ],
        social_links: {
            facebook: 'https://www.facebook.com/',
            instagram: 'https://www.instagram.com/',
            twitter: 'https://x.com/?lang=en',
            linkedin: 'https://www.linkedin.com',
        },
        logo_size: 90
    },
    '2': {
        user_image_url: `${BASE_URL}/static/images/dbc/hermoine.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/38025/91697eb396b4493cadf3789d87693df8',
        first_name: 'Hermoine',
        last_name: 'Granger',
        pronouns_v2: 'she/her',
        designation: 'Paralegal',
        company: 'Murdoch & Associates',
        summary: '',
        email_v2 : [
            {
                label: 'Personal',
                valid: 'valid',
                value: 'hermoine@murdoch.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Personal',
                'valid': 'valid',
                'value': '91139 74687'
            }
        ],
        website_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': 'www.murdoch.com'
            }
        ],
        social_links: {
            facebook: 'https://www.facebook.com/',
            instagram: 'https://www.instagram.com/',
            twitter: 'https://x.com/?lang=en',
            linkedin: 'https://www.linkedin.com',
        },
        address_v2: 'Murdoch & Associates Office, East MR Road, Ohio, USA',
        logo_size: 60
    },
    '3': {
        user_image_url: `${BASE_URL}/static/images/dbc/janet.jpeg`,
        logo_url: '',
        first_name: 'Janet',
        last_name: 'McGee',
        pronouns_v2: '',
        designation: 'Founder, CEO',
        company: 'McGee Security Corp.',
        summary: 'Hi! I head one of the best cybersecurity firms in the US. Get in touch.',
        email_v2 : [
            {
                label: 'Personal',
                valid: 'valid',
                value: 'janet@mcgee.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Mobile',
                'valid': 'valid',
                'value': '+1 12358 1321'
            },
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '+1 43442 83041'
            }
        ],
        website_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': 'https://mcgee.com'
            }
        ],
        social_links: {
            facebook: 'https://www.facebook.com/',
            instagram: 'https://www.instagram.com/',
            twitter: 'https://x.com/?lang=en',
            linkedin: 'https://www.linkedin.com',
        }
    },
    '4': {
        user_image_url: `${BASE_URL}/static/images/dbc/ashley.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/880f4af73be04f2a8f48270d7998f02f?v=1719433098.232262',
        prefix: 'Dr.',
        first_name: 'Ashley James',
        last_name: 'Fletcher',
        pronouns_v2: 'she/her',
        designation: 'CXO, Founding Designer',
        company: 'Schoen & Co.',
        summary: 'I lead design across teams for Schoen & Co. and it\’s subsidiaries.',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'ash@schoenco.design'
            },
            {
                label: 'Personal',
                valid: 'valid',
                value: 'ashleyrocks@gmail.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '+1 91139 74687'
            }
        ],
        address_v2: '301, Peter’s Cote, 3rd Avenue St., Sunnyville, California',
        social_links : {
            'facebook': 'https://www.facebook.com/username',
            'instagram': 'https://www.instagram.com/username',
            'twitter': 'http://twitter.com/username',
            'whatsapp': '23423423'
        }
    },
    '5': {
        user_image_url: `${BASE_URL}/static/images/dbc/james.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/c66faea4cdd345199e411d3cd4376f09',
        first_name: 'James',
        last_name: 'McFinnigan',
        pronouns_v2: 'he/him',
        designation: 'Product Lead',
        company: 'Eurus Sports',
        summary: 'I lead product across teams for Eurus and I ski in my free time. I love to work on AR/VR and have created product strategies for the same.\n\nHit me up for a drink!',
        phone_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '+1 91139 74687'
            }
        ],
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'james@eurus.design'
            },
        ],
        address_v2: '301, Peter’s Cote, 3rd Avenue St., Sunnyville, California',
        social_links : {
            'facebook': 'https://www.facebook.com/username',
            'instagram': 'https://www.instagram.com/username',
            'twitter': 'http://twitter.com/username',
            'whatsapp': '23423423'
        }
    },
    '6': {
        user_image_url: `${BASE_URL}/static/images/dbc/joe.png`,
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/1314632af1fe443a8528e8ffabde5dbf',
        first_name: 'Joe',
        last_name: 'Tribianni',
        pronouns_v2: 'he/him',
        designation: 'CTO',
        company: 'AIB Global Business Services',
        summary: 'I lead product across teams for AIB and I ski in my free time.',
        phone_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '+1 91139 74687'
            }
        ],
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'joe@aibglobal.com'
            },
        ],
        address_v2: '301, Peter’s Cote, 3rd Avenue St., Sunnyville, California',
        social_links : {
            'facebook': 'https://www.facebook.com/username',
            'instagram': 'https://www.instagram.com/username',
            'twitter': 'http://twitter.com/username',
            'whatsapp': '23423423'
        }
    },
    '7': {
        user_image_url: `${BASE_URL}static/images/dbc/profile-img/layout-7-profile.jpg`,
        prefix: 'Dr.',
        first_name: 'Saul',
        last_name: 'Mcmillan',
        pronouns_v2: 'he/him',
        designation: 'Family Physician',
        company: 'Stanford Health',
        summary: 'I aim to provide personalized healthcare for all my patients, fostering relationships built on trust and expertise.',
        phone_v2: [{
            label: 'Work',
            value: '+91 12345 4321',
            valid: true
        }],
        email_v2: [{
            label: 'Personal',
            value: 'dr.saulmcmillan@gmail.com',
            valid: true
        }],
        website_v2: [{
            label: 'Work',
            value: 'www.salford.com',
            valid: true
        }],
        address_v2: '7701 Chris Allee 5C, Niedersachsen',
        social_links: {
            facebook: 'https://www.facebook.com/username',
            twitter: 'https://www.x.com/username',
            instagram: 'https://www.instagram.com/username',
            linkedin: 'https://www.linkedin.com'
        },
        logo_url: `${environment.imagesEndpoint}/dbc/logos/layout_7_logo.svg`,
        logo_size: 90
    },
    '8' : {
        first_name: 'Katy',
        last_name: 'Hartman',
        prefix: '',
        designation: 'UX Researcher',
        pronoun: 'she/her',
        company: 'Onama Tech LLC',
        'department': 'Research Department',
        summary: 'I work on enhancing user experiences. When I\'m not delving into user insights, you\'ll find me exploring hiking trails.',
        email_v2: [{
            label: 'Work',
            value: 'k.hartman@onama.com',
            valid: true
        }],
        custom_fields: [{
            label: 'Employee ID',
            value: '00001234-56',
            valid: true
        }],
        address_v2: '922 East Portertown, West Virginia 82571',
        social_links: {
            facebook: 'https://www.facebook.com/username',
            twitter: 'https://www.x.com/username',
            instagram: 'https://www.instagram.com/username',
            linkedin: 'https://www.linkedin.com'
        },
        lead_collection: true,
        logo_url: `${environment.imagesEndpoint}/dbc/logos/layout_8_logo.svg`,
        logo_size: 60
    },
    '9': {
        user_image_url: '',
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/24534/fa838a2bbb5e4928b01e19152752adfd?v=1719294908.418258',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'm.scott@harzone.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Mobile',
                'valid': 'valid',
                'value': '(406) 555-0120'
            }
        ],
        website_v2: [
            {
                'label': 'Company',
                'valid': 'valid',
                'value': 'www.harzone.com'
            }
        ],
        social_links : {
            'facebook': 'https://www.facebook.com/username',
            'instagram': 'https://www.instagram.com/username',
            'twitter': 'http://twitter.com/username',
            'whatsapp': '23423423'
        },
        prefix: '',
        first_name: 'Michael',
        last_name: 'Scott',
        pronouns_v2: 'he/him',
        designation: 'Regional Manager',
        company: 'Harzone Logistics Inc.',
        department: 'Scranton branch',
        summary: 'I work on optimizing supply chain operations at Harzone. Outside of work, I enjoy cycling and cooking gourmet meals.'
    },
    '11': {
        user_image_url: '',
        logo_url: 'https://s3.amazonaws.com/beaconstac-content-qa/38025/67b9b86e8f2a419c93deb3642f914d7f?v=1719434970.14374',
        email_v2 : [
            {
                label: 'Work',
                valid: 'valid',
                value: 'darell.b@anakin.com'
            }
        ],
        phone_v2: [
            {
                'label': 'Work',
                'valid': 'valid',
                'value': '+91 99900 0887'
            }
        ],
        website_v2: [
            {
                'label': 'company',
                'valid': 'valid',
                'value': 'www.anakinpedagogic.com'
            }
        ],
        first_name: 'Darell',
        last_name: 'Blanchard',
        pronouns_v2: 'they/them',
        designation: 'Education Director,',
        company: 'Anakin Pedagogical Institute',
        summary: 'Passionate about shaping future educators and innovative teaching methods, I enjoy reading and gardening in my free time.',
        social_links: {
            facebook: 'https://www.facebook.com/',
            instagram: 'https://www.instagram.com/',
            twitter: 'https://x.com/?lang=en',
            linkedin: 'https://www.linkedin.com',
        },
        address_v2: '104 Pine Avenue Springfield, SP 56789 United States',
    },
}


export const typographyLayouts = {
    '1': {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Semi Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Medium',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    },
    '2': {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Semi Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Medium',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    },
    '3': {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Medium',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Light',
        },
        contact_details: {
            google_font_style: 'Regular',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    },
    '4': {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Semi Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Semi Bold',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    },
    '5': {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Semi Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Semi Bold',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    },
    '6': {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Semi Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Semi Bold',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    },
    '9': {
        font_type: 'google',
        font_family: 'Open Sans',
        personal_info: {
            google_font_style: 'Semi Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Semi Bold',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    },
    '11': {
        font_type: 'google',
        font_family: 'Atkinson Hyperlegible',
        personal_info: {
            google_font_style: 'Bold',
            google_font_size: 24,
        },
        company_details: {
            google_font_style: 'Regular',
        },
        contact_details: {
            google_font_style: 'Regular',
        },
        button: {
            google_font_style: 'Regular',
        },
        bio : {
            google_font_style: 'Regular',
        },
    }
}

// Template mapping with their names
export const TEMPLATE_NAMES_MAP = {
    1: 'Brisk_Motors',
    2: 'Murdoch_Associates',
    3: 'McGee_Security_Corp',
    4: 'Schoen_&_Co.',
    5: 'Eurus_Sports',
    6: 'AIB_Global',
    7: 'Salford_Health',
    8: 'Onama_Tech',
    9: 'Harzone',
    10: 'Cinco',
    11: 'Anakin'
}
