import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DbcRenewComponentComponent } from './dbc-renew-component.component';


import { ModalModule } from 'ngx-bootstrap/modal';
import { LowerCaseTextInputModule } from '../../shared/lower-case-text-input/lower-case-text-input.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BeaconstacModule } from 'kaizen-design-system';
import { PipesModule } from '../pipes/pipes.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';



@NgModule({
    declarations: [
        DbcRenewComponentComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        LowerCaseTextInputModule,
        BeaconstacModule,
        PipesModule,
        CollapseModule.forRoot(),
    ],
    exports: [
        DbcRenewComponentComponent
    ],
})

export class DbcRenewComponentModule { }
