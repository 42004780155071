<ng-container *ngIf="designType === DBC_DESIGN_TYPE.LAYOUTS">
  <beaconstac-title
    [title]="trialSignUp ? 'Create your first card' : 'Choose a card design'"
    [subtitle]="trialSignUp ? 'Choose from Uniqode card designs' : 'Create a card with Uniqode designs'"
    [mobileSubtitle]="true"
  ></beaconstac-title>
</ng-container>

<ng-container *ngIf="designType === DBC_DESIGN_TYPE.TEMPLATES" style="padding-left: 1rem">
  <beaconstac-title
    [title]="'Select a Template'"
    [subtitle]="'Create consistent business cards with ease'"
    [mobileSubtitle]="true"
  ></beaconstac-title>
</ng-container>

<div class="bx--col bg-white mt-4">
    <div class="bx--row" [ngClass]="designType === DBC_DESIGN_TYPE.LAYOUTS ? 'mb-4': 'mb-0'">
              <app-dbc-designs-grid-view class="bx--col-max-16 " *ngIf="designType === DBC_DESIGN_TYPE.TEMPLATES && isMobileScreen" style="padding-left: 0px; background-color: #f4f5f7"
                                    [type]="DBC_DESIGN_TYPE.TEMPLATES"
                                    [digitalBusinessCardTemplates]="digitalBusinessCardTemplates"
                                    (selectedTemplate)="selectDesign($event, DBC_DESIGN_TYPE.TEMPLATES)"
                                    [cardType]="cardType"
                                    [orgCardSettings]="orgCardSettings"
              ></app-dbc-designs-grid-view>
           
            <app-dbc-designs-grid-view class="bx--col-max-16 " *ngIf="designType === DBC_DESIGN_TYPE.TEMPLATES && !isMobileScreen" 
                                    [type]="DBC_DESIGN_TYPE.TEMPLATES"
                                    [digitalBusinessCardTemplates]="digitalBusinessCardTemplates"
                                    (selectedTemplate)="selectDesign($event, DBC_DESIGN_TYPE.TEMPLATES)"
                                    [cardType]="cardType"
                                    [orgCardSettings]="orgCardSettings"
            ></app-dbc-designs-grid-view>
            <app-dbc-designs-grid-view class="bx--col-max-16 p-0" *ngIf="designType === DBC_DESIGN_TYPE.LAYOUTS"
                                    [type]="DBC_DESIGN_TYPE.LAYOUTS"
                                    [hasLayoutsAccess]="!orgCardSettings.layout"
                                    (selectedLayout)="selectDesign($event, DBC_DESIGN_TYPE.LAYOUTS)"
                                    [cardType]="cardType"
            ></app-dbc-designs-grid-view>
    </div>
    <div class="bx--row mb-4 bg-white" *ngIf="designType === DBC_DESIGN_TYPE.TEMPLATES && !isMobileScreen">
        <div class="bx--col pagination-footer">
            <beaconstac-pagination
                [pageCount]="currentPage"
                [totalResults]="totalResults"
                [pageSize]="12"
                (onPageChange)="onPageChange($event)"
            ></beaconstac-pagination>
        </div>
    </div>
</div>
