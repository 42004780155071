<div [ngSwitch]="selectedStep" class="bx--row">
    <div *ngSwitchCase="BULK_DBC_STEPPER.UPLOAD" class="bx--col" [@collapse]>
        <div class="bx--row mx-0">
            <div class="bx--col bg-white">
                <div class="mt-4 px-2 mb-3">
                    <p class="mb-2 font-weight-400" style="font-size: 20px;">Upload business card details</p>
                    <div class="mt-4 mb-4 d-flex flex-column bulk-dbc-container">
                        <span class="bulk-message d-flex flex-row mb-4">
                            <i class="fas fa-info-circle" style="padding-top: 2px;"></i>
                            <span class="pl-2">We recommend using our data templates for accurate results.</span>
                        </span>
                        <div class="d-flex flex-sm-row flex-column mb-4" style="padding-left: 46px;">
                            <beaconstac-button class="mr-2 pr-4"
                                [label]="'CSV template'"
                                [type]="BUTTON_TYPES.PRIMARY"
                                [style]="BUTTON_STYLES.STROKE"
                                (onClick)="downloadTemplates($event, 1)"
                                [icon]="'fas fa-download'"
                                [iconPosition]="ICON_POSITION.LEFT"
                            ></beaconstac-button>
                            <beaconstac-button class="pt-3 pt-sm-0"
                                [label]="'XLSX template'"
                                [type]="BUTTON_TYPES.PRIMARY"
                                [style]="BUTTON_STYLES.STROKE"
                                (onClick)="downloadTemplates($event, 2)"
                                [icon]="'fas fa-download'"
                                [iconPosition]="ICON_POSITION.LEFT"
                            ></beaconstac-button>
                        </div>
                    </div>
                </div>
                <div *ngIf="invalidFileError !== ''" class="mt-3 mb-4 bulkqr-csv-message bulkqr-csv-message-danger"
                     style="max-width: 400px">
                    <b style="color: #dc3444"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                        {{invalidFileError}}</b>
                    <br>
                </div>
                <p class="mb-2 px-2 upload-headline">Upload your data</p>
                <div class="mb-4 custom-upload pl-2">
                    <beaconstac-upload #csvDrop (fileChange)="onDataFileAdded($event)"
                                       [acceptTypes]="['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
                                       [maxSize]="5e+6" [custom]="'Supported extensions: .csv, .xlsx.'"
                                       [disabled]="!hasWriteAccess" (onFileRemove)="resetFilePicker()">
                    </beaconstac-upload>
                </div>
                <div class="my-4 pl-2" *ngIf="errorRows.length > 0">
                    <app-bulk-csv-validation (dataChange)="onCSVChanged($event)" [csvData]="csvData"
                                             [errorObject]="csvValidationResponse"></app-bulk-csv-validation>
                    <div class="mt-4">
                        <beaconstac-button
                            [type]="BUTTON_TYPES.PRIMARY"
                            [style]="BUTTON_STYLES.FILLED"
                            [label]="'REVALIDATE'"
                            [icon]="'fas fa-sync-alt'"
                            (onClick)="validateCSV()"></beaconstac-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="BULK_DBC_STEPPER.TEMPLATE_SELECT" class="bx--col" [@collapse]>
        <app-cards-multi-org-bulk-setup
            [organizationsList]="organizationsList"
            [(orgTemplateMap)]="orgTemplateMap"
        ></app-cards-multi-org-bulk-setup>
    </div>
</div>

<div class="bx--row mt-4">
    <div class="bx--col">
        <beaconstac-button
            [style]="BUTTON_STYLES.FILLED"
            [type]="BUTTON_TYPES.PRIMARY"
            [label]="selectedStep === BULK_DBC_STEPPER.UPLOAD ? 'Next': 'Finish'"
            (onClick)="goToNext(this.selectedStep + 1)"
            [id]="'next-btn'"
        ></beaconstac-button>
    </div>
</div>

<div bsModal #generatingBatchModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body p-4">
                <h4 class="modal-title">Processing bulk request...</h4>
                <div class="mt-3" style="font-size: 15px; color: #5E6980;">
                    <p class="mt-1 mb-0">Sit back and relax! We'll send a confirmation email once the business cards are ready.
                    </p>
                </div>
            </div>
            <div class="modal-footer pb-4 px-4 pt-3 d-flex justify-content-end" style="border: none;">
                <beaconstac-button
                    [type]="BUTTON_TYPES.PRIMARY"
                    [label]="'Close'"
                    [style]="BUTTON_STYLES.FILLED"
                    (onClick)="finishBulkUpload()"
                ></beaconstac-button>
            </div>
        </div>
    </div>
</div>

<div bsModal #noTemplatesWarnModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">

                <h4 class="create-card-heading">
                    Template unavailable
                </h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    Some organizations do not have a template created for cards. Template is required to proceed with bulk upload.
                </div>

            </div>
            <div class="modal-footer border-0" style="padding: 24px;">

                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="noTemplatesWarnModal.hide()"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.DANGER" [label]="'Create'"
                                     (onClick)="createNewTemplate(true)"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>

        </div>
    </div>
</div>
