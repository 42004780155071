
const customDomainSuccessStatuses = ['PENDING_VALIDATION', 'SUCCESS'];


const getDomainValidationOption = (ssl, product, cloudfront) => {
    if (cloudfront){
        return ssl[`${product}_ssl_certificate_description`];
    }
    return ssl?.ssl_certificate_description?.['Certificate']?.['DomainValidationOptions']?.[0];
}

export class CustomDomain {
    id: number;
    name: string = '';
    domain: string = '';
    cname_verified: {
        qr: boolean;
        dbc: boolean;
        linkpage: boolean;
        nfc: boolean;
        geofence: boolean;
    };
    qr_ssl: {
        ssl_certificate_arn: null;
        ssl_certificate_description: {};
        verified: false;
        loadbalancer_arn: null;
        cloudfront_distribution_domain_name: null;
        qr_ssl_certificate_description: {};
        cloudfront: false;
    };
    dbc_ssl: {
        ssl_certificate_arn: null;
        ssl_certificate_description: {};
        verified: false;
        loadbalancer_arn: null;
        cloudfront_distribution_domain_name: null;
        dbc_ssl_certificate_description: {};
        cloudfront: false;
    };
    linkpage_ssl: {
        ssl_certificate_arn: null;
        ssl_certificate_description: {};
        verified: false;
        loadbalancer_arn: null;
        cloudfront_distribution_domain_name: null;
        linkpage_ssl_certificate_description: {};
        cloudfront: false;
    };
    nfc_ssl: {
        ssl_certificate_arn: null;
        ssl_certificate_description: {};
        verified: false;
        loadbalancer_arn: null;
    };
    geofence_ssl: {
        ssl_certificate_arn: null;
        ssl_certificate_description: {};
        verified: false;
        loadbalancer_arn: null;
    };
    created: string;
    updated: string;
    organization: number;

    cname_verification_in_progress = {};
    ssl_request_in_progress = {};
    load_balancer = {
        qr: {
            cname_value: null
        },
        dbc: {
            cname_value: null
        },
        linkpage: {
            cname_value: null
        }
    };
    is_qr_configured: boolean = false;
    is_dbc_configured: boolean = false;
    is_linkpage_configured: boolean = false;

    is_qr_cname_verified: boolean = false;
    is_qr_ssl_verified: boolean = false;
    is_qr_ssl_expired: boolean = false;

    is_dbc_cname_verified: boolean = false;
    is_dbc_ssl_verified: boolean = false;
    is_dbc_ssl_expired: boolean = false;

    is_linkpage_cname_verified: boolean = false;
    is_linkpage_ssl_verified: boolean = false;
    is_linkpage_ssl_expired: boolean = false;

    qr_ssl_host: string = '';
    qr_ssl_value: string = '';
    is_qr_unconfigured: boolean = false;

    dbc_ssl_host: string = '';
    dbc_ssl_value: string = '';
    is_dbc_unconfigured: boolean = false;

    linkpage_ssl_host: string = '';
    linkpage_ssl_value: string = '';
    is_linkpage_unconfigured: boolean = false;

    constructor(json?) {
        if (!json) {
            return;
        }

        const {
            id,
            name,
            domain,
            cname_verified,
            qr_ssl,
            nfc_ssl,
            geofence_ssl,
            created,
            updated,
            organization,
            load_balancer,
            linkpage_ssl,
            dbc_ssl,
        } = json;

        this.id = id;
        this.name = name;
        this.domain = domain;
        this.cname_verified = cname_verified;
        this.qr_ssl = qr_ssl;
        this.nfc_ssl = nfc_ssl;
        this.geofence_ssl = geofence_ssl;
        this.linkpage_ssl = linkpage_ssl;
        this.dbc_ssl = dbc_ssl;
        this.created = created;
        this.updated = updated;
        this.organization = organization;
        this.load_balancer = load_balancer || null;

        this.is_qr_cname_verified = cname_verified ? cname_verified['qr'] : false;
        this.is_qr_ssl_verified = qr_ssl ? qr_ssl['verified'] : false;

        const qrDomainValidationOptions = getDomainValidationOption(qr_ssl, 'qr', qr_ssl?.cloudfront);
        this.is_qr_ssl_expired = !customDomainSuccessStatuses.includes(qrDomainValidationOptions?.ValidationStatus);

        this.is_dbc_cname_verified = cname_verified ? cname_verified['dbc'] : false;
        this.is_dbc_ssl_verified = dbc_ssl ? dbc_ssl['verified'] : false;

        const dbcDomainValidationOptions = getDomainValidationOption(dbc_ssl, 'dbc', dbc_ssl?.cloudfront);
        this.is_dbc_ssl_expired = !customDomainSuccessStatuses.includes(dbcDomainValidationOptions?.ValidationStatus);

        this.is_linkpage_cname_verified = cname_verified ? cname_verified['linkpage'] : false;
        this.is_linkpage_ssl_verified = linkpage_ssl ? linkpage_ssl['verified'] : false;

        const linkpageDomainValidationOptions = getDomainValidationOption(linkpage_ssl, 'linkpage', linkpage_ssl?.cloudfront);
        this.is_linkpage_ssl_expired = !customDomainSuccessStatuses.includes(linkpageDomainValidationOptions?.ValidationStatus);

        this.is_qr_unconfigured =
            !qr_ssl['loadbalancer_arn'] && !qr_ssl['ssl_certificate_arn'] && !qr_ssl['ssl_certificate_description'];

        this.is_dbc_unconfigured =
            !dbc_ssl['loadbalancer_arn'] && !dbc_ssl['ssl_certificate_arn'] && !dbc_ssl['ssl_certificate_description'];
        this.is_linkpage_unconfigured = linkpage_ssl
            ? !linkpage_ssl['loadbalancer_arn'] &&
              !linkpage_ssl['ssl_certificate_arn'] &&
              !linkpage_ssl['ssl_certificate_description']
            : false;
        if (
            this.is_qr_ssl_verified &&
            qr_ssl.ssl_certificate_description &&
            qr_ssl.ssl_certificate_description['Certificate']
        ) {
            const { Name , Value } = qrDomainValidationOptions?.ResourceRecord || {};
            this.qr_ssl_host = Name || '';
            this.qr_ssl_value = Value || '';
        }

        if (
            this.is_dbc_ssl_verified &&
            dbc_ssl.ssl_certificate_description &&
            dbc_ssl.ssl_certificate_description['Certificate']
        ) {
            const { Name, Value } = dbcDomainValidationOptions?.ResourceRecord || {};
            this.dbc_ssl_host = Name || '';
            this.dbc_ssl_value = Value || '';
        }

        if (
            this.is_linkpage_ssl_verified &&
            linkpage_ssl.ssl_certificate_description &&
            linkpage_ssl.ssl_certificate_description['Certificate']
        ) {
            const { Name, Value } = linkpageDomainValidationOptions?.ResourceRecord || {};
            this.linkpage_ssl_host = Name || '';
            this.linkpage_ssl_value = Value || '';
        }

        this.is_qr_configured = this.is_qr_cname_verified && this.is_qr_ssl_verified;
        this.is_dbc_configured = this.is_dbc_cname_verified && this.is_dbc_ssl_verified;
        this.is_linkpage_configured = this.is_linkpage_cname_verified && this.is_linkpage_ssl_verified;
    }

    isSAN() {
        const sslObjects = [this.qr_ssl, this.nfc_ssl, this.geofence_ssl, this.dbc_ssl, this.linkpage_ssl];
        // @ts-ignore
        return sslObjects.some((obj) => obj?.ssl_certificate_description?.SAN === true);
    }
}
