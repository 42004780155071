  <div class="d-flex justify-content-between flex-wrap">
      <div>
          <div class="font-fs-20 d-flex align-items-center" style="font-weight:600;">
              Design Your Card
          </div>

          <div class="subheading" [ngClass]="isMobileScreenSize ? 'pt-2' : 'pt-1'">
              Select colors and fonts that reflect your brand
          </div>
      </div>

      <div *ngIf="!hasDBCAdvancedCustomization" [ngClass]="isMobileScreenSize ? 'mt-2' : ''">
          <beaconstac-button
              [type]="BUTTON_TYPES.PRIMARY"
              [style]="BUTTON_STYLES.STROKE"
              [label]="'Advanced Customization'"
              [icon]="'fas fa-crown text-warning'"
              [iconPosition]="ICON_POSITION.RIGHT"
              (onClick)="showAdvancedCustomizationUpsell()"
          >
          </beaconstac-button>
      </div>

  </div>


<div class="mt-3" *ngIf="showInfoMessage">
    <beaconstac-message [type]="MESSAGE_TYPES.PRIMARY" [message]="teamSoloInfoMsg" [addContent]="false"></beaconstac-message>
</div>

<div class="mt-3 bg-white box-border" *ngIf="product">
    <div class="d-flex justify-content-between cursor-pointer py-3 px-4" (click)="collapseSection('color')">
              <div class="d-flex align-items-center font-fs-15 text-left font-weight-600 line-height-15 letter-spacing-1 text-gray">COLOR
                  <span [ngClass]="cardSetting.customizations | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.CUSTOMIZATIONS); $event.stopPropagation()"></span>
              </div>
        <div class="float-right">
            <beaconstac-button
                [type]="BUTTON_TYPES.SECONDARY"
                [label]="''"
                [icon]="sections.color ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                [style]="BUTTON_STYLES.TEXT"
            ></beaconstac-button>
        </div>
    </div>

    <hr *ngIf="!sections.color" class="block-border-bottom">

    <div *ngIf="!sections.color" class="px-4 pt-3 pb-4" [@collapse]>
      <div class="bx--row">
          <div class="bx--col-lg-8">
              <beaconstac-color-picker
                  [label]="true"
                  [title]="'Primary color'"
                  [(color)]="product.customizations.background_color"
                  [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                  (colorPickerChange)="onColorPickerChange($event, PRODUCT_CUSTOMIZATION.PrimaryColor)"
                  [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                  [cpPosition]="'bottom'"
                  [id]="'background_color'"
                  [cpWidth]="'100%'"
              ></beaconstac-color-picker>
          </div>
      </div>

      <div class="bx--row" *ngIf="!hasDBCAdvancedCustomization">
          <div class="bx--col-lg-8 pt-3">
              <beaconstac-color-picker
                  [label]="true"
                  [title]="'Profile text color'"
                  [(color)]="product.customizations.user_info_color"
                  [disabled]="
                    hasWriteAccess
                        | disableFieldCheckPipe
                            : cardSetting
                            : orgCardSetting
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                "
                  (colorPickerChange)="onColorPickerChange($event, PRODUCT_CUSTOMIZATION.UserInfoColor)"
                  [cpAlphaChannel]="'disabled'"
                  [cpOutputFormat]="'hex'"
                  [cpPosition]="'bottom'"
                  [id]="'user_info_color'"
                  [cpWidth]="'100%'"
              ></beaconstac-color-picker>
          </div>

          <div class="bx--col-lg-8 pt-3">
              <beaconstac-color-picker
                  [label]="true"
                  [title]="'Secondary text color'"
                  [(color)]="product.customizations.secondary_color"
                  [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                  (colorPickerChange)="onColorPickerChange($event, PRODUCT_CUSTOMIZATION.SecondaryColor)"
                  [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                  [cpPosition]="'bottom'"
                  [id]="'secondary_color'"
                  [cpWidth]="'100%'"
              ></beaconstac-color-picker>
          </div>

      </div>

      <div class="bx--row" *ngIf="hasDBCAdvancedCustomization">
          <div class="bx--col-lg-8 pt-3">
              <beaconstac-color-picker
                  [label]="true"
                  [title]="'Button color'"
                  [(color)]="product.customizations.button_color"
                  [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                  (colorPickerChange)="onColorPickerChange($event, PRODUCT_CUSTOMIZATION.ButtonColor)"
                  [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                  [cpPosition]="'bottom'"
                  [id]="'button_color'"
                  [cpWidth]="'100%'"
              ></beaconstac-color-picker>
          </div>
          <div class="bx--col-lg-8 pt-3">
              <beaconstac-color-picker
                  [label]="true"
                  [title]="'Icon color'"
                  [(color)]="product.customizations.icon_color"
                  [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                  (colorPickerChange)="onColorPickerChange($event, PRODUCT_CUSTOMIZATION.IconColor)"
                  [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                  [cpPosition]="'bottom'"
                  [id]="'icon_color'"
                  [cpWidth]="'100%'"
              ></beaconstac-color-picker>
          </div>
      </div>

      <div *ngIf="hasDBCAdvancedCustomization">
          <div class="card-background fs-13 pt-3">Card background</div>
          <div class="bx--row p-0 mx-1 my-2 fs-13">
              <beaconstac-radio-button
                  [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                  (onClick)="setBackgroundType({type: DBC_BACKGROUND_TYPE.Color})"
                  [options]="[{name: 'Color', state: !dbcBackgroundIsImg, disabled: false}]"
              ></beaconstac-radio-button>

              <beaconstac-radio-button
                  [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                  (onClick)="setBackgroundType({type: DBC_BACKGROUND_TYPE.Image})"
                  [options]="[{name: 'Image', state: dbcBackgroundIsImg, disabled: false}]"
              ></beaconstac-radio-button>
          </div>

          <div *ngIf="!dbcBackgroundIsImg" class="bx--row">
              <div class="bx--col-lg-8 ">
                  <beaconstac-color-picker
                      [(color)]="product.customizations.background.value"
                      [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                      (colorPickerChange)="onBackgroundColorChange($event)"
                      [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                      [cpPosition]="'bottom'"
                      [id]="'dbc_background_color'"
                      [cpWidth]="'100%'"
                  ></beaconstac-color-picker>
              </div>
          </div>


          <div *ngIf="dbcBackgroundIsImg">
              <beaconstac-upload #BgImgDrop
                                 [width]="'webkit-fill-available'"
                                 (fileChange)="onBgImgAdded($event)"
                                 [isUploading]="bgImgUpload.isUploading"
                                 [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                                 [maxSize]="5e+6"
                                 [height]="'auto'"
                                 [preview]="bgImgUpload.mediaUrl"
                                 [overrideClick]="hasWriteAccess"
                                 (onClick)="bgImgSelectorModal.show()"
                                 [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                                 [showRemoveButton]="true"
                                 (onFileRemove)="onBgImgRemoved()">
              </beaconstac-upload>
          </div>
      </div>


    </div>
</div>


<div class="mt-4 bg-white box-border">
    <div class="d-flex justify-content-between cursor-pointer py-3 px-4" (click)="collapseSection('fontStyle')">
        <div class="block-title">
            TYPOGRAPHY
            <span
                [ngClass]="
                    cardSetting.customizations
                        | inputIconPipe
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                            : cardSetting
                            : orgCardSetting
                "
                (click)="handleIconClick(CARD_PROPERTY_TYPES.CUSTOMIZATIONS); $event.stopPropagation()"
            ></span>
        </div>
        <div class="float-right">
            <beaconstac-button
                [disabled]="!hasWriteAccess || cardSetting.customizations"
                [type]="BUTTON_TYPES.SECONDARY"
                [label]="''"
                [icon]="sections.fontStyle ? 'fa fa-angle-down ' : 'fa fa-angle-up'"
                [style]="BUTTON_STYLES.TEXT"
            >
            </beaconstac-button>
        </div>
    </div>

    <hr *ngIf="!sections.fontStyle" class="block-border-bottom">

    <div class="px-4 py-3 d-flex flex-column" *ngIf="!sections.fontStyle" [@collapse]>
        <div class="mb-4">
          <beaconstac-filter-dropdown
            [label]="'Font'"
            [disabled]="
                    hasWriteAccess
                        | disableFieldCheckPipe
                            : cardSetting
                            : orgCardSetting
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                "
            [title]="'font'"
            [placeholder]="'Search font'"
            [value]="{ name: this.product.customizations.typography.font_family, value: this.product.customizations.typography.font_family}"
            [specificOutput]="'name'"
            (valueChange)="setFontFamily($event)"
            [listItems]="fontList"
            [optionLabelField]="'name'"
            [optionValueField]="'value'"
            [width]="'347px'"
            (inputChangeEvent)="changeFontList($event)"
          ></beaconstac-filter-dropdown>
        </div>
        <div class="mb-4" *ngIf="isManageCustomFontVisibleFlag">
          <beaconstac-button
            [type]="BUTTON_TYPES.PRIMARY"
            [label]="'Manage Custom Fonts'"
            [icon]="!notOnEnterprisePlan? '' : 'fas fa-crown text-warning'"
            [iconPosition]="'right'"
            [style]="BUTTON_STYLES.STROKE"
            [width]="isMobileScreenSize ? '100%' : '240px'"
            (onClick)="handleClickForManageCustomFontsModal()"
          >
          </beaconstac-button>
        </div>
        <div *ngIf="hasDBCAdvancedCustomization">
            <span class="text-left" style="font-size:15px; line-height: 24px; color:#5E6980;">
                Customize font styles for card sections
            </span>
        </div>

        <!-- Personal info -->
        <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap font-specifics pt-3">
            <div class="pr-3 font-specifics-child">
              <beaconstac-filter-dropdown
                [label]="'Personal info'"
                [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                [title]="'Personal info'"
                [placeholder]="'Select personal info font'"
                [value]="{
                            name: this.product.customizations.typography.personal_info.hasOwnProperty('custom_font_style') && this.product.customizations.typography.personal_info.custom_font_style !== ''? this.product.customizations.typography.personal_info.custom_font_style  :this.product.customizations.typography.personal_info.google_font_style,
                            value: this.product.customizations.typography.personal_info.hasOwnProperty('custom_font_style') && this.product.customizations.typography.personal_info.custom_font_style !== ''? this.product.customizations.typography.personal_info.custom_font_style  :this.product.customizations.typography.personal_info.google_font_style,
                        }"
                [specificOutput]="'name'"
                [listItems]="fontOptionsListProfileInfo"
                [optionLabelField]="'name'"
                [optionValueField]="'value'"
                [width]="'100%'"
                (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)"
              ></beaconstac-filter-dropdown>
            </div>

            <div class="pl-0 d-flex font-specifics-child">
                <div class="pr-3 typography-font-size-dropdown margin-for-no-label">
                    <beaconstac-filter-dropdown
                        [label]="''"
                        [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                        [title]="'profile info font size'"
                        [placeholder]="'select profile info font size'"
                        [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.personal_info.custom_font_size :
                            product.customizations.typography.personal_info.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.personal_info.custom_font_size :
                            product.customizations.typography.personal_info.google_font_size }"
                        [specificOutput]="'name'"
                        [listItems]="Utils.typography_customization_font_size"
                        [optionLabelField]="'name'"
                        [optionValueField]="'value'"
                        [width]="'100%'"
                        (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)"
                    ></beaconstac-filter-dropdown>
                </div>

                <div class="typography-color-picker margin-for-no-label">
                    <beaconstac-color-picker
                        [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.personal_info.custom_font_colour :
                                   product.customizations.typography.personal_info.google_font_colour"
                        [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                        (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO)"
                        [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                        [cpPosition]="'bottom'"
                        [id]="'personal_info_color'"
                        [cpWidth]="'100%'"
                    ></beaconstac-color-picker>
                </div>

            </div>
          </div>

        <!-- Company details -->
        <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 font-specifics">
            <div class="pr-3 font-specifics-child">
                <beaconstac-filter-dropdown
                    [label]="'Company details'"
                    [disabled]="
                    hasWriteAccess
                        | disableFieldCheckPipe
                            : cardSetting
                            : orgCardSetting
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                "
                    [title]="'company details'"
                    [placeholder]="'Select company details font'"
                    [value]="{
                    name: this.product.customizations.typography.company_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.company_details.custom_font_style !== ''? this.product.customizations.typography.company_details.custom_font_style  :this.product.customizations.typography.company_details.google_font_style,
                    value: this.product.customizations.typography.company_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.company_details.custom_font_style !== ''? this.product.customizations.typography.company_details.custom_font_style  :this.product.customizations.typography.company_details.google_font_style,
                    }"
                    [specificOutput]="'name'"
                    [listItems]="fontOptionsListCompanyDetails"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="pl-0 d-flex font-specifics-child">
                <div class="pr-3 typography-font-size-dropdown">
                    <beaconstac-filter-dropdown
                        [label]="' '"
                        [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                        [title]="'company detail font size'"
                        [placeholder]="'select company detail font size'"
                        [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.company_details.custom_font_size :
                            product.customizations.typography.company_details.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.company_details.custom_font_size :
                            product.customizations.typography.company_details.google_font_size }"
                        [specificOutput]="'name'"
                        [listItems]="Utils.typography_customization_font_size"
                        [optionLabelField]="'name'"
                        [optionValueField]="'value'"
                        [width]="'100%'"
                        (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)"
                    ></beaconstac-filter-dropdown>
                </div>
                <div class="typography-color-picker margin-for-no-label">
                    <beaconstac-color-picker
                        [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.company_details.custom_font_colour :
                                   product.customizations.typography.company_details.google_font_colour"
                        [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                        (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS)"
                        [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                        [cpPosition]="'bottom'"
                        [id]="'company_details_id'"
                        [cpWidth]="'100%'"
                    ></beaconstac-color-picker>
                </div>
            </div>
        </div>

        <!-- Bio -->
        <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 font-specifics">
            <div class="pr-3 font-specifics-child">
                <beaconstac-filter-dropdown
                    [label]="'Bio'"
                    [disabled]="
                    hasWriteAccess
                        | disableFieldCheckPipe
                            : cardSetting
                            : orgCardSetting
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                "
                    [title]="'bio'"
                    [placeholder]="'Select bio font'"
                    [value]="{
                    name: this.product.customizations.typography.bio.hasOwnProperty('custom_font_style') && this.product.customizations.typography.bio.custom_font_style !== ''? this.product.customizations.typography.bio.custom_font_style  :this.product.customizations.typography.bio.google_font_style,
                    value: this.product.customizations.typography.bio.hasOwnProperty('custom_font_style') && this.product.customizations.typography.bio.custom_font_style !== ''? this.product.customizations.typography.bio.custom_font_style  :this.product.customizations.typography.bio.google_font_style,
                    }"
                    [specificOutput]="'name'"
                    [listItems]="fontOptionsListBio"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BIO)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="pl-0 d-flex font-specifics-child">
                <div class="pr-3 typography-font-size-dropdown">
                        <beaconstac-filter-dropdown
                            [label]="' '"
                            [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                            [title]="'bio font size'"
                            [placeholder]="'select bio font size'"
                            [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.bio.custom_font_size :
                            product.customizations.typography.bio.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.bio.custom_font_size :
                            product.customizations.typography.bio.google_font_size }"
                            [specificOutput]="'name'"
                            [listItems]="Utils.typography_customization_font_size"
                            [optionLabelField]="'name'"
                            [optionValueField]="'value'"
                            [width]="'100%'"
                            (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.BIO)"
                        ></beaconstac-filter-dropdown>
                    </div>
                <div class="typography-color-picker margin-for-no-label">
                        <beaconstac-color-picker
                            [(color)]="product.customizations.typography.font_type === 'custom' ?
                                       product.customizations.typography.bio.custom_font_colour :
                                       product.customizations.typography.bio.google_font_colour"
                            [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                            (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BIO)"
                            [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                            [cpPosition]="'top'"
                            [id]="'bio_color'"
                            [cpWidth]="'100%'"
                        ></beaconstac-color-picker>
                    </div>
            </div>
        </div>

        <!-- Contact Details -->
        <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 font-specifics">
            <div class="pr-3 font-specifics-child">
                <beaconstac-filter-dropdown
                    [label]="'Contact details'"
                    [disabled]="
                    hasWriteAccess
                        | disableFieldCheckPipe
                            : cardSetting
                            : orgCardSetting
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                "
                    [title]="'contact details'"
                    [placeholder]="'Select contact details font'"
                    [value]="{
                    name: this.product.customizations.typography.contact_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.contact_details.custom_font_style !== ''? this.product.customizations.typography.contact_details.custom_font_style  :this.product.customizations.typography.contact_details.google_font_style,
                    value: this.product.customizations.typography.contact_details.hasOwnProperty('custom_font_style') && this.product.customizations.typography.contact_details.custom_font_style !== ''? this.product.customizations.typography.contact_details.custom_font_style  :this.product.customizations.typography.contact_details.google_font_style,
                }"
                    [specificOutput]="'name'"
                    [listItems]="fontOptionsListContactDetails"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="pl-0 d-flex font-specifics-child">
                <div class="pr-3 typography-font-size-dropdown">
                    <beaconstac-filter-dropdown
                        [label]="' '"
                        [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                        [title]="'contact detail font size'"
                        [placeholder]="'select contact details font size'"
                        [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.contact_details.custom_font_size :
                            product.customizations.typography.contact_details.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.contact_details.custom_font_size :
                            product.customizations.typography.contact_details.google_font_size }"
                        [specificOutput]="'name'"
                        [listItems]="Utils.typography_customization_font_size"
                        [optionLabelField]="'name'"
                        [optionValueField]="'value'"
                        [width]="'100%'"
                        (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)"
                    ></beaconstac-filter-dropdown>
                </div>
                <div class="typography-color-picker margin-for-no-label">
                    <beaconstac-color-picker
                        [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.contact_details.custom_font_colour :
                                   product.customizations.typography.contact_details.google_font_colour"
                        [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                        (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS)"
                        [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                        [cpPosition]="'top'"
                        [id]="'contact_details'"
                        [cpWidth]="'100%'"
                    ></beaconstac-color-picker>
                </div>
            </div>
        </div>

        <!-- Button -->
        <div *ngIf="hasDBCAdvancedCustomization" class="d-flex flex-wrap pt-3 font-specifics">
            <div class="pr-3 font-specifics-child">
                <beaconstac-filter-dropdown
                    [label]="'Button'"
                    [disabled]="
                    hasWriteAccess
                        | disableFieldCheckPipe
                            : cardSetting
                            : orgCardSetting
                            : cardType
                            : priortizeOrgSettings
                            : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                "
                    [title]="'button'"
                    [placeholder]="'Select button font'"
                    [value]="{
                    name: this.product.customizations.typography.button.hasOwnProperty('custom_font_style') && this.product.customizations.typography.button.custom_font_style !== ''? this.product.customizations.typography.button.custom_font_style  :this.product.customizations.typography.button.google_font_style,
                    value:this.product.customizations.typography.button.hasOwnProperty('custom_font_style') && this.product.customizations.typography.button.custom_font_style !== ''? this.product.customizations.typography.button.custom_font_style  :this.product.customizations.typography.button.google_font_style,
                    }"
                    [specificOutput]="'name'"
                    [listItems]="fontOptionsListButton"
                    [optionLabelField]="'name'"
                    [optionValueField]="'value'"
                    [width]="'100%'"
                    (valueChange)="setFontStyleForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)"
                ></beaconstac-filter-dropdown>
            </div>
            <div class="pl-0 d-flex font-specifics-child">
                <div class="pr-3 typography-font-size-dropdown">
                    <beaconstac-filter-dropdown
                        [label]="' '"
                        [disabled]="
                            hasWriteAccess
                                | disableFieldCheckPipe
                                    : cardSetting
                                    : orgCardSetting
                                    : cardType
                                    : priortizeOrgSettings
                                    : CARD_PROPERTY_TYPES.CUSTOMIZATIONS
                        "
                        [title]="'button font size'"
                        [placeholder]="'select button font size'"
                        [value]="{ name: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.button.custom_font_size :
                            product.customizations.typography.button.google_font_size
                            , value: product.customizations.typography.font_type === 'custom' ?
                            product.customizations.typography.button.custom_font_size :
                            product.customizations.typography.button.google_font_size }"
                        [specificOutput]="'name'"
                        [listItems]="Utils.typography_customization_font_size"
                        [optionLabelField]="'name'"
                        [optionValueField]="'value'"
                        [width]="'100%'"
                        (valueChange)="setFontSizeForTypography($event.value, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)"
                    ></beaconstac-filter-dropdown>
                </div>
                <div class="typography-color-picker margin-for-no-label">
                    <beaconstac-color-picker
                        [(color)]="product.customizations.typography.font_type === 'custom' ?
                                   product.customizations.typography.button.custom_font_colour :
                                   product.customizations.typography.button.google_font_colour"
                        [disabled] = "hasWriteAccess | disableFieldCheckPipe: cardSetting : orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.CUSTOMIZATIONS"
                        (colorPickerChange)="setFontColorForTypography($event, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON)"
                        [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                        [cpPosition]="'top'"
                        [id]="'button_text_color'"
                        [cpWidth]="'100%'"
                    ></beaconstac-color-picker>
                </div>
            </div>
        </div>
    </div>

</div>


<div bsModal #handleShowModalForEnterprisePlan="bs-modal" *ngIf="isComputerScreenSize" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 479px;">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">

                <h4 class="create-card-heading font-weight-bold" style="font-style: italic;">
                    <span style="color: black;">Style with </span>
                    <span style="color: #2495ff;">Custom Fonts</span>
                </h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    Upgrade to the BUSINESS+ plan to use your brand's custom fonts.
                </div>

            </div>
            <div class="modal-footer border-0" style="padding: 24px;">

                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="handleShowModalForEnterprisePlan.hide()"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Upgrade Now'"
                  [style]="BUTTON_STYLES.FILLED"
                  (onClick)="routeToChangePlan()"
                  ></beaconstac-button>
                </span>
            </div>

        </div>
    </div>
</div>
<div bsModal #handleShowModalForEnterprisePlan="bs-modal" *ngIf="isMobileScreenSize" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 388px; max-height: 316px;">
        <div class="modal-content modal-wrapper">

            <div class="modal-body" style="padding: 24px">
                <h4 class="create-card-heading font-weight-bold" style="font-style: italic;">
                    <span style="color: black;">Style with </span>
                    <span style="color: #2495ff;">Custom Fonts</span>
                </h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    Upgrade to the BUSINESS+ plan to use your brand's custom fonts.
                </div>
            </div>

            <div class="custom-modal-footer w-100 border-0">
                <div class="bx--row">
                    <div class="bx--col-lg-16 d-flex justify-content-center">
                        <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.FILLED" [label]="'Upgrade Now'" class="btn-flex" [width]="'324px'" (onClick)="routeToChangePlan()"></beaconstac-button>
                    </div>
                </div>
                <div class="bx--row pt-3" style="display:block; justify-content: center;">
                    <div class="bx--col-lg-16 d-flex justify-content-center">
                        <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                           [style]="BUTTON_STYLES.TEXT"
                                           (onClick)="handleShowModalForEnterprisePlan.hide()"
                                           [label]="'Cancel'"
                                           [width]="'324px'"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


  <div bsModal #bgImgSelectorModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" (onShow)="setupBgImgSelectModal()" (onHide)="bgImgSelectGallery.onHide(); bgImgUpload.isUploading = false">
      <div class="modal-dialog modal-info modal-lg" role="document">
          <div class="modal-content modal-content-kz">

              <div class="modal-header-kz">
                  <h4 class="modal-title">Select Background Image</h4>
              </div>

              <div class="modal-body modal-body-kz">
                  <div class="form-group">
                      <app-media-gallery #bgImgSelectGallery
                                         (fileUploaded)="loadCropper($event)"
                                         (mediaSelector)="imageSelectedInGallery($event)"
                                         [addMedia]="bgImgUpload.media"
                                         [dimensions]="'1024x1024'"
                                         (uploadToFolderId)="setUploadToFolder($event)" [searchParams]="{'content_type__nin':'image/gif'}"
                                         [acceptedTypes]="['image/jpeg', 'image/png', 'image/svg+xml']"
                                         [isFooterVisible]="true"
                                         [isSelectDisabled]="!bgImgUpload.media"
                                         (onSelect)="onSelectBgImg()"
                                         (onClose)="onCloseBgImgSelectGallery();">
                      </app-media-gallery>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <app-image-cropper #imageCropper *ngIf="imageCropperFile"
                     [imageFile]="imageCropperFile"
                     (cropperClose)="closeImageCropperModal()"
                     [squareDragMode]="true"
                     [folderId]="uploadToFolder"
                     (imageUpdated)="updateMedia($event)"
                     [imageAspectRatio]="'1'">
  </app-image-cropper>
