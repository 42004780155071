import {BaseModel} from '../global-services/base.model';
import { EddyCampaignTypes } from '../global-services/campaign.model';

export enum FEATURES {
    qr = 'qr',
    nfc = 'nfc',
    beacon = 'beacon',
    geofence = 'geofence',
    retargeting = 'retargeting',
    multi_lang = 'multi_lang',
    location = 'location',
    multi_user = 'multi_user',
    multi_qr = 'multi_qr',
    multi_org = 'multi_org',
    reseller_access = 'reseller_access',
    custom_domain = 'custom_domain',
    whitelabel_access = 'whitelabel_access',
    webhook = 'webhook',
    ibeacon = 'ibeacon',
    custom_slug = 'custom_slug',
    sso = 'sso',
    form_response_sms = 'form_response_sms',
    api = 'api',
    short_url = 'short_url',
    share_folder = 'share_folder',
    manage_campaign_access = 'manage_campaign_access',
    restrict_static_qr = 'restrict_static_qr',
    apple_wallet = 'apple_wallet',
    google_wallet = 'google_wallet',
    bulk_upload = 'bulk_upload',
    bulk_dbc = 'bulk_dbc',
    qr_template = 'qr_template',
    azure_scim_integration = 'azure_scim_integration',
    edit_org = 'edit_org',
    salesforce_integration = 'salesforce_integration',
    api_dbc = 'api_dbc',
    custom_font = 'custom_font',
    advanced_customization_options = 'advanced_customization_options',
    password_protection = 'password_protection',
    age_restriction = 'age_restriction'
}


export class Permissions extends BaseModel {

    qr: boolean;
    nfc: boolean;
    beacon: boolean;
    geofence: boolean;
    retargeting: boolean;
    multi_lang: boolean;
    location: boolean;
    multi_user: boolean;
    reseller_access: boolean;
    custom_domain: boolean;
    whitelabel_access: boolean;
    webhook: boolean;
    ibeacon: boolean;
    custom_slug: boolean;
    sso: boolean;
    form: boolean;
    form_response_sms: boolean;
    restrict_static_qr: boolean;
    bulk_qr: boolean;
    wallet_pass: boolean;
    qr_template: boolean;
    azure_scim_integration: boolean;
    campaign: {
        1: boolean,
        2: boolean,
        3: boolean,
        4: boolean,
        5: boolean,
        6: boolean,
        7: boolean,
        8: boolean,
        9: boolean,
        10: boolean,
        11: boolean,
        13: boolean,
        14: boolean,
        15: boolean,
        16: boolean,
        17: boolean,
        18: boolean,
        19: boolean,
        20: boolean,
        21: boolean,
        22: boolean,
        23: boolean
    } = {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true,
            7: true,
            8: true,
            9: true,
            10: true,
            11: true,
            13: true,
            14: true,
            15: true,
            16: true,
            17: true,
            18: true,
            19: true,
            20: true,
            21: true,
            22: true,
            23: true
        };
    dbc: boolean;
    webhooks: boolean;
    multi_org: boolean;
    edit_org: boolean;
    ga_code: boolean;
    non_admin_add_user: boolean;
    password_protection: boolean;
    age_restriction: boolean;
    geolocation: boolean;
    smart_rules: boolean;
    advanced_analytics: boolean;
    qr_watchlist: boolean;

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.qr = json['qr'];
        this.nfc = json['nfc'];
        this.beacon = json['beacon'];
        this.geofence = json['geofence'];
        this.retargeting = json['retargeting'];
        this.multi_lang = json['multi_lang'];
        this.location = json['location'];
        this.multi_user = json['multi_user'];
        this.reseller_access = json['reseller_access'];
        this.custom_domain = json['custom_domain'];
        this.whitelabel_access = json['whitelabel_access'];
        this.webhook = json['webhook'];
        this.ibeacon = json['ibeacon'];
        this.custom_slug = json['custom_slug'];
        this.sso = json['sso'];
        this.form_response_sms = json['form_response_sms'];
        this.qr_template = json['qr_template'];
        this.form = json['form'];
        this.restrict_static_qr = json['restrict_static_qr'] || false;
        this.campaign = Object.assign(this.campaign, json['campaign']);
        this.bulk_qr = json['bulk_qr'];
        this.wallet_pass = json['wallet_pass'];
        this.azure_scim_integration = json['azure_scim_integration'];
        this.dbc = json['dbc'] ? json['dbc'] : false;
        this.edit_org = json['edit_org'];
        this.multi_org = json['multi_org'];
        this.ga_code = json['ga_code'];
        this.non_admin_add_user = json['non_admin_add_user'];
        this.geolocation = json['geolocation'] || false;
        this.smart_rules = json['smart_rules'] || false;
        this.password_protection = json['password_protection'] || false;
        this.age_restriction = json['age_restriction'] || false;
        this.advanced_analytics = json['advanced_analytics'] || false;
        this.qr_watchlist = json['qr_watchlist'];
    }

    get hasOnlyQrAccess(): boolean {
        return this.qr && !this.beacon && !this.nfc && !this.geofence;
    }

    hasCampaignAccess(campaignType: EddyCampaignTypes): boolean {
        return this.campaign[campaignType];
    }

    checkForMultipleCampaignAccess(campaignTypes: EddyCampaignTypes[]): boolean {

        for (const campaignType of campaignTypes) {
            if (!this.campaign[campaignType]) {
                return false
            }
        }
        return true
    }
}

export class DbcPermissions extends BaseModel {
    bulk_dbc: boolean
    wallet_pass: boolean
    retargeting: boolean
    location: boolean
    multi_user: boolean
    custom_domain: boolean
    webhook: boolean
    multi_org: boolean
    custom_slug: boolean
    azure_scim_integration: boolean
    edit_org: boolean;
    salesforce_integration: boolean;
    multi_language: boolean;
    non_admin_add_user: boolean;
    card_template: boolean;
    deprecated_restricted_fields: boolean;
    custom_font: boolean;
    advanced_customization_options: boolean;

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.setupDbcPermissions(json);
    }

    private setupDbcPermissions(json) {
        this.bulk_dbc = json['bulk_dbc'];
        this.wallet_pass = json['wallet_pass'] || true;
        this.retargeting = json['retargeting'];
        this.location = json['location'];
        this.multi_user = json['multi_user'];
        this.custom_domain = json['custom_domain']
        this.webhook = json['webhook'];
        this.multi_org = json['multi_org'];
        this.custom_slug = json['custom_slug'];
        this.azure_scim_integration = json['azure_scim_integration'];
        this.edit_org = json['edit_org'];
        this.salesforce_integration = json['salesforce_integration'];
        this.multi_language = json['multi_language'];
        this.non_admin_add_user = json['non_admin_add_user'];
        this.card_template = json['card_template'];
        this.deprecated_restricted_fields = json['deprecated_restricted_fields'];
        this.custom_font = json['custom_font'];
        this.advanced_customization_options = json['advanced_customization_options'];
    }
}

