import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { QR_BULK_OPERATIONS, QR_CODE_TYPE_VALUE } from '../../qr/qr.model';
import { BULK_ACTION_PRODUCT, CARD_BULK_OPERATIONS } from '../../shared/utils';
import { BUTTON_TYPES, BUTTON_STYLES, ICON_SIZE } from 'kaizen-design-system';

@Component({
    selector: 'app-bulk-action',
    templateUrl: './bulk-action.component.html',
    styleUrls: ['./bulk-action.component.scss']
})
export class BulkActionComponent implements OnInit {
    // For QR
    @Input() selectedQRCodeLength: number;
    @Input() qrCodeTotalCount: number;
    @Input() isAllQRCodeSelected: boolean;
    @Input() disableLabels: boolean;
    @Input() qrCodeListTypeValue: number;
    @Input() showShareWalletPassButton: boolean;
    @Input() showBulkIconsLabel: boolean = true;

    @Input() hasSelectedStaticQRCode: boolean;
    @Input() hasFilterApplied: boolean = false;

    @Output() isAllQRCodeSelectedChange: EventEmitter<boolean> = new EventEmitter();
    @Output() selectAllQRCodes: EventEmitter<boolean> = new EventEmitter();

    @Input() bulkActionProduct: BULK_ACTION_PRODUCT = BULK_ACTION_PRODUCT.QR;
    @Output() setBulkOperations: EventEmitter<number> = new EventEmitter();
    @Output() resetBulkOperation: EventEmitter<boolean> = new EventEmitter();

    // For DBC
    @Input() selectedVcardLength: number;
    @Input() vCardTotalCount: number;
    @Input() isAllVcardSelected: boolean;

    @Output() isAllVcardSelectedChange: EventEmitter<boolean> = new EventEmitter();
    @Output() selectAllvCards: EventEmitter<boolean> = new EventEmitter();


    QR_BULK_OPERATIONS = QR_BULK_OPERATIONS
    QR_CODE_TYPE_VALUE = QR_CODE_TYPE_VALUE
    BULK_ACTION_PRODUCT = BULK_ACTION_PRODUCT;
    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;
    CARD_BULK_OPERATIONS = CARD_BULK_OPERATIONS;
    ICON_SIZE = ICON_SIZE;

    selectedBulkOperation: number = 1;

    constructor() { }
    ngOnInit(): void {}

    deselectAllQRCodes() {
        this.isAllQRCodeSelected = false;
        this.isAllQRCodeSelectedChange.emit(this.isAllQRCodeSelected);
        this.resetBulkOperation.emit();
    }

    onSelectBulkOperation(event: any) {
        this.selectedBulkOperation = event ;
        this.setBulkOperations.emit(this.selectedBulkOperation);
    }

    bulkSelectQRCodes() {
        this.isAllQRCodeSelected = true;
        this.isAllQRCodeSelectedChange.emit(this.isAllQRCodeSelected);
        this.selectAllQRCodes.emit();
    }

    deselectAllVcards() {
        this.isAllVcardSelected = false;
        this.isAllVcardSelectedChange.emit(this.isAllVcardSelected);
        this.resetBulkOperation.emit();
    }

    bulkSelectVcards() {
        this.isAllVcardSelected = true;
        this.isAllVcardSelectedChange.emit(this.isAllVcardSelected);
        this.selectAllvCards.emit();
    }
}
