import {Injectable} from '@angular/core';
import {BaseModel} from '../global-services/base.model';
import {BaseBackendService} from '../global-services/base-backend.service';
import {AuthService} from '../global-services/auth.service';
import {HttpClient} from '@angular/common/http';
import {MessageModalService} from '../shared/message-modal/message-modal.service';

export interface TagSearch {
    id: number,
    name: string
}

export class Tag extends BaseModel {

    id: number = null;
    name: string = null;
    slug: string = null;
    organization: number = null;
    beacon_data: Array<object>;
    beacon_set: Array<number> = [];
    beacon_count: number;
    qrcode_data: Array<object>;
    qrcode_set: Array<number> = [];
    qrcode_count: number;
    nfctag_data: Array<object>;
    nfctag_set: Array<number> = [];
    nfctag_count: number;
    geofence_data: Array<object>;
    geofence_set: Array<number> = [];
    bulkqrcodes_set: Array<number> = [];
    geofence_count: number;
    created: Date = null;
    updated: Date = null;
    color: string = null;
    maintainer: number = null;

    digitalbusinesscard_data: Array<object>;
    digitalbusinesscard_set: Array<number> = [];
    digitalbusinesscard_count: number;

    constructor(json?: any) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.name = json['name'];
        this.slug = json['slug'];
        this.organization = json['organization'];
        this.created = new Date(json['created']);
        this.updated = new Date(json['updated']);
        this.beacon_data = json['beacon_data'];
        this.beacon_set = json['beacon_set'] || [];
        this.beacon_count = json['beacon_count'];
        this.qrcode_data = json['qrcode_data'];
        this.qrcode_set = json['qrcode_set'] || [];
        this.qrcode_count = json['qrcode_count'];
        this.nfctag_data = json['nfctag_data'];
        this.nfctag_set = json['nfctag_set'] || [];
        this.bulkqrcodes_set = json['bulkqrcodes_set'] || [];
        this.nfctag_count = json['nfctag_count'];
        this.geofence_data = json['geofence_data'];
        this.geofence_set = json['geofence_set'] || [];
        this.geofence_count = json['geofence_count'];
        this.color = json['color'] ? json['color'] : '#C9C9C9';
        this.maintainer = json['maintainer'] || null;

        this.digitalbusinesscard_data = json['digitalbusinesscard_data'] || this.digitalbusinesscard_data;
        this.digitalbusinesscard_set = json['digitalbusinesscard_set'] || this.digitalbusinesscard_set;
        this.digitalbusinesscard_count = json['digitalbusinesscard_count'] || this.digitalbusinesscard_count;
    }
}

@Injectable()
export class TagsService extends BaseBackendService<Tag> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Tag, 'tags', messageModal);
    }

}

@Injectable()
export class TagAttachService extends BaseBackendService<Tag> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Tag, 'tags/attach_products/list', messageModal);
    }
}

@Injectable()
export class TagAttachByFilterService extends BaseBackendService<Tag> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, Tag, 'tags/attach_products/filter', messageModal);
    }
}
