import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../../user-account/user-organization.model';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../../digital-business-card-setting/digital-business-card-setting.model';
import {DigitalBusinessCard, } from '../../digital-business-card.model';
import {DBCCardTypes} from '../../../shared/utils';
import {Animations} from '../../../shared/beaconstac-animations';
import {LEAD_COLLECT_TYPE} from '../../digital-business-cards.service';

@Component({
    selector: 'app-dbc-lead-form',
    templateUrl: './dbc-lead-form.component.html',
    styleUrls: ['./dbc-lead-form.component.scss'],
    animations: [Animations.collapse]
})
export class DbcLeadFormComponent implements AfterViewInit {

    @Input() product: any = null;
    @Input() hasWriteAccess = true;
    @Input() cardSetting: DigitalBusinessCardSetting;
    @Input() orgCardSetting: CardsOrganizationRestrictedFields;
    @Input() cardType: DBCCardTypes;
    @Input() priortizeOrgSettings: boolean = false;
    @Input() leadSettings: Array<{}>;
    @Input() isSalesforceConnected: boolean = false;

    @Output() productChange: EventEmitter<DigitalBusinessCard> = new EventEmitter();
    @Output() lockIconClickEmit: EventEmitter<CARD_PROPERTY_TYPES> = new EventEmitter();
    @Output() cardSettingChange: EventEmitter<DigitalBusinessCardSetting> = new EventEmitter();

    protected readonly DBC_ORG_PERMISSION_OPTIONS = DBC_ORG_PERMISSION_OPTIONS;
    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;

    ngAfterViewInit() {
        // set company disable state
        this.leadSettings.forEach((setting: any) => {
            if (setting?.name === 'company') {
                setting['disable'] = !this.hasWriteAccess || (this.cardSetting.lead_collection && this.cardType !== DBCCardTypes.TEMPLATE_CARDS);
            }
        });
    }

    toggleLeadCollection(event: boolean) {
        this.product.lead_collection = event;
        this.productChange.emit(this.product);
    }

    handleIconClick(type: CARD_PROPERTY_TYPES) {
        this.lockIconClickEmit.next(type)
    }

    toggleLeadSetting(event: boolean, setting) {
        this.product.lead_attribute[setting.name] = event;
        this.productChange.emit(this.product);
    }

    toggleTermsOfService(event) {
        this.product.lead_user_agreement = event;
        this.productChange.emit(this.product);
    }

    toggleLeadCollectType(event, type: LEAD_COLLECT_TYPE) {
        this.product.lead_attribute.connection = type;
        this.productChange.emit(this.product);
    }

    protected readonly LEAD_COLLECT_TYPE = LEAD_COLLECT_TYPE;
}
