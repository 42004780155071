import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomStickyButtonsComponent } from './bottom-sticky-buttons.component';
import {BeaconstacModule} from 'kaizen-design-system';

@NgModule({
    declarations: [BottomStickyButtonsComponent],
    imports: [CommonModule, BeaconstacModule],
    exports: [BottomStickyButtonsComponent]
})
export class BottomStickyButtonsModule { }
