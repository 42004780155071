import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalBusinessCardListComponent } from './digital-business-card-list/digital-business-card-list.component';
import {
    DigitalBusinessCardDetailComponent, DisableFieldCheckPipe,
    InputIconPipe
} from './digital-business-card-detail/digital-business-card-detail.component';
import { BeaconstacModule } from 'kaizen-design-system';
import { DigitalBusinessCardRoutingModule } from './digital-business-card-routing.module';
import { DigitalBusinessCardSetupComponent } from './digital-business-card-detail/digital-business-card-setup/digital-business-card-setup.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PipesModule } from '../global-components/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BeaconstacFileDropModule } from '../global-components/beaconstac-file-drop/beaconstac-file-drop.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { MediaGalleryModule } from '../global-components/media-gallery/media-gallery.module';
import { ImageCropperModule } from '../global-components/image-cropper/image-cropper.module';
import { DigitalBusinessCardDesignComponent } from './digital-business-card-detail/digital-business-card-design/digital-business-card-design.component';
import { DigitalBusinessCardLayoutsComponent } from './digital-business-card-layouts/digital-business-card-layouts.component';
import {
    DigitalBusinessCardLaunchComponent,
    GetSlugFromUrl,
} from './digital-business-card-detail/digital-business-card-launch/digital-business-card-launch.component';
import {
    CustomSlugValidationService,
    DigitalBusinessCardsService,
    ShareWalletPassFilterService,
    ShareWalletPassService,
    DbcBulkFilterSoftDeleteService,
    DbcBulkSoftDeleteService,
    OwnerTransferService,
    DbcQRDownloadService,
    DigitalBusinessCardTemplateService,
    DigitalBusinessCardSettingService,
    DbcBulkFilterHardDeleteService,
    DbcBulkHardDeleteService,
    DbcQRBulkFilterDownloadService,
    DbcQRBulkDownloadService, CardsOrgSettingService
} from './digital-business-cards.service';
import { BulkActionModule } from '../global-components/bulk-action/bulk-action.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UserService } from '../user-account/user.service';
import { ProductTransfer } from '../global-services/product-transfer.service';
import { AnalyticsService } from '../global-services/analytics.service';
import { ProductGuard } from '../guards/product.guard';
import {
    DigitalBusinessCardSettingComponent,
    SettingReadableName,
} from './digital-business-card-setting/digital-business-card-setting.component';
import { BulkQrV2Service } from '../qr/bulk-upload/bulk-qr-v2.service';
import { CsvValidationModule } from '../shared/csv-validation/csv-validation.module';
import { DigitalBusinessCardBulkUploadComponent } from './digital-business-card-bulk-upload/digital-business-card-bulk-upload.component';
import { DigitalBusinessCardBulkDesignComponent } from './digital-business-card-bulk-upload/digital-business-card-bulk-design/digital-business-card-bulk-design.component';
import { DigitalBusinessCardBulkLaunchComponent } from './digital-business-card-bulk-upload/digital-business-card-bulk-launch/digital-business-card-bulk-launch.component';
import { BulkCsvValidationModule } from '../global-components/bulk-csv-validation/bulk-csv-validation.module';
import { DigitalBusinessCardBulkService } from './digital-business-card-bulk-upload/digital-business-card-bulk-service';
import { BeaconstacAddOnModule } from '../global-components/beaconstac-add-on/beaconstac-add-on.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QRTemplateService } from 'app/qr-template/qr-template.service';
import { ExternalUrlService } from 'app/global-services/external-url.service';
import { FetchOrganizationsService } from 'app/global-services/fetch-organizations.service';
import { NgSelectModule } from '@ng-select/ng-select';


import { GoogleWalletPassPreviewModule } from '../global-components/google-wallet-pass-preview/google-wallet-pass-preview.module';
import { AppleWalletPassPreviewModule } from '../global-components/apple-wallet-pass-preview/apple-wallet-pass-preview.module';
import { NotificationLanguageModule } from '../global-components/notification-language/notification-language.module';

import { CustomDomainService } from 'app/user-account/custom-domain.service';
import { WalletpassTemplateService } from 'app/wallet-pass-template/wallet-pass-template.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapModule } from 'app/global-components/map/map.module';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { DigitalBusinessCardAnalyticsModule } from './digital-business-card-analytics/digital-business-card-analytics.module';
import { DigitalBusinessCardTemplatesComponent } from './digital-business-card-templates/digital-business-card-templates.component';
import { DbcDesignsGridViewComponent } from './digital-business-card-global-components/dbc-designs-grid-view/dbc-designs-grid-view.component';
import { DigitalBusinessCardDesignLibraryComponent } from './digital-business-card-design-library/digital-business-card-design-library.component';
import { DigitalBusinessCardTemplatesDropdownComponent } from './digital-business-card-global-components/digital-business-card-templates-dropdown/digital-business-card-templates-dropdown.component';
import { CardsMultiOrgBulkUploadComponent } from './cards-multi-org-bulk-upload/cards-multi-org-bulk-upload.component';
import { CardsMultiOrgBulkSetupComponent } from './cards-multi-org-bulk-upload/cards-multi-org-bulk-setup/cards-multi-org-bulk-setup.component';
import {FilterProductModule} from '../global-components/filter-products/filter-products.module';
import { TypographyFontsComponent } from './digital-business-card-global-components/typography-fonts/typography-fonts.component';
import {BeaconstacProductLabelModule} from '../global-components/product-label/beaconstac-product-label.module';
import { TagAttachByFilterService, TagAttachService, TagsService } from '../tags/tags.service';
import { BeaconstacMobileDrawerModule } from 'app/global-components/beaconstac-mobile-drawer/beaconstac-mobile-drawer.module';
import { GetSocialIconSvgPipe } from './digital-business-card-detail/digital-business-card-setup/digital-business-card-setup.component';
import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import { DbcStepperModuleNew } from '../global-components/dbc-stepper-new/dbc-stepper-new.component.module';
import { BottomStickyButtonsModule } from '../global-components/bottom-sticky-buttons/bottom-sticky-buttons.component.module';
import { DbcLeadFormComponent } from './digital-business-card-global-components/dbc-lead-form/dbc-lead-form.component';
import {StrokeRadioButtonModule} from '../global-components/stroke-radio-button/stroke-radio-button.module';
import {TermsServiceModule} from '../global-components/terms-service/terms-service.module';
import {SalesforceModule} from '../integrations/salesforce/salesforce.module';


const CustomSelectOptions: INgxSelectOptions = {
    optionValueField: 'id',
    keepSelectedItems: true,
    noResultsFound: '',
};

@NgModule({
    declarations: [
        DigitalBusinessCardListComponent,
        DigitalBusinessCardDetailComponent,
        DigitalBusinessCardSetupComponent,
        DigitalBusinessCardDesignComponent,
        DigitalBusinessCardLayoutsComponent,
        DigitalBusinessCardLaunchComponent,
        DigitalBusinessCardSettingComponent,
        GetSlugFromUrl,
        SettingReadableName,
        DigitalBusinessCardBulkUploadComponent,
        DigitalBusinessCardBulkDesignComponent,
        DigitalBusinessCardBulkLaunchComponent,
        DigitalBusinessCardTemplatesComponent,
        DbcDesignsGridViewComponent,
        DigitalBusinessCardDesignLibraryComponent,
        InputIconPipe,
        DigitalBusinessCardTemplatesDropdownComponent,
        DisableFieldCheckPipe,
        CardsMultiOrgBulkUploadComponent,
        CardsMultiOrgBulkSetupComponent,
        TypographyFontsComponent,
        GetSocialIconSvgPipe,
        DbcLeadFormComponent
    ],
    imports: [
        CommonModule,
        BeaconstacModule,
        DigitalBusinessCardRoutingModule,
        TabsModule,
        PipesModule,
        FormsModule,
        BeaconstacFileDropModule,
        ColorPickerModule,
        ModalModule,
        MediaGalleryModule,
        ImageCropperModule,
        ReactiveFormsModule,
        BulkActionModule,
        BsDropdownModule,
        CsvValidationModule,
        BeaconstacAddOnModule,
        BulkCsvValidationModule,
        InfiniteScrollModule,
        GoogleMapsModule,
        DigitalBusinessCardAnalyticsModule,
        MapModule,
        NgSelectModule,
        GoogleWalletPassPreviewModule,
        AppleWalletPassPreviewModule,
        NotificationLanguageModule,
        NgxChartsModule,
        NgxSelectModule.forRoot(CustomSelectOptions),
        FilterProductModule,
        BeaconstacMobileDrawerModule,
        FilterProductModule,
        BeaconstacProductLabelModule,
        CdkDropList,
        CdkDrag,
        DbcStepperModuleNew,
        BottomStickyButtonsModule,
        StrokeRadioButtonModule,
        TermsServiceModule,
        SalesforceModule
    ],
    providers: [
        DigitalBusinessCardsService,
        CustomSlugValidationService,
        ShareWalletPassService,
        ShareWalletPassFilterService,
        DbcBulkFilterSoftDeleteService,
        DbcBulkSoftDeleteService,
        UserService,
        OwnerTransferService,
        ProductTransfer,
        ExternalUrlService,
        AnalyticsService,
        DbcQRDownloadService,
        ProductGuard,
        BulkQrV2Service,
        CustomDomainService,
        DigitalBusinessCardTemplateService,
        DigitalBusinessCardSettingService,
        DigitalBusinessCardBulkService,
        QRTemplateService,
        DbcBulkHardDeleteService,
        DbcBulkFilterHardDeleteService,
        DbcQRBulkDownloadService,
        DbcQRBulkFilterDownloadService,
        FetchOrganizationsService,
        WalletpassTemplateService,
        BsLocaleService,
        CardsOrgSettingService,
        TagsService,
        TagAttachService,
        TagAttachByFilterService
    ],
})
export class DigitalBusinessCardModule {}
