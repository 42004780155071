import {Component, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild , ElementRef, AfterViewInit } from '@angular/core';
import {OverlayService} from '../../global-services/overlay.service';
import {DigitalBusinessCardSetting} from './digital-business-card-setting.model';
import {DigitalBusinessCardSettingService} from '../digital-business-cards.service';
import {AuthService} from '../../global-services/auth.service';
import {debounceTime, map, distinctUntilChanged} from 'rxjs/operators';
import {DigitalBusinessCardTemplateService} from '../digital-business-cards.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {Subject , fromEvent} from 'rxjs';
import {Utils} from '../../shared/utils';
import {UserOrgService} from '../../user-account/user-organization.service';
import { WalletpassTemplateService } from 'app/wallet-pass-template/wallet-pass-template.service';
import {BUTTON_TYPES, TOAST_TYPES, BUTTON_STYLES, TOOLTIP_POSITION,
    CARET_POSITION, } from 'kaizen-design-system';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomFontsService } from 'app/global-services/custom-fonts.service';
import { CustomFontsData } from 'app/global-services/customfont';
import { BeaconstacFileDropComponent } from 'app/global-components/beaconstac-file-drop/beaconstac-file-drop.component';
import { PLAN_TYPES } from 'app/user-account/user.model';
import { Router } from '@angular/router';
import { FEATURES } from 'app/user-account/organization-permission-model';
import { AMPLITUDE_EVENT_CATEGORIES  , AmplitudeService , AMPLITUDE_EVENTS } from 'app/global-services/amplitude.service';
import {UpsellService} from '../../global-services/upsell.service';
@Pipe({name: 'SettingReadableName', pure: true})
export class SettingReadableName implements PipeTransform {
    transform(setting: string): string {
        setting = (setting === 'lead_collection') ? 'two-way contact sharing' : setting;
        return (setting.charAt(0).toUpperCase() + setting.slice(1)).replace(/_/g, ' ');
    }
}

enum SETTINGS_PAGE_EVENTS {
    CUSTOM_FONTS = 'settings',
}
@Component({
    selector: 'app-digital-business-card-trash-list',
    templateUrl: './digital-business-card-setting.component.html',
    styleUrls: ['./digital-business-card-setting.component.scss']
})
export class DigitalBusinessCardSettingComponent implements OnInit, OnDestroy , AfterViewInit {

    dbcSetting: DigitalBusinessCardSetting = null;
    dbcSettingKeys: Array<string>;
    dbcTemplatesExists: boolean = false;
    customLabels = [];
    customLabelContentChange = new Subject<boolean>();
    walletPassEnforced: boolean = false;
    walletPassTemplateSetting = new Subject<boolean>();
    saveContentChange = new Subject<boolean>();
    hasWriteAccess: boolean;
    @ViewChild('uploadFontFile', { static: false }) uploadFontFile: BeaconstacFileDropComponent;
    @ViewChild('handleShowModalForDeleteFont', { static: false }) handleShowModalForDeleteFont: ModalDirective;
    @ViewChild('fontUploadTooltipDescription', { static: false }) fontUploadTooltipDescription: ModalDirective;
    @ViewChild('manageCustomFontsSection' , {static : false}) manageCustomFontsSection: ElementRef;
    BUTTON_STYLES = BUTTON_STYLES;

    hiddenSettingKeys = ['organization', 'custom_labels', 'lead_collection', 'autodownload', 'ip_location_enabled', 'location_enabled', 'id', 'email_wallet_pass', 'template', 'enforce_card_template', 'layout', 'lead_user_agreement'];

    BUTTON_TYPES = BUTTON_TYPES;
    TOOLTIP_POSITION  = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION;
    showDepricatedOrgSettings = false;
    hasCardTemplateAccess = false;

    customFontsData: CustomFontsData = {
        header: [
            {
                text: 'FONT',
                mobile: true
            },
            {
                text: 'STYLE',
                mobile: true
            }
        ],
        data: [
            {
                dataObject: '0',
                dataItems: {
                    title: {
                        type: 'single',
                        class: 'title',
                        mobile: true,
                        value: {
                            text: 'Inter'
                        }
                    },
                    labels: {
                        type: 'single',
                        value: {
                            text: 'Font'
                        }
                    }
                },
                toolbarIcons: {
                    desktop: [
                        {
                            class: 'fas fa-trash-alt',
                            color: '#FF697E',
                            tooltip: 'I am delete',
                            event: {
                                type: 'link',
                                operation: 'delete'
                            },
                            hidden: false
                        }
                    ],
                    mobile: [
                        {
                            class: 'fas fa-trash-alt',
                            color: '#FF697E',
                            tooltip: 'I am delete',
                            event: {
                                type: 'link',
                                operation: 'delete',
                                output: 'function()'
                            }
                        }
                    ]
                }
            },
            {
                dataObject: '1',
                dataItems: {
                    title: {
                        type: 'single',
                        class: 'title',
                        mobile: true,
                        value: {
                            text: 'title text'
                        }
                    },
                    labels: {
                        type: 'single',
                        value: {
                            text: 'Feedback form'
                        }
                    }
                },
                toolbarIcons: {
                    desktop: [
                        {
                            class: 'fas fa-trash-alt',
                            color: '#FF697E',
                            tooltip: 'I am delete',
                            event: {
                                type: 'link',
                                operation: 'delete'
                            },
                            hidden: false
                        }
                    ],
                    mobile: [
                        {
                            class: 'fas fa-trash-alt',
                            color: '#FF697E',
                            tooltip: 'I am delete',
                            event: {
                                type: 'link',
                                operation: 'info',
                                output: 'function()'
                            }
                        }
                    ]
                }
            }
        ]
    };



    notOnEnterprisePlan: boolean;
    isMobileScreenSize: boolean;
    constructor(
        private overlayService: OverlayService,
        public organizationService: UserOrgService,
        private dbcSettingService: DigitalBusinessCardSettingService,
        private authService: AuthService,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private walletpassTemplateService: WalletpassTemplateService,
        private messageModal: MessageModalService,
        private router: Router,
        private customFontService: CustomFontsService,
        private manageCustomFontsSectionRef: ElementRef,
        private amplitude: AmplitudeService,
        private upsellService: UpsellService
    ) {

        this.hasCardTemplateAccess = this.authService.getUser().organization.dbc_feature_permissions.card_template;
        this.notOnEnterprisePlan = !this.authService.getUser().isOnHigherDBCPlan(PLAN_TYPES.DBCEnterprise);

    }
    resizeSubject = new Subject();
    ngOnInit(): void {
        if (this.authService.getUser().hasDbcPermissionFor(FEATURES.custom_font)) {
            this.getFonts();
        }
        this.isMobileScreen();
        fromEvent(window, 'resize')
            .pipe(
                map(() => window.innerWidth),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe((width: number) => {
                this.resizeSubject.next(width);
            });

        this.resizeSubject.subscribe((width: number) => {
            this.isMobileScreenSize = width <= 767;
        });
        this.dbcSettingService.getDetail(this.authService.getCurrentOrgId()).subscribe(settings =>  {
            this.overlayService.isLoading(false);
            this.dbcSetting = new DigitalBusinessCardSetting(settings);
            this.dbcSettingKeys = Object.keys(this.dbcSetting).filter(key => key !== 'custom_labels' && !this.hiddenSettingKeys.includes(key));
            this.dbcSetting['organization'] = this.authService.getCurrentOrgId();
            for (const label of this.dbcSetting['custom_labels']) {
                this.customLabels.push({ label : label})
            }
        }, error => {
            this.dbcSetting = new DigitalBusinessCardSetting();
            this.dbcSetting['organization'] = this.authService.getCurrentOrgId();
            console.error(error);
        })


        this.walletPassTemplateSetting.pipe(debounceTime(200)).subscribe(event => {
            this.saveWalletPassChanges(event);
        });

        if (this.hasCardTemplateAccess) {
            this.digitalBusinessCardTemplateService.getList(1, 1).subscribe( templates => {
                this.dbcTemplatesExists = templates.totalCount > 0;
            })
        }

        this.saveContentChange.pipe(debounceTime(200)).subscribe(() => {
            this.onSave();
        });

        this.walletPassEnforced = this.authService.getCurrentOrganization().wallet_pass_template.enforce_wallet_pass_template;
        this.hasWriteAccess = this.authService.getUser().hasWriteAccess(this.authService.getCurrentOrgId());
        this.showDepricatedOrgSettings = this.authService.getCurrentOrganization().dbc_feature_permissions.deprecated_restricted_fields;
    }

    ngAfterViewInit(): void {
        this.customFontService.shouldScrollToManageCustomFonts$.subscribe(shouldScroll => {
            if (shouldScroll ) {
                setTimeout(() => {
                    this.scrollIntoViewIfNeeded();
                }, 1200)
            }
        });
    }
    isMobileScreen() {
        this.isMobileScreenSize =  window.innerWidth <= 767;
    }
    private scrollIntoViewIfNeeded(): void {
        if (this.manageCustomFontsSectionRef) {
            const element = this.manageCustomFontsSectionRef.nativeElement;
            const yOffset = 1180; // Adjust as needed to position the element correctly
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        this.customFontService.setScrollToManageCustomFonts(false);
    }
    deleteUserFont: object;
    handleToolbarClickForDeleting($event){
        this.deleteUserFont = $event;
        this.handleShowModalForDeleteFont.show();
    }
    handleDeleteOperationForDeletingFont() {
        this.customFontService.delete(this.deleteUserFont['dataObject'].id).subscribe(() => {
            this.messageModal.show('Successfully deleted font from dashboard', 'success', 0);
            this.getFonts();
        }, error => {
            this.messageModal.show('Error while Deleting Font', 'danger', 0);
            console.log('error deleting font');
        })
        this.handleShowModalForDeleteFont.hide();
    }

    onDataFileAdded(file: File) {
        const updatedFile = file;
        const fileExtension = updatedFile.name.split('.').pop().toLowerCase();
        if (updatedFile.type === '' && ( fileExtension === 'otf' || fileExtension === 'ttf')) {
            let newType = '';
            if (fileExtension === 'otf') {
                newType = 'font/otf';
            } else if (fileExtension === 'ttf') {
                newType = 'font/ttf';
            }
            const newFile = new File([file], file.name, { type: newType });
            file = newFile;
        }
        this.messageModal.show('Uploading fonts', 'warning', 0);
        this.customFontService.validate(file, 'validate').pipe().subscribe(res => {
            this.saveFontFile(file, res);
        }, error => {
            this.messageModal.show('Something went wrong', 'danger', 0);
            console.log('Error uploading font');
            this.settingsPageEventToAmplitude(SETTINGS_PAGE_EVENTS.CUSTOM_FONTS, 'Unsuccessful');
        });

        this.uploadFontFile.clear();
        return;
    }
    settingsPageEventToAmplitude(source: SETTINGS_PAGE_EVENTS, type?) {
        const eventProperties = {
            source: source,
            ...(type) && {type: type}
        }
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.SETTING_CUSTOM_FONTS, eventProperties);
    }
    routeToChangePlan() {
        const queryParams = {
            source: 'customfont',
            orgID: this.authService.getCurrentOrgId()
        };
        const url = this.router.createUrlTree(['/account/upgrade/'], { queryParams }).toString();
        window.open(url, '_blank');
    }
    saveFontFile(file: File, result: any) {
        this.customFontService.uploadFile(file, file.type.split('/')[0], null, null, null, result).pipe().subscribe(res => {

            this.messageModal.show('Successfully added fonts to your dashboard', 'success', 0);
            this.settingsPageEventToAmplitude(SETTINGS_PAGE_EVENTS.CUSTOM_FONTS, 'Successful');
            this.getFonts();

        }, error => {
            this.messageModal.show('Something went wrong', 'danger', 0);
            console.log('Here error');
            this.settingsPageEventToAmplitude(SETTINGS_PAGE_EVENTS.CUSTOM_FONTS, 'Unsuccessful');
        })
    }
    getFonts() {
        const pageNumber = 1;
        const pageSize = 20;
        const params = { content_type: 'font' };

        this.customFontService.getList(pageNumber, pageSize, params).subscribe(res => {
            this.SetFontsList(res.objects);
        });

    }

    SetFontsList(fontsList: any[]) {
        const transformedFonts = fontsList.map((font) => {
            const dataItems = {
                title: {
                    type: 'single',
                    class: '',
                    mobile: true,
                    value: {
                        text: font.family
                    }
                },
                labels: {
                    type: 'single',
                    value: {
                        text: font.style
                    }
                }
            };

            const toolbarIcons = {
                desktop: [
                    {
                        class: 'fas fa-trash-alt',
                        color: '#FF697E',
                        tooltip: 'Delete',
                        event: {
                            type: 'link',
                            operation: 'delete'
                        },
                        hidden: false
                    }
                ],
                mobile: [
                    {
                        class: 'fas fa-trash-alt',
                        color: '#FF697E',
                        tooltip: 'Delete',
                        event: {
                            type: 'link',
                            operation: 'info'
                        }
                    }
                ]
            };

            return {
                dataObject: font,
                dataItems: dataItems,
                toolbarIcons: toolbarIcons
            };
        });

        this.customFontsData.data = transformedFonts;
        if (this.customFontsData.data.length === 0) {
            this.customFontsData.data = [
                {
                    dataObject: '0',
                    dataItems: {
                        title: {
                            type: 'single',
                            class: '',
                            mobile: true,
                            value: {
                                text: 'No fonts added'
                            }
                        },
                        labels: {
                            type: 'single',
                            value: {
                                text: ''
                            }
                        }
                    },
                    toolbarIcons: {
                        desktop: [
                            {
                                class: '',
                                color: '#FF697E',
                                tooltip: '',
                                event: {
                                    type: 'link',
                                    operation: 'delete'
                                },
                                hidden: false
                            }
                        ],
                        mobile: [
                            {
                                class: '',
                                color: '#FF697E',
                                tooltip: '',
                                event: {
                                    type: 'link',
                                    operation: 'delete',
                                    output: 'function()'
                                }
                            }
                        ]
                    }
                }
            ];
        }
    }




    toggleDBCSetting(event: boolean, setting: string) {
        this.dbcSetting[setting] = event;
        if (event && setting === 'layout' && !this.dbcTemplatesExists) {
            this.messageModal.show('You currently have 0 DBC templates in this organization, You should keep layouts enabled', 'warning');
            setTimeout( () => {
                this.dbcSetting[setting] = false
            }, 3000)
        }
    }

    onSave() {
        this.dbcSettingService.put(this.dbcSetting, this.dbcSetting['organization'], `?organization=${this.authService.getCurrentOrgId()}`).subscribe(res => {
            this.messageModal.show('Saved successfully', TOAST_TYPES.SUCCESS, 3000);
        }, error => {
            this.messageModal.show('Error saving settings', TOAST_TYPES.DANGER, 3000);
        });
    }

    enforceWalletPass(event: boolean) {
        this.walletPassTemplateSetting.next(event);
    }

    changeEvent(values = []) {
        this.dbcSetting.custom_labels = values.map(value => Utils.capitalizeFirstLetter(value.label))
    }

    onSaveClick() {
        this.saveContentChange.next(true);
    }

    ngOnDestroy(): void {
        if (this.saveContentChange) {
            this.saveContentChange.unsubscribe()
        }
    }

    saveWalletPassChanges(event) {
        this.overlayService.isLoading(true);
        const currOrg = this.authService.getCurrentOrganization();
        const body = {
            name: currOrg.name,
            wallet_pass_template: currOrg.wallet_pass_template
        }
        body.wallet_pass_template.enforce_wallet_pass_template = event;
        const currentOrgId = this.authService.getCurrentOrgId();
        this.walletpassTemplateService.put(body, currentOrgId, `?organization=${currentOrgId}`).subscribe(() => {
            this.walletPassEnforced = event;
            this.overlayService.isLoading(false);
        }, error => {
            this.messageModal.show('Error saving, please contact support.', 'danger');
            this.walletPassEnforced = !event;
            this.overlayService.isLoading(false);
            console.log(error);
        });
    }

    upsellCustomFonts() {
        if (!this.isMobileScreenSize) {
            this.upsellService.showV2({feature: 'custom_fonts', source: 'customfont'});
        } else {
            this.upsellService.showUpsellModalOnMobile({feature: 'custom_fonts', source: 'customfont'});
        }
    }

}
