<div class="bx--grid padding-for-cards-list-page my-4 pl-0 pr-0 pb-2" style="max-width: -webkit-fill-available;">

    <div class="bx--row">
        <div class="bx--col-xlg-10 bx--col-lg-8 bx--col-md-4">
            <beaconstac-title
                [title]="(!this.cardType ? 'My Cards ' : 'Organization Cards ') +
                (this.digitalBusinessCards ? ('(' + this.digitalBusinessCards.totalCount + ')') : '')"
                [subtitle]="!this.cardType ? 'Create and manage your personal cards here' : 'Manage your organization cards here'"
            ></beaconstac-title>
        </div>
        <div class="bx--col-xlg-6 bx--col-lg-8 bx--col-md-4 dbc-mobile-buttons" *ngIf="hasWriteAccess && !isMobileScreenSize">
            <beaconstac-button *ngIf="isAdmin"
            [type]="BUTTON_TYPES.PRIMARY"
            [style]="BUTTON_STYLES.STROKE"
            [label]="'Bulk Create'"
            [width]="'max-content'"
            (onClick)="hasDbcBulkCreate ? handleBulkCardCreateCta() : openUpsellModal()"
            [useCustomImage]="!hasDbcBulkCreate"
            [icon]="!hasDbcBulkCreate ? '../assets/logo/crown-solid.svg' : 'fa fa-solid fa-list-alt'"
            ></beaconstac-button>
            <button id="dbc-create-button" class="btn create-dbc-btn text-white border-0 float-right d-flex flex-row justify-content-center align-items-center" (click)="createDBC();"
                style="max-height: 48px; width: 215px;">
                <i class="fa fa-plus" aria-hidden="true"></i>Create Card</button>
        </div>
        <div class="modal-wrapper bg-white" *ngIf="hasWriteAccess && isMobileScreenSize">
            <beaconstac-button *ngIf="isAdmin"
            [type]="BUTTON_TYPES.SECONDARY"
            [style]="BUTTON_STYLES.STROKE"
            [label]="'Bulk Create'"
            [width]="'157px'"
            (onClick)="hasDbcBulkCreate ? handleBulkCardCreateCta() : openUpsellModal()"
            [useCustomImage]="!hasDbcBulkCreate"
            ></beaconstac-button>
            <button id="dbc-create-button" class="btn create-card-dbc-btn text-white border-0 float-right d-flex flex-row justify-content-center align-items-center primary-cta" (click)="createDBC();" style="width: 157px !important;">
                Create Card</button>
        </div>
    </div>

    <app-bulk-action *ngIf="selectedCards?.length && !isMobileScreen" [bulkActionProduct]="BULK_ACTION_PRODUCT.CARDS" [selectedVcardLength]="selectedCards.length" [vCardTotalCount]="digitalBusinessCards.totalCount"
                     [(isAllVcardSelected)]="isAllCardsSelected" (resetBulkOperation)="resetBulkOperations()"
                     [hasFilterApplied]="isListFiltered"
                     (selectAllvCards)="addSelectedCardsToList()" (setBulkOperations)="setSelectedBulkOperation($event)"
    ></app-bulk-action>

    <app-bulk-action *ngIf="selectedCards?.length && isMobileScreen" [bulkActionProduct]="BULK_ACTION_PRODUCT.MOBILE_CARDS" [selectedVcardLength]="selectedCards.length" [vCardTotalCount]="digitalBusinessCards.totalCount"
                     [(isAllVcardSelected)]="isAllCardsSelected" (resetBulkOperation)="resetBulkOperations()"
                     [hasFilterApplied]="isListFiltered" [showBulkIconsLabel]="!isMobileScreen"
                     (selectAllvCards)="addSelectedCardsToList()" (setBulkOperations)="setSelectedBulkOperation($event)"
    ></app-bulk-action>

    <div *ngIf="!selectedCards.length && (digitalBusinessCards?.totalCount > 0 || (digitalBusinessCards?.totalCount === 0 && isListFiltered))"
        class="bg-white p-4 mt-4 filter-container-box">
        <beaconstac-filter-products
            [filterOptions]="CARDS_FILTER_OPTIONS"
            [filterQueryParams]="queryParams"
            [qrCodeCount]="digitalBusinessCards?.totalCount"
            (filterChange)="onFilterChange($event)"
            [dbcTemplatesList]="dbcTemplatesList"
            [productType]="PRODUCT_TYPES.DBC"
            [tagList]="tagList"
            [mobileDrawer]="true"
        ></beaconstac-filter-products>
    </div>

    <div class="mb-4" *ngIf="isListFiltered || (digitalBusinessCards && digitalBusinessCards.totalCount !== 0)">

        <beaconstac-table
            id = 'cards-list-beaconstac-table'
            [showCheckbox]="true"
            [currentPage]="pageAttributes.pageNumber"
            [showPages]="true"
            [dropdowns]="this.selectedCards.length > 0 ? [] : []"
            [pages]="pages"
            [currentPageSize]="pageSizeMapping[pageAttributes.pageSize.toString()]"
            [pagination]="digitalBusinessCards?.pageCount"
            [totalResults]="digitalBusinessCards?.totalCount"
            [searchBox]="false"
            [showToolBar]="true"
            (sortColumnChange)="sortTable($event)"
            (onLinkClick)="redirectToPreview($event)"
            [isLinkPrimary]="false"
            [isAscending]="pageAttributes.isAscending"
            [tableData]="tableData"
            (onDropdownValueChange)="handleDropdownChange($event)"
            (onPageChange)="onPageAttributesChange($event)"
            (onToolbarClick)="handleToolBarClick($event, 'toolbar')"
            (onToolbarDropdownChange)="handleToolBarClick($event, 'dropdown')"
            (onMobileToolbarDropdownChange)="handleToolBarClick($event, 'dropdown')"
            (onShowPagesChange)="changePageSize(($event).value)"
            (onSearchBoxChange)="handleSearchBoxChange($event)"
            [emptyStateUrl]="'../../../assets/img/empty-search-state.png'"
            [showInbuiltEmptyState]="true"
            (checkboxStateEmitter)="checkboxEventHandler($event)"
            (allCheckboxStateEmitter)="selectAllCheckBoxEventHandler($event)"
            [searchBoxWidth]="'430px'"
            [searchPlaceHolder]="searchPlaceholder"
            [circularProfileImage]="true"
            [emptyStateType]="EMPTY_STATE_TYPES.ACTION_BUTTON_SUPPORT"
            [emptyStateTitle]="'Uh-ho! No cards found'"
            [emptyStateDescription]="'Double-check your spellings, apply different filters, and try again.'"
            (emptyStateButtonClick)="resetApppliedFilters()"
            (onCellClick)="handleCellClick($event)"
        ></beaconstac-table>
    </div>

    <div *ngIf="!isListFiltered && digitalBusinessCards && digitalBusinessCards.totalCount === 0" class="my-4">
    <div class="card" style="border: 1px solid #E4E5E7; min-height: 80vh; box-shadow: none">
        <div style="height: 88px"></div>
        <div style="height: 48px; background: #FAFAFA; border-top: 1px solid #E4E5E7;
  border-bottom: 1px solid #E4E5E7;"></div>
        <div class="card-body" style="text-align: center; display: flex; flex-direction: column">
    <span>
      <img class="bx--sm-above-height-400 bx--sm-below-height-200" src="../../../assets/img/dbc-empty-state.svg">
    </span>
    <span class="mt-1"
          style="font-style: normal; font-weight: 400; font-size: 15px; line-height: 18px;
          letter-spacing: 0.5px; color: #979797;">
      No cards have been created yet.
        <span *ngIf="this.cardType === DBCCardTypes.MY_CARDS">
            Create your first card now!
        </span>
    </span>
            <span class="mt-3" *ngIf="hasWriteAccess && cardType === DBCCardTypes.MY_CARDS">
      <beaconstac-button
          [type]="BUTTON_TYPES.PRIMARY"
          [style]="BUTTON_STYLES.FILLED"
          (onClick)="redirectToAddDBC()"
          [label]="'CREATE A CARD'"
      ></beaconstac-button>
    </span>
        </div>
    </div>
</div>

</div>

<div bsModal #sideModalOnInfoClick="bs-modal" (onShow)="!isNewDigitalBusinessCard ? setActionsItems(selectedDigitalBusinessCard) : ''"
     [style.height.px]="sideModalHeight" class="modal fade" role="dialog"
     [config]="{'backdrop':'static'}" *ngIf="isMobileScreen">
     <div class="modal-dialog wallet-pass-modal modal-dialog-scrollable modal-dialog-bottom">
        <div class="modal-content">
            <div class="modal-body py-0">
                <div class="bx--row bg-white" style="display: flex; flex-direction: column; bottom: 0; width: 100%;margin-bottom: 0px !important;border: none;" [ngStyle]="!showPreviewFrame ? {'position':'fixed'} : ''">
                    <div class="align-items-center justify-content-center bg-white mb-5">
                        <div class="">
                            <h4 class="">
                                <i id="close-info-modal" class="fa fa-times" style="color: #5E6980; font-size: 20px;padding: 10px 20px 0px 0px;display: flex; justify-content: flex-end;" (click)="sideModalOnInfoClick.hide(); showPreviewFrame = false; showDetails = false"></i>
                            </h4>
                        </div>

                        <div style="font-size: 24px; font-weight: 600; line-height: 28.15px; display:flex; justify-content: center;">Congratulations! </div>
                        <div style="font-size: 24px; font-weight: 400; line-height: 28.15px; display:flex; justify-content: center;">Your card is ready.</div>

                        <div class="d-flex justify-content-center" style="margin-top: 42px;">
                            <div class="qrcode-preview-btngroup d-flex justify-content-between align-items-center p-1" style="width: 327px; height: 52px;">
                                <input type="radio" id="first-2" class="first-mobile" name="section-2" value="0" [checked]="!showPreviewFrame">
                                <label class="d-flex align-items-center justify-content-center" for="first-2" (click)="togglePreview(true)" [ngClass]="!showPreviewFrame? 'active-preview': ''" style="width: 150px; height: 50px;">
                                    <i class="fas fa-share-alt" style="font-size: 16px; color: #16212f; margin: 4px" ></i>
                                    <span style="font-size: 15px; font-weight: 400;">Share</span>
                                </label>
                                <input type="radio" id="second-2" class="second-mobile" name="section-2" value="1" [checked]="showPreviewFrame">
                                <label class="d-flex align-items-center justify-content-center" for="second-2" (click)="togglePreview(false)" [ngClass]="showPreviewFrame? 'active-preview': ''" style="width: 150px; height: 50px;">
                                    <i class="fa fa-eye" style="font-size: 16px; color: #16212f; margin: 4px"></i>
                                    <span style="font-size: 15px; font-weight: 400;">Preview</span>
                                </label>
                                <div class="slider" style="width: 150px; height: 42px;"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!showPreviewFrame">
                        <!--Share as a link-->
                        <div class="" style="margin: 0px 12px 14px 12px;">
                            <div class="subheading-mobile pl-3">Share as a link</div>
                            <div class="d-flex flex-row pt-4 pr-3">
                                <div class="link-image">
                                    <i class="fa fa-link" style="width: 5%; height: 24px; color: #C0C5CB; margin-left: 1%; margin-right: 20px;"></i>
                                </div>
                                <div style="width: 100%;">
                                    <beaconstac-textfield
                                        [placeholder]="selectedDigitalBusinessCard?.url.length > 39 ? (selectedDigitalBusinessCard?.url | sliceValues:39).slice(8) + '...' : selectedDigitalBusinessCard?.url.slice(8)"
                                        [disabled]="true"
                                        [width]="'100%'"
                                        [maxWidth]="'100%'"
                                       >
                                    </beaconstac-textfield>
                                </div>
                            </div>

                            <div class="subheading-mobile pl-3 pt-4 mb-3">Share with a QR Code</div>

                            <div class="d-flex p-3" style="width: 100%">
                                <div style="max-width: 87px; max-height: 87px; border: 1px solid #E7E7E7; display: flex; justify-content: center;align-items: center;margin-top: 5px;">
                                <div style="max-width: 81px; max-height: 81px;" [ngClass]="selectedDigitalBusinessCard?.template?.frameStyle === QRCodeFrame.CIRCULAR ? 'circular-qr-code-frame' : 'qr-code-container'" [innerHTML]="generatedDBCQRcode | getSanitizedHtml"></div>
                                </div>
                                <div class="d-flex flex-column ml-2" style="width: 100%;">
                                    <div class="pt-2 pl-2" style="width: auto; height: 30px;font-weight: 400;font-size: 13px; line-height: 15.25px; letter-spacing: 0.25px;color: #5E6980;">
                                        Scanning this QR Code will open your card
                                    </div>

                                    <div class="bx--row pt-3 pl-1 mt-2">
                                        <div class="bx--col bx--col-sm-4">
                                        <beaconstac-dropdown class="custom-dropdown"
                                                            [style]="DROPDOWN_STYLES.STROKE"
                                                            [type]="DROPDOWN_TYPES.PRIMARY"
                                                            [alignment]="DROPDOWN_ALIGNMENT.CENTER"
                                                            [title]="'Download'"
                                                            [optionLabelField]="'name'"
                                                            [optionValueField]="'value'"
                                                            [showCaret]="true"
                                                            [listItems]="qrDownloadOptions"
                                                            (valueChange)="downloadQRtype($event);"
                                        ></beaconstac-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="subheading-mobile pl-3">Share via Wallet pass</div>
                        </div>

                        <div style="display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-right: 23px;">
                            <button class=" ml-4 mb-4" (click)="openGoogleWalletPassModal()" style="outline: none;border: none; background-color: #fff;">
                            <img style="width: 145px; height: 41px;" src="https://ci3.googleusercontent.com/meips/ADKq_NY95DeQ8k7HNA1LIQAGubm1MVrBGidKQqY3bXNl8r0MxQZxaar_0JPbmnNxg4fKdLfDVEAgtBOnPHeSM_g9P71h8tD567alB_2uoY1YsuWAVBaNpzLM_YDxVczeUpeEz6OhcNnEhg=s0-d-e1-ft#https://q.eddy.pro/static/images/add_wallet_helper/add_to_google_wallet_logo.png">
                            </button>

                            <button class=" ml-2 mb-4" (click)="openAppleWalletPassModal()" style="outline: none;border: none;background-color: #fff;">
                            <img style="width: 145px; height: 41px;border-radius: 60px;" src="https://ci3.googleusercontent.com/meips/ADKq_NZMV97EmNsuge2PtKofKAw55Klj1_XKon_eWUeLfLCeu9_rKRT8U4H8RTeDRVIaUtb5iQ7XbCjpFDDm1zSakRUQhNzUd2LRWZRnnl3p0Ims-ZEHSIY4NAay0XYo3UZhVoXgKi4P=s0-d-e1-ft#https://q.eddy.pro/static/images/add_wallet_helper/add_to_apple_wallet_logo.png">
                            </button>
                        </div>
                        <!-- new design -->

                    </div>

                    <div class=""*ngIf="showPreviewFrame">
                        <!--dbc preview and link-->
                        <div class="d-flex flex-column justify-content-center align-items-center" style="row-gap: 30px; margin-top: 19px; margin-bottom: 14px">
                            <div class="d-flex justify-content-center align-items-center" id="customPreview" [@toggleScale]="!showDetails ? 'big' :'small'">
                                <iframe class="preview-iframe" style="box-shadow: 0px 8px 25px 0px #81848826;" #dbcPreview></iframe>
                            </div>
                            <div class="d-flex" style="column-gap: 12px">
                                <span class="dbc-url"  (click)="redirectToPreview(selectedDigitalBusinessCard)">
                                    {{selectedDigitalBusinessCard?.url.slice(8)}}
                                </span>
                                <i class="far fa-copy copy-icon" (click)="copyText(selectedDigitalBusinessCard?.url)"></i>
                            </div>
                        </div>
                        <!--section division line-->
                        <div class="bx--row">
                            <div class="bx--col px-0">
                                <hr style="border-top: 1px dashed #DBDFE3;">
                            </div>
                        </div>
                        <!--view more details-->
                        <div class="my-2 mx-3">
                            <div class="d-flex justify-content-between align-items-center my-2">
                                <span class="dbc-details-heading" (click)="showDetails = !showDetails;">View more details</span>
                                <i class="fa" style="color: #5E6980; font-size: 20px" (click)="showDetails = !showDetails;" [ngClass]="showDetails ? 'fa-angle-up': 'fa-angle-down'"></i>
                            </div>
                            <div *ngIf="showDetails" class="my-2">
                                <p class="template-parent my-2" *ngIf="selectedDigitalBusinessCard?.is_template">
                                    <beaconstac-message message="This is a template card"></beaconstac-message>
                                </p>
                                <p class="dbc-detail-value my-2">
                                    <span class="dbc-detail-key">Views :</span> {{selectedDigitalBusinessCard?.views.toLocaleString() || 0}}
                                    <span class="" (click)="navigateToAnalytics(selectedDigitalBusinessCard)">
                                    <beaconstac-tooltip
                                        elementClass="fas fa-chart-bar"
                                        [content]="'Analytics'"
                                        [tooltipPosition]="TOOLTIP_POSITION.TOP"
                                        [caretPosition]="CARET_POSITION.LEFT"
                                        style="margin-top: 0 !important; cursor: pointer !important;"
                                        [color]="'#2595ff'"
                                    ></beaconstac-tooltip>
                                    </span>
                                </p>
                                <p class="dbc-detail-value my-2">
                                    <span class="dbc-detail-key">Owner :</span>
                                    {{truncateEmail(selectedDigitalBusinessCard?.card_owner.email, 'owner') || 'NA' }}
                                </p>
                                <p class="dbc-detail-value my-2">
                                    <span class="dbc-detail-key">Created by :</span>
                                    {{truncateEmail(selectedDigitalBusinessCard?.meta['creator_email'], 'creator') || 'NA' }}
                                </p>
                                <div class="my-2 d-flex" [ngClass]="selectedDigitalBusinessCard?.tags_data.length ? 'flex-column' : 'flex-row align-items-center'">
                                    <span class="dbc-detail-key">Lables :</span>
                                    <div class="flex-wrap d-flex  mt-2" *ngIf="this.selectedDigitalBusinessCard?.tags_data.length">
                                        <div *ngFor="let tag of this.selectedDigitalBusinessCard?.tags_data" [ngStyle]="{'background-color' : tag.color}" class="px-2 mb-2 mr-2 border-radius-2px" style="color: #FAFAFA; height: 24px;" >{{tag.name}}</div>
                                    </div>
                                    <div *ngIf="!this.selectedDigitalBusinessCard?.tags_data.length" class="pl-2 none-label-text">None</div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer" style="padding: 24px;" *ngIf="isMobileScreen">
                            <span>
                            <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                                (onClick)="sideModalOnInfoClick.hide(); isNewDigitalBusinessCard = false;"
                                                [label]="'Close'"
                            ></beaconstac-button>
                            </span>

                            <span class="ml-3 custom-actions">
                            <beaconstac-dropdown class="custom-dropdown-inset"
                                [style]="DROPDOWN_STYLES.STROKE"
                                [type]="DROPDOWN_TYPES.PRIMARY"
                                [alignment]="DROPDOWN_ALIGNMENT.CENTER"
                                [title]="'Actions'"
                                [optionLabelField]="'name'"
                                [optionValueField]="'value'"
                                [showCaret]="true"
                                [listItems]="infoModalActionsOptions"
                                (valueChange)="handleToolBarClick($event, 'side-modal');"
                            ></beaconstac-dropdown>
                            </span>
                        </div>
                    </div>

                    <!-- preview -->

                    <!-- prebiew ends -->
                </div>
            </div>
        </div>
    </div>
</div>


<div bsModal #sideModalOnInfoClick="bs-modal" (onShow)="!isNewDigitalBusinessCard ? setActionsItems(selectedDigitalBusinessCard) : ''"
     [style.height.px]="sideModalHeight" class="modal modal-fullscreen fade" role="dialog"
     [config]="{'backdrop':'static'}" *ngIf="!isMobileScreen">
    <div class="modal-dialog" [@flyInOut] role="document">
        <div class="modal-content" style="min-height: 500px;">
            <!--Modal Body-->
            <div class="modal-body">
                <!--heading and cross-->
                <div class="d-flex justify-content-between align-items-center" style="margin-top: 7px; margin-bottom: 42px;">
                        <div class="d-flex flex-grow-1">
                            <h4 class="d-flex justify-content-center mx-auto" style="font-size: 20px;">{{isNewDigitalBusinessCard
                                ? 'Your card is ready' : selectedDigitalBusinessCard?.name.length > 20 ?
                                    (selectedDigitalBusinessCard?.name | sliceValues:20) + '...' : selectedDigitalBusinessCard?.name}}
                            </h4>
                        </div>
                        <div class="">
                            <h4 class="">
                                <i id="close-info-modal" class="fa fa-times" style="color: #5E6980; font-size: 20px;" (click)="sideModalOnInfoClick.hide(); showPreviewFrame = false; showDetails = false"></i>
                            </h4>
                        </div>
                </div>
                <!--Switcher-->
                <div class="d-flex justify-content-center" style="margin-top: 42px;">
                    <div class="qrcode-preview-btngroup d-flex justify-content-between align-items-center p-1">
                        <input type="radio" id="first-2" class="first" name="section-2" value="0" [checked]="!showPreviewFrame">
                        <label class="d-flex align-items-center justify-content-center" for="first-2" (click)="togglePreview(true)" [ngClass]="!showPreviewFrame? 'active-preview': ''">
                            <i class="fas fa-share-alt" style="font-size: 16px; color: #16212f; margin: 4px" ></i>
                            <span style="font-size: 15px; font-weight: 400;">Share</span>
                        </label>
                        <input type="radio" id="second-2" class="second" name="section-2" value="1" [checked]="showPreviewFrame">
                        <label class="d-flex align-items-center justify-content-center" for="second-2" (click)="togglePreview(false)" [ngClass]="showPreviewFrame? 'active-preview': ''">
                            <i class="fa fa-eye" style="font-size: 16px; color: #16212f; margin: 4px"></i>
                            <span style="font-size: 15px; font-weight: 400;">Preview</span>
                        </label>
                        <div class="slider"></div>
                    </div>
                </div>
                <!--Share-->
                <div *ngIf="!showPreviewFrame">
                    <!--Share as a link-->
                    <div class="" style="margin: 48px 12px 14px 12px;">
                        <div class="subheading">Share as a link</div>
                        <div class="d-flex justify-content-between" style="margin-top: 24px">
                            <span class="d-flex align-items-center" style="color: #5E6980; font-size: 14px">
                                <i class="fa fa-link" style="font-size: 20px; color: #C0C5CB; margin-right: 10px"></i>
                                {{selectedDigitalBusinessCard?.url.length > 39 ? (selectedDigitalBusinessCard?.url | sliceValues:39).slice(8) + '...' : selectedDigitalBusinessCard?.url.slice(8)}}
                            </span>
                            <span class="" style="color:#2595ff; cursor: pointer" (click)="copyText(selectedDigitalBusinessCard?.url)">
                                <i class="far fa-copy" style="font-size:16px;"></i>
                            </span>
                        </div>
                    </div>
                    <!--section division line-->
                    <div class="bx--row">
                        <div class="bx--col px-0">
                            <hr style="border-top: 1px dashed #DBDFE3;">
                        </div>
                    </div>
                    <!--Share as a wallet pass-->
                    <div class="d-flex flex-column" style="margin: 8px 12px 8px 12px">
                        <div class="subheading my-2">Share via Wallet pass</div>
                        <div class="my-2">
                            <span class="d-flex align-items-center" style="color:#2595ff; cursor: pointer" (click)="openAppleWalletPassModal()">
                                <i class="fab fa-apple" style="font-size:20px; margin-right: 14px"></i>
                                Add to Apple Wallet Pass
                            </span>
                        </div>
                        <div class="my-2">
                            <span class="d-flex align-items-center" style="color:#2595ff; cursor: pointer" (click)="openGoogleWalletPassModal()">
                                <i class="fas fa-wallet" style="font-size:18px; margin-right: 10px"></i>
                                Add to Google Wallet Pass
                            </span>
                        </div>
                    </div>
                    <!--section division line-->
                    <div class="bx--row">
                        <div class="bx--col px-0">
                            <hr style="border-top: 1px dashed #DBDFE3;">
                        </div>
                    </div>
                    <!--Share with a QR Code-->
                    <div class="d-flex flex-column" style="margin: 8px 12px">
                        <div class="subheading my-2">Share with a QR Code</div>
                        <div class="d-flex flex-row justify-content-between align-items-center my-2">
                            <div [ngClass]="selectedDigitalBusinessCard?.template?.frameStyle === QRCodeFrame.CIRCULAR ? 'circular-qr-code-frame' : 'qr-code-container'" [innerHTML]="generatedDBCQRcode | getSanitizedHtml"></div>
                            <div class="d-flex flex-column justify-content-center align-items-center" style="margin: 0px 8px">
                                <div class="qrScanGuidetext">Scanning this QR code will open your card</div>
                                <div class="d-flex justify-content-center dropdown-parent" style="width: 100%">
                                    <beaconstac-dropdown class=""
                                                         [style]="DROPDOWN_STYLES.STROKE"
                                                         [type]="DROPDOWN_TYPES.PRIMARY"
                                                         [alignment]="DROPDOWN_ALIGNMENT.CENTER"
                                                         [title]="'Download'"
                                                         [optionLabelField]="'name'"
                                                         [optionValueField]="'value'"
                                                         [showCaret]="true"
                                                         [listItems]="qrDownloadOptions"
                                                         (valueChange)="downloadQRtype($event);"
                                    ></beaconstac-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=""*ngIf="showPreviewFrame">
                    <!--dbc preview and link-->
                    <div class="d-flex flex-column justify-content-center align-items-center" style="row-gap: 30px; margin-top: 19px; margin-bottom: 14px">
                        <div class="d-flex justify-content-center align-items-center" id="customPreview" [@toggleScale]="!showDetails ? 'big' :'small'">
                            <iframe class="preview-iframe" style="box-shadow: 0px 8px 25px 0px #81848826;" #dbcPreview></iframe>
                        </div>
                        <div class="d-flex" style="column-gap: 12px">
                            <span class="dbc-url" (click)="redirectToPreview(selectedDigitalBusinessCard)">
                                {{selectedDigitalBusinessCard?.url.slice(8)}}
                            </span>
                            <i class="far fa-copy copy-icon" (click)="copyText(selectedDigitalBusinessCard?.url)"></i>
                        </div>
                    </div>
                    <!--section division line-->
                    <div class="bx--row">
                        <div class="bx--col px-0">
                            <hr style="border-top: 1px dashed #DBDFE3;">
                        </div>
                    </div>
                    <!--view more details-->
                    <div class="my-2 mx-3">
                        <div class="d-flex justify-content-between align-items-center my-2">
                            <span class="dbc-details-heading" (click)="showDetails = !showDetails;">View more details</span>
                            <i class="fa" style="color: #5E6980; font-size: 20px" (click)="showDetails = !showDetails;" [ngClass]="showDetails ? 'fa-angle-up': 'fa-angle-down'"></i>
                        </div>
                        <div *ngIf="showDetails" class="my-2">
                            <p class="template-parent my-2" *ngIf="selectedDigitalBusinessCard?.is_template">
                                <beaconstac-message message="This is a template card"></beaconstac-message>
                            </p>
                            <p class="dbc-detail-value my-2">
                                <span class="dbc-detail-key">Views :</span> {{selectedDigitalBusinessCard?.views.toLocaleString() || 0}}
                                <span class="" (click)="navigateToAnalytics(selectedDigitalBusinessCard)">
                                   <beaconstac-tooltip
                                       elementClass="fas fa-chart-bar"
                                       [content]="'Analytics'"
                                       [tooltipPosition]="TOOLTIP_POSITION.TOP"
                                       [caretPosition]="CARET_POSITION.LEFT"
                                       style="margin-top: 0 !important; cursor: pointer !important;"
                                       [color]="'#2595ff'"
                                   ></beaconstac-tooltip>
                                </span>
                            </p>
                            <p class="dbc-detail-value my-2">
                                <span class="dbc-detail-key">Owner :</span>
                                {{truncateEmail(selectedDigitalBusinessCard?.card_owner.email, 'owner') || 'NA' }}
                            </p>
                            <p class="dbc-detail-value my-2">
                                <span class="dbc-detail-key">Created by :</span>
                                {{truncateEmail(selectedDigitalBusinessCard?.meta['creator_email'], 'creator') || 'NA' }}
                            </p>
                            <div class="my-2"[ngClass]="selectedDigitalBusinessCard?.tags_data.length ? 'flex-column' : 'flex-row align-items-center'">
                                <span class="dbc-detail-key">Lables :</span>
                                <div class="flex-wrap d-flex mt-2" *ngIf="this.selectedDigitalBusinessCard?.tags_data.length">
                                    <div *ngFor="let tag of this.selectedDigitalBusinessCard?.tags_data" [ngStyle]="{'background-color' : tag.color}" class="px-2 mb-2 mr-2 border-radius-2px" style="color: #FAFAFA; height: 24px;" >{{tag.name}}</div>
                                </div>
                                <div *ngIf="!this.selectedDigitalBusinessCard?.tags_data.length" class="none-label-text">None</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Footer-->
            <div class="modal-footer" style="padding: 24px;" *ngIf="!isMobileScreen">
                    <span>
                      <beaconstac-button class="info-close-btn" [type]="BUTTON_TYPES.SECONDARY"
                                         (onClick)="sideModalOnInfoClick.hide(); isNewDigitalBusinessCard = false;"
                                         [label]="'Close'"
                      ></beaconstac-button>
                    </span>

                    <span class="ml-3 custom-actions">
                      <beaconstac-dropdown class="custom-dropdown-inset"
                          [style]="DROPDOWN_STYLES.STROKE"
                          [type]="DROPDOWN_TYPES.PRIMARY"
                          [alignment]="DROPDOWN_ALIGNMENT.CENTER"
                          [title]="'Actions'"
                          [optionLabelField]="'name'"
                          [optionValueField]="'value'"
                          [showCaret]="true"
                          [listItems]="infoModalActionsOptions"
                          (valueChange)="handleToolBarClick($event, 'side-modal');"
                      ></beaconstac-dropdown>
                    </span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isMobileScreen">
    <div bsModal #appleWalletPassModal="bs-modal" class="modal fade" role="form" [config]="{'backdrop':'static'}" style="overflow-y: scroll">
        <div class="modal-dialog wallet-pass-modal" style="width: 800px">
            <div class="modal-content" style="margin-top: 85px">
                <div class="modal-body py-0">
                    <div class="bx--row">

                        <!-- awp: Apple wallet pass -->
                        <div class="bx--col align-items-center justify-content-center awp-preview-cont" [ngClass]="isMobileScreen ? 'd-none' : ''">
                            <beaconstac-apple-wallet-pass-preview
                                [backgroundColor]="appleWalletPassCustomization.backgroundColor"
                                [textColor]="appleWalletPassCustomization.textColor"
                                [designation]="selectedDigitalBusinessCard?.designation"
                                [companyName]="selectedDigitalBusinessCard?.company"
                                [logo]="appleWalletPassCustomization?.logo.url"
                                [firstName]="selectedDigitalBusinessCard?.first_name"
                                [lastName]="selectedDigitalBusinessCard?.last_name"
                                [email]="selectedDigitalBusinessCard?.email_v2[0] ? selectedDigitalBusinessCard?.email_v2[0]['value'] : ''"
                                [profilePhoto]="selectedDigitalBusinessCard?.user_image_url"
                            ></beaconstac-apple-wallet-pass-preview>
                        </div>

                        <div class="bx--col p-4">
                            <h4>Get Apple Wallet Pass</h4>

                            <p [ngClass]="!generatedWalletQRcode ? 'd-none' : ''" class="text-secondary wallet-pass-msg1 pt-2">Customize your Wallet pass to fit your brand.</p>

                            <div class="p-3" style="background-color: #F4F5F7">
                                <div class="awp-customization w-100">
                                    <span [ngClass]="awpCurrentCustomizeOption === AWP_CUSTOMIZE_OPTION.BACKGROUND_COLOR ? 'awp-active-customization' : ''" (click)="updateAWPCustomization(AWP_CUSTOMIZE_OPTION.BACKGROUND_COLOR)">Background</span>
                                    <span [ngClass]="awpCurrentCustomizeOption === AWP_CUSTOMIZE_OPTION.TEXT_COLOR ? 'awp-active-customization' : ''" (click)="updateAWPCustomization(AWP_CUSTOMIZE_OPTION.TEXT_COLOR)">Text</span>
                                    <span [ngClass]="awpCurrentCustomizeOption === AWP_CUSTOMIZE_OPTION.LOGO_IMG ? 'awp-active-customization' : ''" (click)="updateAWPCustomization(AWP_CUSTOMIZE_OPTION.LOGO_IMG)">Logo</span>
                                </div>
                                <div class="pt-3">
                                    <div *ngIf="awpCurrentCustomizeOption === AWP_CUSTOMIZE_OPTION.BACKGROUND_COLOR">
                                        <beaconstac-color-picker
                                            [label]="true"
                                            [title]="'Background color'"
                                            [(color)]="appleWalletPassCustomization.backgroundColor"
                                            [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                                            (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_BACKGROUND)"
                                            [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                                            [cpPosition]="'bottom'"
                                            [id]="'awp-background-color'"
                                        ></beaconstac-color-picker>
                                    </div>

                                    <div *ngIf="awpCurrentCustomizeOption === AWP_CUSTOMIZE_OPTION.TEXT_COLOR">
                                        <!-- Text Color picker -->
                                        <beaconstac-color-picker
                                            [label]="true"
                                            [title]="'Text color'"
                                            [(color)]="appleWalletPassCustomization.textColor"
                                            [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                                            (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_FOREGROUND)"
                                            [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                                            [cpPosition]="'bottom'"
                                            [id]="'awp-text-color'"
                                        ></beaconstac-color-picker>
                                    </div>

                                    <div *ngIf="awpCurrentCustomizeOption === AWP_CUSTOMIZE_OPTION.LOGO_IMG">
                                        <div class="awp-company-logo-desc d-flex justify-content-between cursor-pointer pb-2">
                                            <div class="font-fs-13">Change Wallet pass logo</div>
                                            <div>
                                                <beaconstac-tooltip
                                                    #tooltip
                                                    [elementClass]="'fas fa-info-circle'"
                                                    [color]="'#2595FF'"
                                                    [content]="'For best results, use a circular logo. Avoid transparent background and test the logo against the background color of your pass.'"
                                                    [width]="'256px'"
                                                    [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                                    [caretPosition]="CARET_POSITION.RIGHT"
                                                    [image]="'../../../assets/img/Guidelines.png'"
                                                ></beaconstac-tooltip>
                                                <span class="pl-1 text-primary"><u>Guidelines</u></span>
                                            </div>
                                        </div>

                                        <beaconstac-upload
                                            #uploadAWPLogoFile
                                            [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                                            [acceptTypes]="['image/svg+xml','image/png', 'image/jpeg']"
                                            [maxSize]="5e6"
                                            [custom]="'213 x 67 px logo size, PNG & JPEG supported'"
                                            [overrideClick]="true"
                                            [width]="'100%'"
                                            [height]="'150px'"
                                            (onClick)="awpLogoImageSelectModal.show()"
                                            [showRemoveButton]="true"
                                            [preview]="appleWalletPassCustomization.logo.url ? appleWalletPassCustomization.logo.url : ''"
                                            (onFileRemove)="onAWPLogoRemoved()">
                                        </beaconstac-upload>

                                    </div>

                                </div>
                            </div>


                            <div class="d-flex qr-modal-container p-2 mt-4">
                                <div style="width: 40%" *ngIf="generatedWalletQRcode" class="awp-qrcode">
                                    <img
                                        style="width: 120px; height: 120px; border-radius: 3px;"
                                        class="p-2 bg-white"
                                        *ngIf="generatedWalletQRcode?.length"
                                        [src]="generatedWalletQRcode"
                                        alt="QR Code preview" />
                                </div>
                                <div class="d-flex flex-column justify-content-center pl-md-3">
                                    <p *ngIf="generatedWalletQRcode" class="mb-2 scan-qr-code-title">Scan QR Code</p>
                                    <p class="scan-qr-code-link mb-2">
                                        {{appleWalletPassLink.length > 50 ?
                                        (appleWalletPassLink | sliceValues:50) + '...' : appleWalletPassLink}}
                                    </p>
                                    <p (click)="copyText(this.appleWalletPassLink)" class="text-blue mb-0"><span class="cursor-pointer">Copy URL</span></p>
                                </div>
                            </div>

                            <p style="color: #979797;" class="text-center mt-3 mb-1 font-weight-bold">OR</p>

                            <label style="font-family: Work Sans;font-weight: 400; font-size: 15px; line-height: 24px;font-style: normal; padding-top: 16px;">Send via email to</label>
                            <beaconstac-textfield [text]="appleWalletEmailModel.email"
                                                  (textChange)="handleWalletPassEmail(WALLET_PASS.APPLE_WALLET)"
                                                  [placeholder]="'Enter email address'"
                                                  [disabled]="true"
                                                  [maxWidth]="'100%'">
                            </beaconstac-textfield>

                            <div style="padding-top: 32px">
                              <span class="float-right d-flex flex-row">
                                <span>
                                  <beaconstac-button type="secondary" (onClick)="appleWalletPassModal.hide(); resetAWPCustomizations()" [label]="'Close'"></beaconstac-button>
                                </span>
                                <span style="padding-left: 16px">
                                  <beaconstac-button [disabled]="false" type="primary" (onClick)="sendAppleWalletLink()" [label]="'Send'"></beaconstac-button>
                                </span>
                              </span>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!-- awp preview mobile modal-->
<div *ngIf="isMobileScreen">
    <beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isAWPModalOpen" (onOutsideClick)="isAWPModalOpen=false;resetAWPCustomizations()">
        <div class="awp-preview-container">
            <div style="font-size:24px">Get Apple Wallet Pass</div>

            <div class="text-secondary py-2 ">View your pass or send it to the card owner</div>

            <div class="p-2 qr-modal-container">
                <p class="scan-qr-code-link mb-2">
                        {{appleWalletPassLink.length > 40 ?
                        (appleWalletPassLink | sliceValues:40) + '...' : appleWalletPassLink}}
                </p>
                <p (click)="copyText(this.appleWalletPassLink); isAWPModalOpen = false" class="text-primary mb-0"><span class="cursor-pointer">Copy URL</span></p>
            </div>

            <div class="text-center pb-3 or-text">OR</div>

            <div class="send-email-text-cont">
                <span class="">Send via email to:</span>
                <span class="text-secondary">{{appleWalletEmailModel.email}}</span>
            </div>

            <div class="my-3 d-flex justify-content-center">
                <beaconstac-apple-wallet-pass-preview
                    [backgroundColor]="appleWalletPassCustomization.backgroundColor"
                    [textColor]="appleWalletPassCustomization.textColor"
                    [designation]="selectedDigitalBusinessCard?.designation"
                    [companyName]="selectedDigitalBusinessCard?.company"
                    [logo]="appleWalletPassCustomization?.logo.url"
                    [firstName]="selectedDigitalBusinessCard?.first_name"
                    [lastName]="selectedDigitalBusinessCard?.last_name"
                    [email]="selectedDigitalBusinessCard?.email_v2[0] ? selectedDigitalBusinessCard?.email_v2[0]['value'] : ''"
                    [profilePhoto]="selectedDigitalBusinessCard?.user_image_url"
                ></beaconstac-apple-wallet-pass-preview>
            </div>

            <div class="mb-10 mt-2">
                <beaconstac-button
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.STROKE"
                    [label]="'Customize'"
                    [width]="'100%'"
                    (onClick)="isAWPModalOpen = false; isCustomizeAWPOpen = true;"
                ></beaconstac-button>
            </div>
        </div>

        <div class="modal-wrapper bg-white">
            <beaconstac-button
                [type]="BUTTON_TYPES.SECONDARY"
                [style]="BUTTON_STYLES.STROKE"
                [label]="'Go Back'"
                [width]="'157px'"
                (onClick)="isAWPModalOpen = false; resetAWPCustomizations()"
            ></beaconstac-button>
            <beaconstac-button
                class="ml-2"
                [type]="BUTTON_TYPES.PRIMARY"
                [label]="'Send'"
                [width]="'157px'"
                (onClick)="sendAppleWalletLink(); isAWPModalOpen = false">
            </beaconstac-button>
        </div>

    </beaconstac-mobile-drawer>
</div>

<!-- awp customization mobile modal -->
<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isCustomizeAWPOpen" (onOutsideClick)="isCustomizeAWPOpen=false; resetAWPCustomizations()">
    <section class="awp-customization-container-mob">
        <div style="padding-bottom:80px">
            <p class="text-secondary">Customize your Wallet pass to fit your brand</p>

            <div class="awp-customization-mob">
                <beaconstac-color-picker
                    [label]="true"
                    [title]="'Background color'"
                    [(color)]="appleWalletPassCustomization.backgroundColor"
                    [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                    (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_BACKGROUND)"
                    [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'awp-background-color'"
                ></beaconstac-color-picker>
                <div class="pt-2">
                    <beaconstac-color-picker
                        [label]="true"
                        [title]="'Text color'"
                        [(color)]="appleWalletPassCustomization.textColor"
                        [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                        (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_FOREGROUND)"
                        [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                        [cpPosition]="'bottom'"
                        [id]="'awp-text-color'"
                    ></beaconstac-color-picker>
                </div>

                <div class="d-flex justify-content-between font-fs-13 py-2">
                    <div class="d-flex align-items-center">Change wallet pass logo</div>
                    <div class="d-flex">
                        <i class="fa-info-circle fas" style="color: rgb(37, 149, 255); line-height: 26px;"></i>

                        <div class="pl-1 guidelines-button">
                            <beaconstac-button
                                [type]="BUTTON_TYPES.PRIMARY"
                                [style]="BUTTON_STYLES.TEXT"
                                [label]="'Guidelines'"
                                (onClick)="onGuideLineClick(WALLET_PASS.APPLE_WALLET)"
                            ></beaconstac-button>
                        </div>
                    </div>
                </div>

                <beaconstac-upload
                    #uploadAWPLogoFile
                    [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                    [acceptTypes]="['image/svg+xml','image/png', 'image/jpeg']"
                    [maxSize]="5e6"
                    [custom]="'213 x 67 px logo size, PNG & JPEG supported'"
                    [overrideClick]="true"
                    [width]="'100%'"
                    [height]="'150px'"
                    (onClick)="awpLogoImageSelectModal.show()"
                    [showRemoveButton]="true"
                    [preview]="appleWalletPassCustomization.logo.url ? appleWalletPassCustomization.logo.url : ''"
                    (onFileRemove)="onAWPLogoRemoved()">
                </beaconstac-upload>
            </div>

            <div class="modal-wrapper bg-white">
                <beaconstac-button
                    class="w-100"
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.STROKE"
                    [width]="'100%'"
                    [label]="'Done'"
                    (onClick)="isAWPModalOpen = true; isCustomizeAWPOpen = false;">
                </beaconstac-button>
            </div>
        </div>

    </section>


</beaconstac-mobile-drawer>


<div class="asset-library-container">
    <div bsModal #awpLogoImageSelectModal="bs-modal" class="modal fade " role="dialog" [config]="{'backdrop':'static'}" (onShow)="setupAWPLogoImgSelectModal()">
        <div class="modal-dialog modal-info modal-lg" role="document">
            <div class="modal-content modal-content-kz">

                <div class="modal-header-kz">
                    <h4 class="modal-title">Select an image</h4>
                </div>
                <div class="modal-body modal-body-kz">
                    <div class="form-group">
                        <app-media-gallery #awpLogoImageSelectMediaGallery
                                           [isSelectDisabled]="false"
                                           (mediaSelector)="onAWPLogoImgSelect($event)"
                                           [addMedia]="awpUpdatedMediaLogo"
                                           (uploadToFolderId)="setupAWPLogoUploadPath($event)"
                                           [searchParams]="{'content_type__nin':'image/gif'}"
                                           [isFooterVisible]="true"
                                           [acceptedTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                                           (onClose)="awpLogoImageSelectModal.hide()"
                                           (fileUploaded)="setupAWPLogoImgCropper($event)"
                                           (onSelect)="selectAWPLogoImg()"
                        >
                        </app-media-gallery>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="asset-library-container">
    <app-image-cropper #imageCropperAWPLogoImg *ngIf="awpLogoImgCropperFile" [imageFile]="awpLogoImgCropperFile"
                   (cropperClose)="closeAWPLogoImgCropperModal()" [squareDragMode]="true" [folderId]="awpLogoImgUploadToFolder"
                   (imageUpdated)="updateAWPLogoMedia($event)" [imageAspectRatio]="'1:1'" [showUploadOriginal]="true">
    </app-image-cropper>
</div>

<div *ngIf="!isMobileScreen">
    <div bsModal #googleWalletPassModal="bs-modal" class="modal fade" role="form" [config]="{'backdrop':'static'}" style="margin-top: -20px;height: 900px;">
    <div class="modal-dialog wallet-pass-modal">
        <div class="modal-content">
            <div class="modal-body py-0">
                <div class="bx--row position-relative">
                    <!-- gwp: Google wallet pass -->
                    <div class="bx--col align-items-center justify-content-center gwp-preview-cont">
                        <beaconstac-google-wallet-pass-preview
                        [backgroundColor]="googleWalletPassPreviewColor.backgroundColor"
                        [textColor]="googleWalletPassPreviewColor.textColor"
                        [firstName]="selectedDigitalBusinessCard?.first_name"
                        [lastName]="selectedDigitalBusinessCard?.last_name"
                        [company]="selectedDigitalBusinessCard?.company"
                        [email]="selectedDigitalBusinessCard?.email_v2[0] ? selectedDigitalBusinessCard?.email_v2[0]['value'] : ''"
                        [phone]="selectedDigitalBusinessCard?.phone_v2[0] ? selectedDigitalBusinessCard?.phone_v2[0]['value'] : ''"
                        [logo]="googleWalletPassPreviewLogo.logo_url? googleWalletPassPreviewLogo.logo_url : this.selectedDigitalBusinessCard?.logo_url"
                        [website] = "selectedDigitalBusinessCard?.website_v2[0] ? selectedDigitalBusinessCard?.website_v2[0]['value'] : ''"
                        [designation] = "selectedDigitalBusinessCard?.designation"
                        [heroImage]="googleWalletPassPreviewHeroImage.hero_image_url ? googleWalletPassPreviewHeroImage.hero_image_url : ''"
                        ></beaconstac-google-wallet-pass-preview>
                    </div>

                    <div class="bx--col py-4 d-flex flex-column gwp-content-box">
                        <h4 class="m-0">Get Google Wallet pass</h4>

                        <div style="padding-top:4px" [ngClass]="!generatedWalletQRcode ? 'd-none' : ''" class="text-secondary mb-3 wallet-pass-msg1">Customize your Wallet pass to fit your brand</div>

                        <p [ngClass]="!generatedWalletQRcode ? 'd-none' : ''" class="text-secondary mt-3 wallet-pass-msg2">Copy the URL below or send an email link to the owner to share the wallet pass.</p>

                        <div class="bx--row pt-3 pb-3 tabs-container">
                            <div class="bx--col">
                                <div class="bx--row">
                                    <div class="ml-3 mr-4">
                                        <span
                                        [style]="{'cursor':'pointer','display':'block' , 'color':chooseTabComponent === 1 ? activeTabColour : inactiveTabColour}"
                                        (click)="chooseTabComponent = 1;"
                                        >Background</span>
                                        <span style="display: block;">
                                            <hr
                                            [style]="{
                                                'margin-top':'8px',
                                                'margin-bottom':'0px',
                                                'background-color': chooseTabComponent === 1 ? activeTabColour : inactiveTabHrColour,
                                                'height': chooseTabComponent === 1 ? '2px' : '0.5px',
                                                'border-top-left-radius': chooseTabComponent === 1 ? '5px' : '0',
                                                'border-top-right-radius': chooseTabComponent === 1 ? '5px' : '0',
                                                'border-bottom-left-radius': '0',
                                                'border-bottom-right-radius': '0'
                                            }"
                                            >
                                        </span>
                                    </div>
                                    <div class="ml-2 mr-4">
                                        <span
                                        [style]="{'cursor':'pointer', 'display':'block' , 'color':chooseTabComponent === 2 ? activeTabColour : inactiveTabColour}"
                                        (click)="chooseTabComponent = 2;"
                                        >Logo</span>
                                        <span style="display: block;" >
                                            <hr
                                            [style]="{
                                                'margin-top':'8px',
                                                'margin-bottom':'0px',
                                                'background-color': chooseTabComponent === 2 ? activeTabColour : inactiveTabHrColour,
                                                'height': chooseTabComponent === 2 ? '2px' : '0.5px',
                                                'border-top-left-radius': chooseTabComponent === 2 ? '5px' : '0',
                                                'border-top-right-radius': chooseTabComponent === 2 ? '5px' : '0',
                                                'border-bottom-left-radius': '0',
                                                'border-bottom-right-radius': '0'
                                            }"
                                            >
                                        </span>
                                    </div>
                                    <div class="ml-2" >
                                        <span
                                        [style]="{'cursor':'pointer','display':'block' , 'color':chooseTabComponent === 3 ? activeTabColour : inactiveTabColour}"
                                        (click)="chooseTabComponent = 3;"
                                        >Hero Image</span>
                                        <span style="display: block;">
                                            <hr
                                            [style]="{
                                                'margin-top':'8px',
                                                'margin-bottom':'0px',
                                                'background-color': chooseTabComponent === 3 ? activeTabColour : inactiveTabHrColour,
                                                'height': chooseTabComponent === 3 ? '2px' : '0.5px',
                                                'border-top-left-radius': chooseTabComponent === 3 ? '5px' : '0',
                                                'border-top-right-radius': chooseTabComponent === 3 ? '5px' : '0',
                                                'border-bottom-left-radius': '0',
                                                'border-bottom-right-radius': '0'
                                            }"
                                            >
                                        </span>
                                    </div>
                                </div>
                                <div class="bx--row" style="padding-top:20px">
                                    <div class="bx--col">
                                        <beaconstac-color-picker *ngIf="chooseTabComponent===1"
                                            [label]="true"
                                            [title]="'Background color'"
                                            [(color)]="googleWalletPassPreviewColor.backgroundColor"
                                            [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                                            (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.GOOGLE_WALLET_PASS_BACKGROUND)"
                                            [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                                            [cpPosition]="'bottom'"
                                            [id]="'gwp-background-color'"
                                        ></beaconstac-color-picker>
                                        <div *ngIf="chooseTabComponent === 2">
                                            <div class="bx--row justify-content-between ml-0" style="width: 100%;">
                                                <div style="font-size: 13px; font-weight: 400; line-height: 16px; letter-spacing: 0.25px;" class="pb-2 pt-1">
                                                    Change Wallet pass logo
</div>
                                                <div class="bx--row pr-3 upload-component">
                                                    <beaconstac-tooltip
                                                        #tooltip
                                                        [elementClass]="'fas fa-info-circle'"
                                                        [color]="'#2595FF'"
                                                        [content]="'For best results, use a circular logo. Avoid transparent background and test the logo against the background color of your pass.'"
                                                        [width]="'256px'"
                                                        [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                                        [caretPosition]="CARET_POSITION.RIGHT"
                                                        [image]="'../../../assets/img/Guidelines.png'"
                                                    ></beaconstac-tooltip>
                                                    <div class="pl-1 guidelines-button">
                                                        <beaconstac-button
                                                            [type]="'primary'"
                                                            [style]="'text'"
                                                            [label]="'Guidelines'"
                                                        ></beaconstac-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <beaconstac-upload
                                                    #uploadLogoFile
                                                    [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                                                    [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                                                    [maxSize]="5e6"
                                                    [custom]="'160 x 160 px logo size, PNG & JPEG supported'"
                                                    [overrideClick]="true"
                                                    [width]="'270px'"
                                                    [height]="'150px'"
                                                    (onClick)="openLogoModal('logo')"
                                                    [preview]="googleWalletPassPreviewLogo.logo_url ? googleWalletPassPreviewLogo.logo_url : selectedDigitalBusinessCard?.logo_url"
                                                    [showRemoveButton]="true"
                                                    (onFileRemove)="onVcardPlusLogoRemoved('logo');">
                                            </beaconstac-upload>
                                        </div>
                                        <div *ngIf="chooseTabComponent === 3">
                                            <div class="bx--row justify-content-between ml-0" style="width: 100%;">
                                                <div style="font-size: 13px; font-weight: 400; line-height: 16px; letter-spacing: 0.25px;" class="pb-2 pt-1">
                                                    Upload hero image
                                                </div>
                                                <div class="bx--row pr-3">
                                                    <beaconstac-tooltip
                                                        #tooltip
                                                        [elementClass]="'fas fa-info-circle'"
                                                        [color]="'#2595FF'"
                                                        [content]="'For best results, use a wide, rectangular image, preferably of the recommended size, with a colored background.'"
                                                        [width]="'256px'"
                                                        [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                                        [caretPosition]="CARET_POSITION.RIGHT"
                                                        [image]="'../../../assets/img/Guidelines2.jpeg'"
                                                    ></beaconstac-tooltip>
                                                    <div class="pl-1 guidelines-button">
                                                        <beaconstac-button
                                                            [type]="'primary'"
                                                            [style]="'text'"
                                                            [label]="'Guidelines'"
                                                        ></beaconstac-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <beaconstac-upload
                                                    #uploadHeroImage
                                                    [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                                                    [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                                                    [maxSize]="5e6"
                                                    [custom]="'1032 x 336 px logo size, PNG & JPEG supported'"
                                                    [overrideClick]="true"
                                                    [width]="'270px'"
                                                    [height]="'150px'"
                                                    (onClick)="openLogoModal('heroImage')"
                                                    [preview]="googleWalletPassPreviewHeroImage.hero_image_url ? googleWalletPassPreviewHeroImage.hero_image_url : ''"
                                                    [showRemoveButton]="true"
                                                    (onFileRemove)="onVcardPlusLogoRemoved('heroImage');">
                                            </beaconstac-upload>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="scan-qr-code-text">View your pass or send it to the card owner</div>
                        <div class="d-flex qr-modal-container">
                            <div style="width: 40%" *ngIf="generatedWalletQRcode" class="gwp-qrcode">
                                <img
                                    style="width: 120px; height: 120px; border-radius: 3px;"
                                    class="p-2 bg-white"
                                    *ngIf="generatedWalletQRcode?.length"
                                    [src]="generatedWalletQRcode"
                                    alt="QR Code preview" />
                            </div>
                            <div style="display: flex; flex-direction: column; padding-left: 18px; justify-content: center;">
                                <p *ngIf="generatedWalletQRcode" class="mb-2 scan-qr-code-title">Scan QR Code</p>
                                <p class="scan-qr-code-link mb-2">
                                    {{googleWalletPassLink.length > 50 ?
                                    (googleWalletPassLink | sliceValues:50) + '...' : googleWalletPassLink}}
                                </p>
                                <p (click)="copyText(this.googleWalletPassLink)" class="text-blue mb-0"><span class="cursor-pointer">Copy URL</span></p>
                            </div>
                        </div>

                         <div class="text-center pt-3 mb-3 or-text">OR</div>

                        <div class="desktop-email-text-container">
                            <div class="desktop-email-text">
                              Send via email to:&nbsp;<div class="email-text google-wallet-pass-email-text"> {{googleWalletEmailModel.email.length > 24 ?
                                (googleWalletEmailModel.email | sliceValues:24) + '...' : googleWalletEmailModel.email}} </div>
                            </div>
                          </div>

        <div class="gwp-button-container">
          <span class="float-right d-flex flex-row">
            <span>
              <beaconstac-button type="secondary" (onClick)="googleWalletPassModal.hide(); resetWalletPassPreviewColor(); showLogoUploadModal = false" [label]="'Close'"></beaconstac-button>
            </span>
            <span style="padding-left: 16px">
              <beaconstac-button [disabled]="false" type="primary" (onClick)="sendGoogleWalletLink()" [label]="'Send'"></beaconstac-button>
            </span>
          </span>
        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div *ngIf="isMobileScreen">
    <beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isGoogleWalletPassModalOpen" (onOutsideClick)="isGoogleWalletPassModalOpen = false;resetWalletPassPreviewColor()">
            <div class="gwp-preview-container">
                <div class="gwp-preview-title">Get Google Wallet Pass</div>

                <div [ngClass]="!generatedWalletQRcode ? 'd-none' : ''" class="text-secondary ml-0 pt-3 pb-2 gwp-preview-text">View your pass or send it to the card owner</div>

                <div class="d-flex qr-modal-container mt-0">
                    <div class="d-flex flex-column pl-md-3 p-2">
                        <p class="scan-qr-code-link mb-2">
                            {{googleWalletPassLink.length > 40 ?
                            (googleWalletPassLink | sliceValues:40) + '...' : googleWalletPassLink}}
                        </p>
                        <p (click)="copyText(this.googleWalletPassLink); isGoogleWalletPassModalOpen = false" class="text-blue mb-0"><span class="cursor-pointer">Copy URL</span></p>
                    </div>
                </div>

                <div class="text-center pb-3 or-text">OR</div>

                <div class="email-container">
                    <label class="google-wallet-pass-email-text">
                    Send via email to: <span class="email-text google-wallet-pass-email-text">{{googleWalletEmailModel.email}}</span>
                    </label>
                </div>

                <div class="mt-2 mb-3 d-flex justify-content-center align-items-center">
                    <beaconstac-google-wallet-pass-preview
                        [backgroundColor]="googleWalletPassPreviewColor.backgroundColor"
                        [textColor]="googleWalletPassPreviewColor.textColor"
                        [firstName]="selectedDigitalBusinessCard?.first_name"
                        [lastName]="selectedDigitalBusinessCard?.last_name"
                        [company]="selectedDigitalBusinessCard?.company"
                        [email]="selectedDigitalBusinessCard?.email_v2[0] ? selectedDigitalBusinessCard?.email_v2[0]['value'] : ''"
                        [phone]="selectedDigitalBusinessCard?.phone_v2[0] ? selectedDigitalBusinessCard?.phone_v2[0]['value'] : ''"
                        [logo]="googleWalletPassPreviewLogo.logo_url? googleWalletPassPreviewLogo.logo_url : this.selectedDigitalBusinessCard?.logo_url"
                        [website] = "selectedDigitalBusinessCard?.website_v2[0] ? selectedDigitalBusinessCard?.website_v2[0]['value'] : ''"
                        [designation] = "selectedDigitalBusinessCard?.designation"
                        [heroImage]="googleWalletPassPreviewHeroImage.hero_image_url ? googleWalletPassPreviewHeroImage.hero_image_url : ''"
                    ></beaconstac-google-wallet-pass-preview>
                </div>

                <div class="mb-10 mt-2">
                    <beaconstac-button
                    [type]="'secondary'"
                    [style]="'stroke'"
                    [label]="'Customize'"
                    [width]="'100%'"
                    (onClick)="isGoogleWalletPassModalOpen = false; isCustomizeGoogleWalletPassOpen = true; closeLogoUploadModal('logo')"
                ></beaconstac-button>
                </div>
            </div>
        <div class="modal-wrapper bg-white">
            <beaconstac-button
            [type]="BUTTON_TYPES.SECONDARY"
            [style]="BUTTON_STYLES.STROKE"
            [label]="'Go Back'"
            [width]="'157px'"
            (onClick)="isGoogleWalletPassModalOpen = false; resetWalletPassPreviewColor()"
            ></beaconstac-button>
            <button class="btn create-card-dbc-btn text-white border-0 float-right d-flex flex-row justify-content-center align-items-center primary-cta" (click)="sendGoogleWalletLink();">
                    Send</button>
        </div>
    </beaconstac-mobile-drawer>
</div>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isCustomizeGoogleWalletPassOpen" (onOutsideClick)="isCustomizeGoogleWalletPassOpen = false; resetWalletPassPreviewColor();">
    <section class="gwp-customize-drawer">
        <div class="gwp-preview-cont bg-white mb-5 px-6 d-flex flex-column pt-0 pb-4">
            <p [ngClass]="!generatedWalletQRcode ? 'd-none' : ''" class="text-secondary mt-3 wallet-pass-msg1 ml-0 d-flex justify-content-flex-start">Customize your Wallet pass to fit your brand</p>
            <div class="p-2 pl-3 color-picker-container">
                <beaconstac-color-picker
                    [label]="true"
                    [title]="'Background color'"
                    [(color)]="googleWalletPassPreviewColor.backgroundColor"
                    [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                    (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.GOOGLE_WALLET_PASS_BACKGROUND)"
                    [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'gwp-background-color'"
                ></beaconstac-color-picker>
            </div>

            <div class="bx--row justify-content-between ml-0 upload-container">
                <div class="ml-2 pl-2 upload-text">
                    Upload logo
                </div>
                <div class="bx--row pr-3">
                    <i class="fa-info-circle fas" style="color: rgb(37, 149, 255); line-height: 26px;"></i>

                    <div class="pr-2 pl-1 guidelines-button">
                        <beaconstac-button
                            [type]="'primary'"
                            [style]="'text'"
                            [label]="'Guidelines'"
                            (onClick)="onGuideLineClick(WALLET_PASS.GOOGLE_WALLET)"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>

            <div class="upload-main">
                <beaconstac-upload
                    #uploadLogoFile
                    [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                    [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                    [maxSize]="5e6"
                    [custom]="'160 x 160 px logo size, PNG & JPEG supported'"
                    [overrideClick]="true"
                    [width]="'270px'"
                    [height]="'150px'"
                    (onClick)="openLogoModal('logo')"
                    [preview]="googleWalletPassPreviewLogo.logo_url ? googleWalletPassPreviewLogo.logo_url : selectedDigitalBusinessCard?.logo_url"
                    [showRemoveButton]="true"
                    (onFileRemove)="onVcardPlusLogoRemoved('logo');">
                </beaconstac-upload>
            </div>

            <div class="bx--row justify-content-between ml-0 upload-container">
                <div class="ml-2 pl-2 upload-text">
                    Upload Hero Image
                </div>
                <div class="bx--row pr-3">
                    <i class="fa-info-circle fas" style="color: rgb(37, 149, 255); line-height: 26px;"></i>
                    <div class="pr-2 pl-1 guidelines-button">
                        <beaconstac-button
                            [type]="BUTTON_TYPES.PRIMARY"
                            [style]="BUTTON_STYLES.TEXT"
                            [label]="'Guidelines'"
                            (onClick)="isHeroImageGuidelinesModalOpen = true;
                            isCustomizeGoogleWalletPassOpen = false"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>

        <div class="upload-main">
            <beaconstac-upload
                #uploadHeroImage
                [disabled]="!hasWriteAccess || isDefaultWalletPassCustomizationSet"
                [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                [maxSize]="5e6"
                [custom]="'1032 x 336 px logo size, PNG & JPEG supported'"
                [overrideClick]="true"
                [width]="'270px'"
                [height]="'150px'"
                (onClick)="openLogoModal('heroImage')"
                [preview]="googleWalletPassPreviewHeroImage.hero_image_url ? googleWalletPassPreviewHeroImage.hero_image_url : ''"
                [showRemoveButton]="true"
                (onFileRemove)="onVcardPlusLogoRemoved('heroImage');">
            </beaconstac-upload>
        </div>

            <div class="modal-wrapper bg-white">
                <beaconstac-button
                    class="w-100"
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.STROKE"
                    [width]="'100%'"
                    [label]="'Done'"
                    (onClick)="isGoogleWalletPassModalOpen = true;isCustomizeGoogleWalletPassOpen = false; closeLogoUploadModal('heroImage');">
                </beaconstac-button>
            </div>

        </div>
    </section>
</beaconstac-mobile-drawer>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isGuidelinesModalOpen" (onOutsideClick)="isGuidelinesModalOpen = false;">
        <div class="align-items-center justify-content-center gwp-preview-cont bg-white default-drawer-styles">
            <img src="../../../assets/img/Guidelines.png" class="guidelines-img mt-4 mb-3">

            <div class="pl-3 pr-3 modal-text mb-3">
                For best results, use a circular logo. Avoid transparent background and test the logo against the background color of your pass.
            </div>

            <div class="pb-4 mt-2" style="width: 100%;">
                <beaconstac-button
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.STROKE"
                    [width]="'100%'"
                    [label]="'Go Back'"
                    (onClick)="isGuidelinesModalOpen=false; updateMobileWalletPassModal({walletPassType: currentWalletPassType, isModalOpen: false, isCustomizeModalOpen: true})"
                >
                </beaconstac-button>
            </div>
        </div>
</beaconstac-mobile-drawer>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isHeroImageGuidelinesModalOpen" (onOutsideClick)="isHeroImageGuidelinesModalOpen = false;resetWalletPassPreviewColor()">
    <div class="align-items-center justify-content-center gwp-preview-cont bg-white default-drawer-styles">
        <img src="../../../assets/img/Guidelines2.jpeg" class="guidelines-img mt-4 mb-3">

        <div class="pl-3 pr-3 modal-text mb-3">
            For best results, use a wide, rectangular image, preferably of the recommended size, with a colored background.
        </div>

        <div class="pb-4 mt-2" style="width: 100%;">
            <beaconstac-button
                [type]="BUTTON_TYPES.SECONDARY"
                [style]="BUTTON_STYLES.STROKE"
                [width]="'100%'"
                [label]="'Go Back'"
                (onClick)="isCustomizeGoogleWalletPassOpen = true;isHeroImageGuidelinesModalOpen = false; "
            >
            </beaconstac-button>
        </div>
    </div>
</beaconstac-mobile-drawer>

<div bsModal #deleteCardModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div *ngIf="selectedDigitalBusinessCard" class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">

                <h4 class="create-card-heading">
                    Delete Card?
                </h4>

                <div class="create-linkpage-description mt-4 mb-4 text-secondary">
                    Once deleted, you will lose access to this Card permanently.
                </div>

            </div>
            <div class="modal-footer" style="padding: 0 24px 24px 24px; border: none">

                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="deleteCardModal.hide(); this.selectedDigitalBusinessCard = null"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.DANGER" [label]="'Delete'"
                                     (onClick)="deleteCard(); deleteCardModal.hide()"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>

        </div>
    </div>
</div>

<div bsModal #confirmShareWalletPassModal="bs-modal" class="modal fade" role="dialog">
    <div class="modal-dialog d-flex justify-content-center" role="document">
        <div class="modal-content pl-2 pr-2" style="width: 350px; height: 340px;">
            <div class="modal-header border-0 pt-4">
                <h4 class="modal-title fw-400 ff-WorkSans line-height-24px" style="
                font-size: 20px; color: #16212F;">Share Wallet passes</h4>
            </div>

            <div class="modal-body border-0 pt-0 pb-0">
                <h5 class="text-muted pb-3 share-wallet-info">By clicking Share, Apple and Google wallet passes will be shared via email
                    to their respective owner(s) with a secure link.</h5>
                <div class="d-flex border-radius-2px" style="background-color: #DDEFFF; border: 1px solid #2595FF; padding: 1rem;">
                    <div class="text-center" style="flex: 1;">
                        <i class="fa fa-info-circle fa-lg text-primary"></i>
                    </div>
                    <div class="fw-400 ff-WorkSans line-height-24px fs-15px" style="flex: 7; color: #16212F;">
                        No passes will be created for Digital business cards with no email.
                    </div>
                </div>
            </div>

            <div class="modal-footer border-0" style="padding:6px 1px 16px 0 !important">
                <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" class="btn border-radius-2px"
                                   (onClick)="handleConfirmShareWalletPassModal()" [label]="'Share'"></beaconstac-button>
            </div>
        </div>
    </div>
</div>

<div bsModal #confirmBulkDeletePermanentlyModal="bs-modal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg modal-danger modal-dialog-centered" style="width: 530px;" role="document">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">
                <h4 class="create-card-heading">
                    Delete cards permanently?
                </h4>
                <div class="create-linkpage-description mt-4 mb-4 text-secondary">
                    This action cannot be undone.
                </div>
                <div class="create-linkpage-description mt-4 mb-4 text-secondary">
                    Note - Only Admins can delete digital business cards created through Microsoft Entra ID.
                </div>
            </div>
            <div class="modal-footer" style="padding: 0 24px 24px 24px; border: none">
                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="confirmBulkDeletePermanentlyModal.hide();"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.DANGER" [label]="'Delete'"
                                     (onClick)="bulkDeletePermanentlyCards()"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>

        </div>
    </div>
</div>

<div bsModal #changeMaintainer="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" (onShow)="checkCurrentMaintainer()">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">

            <div class="modal-header" style="border:none;margin-bottom: -15px">
                <h4 class="modal-title">Change Owner </h4>
            </div>

            <div class="modal-body">
                <div class="text-secondary">Choose a user in this organization to change ownership of this Card.</div>
                <div *ngIf="!maintainerList.length">No user found in organization</div>
            </div>
            <div class="mx-3" *ngIf="maintainerList.length">
                <beaconstac-filter-dropdown
                    [label]="'User'"
                    [title]="'User'"
                    [value]="currentProductOwner"
                    [optionLabelField]="'username'"
                    [optionValueField]="'id'"
                    [listItems]="maintainerList"
                    [width]="'450px'"
                    (inputChangeEvent) = "fetchUsersByEmail($event)"
                    (valueChange)="setNewOwner($event)"
                ></beaconstac-filter-dropdown>
            </div>

            <div class="modal-footer" style="border: none">
                <button type="button" class="btn btn-secondary" (click)="changeMaintainer.hide()">
                    Cancel
                </button>
                <button type="button" style ="text-transform: none;" class="btn btn-primary" (click)="changeProductOwner(true)" [disabled]="disableTransferOwnerButton">
                    Change
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal #changeOrganization="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">

            <div class="modal-header" style="border:none;margin-bottom: -15px">
                <h4 class="modal-title">Change Organization</h4>
            </div>

            <div class="modal-body">
                <div class="text-secondary">Move this Card to any of your other organizations.</div>
                <div class="text-secondary pt-3">Once transferred, the Card will only be visible in the new organization.</div>
                <div *ngIf="!organizationList.length">No organization found in account</div>
            </div>
            <div class="mx-3">
                <beaconstac-filter-dropdown
                    [label]="'Organization'"
                    [title]="'Organization'"
                    [optionLabelField]="'name'"
                    [optionValueField]="'id'"
                    [listItems]="organizationList"
                    [value]="currentProductOrg"
                    (inputChangeEvent) = "fetchOrganizationsByName($event)"
                    [width]="'450px'"
                    (valueChange)="setNewOrg($event)"
                ></beaconstac-filter-dropdown>
            </div>

            <div class="modal-footer" style="border: none">
                <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                [label]="'Cancel'"
                                (onClick)="changeOrganization.hide();"
                ></beaconstac-button>

                <beaconstac-button [disabled]="disableTransferOrgButton" [type]="BUTTON_TYPES.PRIMARY"
                                    [label]="'Transfer'"
                                    (onClick)="transferProductOrg(true);"
                ></beaconstac-button>
            </div>
        </div>
    </div>
</div>

<div bsModal #downloadModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-primary modal-md" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title">Advanced download options</h4>
            </div>

            <div class="modal-body">
                <div class="row mt-1">
                    <label for="canvasTypeSelector" class="col-4 download-modal-label">File type</label>
                    <select class="form-control col-7" id="canvasTypeSelector" [(ngModel)]="customDownloadOptions.canvasType">
                        <option *ngFor="let canvasType of (QR_CANVAS_TYPES | utilsObjectKeys)"
                                [value]="QR_CANVAS_TYPES[canvasType]">
                            {{canvasType}}
                        </option>
                    </select>
                </div>
                <div class="row mt-3">
                    <label for="QRSizeSelector" class="col-4 download-modal-label">Size</label>
                    <select class="form-control col-7" id="QRSizeSelector" [(ngModel)]="customDownloadOptions.size">
                        <option [value]="512">512 x 512</option>
                        <option [value]="1024">1024 x 1024</option>
                        <option *ngIf="customDownloadOptions.canvasType !== QR_CANVAS_TYPES.EPS" [value]="2048">2048 x 2048</option>
                        <option *ngIf="customDownloadOptions.canvasType !== QR_CANVAS_TYPES.EPS" [value]="4096">4096 x 4096</option>
                    </select>
                </div>
                <div class="row mt-3">
                    <label for="errorCorrectionSelector" class="col-4 download-modal-label">Error correction level</label>
                    <select class="form-control col-7" id="errorCorrectionSelector"
                            [(ngModel)]="customDownloadOptions.correctLevel">
                        <!--            slice() is used since Object.keys() on enum returns n*2 elements-->
                        <option *ngFor="let level of QR_ERROR_CORRECTION_LEVEL_KEYS" [value]="QR_ERROR_CORRECTION_LEVEL[level]">
              <span [ngSwitch]="level">
                <span *ngSwitchCase="'L'">Low</span>
                <span *ngSwitchCase="'M'">Medium</span>
                <span *ngSwitchCase="'Q'">High</span>
                <span *ngSwitchCase="'H'">Highest</span>
              </span>
                        </option>
                    </select>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="downloadModal.hide()">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="downloadCustomQRCode()">
                    Download
                </button>
            </div>
        </div>
    </div>
</div>

<div bsModal #selectCardOwnerModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">

            <div class="modal-header" style="border:none;margin-bottom: -15px">
                <h4 class="modal-title">Create a digital business card</h4>
            </div>

            <div class="modal-body">
                <div class="text-secondary">Create a card for you or your teammates.</div>
                <div *ngIf="!maintainerList.length">No user found in organization</div>
            </div>
            <div class="mx-3" *ngIf="maintainerList.length">
                <beaconstac-filter-dropdown
                    [label]="'Owner'"
                    [title]="'user-select'"
                    [value]="currentProductOwner"
                    [optionLabelField]="'username'"
                    [optionValueField]="'id'"
                    [listItems]="maintainerList"
                    [width]="'450px'"
                    (valueChange)="setOwner($event)"
                    (inputChangeEvent) = "fetchUsersByEmail($event)"
                ></beaconstac-filter-dropdown>
            </div>

            <div class="modal-footer" style="border: none">
                <beaconstac-button
                    [label]="'Cancel'"
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.FILLED"
                    (onClick)="selectCardOwnerModal.hide()"
                ></beaconstac-button>

                <beaconstac-button
                    [label]="'Create a card'"
                    [type]="BUTTON_TYPES.PRIMARY"
                    (onClick)="redirectToAddDBC()"
                    [disabled]="!hasWriteAccess || (selectedCardOwnerId === -1)"
                ></beaconstac-button>
            </div>
        </div>
    </div>
</div>

<div bsModal #dbcBulkDownloadModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">

    <div class="modal-dialog" role="document">
        <div class="modal-content p-2">

            <div class="modal-header" style="border:none;margin-bottom: -15px">
                <h4 class="modal-title">Bulk Download</h4>
            </div>

            <div class="modal-body">

                <div class="text-secondary">
                    <b>Please note: </b><span [innerHTML]="bulkOperationsMessage"></span>
                </div>

                <div class="bx--row mt-3">
                    <div class="bx--col">
                        <beaconstac-filter-dropdown
                            [label]="'File Type'"
                            [title]="'file-type'"
                            [value]="defaultCanvasType"
                            [optionLabelField]="'id'"
                            [optionValueField]="'value'"
                            [listItems]="canvasTypeSelection"
                            [width]="'100%'"
                            (valueChange)="customDownloadOptions.canvasType=($event).value"
                        ></beaconstac-filter-dropdown>
                    </div>
                    <!-- <div>{{customDownloadOptions.canvasType=($event).value}}</div> -->
                </div>

                <div class="bx--row mt-3">
                    <div class="bx--col">
                        <beaconstac-filter-dropdown
                            [label]="'Size'"
                            [title]="'size'"
                            [value]="defaultQrSize"
                            [optionLabelField]="'id'"
                            [optionValueField]="'value'"
                            [listItems]="qrSizeSelection"
                            [width]="'100%'"
                            (valueChange)="customDownloadOptions.size=($event).value"
                            ></beaconstac-filter-dropdown>
                    </div>
                </div>

                <div class="bx--row mt-3">
                    <div class="bx--col">
                        <beaconstac-filter-dropdown
                            [label]="'Error correction level'"
                            [title]="'error-correction-level'"
                            [value]="defaultErrorCorrectionLevel"
                            [optionLabelField]="'id'"
                            [optionValueField]="'value'"
                            [listItems]="errorCorrectionLevelSelection"
                            [width]="'100%'"
                            (valueChange)="customDownloadOptions.correctLevel=($event).value"
                        ></beaconstac-filter-dropdown>
                    </div>
                </div>

            </div>
            <div class="modal-footer" style="border: none">
                <beaconstac-button
                    [label]="'Cancel'"
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.FILLED"
                    (onClick)="dbcBulkDownloadModal.hide()"
                ></beaconstac-button>
                <beaconstac-button
                    [label]="'Download'"
                    [type]="BUTTON_TYPES.PRIMARY"
                    (onClick)="bulkDownloadDbcQRCodes()"
                ></beaconstac-button>
            </div>
        </div>
    </div>
</div>

<div bsModal #downloadRequestModal="bs-modal" class="modal fade" role="dialog" style="margin-top: 5%">
    <div class="modal-dialog modal-primary" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Download processing</h4>
        </div>
        <div class="modal-body">
          <div class="px-3 py-4" style="font-size: 13px">
            <img class="mb-3" src="../../../assets/img/bulk-qr/emailnotif.svg">
            <p class="mt-1">We are preparing your QR code files for download.
              <br>You will receive an email with a secure link when they are ready.
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary mt-2" (click)="downloadRequestModal.hide()">CLOSE
          </button>
        </div>
      </div>
    </div>
</div>

<div bsModal #bulkCreateTemplateWarnModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" *ngIf="!isMobileScreen">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">

                <h4 class="create-card-heading">
                    Bulk create
                </h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    This organizations does not have any templates. Please create a template to create cards in bulk.
                </div>

            </div>
            <div class="modal-footer border-0" style="padding: 24px;">

                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="bulkCreateTemplateWarnModal.hide()"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.DANGER" [label]="'Create'"
                                     (onClick)="createNewTemplate(true)"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>

        </div>
    </div>
</div>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isBulkCreateWarnDrawerOpen" (onOutsideClick)="isBulkCreateWarnDrawerOpen = false" *ngIf="isMobileScreen">
    <section class="d-flex flex-column">
        <div class="create-template-heading">
            Bulk create using templates
        </div>

        <div class="create-template-text">
            You'll need a template to bulk create cards. Templates help keep a consistent card style.
        </div>

        <beaconstac-button class="pb-4"
            [type]="BUTTON_TYPES.PRIMARY"
            [style]="BUTTON_STYLES.FILLED"
            (onClick)="createNewTemplate(true)"
            [label]="'Create Template'"
            [width]="'100%'"
        ></beaconstac-button>

        <beaconstac-button
            [type]="BUTTON_TYPES.SECONDARY"
            [style]="BUTTON_STYLES.STROKE"
            (onClick)="isBulkCreateWarnDrawerOpen = false"
            [label]="'Close'"
            [width]="'100%'"
        ></beaconstac-button>
    </section>
</beaconstac-mobile-drawer>

<div bsModal #bulkCreateOptionsModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">

                <h4 class="create-card-heading">
                    Bulk create
                </h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    Generate business cards for one or multiple organisations easily.
                </div>

            </div>
            <div class="modal-footer border-0" style="padding: 24px;">

                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="bulkCreateOptionsModal.hide()"
                                     [label]="'Cancel'"
                                     [style]="BUTTON_STYLES.TEXT"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Multiple orgs'"
                                     (onClick)="redirectToMultiOrgUpload()"
                                     [style]="BUTTON_STYLES.STROKE"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'This org only'"
                                     (onClick)="redirectToBulkAddDBC()"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>

        </div>
    </div>
</div>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isBulkCreateOptionsModalOpen" (onOutsideClick)="isBulkCreateOptionsModalOpen = false">
    <h4 class="create-card-heading">
        Bulk create
    </h4>

    <div class="create-linkpage-description mt-3 text-secondary">
        Generate business cards for one or multiple organisations easily.
    </div>

    <div class="border-0 d-flex flex-column">

        <span class="pt-3">
              <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'This org only'"
                                 (onClick)="redirectToBulkAddDBC()"
                                 [style]="BUTTON_STYLES.FILLED"
                                 [width]="'100%'"
              ></beaconstac-button>
        </span>

        <span class="pt-3">
              <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Multiple orgs'"
                                 (onClick)="redirectToMultiOrgUpload()"
                                 [style]="BUTTON_STYLES.STROKE"
                                 [width]="'100%'"
              ></beaconstac-button>
        </span>

        <span class="pt-3">
              <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                 (onClick)="isBulkCreateOptionsModalOpen = false"
                                 [label]="'Cancel'"
                                 [style]="BUTTON_STYLES.STROKE"
                                 [width]="'100%'"
              ></beaconstac-button>
        </span>
    </div>
</beaconstac-mobile-drawer>

<div class="asset-library-container">
    <div bsModal #imageSelectModalLogo="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}"
        (onShow)="setupImageSelectModal('logo')" (onHide)="imageSelectGalleryLogo.onHide(); isLogoUploading = false">
        <div class="modal-dialog modal-info modal-lg" role="document">
            <div class="modal-content modal-content-kz">

                <div class="modal-header-kz">
                    <h4 class="modal-title">Select {{ isLogoUploading ? 'a Logo' : 'an image'}}</h4>
                </div>
                <div class="modal-body modal-body-kz">
                    <div class="form-group">
                        <app-media-gallery #imageSelectGalleryLogo
                            [isSelectDisabled]="false"
                            (mediaSelector)="imageSelectedInGallery($event, 'logo')"
                            (fileUploaded)="loadCropper($event,'logo')"
                            [addMedia]="updatedMediaLogo"
                            (uploadToFolderId)="setUploadToFolder($event)"
                            [searchParams]="{'content_type__nin':'image/gif'}"
                            [isFooterVisible]="true"
                            [acceptedTypes]="['image/jpeg', 'image/png']"
                            (onSelect)="selectImage('logo')"
                            (onClose)="imageSelectModalLogo.hide()">
                        </app-media-gallery>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="asset-library-container">
    <div bsModal #imageSelectModalHeroImage="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}"
        (onShow)="setupImageSelectModal('heroImage')" (onHide)="imageSelectGalleryHeroImage.onHide(); isLogoUploading = false">
        <div class="modal-dialog modal-info modal-lg" role="document">
            <div class="modal-content modal-content-kz">

                <div class="modal-header-kz">
                    <h4 class="modal-title">Select {{ isLogoUploading ? 'a Logo' : 'an image'}}</h4>
                </div>
                <div class="modal-body modal-body-kz">
                    <div class="form-group">
                        <app-media-gallery #imageSelectGalleryHeroImage
                            [isSelectDisabled]="false"
                            (mediaSelector)="imageSelectedInGallery($event,'heroImage')"
                            (fileUploaded)="loadCropper($event,'heroImage')"
                            [addMedia]="updatedMediaHeroImage"
                            (uploadToFolderId)="setUploadToFolder($event)"
                            [searchParams]="{'content_type__nin':'image/gif'}"
                            [isFooterVisible]="true"
                            [acceptedTypes]="['image/png', 'image/jpeg']"
                            (onSelect)="selectImage('heroImage')"
                            (onClose)="imageSelectModalHeroImage.hide()">
                        </app-media-gallery>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-image-cropper #imageCropperLogo *ngIf="imageCropperFile" [imageFile]="imageCropperFile"
                   (cropperClose)="closeImageCropperModal()" [squareDragMode]="true" [folderId]="uploadToFolder"
                   (imageUpdated)="updateMedia($event,'logo')" [imageAspectRatio]="'1:1'" [showUploadOriginal]="true"></app-image-cropper>

<app-image-cropper #imageCropperHeroImage *ngIf="imageCropperFile" [imageFile]="imageCropperFile"
                   (cropperClose)="closeImageCropperModal()" [squareDragMode]="true" [folderId]="uploadToFolder"
                   (imageUpdated)="updateMedia($event,'heroImage')" [imageAspectRatio]="'1:1'" [showUploadOriginal]="true"></app-image-cropper>

<beaconstac-product-label #tagUntagModal [productType]="'cards'" [tagList]="tagList" [cards]="selectedCardsForTag"
                          [cards_filter]="queryParams" [message]="bulkOperationsMessage"
                          [showMessage]="selectedCardsForTag.length === 0 && this.searchParams === {}" [operationMethod]="applyLabelOperationType"
                          (labelsApplied)="onLabelsApplied($event)"
                          (labelUpdated)="onLabelsUpdated($event)" (deletedLabel)="deleteLabel($event)"
                          [cardType]="cardType">
</beaconstac-product-label>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isDownloadsDrawerOpen" (onOutsideClick)="checkDrawerOpen()">
    <section>
        <!-- Share Screen -->
        <div *ngIf="current_mobile_screen===DOWNLOAD_SCREENS.SHARE_SCREEN">
            <div class="align-items-center justify-content-center bg-white mb-5" >
                <div style="font-size: 24px; font-weight: 600; line-height: 28.15px; display:flex; justify-content: center;">Congratulations! </div>
                <div style="font-size: 24px; font-weight: 400; line-height: 28.15px; display:flex; justify-content: center;">Your card is ready.</div>

                <div class="d-flex justify-content-center" style="margin-top: 42px;">
                    <div class="qrcode-preview-btngroup d-flex justify-content-between align-items-center p-1" style="width: 327px; height: 52px;">
                        <input type="radio" id="first-2" class="first-mobile" name="section-2" value="0" [checked]="!showPreviewFrame">
                        <label class="d-flex align-items-center justify-content-center" for="first-2" (click)="togglePreview(true)" [ngClass]="!showPreviewFrame? 'active-preview': ''" style="width: 150px; height: 50px;">
                            <i class="fas fa-share-alt" style="font-size: 16px; color: #16212f; margin: 4px" ></i>
                            <span style="font-size: 15px; font-weight: 400;">Share</span>
                        </label>
                        <input type="radio" id="second-2" class="second-mobile" name="section-2" value="1" [checked]="showPreviewFrame">
                        <label class="d-flex align-items-center justify-content-center" for="second-2" (click)="togglePreview(false)" [ngClass]="showPreviewFrame? 'active-preview': ''" style="width: 150px; height: 50px;">
                            <i class="fa fa-eye" style="font-size: 16px; color: #16212f; margin: 4px"></i>
                            <span style="font-size: 15px; font-weight: 400;">Preview</span>
                        </label>
                        <div class="slider" style="width: 150px; height: 42px;"></div>
                    </div>
                </div>
            </div>

            <div *ngIf="!showPreviewFrame">
                <!--Share as a link-->
                <div>
                    <div class="subheading-mobile pl-3">Share as a link</div>
                    <div class="d-flex flex-row pt-4 pr-3">
                        <div class="link-image">
                            <i class="fa fa-link" style="width: 5%; height: 24px; color: #C0C5CB; margin-left: 1%; margin-right: 20px;"></i>
                        </div>
                        <div style="width: 100%;">
                            <beaconstac-textfield
                                [placeholder]="selectedDigitalBusinessCard?.url.length > 39 ? (selectedDigitalBusinessCard?.url | sliceValues:39).slice(8) + '...' : selectedDigitalBusinessCard?.url.slice(8)"
                                [disabled]="true"
                                [width]="'100%'"
                                [maxWidth]="'100%'"
                                [icon]="'fa fa-share-square'"
                                (click)="onShare()"
                            >
                            </beaconstac-textfield>
                        </div>
                    </div>

                    <div class="subheading-mobile pl-3 pt-4 mb-4">Share with a QR Code</div>

                    <div class="d-flex pl-3 pr-3 pt-3 pb-0" style="width: 100%">
                        <div style="max-width: 87px; max-height: 87px; border: 1px solid #E7E7E7; display: flex; justify-content: center;align-items: center;margin-top: 5px;">
                        <div style="max-width: 81px; max-height: 81px;" [ngClass]="selectedDigitalBusinessCard?.template?.frameStyle === QRCodeFrame.CIRCULAR ? 'circular-qr-code-frame' : 'qr-code-container'" [innerHTML]="generatedDBCQRcode | getSanitizedHtml"></div>
                        </div>
                        <div class="d-flex flex-column ml-2" style="width: 100%;">
                            <div class="pt-2 pl-2" style="width: auto; height: 30px;font-weight: 400;font-size: 13px; line-height: 15.25px; letter-spacing: 0.25px;color: #5E6980;">
                                Scanning this QR Code will open your card
                            </div>

                            <div class="bx--row pt-3 pl-1 mt-2">
                                <div class="bx--col bx--col-sm-4">
                                    <beaconstac-button
                                        [type]="BUTTON_TYPES.PRIMARY"
                                        [style]="BUTTON_STYLES.STROKE"
                                        [label]="'Download'"
                                        (onClick)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.DOWNLOADS)"
                                        [width]="'100%'"
                                    ></beaconstac-button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="subheading-mobile pt-4 pl-3">Share via Wallet pass</div>
                </div>
                <div class="btn-layout pt-3">
                        <button class="add-wallet-button ml-4 mb-4" (click)="openGoogleWalletPassModal()">
                            <img style="width: 145px; height: 41px; " src="https://ci3.googleusercontent.com/meips/ADKq_NY95DeQ8k7HNA1LIQAGubm1MVrBGidKQqY3bXNl8r0MxQZxaar_0JPbmnNxg4fKdLfDVEAgtBOnPHeSM_g9P71h8tD567alB_2uoY1YsuWAVBaNpzLM_YDxVczeUpeEz6OhcNnEhg=s0-d-e1-ft#https://q.eddy.pro/static/images/add_wallet_helper/add_to_google_wallet_logo.png">
                        </button>
                        <button class="add-wallet-button ml-2 mb-4" (click)="openAppleWalletPassModal()">
                            <img style="width: 145px; height: 41px;border-radius: 60px;" src="https://ci3.googleusercontent.com/meips/ADKq_NZMV97EmNsuge2PtKofKAw55Klj1_XKon_eWUeLfLCeu9_rKRT8U4H8RTeDRVIaUtb5iQ7XbCjpFDDm1zSakRUQhNzUd2LRWZRnnl3p0Ims-ZEHSIY4NAay0XYo3UZhVoXgKi4P=s0-d-e1-ft#https://q.eddy.pro/static/images/add_wallet_helper/add_to_apple_wallet_logo.png">
                        </button>
                </div>
                <!-- new design -->
            </div>
        </div>

        <!-- Preview Screen -->

        <div *ngIf="current_mobile_screen===DOWNLOAD_SCREENS.PREVIEW_SCREEN" class="preview-layout">
            <div class="d-flex justify-content-center" style="margin-top: 42px;">
                <div class="qrcode-preview-btngroup d-flex justify-content-between align-items-center p-1">
                    <input type="radio" id="first-2" class="first" name="section-2" value="0" [checked]="!showPreviewFrame">
                    <label class="d-flex align-items-center justify-content-center" for="first-2" (click)="togglePreview(true)" [ngClass]="!showPreviewFrame? 'active-preview': ''">
                        <i class="fas fa-share-alt" style="font-size: 16px; color: #16212f; margin: 4px" ></i>
                        <span style="font-size: 15px; font-weight: 400;">Share</span>
                    </label>
                    <input type="radio" id="second-2" class="second" name="section-2" value="1" [checked]="showPreviewFrame">
                    <label class="d-flex align-items-center justify-content-center" for="second-2" (click)="togglePreview(false)" [ngClass]="showPreviewFrame? 'active-preview': ''">
                        <i class="fa fa-eye" style="font-size: 16px; color: #16212f; margin: 4px"></i>
                        <span style="font-size: 15px; font-weight: 400;">Preview</span>
                    </label>
                    <div class="slider"></div>
                </div>
            </div>

            <!--preview img-->
            <div class="d-flex flex-column justify-content-center align-items-center" style="row-gap: 30px; margin-top: 19px; margin-bottom: 14px;">
                <div class="d-flex justify-content-center align-items-center" id="customPreview" style="position: relative;">
                    <div class="expand-btn" (click)="showPreview()">
                        <svg class="circle" xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                            <circle cx="16.5" cy="16" r="16" fill="#16212F" fill-opacity="0.5"/>
                        </svg>
                        <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.49607 12.1184L6.18586 15.4286L7.36165 16.5367C7.90165 17.0767 7.51922 18 6.75557 18H2.75557C2.28218 18 1.90015 17.6162 1.90015 17.1429V13.1429C1.90015 12.3792 2.82168 11.9968 3.36165 12.5367L4.47157 13.7143L7.78179 10.4041C8.00493 10.1809 8.36675 10.1809 8.5899 10.4041L9.49607 11.3102C9.71925 11.5334 9.71925 11.8952 9.49607 12.1184ZM10.3042 7.88164L13.6144 4.57143L12.4386 3.46325C11.8986 2.92325 12.2811 2 13.0447 2H17.0447C17.5181 2 17.9001 2.38375 17.9001 2.85714V6.85714C17.9001 7.62078 16.9786 8.00321 16.4386 7.46325L15.3287 6.28571L12.0185 9.59593C11.7954 9.81907 11.4335 9.81907 11.2104 9.59593L10.3042 8.68975C10.081 8.46661 10.081 8.10482 10.3042 7.88164Z" fill="white"/>
                        </svg>
                    </div>
                    <iframe class="preview-iframe" style="box-shadow: 0px 8px 25px 0px #81848826;" #dbcMobileOnePreview></iframe>
                </div>
            </div>

            <!--section division line-->
            <div class="bx--row">
                <div class="bx--col px-0">
                    <hr style="border-top: 1px dashed #DBDFE3;">
                </div>
            </div>
            <!--view more details-->
            <div class="my-2 mx-3">
                <div class="d-flex justify-content-between align-items-center my-2">
                    <span class="dbc-details-heading" (click)="showDetails = !showDetails;">View more details</span>
                    <i class="fa" style="color: #5E6980; font-size: 20px" (click)="showDetails = !showDetails;" [ngClass]="showDetails ? 'fa-angle-up': 'fa-angle-down'"></i>
                </div>
                <div *ngIf="showDetails" class="my-2">
                    <p class="template-parent my-2" *ngIf="selectedDigitalBusinessCard?.is_template">
                        <beaconstac-message message="This is a template card"></beaconstac-message>
                    </p>
                    <p class="dbc-detail-value my-2">
                        <span class="dbc-detail-key">Views :</span> {{selectedDigitalBusinessCard?.views.toLocaleString() || 0}}
                        <span class="" (click)="navigateToAnalytics(selectedDigitalBusinessCard)">
                        <beaconstac-tooltip
                            elementClass="fas fa-chart-bar"
                            [content]="'Analytics'"
                            [tooltipPosition]="TOOLTIP_POSITION.TOP"
                            [caretPosition]="CARET_POSITION.LEFT"
                            style="margin-top: 0 !important; cursor: pointer !important;"
                            [color]="'#2595ff'"
                        ></beaconstac-tooltip>
                        </span>
                    </p>
                    <p class="dbc-detail-value my-2">
                        <span class="dbc-detail-key">Owner :</span>
                        {{truncateEmail(selectedDigitalBusinessCard?.card_owner.email, 'owner') || 'NA' }}
                    </p>
                    <p class="dbc-detail-value my-2">
                        <span class="dbc-detail-key">Created by :</span>
                        {{truncateEmail(selectedDigitalBusinessCard?.meta['creator_email'], 'creator') || 'NA' }}
                    </p>
                </div>
            </div>
            </div>

        <!-- Download screen -->
        <ul *ngIf="current_mobile_screen===DOWNLOAD_SCREENS.DOWNLOADS_SCREEN" class="download-options">
            <li  class="heading">
                DOWNLOAD FORMAT
            </li>
            <li *ngFor="let option of qrDownloadOptions" (click)="downloadQRtype(option)" class="options">
                {{ option.name }}
            </li>
            <li (click)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.SHARE)" class="options" style="color: var(--Main-Primary-Blue, #2595FF);">
                Go Back
            </li>
        </ul>

        <!-- custom options -->
        <ul *ngIf="current_mobile_screen===DOWNLOAD_SCREENS.ADVANCE_DOWNLOAD_SCREEN" class="download-options">
            <li  class="heading">
                ADVANCED DOWNLOAD OPTIONS
            </li>
            <li class="options">
                <div class="">
                    <beaconstac-filter-dropdown
                        [label]="'File type'"
                        [title]="'file-type'"
                        [value]="{id: selectedFileType, value: selectedFileType}"
                        [optionLabelField]="'id'"
                        [optionValueField]="'value'"
                        [listItems]="canvasTypeSelection"
                        [width]="'100%'"
                        (valueChange)="setFileType(selectedFileType)"
                        (click)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.FILE_TYPE)"
                    ></beaconstac-filter-dropdown>
                </div>
            </li>
            <li class="options">
                <div class="">
                    <beaconstac-filter-dropdown
                        [label]="'Size'"
                        [title]="'size'"
                        [value]="selectedSize"
                        [optionLabelField]="'id'"
                        [optionValueField]="'value'"
                        [listItems]="qrSizeSelection"
                        [width]="'100%'"
                        (valueChange)="setsize(selectedSize)"
                        (click)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.SIZE)"
                    ></beaconstac-filter-dropdown>
                </div>
            </li>
            <li class="options">
                <div class="">
                    <beaconstac-filter-dropdown
                        [label]="'Error correction level'"
                        [title]="'error-correction-level'"
                        [value]="selectedErrorCorrection"
                        [optionLabelField]="'id'"
                        [optionValueField]="'value'"
                        [listItems]="errorCorrectionLevelSelection"
                        [width]="'100%'"
                        (valueChange)="setcorrectionlevel(selectedErrorCorrection)"
                        (click)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.ERROR_CORRECTION)"
                    ></beaconstac-filter-dropdown>
                </div>
            </li>
            <li>
                <div class="buttons-on-custom">
                    <div style="width:50%;">
                        <beaconstac-button
                            [type]="BUTTON_TYPES.SECONDARY"
                            [label]="'Cancel'"
                            (onClick)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.DOWNLOADS)"
                            [width]="'100%'">
                        </beaconstac-button>
                    </div>
                    <div style=" width:50%;">
                        <beaconstac-button
                            [type]="BUTTON_TYPES.PRIMARY"
                            [label]="'Download'"
                            (onClick)="downloadCustomQRCode()"
                            [width]="'100%'">
                        </beaconstac-button>
                    </div>
                </div>
            </li>
        </ul>

        <!-- option for file type -->
        <ul *ngIf="current_mobile_screen===DOWNLOAD_SCREENS.FILE_TYPE_SCREEN" class="download-options">
            <li  class="heading">
                FILE TYPE
            </li>
            <li class="options" *ngFor="let canvasType of (QR_CANVAS_TYPES | utilsObjectKeys)" (click)="selectFileType(canvasType)">
                {{canvasType}}
            </li>
        </ul>

        <!-- options for size -->
        <ul *ngIf="current_mobile_screen===DOWNLOAD_SCREENS.SIZE_SCREEN" class="download-options">
            <li  class="heading">
                SIZE
            </li>
            <li class="options" *ngFor="let size of qrSizeSelection" (click)="selectSize(size)">
                {{size.id}}
            </li>
        </ul>

        <!--option for error correction level -->
        <ul *ngIf="current_mobile_screen===DOWNLOAD_SCREENS.ERROR_CORRECTION_SCREEN" class="download-options">
            <li  class="heading">
                ERROR CORRECTION SCREEN
            </li>
            <li class="options" *ngFor="let errorCorrection of errorCorrectionLevelSelection" (click)="selectErrorCorrection(errorCorrection)">
                {{errorCorrection.id}}
                <span [ngSwitch]="errorCorrection.id">
                    <span *ngSwitchCase="'L'">Low</span>
                    <span *ngSwitchCase="'M'">Medium</span>
                    <span *ngSwitchCase="'Q'">High</span>
                    <span *ngSwitchCase="'H'">Highest</span>
                </span>
            </li>
        </ul>
    </section>
</beaconstac-mobile-drawer>

<div bsModal #openPreviewModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" style="z-index: 84757;">
    <div class="mobile-preview-layout">
            <div class="contract-btn" (click)="closePreview()">
                <svg class="circle" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <circle cx="16" cy="16" r="16" fill="#16212F" fill-opacity="0.5"/>
                </svg>
                <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M2.5675 16.1183L6.11443 12.5714L4.93865 11.4632C4.39865 10.9232 4.78107 9.99994 5.54472 9.99994H9.54472C10.0181 9.99994 10.4001 10.3837 10.4001 10.8571V14.8571C10.4001 15.6207 9.47861 16.0032 8.93865 15.4632L7.82872 14.2857L4.28179 17.8326C4.05865 18.0557 3.69682 18.0557 3.47368 17.8326L2.5675 16.9264C2.34436 16.7033 2.34436 16.3414 2.5675 16.1183ZM18.2328 3.88158L14.6859 7.42851L15.8616 8.53669C16.4016 9.07669 16.0192 9.99994 15.2556 9.99994H11.2556C10.7822 9.99994 10.4001 9.61619 10.4001 9.1428V5.1428C10.4001 4.37915 11.3217 3.99672 11.8616 4.53669L12.9716 5.71422L16.5185 2.1673C16.7416 1.94415 17.1035 1.94415 17.3266 2.1673L18.2328 3.07347C18.4559 3.29662 18.4559 3.65844 18.2328 3.88158Z" fill="white"/>
                </svg>
            </div>
            <iframe class="mobile-preview" style="position: relative; background-color: #FFFFFF;" #dbcMobileTwoPreview></iframe>
    </div>
</div>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="ismoreoptionDrawerOpen" (onOutsideClick)="checkMoreOptionsDrawerOpen()" (onShow)="!isNewDigitalBusinessCard ? setMobileActionsItems(selectedDigitalBusinessCard) :''" >
    <section>
        <!-- MORE OPTION SCREEN -->
        <div *ngIf="current_more_option_screen===MORE_OPTIONS_SCREEN.MORE_OPTIONS" >
            <div class="more_options_layout">
                <div *ngFor="let option of mobileActionsOptions; let i = index">

                    <div class="more-options pt-4" [ngClass]="{'text-danger': option.name === 'Delete Card', 'pt-4': i !== 0 }" (click)="handleMoreOptionsMobileClick(option.value)">
                        {{ option.name }}
                    </div>
                </div>
            </div>
        </div>

        <!-- CHANGE OWNER SCREEN -->
        <div *ngIf="current_more_option_screen===MORE_OPTIONS_SCREEN.CHANGE_OWNER" (onShow)="checkCurrentMaintainer()">
            <div class="change_owner_layout">
                <div class="mobile_drawer_heading">Change Owner</div>
                <div class="mobile-drawer-subheading">Choose a user in this organization to change ownership of this Card</div>
                <div *ngIf="!maintainerList.length">No user found in organization</div>
                <div class="" style="width: 100%;" *ngIf="maintainerList.length">
                    <beaconstac-filter-dropdown
                        [label]="'User'"
                        [title]="'User-mobile'"
                        [value]="currentProductOwner"
                        [optionLabelField]="'username'"
                        [optionValueField]="'id'"
                        [listItems]="maintainerList"
                        [width]="''"
                        (inputChangeEvent) = "fetchUsersByEmail($event)"
                        (valueChange)="setNewOwner($event)"
                    ></beaconstac-filter-dropdown>
                </div>

                <div class="change_owner_button">
                    <div style=" width:50%;" >
                        <beaconstac-button
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style]="BUTTON_STYLES.STROKE"
                        [label]="'Cancel'"
                        (onClick)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.MORE_OPTIONS)"
                        [width]="'100%'">
                        </beaconstac-button>
                    </div>
                    <div style=" width:50%;" >
                        <beaconstac-button
                        [disabled]="disableTransferOwnerButton"
                        [type]="BUTTON_TYPES.PRIMARY"
                        [label]="'Change'"
                        (onClick)="changeProductOwner(true)"
                        [width]="'100%'">
                        </beaconstac-button>
                    </div>
                </div>

            </div>
        </div>

        <!-- CHANGE ORGANIZATION -->
        <div *ngIf="current_more_option_screen===MORE_OPTIONS_SCREEN.CHANGE_ORGANIZATION">
           <div class="change_owner_layout">
                <div class="mobile_drawer_heading">Change organization</div>
                <div class="mobile-drawer-subheading">Move this Card to any of your other organizations. </div>
                <div class="" style="width: 100%;">
                    <beaconstac-filter-dropdown
                        [label]="'Organization'"
                        [title]="'Organization-mobile'"
                        [optionLabelField]="'name'"
                        [optionValueField]="'id'"
                        [listItems]="organizationList"
                        [width]="''"
                        [value]="currentProductOrg"
                        (inputChangeEvent) = "fetchOrganizationsByName($event)"
                        (valueChange)="setNewOrg($event)"
                    ></beaconstac-filter-dropdown>
                </div>

                <div class="change_owner_button">
                    <div style=" width:50%;" >
                        <beaconstac-button
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style]="BUTTON_STYLES.STROKE"
                        [label]="'Cancel'"
                        (onClick)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.MORE_OPTIONS)"
                        [width]="'100%'">
                        </beaconstac-button>
                    </div>
                    <div style=" width:50%;">
                        <beaconstac-button [disabled]="disableTransferOrgButton" [type]="BUTTON_TYPES.PRIMARY"
                                    [label]="'Transfer'"
                                    (onClick)="transferProductOrg(true);"
                                    [width]="'100%'"
                        ></beaconstac-button>
                    </div>
                </div>

           </div>
        </div>

        <!-- DELETE CARD SCREEN -->
        <div *ngIf="current_more_option_screen===MORE_OPTIONS_SCREEN.DELETE_CARD">
            <div class="change_owner_layout">
                <div class="mobile_drawer_heading">Delete Card?</div>
                <div class="mobile-drawer-subheading">Once deleted, you will lose access to this Card permanently.</div>
                <div class="change_owner_button">
                    <div style=" width:50%;" >
                        <beaconstac-button
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style]="BUTTON_STYLES.STROKE"
                        [label]="'Cancel'"
                        (onClick)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.MORE_OPTIONS)"
                        [width]="'100%'">
                        </beaconstac-button>
                    </div>
                    <div style=" width:50%;">
                        <beaconstac-button
                        [type]="BUTTON_TYPES.DANGER"
                        [label]="'Delete'"
                        (onClick)="deleteCard()"
                        [width]="'100%'">
                        </beaconstac-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- BULK DELETE SCREEN -->
        <div *ngIf="current_more_option_screen===MORE_OPTIONS_SCREEN.BULK_DELETE" class="change_owner_layout">
            <div class="mobile_drawer_heading">Delete cards permanently?</div>
            <div class="mobile-drawer-subheading">This action cannot be undone.</div>
            <div class="info_box">
                <div class="info-size">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C6.47754 2 2 6.47915 2 12C2 17.5241 6.47754 22 12 22C17.5225 22 22 17.5241 22 12C22 6.47915 17.5225 2 12 2ZM12 6.43548C12.9353 6.43548 13.6935 7.19371 13.6935 8.12903C13.6935 9.06435 12.9353 9.82258 12 9.82258C11.0647 9.82258 10.3065 9.06435 10.3065 8.12903C10.3065 7.19371 11.0647 6.43548 12 6.43548ZM14.2581 16.6774C14.2581 16.9446 14.0414 17.1613 13.7742 17.1613H10.2258C9.95859 17.1613 9.74194 16.9446 9.74194 16.6774V15.7097C9.74194 15.4425 9.95859 15.2258 10.2258 15.2258H10.7097V12.6452H10.2258C9.95859 12.6452 9.74194 12.4285 9.74194 12.1613V11.1935C9.74194 10.9263 9.95859 10.7097 10.2258 10.7097H12.8065C13.0737 10.7097 13.2903 10.9263 13.2903 11.1935V15.2258H13.7742C14.0414 15.2258 14.2581 15.4425 14.2581 15.7097V16.6774Z" fill="#2595FF"/>
                    </svg>
                </div>
                <div>Only Admins can delete digital business cards created through Microsoft Entra ID.</div>
            </div>
            <div class="change_owner_button">
                <div style=" width:50%;" >
                    <beaconstac-button
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.STROKE"
                    [label]="'Cancel'"
                    (onClick)="checkMoreOptionsDrawerOpen()"
                    [width]="'100%'">
                    </beaconstac-button>
                </div>
                <div style=" width:50%;">
                    <beaconstac-button
                    [type]="BUTTON_TYPES.DANGER"
                    [label]="'Delete'"
                    (onClick)="bulkDeletePermanentlyCards()"
                    [width]="'100%'">
                    </beaconstac-button>
                </div>
            </div>
        </div>

        <!-- BULK DOWNLOAD SCREEN -->
        <div *ngIf="current_more_option_screen===MORE_OPTIONS_SCREEN.BULK_DOWNLOAD" class="change_owner_layout">
            <div class="mobile_drawer_heading">Download multiple cards</div>
            <div class="info_box">
                <div class="info-size">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C6.47754 2 2 6.47915 2 12C2 17.5241 6.47754 22 12 22C17.5225 22 22 17.5241 22 12C22 6.47915 17.5225 2 12 2ZM12 6.43548C12.9353 6.43548 13.6935 7.19371 13.6935 8.12903C13.6935 9.06435 12.9353 9.82258 12 9.82258C11.0647 9.82258 10.3065 9.06435 10.3065 8.12903C10.3065 7.19371 11.0647 6.43548 12 6.43548ZM14.2581 16.6774C14.2581 16.9446 14.0414 17.1613 13.7742 17.1613H10.2258C9.95859 17.1613 9.74194 16.9446 9.74194 16.6774V15.7097C9.74194 15.4425 9.95859 15.2258 10.2258 15.2258H10.7097V12.6452H10.2258C9.95859 12.6452 9.74194 12.4285 9.74194 12.1613V11.1935C9.74194 10.9263 9.95859 10.7097 10.2258 10.7097H12.8065C13.0737 10.7097 13.2903 10.9263 13.2903 11.1935V15.2258H13.7742C14.0414 15.2258 14.2581 15.4425 14.2581 15.7097V16.6774Z" fill="#2595FF"/>
                    </svg>
                </div>
                <div>Please note: You can only download 10 Cards at a time</div>
            </div>

            <div style="width: 100%;">
                <beaconstac-filter-dropdown
                    [label]="'File Type'"
                    [title]="'file-type-mobile'"
                    [value]="defaultCanvasType"
                    [optionLabelField]="'id'"
                    [optionValueField]="'value'"
                    [listItems]="canvasTypeSelection"
                    [width]="'100%'"
                    (valueChange)="customDownloadOptions.canvasType=($event).value"
                ></beaconstac-filter-dropdown>
            </div>

            <div style="width: 100%;">
                <beaconstac-filter-dropdown
                    [label]="'Size'"
                    [title]="'size-mobile'"
                    [value]="defaultQrSize"
                    [optionLabelField]="'id'"
                    [optionValueField]="'value'"
                    [listItems]="qrSizeSelection"
                    [width]="'100%'"
                    (valueChange)="customDownloadOptions.size=($event).value"
                ></beaconstac-filter-dropdown>
            </div>

            <div style="width: 100%;">
                <beaconstac-filter-dropdown
                    [label]="'Error correction level'"
                    [title]="'error-correction-level-mobile'"
                    [value]="defaultErrorCorrectionLevel"
                    [optionLabelField]="'id'"
                    [optionValueField]="'value'"
                    [listItems]="errorCorrectionLevelSelection"
                    [width]="'100%'"
                    (valueChange)="customDownloadOptions.correctLevel=($event).value"
                ></beaconstac-filter-dropdown>
            </div>

            <div class="change_owner_button">
                <div style="width:50%;">
                    <beaconstac-button
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style]="BUTTON_STYLES.STROKE"
                        [label]="'Cancel'"
                        (onClick)="checkMoreOptionsDrawerOpen()"
                        [width]="'100%'">
                    </beaconstac-button>
                </div>
                <div style="width:50%;">
                    <beaconstac-button
                        [type]="BUTTON_TYPES.PRIMARY"
                        [label]="'Download'"
                        (onClick)="bulkDownloadDbcQRCodes()"
                        [width]="'100%'">
                    </beaconstac-button>
                </div>
            </div>
        </div>


        <!-- BULK SHARE WALLET PASS SCREEN -->
        <div *ngIf="current_more_option_screen===MORE_OPTIONS_SCREEN.BULK_SHARE_WALLET_PASS" class="change_owner_layout">
            <div class="mobile_drawer_heading">Share Wallet passes</div>
            <div class="mobile-drawer-subheading">Wallet passes will be shared with their respective Card owners on email with a secure link.</div>
            <div class="info_box">
                <div class="info-size">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C6.47754 2 2 6.47915 2 12C2 17.5241 6.47754 22 12 22C17.5225 22 22 17.5241 22 12C22 6.47915 17.5225 2 12 2ZM12 6.43548C12.9353 6.43548 13.6935 7.19371 13.6935 8.12903C13.6935 9.06435 12.9353 9.82258 12 9.82258C11.0647 9.82258 10.3065 9.06435 10.3065 8.12903C10.3065 7.19371 11.0647 6.43548 12 6.43548ZM14.2581 16.6774C14.2581 16.9446 14.0414 17.1613 13.7742 17.1613H10.2258C9.95859 17.1613 9.74194 16.9446 9.74194 16.6774V15.7097C9.74194 15.4425 9.95859 15.2258 10.2258 15.2258H10.7097V12.6452H10.2258C9.95859 12.6452 9.74194 12.4285 9.74194 12.1613V11.1935C9.74194 10.9263 9.95859 10.7097 10.2258 10.7097H12.8065C13.0737 10.7097 13.2903 10.9263 13.2903 11.1935V15.2258H13.7742C14.0414 15.2258 14.2581 15.4425 14.2581 15.7097V16.6774Z" fill="#2595FF"/>
                    </svg>
                </div>
                <div>No passes will be created for Cards without an email address.</div>
            </div>
            <div class="change_owner_button">
                <div style=" width:50%;" >
                    <beaconstac-button
                    [type]="BUTTON_TYPES.SECONDARY"
                    [style]="BUTTON_STYLES.STROKE"
                    [label]="'Cancel'"
                    (onClick)="checkMoreOptionsDrawerOpen()"
                    [width]="'100%'">
                    </beaconstac-button>
                </div>
                <div style=" width:50%;">
                    <beaconstac-button
                    [type]="BUTTON_TYPES.PRIMARY"
                    [label]="'Share'"
                    (onClick)="handleConfirmShareWalletPassModal()"
                    [width]="'100%'">
                    </beaconstac-button>
                </div>
            </div>
        </div>
    </section>
</beaconstac-mobile-drawer>

<div bsModal #openDowloadShareWalletPassModal="bs-modal" role="dialog"  *ngIf="selectedCards?.length && isMobileScreen" class="Download-share-wallet-pass-layout">
    <div class="Download-share-wallet-pass">
        <div style=" width:50%;" >
            <beaconstac-button
            [type]="BUTTON_TYPES.SECONDARY"
            [label]="'Download'"
            [style]="BUTTON_STYLES.STROKE"
            (onClick)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.BULK_DOWNLOAD)"
            [width]="'100%'">
            </beaconstac-button>
        </div>

        <div style=" width:50%;">
            <beaconstac-button
            [type]="BUTTON_TYPES.PRIMARY"
            [label]="'Share Wallet Pass'"
            (onClick)="moveBetweenMobileScreens(SWITCH_MOBILE_SCREEN.BULK_SHARE_WALLET_PASS)"
            [width]="'100%'">
            </beaconstac-button>
        </div>
    </div>

</div>
