<beaconstac-title class="title" title="Wallet Passes"
    subtitle="Manage Apple and Google Wallet pass designs here"></beaconstac-title>

<div class="grid">
    <div class="bx--row pr-3">
        <div class="bx--col-lg-10 big-box">
            <div class="bx--row tabs">
                <span class="bx--col-sm-2 tab" (click)="isAWP = true"
                    [style.color]="isAWP ? activeTabColour : inactiveTabColour"
                    [style.border-bottom]="isAWP ? '2px solid #2595FF' : ''">
                    <i class="fab fa-apple"></i><span class="d-sm-block">Apple Wallet</span>
                </span>
                <span class="bx--col-sm-2 tab" (click)="isAWP = false"
                    [style.color]="!isAWP ? activeTabColour : inactiveTabColour"
                    [style.border-bottom]="!isAWP ? '2px solid #2595FF' : ''">
                    <i class="fa fa-wallet fa-sm"></i><span class="d-sm-block">Google Wallet</span>
                </span>
            </div>
            <div class="settings" *ngIf="isAWP" [@collapse]>
                <div class="subtitle">
                    Modify your organization’s Apple Wallet pass
                </div>
                <!-- Color picker for AWP template -->
                <div class="d-flex" [ngClass]="isMobileScreen ? 'flex-wrap' : ''">
                    <div [ngClass]="isMobileScreen ? 'w-100' : 'w-50 pr-3'">
                        <beaconstac-color-picker class="d-block"
                                                 [label]="true"
                                                 [title]="'Background color'"
                                                 [(color)]="appleWalletPassCustomizations.backgroundColor"
                                                 [cpOutputFormat]="'hex'"
                                                 [cpPosition]="'bottom'"
                                                 [id]="'awp-background-color'"
                                                 [cpAlphaChannel]="'disabled'"
                                                 [cpWidth]="'100%'"
                                                 (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_BACKGROUND)"
                        ></beaconstac-color-picker>
                    </div>

                    <div [ngClass]="isMobileScreen ? 'w-100 pt-2' : 'w-50 pr-3'">
                        <beaconstac-color-picker class="d-block"
                                                 [label]="true"
                                                 [title]="'Text color'"
                                                 [id]="'text-colour'"
                                                 [(color)]="appleWalletPassCustomizations.textColor"
                                                 [cpOutputFormat]="'hex'"
                                                 [cpPosition]="'bottom'"
                                                 [cpAlphaChannel]="'disabled'"
                                                 [cpWidth]="'100%'"
                                                 (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_FOREGROUND)"
                        ></beaconstac-color-picker>
                    </div>

                </div>

                <div class="mt-2" [ngClass]="isMobileScreen ? 'w-100' : 'w-50 pr-3'">
                    <div class="d-flex justify-content-between pb-2">
                        <div class="font-fs-13 d-flex align-items-center">Change Wallet pass logo</div>
                        <div class="d-flex awp-tooltip">
                            <beaconstac-tooltip
                                class="d-flex align-items-center"
                                [elementClass]="'fas fa-info-circle'"
                                [color]="'#2595ff'"
                                [content]="'For best results, avoid transparent background and test the logo against the background color of your pass.'"
                                [width]="'200px'"
                                [image]="'../../../assets/img/Guidelines.png'"
                                [caretPosition]="CARET_POSITION.LEFT"
                                [tooltipPosition]="TOOLTIP_POSITION.TOP"
                            ></beaconstac-tooltip>
                            <div class="pl-1 d-flex align-items-center guidelines-button">
                                <beaconstac-button
                                    [type]="BUTTON_TYPES.PRIMARY"
                                    [style]="BUTTON_STYLES.TEXT"
                                    [label]="'Guidelines'"
                                    (onClick)="isGuidelinesModalOpen = true"
                                ></beaconstac-button>
                            </div>
                        </div>
                    </div>
                    <div class="awp-logo-img-upload-cont">
                        <beaconstac-upload
                            #uploadAWPLogoFile
                            [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                            [maxSize]="5e6"
                            [custom]="'213 x 67 px logo size, PNG & JPEG supported'"
                            [overrideClick]="true"
                            [width]="'100%'"
                            [height]="'auto'"
                            (onClick)="awpLogoImageSelectModal.show()"
                            [showRemoveButton]="true"
                            [preview]="appleWalletPassCustomizations.logo.url ? appleWalletPassCustomizations.logo.url : ''"
                            (onFileRemove)="onAWPLogoRemoved()">
                        </beaconstac-upload>
                    </div>
                </div>

                <div class="pt-4" *ngIf="!isMobileScreen"><beaconstac-button
                    label="Save" [type]="BUTTON_TYPES.PRIMARY"
                    (onClick)="updateAppleWalletPassPreview()"></beaconstac-button></div>
            </div>

            <div class="settings" *ngIf="!isAWP" [@collapse]>
                <div class="subtitle">
                    Modify your organization’s Google Wallet pass
                </div>
                <beaconstac-color-picker
                    [label]="true"
                    [title]="'Background color'"
                    [(color)]="googleWalletPassPreviewColor.backgroundColor"
                    [cpOutputFormat]="'hex'"
                    [cpPosition]="'bottom'"
                    [id]="'gwp-background-color'"
                    [cpAlphaChannel]="'disabled'"
                    (colorPickerChange)="onColorPickerChange($event, WALLET_PASS_CUSTOMIZATION.GOOGLE_WALLET_PASS_BACKGROUND)"
                ></beaconstac-color-picker>

            <div class="upload-container">

                <div>
                    <div class="tooltip_button">
                        <div style="font-size: 13px; font-weight: 400; line-height: 16px; letter-spacing: 0.25px;" class="pb-2 mt-2">
                            Change Wallet pass logo
                        </div>
                        <div class="bx--row mt-1">
                            <div *ngIf="isMobileScreen" class="icon-container">
                                <i class="fa-info-circle fas" style="color: rgb(37, 149, 255)"></i>
                            </div>

                            <div *ngIf="!isMobileScreen">
                                <beaconstac-tooltip
                                                    [elementClass]="'fas fa-info-circle'"
                                                    [color]="'#2595ff'"
                                                    [content]="'For best results, avoid transparent background and test the logo against the background color of your pass.'"
                                                    [width]="'200px'"
                                                    [image]="'../../../assets/img/Guidelines.png'"
                                                    [caretPosition]="CARET_POSITION.LEFT"
                                                    [tooltipPosition]="TOOLTIP_POSITION.TOP"
                                ></beaconstac-tooltip>
                            </div>

                            <div style="text-decoration: underline;font-weight: 400; font-size: 15px; line-height: 16px; letter-spacing: 0.25px;" class="pr-3 pl-1 guidelines-button">
                                <beaconstac-button
                                    [type]="'primary'"
                                    [style]="'text'"
                                    [label]="'Guidelines'"
                                    (onClick)="isGuidelinesModalOpen = true"
                                ></beaconstac-button>
                            </div>
                        </div>
                    </div>

                    <div class="mb-2" [ngStyle]="isMobileScreen ? {'margin-right': '0px'} : {'margin-right': '10px'}">
                        <beaconstac-upload
                            #uploadLogoFiles
                            [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                            [maxSize]="5e6"
                            [custom]="'160 x 160 px logo size, PNG & JPEG supported'"
                            [overrideClick]="true"
                            [width]="'270px'"
                            [height]="'150px'"
                            (onClick)="imageSelectModalLogo.show()"
                            [showRemoveButton]="true"
                            [preview]="googleWalletPassPreviewLogo.logo_url ? googleWalletPassPreviewLogo.logo_url : ''"
                            (onFileRemove)="onLogoRemoved('logo')">
                        </beaconstac-upload>
                    </div>
                </div>

                <div>
                    <div class="tooltip_button">
                        <div style="font-size: 13px; font-weight: 400; line-height: 16px; letter-spacing: 0.25px;" class="pb-2 mt-2">
                            Upload hero image
                        </div>
                        <div class="bx--row mt-1">
                            <div *ngIf="isMobileScreen" class="icon-container">
                                <i class="fa-info-circle fas" style="color: rgb(37, 149, 255)"></i>
                            </div>

                            <div *ngIf="!isMobileScreen">
                            <beaconstac-tooltip
                                                [elementClass]="'fas fa-info-circle'"
                                                [color]="'#2595ff'"
                                                [content]="'For best results, use a wide, rectangular image, preferably of the recommended size, with a colored background.'"
                                                [width]="'200px'"
                                                [image]="'../../../assets/img/Guidelines2.jpeg'"
                                                [caretPosition]="CARET_POSITION.RIGHT"
                            ></beaconstac-tooltip>
                            </div>

                            <div style="text-decoration: underline;font-weight: 400; font-size: 15px; line-height: 16px; letter-spacing: 0.25px;" class="pr-3 pl-1 guidelines-button">
                                <beaconstac-button
                                    [type]="'primary'"
                                    [style]="'text'"
                                    [label]="'Guidelines'"
                                    (onClick)="isHeroImageGuidelinesModalOpen = true"
                                ></beaconstac-button>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <beaconstac-upload
                            #uploadHeroImage
                            [acceptTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                            [maxSize]="5e6"
                            [custom]="'1032 x 336 px logo size, PNG & JPEG supported'"
                            [overrideClick]="true"
                            [width]="'270px'"
                            [height]="'150px'"
                            (onClick)="imageSelectModalHeroImage.show()"
                            [showRemoveButton]="true"
                            [preview]="googleWalletPassPreviewHeroImage.hero_image_url ? googleWalletPassPreviewHeroImage.hero_image_url : ''"
                            (onFileRemove)="onLogoRemoved('heroImage')">
                        </beaconstac-upload>
                    </div>
                </div>

            </div>
                <div class="mt-2" *ngIf="!isMobileScreen"><beaconstac-button
                    label="Save"
                    [type]="BUTTON_TYPES.PRIMARY"
                    (onClick)="updateGoogleWalletPassPreview()"></beaconstac-button></div>
                </div>
        </div>

        <div class="bx--col-lg-5 align-items-center justify-content-center preview-cont" *ngIf="!isMobileScreen">
            <beaconstac-apple-wallet-pass-preview *ngIf="isAWP"
                [backgroundColor]=appleWalletPassCustomizations.backgroundColor
                [textColor]=appleWalletPassCustomizations.textColor [designation]="'DESIGNATION'" [companyName]="'Your Company Name'"
                [logo]="appleWalletPassCustomizations.logo.url"
                [firstName]="'John'" [lastName]="'Doe'" [email]="'john.d@company.com'" [profilePhoto]="profilePictureUrl"></beaconstac-apple-wallet-pass-preview>

            <beaconstac-google-wallet-pass-preview *ngIf="!isAWP"
                [backgroundColor]=googleWalletPassPreviewColor.backgroundColor
                [textColor]=googleWalletPassPreviewColor.textColor [firstName]="'John'" [lastName]="'Doe'"
                [email]="'john.d@company.com'" [phone]="'+1 23456 7890'" [website]="'www.amazon.com'" [designation]="'CXO, Founding Designer'" company="Your Company Name" [logo]="googleWalletPassPreviewLogo.logo_url ? googleWalletPassPreviewLogo.logo_url : ''" [heroImage]="googleWalletPassPreviewHeroImage.hero_image_url ? googleWalletPassPreviewHeroImage.hero_image_url : ''"></beaconstac-google-wallet-pass-preview>
        </div>
    </div>

</div>

<div class="modal-wrapper-gwp modal-wrapper bg-white" *ngIf="isMobileScreen">
    <button class="btn create-card-dbc-btn text-white border-0 float-right d-flex flex-row justify-content-center align-items-center preview-button"
    (click)="isPreviewDrawerOpen = true">
    Preview</button>
    <button class="btn create-card-dbc-btn text-white border-0 float-right d-flex flex-row justify-content-center align-items-center save-btn"
    (click)="updateWalletPassPreview()">
    Save</button>
</div>


<div bsModal #imageSelectModalLogo="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" (onShow)="setupImageSelectModal('logo')">
   <div class="modal-dialog modal-info modal-lg" role="document">
       <div class="modal-content modal-content-kz">

            <div class="modal-header-kz">
                <h4 class="modal-title">Select an image</h4>
            </div>
           <div class="modal-body modal-body-kz">
               <div class="form-group">
                <app-media-gallery #imageSelectGalleryLogo
                    [isSelectDisabled]="false"
                    (mediaSelector)="imageSelectedInGallery($event,'logo')"
                    [addMedia]="updatedMediaLogo"
                    (uploadToFolderId)="setUploadToFolder($event)"
                    [searchParams]="{'content_type__nin':'image/gif'}"
                    [isFooterVisible]="true"
                    [acceptedTypes]="['image/png', 'image/jpeg']"
                    (onClose)="imageSelectModalLogo.hide()"
                    (fileUploaded)="loadCropper($event, 'logo')"
                    (onSelect)="selectImage('logo')"
                    >
                </app-media-gallery>
               </div>
           </div>
       </div>
   </div>
</div>

<div bsModal #imageSelectModalHeroImage="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" (onShow)="setupImageSelectModal('heroImage')">
    <div class="modal-dialog modal-info modal-lg" role="document">
        <div class="modal-content modal-content-kz">

             <div class="modal-header-kz">
                 <h4 class="modal-title">Select an image</h4>
             </div>
            <div class="modal-body modal-body-kz">
                <div class="form-group">
                 <app-media-gallery #imageSelectGalleryHeroImage
                     [isSelectDisabled]="false"
                     (mediaSelector)="imageSelectedInGallery($event,'heroImage')"
                     [addMedia]="updatedMediaHeroImage"
                     (uploadToFolderId)="setUploadToFolder($event)"
                     [searchParams]="{'content_type__nin':'image/gif'}"
                     [isFooterVisible]="true"
                     [acceptedTypes]="['image/jpeg', 'image/png']"
                     (onClose)="imageSelectModalHeroImage.hide()"
                     (fileUploaded)="loadCropper($event, 'heroImage')"
                     (onSelect)="selectImage('heroImage')"
                     >
                 </app-media-gallery>
                </div>
            </div>
        </div>
    </div>
 </div>

<app-image-cropper #imageCropperLogo *ngIf="imageCropperFile" [imageFile]="imageCropperFile"
                   (cropperClose)="closeImageCropperModal()" [squareDragMode]="true" [folderId]="uploadToFolder"
                   (imageUpdated)="updateMedia($event,'logo')" [imageAspectRatio]="'1:1'" [showUploadOriginal]="true"></app-image-cropper>

<app-image-cropper #imageCropperHeroImage *ngIf="imageCropperFile" [imageFile]="imageCropperFile"
                   (cropperClose)="closeImageCropperModal()" [squareDragMode]="true" [folderId]="uploadToFolder"
                   (imageUpdated)="updateMedia($event,'heroImage')" [imageAspectRatio]="'1:1'" [showUploadOriginal]="true"></app-image-cropper>


<div *ngIf="isMobileScreen">
    <div class="bx--col-lg-5 align-items-center justify-content-center preview-cont">
        <beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isPreviewDrawerOpen" (onOutsideClick)="isPreviewDrawerOpen = false;">
            <section style="max-height: 500px;overflow-y: scroll;">
                <div class="wallet-pass-wrapper">
                        <beaconstac-apple-wallet-pass-preview *ngIf="isAWP"
                        [backgroundColor]=appleWalletPassCustomizations.backgroundColor [logo]="appleWalletPassCustomizations.logo.url"
                        [textColor]=appleWalletPassCustomizations.textColor [designation]="'DESIGNATION'" [companyName]="'Your Company Name'"
                        [firstName]="'John'" [lastName]="'Doe'" [email]="'john.d@company.com'" [profilePhoto]="profilePictureUrl">
                        </beaconstac-apple-wallet-pass-preview>

                        <beaconstac-google-wallet-pass-preview *ngIf="!isAWP"
                        [backgroundColor]=googleWalletPassPreviewColor.backgroundColor
                        [textColor]=googleWalletPassPreviewColor.textColor [firstName]="'John'" [lastName]="'Doe'"
                        [email]="'john.d@company.com'" [phone]="'+1 23456 7890'" [website]="'www.amazon.com'" [designation]="'CXO, Founding Designer'" company="Your Company Name" [logo]="googleWalletPassPreviewLogo.logo_url ? googleWalletPassPreviewLogo.logo_url : ''" [heroImage]="googleWalletPassPreviewHeroImage.hero_image_url ? googleWalletPassPreviewHeroImage.hero_image_url : ''"></beaconstac-google-wallet-pass-preview>
                </div>

                <div class="modal-wrapper-gwp modal-wrapper bg-white">
                    <beaconstac-button
                        class="w-100"
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style]="BUTTON_STYLES.STROKE"
                        [width]="'100%'"
                        [label]="'Go Back'"
                        (onClick)="isPreviewDrawerOpen = false">
                    </beaconstac-button>
                </div>

            </section>
        </beaconstac-mobile-drawer>

        <beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isGuidelinesModalOpen" (onOutsideClick)="isGuidelinesModalOpen = false;">
            <div class="align-items-center justify-content-center gwp-preview-cont bg-white d-flex flex-column">
                <img src="../../../assets/img/Guidelines.png" class="guidelines-img mt-4 mb-3">

                <div class="pl-3 pr-3 modal-text mb-5">
                    For best results, use a circular logo. Avoid transparent background and test the logo against the background color of your pass.
                </div>

                <div class="pb-4 mt-2" style="width: 100%;">
                    <beaconstac-button
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style]="BUTTON_STYLES.STROKE"
                        [width]="'100%'"
                        [label]="'Go Back'"
                        (onClick)="isGuidelinesModalOpen = false; "
                    >
                    </beaconstac-button>
                </div>
            </div>
        </beaconstac-mobile-drawer>

        <beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isHeroImageGuidelinesModalOpen" (onOutsideClick)="isHeroImageGuidelinesModalOpen = false;">
            <div class="align-items-center justify-content-center gwp-preview-cont gwp-default-styles bg-white mb-5 d-flex flex-column">
                <img src="../../../assets/img/Guidelines2.jpeg" class="guidelines-img mt-4 mb-3">

                <div class="pl-3 pr-3 modal-text mb-5">
                    For best results, use a wide, rectangular image, preferably of the recommended size, with a colored background.
                </div>

                <div class="pb-4 mt-2" style="width: 100%;">
                    <beaconstac-button
                        [type]="'secondary'"
                        [style]="'stroke'"
                        [width]="'100%'"
                        [label]="'Go Back'"
                        (onClick)="isHeroImageGuidelinesModalOpen = false;"
                    >
                    </beaconstac-button>
                </div>
            </div>
        </beaconstac-mobile-drawer>
    </div>
</div>


<div bsModal #awpLogoImageSelectModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" (onShow)="setupAWPLogoImgSelectModal()">
    <div class="modal-dialog modal-info modal-lg" role="document">
        <div class="modal-content modal-content-kz">

            <div class="modal-header-kz">
                <h4 class="modal-title">Select an image</h4>
            </div>
            <div class="modal-body modal-body-kz">
                <div class="form-group">
                    <app-media-gallery #awpLogoImageSelectMediaGallery
                                       [isSelectDisabled]="false"
                                       (mediaSelector)="onAWPLogoImgSelect($event)"
                                       [addMedia]="awpUpdatedMediaLogo"
                                       (uploadToFolderId)="setupAWPLogoUploadPath($event)"
                                       [searchParams]="{'content_type__nin':'image/gif'}"
                                       [isFooterVisible]="true"
                                       [acceptedTypes]="['image/png', 'image/jpeg', 'image/svg+xml']"
                                       (onClose)="awpLogoImageSelectModal.hide()"
                                       (fileUploaded)="setupAWPLogoImgCropper($event)"
                                       (onSelect)="selectAWPLogoImg()"
                    >
                    </app-media-gallery>
                </div>
            </div>
        </div>
    </div>
</div>

<app-image-cropper #imageCropperAWPLogoImg *ngIf="awpLogoImgCropperFile" [imageFile]="awpLogoImgCropperFile"
                   (cropperClose)="closeAWPLogoImgCropperModal()" [squareDragMode]="true" [folderId]="awpLogoImgUploadToFolder"
                   (imageUpdated)="updateAWPLogoMedia($event)" [imageAspectRatio]="'1:1'" [showUploadOriginal]="true"></app-image-cropper>

