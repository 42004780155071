import { Component, Input } from '@angular/core';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';

@Component({
    selector: 'app-bottom-sticky-buttons',
    templateUrl: './bottom-sticky-buttons.component.html',
    styleUrls: ['./bottom-sticky-buttons.component.scss']
})
export class BottomStickyButtonsComponent {

    @Input() primaryCtaName: string;
    @Input() secondaryCtaName: string;
    @Input() tertiaryCtaAction: () => void;
    @Input() primaryCtaAction: () => void;
    @Input() secondaryCtaAction: () => void;
    @Input() isTertiaryButtonActive: boolean;
    @Input() showTertiaryButton!: boolean; // should check why the ! is there
    @Input() tertiaryButtonText: string;
    @Input() primaryButtonDisabled: boolean;
    @Input() secondaryButtonDisabled: boolean;

    protected readonly BUTTON_STYLES = BUTTON_STYLES;
    protected readonly BUTTON_TYPES = BUTTON_TYPES;

}
