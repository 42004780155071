import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BeaconstacModule} from 'kaizen-design-system';
import {CsvValidationComponent} from './csv-validation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../global-components/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        BeaconstacModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule
    ],
    declarations: [
        CsvValidationComponent,
    ],
    exports: [
        CsvValidationComponent,
    ],
    providers: [
    ]
})
export class CsvValidationModule {
}
