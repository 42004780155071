<div bsModal #qrTagUntagModal="bs-modal" class="modal fade" role="dialog" tabindex="-1" [config]="{'backdrop':'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content p-2">

      <div class="modal-header border-0">
        <h4 class="modal-title">Manage labels</h4>
        <h4 class="float-right">
          <button class="cancel-btn-color" (click)="cancelQrTagUntagModal()"><i class="fas fa-times "></i></button>
        </h4>
      </div>
      <div class="modal-body py-0">
        <div *ngIf="message" class="row mb-3 text-secondary">
          <a class="col-12">
            <span [innerHTML]="message"></span>
          </a>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="d-flex" style="height:3rem">
              <div class="search-label" *ngIf="!isAttachingLabel">
                <beaconstac-search (textChange)="onTagSearchInput($event)" [id]="'label'"></beaconstac-search>
              </div>
            </div>

            <div style="height: 15rem; overflow: scroll;" class="mt-3">
              <div *ngIf="isFetchingLabels || isAttachingLabel" class="align-spinner">
                <spinner style="margin-left: 12px" [tickness]="3" size="60"></spinner>
              </div>
              <div *ngIf="!isFetchingLabels && !isAttachingLabel">
                <div *ngFor="let tag of tagList; index as i" class="tag-container">
                  <div style="flex: 2" class="d-flex mr-auto cursor-pointer">
                    <div class="label-item">
                      <beaconstac-checkbox
                        [checked]="(tag['state'] === 1)"
                        [indeterminate]="(tag['state'] === 2)"
                        (checkboxStateHandler)="tagStateChange($event, i)"
                        label="{{tag.name.length < 20 ? tag.name : (tag.name | sliceValues : 20) + '...'}} ({{tag | getTagCount: productType}})"
                      ></beaconstac-checkbox>

                    </div>
                  </div>
                  <div class="ml-1 p-1 label-color">
                    <span><i class="fas fa-circle icon-size" [ngStyle]="{color:tag.color}"  aria-hidden="true"></i></span>
                  </div>
                  <div class="ml-1 p-1 label-edit" [ngStyle]="{'cursor' : (isEditor && (tag.maintainer !== currentUser)) ? 'not-allowed' : 'pointer'}"
                       (click)="showUpdateLabelModal('Edit Label', tag, isEditor && (tag.maintainer !== currentUser))">
                  <span>
                    <i class="far fa-edit text-primary icon-size" aria-hidden="true"></i>
                  </span>
                  </div>
                  <div class="ml-1 p-1 label-delete cursor-pointer"  [ngStyle]="{'cursor' : (isEditor && (tag.maintainer !== currentUser)) ? 'not-allowed' : 'pointer'}" (click)="deleteLabel(tag, isEditor && (tag.maintainer !== currentUser))">
                  <span>
                    <i class="far fa-trash-alt beaconstac-danger icon-size" aria-hidden="true"></i>
                  </span>
                  </div>
                </div>
              </div>
              <div class="text-center" *ngIf="(tagSearchString?.length > 0 && !tagWithNameExists) && tagList.length === 0">
                <div class="text-secondary my-2">No label found!</div>
                  <beaconstac-button
                    [style]="BUTTON_STYLES.STROKE"
                    label="Create label for {{tagSearchString}}"
                    icon="fas fa-plus"
                    (onClick)="showUpdateLabelModal('New Label',{color: '#C9C9C9'})">
                  </beaconstac-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-3" style="height:4rem">
        <div class="float-left" *ngIf="tagList.length > 0 && !isFetchingLabels && !isAttachingLabel || (tagList.length === 0 && tagSearchString?.length === 0)">
          <beaconstac-button
            [style]="BUTTON_STYLES.STROKE"
            label="New Label"
            icon="fas fa-plus"
            (onClick)="showUpdateLabelModal('New Label',{color: '#C9C9C9'})">
          </beaconstac-button>
        </div>
        <div class="float-right" *ngIf="!isAttachingLabel">
          <beaconstac-button
            (onClick)="tagUntagQRCodes(operationMethod)" [disabled]="addLabelApplyButtonDisabled"
            [label]="'Confirm'">
          </beaconstac-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #labelUpdateModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="dialog-nested-name2">
  <div class="modal-dialog">
    <div class="modal-content p-2">
      <div class="modal-header border-0">
        <h4 id="dialog-nested-name2" class="modal-title pull-left">{{labelUpdateModalTitle}}</h4>
        <h4 class="float-right">
          <button class="cancel-btn-color" (click)="hideLabelUpdateModal()"><i class="fas fa-times "></i></button>
        </h4>
      </div>
      <div *ngIf="selectedTag" class="modal-body py-0">
        <div class="row" style="height: 85px">
          <div class="col-12">
              <beaconstac-textfield
                [(text)]="tagSearchString"
                [label]="true"
                [title]="'Name'"
                [placeholder]="'Enter Name'"
                [maxWidth]="'webkit-fill-available'"
                (textChange)="getTagsWithName($event)"
                [valid]="tagNameAvailable ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR"
                [errorMessage]="'This label already exists.'"
              ></beaconstac-textfield>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div style="margin-bottom: 5px;" class="mt-2">
              <span style="font-weight: 800; color: #5e5e5e; font-size: 12px; padding-left: 2px;" class="text-secondary">CHOOSE COLOR</span>
            </div>
            <div class="d-flex flex-wrap align-content-start" style="height: 15rem">
              <div *ngFor="let color of labelColors" [ngStyle]="{'background-color' : color}"
                   (click)="selectTagColor(color)"
                   class="label-color-block">
                <span *ngIf="selectedTag.color === color" style="color: #fff;">
                  <i class="fas fa-check" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="float-right">
          <beaconstac-button
            (onClick)="hideLabelUpdateModal()"
            label="Back"
            [type]="BUTTON_TYPES.SECONDARY">
          </beaconstac-button>
        </div>
        <div class="float-right" *ngIf="!selectedTag?.isNew">
          <beaconstac-button
            (onClick)="updateLabel()"
            label="Save"
            [disabled]="!(selectedTag?.name | trimValues) || !tagNameAvailable">
          </beaconstac-button>
        </div>
        <div class="float-right" *ngIf="selectedTag?.isNew">
          <beaconstac-button
            (onClick)="createLabel()"
            [disabled]="!(selectedTag?.name | trimValues) || !tagNameAvailable"
             label="Create">
          </beaconstac-button>
        </div>
      </div>
    </div>
  </div>
</div>
