

export const DBC_FEATURE_DETAILS_DATA = [
    {
        type: 'Number of users',
        description: ' ',
        plans: [
            {
                planType: 'SOLO',
                value: '1-9'
            },
            {
                planType: 'TEAM',
                value: '10-49'
            },
            {
                planType: 'BUSINESS',
                value: '50+'
            },
            {
                planType: 'ENTERPRISE',
                value: '50+'
            },
        ]
    },
    {
        type: 'Cards per user',
        description: ' ',
        plans: [
            {
                planType: 'SOLO',
                value: 3
            },
            {
                planType: 'TEAM',
                value: 5
            },
            {
                planType: 'BUSINESS',
                value: 5
            },
            {
                planType: 'ENTERPRISE',
                value: 'Unlimited'
            },
        ]
    },
    {
        type: 'Personalize your digital business cards',
        description: 'Design your digital business card with custom colors, layouts, and contact details.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Get branded QR Code',
        description: 'Personalize the QR Code for your digital business card with unique shapes, brand colors, and logos.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Share card via Apple/Google Wallet',
        description: 'Store your card in Apple/Google Wallet and share it with ease. No separate app needed.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Two-way contact sharing',
        description: 'Share your card with prospects and get their contact in return. (No app required)',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Contact manager',
        description: 'Organize your collected contacts in Uniqode dashboard and use them for sales/marketing campaigns.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Multi-lingual digital business cards',
        description: 'Network easily with a global audience by creating your card in multiple languages and letting your prospects see your contact details in their preferred language.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Custom Fonts & Style',
        description: 'Upload your custom fonts and use them for creating and editing your cards; match your brand\'s unique style, and add a personal touch to your projects.',
        plans: [
            {
                planType: 'SOLO',
                value: ''
            },
            {
                planType: 'TEAM',
                value: ''
            },
            {
                planType: 'BUSINESS',
                value: ''
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Advanced customizations',
        description: 'Take your digital card branding to the next level. With advanced customizations, you can add the right colors at the right place. Upload any image you like as your card background and shape the way your contacts perceive you.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'GPS tracking',
        description: 'Know where people are scanning your digital business card - narrowed down to the exact GPS coordinates.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Card analytics',
        description: 'Get a bird’s eye view of all your card saves and views—filter card data by time, location and device.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'GDPR compliant',
        description: 'Protect your user data as per GDPR compliance.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'SOC® 2 Type 2 compliant',
        description: 'Ensure top security and privacy standards with SOC® 2 Type 2 compliance.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Anomalous scan detection',
        description: 'Identify abnormal scan behavior and filter them out. Maintain data sanity and accurate campaign analytics.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Malware & phishing URL detection',
        description: 'Protect your users from malicious phishing attacks with our URL-based phishing detection.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'SSO integration via SAML',
        description: 'Ensure complete control and security with a singular company-level sign-in SAML account.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: '2-factor authentication (2FA)',
        description: 'Safeguard your data against phishing attacks and unauthorized access with 2FA.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Filling infosec questionnaire',
        description: 'Get your Infosec questionnaire filled out by us and be assured of a top-tier secure partnership.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Access to the trust portal',
        description: 'View all information related to Uniqode’s security and privacy practices in one place.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Short URL',
        description: 'Modify and shorten your URL slug to suit your digital business card’s messaging.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Custom domain',
        description: 'Customize the URL of the digital business card with your brand URL.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: 'Available as an add-on'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Add multiple users',
        description: 'Add your team members to your Uniqode account and set up unique login credentials.',
        plans: [
            {
                planType: 'SOLO',
                value: 'up to 9'
            },
            {
                planType: 'TEAM',
                value: 'up to 49'
            },
            {
                planType: 'BUSINESS',
                value: 'up to 249'
            },
            {
                planType: 'ENTERPRISE',
                value: 'Custom'
            },
        ]
    },
    {
        type: 'Role-based access control',
        description: 'Configure custom role-based access to different users.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Transfer ownership',
        description: 'Ensure smooth team member exits by transferring account ownership and admin access to the current employees.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Enforce card templates',
        description: 'Customize the URL of the digital business card with your brand URL.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Enforce non-editable card fields',
        description: 'Make certain information on a digital business card uneditable by the card recipient to ensure greater uniformity and brand consistency',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Configure separate organizations',
        description: 'Have multiple teams using digital business cards? Create separate organizations or workspaces and configure their access.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'Custom'
            },
        ]
    },
    {
        type: 'Map Microsoft Entra ID groups to different organizations',
        description: 'Map your Microsoft Entra ID groups to the organizations or workspaces you create in Uniqode dashboard.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Just-in-time user provisioning',
        description: 'Create a user account automatically the first time a user logs in with a SAML identity provider.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Transfer cards across organizations ',
        description: 'Use admin rights to ensure smooth migration of digital business cards across multiple organizations..',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Separate asset library per organization',
        description: 'Never lose your assets again. Create separate branding kits for different organizations and share them for company-wide reuse.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Control analytics export per organization',
        description: 'Export analytics reports of one or multiple digital business cards as CSV.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Bulk creation',
        description: 'Generate multiple digital business cards in a few minutes using a CSV file.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'API',
        description: 'Use Uniqode API to create, track, and manage all your digital business cards at scale.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Microsoft Entra ID',
        description: 'Manage employee permissions and access to digital business card resources with a seamless Uniqode and Microsoft Entra ID integration.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Salesforce',
        description: 'Automate follow-ups and outreach campaigns by transferring contacts collected with digital business cards into Salesforce.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Zapier',
        description: 'Move information between Uniqode cards and your web apps using Zapier.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Google Analytics',
        description: 'Connect Uniqode cards as a property on Google Analytics to get unique and advanced user insights.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Meta Pixel',
        description: 'Integrate with Meta Pixel to re-target users who viewed your card.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Google Ads',
        description: 'Integrate with Google Ads to re-target users who viewed your card.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Knowledge base',
        description: 'Explore articles, videos, and guides on anything you need about Uniqode.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Live chat',
        description: 'Unmatched customer support for all users via chat. Available anytime, anywhere.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Email support',
        description: 'Email our support team anytime, and we get back in a few hours with a solution.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Phone support',
        description: 'Call our digital business card experts and quickly resolve any issue.',
        plans: [
            {
                planType: 'SOLO',
                value: 'true'
            },
            {
                planType: 'TEAM',
                value: 'true'
            },
            {
                planType: 'BUSINESS',
                value: 'true'
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Dedicated customer success manager',
        description: 'Get personalized customer onboarding, training hours, and hands-on support.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Onboarding & setup',
        description: 'Get hands-on support during onboarding and setup of the Uniqode dashboard.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Redlining',
        description: 'Create negotiable, custom contracts for your subscription.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'Pay by invoice',
        description: ' ',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'true'
            },
        ]
    },
    {
        type: 'SLA',
        description: 'Create tailored and unique agreements that reflect your requirements and expectations.',
        plans: [
            {
                planType: 'SOLO',
                value: ' '
            },
            {
                planType: 'TEAM',
                value: ' '
            },
            {
                planType: 'BUSINESS',
                value: ' '
            },
            {
                planType: 'ENTERPRISE',
                value: 'Custom'
            },
        ]
    },
]
