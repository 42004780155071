// data for feature details
export const featureDetailsData = [
    {
        typeOfFeature: 'QR Code essentials',
        id: 'isQRCodeEssentialCollapsed',
        features: [
            {
                type: 'Dynamic QR Codes',
                description: 'Edit destination URL anytime. QR Code stays the same. Dynamic QR Codes are trackable.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: '3'
                    },
                    {
                        planType: 'LITE',
                        value: '50'
                    },
                    {
                        planType: 'PRO',
                        value: '250'
                    },
                    {
                        planType: 'PLUS',
                        value: '500'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Custom'
                    },
                ]
            },
            {
                type: 'Static QR Codes',
                description: 'Create non-editable and non-trackable QR Codes.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'LITE',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'PRO',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'PLUS',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Unlimited'
                    },
                ]
            },
            {
                type: 'Annual scans',
                description: 'Total number of scans allowed per account per year.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: '25,000'
                    },
                    {
                        planType: 'LITE',
                        value: '500,000'
                    },
                    {
                        planType: 'PRO',
                        value: '1,000,000'
                    },
                    {
                        planType: 'PLUS',
                        value: '2,000,000'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Unlimited'
                    },
                ]
            },
            {
                type: 'QR Code templates',
                description: 'Choose from various pre-designed QR Code templates built for your use case.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Smart rules',
                description: 'A condition-controlled QR Code that responds to specific conditions like the type of device, device languages, day of the week, time of the day, etc., and delivers tailored content and experiences.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Customizable forms',
                description: 'Customize your forms or choose from various templates for lead generation, surveying, or health screening.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Customizable landing pages',
                description: 'Build mobile-friendly landing pages with ready-to-use templates and link them to QR Codes.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Realtime scannability scores',
                description: 'Check color contrast and impact of customization on QR Code scannability.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Error correction',
                description: 'De-risk QR Code wear and tear with a high error correction level.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Multiple download formats',
                description: 'Download QR Codes in digital/print formats such as PNG, JPG, PDF, SVG, and EPS.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Age-gated QR Codes',
                description: 'Filter your audiences with age restrictions and limit accessibility.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Password protected QR Codes',
                description: 'Gate all your QR Code content with a password. Limit access to financial documents and sensitive information.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Multi-lingual QR Codes',
                description: 'Localize your global campaigns with one QR Code for multiple languages.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
        ]
    },
    {
        typeOfFeature: 'Multi-link QR Codes (Linkpages)',
        id: 'isLinkpageCollapsed',
        features: [
            {
                type: 'Linkpage',
                description: 'Create mobile-friendly, link-in-bio pages with multiple links. Share multiple resources and links with one QR Code.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: '1'
                    },
                    {
                        planType: 'LITE',
                        value: '5'
                    },
                    {
                        planType: 'PRO',
                        value: '10'
                    },
                    {
                        planType: 'PLUS',
                        value: '25'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: '100'
                    },
                ]
            },
            {
                type: 'Links per page',
                description: 'Total number of links allowed on a page.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: '10'
                    },
                    {
                        planType: 'LITE',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'PRO',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'PLUS',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Unlimited'
                    },
                ]
            },
            {
                type: 'Views and clicks',
                description: 'Total number of Linkpage views and unique clicks allowed.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'LITE',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'PRO',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'PLUS',
                        value: 'Unlimited'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Unlimited'
                    },
                ]
            },
            {
                type: 'Linkpage analytics',
                description: 'View detailed analytics about link clicks, link history, CTR (Click-through rate), page views, and its breakdown by city, state, and country.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Custom short URL',
                description: 'Add your custom short URL to Linkpage.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Password protection',
                description: 'Gate your Linkpages with a password.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Custom background colors',
                description: '',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Profile and custom background image',
                description: '',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Font and button styles',
                description: '',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Google fonts support',
                description: '',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            }
        ]
    },
    {
        typeOfFeature: 'Reporting & analytics',
        id: 'isCompaignAnalyticsCollapsed',
        features: [
            {
                type: 'Tracks scans/time of day',
                description: 'Identify the busiest and the slowest scanning hours via smart heatmaps.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Tracks scans/locations',
                description: 'Track QR Code campaign performance by city, state, or country.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Tracks scans/device type',
                description: 'Monitor QR Code users across multiple devices: Android, iOS, iPadOS, etc.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Email reports',
                description: 'Configure weekly/monthly email reports for the best and worst-performing QR Code campaigns.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Tracks scans/GPS coordinates',
                description: 'See a GPS-aided visual map of your scans by enabling GPS location tracking.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Know your users better with Google Analytics',
                description: 'Use our Google Analytics integration to find unique user insights such as demographics, behaviors, and interests.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
        ]
    },
    {
        typeOfFeature: 'Data & security',
        id: 'isDataSecurityCollapsed',
        features: [
            {
                type: 'GDPR compliant',
                description: 'Protect your user data as per GDPR compliance.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'SOC® 2 Type 2 compliant',
                description: 'Ensure top security and privacy standards with SOC® 2 Type 2 compliance.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Anomalous scan detection',
                description: 'Identify abnormal scan behavior and filter them out. Maintain data sanity and accurate campaign analytics.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Malware & phishing URL detection',
                description: 'Protect your users from malicious phishing attacks with our URL-based phishing detection.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'SSO integration via SAML',
                description: 'Ensure complete control and security with a singular company-level sign-in SAML account.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: '2-factor authentication (2FA)',
                description: 'Safeguard your data against phishing attacks and unauthorized access with 2FA.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Filling infosec questionnaire',
                description: 'Get your Infosec questionnaire filled out by us and be assured of a top-tier secure partnership.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Access to the trust portal',
                description: 'View all information related to Uniqode’s security and privacy practices in one place.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
        ]
    },
    {
        typeOfFeature: 'Branding',
        id: 'isBrandingCollapsed',
        features: [
            {
                type: 'QR Code customizations',
                description: 'Personalize your QR Codes with unique shapes, brand colors, logos, frames, data patterns, and backgrounds.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'White-label solution',
                description: 'Replace the Uniqode branding from campaigns, dashboards, and email templates with your branding.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Custom domain/URL',
                description: 'Use your domain in the QR Code URL and reinforce your branding.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: 'Available as add-on purchase'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
        ]
    },
    {
        typeOfFeature: 'Collaboration & control',
        id: 'isCollaborationCollapsed',
        features: [
            {
                type: 'Add multiple users',
                description: 'Add your team members to your Uniqode account and set up unique login credentials.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'Single'
                    },
                    {
                        planType: 'LITE',
                        value: 'Single'
                    },
                    {
                        planType: 'PRO',
                        value: 'Single'
                    },
                    {
                        planType: 'PLUS',
                        value: '3'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Custom'
                    },
                ]
            },
            {
                type: 'Role-based access control',
                description: 'Configure custom role-based access to different users.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Transfer ownership',
                description: 'Ensure smooth team member exits by transferring account ownership and admin access to the current employees.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Configure separate organizations',
                description: 'Manage multiple clients and team campaigns with separate organizations and configure their access settings.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Custom'
                    },
                ]
            },
            {
                type: 'Manage shared labels across organizations',
                description: 'Add labels to your QR Codes based on campaigns, goals, and locations and manage them across organizations. Export segmented data and use it for relevant customer insights.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Enforce QR Codes templates',
                description: 'Maintain brand consistency by locking the shape, color, and logos of your QR Codes and enforcing them across organizations.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Transfer QR Codes across organizations',
                description: 'Use admin rights to ensure smooth migration of QR Codes across multiple organizations.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Restrict QR Codes type access',
                description: 'As an admin, limit the access to specific QR Code types to specific users.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Separate asset library per organization',
                description: 'Never lose your assets again. Create separate branding kits for different organizations and share them for company-wide reuse.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Control analytics export per organization',
                description: 'Export scan analytics reports of one or multiple QR Codes as CSV or PDF.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Disable static QR Codes per organization',
                description: 'Deactivate your static QR Codes of different organizations when they are no longer in use',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },

        ]
    },
    {
        typeOfFeature: 'Scalability',
        id: 'isScalabilityCollapsed',
        features: [
            {
                type: 'Bulk Static QR Code generation',
                description: ' ',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ''
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Bulk Dynamic QR Code generation',
                description: ' ',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'QR Code API - rate limit',
                description: 'The rate limit is the maximum number of requests you can make per second via the API.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'QR Code API - usage limit',
                description: 'The usage limit is the maximum number of requests you can make monthly via the API.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
        ]
    },
    {
        typeOfFeature: 'Integrations',
        id: 'isIntegrationsCollapsed',
        features: [
            {
                type: 'Zapier',
                description: 'Zapier moves information between Uniqode QR Codes and your web apps without coding.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Workato',
                description: 'Build complex workflows with QR Codes and other marketing tools without coding.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Make',
                description: 'Move data between Uniqode QR Codes and different web apps using Make.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Google Analytics',
                description: 'Connect Uniqode QR Code as a property on Google Analytics to get unique and advanced user insights.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Google Sheets',
                description: 'Manage your QR Code creation automatically using Google Sheet add-ons.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Slack',
                description: 'Get notified about QR Code scans and response submissions on Slack.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Canva',
                description: 'Add your Dynamic and Static QR Codes from Uniqode QR code generator into your Canva design.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Meta Pixel',
                description: 'Add your Meta Pixel code to Uniqode and retarget those who scan your QR Code with Facebook ads.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Google Ads',
                description: 'Add your Google Ads ID and retarget those who scan your QR Code with RLSA or GDN ads.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
        ]
    },
    {
        typeOfFeature: 'Customer support',
        id: 'isCustomerSupportCollapsed',
        features: [
            {
                type: 'Knowledge base',
                description: 'Explore articles, videos, and guides on anything you need about Uniqode.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Live chat',
                description: 'Unmatched customer support for all users via chat. Available anytime, anywhere.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Email support',
                description: 'Email our support team anytime, and we get back in a few hours with a solution.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Phone support',
                description: 'Call our QR Code experts and quickly resolve any issue.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: 'true'
                    },
                    {
                        planType: 'LITE',
                        value: 'true'
                    },
                    {
                        planType: 'PRO',
                        value: 'true'
                    },
                    {
                        planType: 'PLUS',
                        value: 'true'
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Dedicated customer success manager',
                description: 'Get personalized customer onboarding, training hours, and hands-on support.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Onboarding & setup',
                description: 'Get hands-on support during onboarding and setup of the Uniqode dashboard.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Redlining',
                description: 'Create negotiable, custom contracts for your subscription.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'Pay by invoice',
                description: ' ',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'true'
                    },
                ]
            },
            {
                type: 'SLA',
                description: 'Create tailored and unique agreements that reflect your requirements and expectations.',
                plans: [
                    {
                        planType: 'STARTER',
                        value: ' '
                    },
                    {
                        planType: 'LITE',
                        value: ' '
                    },
                    {
                        planType: 'PRO',
                        value: ' '
                    },
                    {
                        planType: 'PLUS',
                        value: ' '
                    },
                    {
                        planType: 'ENTERPRISE',
                        value: 'Custom'
                    },
                ]
            },
        ]
    },
]
