<div class="d-flex flex-column datepicker-parent">
    <beaconstac-textfield
        (keydown)="$event.preventDefault(); datepicker.open()"
        type="text"
        [placeholder]="placeholder"
        [maxWidth]="maxWidth"
        [icon]="icon"
        iconPosition = "left"
        (click)="datepicker.open()"
        [text]="dateSelectedText"
        [label]="label"
        [title]="title"
        [disabled]="disabled"
    >
    </beaconstac-textfield>
    <input
        matInput
        [matDatepicker]="datepicker"
        (click)="datepicker.open()"
        (dateChange)="onDateChangeHandler($event)"
        [max]="_maxDate"
        [min]="_startDate"
        [ngModel]="_dateSelected"
        [disabled]="disabled"
        readonly
        style="all: unset; height: 0px"
    >
    <mat-datepicker #datepicker
                    disabled="false"
                    [startAt]="_maxDate"
    ></mat-datepicker>
</div>
