<div class="bx--grid breadcrumb-top-margin-issue my-4 px-2 ml-4" *ngIf="dbcSetting" style="max-width: 781px">
    <div class="bx--row mt-4">
        <div class="bx--col-xlg-16 bx--col-lg-16 bx--col-md-16 pl-0 pr-0">
            <beaconstac-title
                [title]="'Settings'"
                [subtitle]="'Manage your business card settings and preferences here'"
                [primaryActionTitle]="'Save Changes'"
                [primaryActionButtonType]="BUTTON_TYPES.PRIMARY"
                [primaryAction]="true"
                [mobileSubtitle]="true"
                (onClickPrimaryAction)="onSaveClick()"
            ></beaconstac-title>
        </div>
    </div>
</div>
<div class="bx--grid breadcrumb-top-margin-issue my-4 px-2 ml-4" *ngIf="dbcSetting" style="max-width: 781px">
    <div class="bx--row bg-white mt-4 mb-4">
        <div class="bx--col mb-4">
            <div *ngIf="showDepricatedOrgSettings">
                <div *ngFor="let setting of dbcSettingKeys" class="bx--row mt-3">
                    <div class="bx--col d-flex">
                        <beaconstac-toggle
                            (checkedChange)="toggleDBCSetting($event, setting)"
                            [checked]="dbcSetting[setting]"
                        ></beaconstac-toggle>
                        <label class="form-control-label" id="cards-lead-collection">{{
                            setting | SettingReadableName
                        }}</label>
                    </div>
                </div>
            </div>
            <div class="bx--row mt-3">
                <div class="bx--col d-flex">
                    <beaconstac-toggle
                        (checkedChange)="enforceWalletPass($event)"
                        [(checked)]="this.walletPassEnforced"
                    ></beaconstac-toggle>
                    <label class="form-control-label">Wallet pass template</label>
                </div>
            </div>
        </div>
    </div>
    <div class="bx--row bg-white">
        <div class="bx--col p-4">
            <beaconstac-title
                [title]="'Custom label'"
                [subtitle]="'Add customized label for your contacts'"
            ></beaconstac-title>
            <div class="custom-label-heading pt-4 mt-1 pb-1">
                NOTE: For multiple labels, use a comma (,) after each one
            </div>
            <ng-select
                class="custom-ng-select"
                #customLabelsBox
                [addTag]="true"
                [ngModel]="customLabels"
                [multiple]="true"
                [selectOnTab]="true"
                [isOpen]="false"
                [clearable]="false"
                [addTagText]="'Add labels'"
                [clearOnBackspace]="false"
                (change)="changeEvent($event)"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Press Enter/Return for each label entered"
            ></ng-select>
        </div>
    </div>

    <div *ngIf="!notOnEnterprisePlan">
        <div class="bx--row mt-4 bg-white">
            <div class="bx--col-xlg-13 bx--col-lg-12 bx--col-md-5 pl-4 pt-4">
                <span class="text-uppercase font-13 text-left line-height-15 letter-spacing-2" style="color:gray; font-weight:550; letter-spacing:1px;">
                    MANAGE CUSTOM FONTS
                </span>
            </div>
        </div>
        <div class="bx--row bg-white">
            <div class="bx--col pl-0 pr-0">
                <hr
                    style="
                        border: none;
                        height: 1.5px;
                        background: #e6e9ec;
                        background: repeating-linear-gradient(
                            90deg,
                            #e6e9ec,
                            #e6e9ec 6px,
                            transparent 6px,
                            transparent 12px
                        );
                        border-color: #e6e9ec;
                    "
                />
            </div>
        </div>
        <div class="bx--row bg-white">
            <div class="bx--col-xlg-13 bx--col-lg-12 bx--col-md-5 pl-4 pb-4 pt-2">
                <span class="text-left line-height-18" style="font-size: 16px; font-weight: 500"> Upload fonts </span>
                <br />
                <div style="display: flex; position:relative;" class="mt-2">
                    <div class="custom-label-heading pb-1">
                        Add a font with multiple font styles to create a good-looking card
                        <beaconstac-tooltip *ngIf = "!isMobileScreenSize"
                            [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                            [caretPosition]="CARET_POSITION.LEFT"
                            [elementClass]="'fas fa-info-circle'"
                            [color] = "'#000000'"
                            [content]="'We recommend adding regular, bold, semi-bold and italic font styles.'"
                            >
                        </beaconstac-tooltip>
                    </div>
                </div>

            </div>
        </div>
        <div class="bx--row bg-white">
            <div class="bx--col-xlg-16 bx--col-lg-16 bxPP--col-md-16 pl-4 pb-4 pr-4">
                <beaconstac-upload
                    #uploadFontFile
                    [acceptTypes]="['font/otf', 'font/ttf']"
                    [maxSize]="5e6"
                    [height]="'auto'"
                    [custom]="'Supported extensions: .otf, .ttf.'"
                    [showRemoveButton]="true"
                    (fileChange)="onDataFileAdded($event)"
                >
                </beaconstac-upload>
            </div>
        </div>
        <div class="bx--row bg-white">
            <div class="bx--col-xlg-16 bx--col-lg-16 bx--col-md-16 pl-4 pb-4 pr-4">
                <div style="margin-bottom: 24px;">
                    <span class="text-left line-height-18" style="font-size: 16px; font-weight: 500;"> Your fonts </span>
                </div>
                <beaconstac-table
                    [showToolBar]="true"
                    [tableData]="customFontsData"
                    [showInbuiltEmptyState]="true"
                    (onToolbarClick)="handleToolbarClickForDeleting($event)"
                >
                </beaconstac-table>
            </div>
        </div>
    </div>
    <div *ngIf="notOnEnterprisePlan"  >
        <div class="bx--row mt-4 bg-white" #manageCustomFontsSection>
            <div class="bx--col-xlg-6 bx--col-lg-6 bx--col-md-3 pl-4 pt-4">
                <span class="text-uppercase font-13 text-left line-height-15 letter-spacing-2" style="color:gray; font-weight:550; letter-spacing:1px;">
                    MANAGE CUSTOM FONTS
                </span>
                <span>
                    <i class="fas fa-crown text-warning"></i>
                </span>
            </div>
        </div>
        <div class="bx--row bg-white">
            <div class="bx--col pl-0 pr-0">
                <hr
                    style="
                        border: none;
                        height: 1.5px;
                        background: #e6e9ec;
                        background: repeating-linear-gradient(
                            90deg,
                            #e6e9ec,
                            #e6e9ec 6px,
                            transparent 6px,
                            transparent 12px
                        );
                        border-color: #e6e9ec;
                    "
                />
            </div>
        </div>
        <div class="bx--row bg-white">
            <div class="bx--col pl-4 pr-0">
                <span style="color:grey">
                    Get the BUSINESS+ plan to use your brand's custom fonts.
                </span>
            </div>
        </div>
        <div class="bx--row bg-white pt-4 pb-4">
            <div class="bx--col pl-4 pr-0">
                <span style="background-color: transparent;">
                    <beaconstac-button
                        [type]="BUTTON_TYPES.PRIMARY"
                        (onClick)="upsellCustomFonts()"
                        [label]="'Upgrade Now'"
                        [style]="BUTTON_STYLES.STROKE"
                    ></beaconstac-button>
                </span>
            </div>
        </div>
    </div>

</div>

<div
    bsModal
    #handleShowModalForDeleteFont="bs-modal"
    class="modal fade"
    role="dialog"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 336px">
        <div class="modal-content">
            <div class="modal-body" style="padding: 24px">
                <h4 class="create-card-heading">Delete font</h4>

                <div class="create-linkpage-description mt-4 text-secondary">
                    Cards using this font will switch to the default font.
                </div>
            </div>
            <div class="modal-footer border-0" style="padding: 24px">
                <span>
                    <beaconstac-button
                        [type]="BUTTON_TYPES.SECONDARY"
                        (onClick)="handleShowModalForDeleteFont.hide()"
                        [label]="'Cancel'"
                    ></beaconstac-button>
                </span>
                <span class="ml-3">
                    <beaconstac-button
                        [type]="BUTTON_TYPES.DANGER"
                        [label]="'Delete'"
                        (onClick)="handleDeleteOperationForDeletingFont()"
                    ></beaconstac-button>
                </span>
            </div>
        </div>
    </div>
</div>

