import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Card, PLAN_TYPE, SubscriptionDetail, UserService} from '../../user-account/user.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {AuthService} from '../../global-services/auth.service';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {BUTTON_STYLES, BUTTON_TYPES, ICON_SIZE} from 'kaizen-design-system';
import {DBC_PRICE_RATES, PLAN_TYPES} from '../../user-account/user.model';
import {PRODUCT_TYPES} from '../../shared/utils';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../global-services/amplitude.service';

export enum ADD_ON_PRODUCT_TYPE {
    USERS = 'user',
    QR_CODES = 'qr',
    CUSTOM_DOMAIN = 'custom_domain',
    USER_SEATS = 'user_seats',
    LINKPAGE = 'link_page'
}

export enum ADD_ON_STEPS {
    SELECT_QUANTITY = 'select_quantity',
    PAYMENT = 'payment'
}

export enum USER_SEATS_ADD_LIMIT {
    SOLO = 9,
    TEAM = 49 ,
    BUSINESS = 249,
    ENTERPRISE = Number.MAX_SAFE_INTEGER
}

interface addOnProduct{
    product: string,
    addOnMaxLimit: number;
}

export enum ADDON_SOURCE {
    BILLING_PAGE= 'billing_page',
    USER_MGMT_ADD_SEAT = 'user_mgmt_add_seat',
    DBC_BULK_CREATE = 'dbc_bulk_create',
    UNKNOWN = 'unknown'
}

@Component({
    selector: 'app-beaconstac-add-on',
    templateUrl: './beaconstac-add-on.component.html',
    styleUrls: ['./beaconstac-add-on.component.scss']
})
export class BeaconstacAddOnComponent implements OnInit, OnDestroy {

    ADDITIONAL_SCANS_PER_QR = 1000
    ADD_ON_STEPS = ADD_ON_STEPS
    ADD_ON_PRODUCT_TYPE = ADD_ON_PRODUCT_TYPE
    isOnEnterprisePlan: boolean;
    buttonLabel = {
        user: 'Buy User seats',
        qr: 'Buy QR Codes',
        custom_domain: 'Pay & Proceed',
        user_seats: 'Buy User Seats',
        link_page: 'Buy Linkpages'
    };
    descriptionText = {
        user: 'user',
        qr: 'QR code',
        custom_domain: 'custom domain',
        user_seats: 'user seats',
        link_page: 'Linkpage'
    }
    tableTitle = {
        user: 'User',
        qr: 'QR code',
        custom_domain: 'Custom Domain',
        user_seats: 'User Seats',
        link_page: 'Linkpage'
    }

    productNames = {
        [ADD_ON_PRODUCT_TYPE.USERS]: 'Users',
        [ADD_ON_PRODUCT_TYPE.QR_CODES]: 'QR codes',
        [ADD_ON_PRODUCT_TYPE.CUSTOM_DOMAIN]: 'Custom domain',
        [ADD_ON_PRODUCT_TYPE.USER_SEATS]: 'User Seats',
        [ADD_ON_PRODUCT_TYPE.LINKPAGE]: 'Linkpages'
    };

    addOnMaxLimit: number;

    addonProductPrice = {
        user: 6000,
        qr: 150,
        custom_domain: 200000,
        user_seats: 6000,
        link_page: 1200
    }
    customSucessMessage: string = '';

    addOnModel: {
        product: 'qr' | 'user' | 'custom_domain' | 'user_seats' | 'link_page',
        quantity: number,
        pack: number,
        pricePerQuantityInCents: number,
        hideQuantityControls?: boolean,
        useInnerHtmlForDescription?: boolean,
        addOnStep: ADD_ON_STEPS
    } = {
            product: ADD_ON_PRODUCT_TYPE.USERS,
            quantity: 1,
            pack: 1,
            pricePerQuantityInCents: 6000,
            hideQuantityControls: false,
            useInnerHtmlForDescription: false,
            addOnStep: ADD_ON_STEPS.SELECT_QUANTITY
        };
    lastBillingDate: any;
    nextBillingDate: any;
    selectedCard: Card;
    @ViewChild('addOnModal', { static: false }) addOnModal: ModalDirective;
    private subscriptionDetail: SubscriptionDetail;
    @Output() dataChange: EventEmitter<boolean> = new EventEmitter();
    @Output() updateMaxLimit: EventEmitter<addOnProduct> = new EventEmitter();
    @Output() addInviteUser: EventEmitter<boolean> = new EventEmitter();

    @Input() pollForFeaturePermissions?: boolean = false;

    title: string = ''
    message: string = ''
    upcomingPriceData: {
        additionalUsers: number,
        additionalQRCodes: number,
        additionalCustomDomains: number,
        additionalUserSeats: number,
        additionalLinkpages: number,
        plan: PLAN_TYPES
    } = {
            additionalUsers: 0,
            additionalQRCodes: 0,
            additionalCustomDomains: 0,
            additionalUserSeats: 0,
            additionalLinkpages: 0,
            plan: null
        }
    upcomingPrice: number;
    upcomingPriceSubject = new Subject<any>();
    fetchingUpcomingPrice = false
    eventFromSource: string = 'unknown';
    showWarning: boolean = false;
    warningMessage: string = ''
    inviteUser: boolean = false;

    // kaizen
    BUTTON_TYPES = BUTTON_TYPES
    BUTTON_STYLES = BUTTON_STYLES
    ICON_SIZE = ICON_SIZE
    ngUnsubscribe: Subject<any> = new Subject();
    anotherSub: Subject<any> = new Subject();
    dbcPlan: PLAN_TYPES;
    maxDBCAddOnsAllowed: number = 9;
    maxCurrentDbcSeats: number = 1;
    isOnDbcTrialPlan: boolean = false;

    constructor(private router: Router, private userService: UserService, private authService: AuthService, private messageService: MessageModalService,
        private amplitude: AmplitudeService) {
    }

    ngOnInit(): void {
        this.isOnEnterprisePlan = this.authService.getUser().isOnEnterprisePlan();
        this.dbcPlan = this.authService.getUser().dbcCustomerPlan;
        this.maxCurrentDbcSeats = this.authService.getUser().organization.maxCurrentDbcSeats;
        this.isOnDbcTrialPlan = this.authService.getUser().isOnTrialPlan(PRODUCT_TYPES.DBC) || false;
        this.handleDbcUserSeatsAddOn()
    }

    setupAddOnModal(product, additionalParams: any = {}) {
        if (this.authService.getUser().isOwner()) {
            this.userService.getSubscription()
                .pipe(takeUntil(this.ngUnsubscribe)).subscribe(subscriptionResult => {
                    if (subscriptionResult !== null) {
                        for (const subscription of subscriptionResult) {
                            if (subscription.type === PLAN_TYPE.SUBSCRIPTION) {
                                this.subscriptionDetail = subscription;
                                const options = { year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions;
                                this.nextBillingDate = this.subscriptionDetail.expires.toLocaleDateString('en-US', options)
                                if (this.subscriptionDetail.interval === 'year') {
                                    this.lastBillingDate = moment(this.subscriptionDetail.expires).subtract(1, 'years').toDate().toLocaleDateString('en-US', options)
                                } else {
                                    this.lastBillingDate = moment(this.subscriptionDetail.expires).subtract(1, 'months').toDate().toLocaleDateString('en-US', options)
                                }
                            }
                        }
                    }
                }, error => {
                    // TODO error handling
                });
        }
        this.userService.getCards().pipe(takeUntil(this.ngUnsubscribe)).subscribe(cards => {
            this.selectedCard = cards.find(c => c.default === true) || cards[0];
        }, err => {
        });
        this.upcomingPriceSubject.pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000)).subscribe(event => {
            this.fetchUpcomingPrice();
        });
        this.addOnModel = {
            ...additionalParams,
            product: product,
            addOnStep: ADD_ON_STEPS.SELECT_QUANTITY,
            quantity: product === ADD_ON_PRODUCT_TYPE.USERS ? 1 : 1,
            pack: product === ADD_ON_PRODUCT_TYPE.QR_CODES ? 50 : 1,
            pricePerQuantityInCents: product === 'user_seats' ? this.getAddOnProductPriceBasedOnPlan() : this.addonProductPrice[product],
        }
        if (additionalParams?.quantity){
            this.addOnModel.quantity = additionalParams.quantity;
        }
        this.fetchingUpcomingPrice = true;
        this.fetchUpcomingPrice();
        this.addOnModal.show();
    }

    routeToChangePlan() {
        this.addOnModal.hide()
        this.router.navigate(['/account/upgrade/'], { queryParams: { source: this.eventFromSource, orgID: this.authService.getCurrentOrgId()}});
    }

    show(product, title, message, source: string = 'unknown', invite: boolean = false, additionalParams?: any) {
        this.message = message;
        this.title = title;
        this.customSucessMessage = (additionalParams?.customSucessMessage || '');
        this.upcomingPriceData = {
            additionalUsers: 0,
            additionalQRCodes: 0,
            additionalCustomDomains: 0,
            additionalUserSeats: 0,
            additionalLinkpages: 0,
            plan: ADD_ON_PRODUCT_TYPE.USER_SEATS ? this.authService.getUser().dbcCustomerPlan : this.authService.getUser().qrCustomerPlan
        }
        this.inviteUser = (invite && source === 'billing-addon-user-modal-upgrade-cta ');
        this.eventFromSource = source
        this.setupAddOnModal(product, additionalParams);
    }

    hide() {
        this.addOnModal.hide();
    }

    updateAddOns() {
        let data = {}
        if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.USERS) {
            data = {
                additionalUsers: this.addOnModel.quantity * this.addOnModel.pack
            }
            this.addOnMaxLimit = this.addOnModel.quantity * this.addOnModel.pack;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.QR_CODES) {
            data = {
                additionalQRCodes: this.addOnModel.quantity * this.addOnModel.pack
            }
            this.addOnMaxLimit = this.addOnModel.quantity * this.addOnModel.pack;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.CUSTOM_DOMAIN) {
            data = {
                customDomains: this.addOnModel.quantity * this.addOnModel.pack
            }
            this.addOnMaxLimit = this.addOnModel.quantity * this.addOnModel.pack;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.LINKPAGE){
            data = {
                additionalLinkpages: this.addOnModel.quantity * this.addOnModel.pack
            }
            this.addOnMaxLimit = this.addOnModel.quantity * this.addOnModel.pack;
        } else {
            data = {
                additionalUserSeats: this.addOnModel.quantity * this.addOnModel.pack,
                plan: this.dbcPlan
            }
        }
        this.hide()
        this.messageService.show('Updating...', 'warning', 0)
        this.userService.updateAddOns(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            if (this.pollForFeaturePermissions) {
                this.authService.fetchOrganization().pipe(takeUntil(this.ngUnsubscribe), debounceTime(1000)).subscribe(res => {
                    if (this.authService.getUser().organization.feature_permissions.custom_domain) {
                        this.updateAddonSuccess();
                    }
                })
            } else {
                this.updateAddonSuccess();
            }
        }, error => {
            const product = this.productNames[this.addOnModel.product] || 'NA';
            const message = `Error adding ${product}`;
            this.messageService.show(message, 'danger')
            this.pushDataToAmplitude(false);
        })
    }

    updateAddonSuccess() {
        const product = this.productNames[this.addOnModel.product] || 'NA';
        let message = '';
        switch (this.addOnModel.product){
            case ADD_ON_PRODUCT_TYPE.USERS:
                message = 'User seats added successfully';
                break;
            case ADD_ON_PRODUCT_TYPE.CUSTOM_DOMAIN:
                message = 'Custom Domain added! The updates might take a while to reflect, after which you can set it up under the Domains page.';
                break;
            default:
                message = `${product} added successfully`;
                break;
        }
        if (this.customSucessMessage.length){
            message = this.customSucessMessage;
        }
        this.messageService.show(message, 'success')
        this.dataChange.emit(true);
        const addOnProduct = {
            product: this.addOnModel.product,
            addOnMaxLimit: this.addOnMaxLimit
        }
        this.updateMaxLimit.emit(addOnProduct);
        if (this.inviteUser) {
            setTimeout(() => {
                this.messageService.show('Inviting new user...', 'warning', 0);
                this.addInviteUser.emit(true);
            }, 500);
        }
        this.customSucessMessage = '';

        this.pushDataToAmplitude(true);
    }

    pushDataToAmplitude(success: boolean = true) {
        const data = {
            source: this.eventFromSource || 'unknown',
            type: this.addOnModel.product,
            success: success
        }
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.ADDONS, data);
    }

    fetchUpcomingPrice() {
        if ( this.ADD_ON_PRODUCT_TYPE.USER_SEATS && this.isOnDbcTrialPlan) {
            // No need to fetch as user is on trial
            return;
        }
        this.upcomingPrice = 0;
        this.upcomingPriceData.plan = this.authService.getUser().qrCustomerPlan;
        if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.USERS) {
            this.upcomingPriceData.additionalUsers = this.addOnModel.quantity * this.addOnModel.pack;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.QR_CODES) {
            this.upcomingPriceData.additionalQRCodes = this.addOnModel.quantity * this.addOnModel.pack;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.CUSTOM_DOMAIN) {
            this.upcomingPriceData.additionalCustomDomains = this.addOnModel.quantity * this.addOnModel.pack;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.LINKPAGE){
            this.upcomingPriceData.additionalLinkpages = this.addOnModel.quantity * this.addOnModel.pack;
        } else {
            this.upcomingPriceData.additionalUserSeats = this.addOnModel.quantity * this.addOnModel.pack;
            this.upcomingPriceData.plan = this.authService.getUser().dbcCustomerPlan;
        }
        this.userService.getUpcomingInvoicePrice(this.upcomingPriceData)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.fetchingUpcomingPrice = false
                this.upcomingPrice = Number.parseInt(res.upcomingPrice)
            }, error => {
                this.fetchingUpcomingPrice = false
                console.error(error);
            })

    }

    addQuantity() {
        if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.QR_CODES) {
            if (this.addOnModel.quantity * this.addOnModel.pack + 1 <= 5000) {
                this.addOnModel.quantity = this.addOnModel.quantity + 1;
            } else {
                return
            }
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.CUSTOM_DOMAIN) {
            return;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.USERS) {
            this.addOnModel.quantity = this.addOnModel.quantity + 1;
        } else if (this.addOnModel.product === ADD_ON_PRODUCT_TYPE.LINKPAGE){
            this.addOnModel.quantity = this.addOnModel.quantity + 1;
        } else {
            if ((this.addOnModel.quantity + 1 + this.maxCurrentDbcSeats) <= this.maxDBCAddOnsAllowed) {
                this.addOnModel.quantity = this.addOnModel.quantity + 1;
                this.showWarning = false;
            } else {
                this.showWarning = true;
                this.warningMessage = `Your plan allows a maximum of ${this.maxDBCAddOnsAllowed} Users.`

            }
        }
        this.fetchingUpcomingPrice = true;
        this.upcomingPriceSubject.next()
    }

    handleDbcUserSeatsAddOn() {
        switch (this.dbcPlan) {
            case PLAN_TYPES.Solo:
                this.maxDBCAddOnsAllowed = USER_SEATS_ADD_LIMIT.SOLO;
                break;
            case PLAN_TYPES.Team:
                this.maxDBCAddOnsAllowed = USER_SEATS_ADD_LIMIT.TEAM;
                break;
            case PLAN_TYPES.Business:
                this.maxDBCAddOnsAllowed = USER_SEATS_ADD_LIMIT.BUSINESS;
                break;
            default:
                this.maxDBCAddOnsAllowed = USER_SEATS_ADD_LIMIT.ENTERPRISE;
        }
    }

    subtractQuantity() {
        if (this.addOnModel.quantity - 1 >= 1) {
            this.addOnModel.quantity = this.addOnModel.quantity - 1;
        } else {
            return
        }
        this.showWarning = false
        this.fetchingUpcomingPrice = true;
        this.upcomingPriceSubject.next()
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    private getAddOnProductPriceBasedOnPlan() {
        return DBC_PRICE_RATES[this.authService.getUser().getReadablePlan(PRODUCT_TYPES.DBC)] * 12 * 100;
    }
}
