<div *ngIf="bulkActionProduct === BULK_ACTION_PRODUCT.QR" class="mx-2 d-flex bulk-op-container bulk-op-container-qr ">
  <div class="d-flex bulk-op-container-1 mb-2 mb-md-0">
    <div class="bulk-op-selection-count">
      {{isAllQRCodeSelected ? qrCodeTotalCount : selectedQRCodeLength}} Selected<span class="space-alignment">|</span>
    </div>
    <div class="bulk-op-deselect-all" (click)="deselectAllQRCodes()">De-select All</div>
  </div>
  <div class="bulk-op-container-2 mb-2 mb-md-0">
    <div class="bulk-op-select-all">
      <button class="bulk-op-select-all-btn cursor-pointer btn btn-outline-primary" (click)="bulkSelectQRCodes()" [ngClass]="isAllQRCodeSelected ? 'd-none' : 'd-inline' ">
        Select All
      </button>
    </div>
  </div>

  <div class="d-flex bulk-op-container-3">
    <ng-container *ngIf="qrCodeListTypeValue !== QR_CODE_TYPE_VALUE.Deleted">
      <div  class="bulk-op-delete">
        <button class="btn btn-outline-danger" (click)="onSelectBulkOperation(QR_BULK_OPERATIONS.Delete)">
          <i class="fas fa-trash-alt align-icon"></i>
          <span class="d-sm-down-none">Delete</span>
        </button>
      </div>
      <div *ngIf="showShareWalletPassButton" class="bulk-op-label">
        <button class="btn bulk-op-label-btn" [ngStyle]="{'cursor' : disableLabels ? 'not-allowed' : 'pointer'}" (click)="onSelectBulkOperation(QR_BULK_OPERATIONS.ShareWalletPass)">
          <img src="../../../assets/img/dynamic-qr/v-card-layout.svg" width="17px" style="transform: translateY(-3px);" class="align-icon" alt="id-badge" />
          <span class="d-sm-down-none">Share Wallet pass</span>
        </button>
      </div>
      <div *ngIf="qrCodeListTypeValue !== QR_CODE_TYPE_VALUE.Static && !hasSelectedStaticQRCode" class="bulk-op-label">
        <button class="btn bulk-op-label-btn" [ngStyle]="{'cursor' : disableLabels ? 'not-allowed' : 'pointer'}" (click)="onSelectBulkOperation(QR_BULK_OPERATIONS.ViewAnalytics)">
          <i class="fas fa-chart-bar align-icon"></i>
          <span class="d-sm-down-none">View Analytics</span>
        </button>
      </div>

      <div class="bulk-op-label">
        <button class="btn bulk-op-label-btn" [ngStyle]="{'cursor' : disableLabels ? 'not-allowed' : 'pointer'}" (click)="onSelectBulkOperation(QR_BULK_OPERATIONS.AddLabel)">
          <i class="fas fa-tag align-icon"></i>
          <span class="d-sm-down-none">Label</span>
        </button>
      </div>

      <div class="bulk-op-download">
        <button class="btn bulk-op-download-btn" (click)="onSelectBulkOperation(QR_BULK_OPERATIONS.BulkDownload)">
          <i class="fas fa-download align-icon"></i>
          <span class="d-sm-down-none">Download</span>
        </button>
      </div>

      <div class="bulk-op-export">
        <button class="btn bulk-op-export-btn" (click)="onSelectBulkOperation(QR_BULK_OPERATIONS.CSVreport)">
          <i class="fas fa-table align-icon"></i>
          <span class="d-sm-down-none">Export</span>
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="qrCodeListTypeValue === QR_CODE_TYPE_VALUE.Deleted">
      <div class="bulk-op-delete">
        <button class="btn btn-danger" (click)="onSelectBulkOperation(QR_BULK_OPERATIONS.DeletePermanently)" >
          <i class="fas fa-trash-alt align-icon"></i>
          <span class="d-sm-down-none">Delete permanently</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="bulkActionProduct === BULK_ACTION_PRODUCT.CARDS" class="mt-4 p-4 d-flex flex-wrap bg-white" style="row-gap: 16px;">
    <div class="d-flex bulk-op-container-1 ">
        <div class="bulk-op-selection-count d-flex align-items-center" >{{isAllVcardSelected ? vCardTotalCount : selectedVcardLength}} Selected<span class="space-alignment">|</span></div>
        <div class="bulk-op-deselect-all d-flex align-items-center" (click)="deselectAllVcards()">Deselect All</div>
    </div>
    <div class="bulk-op-container-2">
        <div class="bulk-op-select-all">
            <beaconstac-button
                id="select-cards-btn"
                [ngClass]="isAllVcardSelected ? 'd-none' : 'd-inline' "
                [label]="hasFilterApplied ? 'Select all filtered ' + vCardTotalCount + ' Cards' : 'Select all ' + vCardTotalCount + ' Cards'"
                (click)="bulkSelectVcards()"
                [type]="BUTTON_TYPES.PRIMARY"
                [style]="BUTTON_STYLES.STROKE">
              </beaconstac-button>
        </div>
    </div>

    <div class="d-flex bulk-op-container-3 align-items-center mobile-screen-gap-24px">
<!--        <div class="bulk-op-delete"><button class="btn btn-outline-danger" (click)="selectedBulkOperation = QR_BULK_OPERATIONS.Delete; setBulkOperation()" ><i class="fa fa-trash align-icon"></i><span class="d-sm-down-none">Delete</span></button>
            </div>-->
            <beaconstac-button
                [ngClass]="'ml-4'"
                [label]="showBulkIconsLabel ? 'Label': ''"
                (click)="onSelectBulkOperation(CARD_BULK_OPERATIONS.LABEL)"
                [type]="BUTTON_TYPES.SECONDARY"
                [style]="BUTTON_STYLES.TEXT"
                [icon]="'fas fa-tag'"
                [iconSize]="ICON_SIZE.SMALL"
            ></beaconstac-button>

            <beaconstac-button
                [ngClass]="'ml-4'"
                [label]="showBulkIconsLabel ? 'Download QR Codes': ''"
                (click)="onSelectBulkOperation(CARD_BULK_OPERATIONS.BULK_DOWNLOAD)"
                [type]="BUTTON_TYPES.SECONDARY"
                [style]="BUTTON_STYLES.TEXT"
                [icon]="'fas fa-download'"
                [iconSize]="ICON_SIZE.SMALL"
            ></beaconstac-button>

            <beaconstac-button
                [ngClass]="'ml-4'"
                [label]="showBulkIconsLabel ? 'Share Wallet pass': ''"
                (click)="onSelectBulkOperation(CARD_BULK_OPERATIONS.WALLET_PASS)"
                [type]="BUTTON_TYPES.PRIMARY"
                [style]="BUTTON_STYLES.TEXT"
                [icon]="'fas fa-wallet'"
                [iconSize]="ICON_SIZE.SMALL"
            ></beaconstac-button>

            <beaconstac-button
                id="bulk-delete-operation"
                [label]="showBulkIconsLabel ? 'Delete': ''"
                (click)="onSelectBulkOperation(CARD_BULK_OPERATIONS.DELETE)"
                [type]="BUTTON_TYPES.DANGER"
                [style]="BUTTON_STYLES.TEXT"
                [icon]="'fal fa-trash-alt'"
                [iconSize]="ICON_SIZE.SMALL"
            ></beaconstac-button>

    </div>
</div>

<div *ngIf="bulkActionProduct === BULK_ACTION_PRODUCT.MOBILE_CARDS" class="mt-4 p-4 d-flex flex-wrap bg-white layout">
    <div class="mobilebulk-op-container-1">

      <div class="d-flex bulk-op-container-1 ">
        <div class="bulk-op-selection-count d-flex align-items-center" >{{isAllVcardSelected ? vCardTotalCount : selectedVcardLength}} Selected<span class="space-alignment">|</span></div>
        <div class="bulk-op-deselect-all d-flex align-items-center" (click)="deselectAllVcards()">Deselect All</div>
      </div>

      <div class="bulk-op-container-2">
        <div class="bulk-op-select-all">
            <beaconstac-button
                id="select-cards-btn"
                [ngClass]="isAllVcardSelected ? 'd-none' : 'd-inline' "
                [label]="hasFilterApplied ? 'Select all filtered ' + vCardTotalCount + ' Cards' : 'Select all ' + vCardTotalCount + ' Cards'"
                (click)="bulkSelectVcards()"
                [type]="BUTTON_TYPES.PRIMARY"
                [style]="BUTTON_STYLES.STROKE"
            ></beaconstac-button>
        </div>
      </div>

      <div class="d-flex bulk-op-container-3 align-items-center">
              <beaconstac-button
                  [label]="showBulkIconsLabel ? 'Delete': ''"
                  (click)="onSelectBulkOperation(CARD_BULK_OPERATIONS.DELETE)"
                  [type]="BUTTON_TYPES.DANGER"
                  [style]="BUTTON_STYLES.TEXT"
                  [icon]="'fal fa-trash-alt'"
                  [iconSize]="ICON_SIZE.SMALL"
              ></beaconstac-button>
              <beaconstac-button
                [ngClass]="'ml-4'"
                [label]="''"
                (click)="onSelectBulkOperation(CARD_BULK_OPERATIONS.LABEL)"
                [type]="BUTTON_TYPES.SECONDARY"
                [style]="BUTTON_STYLES.TEXT"
                [icon]="'fas fa-tag'"
                [iconSize]="ICON_SIZE.SMALL"
            ></beaconstac-button>
      </div>
      
    </div>
</div>





