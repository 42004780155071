import {finalize, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {AuthService} from '../global-services/auth.service';
import {PLAN_INTERVALS, UserSubscriptionService} from '../global-services/user-subscription-service';
import {Card, UserService} from '../user-account/user.service';
import {environment} from '../../environments/environment';
import {OverlayService} from '../global-services/overlay.service';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Animations} from 'app/shared/beaconstac-animations';
import {
    DBC_MINM_USER_SEATS,
    DBC_PLANS_FEATURES_DATA,
    DBC_PRICE_RATES,
    PLAN_TYPES,
    SUBSCRIPTION_STATE,
    User
} from '../user-account/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggedInGuard} from '../guards/logged-in.guard';
import {IntercomService} from '../global-services/intercom.service';
import {Subject} from 'rxjs';
import {AMPLITUDE_EVENT_CATEGORIES, AmplitudeService} from '../global-services/amplitude.service';
import {RouterReloadService} from '../global-services/router-reload.service';
import * as moment from 'moment-timezone';
import {DASHBOARD_MENU, getCookie, PRODUCT_TYPES, Utils} from 'app/shared/utils';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {BUTTON_STYLES, BUTTON_TYPES, TEXT_FIELD_TYPES} from 'kaizen-design-system';
import {GoogleAnalyticsService} from '../global-services/google-analytics.service';
import {DBC_FEATURE_DETAILS_DATA} from '../user-account/dbc-feature-details-data';
import {ProductSegmentationService} from '../global-services/product-segmentation.service';
import {OrganizationCountModel, OrganizationCountService} from '../global-services/organization-count.service';

enum PURCHASE_SECTION {
    Plan,
    Payment
}

enum BLACK_FRIDAY_COUNPONS {
    LT = 'SUB_BLK_LT_2022_40',
    PO = 'SUB_BLK_PO_2022_30',
    PL = 'SUB_BLK_PL_2022_25'
}

@Pipe({name: 'isOnHigherPlan'})
export class IsOnHigherPlan implements PipeTransform {
    transform(user: User, plan: PLAN_TYPES, planId: string, planYearly: boolean): any {
        const isOnHigherPlan = user.isOnHigherPlan(plan) || !user.isOnQRPlan();
        return isOnHigherPlan && user.customer_plan !== plan;
    }
}

@Component({
    selector: 'app-renew-plan',
    templateUrl: './renew-plan.component.html',
    styleUrls: ['./renew-plan.scss'],
    animations: [Animations.collapse],
})
export class RenewPlanComponent implements AfterViewInit, OnInit, OnDestroy {

    private static stripeCardBaseURL = environment.baseURL + environment.apiEndpoint + environment.apiVersion + '/users/';
    staterPurchase: boolean = false;
    disabled: boolean = false;
    loading$: any;

    PURCHASE_SECTION: any = PURCHASE_SECTION;
    PLAN_INTERVALS: any = PLAN_INTERVALS;
    PLAN_TYPES: any = PLAN_TYPES;
    subscription$: any;
    previousPlan$: any;

    // plan duration
    planYearly: boolean = true;
    // plan type
    planType: PLAN_TYPES = PLAN_TYPES.Pro;
    accountPassword: string;

    cards: Array<Card> = [];
    useExistingCard: boolean = false;
    selectedCard: Card;
    paymentSource: string;

    billingCycle: string = 'YEARLY';
    yearlyPrice: string = '5';
    discount: string = '45';
    currentPlanInterval: PLAN_INTERVALS;
    previousPlanId: string;
    eventSourceQueryParams: string;

    user: User;

    purchaseModel = {
        name: '',
        price: 0,
        duration: ''
    };

    totalPrice = {
        monthly: '',
        yearly: ''
    };

    couponCodeModel = {
        couponCode: '',
        error: false,
        loading: false,
        success: false,
        coupon: null
    };

    plansFullName = {
        ST: 'Starter',
        LT: 'Lite',
        PO: 'Pro',
        PL: 'Plus'
    };

    plansYearlyPrice = {
        ST: '60',
        LT: '180',
        PO: '588',
        PL: '1188'
    };

    TrialInfo: any = {
        Product: 'qr',
        Heading: '',
        Description: 'Try out this',
        Details: ['first', 'second']
    }

    previousPage;

    currentSection: PURCHASE_SECTION = PURCHASE_SECTION.Plan;
    hasSelectedPlan: boolean = false;
    hasSelectedDuration: boolean = false;

    infoSectionPlan: number = 1;
    ngUnsubscribe: Subject<any> = new Subject();

    plansViewLogged: boolean = false;
    brandLogo: string = '';
    firstName: string;

    // Kaizen
    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;

    date: any;
    now: any;
    difference: number;
    months: Array<string> = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    showBlackFridayPrice: boolean;
    blackFridayBannerWidth: string
    @ViewChild('days', {static: false}) days: ElementRef;
    @ViewChild('hours', {static: false}) hours: ElementRef;
    @ViewChild('minutes', {static: false}) minutes: ElementRef;
    @ViewChild('seconds', {static: false}) seconds: ElementRef;
    @ViewChild('paymentSuccess', {static: false}) paymentSuccess: ModalDirective;
    @ViewChild('paymentFailure', {static: false}) paymentFailure: ModalDirective;
    @ViewChild('trialSelectModal', { static: true }) trialSelectModal: ModalDirective;
    @ViewChild('invoiceModal', { static: true }) invoiceModal: ModalDirective;
    DASHBOARD_MENU = DASHBOARD_MENU
    currentDashboard: DASHBOARD_MENU;
    dbcUserSeats: string;
    selectedDBCPlan: PLAN_TYPES;
    dbcUserSeatsPrice: number;
    dbcAccountLimit: number
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    DBC_PRICE_RATES = DBC_PRICE_RATES
    dbcTrialPlan: PLAN_TYPES;
    monthlyPrice: string = '15';
    dbcPlansYearlyPrice =  {
        SL: '',
        TM: '',
        BN: '',
    }
    priceSubscription: any;
    planPrice: number;
    DBC_PLANS_FEATURES_DATA = DBC_PLANS_FEATURES_DATA;
    DBC_FEATURE_DETAILS_DATA = DBC_FEATURE_DETAILS_DATA;
    planTypeIndex: number = 0;
    isFullCollapsed: boolean = true;
    isDBCPlanExpired: Boolean = true;

    utils = Utils;
    currentOrganizationId: number;
    hasOwnerPermissions: boolean;

    invoicePending: boolean = false;
    fetchingInvoices: boolean = false;
    pendingInvoices = [];

    showBusinessPlans: boolean = false;
    qrPlansData;
    exchangeRates;
    qrUserCount: number;
    pageType: string = 'renew-plan';


    constructor(private http: HttpClient, private authService: AuthService, private overlay: OverlayService,
        private messageModal: MessageModalService, private userSubscriptionService: UserSubscriptionService,
        private routerReloadService: RouterReloadService, private googleAnalyticsService: GoogleAnalyticsService,
        private userService: UserService, private intercom: IntercomService, private amplitude: AmplitudeService,
        private router: Router, private route: ActivatedRoute, private productSegmentationService: ProductSegmentationService, private messageService: MessageModalService,
        private organizationCountService: OrganizationCountService) {
        this.authService.currentOrgId$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((orgID) => {
            this.currentOrganizationId = orgID;
        });
        this.loading$ = this.overlay.loading$;
        this.previousPlan$ = this.userSubscriptionService.previousPlan$;
        this.authService.addDatadogAction('PurchaseAttempted');
        this.overlay.isLoading(false);
        this.user = this.authService.getUser();
        if (this.user && this.authService.checkSubscriptionStateForCurrentProduct()) {
            this.router.navigateByUrl('/overview');
        }
        if (this.user.isOwner()) {
            this.userSubscriptionService.getLastInvoice();
            this.previousPlan$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(plan => {
                // @ts-ignore
                this.previousPlanId = plan ? plan.id : '';
            });

            // Note: This is currently dependent on a flag in the user's metadata
            if (this.user.meta['invoice_pending']) {
                this.invoicePending = true;
            }

            // Black Friday
            // this.route.queryParams.subscribe(params => {
            //   if(params.campaign){
            //     this.showBlackFridayPrice = true;
            //   }
            // })

            // switch (this.user.customer_plan) {
            //   case PLAN_TYPES.Starter:
            //     this.blackFridayBannerWidth = '60%';
            //     break;
            //   case PLAN_TYPES.Lite:
            //     this.blackFridayBannerWidth = '40%';
            //     break;
            //   case PLAN_TYPES.Pro:
            //     this.blackFridayBannerWidth = '60%';
            //     break;
            // }
        }

        if (this.authService.getCurrentProduct() === DASHBOARD_MENU.CARDS) {
            this.currentDashboard = DASHBOARD_MENU.CARDS;
        } else {
            this.currentDashboard = DASHBOARD_MENU.QRCODES;
        }

        this.setupLogo();
        if (this.user.isOnHigherPlan(PLAN_TYPES.Enterprise) || this.user.customer_plan !== PLAN_TYPES.Starter) {
            this.showBusinessPlans = true;
        }
    }

    showSwitcher() {
        const isOnHigherPlan = this.user.isOnHigherPlan(PLAN_TYPES.Enterprise);
        return !isOnHigherPlan;
    }

    setupLogo() {
        if (this.user.organization.whitelabel_logo_small) {
            this.brandLogo = this.user.organization.whitelabel_logo_small;
        } else {
            this.brandLogo = '/assets/img/uniqode-header-logo.svg';
        }
    }

    ngOnInit(): void {
        this.previousPage = LoggedInGuard.previousPage;
        this.route.fragment.subscribe(fragment => {
            this.overlay.isLoading(false);
            switch (fragment) {
                case PURCHASE_SECTION[this.PURCHASE_SECTION.Plan].toString().toLowerCase():
                case null:
                    this.currentSection = this.PURCHASE_SECTION.Plan;
                    break;
                case this.PURCHASE_SECTION[this.PURCHASE_SECTION.Payment].toString().toLowerCase():
                    if (this.hasSelectedPlan) {
                        this.currentSection = this.PURCHASE_SECTION.Payment;
                        setTimeout(() => {
                            this.getToken();
                        }, 1000);
                    }
                    break;
            }
        });

        this.handleQueryParams();
        this.firstName = this.user.first_name;

        if (this.invoicePending) {
            this.invoiceModal.show();
            this.fetchUserInvoices();
        }

        this.organizationCountService.getCounts()
            .pipe().subscribe((organizationCount: OrganizationCountModel) => {
                this.qrUserCount = organizationCount.qr_user_count;
            });

        const pricingURL = `https://uniqode-assets.s3.amazonaws.com/pricing${environment.production ? '' : '-qa'}.json`
        this.http.get(pricingURL)
            .subscribe(
                (res: any) => {
                    this.qrPlansData = res;
                    this.exchangeRates = res.exchangeRates;
                },
                (error) => {
                    console.error('error fetching pricing data:', error);
                }
            )
    }

    handleQueryParams(): void {
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            if (!this.plansViewLogged) {
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'view plans', {
                    source: params['source'] || 'unknown'
                });
                this.plansViewLogged = true;
            }
            this.eventSourceQueryParams = params['source'] || 'unknown';
        })
    }

    ngAfterViewInit(): void {
        this.userService.getCards().pipe(takeUntil(this.ngUnsubscribe)).subscribe(cards => {
            this.useExistingCard = true;
            this.cards = cards;
            this.selectedCard = cards.find(c => c.default === true) || cards[0];
        }, err => {
            // handle error
        });

        // setInterval(() => {
        //   if (this.days && this.hours && this.minutes && this.seconds) {
        //     this.countDown();
        //     this.difference = 1669968000000 - this.now;
        //     this.difference = this.difference / (1000 * 60 * 60 * 24);
        //
        //     !isNaN(this.days!.nativeElement.innerText)
        //       ? (this.days!.nativeElement.innerText = Math.floor(this.difference))
        //       : (this.days!.nativeElement.innerHTML = `0`);
        //   }
        // }, 1000);
        this.handleStripeCheckoutCallback();
    }


    private buildHeaders(argsObject: object): object {
        if (!argsObject) {
            // throw new Error('RequestOptionsArgs not passed to buildHeaders');
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });
        argsObject['headers'] = headers;
        return argsObject;
    }


    getToken() {

        const _this = this;

        const stripe = (<any>window).Stripe(environment.stripeKey);
        const elements = stripe.elements();
        const card = elements.create('card');

        card.mount('#card-element');

        card.addEventListener('change', function (event) {
            _this.useExistingCard = false;
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        const form = document.getElementById('payment-form');
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            _this.overlay.isLoading(true);
            // @ts-ignore
            const name = document.getElementById('name').value;
            stripe.createToken(card, {name: name}).then(function (result) {
                if (result.error) {
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    _this.messageModal.show('Failed to update your card', 'danger');
                    _this.overlay.isLoading(false);
                } else {
                    _this.updateCard(result.token.id);
                }
            });
        });
    }

    switchSection(section: PURCHASE_SECTION): void {
        switch (section) {
            case PURCHASE_SECTION.Plan:
                this.currentSection = PURCHASE_SECTION.Plan;
                this.router.navigate(['/renew-plan/'], {
                    relativeTo: this.route,
                    fragment: 'plan',
                    queryParams: {source: this.eventSourceQueryParams}
                });
                this.hasSelectedPlan = false;
                this.hasSelectedDuration = false;
                break;
            case PURCHASE_SECTION.Payment:
                if (this.hasSelectedPlan) {
                    this.currentSection = PURCHASE_SECTION.Payment;
                    this.router.navigate(['/renew-plan/'], {
                        relativeTo: this.route,
                        fragment: 'payment',
                        queryParams: {source: this.eventSourceQueryParams}
                    });
                }
                break;
        }
    }

    setUpPurchaseModel() {
        switch (this.planType) {
            case PLAN_TYPES.Starter:
                this.purchaseModel.name = '';
                this.purchaseModel.price = this.planYearly ? 60 : 15;
                this.purchaseModel.duration = this.planYearly ? 'year' : 'month';
                this.purchaseModel.name = 'Starter ' + this.purchaseModel.duration + 'ly';
                break;
            case PLAN_TYPES.Lite:
                this.purchaseModel.name = '';
                this.purchaseModel.price = this.planYearly ? 180 : 25;
                this.purchaseModel.duration = this.planYearly ? 'year' : 'month';
                this.purchaseModel.name = 'Lite ' + this.purchaseModel.duration + 'ly';
                break;
            case PLAN_TYPES.Pro:
                this.purchaseModel.price = this.planYearly ? 588 : 49;
                this.purchaseModel.duration = this.planYearly ? 'year' : 'month';
                this.purchaseModel.name = 'Pro ' + this.purchaseModel.duration + 'ly';
                break;
            case PLAN_TYPES.Plus:
                this.purchaseModel.price = this.planYearly ? 1188 : 99;
                this.purchaseModel.duration = this.planYearly ? 'year' : 'month';
                this.purchaseModel.name = 'Plus ' + this.purchaseModel.duration + 'ly';
                break;
        }
    }

    setupTotalPrice() {
        switch (this.planType) {
            case PLAN_TYPES.Starter:
                this.totalPrice.yearly = '60';
                break;
            case PLAN_TYPES.Lite:
                this.totalPrice.yearly = '180';
                break;
            case PLAN_TYPES.Pro:
                this.totalPrice.yearly = '588';
                break;
            case PLAN_TYPES.Plus:
                this.totalPrice.yearly = '1188';
                break;
        }
    }

    purchaseWithCard() {
        if (!this.selectedCard) {
            this.messageModal.show('Please add a card before proceeding', 'danger');
            return;
        }
        this.userService.markCardAsDefault(this.selectedCard).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.createSubscription(this.planType);
        }, error => {
            console.error(error);
            this.messageModal.show('An error occurred while change your card', 'danger');
        });
    }

    async getUserCard() {
        await this.userService.getCards().pipe(takeUntil(this.ngUnsubscribe)).subscribe(cards => {
            const source = cards.find(c => c.default === true) || cards[0];
            this.paymentSource = source.id;
        }, err => {
            // handle error
        });
    }

    subscribeWithCheckout(plan: PLAN_TYPES, plusPlanUserAddOn?: number, additionalCustomDomains: boolean = false) {
        this.overlay.isLoading(true);
        const intercom_data = this.utils.checkProductBasedOnPlan(plan) === PRODUCT_TYPES.DBC ? { dbc_upgrade_attempted: true } : { upgrade_attempted: true };
        this.intercom.update(intercom_data);

        // Black Friday
        let coupon = null;
        if (!this.user.isOnTrialPlan() && this.showBlackFridayPrice) {
            coupon = BLACK_FRIDAY_COUNPONS[plan];
            this.onCouponApplied();
        }
        // only for QR plan so dbcUserSeat = 0
        this.userService.subscribeWithCheckout(plan, this.planYearly, this.eventSourceQueryParams, coupon, 0, plusPlanUserAddOn, null, { additionalCustomDomains }).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            window.location.href = res.redirectUrl;
        }, errorResponse => {
            if (errorResponse.error?.error?.status === 409) {
                return this.pollUserUntilSubscriptionActive(10);
            }
            this.overlay.isLoading(false);
            this.authService.addDatadogAction('SubscriptionUpgradeFailed');
            this.messageModal.show('Failed to upgrade. Please contact support@uniqode.com.\n' + errorResponse.error.error.error, 'danger', 10000);

            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'purchase plan', {
                plan: User.getReadablePlan(plan),
                success: false,
                source: this.eventSourceQueryParams
            });
        });
    }

    handleStripeCheckoutCallback() {
        this.route.queryParams.subscribe(params => {
            // check plan from route
            let plan;
            if (params.plan) {
                plan = params.plan
            } else {
                plan = this.currentDashboard === DASHBOARD_MENU.CARDS ? this.user.dbcCustomerPlan : this.user.qrCustomerPlan;
            }
            if (params.payment === 'success') {
                if (params.trial_upgrade) {
                    const intercom_trial_upgrade = this.utils.checkProductBasedOnPlan(plan) === PRODUCT_TYPES.DBC ? { dbc_trial_upgrade: true } : { trial_upgrade: true };
                    this.intercom.update(intercom_trial_upgrade);
                    this.googleAnalyticsService.eventEmitter('TrialUpgrade', 'conversion', 'Trial Upgrade');
                }
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'purchase plan', {
                    plan: this.user.getReadablePlan(),
                    success: true,
                    source: params.source
                });
                this.authService.addDatadogAction('SubscriptionUpgraded');
                this.intercom.update({upgrade_source: params.source});
                this.paymentSuccess.show();
                this.userService.subscribeWithCheckoutId(params.checkout_id).subscribe(() => {
                    this.pollUserUntilSubscriptionActive();
                }, error => {
                    console.error(error);
                });
            } else if (params.payment === 'failed') {
                this.authService.addDatadogAction('SubscriptionUpgradeFailed');
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'purchase plan', {
                    plan: this.user.getReadablePlan(),
                    success: false,
                    source: params.source
                });
                this.paymentFailure.show();
            }
        });
    }

    async createSubscription(plan: PLAN_TYPES) {
        this.overlay.isLoading(true);

        this.intercom.update({
            purchase_attempted: true
        });
        this.paymentSource = this.paymentSource ? this.paymentSource : this.selectedCard.id;
        const couponCode = this.couponCodeModel.success ? this.couponCodeModel.couponCode : null;
        this.userService.createSubscription(plan, this.paymentSource, this.planYearly, couponCode, this.eventSourceQueryParams).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.messageModal.show('Subscription added successfully');
            this.userSubscriptionService.getSubscriptionDetails();
            this.pollUserUntilPlanCreated(plan);
            this.authService.addDatadogAction('RenewPlan');

            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'renew plan', {
                success: true,
                source: this.eventSourceQueryParams,
                plan: User.getReadablePlan(plan)
            });
        }, errorResponse => {
            this.overlay.isLoading(false);
            this.authService.addDatadogAction('SubscriptionCreationFailed');
            this.messageModal.show('Failed to add the subscription. Please contact support@uniqode.com.\n' + errorResponse.error.error, 'danger', 10000);

            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'renew plan', {
                success: false,
                source: this.eventSourceQueryParams,
                plan: User.getReadablePlan(plan)
            });
        });
    }

    private pollUserUntilSubscriptionActive(count = 10) {
        this.userService.getDetail().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
            if (user.subscription_state !== SUBSCRIPTION_STATE.Active && count > 0) {
                setTimeout(() => {
                    this.pollUserUntilSubscriptionActive(count - 1);
                }, 1000);
                return;
            } else {
                if (count === 0) {
                    this.overlay.isLoading(false);
                    this.paymentSuccess.hide();
                    this.messageModal.show('Something when wrong while activating your account. Please contact support@uniqode.com.', 'danger', 10000);
                    return;
                }
                this.overlay.isLoading(false);
                this.routerReloadService.reload();
                if (this.previousPage) {
                    this.router.navigateByUrl(this.previousPage);
                } else {
                    this.router.navigate(['/']);
                }
            }
        });
    }

    private pollUserUntilPlanCreated(plan: PLAN_TYPES, count = 10) {
        this.userService.getDetail().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
            if (user.customer_plan !== plan && count > 0) {
                setTimeout(() => {
                    this.pollUserUntilPlanCreated(plan, count - 1);
                }, 500);
                return;
            } else {
                this.overlay.isLoading(false);
                this.routerReloadService.reload();
                if (this.previousPage) {
                    this.router.navigateByUrl(this.previousPage);
                } else {
                    this.router.navigate(['/']);
                }
            }
        });
    }

    updateCard(token: string): void {
        const body = {
            stripe_card_token: token,
            default: true
        };
        const url = RenewPlanComponent.stripeCardBaseURL + this.authService.getUser().id + '/cards/';
        this.http.post(url, body, this.buildHeaders({}))
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.paymentSource = res['id'];
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'add card');
                this.createSubscription(this.planType);
            }, error => {
                // TODO: Handle card errors
                this.authService.addDatadogAction('CardUpdateFailed');
                this.messageModal.show('There was an error while updating your card', 'danger');
            })
    }


    setUseExistingCard(card) {
        this.useExistingCard = true;
        this.selectedCard = card;
    }


    onPlanDurationClicked(yearly: boolean) {
        this.planYearly = yearly;
        this.billingCycle = this.planYearly ? 'YEARLY' : 'MONTHLY';
        this.setUpPurchaseModel();
        this.hasSelectedDuration = true;
        this.switchSection(PURCHASE_SECTION.Payment);
    }

    onPlanTypeClicked({ plan, plusPlanUserAddOn, additionalCustomDomains }: { plan: PLAN_TYPES, plusPlanUserAddOn?: number, additionalCustomDomains?: boolean}) {
        this.planType = plan;
        this.yearlyPrice = this.planType === PLAN_TYPES.Starter ? '5' : this.planType === PLAN_TYPES.Lite ? '15' : this.planType === PLAN_TYPES.Pro ? '49' : '';
        this.discount = this.planType === PLAN_TYPES.Starter ? '66.66' : this.planType === PLAN_TYPES.Lite ? '50' : this.planType === PLAN_TYPES.Pro ? '16.67' : '';
        this.setupTotalPrice();
        this.hasSelectedPlan = true;
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'select plan', {
            plan: User.getReadablePlan(plan),
            source: this.eventSourceQueryParams
        });

        if (!getCookie('source')) {
            const cookie = `source=${this.eventSourceQueryParams}; expires=${moment().add(1, 'day')}`;
            document.cookie = cookie;
        }

        this.eventSourceQueryParams = getCookie('source');
        // as 3 is the default value for user add on in plus plan
        plusPlanUserAddOn = plusPlanUserAddOn - 3;
        return this.subscribeWithCheckout(plan, plusPlanUserAddOn, additionalCustomDomains);
    }

    onEnterpriseSelected() {
        window.open('https://www.uniqode.com/schedule-demo?utm_source=Dashboard&utm_medium=Enterprise&utm_campaign=Talktous', '_blank');
    }

    isOnYearlyPlan(planId: string): boolean {
        return planId.indexOf('YEAR') !== -1;
    }

    purchase() {
        if (this.useExistingCard) {
            this.purchaseWithCard();
        } else {
            document.getElementById('purchase-submit-button').click();
        }
    }

    onCouponApplied() {
        this.couponCodeModel.success = false;
        this.couponCodeModel.error = false;
        this.couponCodeModel.loading = true;
        this.couponCodeModel.coupon = null;
        this.userService.verifyPromoCode(this.couponCodeModel.couponCode).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => this.couponCodeModel.loading = false)
        ).subscribe(coupon => {
            this.couponCodeModel.error = false;
            this.couponCodeModel.success = true;
            this.couponCodeModel.coupon = coupon;
            this.messageModal.show(`Promo code ${this.couponCodeModel.couponCode} applied successfully`);
        }, error => {
            this.couponCodeModel.error = true;
            this.couponCodeModel.success = false;
        })
    }

    onCouponRemoved() {
        this.couponCodeModel.success = false;
        this.couponCodeModel.error = false;
        this.couponCodeModel.loading = false;
        this.couponCodeModel.couponCode = '';
        this.couponCodeModel.coupon = null;
    }

    isCurrentPlan(plan: PLAN_TYPES) {
        return this.user.customer_plan === plan;
    }

    switchInfoModalPlan(plan: number) {
        this.infoSectionPlan = plan;
        const table = document.getElementById('table-mobile');
        const childNodes = table.children[1].children;
        for (let i = 0; i < childNodes.length; i++) {
            if (childNodes[i].children.length > 1) {
                for (let j = 0; j < childNodes[i].children.length; j++) {
                    if (j === plan || j === 0) {
                        // @ts-ignore
                        childNodes[i].children[j].style = 'display: table-cell;';
                    } else {
                        // @ts-ignore
                        childNodes[i].children[j].style = 'display: none;';
                    }
                }
            }
        }
    }

    monthlyPlanNotDisabled(plan: PLAN_TYPES) {
        return [this.PLAN_TYPES.Starter, this.PLAN_TYPES.Lite, this.PLAN_TYPES.Pro].indexOf(plan) === -1
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    logout() {
        this.authService.logout();
    }

    redirectToStore(product = DASHBOARD_MENU.QRCODES) {
        switch (product) {
            case DASHBOARD_MENU.QRCODES:
                window.open('https://www.uniqode.com/buy-qr-code-solution/', '_blank');
                break
            case DASHBOARD_MENU.CARDS:
                window.open('https://www.uniqode.com/buy-digital-business-card-solution/', '_blank');
                break
        }
    }

    countDown() {
        this.date = new Date();
        this.now = this.date.getTime();
        this.days!.nativeElement.innerText = Math.floor(this.difference);
        this.hours!.nativeElement.innerText = 23 - this.date.getHours();
        this.minutes!.nativeElement.innerText = 60 - this.date.getMinutes();
        this.seconds!.nativeElement.innerText = 60 - this.date.getSeconds();
    }

    switchCurrentProduct() {

        switch (this.authService.getCurrentProduct()) {
            case DASHBOARD_MENU.QRCODES:
                if (this.user.hasDBCAccess(this.authService.getCurrentOrgId())) {
                    this.router.navigateByUrl('/overview');
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC)
                } else if (this.user.hadDBCAccess()) {
                    this.currentDashboard = DASHBOARD_MENU.CARDS
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC)
                } else {
                    this.router.navigate(['/trial-purchase/'], {queryParams: {orgID: this.authService.getCurrentOrgId(), product: 'cards'}})
                }
                break
            case DASHBOARD_MENU.CARDS:
                if (this.user.hasQRAccess(this.authService.getCurrentOrgId())) {
                    this.router.navigateByUrl('/overview');
                } else if (this.user.hadQRAccess()) {
                    this.currentDashboard = DASHBOARD_MENU.QRCODES
                } else {
                    this.showTrialSelectModal();
                }
                this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR)
                break
        }
    }



    updateSubscriptionWithCheckout(plan: PLAN_TYPES, userSeats?: number) {
        this.overlay.isLoading(true);
        const intercom_data = this.utils.checkProductBasedOnPlan(plan) === PRODUCT_TYPES.DBC ? { dbc_upgrade_attempted: true } : { upgrade_attempted: true };
        this.intercom.update(intercom_data);

        this.userService.subscribeWithCheckout(plan, this.planYearly, this.eventSourceQueryParams, null, userSeats).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            window.location.href = res.redirectUrl;
        }, errorResponse => {
            this.overlay.isLoading(false);
            this.authService.addDatadogAction('SubscriptionUpgradeFailed');
            this.messageModal.show('Failed to upgrade. Please contact support@uniqode.com.\n' + errorResponse.error.error.error, 'danger', 10000);
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'purchase plan', {
                plan: User.getReadablePlan(plan),
                success: false,
                source: this.eventSourceQueryParams
            });
        });
    }

    fetchUpcomingPrice() {
        let requiredNoOfDBCUserSeats = Number(this.dbcUserSeats);
        if (this.selectedDBCPlan) {
            switch (this.selectedDBCPlan) {
                case PLAN_TYPES.Solo:
                    requiredNoOfDBCUserSeats = requiredNoOfDBCUserSeats - DBC_MINM_USER_SEATS.Solo;
                    break;
                case PLAN_TYPES.Team:
                    requiredNoOfDBCUserSeats = requiredNoOfDBCUserSeats - DBC_MINM_USER_SEATS.Team;
                    break;
                case PLAN_TYPES.Business:
                    requiredNoOfDBCUserSeats = requiredNoOfDBCUserSeats - DBC_MINM_USER_SEATS.Business;
                    break;
            }
        }

        const data = {
            plan: this.planType,
            yearly: this.planYearly,
            coupon: this.couponCodeModel.couponCode && this.couponCodeModel.success ? this.couponCodeModel.couponCode : '',
        };

        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            data['additionalUserSeats'] = Number(requiredNoOfDBCUserSeats);
        }

        if (this.priceSubscription) {
            this.priceSubscription.unsubscribe();
        }

        this.priceSubscription = this.userService.getUpcomingInvoicePrice(data).subscribe(res => {
            this.planPrice = res.upcomingPrice
        })
    }

    showTrialSelectModal() {
        this.TrialInfo.Product = 'QR Codes'
        this.TrialInfo.Heading = 'Uniqode QR Codes'
        this.TrialInfo.Description = 'A QR Code management platform to'
        this.TrialInfo.Details = [
            'Create custom codes for your use case',
            'Edit destination later, even after printing ',
            'Track scans in real-time '
        ]
        this.trialSelectModal.show();
    }

    handleTrialCreateModal() {
        this.trialSelectModal.hide();
        this.overlay.isLoading(true)
        if (this.TrialInfo.Product === 'QR Codes') {
            const body = {
                plan: PLAN_TYPES.Trial
            }
            this.productSegmentationService.post(body).pipe().subscribe(res => {
                this.userService.getDetail().subscribe(() => {
                    this.messageService.show('Upgraded to QR Codes trial');
                    this.authService.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR)
                    this.router.navigate(['/qr-codes/add'], { queryParams: { orgID: this.currentOrganizationId } });
                    this.overlay.isLoading(false)
                })
            }, error => {
                console.log('error');
                this.router.navigate(['/overview'], { queryParams: { orgID: this.currentOrganizationId } });
            })
        } else if (this.TrialInfo.Product === 'Cards') {
            this.router.navigate(['/trial-purchase/'], { queryParams: { orgID: this.currentOrganizationId, product: 'cards' } })
        }
    }

    payInvoice() {
        window.open(this.pendingInvoices[0]['link'], '_self');
    }

    copyInvoicePaymentLink() {
        navigator.clipboard.writeText(this.pendingInvoices[0]['link']);
    }

    fetchUserInvoices(startingAfter?) {
        this.fetchingInvoices = true;
        this.userService.getInvoices(startingAfter).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => this.fetchingInvoices = false)
        ).subscribe(res => {
            for (const data of res['data']) {
                // TODO: This currently handles pending invoices from the polo migration, can be generalised later.
                if (data['metadata']['polo_migration'] && data['status'] !== 'paid') {
                    this.pendingInvoices.push({
                        number: data['number'],
                        link: data['hosted_invoice_url'],
                        status: data['status']
                    });
                }
            }
            if (this.pendingInvoices.length === 0) {
                this.messageModal.show('Error fetching invoices. Please contact support@uniqode.com', 'danger');
            }
        }, error => {
            this.messageModal.show('Error fetching invoices. Please contact support@uniqode.com', 'danger');
        })
    }

    togglePlans(isBusinessPlanView: boolean) {
        if ((isBusinessPlanView && !this.showBusinessPlans) || (!isBusinessPlanView && this.showBusinessPlans)) {
            return;
        }
        this.showBusinessPlans = !isBusinessPlanView;
    }

}
