<div
    class="bx--grid breadcrumb-top-margin-issue my-4 mx-2 webkit-fill-available"
>

    <div class="bx--row px-md-0 mb-4 mx-1 mx-md-0">

        <div *ngIf="pageType === 'aggregate'" class="bx--col-lg-3 mb-4 mb-sm-0 analytics-card-header d-flex justify-content-start  align-items-center pl-0">
            <span>Card Analytics {{('(' + this.filteredProductIds.length + ')')}}</span>
        </div>

        <div [ngClass]="pageType === 'aggregate' ? 'bx--col-sm-0 bx--col-md-0 bx--col-lg-13 px-0' : 'bx--col-sm-0 bx--col-md-0 bx--col-lg-16 px-0'">

            <div *ngIf="hasExportAnalyticsPermission && !customDateSelected" class="float-right export--btn">
                <button class="btn btn-primary d-flex justify-content-between export-dropdown align-items-center"
                  (click)="isExportOpen = !isExportOpen">
                  <span style="text-transform: capitalize;">Export</span>
                  <i [ngClass]="isExportOpen && 'caret-up-effect'" class="fas fa-caret-down"></i>
                </button>

                <beaconstac-options *ngIf="isExportOpen" [maxWidth]="'-webkit-fill-available'" [value]="currentOrg"
                  (valueChange)="handleExportClick($event)" [highlightSelectedValue]="false" [optionLabelField]="'name'"
                  [optionValueField]="'name'" [listItems]="ANALYTICS_EXPORT_OPTIONS">
                </beaconstac-options>
            </div>

            <div *ngIf="!isIndividualCard && !customDateSelected && isAdmin" class="mr-lg-1 mr-xl-3 mr-3 mt-3 mt-md-0 float-right" style="min-width: 188px; max-width: 248px">
                <beaconstac-filter-dropdown
                    [title]="'cardSeggregation'"
                    [listItems]="cardSeggregationsOptions"
                    [disableInput]="true"
                    [optionLabelField]="'name'"
                    [optionValueField]="'id'"
                    [width]="'auto'"
                    [(value)]="selectedCardOption"
                    (valueChange)="onChangeCardSeggregation($event)"
                >
                </beaconstac-filter-dropdown>
            </div>

            <div *ngIf="!customDateSelected" class="mr-lg-1 mr-xl-3 mr-3 float-right">
                <div class="nav navbar-nav d-inline-block w-100">
                  <div class="nav-item dropdown" dropdown >
                    <beaconstac-filter-dropdown [title]="'duration'" [listItems]="dayOption" [disableInput]="true"
                                [optionLabelField]="'name'" [optionValueField]="'id'" [width]="'125px'"
                                [(value)]="dayOptionSelected" (valueChange)="selectDuration($event)">
                            </beaconstac-filter-dropdown>
                  </div>
                </div>
            </div>

            <div *ngIf="!customDateSelected " style="float: right" class="mr-lg-1 mr-xl-3 mr-3 date-display date-analytics-button">
                <span style="font-size: 15px; font-weight: 500; white-space: nowrap; overflow: scroll; text-overflow: clip;">
                  {{globalDateString}}
                </span>
            </div>

            <div *ngIf="customDateSelected" class="mr-md-auto ml-2 d-inline-flex align-items-center float-right">
                <div>
                    <beaconstac-datepicker
                        (onDateChange)="onCustomDateChange('from', $event)"
                        [label]="false"
                        [maxWidth]="'650px'"
                        [maxDate]="currentDateForPicker"
                        [dateSelected]="startDateForCustomDate"
                    ></beaconstac-datepicker>
                </div>
                <span class="text-with-margin-analytics">To</span>
                <div class="d-inline-block" style="margin-right: 5px">
                    <beaconstac-datepicker
                        (onDateChange)="onCustomDateChange('to', $event)"
                        [label]="false"
                        [maxWidth]="'650px'"
                        [maxDate]="currentDateForPicker"
                        [dateSelected]="endDateForCustomDate"
                    ></beaconstac-datepicker>
                </div>
                <button class="btn btn-primary done-btn border-radius-2" style="height: 40px;"
                  (click)="applyCustomDate()">Done</button>
            </div>

        </div>

        <div class="bx--col-sm-4 bx--col-md-8 bx--col-lg-0 bx--col-xlg-0 bx--col-max-0 px-0">
            <div class="d-flex flex-column">

                <div *ngIf="!customDateSelected" class="date-display date-analytics-button">
                    <span style="font-size: 15px; font-weight: 500; white-space: nowrap; overflow: scroll; text-overflow: clip;">
                    {{globalDateString}}
                    </span>
                </div>

                <div *ngIf="!customDateSelected">
                    <div class="nav navbar-nav w-100 mt-3">
                        <div class="nav-item dropdown" dropdown >
                            <beaconstac-filter-dropdown [title]="'duration2'" [listItems]="dayOption" [disableInput]="true"
                            [optionLabelField]="'name'" [optionValueField]="'id'" [width]="'auto'"
                            [(value)]="dayOptionSelected" (valueChange)="selectDuration($event)">
                            </beaconstac-filter-dropdown>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isIndividualCard && !customDateSelected" class="mt-3">
                    <beaconstac-filter-dropdown
                        [title]="'cardSeggregationMobile'"
                        [listItems]="cardSeggregationsOptions"
                        [disableInput]="true"
                        [(value)]="selectedCardOption"
                        [optionLabelField]="'name'"
                        [optionValueField]="'id'"
                        (valueChange)="onChangeCardSeggregation($event)"
                        [width]="'auto'"
                    >

                    </beaconstac-filter-dropdown>
                </div>

                <div *ngIf="hasExportAnalyticsPermission && !customDateSelected" class="mt-3">
                    <button class="btn btn-primary d-flex justify-content-between export-dropdown align-items-center"
                    style="width: 100%;"
                    (click)="isExportOpen = !isExportOpen">
                    <span>Export</span>
                    <i [ngClass]="isExportOpen && 'caret-up-effect'" class="fas fa-caret-down"></i>
                    </button>

                    <beaconstac-options *ngIf="isExportOpen" [maxWidth]="'100%'" [value]="currentOrg"
                    (valueChange)="handleExportClick($event)" [highlightSelectedValue]="false" [optionLabelField]="'name'"
                    [optionValueField]="'name'" [listItems]="ANALYTICS_EXPORT_OPTIONS">
                    </beaconstac-options>
                </div>

                <div *ngIf="customDateSelected" class="d-flex" style="justify-content: space-between; align-items: center;">

                    <div style="width: 45%;">
                        <beaconstac-datepicker
                            (onDateChange)="onCustomDateChange('from', $event)"
                            [label]="false"
                            [maxWidth]="'650px'"
                            [maxDate]="currentDateForPicker"
                            [dateSelected]="startDateForCustomDate"
                        ></beaconstac-datepicker>
                    </div>
                    <span class="px-1">To</span>
                    <div style="width: 45%;">
                        <beaconstac-datepicker
                            (onDateChange)="onCustomDateChange('to', $event)"
                            [label]="false"
                            [maxWidth]="'650px'"
                            [maxDate]="currentDateForPicker"
                            [dateSelected]="endDateForCustomDate"
                        ></beaconstac-datepicker>
                    </div>
                </div>
                <button *ngIf="customDateSelected" class="btn btn-primary border-radius-2" style="margin-top: -0.2rem; width: 100%;"
                    (click)="applyCustomDate()">Done</button>

            </div>
        </div>

    </div>


    <!-- CARD DETAILS ( SHOWN FOR INDIVIDUAL CARD ANALYTICS) -->
    <div *ngIf="isIndividualCard" class="bx--row my-3 py-4 px-2 mx-0 bg-white">
        <div class="bx--col-lg-12">
            <div class="bx--row bg-white">
                <div class="bx--col-xl-6 bx--col-lg-10 bx--col-md-8">
                    <span class="caption text-uppercase">Link: </span>
                    <span
                        class="btn-link cursor-pointer"
                        (click)="redirectToPreview()"
                        >{{ card?.url }}</span
                    >
                    <i
                        (click)="copyToClipboard(card?.url)"
                        class="far fa-copy text-primary ml-2 cursor-pointer"
                    ></i>
                </div>
            </div>
            <div class="bx--row mt-2">
                <div class="bx--col">
                    <span class="caption text-uppercase">Created: </span
                    ><span class="body-1">{{
                        card?.created | formatDate
                    }}</span>
                </div>
            </div>
        </div>
        <div class="bx--col-lg-4">
            <div
                *ngIf="hasWriteAccess"
                class="bx--md-down-float-unset analytics-edit-button float-right"
                style="padding-top: 6px"
            >
                <beaconstac-button
                    (onClick)="redirectToEdit()"
                    [label]="'Edit'"
                    [icon]="'fas fa-pencil-alt'"
                    [iconSize]="ICON_SIZE.LARGE"
                    [style]="BUTTON_STYLES.STROKE"
                ></beaconstac-button>
            </div>
        </div>
    </div>


    <!-- FILTERS -->
    <div *ngIf="pageType === 'aggregate'" class="px-4 py-3 my-4 bg-white border-radius-2 border-gray-custom">
        <beaconstac-filter-products
            [filterOptions]="CARDS_FILTER_OPTIONS"
            [filterQueryParams]="queryParams"
            [qrCodeCount]="1"
            (filterChange)="onFilterChange($event)"
            [dbcTemplatesList]="dbcTemplatesList"
            [productType]="productType"
            [tagList]="tagList"
            [mobileDrawer]="true"
        ></beaconstac-filter-products>
    </div>
    <!-- FILTERS END -->


    <!-- Aggregates Start -->
    <div class="d-flex flex-sm-column flex-md-row">
        <div
            class="bg-white p-3 mr-3 border-radius-2px width-quarter shadow-sm"
        >
            <h6 class="font-weight-normal pb-3">Card views</h6>
            <h3 class="mb-2">{{ presentTotalViews || 0 }}</h3>
            <div>
                <beaconstac-tag
                    [tagTitle]="
                        (totalChangeInViews || 0) >= 0
                            ? upArrowIcon + (totalChangeInViews || 0) + '%'
                            : downArrowIcon + (totalChangeInViews || 0) + '%'
                    "
                    [bgColor]="
                        (totalChangeInViews || 0) >= 0 ? '#E2F8EC' : '#FFEFEF'
                    "
                    [textColor]="
                        (totalChangeInViews || 0) >= 0 ? '#26B969' : '#FF445E'
                    "
                ></beaconstac-tag>
                <span> than previous period </span>
            </div>
        </div>
        <div
            class="bg-white p-3 mr-3 border-radius-2px width-quarter shadow-sm"
        >
            <h6 class="font-weight-normal pb-3">Contact saved</h6>
            <h3 class="mb-2">{{ totalDownloadsCount }}</h3>
            <div>
                <beaconstac-tag
                    [tagTitle]="
                        (impressionsComparisonPercentage || 0) >= 0
                            ? upArrowIcon +
                              (impressionsComparisonPercentage || 0) +
                              '%'
                            : downArrowIcon +
                              (impressionsComparisonPercentage || 0) +
                              '%'
                    "
                    [bgColor]="
                        (impressionsComparisonPercentage || 0) >= 0
                            ? '#E2F8EC'
                            : '#FFEFEF'
                    "
                    [textColor]="
                        (impressionsComparisonPercentage || 0) >= 0
                            ? '#26B969'
                            : '#FF445E'
                    "
                ></beaconstac-tag>
                <span> than previous period </span>
            </div>
        </div>
        <div class="bg-white p-3 border-radius-2px width-quarter shadow-sm">
            <h6 class="font-weight-normal pb-3">Unique card views</h6>
            <h3 class="mb-2">{{ presentUniqueUsers || 0 }}</h3>
            <div>
                <beaconstac-tag
                    [tagTitle]="
                        (usersComparisonPercentage || 0) >= 0
                            ? upArrowIcon +
                              (usersComparisonPercentage || 0) +
                              '%'
                            : downArrowIcon +
                              (usersComparisonPercentage || 0) +
                              '%'
                    "
                    [bgColor]="
                        (usersComparisonPercentage || 0) >= 0
                            ? '#E2F8EC'
                            : '#FFEFEF'
                    "
                    [textColor]="
                        (usersComparisonPercentage || 0) >= 0
                            ? '#26B969'
                            : '#FF445E'
                    "
                ></beaconstac-tag>
                <span> than previous period </span>
            </div>
        </div>
    </div>
    <!-- Aggregates End  -->

    <!-- Views Start -->
    <div class="bx--row my-3 mx-0 bg-white shadow-sm border-radius-2px">
        <div class="bx--col">
            <div class="bx--row">
                <div class="bx--col-lg-12 p-4">
                    <div class="bx--row">
                        <div class="bx--col">
                            <span class="analytics-card-header"
                                >Card views</span
                            >
                        </div>
                    </div>
                </div>
                <div class="bx--col-lg-4 p-4">
                    <div class="float-right d-flex pt-2 align-items-center">
                        <div class="ml-1">
                            <i
                                class="fas fa-chart-bar border border-radius-2 chart-container-border-right py-2 px-3 cursor-pointer"
                                aria-hidden="true"
                                [ngClass]="
                                    viewsActiveChartType === 'bar'
                                        ? 'border-primary text-primary'
                                        : 'border-secondary text-secondary'
                                "
                                (click)="viewsActiveChartType = 'bar'"
                            ></i>
                        </div>
                        <div>
                            <i
                                class="fas fa-chart-line border border-radius-2 chart-container-border-left py-2 px-3 cursor-pointer"
                                aria-hidden="true"
                                [ngClass]="
                                    viewsActiveChartType === 'line'
                                        ? 'border-primary text-primary'
                                        : 'border-secondary text-secondary'
                                "
                                (click)="viewsActiveChartType = 'line'"
                            ></i>
                        </div>
                        <beaconstac-button
                            class="ml-4"
                            *ngIf="hasExportAnalyticsPermission"
                            [useCustomImage]="true"
                            [style]="BUTTON_STYLES.TEXT"
                            [iconSize]="ICON_SIZE.LARGE"
                            [icon]="'../../../assets/img/export-filled.svg'"
                            [label]="'Export'"
                            (onClick)="downloadViewsCSV()"
                        >
                        </beaconstac-button>
                    </div>
                </div>
            </div>
            <div *ngIf="isFetchingViews" class="spinner-container-analytics-v2">
                <spinner
                    class="custom-spinner-analytics"
                    [size]="40"
                    [tickness]="2"
                ></spinner>
            </div>
            <div *ngIf="viewsError" class="spinner-container-analytics-v2">
                {{ viewsError }}
            </div>
            <div *ngIf="!isFetchingViews && !viewsError" class="bx--row">
                <div
                    *ngIf="viewsActiveChartType === 'line'"
                    class="bx--col-lg-12 p-4"
                    id="line-chart"
                >
                    <div
                        *ngIf="
                            viewsOverTimeLineChartData[0]['series']?.length &&
                            viewsOverTimeLineChartData[1]['series']?.length
                        "
                    >
                        <ngx-charts-line-chart
                            [view]="lineChartOptions.view"
                            [scheme]="lineChartOptions.colorScheme"
                            [animations]="true"
                            [autoScale]="false"
                            [results]="viewsOverTimeLineChartData"
                            [xAxis]="lineChartOptions.xAxis"
                            [yAxis]="lineChartOptions.yAxis"
                            [timeline]="lineChartOptions.timeline"
                        >
                            <ng-template #tooltipTemplate let-model="model">
                                <div
                                    *ngIf="model"
                                    class="d-flex align-content-center justify-content-between flex-wrap font-size-13 tooltip-blue"
                                >
                                    {{ model.name }}: card views
                                    {{ model.value | number }}
                                </div>
                            </ng-template>
                            <ng-template
                                #seriesTooltipTemplate
                                let-model="model"
                            >
                                <div class="font-size-13">
                                    <div
                                        *ngIf="model[0]"
                                        class="d-flex align-content-center justify-content-between flex-wrap my-auto tooltip-blue"
                                    >
                                        <span class="text-primary">
                                            {{ model[0].name }}:
                                        </span>
                                        <span
                                            *ngIf="
                                                model[1] &&
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                ) >= 0
                                            "
                                            class="pl-2 text-success"
                                        >
                                            +{{
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                )
                                            }}%
                                        </span>
                                        <span
                                            *ngIf="
                                                model[1] &&
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                ) < 0
                                            "
                                            class="pl-2 text-danger"
                                        >
                                            {{
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                )
                                            }}%
                                        </span>
                                        <span class="pl-4 text-primary">{{
                                            model[0].value | number
                                        }}</span>
                                    </div>
                                </div>

                                <div
                                    class="font-size-13"
                                    style="border-top: 1px solid #63c2de"
                                >
                                    <div
                                        *ngIf="model[1]"
                                        class="d-flex align-content-center justify-content-between flex-wrap my-auto text-primary"
                                    >
                                        <span
                                            *ngIf="dayDifference > 1"
                                            class="text-success"
                                        >
                                            {{ getDate(model[1].name) }}:
                                        </span>
                                        <span
                                            *ngIf="dayDifference <= 1"
                                            class="text-success"
                                        >
                                            {{ model[0].name }}:
                                        </span>
                                        <span class="ml-auto text-success">{{
                                            model[1].value | number
                                        }}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-charts-line-chart>
                    </div>
                </div>

                <div
                    *ngIf="viewsActiveChartType === 'bar'"
                    class="bx--col-lg-12 p-4"
                    id="line-chart"
                >
                    <div *ngIf="viewsOverTimeBarChartData.length">
                        <ngx-charts-bar-vertical-2d
                            [view]="lineChartOptions.view"
                            [scheme]="lineChartOptions.colorScheme"
                            [results]="viewsOverTimeBarChartData"
                            [xAxis]="lineChartOptions.xAxis"
                            [yAxis]="lineChartOptions.yAxis"
                        >
                            <ng-template #tooltipTemplate let-model="model">
                                <div
                                    *ngIf="model"
                                    class="d-flex align-content-center justify-content-between flex-wrap font-size-13 tooltip-blue"
                                >
                                    {{ model.name }} card views :
                                    {{ model.value | number }}
                                </div>
                            </ng-template>
                        </ngx-charts-bar-vertical-2d>
                    </div>
                </div>
                <div class="bx--col-lg-4 p-4">
                    <div class="bx--row">
                        <div
                            class="bx--col p-0 bx--md-down-px-4"
                            id="views-section"
                        >
                            <div class="bx--row">
                                <div class="bx--col">
                                    <span
                                        class="caption text-uppercase"
                                        style="color: #979797"
                                        >Card views</span
                                    >
                                </div>
                            </div>
                            <div class="bx--row mt-2">
                                <div class="bx--col">
                                    <span class="analytics-count">{{
                                        presentTotalViews || 0
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="bx--col p-0 bx--md-down-px-4">
                            <span
                                class="pr-3 float-right"
                                [ngStyle]="{ 'margin-top': marginTopViews }"
                                id="views-section-tag"
                            >
                                <beaconstac-tag
                                    [tagTitle]="
                                        (totalChangeInViews || 0) >= 0
                                            ? upArrowIcon +
                                              (
                                                  totalChangeInViews || 0
                                              )?.toFixed(2) +
                                              '%'
                                            : downArrowIcon +
                                              (
                                                  totalChangeInViews || 0
                                              )?.toFixed(2) +
                                              '%'
                                    "
                                    [bgColor]="
                                        (totalChangeInViews || 0) >= 0
                                            ? '#E2F8EC'
                                            : '#FFEFEF'
                                    "
                                    [textColor]="
                                        (totalChangeInViews || 0) >= 0
                                            ? '#26B969'
                                            : '#FF445E'
                                    "
                                >
                                </beaconstac-tag>
                            </span>
                        </div>
                    </div>
                    <div class="bx--row mt-4">
                        <div class="bx--col p-0 bx--md-down-px-4">
                            <div class="bx--row">
                                <div class="bx--col">
                                    <span
                                        class="caption text-uppercase"
                                        style="color: #979797"
                                        >previous period</span
                                    >
                                </div>
                            </div>
                            <div class="bx--row mt-2">
                                <div class="bx--col analytics-count">
                                    {{ pastTotalViews || 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Views End -->

    <!-- Downloads Start -->
    <div class="bx--row mt-4 mb-3 mx-0 bg-white shadow-sm border-radius-2px">
        <div class="bx--col">
            <div class="bx--row">
                <div class="bx--col-lg-12 p-4">
                    <div class="bx--row">
                        <div class="bx--col">
                            <span class="analytics-card-header"
                                >Contact saves</span
                            >
                        </div>
                    </div>
                </div>
                <div class="bx--col-lg-4 p-4">
                    <div class="float-right d-flex pt-2 align-items-center">
                        <div class="ml-1">
                            <i
                                class="fas fa-chart-bar border border-radius-2 chart-container-border-right py-2 px-3 cursor-pointer"
                                aria-hidden="true"
                                [ngClass]="
                                    downloadsActiveChartType === 'bar'
                                        ? 'border-primary text-primary'
                                        : 'border-secondary text-secondary'
                                "
                                (click)="downloadsActiveChartType = 'bar'"
                            ></i>
                        </div>
                        <div>
                            <i
                                class="fas fa-chart-line border border-radius-2 chart-container-border-left py-2 px-3 cursor-pointer"
                                aria-hidden="true"
                                [ngClass]="
                                    downloadsActiveChartType === 'line'
                                        ? 'border-primary text-primary'
                                        : 'border-secondary text-secondary'
                                "
                                (click)="downloadsActiveChartType = 'line'"
                            ></i>
                        </div>
                        <beaconstac-button
                            class="ml-4"
                            *ngIf="hasExportAnalyticsPermission"
                            [useCustomImage]="true"
                            [style]="BUTTON_STYLES.TEXT"
                            [iconSize]="ICON_SIZE.LARGE"
                            [icon]="'../../../assets/img/export-filled.svg'"
                            [label]="'Export'"
                            (onClick)="downloadDownloadsCSV()"
                        >
                        </beaconstac-button>
                    </div>
                </div>
            </div>
            <div
                *ngIf="isFetchingDownloads && !downloadsError"
                class="spinner-container-analytics-v2"
            >
                <spinner
                    class="custom-spinner-analytics"
                    [size]="40"
                    [tickness]="2"
                ></spinner>
            </div>
            <div *ngIf="downloadsError" class="spinner-container-analytics-v2">
                {{ downloadsError }}
            </div>
            <div
                *ngIf="!isFetchingDownloads && !downloadsError"
                class="bx--row"
            >
                <div
                    *ngIf="downloadsActiveChartType === 'line'"
                    class="bx--col-lg-12 p-4"
                    id="line-chart"
                >
                    <div
                        *ngIf="
                            downloadsOverTimeLineChartData[0]['series']
                                ?.length &&
                            downloadsOverTimeLineChartData[1]['series']?.length
                        "
                    >
                        <ngx-charts-line-chart
                            [view]="lineChartOptions.view"
                            [scheme]="lineChartOptions.colorScheme"
                            [results]="downloadsOverTimeLineChartData"
                            [xAxis]="lineChartOptions.xAxis"
                            [animations]="true"
                            [autoScale]="false"
                            [yAxis]="lineChartOptions.yAxis"
                            [timeline]="lineChartOptions.timeline"
                        >
                            <ng-template #tooltipTemplate let-model="model">
                                <div
                                    *ngIf="model"
                                    class="d-flex align-content-center justify-content-between flex-wrap font-size-13 tooltip-blue"
                                >
                                    {{ model.name }}: contact saved
                                    {{ model.value | number }}
                                </div>
                            </ng-template>
                            <ng-template
                                #seriesTooltipTemplate
                                let-model="model"
                            >
                                <div class="font-size-13">
                                    <div
                                        *ngIf="model[0]"
                                        class="d-flex align-content-center justify-content-between flex-wrap my-auto tooltip-blue"
                                    >
                                        <span class="text-primary">
                                            {{ model[0].name }}:
                                        </span>
                                        <span
                                            *ngIf="
                                                model[1] &&
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                ) >= 0
                                            "
                                            class="pl-2 text-success"
                                        >
                                            +{{
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                )
                                            }}%
                                        </span>
                                        <span
                                            *ngIf="
                                                model[1] &&
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                ) < 0
                                            "
                                            class="pl-2 text-danger"
                                        >
                                            {{
                                                getPercentChange(
                                                    model[0].value,
                                                    model[1].value
                                                )
                                            }}%
                                        </span>
                                        <span class="pl-4 text-primary">{{
                                            model[0].value | number
                                        }}</span>
                                    </div>
                                </div>

                                <div
                                    class="font-size-13"
                                    style="border-top: 1px solid #63c2de"
                                >
                                    <div
                                        *ngIf="model[1]"
                                        class="d-flex align-content-center justify-content-between flex-wrap my-auto text-primary"
                                    >
                                        <span
                                            *ngIf="dayDifference > 1"
                                            class="text-success"
                                        >
                                            {{ getDate(model[1].name) }}:
                                        </span>
                                        <span
                                            *ngIf="dayDifference <= 1"
                                            class="text-success"
                                        >
                                            {{ model[0].name }}:
                                        </span>
                                        <span class="ml-auto text-success">{{
                                            model[1].value | number
                                        }}</span>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-charts-line-chart>
                    </div>
                </div>
                <div
                    *ngIf="downloadsActiveChartType === 'bar'"
                    class="bx--col-lg-12 p-4"
                    id="line-chart"
                >
                    <div *ngIf="downloadsBarChartData.length">
                        <ngx-charts-bar-vertical-2d
                            [view]="lineChartOptions.view"
                            [scheme]="lineChartOptions.colorScheme"
                            [results]="downloadsBarChartData"
                            [xAxis]="lineChartOptions.xAxis"
                            [yAxis]="lineChartOptions.yAxis"
                        >
                            <ng-template #tooltipTemplate let-model="model">
                                <div
                                    *ngIf="model"
                                    class="d-flex align-content-center justify-content-between flex-wrap font-size-13 tooltip-blue"
                                >
                                    {{ model.name }} contact saved :
                                    {{ model.value | number }}
                                </div>
                            </ng-template>
                        </ngx-charts-bar-vertical-2d>
                    </div>
                </div>

                <div class="bx--col-lg-4 p-4">
                    <div class="bx--row">
                        <div
                            class="bx--col p-0 bx--md-down-px-4"
                            id="downloads-section"
                        >
                            <div class="bx--row">
                                <div class="bx--col">
                                    <span
                                        class="caption text-uppercase"
                                        style="color: #979797"
                                        >Contact saves</span
                                    >
                                </div>
                            </div>
                            <div class="bx--row mt-2">
                                <div class="bx--col">
                                    <span class="analytics-count">{{
                                        (
                                            totalDownloadsCount || 0
                                        ).toLocaleString()
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="bx--col p-0 bx--md-down-px-4">
                            <span
                                class="pr-3 float-right"
                                id="downloads-section-tag"
                                [ngStyle]="{ 'margin-top': marginTopDownloads }"
                            >
                                <beaconstac-tag
                                    [tagTitle]="
                                        (impressionsComparisonPercentage ||
                                            0) >= 0
                                            ? upArrowIcon +
                                              (impressionsComparisonPercentage ||
                                                  0) +
                                              '%'
                                            : downArrowIcon +
                                              (impressionsComparisonPercentage ||
                                                  0) +
                                              '%'
                                    "
                                    [bgColor]="
                                        (impressionsComparisonPercentage ||
                                            0) >= 0
                                            ? '#E2F8EC'
                                            : '#FFEFEF'
                                    "
                                    [textColor]="
                                        (impressionsComparisonPercentage ||
                                            0) >= 0
                                            ? '#26B969'
                                            : '#FF445E'
                                    "
                                >
                                </beaconstac-tag>
                            </span>
                        </div>
                    </div>
                    <div class="bx--row mt-4">
                        <div
                            class="bx--col p-0 bx--md-down-px-4"
                            id="unique-downloads-period"
                        >
                            <div class="bx--row">
                                <div class="bx--col">
                                    <span
                                        class="caption text-uppercase"
                                        style="color: #979797"
                                        >previous period</span
                                    >
                                </div>
                            </div>
                            <div class="bx--row mt-2">
                                <div class="bx--col">
                                    <span class="analytics-count">{{
                                        (
                                            totalDownloadsCountPrevious || 0
                                        ).toLocaleString()
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="bx--col p-0 bx--md-down-px-4">
                            <span
                                class="pr-3 float-right"
                                id="unique-downloads-period-tag"
                                [ngStyle]="{
                                    'margin-top': marginTopPreviousDownloads
                                }"
                            >
                                <beaconstac-tag
                                    [tagTitle]="
                                        (usersComparisonPercentage || 0) >= 0
                                            ? upArrowIcon +
                                              (usersComparisonPercentage || 0) +
                                              '%'
                                            : downArrowIcon +
                                              (usersComparisonPercentage || 0) +
                                              '%'
                                    "
                                    [bgColor]="
                                        (usersComparisonPercentage || 0) >= 0
                                            ? '#E2F8EC'
                                            : '#FFEFEF'
                                    "
                                    [textColor]="
                                        (usersComparisonPercentage || 0) >= 0
                                            ? '#26B969'
                                            : '#FF445E'
                                    "
                                >
                                </beaconstac-tag>
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Downloads End -->

    <!-- Users Start -->
    <div class="bx--row my-3 mx-0 bg-white shadow-sm border-radius-2px">
        <div class="bx--col">
            <div class="bx--row">
                <div class="bx--col-lg-12 p-4">
                    <div class="bx--row">
                        <div class="bx--col analytics-card-header">
                            Unique card views
                        </div>
                    </div>
                </div>
                <div class="bx--col-lg-4 p-4">
                    <div class="float-right d-flex pt-2 align-items-center">
                        <div class="ml-1">
                            <i
                                class="fas fa-chart-bar border border-radius-2 chart-container-border-right py-2 px-3 cursor-pointer"
                                aria-hidden="true"
                                [ngClass]="
                                    usersActiveChartType === 'bar'
                                        ? 'border-primary text-primary'
                                        : 'border-secondary text-secondary'
                                "
                                (click)="usersActiveChartType = 'bar'"
                            ></i>
                        </div>
                        <div>
                            <i
                                class="fas fa-chart-line border border-radius-2 chart-container-border-left py-2 px-3 cursor-pointer"
                                aria-hidden="true"
                                [ngClass]="
                                    usersActiveChartType === 'line'
                                        ? 'border-primary text-primary'
                                        : 'border-secondary text-secondary'
                                "
                                (click)="usersActiveChartType = 'line'"
                            ></i>
                        </div>
                        <beaconstac-button
                            class="ml-4"
                            *ngIf="hasExportAnalyticsPermission"
                            [useCustomImage]="true"
                            [style]="BUTTON_STYLES.TEXT"
                            [iconSize]="ICON_SIZE.LARGE"
                            [icon]="'../../../assets/img/export-filled.svg'"
                            [label]="'Export'"
                            (onClick)="downloadUsersCSV()"
                        >
                        </beaconstac-button>
                    </div>
                </div>
            </div>
            <div *ngIf="isFetchingViews" class="spinner-container-analytics-v2">
                <spinner
                    class="custom-spinner-analytics"
                    [size]="40"
                    [tickness]="2"
                ></spinner>
            </div>
            <div *ngIf="viewsError" class="spinner-container-analytics-v2">
                {{ viewsError }}
            </div>
            <div *ngIf="!isFetchingViews && !viewsError" class="bx--row">
                <div
                    *ngIf="usersActiveChartType === 'line'"
                    class="bx--col-lg-12 p-4"
                >
                    <div
                        *ngIf="
                            uniqueVisitsOverTimeLineChartData[0]['series']
                                ?.length &&
                            uniqueVisitsOverTimeLineChartData[1]['series']
                                ?.length
                        "
                    >
                        <ngx-charts-line-chart
                            [view]="lineChartOptions.view"
                            [scheme]="lineChartOptions.colorScheme"
                            [results]="uniqueVisitsOverTimeLineChartData"
                            [animations]="true"
                            [autoScale]="false"
                            [xAxis]="lineChartOptions.xAxis"
                            [yAxis]="lineChartOptions.yAxis"
                            [timeline]="lineChartOptions.timeline"
                        >
                        </ngx-charts-line-chart>
                    </div>
                </div>
                <div
                    *ngIf="usersActiveChartType === 'bar'"
                    class="bx--col-lg-12 p-4"
                >
                    <div *ngIf="uniqueVisitsOverTimeBarChartData?.length">
                        <ngx-charts-bar-vertical-2d
                            [view]="lineChartOptions.view"
                            [scheme]="lineChartOptions.colorScheme"
                            [results]="uniqueVisitsOverTimeBarChartData"
                            [xAxis]="lineChartOptions.xAxis"
                            [yAxis]="lineChartOptions.yAxis"
                        >
                            <ng-template #tooltipTemplate let-model="model">
                                <div
                                    *ngIf="model"
                                    class="d-flex align-content-center justify-content-between flex-wrap font-size-13 tooltip-blue"
                                >
                                    {{ model.name }} unique card views :
                                    {{ model.value | number }}
                                </div>
                            </ng-template>
                        </ngx-charts-bar-vertical-2d>
                    </div>
                </div>
                <div class="bx--col-lg-4 p-4">
                    <div class="bx--row">
                        <div class="bx--col p-0 bx--md-down-px-4">
                            <div class="bx--row">
                                <div
                                    class="bx--col caption text-uppercase"
                                    style="color: #979797"
                                >
                                    Unique card views
                                </div>
                            </div>
                            <div class="bx--row mt-2">
                                <div class="bx--col analytics-count">
                                    {{ presentUniqueUsers || 0 }}
                                </div>
                            </div>
                        </div>
                        <div class="bx--col p-0 bx--md-down-px-4">
                            <span
                                class="pr-3 float-right"
                                [ngStyle]="{ 'margin-top': marginTopViews }"
                            >
                                <beaconstac-tag
                                    [tagTitle]="
                                        (totalChangeInUsers || 0) >= 0
                                            ? upArrowIcon +
                                              (
                                                  totalChangeInUsers || 0
                                              )?.toFixed(2) +
                                              '%'
                                            : downArrowIcon +
                                              (
                                                  totalChangeInUsers || 0
                                              )?.toFixed(2) +
                                              '%'
                                    "
                                    [bgColor]="
                                        (totalChangeInUsers || 0) >= 0
                                            ? '#E2F8EC'
                                            : '#FFEFEF'
                                    "
                                    [textColor]="
                                        (totalChangeInUsers || 0) >= 0
                                            ? '#26B969'
                                            : '#FF445E'
                                    "
                                >
                                </beaconstac-tag>
                            </span>
                        </div>
                    </div>
                    <div class="bx--row mt-4">
                        <div class="bx--col p-0 bx--md-down-px-4">
                            <div class="bx--row">
                                <div
                                    class="bx--col caption text-uppercase"
                                    style="color: #979797"
                                >
                                    previous period
                                </div>
                            </div>
                            <div class="bx--row mt-2">
                                <div class="bx--col analytics-count">
                                    {{ pastUniqueUsers || 0 }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Users End -->

    <!-- Views by OS & Scans Over Time Start -->
    <div class="bx--row my-3 mx-0">
        <div class="bx--col-lg-8 bx--col-md-8">
            <div
                [ngClass]="!isMobile && 'pr-2'"
                class="white-background bx--row border-radius-2px"
            >
                <div class="bx--col-lg-12 p-4">
                    <div class="bx--row">
                        <div class="bx--col analytics-card-header">
                            Card views by device
                        </div>
                    </div>
                </div>
                <div class="bx--col-lg-4 p-4">
                    <div
                        class="float-right pt-2"
                        *ngIf="hasExportAnalyticsPermission"
                    >
                        <beaconstac-button
                            [useCustomImage]="true"
                            [style]="BUTTON_STYLES.TEXT"
                            [iconSize]="ICON_SIZE.LARGE"
                            [icon]="'../../../assets/img/export-filled.svg'"
                            [label]="'Export'"
                            (onClick)="downloadOSCSV()"
                        >
                        </beaconstac-button>
                    </div>
                </div>
            </div>
            <div
                class="bx--row white-background"
                [ngClass]="!isMobile && 'pr-2'"
            >
                <div class="bx--col">
                    <div
                        *ngIf="isFetchingOs"
                        class="spinner-container-analytics-v2 white-background"
                    >
                        <spinner
                            class="custom-spinner-analytics"
                            [size]="40"
                            [tickness]="2"
                        ></spinner>
                    </div>
                </div>
            </div>
            <div
                class="bx--row white-background"
                [ngClass]="!isMobile && 'pr-2'"
            >
                <div class="bx--col">
                    <div
                        *ngIf="osDetailsError"
                        class="spinner-container-analytics-v2 white-background"
                    >
                        {{ osDetailsError }}
                    </div>
                </div>
            </div>
            <div
                *ngIf="!isFetchingOs && !osDetailsError"
                [ngClass]="!isMobile && 'pr-2'"
                class="white-background bx--row"
            >
                <div class="bx--col py-4" id="devices-chart">
                    <ngx-charts-pie-chart
                        [view]="deviceChartOptions.view"
                        [scheme]="deviceChartOptions.colorScheme"
                        [results]="osDistributionData"
                        [doughnut]="true"
                    >
                    </ngx-charts-pie-chart>
                </div>
            </div>
            <div
                *ngIf="!isFetchingOs && !osDetailsError"
                [ngClass]="!isMobile && 'pr-2'"
                class="white-background bx--row"
                style="min-height: 250px"
            >
                <div class="bx--col pb-4">
                    <div
                        class="bx--row px-2 py-3 text-secondary font-size-16 device-table-head"
                    >
                        <div
                            class="bx--col-lg-8 bx--col-md-4 bx--col-sm-2 device-table-heading"
                        >
                            DEVICE OS
                        </div>
                        <div
                            class="bx--col-lg-4 bx--col-md-2 bx--col-sm-2 device-table-heading"
                        >
                            CARD VIEWS
                        </div>
                        <div
                            class="bx--col-lg-4 bx--col-md-2 bx--col-sm-0 device-table-heading text-right"
                        >
                            <span>%</span>
                        </div>
                    </div>

                    <div
                        *ngFor="let os of osDistributionData | sliceValues : 4"
                        class="bx--row px-2 mt-3"
                    >
                        <div class="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
                            <span
                                class="border-radius-2px"
                                [ngStyle]="{ 'background-color': os.color }"
                                >&nbsp;&nbsp;&nbsp;&nbsp;</span
                            >
                            <span
                                class="ml-3 font-weight-normal"
                                style="
                                    font-style: normal;
                                    font-size: 16px;
                                    line-height: 19px;
                                "
                            >
                                &nbsp;&nbsp;{{ os.name }}
                            </span>
                        </div>
                        <div
                            class="bx--col-lg-4 bx--col-md-2 bx--col-sm-2 text-primary"
                        >
                            &nbsp;{{ os.value }}
                        </div>
                        <div
                            style="
                                font-style: normal;
                                font-size: 16px;
                                line-height: 19px;
                                color: #5e6980;
                            "
                            class="bx--col-lg-4 bx--col-md-2 bx--col-sm-0 text-right font-weight-normal"
                        >
                            &nbsp;&nbsp;{{ os.percentage }}%
                        </div>
                    </div>

                    <div
                        *ngIf="osDistributionData.length > 4"
                        class="bx--row text-center text-primary mt-4"
                    >
                        <div class="bx--col">
                            <span
                                class="cursor-pointer"
                                (click)="osDistributionModal.show()"
                                >VIEW MORE</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bx--col-lg-8 bx--col-md-8">
            <div
                [ngClass]="isMobile ? 'mt-3' : 'pl-2'"
                class="white-background bx--row border-radius-2px"
            >
                <div class="bx--col p-4">
                    <div class="bx--row">
                        <div class="bx--col analytics-card-header">
                            Card views by time
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="bx--row white-background"
                [ngClass]="!isMobile && 'pl-2'"
            >
                <div class="bx--col">
                    <div
                        *ngIf="isFetchingTimeByWeek"
                        class="spinner-container-analytics-v2 white-background"
                    >
                        <spinner
                            class="custom-spinner-analytics"
                            [size]="40"
                            [tickness]="2"
                        ></spinner>
                    </div>
                </div>
            </div>
            <div
                class="bx--row white-background"
                [ngClass]="!isMobile && 'pl-2'"
            >
                <div class="bx--col">
                    <div
                        *ngIf="timeByWeekError"
                        class="spinner-container-analytics-v2 white-background"
                    >
                        {{ timeByWeekError }}
                    </div>
                </div>
            </div>
            <div
                *ngIf="!isFetchingTimeByWeek && !timeByWeekError"
                [ngClass]="!isMobile && 'pl-2'"
                class="white-background bx--row"
            >
                <div class="bx--col pb-4" id="time-of-the-week">
                    <ngx-charts-heat-map
                        [view]="userTimeOfWeekChartOptions.view"
                        [scheme]="userTimeOfWeekChartOptions.colorScheme"
                        [legend]="userTimeOfWeekChartOptions.legend"
                        [xAxis]="userTimeOfWeekChartOptions.xAxis"
                        [yAxis]="userTimeOfWeekChartOptions.yAxis"
                        [results]="userTimeOfWeekData"
                        [innerPadding]="2"
                    >
                    </ngx-charts-heat-map>
                </div>
            </div>
        </div>
    </div>
    <!-- Views by OS & Scans Over Time End -->

    <!-- Views by City Start-->
    <div class="bx--row my-3 mx-0 bg-white shadow-sm border-radius-2px">
        <div class="bx--col">
            <div class="bx--row">
                <div class="bx--col-lg-12 p-4">
                    <div class="bx--row">
                        <div class="bx--col analytics-card-header">
                            Card views by city
                        </div>
                    </div>
                    <div class="bx--row mt-2">
                        <div class="bx--col"></div>
                    </div>
                </div>
                <div
                    class="bx--col-lg-4 p-4"
                    *ngIf="hasExportAnalyticsPermission"
                >
                    <div class="float-right pt-2">
                        <beaconstac-button
                            [useCustomImage]="true"
                            [style]="BUTTON_STYLES.TEXT"
                            [iconSize]="ICON_SIZE.LARGE"
                            [icon]="'../../../assets/img/export-filled.svg'"
                            [label]="'Export'"
                            (onClick)="downloadCityCSV()"
                        >
                        </beaconstac-button>
                    </div>
                </div>
            </div>
            <div
                *ngIf="isFetchingViewsByCity"
                class="spinner-container-analytics-v2"
            >
                <spinner
                    class="custom-spinner-analytics"
                    [size]="40"
                    [tickness]="2"
                ></spinner>
            </div>
            <div
                *ngIf="viewsByCityError"
                class="spinner-container-analytics-v2"
            >
                {{ viewsByCityError }}
            </div>
            <div
                *ngIf="
                    !isFetchingViewsByCity &&
                    !viewsByCityError &&
                    citiesOriginalData.data.length === 0
                "
                class="spinner-container-analytics-v2"
            >
                No data to show
            </div>
            <div
                *ngIf="
                    !isFetchingViewsByCity &&
                    !viewsByCityError &&
                    citiesOriginalData.data.length
                "
                class="bx--row"
            >
                <div class="bx--col px-0">
                    <beaconstac-table
                        *ngIf="citiesOriginalData.data.length"
                        [currentPage]="currentPage"
                        [tableData]="citiesData"
                        [pages]="pages"
                        [showPages]="totalResults > pageSize"
                        [pagination]="pagination"
                        (onShowPagesChange)="changePageSize($event)"
                        (onPageChange)="onPageChange($event)"
                        [showToolBar]="isMobile"
                        [isAscending]="sortType.isAscending === 'ascending'"
                        (sortColumnChange)="sortTable($event)"
                        (onToolbarClick)="handleToolbarClick($event)"
                        [totalResults]="totalResults"
                    ></beaconstac-table>
                </div>
            </div>
        </div>
    </div>
    <!-- Views By city End -->

    <!-- Views by location Start -->
    <div class="bx--row my-3 mx-0 bg-white shadow-sm border-radius-2px">
        <div class="bx--col">
            <div class="bx--row">
                <div class="bx--col-lg-6 p-4">
                    <div class="bx--row">
                        <div class="bx--col analytics-card-header">
                            Card views by location
                        </div>
                    </div>
                </div>
                <div class="bx--col-lg-10 p-4">
                    <div class="d-flex justify-content-end">
                        <span>
                            <img
                                class="map-icon ml-auto"
                                src="../../../assets/img/map-marker/ip.png"
                            />
                            IP
                            <beaconstac-tooltip
                                elementClass="fas fa-info-circle cursor-pointer ml-1"
                                [content]="
                                    'Approximate location of visitor scanning the QR code based on anonymized IP Address of device'
                                "
                                [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                [caretPosition]="CARET_POSITION.CENTER"
                                color=""
                            >
                            </beaconstac-tooltip>
                        </span>

                        <span class="ml-4">
                            <img
                                class="map-icon"
                                src="../../../assets/img/map-marker/m2.png"
                            />
                            IP Cluster
                        </span>

                        <span class="ml-4">
                            <img
                                class="map-icon"
                                src="../../../assets/img/map-marker/gps.png"
                            />
                            GPS
                            <beaconstac-tooltip
                                elementClass="fas fa-info-circle cursor-pointer ml-1"
                                [content]="
                                    'Exact geo-location of visitor scanning your QR code'
                                "
                                [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                [caretPosition]="CARET_POSITION.CENTER"
                                color=""
                            >
                            </beaconstac-tooltip>
                        </span>

                        <span class="ml-4">
                            <img
                                class="map-icon"
                                src="../../../assets/img/map-marker/m1.png"
                            />
                            GPS Cluster
                        </span>
                    </div>
                </div>
            </div>
            <div
                *ngIf="isFetchingLocationDetails"
                class="spinner-container-analytics-v2"
            >
                <spinner
                    class="custom-spinner-analytics"
                    [size]="40"
                    [tickness]="2"
                ></spinner>
            </div>
            <div
                *ngIf="locationDetailsError"
                class="spinner-container-analytics-v2"
            >
                {{ locationDetailsError }}
            </div>
            <div
                *ngIf="!isFetchingLocationDetails && !locationDetailsError"
                class="bx--row"
            >
                <div class="bx--col pb-4 px-4" *ngIf="locationData[0]">
                    <app-map
                        [center]="center"
                        [markerOptions]="markerOptions"
                        [agmClusterStyleGPS]="agmClusterStyleGPS"
                        [agmClusterStyleIP]="agmClusterStyleIP"
                        [gpsLocationData]="gpsLocationData"
                        [ipLocationData]="ipLocationData"
                        [addMarkerCluster]="addMarkerCluster"
                    >
                    </app-map>
                </div>
            </div>
        </div>
    </div>
    <!-- Views by location End -->
</div>

<div bsModal
    #osDistributionModal
    class="modal fade modal city-mfodal"
    role="dialog"
>
    <div class="modal-dialog modal-secondary modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Card views by device</h5>
                <button
                    type="button"
                    class="close"
                    (click)="osDistributionModal.hide()"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div
                    class="row px-5 pb-2 text-secondary font-size-16 border-bottom"
                >
                    <div class="col-6 px-0">DEVICE USED</div>
                    <div class="col-4 px-0">CARD VIEWS</div>
                    <div class="col-2 px-0 px-md-3">&nbsp;&nbsp;%</div>
                </div>
                <table class="table table-city-distribution">
                    <div
                        *ngFor="let os of osDistributionData"
                        class="py-3 px-5 row"
                    >
                        <div class="col-6">
                            <span
                                class="border-radius-2 m-1"
                                [ngStyle]="{ 'background-color': os.color }"
                                >&nbsp;&nbsp;&nbsp;&nbsp;</span
                            >
                            {{ os.name }}
                        </div>
                        <div class="col-4 text-primary">
                            &nbsp;{{ os.value }}
                        </div>
                        <div class="col-2">{{ os.percentage }}%</div>
                    </div>
                </table>
            </div>
        </div>
    </div>
</div>

<div bsModal
    #mobileOptions
    class="modal fade"
    role="dialog"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="min-height: 500px">
            <div class="modal-header">
                <h5 class="modal-title">Card views by city</h5>
                <button
                    type="button"
                    class="close"
                    (click)="mobileOptions.hide()"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div *ngIf="scanByCityModalData?.city" class="modal-body p-4">
                <div class="font-weight-bold">DETAILS</div>
                <div class="py-2 font-weight-bold text-muted">
                    {{ TableColumns.CITY }} :
                    <span class="text-muted font-weight-normal">{{
                        scanByCityModalData.city.value.text
                    }}</span>
                </div>
                <div class="py-2 font-weight-bold text-muted">
                    {{ TableColumns.STATE }} :
                    <span class="text-muted font-weight-normal">{{
                        scanByCityModalData.state?.value.text
                    }}</span>
                </div>
                <div class="py-2 font-weight-bold text-muted">
                    {{ TableColumns.COUNTRY }} :
                    <span class="text-muted font-weight-normal">{{
                        scanByCityModalData.country?.value.text
                    }}</span>
                </div>
                <div class="py-2 font-weight-bold text-muted">
                    {{ TableColumns.VIEWS }} :
                    <span class="text-muted font-weight-normal">{{
                        scanByCityModalData.views?.value?.text
                    }}</span>
                </div>
                <div class="py-2 font-weight-bold text-muted">
                    {{ TableColumns.DOWNLOADS }} :
                    <span class="text-muted font-weight-normal">{{
                        scanByCityModalData.downloads?.value?.text
                    }}</span>
                </div>
            </div>
            <div class="modal-footer">
                <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="mobileOptions.hide()"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
