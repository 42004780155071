import { NgModule } from '@angular/core';
import { FeatureDetailsComponent, IsOnHigherPlan } from './feature-details.component';
import { BeaconstacModule } from 'kaizen-design-system';
import { CommonModule } from '@angular/common';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import {TooltipModule} from 'ngx-bootstrap/tooltip';



@NgModule({
    declarations: [
        FeatureDetailsComponent,
        IsOnHigherPlan,
    ],
    exports: [
        FeatureDetailsComponent
    ],
    imports: [
        CommonModule,
        BeaconstacModule,
        CollapseModule.forRoot(),
        TooltipModule.forRoot()
    ]
})
export class FeatureDetailsModule { }
