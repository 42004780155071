// data for qr pricing details
export const qrPricingData = [
    {
        planName: 'STARTER',
        planDesc: 'For individuals just getting started with QR Codes',
        planPrice: 5,
        planNoOfDynamicQRCode: '3',
        planUserSeat: '1',
        planIncludes: ['Live tech support', '1 Linkpage', 'Scan analytics'],
        planDoesNotIncludes: ['GPS tracking', 'Bulk creation']
    },
    {
        planName: 'LITE',
        planDesc: 'For small businesses looking to reach more consumers',
        planPrice: 15,
        planNoOfDynamicQRCode: '50',
        planUserSeat: '1',
        planIncludes: ['All STARTER features', '5 Linkpages', 'GPS tracking', 'Bulk creation'],
    },
    {
        planName: 'PRO',
        planDesc: 'For mid-sized businesses with advanced QR Code needs',
        planPrice: 49,
        planNoOfDynamicQRCode: '250',
        planUserSeat: '1',
        planIncludes: ['All LITE features', '10 Linkpages', 'Integrations', 'QR Code API'],
    },
    {
        planName: 'PLUS',
        planDesc: 'For organisations or agencies to manage multiple teams or clients',
        planPrice: 99,
        planNoOfDynamicQRCode: '500',
        planUserSeat: 'unrated',
        planIncludes: ['All PRO features', '25 Linkpages', 'Multi-user access', 'Add-on 50 QR Codes at $6.25/mo', 'Add-on user seats at $5/mo', 'Add-on custom domain at $166.6/month'],
    },
    {
        planName: 'BUSINESS+',
        planDesc: 'For global leaders with security, control, and flexibility needs',
        planPrice: 'Talk to us',
        planNoOfDynamicQRCode: 'unrateed',
        planUserSeat: 'unrated',
        planIncludes: ['All PLUS features', 'Single sign-on (SSO)', 'Custom domain', 'Retargeting', 'White-label', 'Pay by invoice', 'Customer Success Manager'],
    },
]
