import {Injectable} from '@angular/core';
import {Utils} from '../../shared/utils';

@Injectable()
export class LocationService {

    listOfCountries: object = {}


    constructor() {
        for (const country of Utils.countries) {
            this.listOfCountries[country.name] = country.code
        }
    }

    getCountryCode(country: string): string {
        return this.listOfCountries[country];
    }

    getAllCountries(): Array<string> {
        const allCountries = [];
        for (const country in this.listOfCountries) {
            allCountries.push(country);
        }
        return allCountries;
    }

    getCountriesByCodes(codes: Array<string>): Array<string> {
        const countries = [];
        for (const langCode in this.listOfCountries) {
            for (const code of codes) {
                if (code === this.listOfCountries[langCode]) {
                    countries.push(langCode)
                }
            }
        }
        return countries;
    }

    getCountryByCode(code: string): string {
        for (const langCode in this.listOfCountries) {
            if (code === this.listOfCountries[langCode]) {
                return langCode;
            }
        }
    }

}
