import { BaseBackendService } from '../global-services/base-backend.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../global-services/auth.service';
import { MessageModalService } from '../shared/message-modal/message-modal.service';
import { CustomDomain } from './custom-domain.model';
import { Injectable } from '@angular/core';
import { PRODUCT_TYPES } from '../shared/utils';

@Injectable()
export class CustomDomainService extends BaseBackendService<CustomDomain> {
    constructor(protected http: HttpClient, protected authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, CustomDomain, 'customdomains', messageModal);
    }

    verifyCNAME(domain: CustomDomain, product_type: PRODUCT_TYPES) {
        return super.getDetail(domain.id, `verify/?product_type=${product_type}`);
    }

    requestSSL(domain: CustomDomain, product_type: PRODUCT_TYPES, cloudfront: boolean = true) {
        const url = `ssl/?product_type=${product_type}${cloudfront ? '&cloudfront=true' : ''}`;
        return super.getDetail(domain.id, url);
    }

    getAttachedCustomDomainCount(customDomainId: number) {
        return super.getData(customDomainId, 'product_count/?product_type=all');
    }
}
