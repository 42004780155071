<div class="bx--grid stepper-app-body">
    <div class="bx--row p-0">

        <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md stepper cursor-pointer"
             (click)="goToNext(1)"
             [ngClass]="selectedStep === 1 && 'active'"
        >
            <div class="stepper-count">Step 1</div>
            <div class="text-uppercase mt-2"
                 [ngClass]="selectedStep === 1 ? 'stepper-active-heading' : 'stepper-heading'">
                Build
            </div>
        </div>
        <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
             (click)="goToNext(1)"
             [ngClass]="selectedStep === 1 ? 'active mobile-stepper-active' : ''"
        >
            <div class="stepper-count mt-5px">Step 1</div>
        </div>

        <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md stepper cursor-pointer"
             (click)="goToNext(2)"
             [ngClass]="selectedStep === 2 ? 'active' : ''"
        >
            <div class="stepper-count">Step 2</div>
            <div class="text-uppercase mt-2"
                 [ngClass]="selectedStep === 2 ? 'stepper-active-heading' : 'stepper-heading'">
                Customize
            </div>

        </div>
        <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
             (click)="goToNext(2)"
             [ngClass]="selectedStep === 2 ? 'active mobile-stepper-active' : ''"
        >
            <div class="stepper-count mt-5px">Step 2</div>
        </div>

        <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md stepper cursor-pointer"
             (click)="goToNext(3)"
             [ngClass]="selectedStep === 3 && 'active'"
        >
            <div class="stepper-count">Step 3</div>
            <div class="text-uppercase mt-2"
                 [ngClass]="selectedStep === 3 ? 'stepper-active-heading' : 'stepper-heading'">
                Set-up
            </div>
        </div>
        <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
             (click)="goToNext(3)"
             [ngClass]="selectedStep === 3 && 'active mobile-stepper-active'"
        >
            <div class="stepper-count mt-5px">Step 3</div>
        </div>

        <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" style="max-width: 15%;" (click)="showPreview()" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]">
            <i class="stepper-count fa fa-eye mobile-stepper-active"></i>
        </div>

        <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper border-right-0" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
             (click)="pressBack()"
             style="max-width: 15%;"
             [ngClass]="selectedStep === 1 ? 'back-button-disabled' : 'back-button'">
            <i class="text-custom-gray custom-fs-24 fas fa-angle-left"></i>
        </div>
        <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper border-right-0" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
             [ngClass]="disableNextButton ? 'next-button-disable' : 'next-button-enable cursor-next'"
             style="max-width: 15%;"
             (click)="goToNext(selectedStep + 1)">
            <i class="text-white custom-fs-24 fas fa-angle-double-right"></i>
        </div>

        <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md-8 bx--col-sm-0">
            <div class="bx--row">
                <div
                    class="bx--col-xlg-6 bx--col-lg-8 bx--col-md-4 bx--col-sm-2 stepper bg-secondary text-center cursor-pointer border-right-0"
                    [ngClass]="selectedStep === 1 ? 'back-button-disabled' : 'back-button'"
                    (click)="pressBack()"
                >
                    <div class="text-gray" style="margin-top: 9px;">BACK</div>
                </div>
                <div
                    (click)="goToNext(selectedStep + 1)"
                    class="bx--col-xlg-10 bx--col-lg-8 bx--col-md-4 bx--col-sm-2 stepper text-center p-3 border-right-0"
                    [ngClass]="disableNextButton ? 'next-button-disable' : 'next-button-enable cursor-next'"
                >
                    <div class="font-weight-600 text-white" style="margin-top: 9px"  [ngClass]="disableNextButton ? 'next-button-disable' : 'next-button-enable cursor-next'">
                        {{ selectedStep === 3 ? 'FINISH' : 'NEXT' }}
                        <i style="font-size: 16px;" class="fa fa-angle-right ml-2 font-weight-600"></i>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div *ngIf="launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER] && isMobileScreen" class="stepper-app-body px-0">
    <beaconstac-new-dbc-stepper
        [goToNext]="goToNext"
        [selectedStep]="selectedStep">
    </beaconstac-new-dbc-stepper>
</div>

<div class="bx--grid mx-3 mx-md-0" style="max-width: -webkit-fill-available;">
    <div class="bx--row my-4">
        <div style="background-color: white"
             [ngClass]="'bx--col pb-4'"
             [ngSwitch]="selectedStep">
            <div class="bx--row" [@collapse] *ngSwitchCase="1">
                <div class="bx--col">
                    <div class="mt-4 pl-2">
                        <p class="mb-2 font-weight-400" style="font-size: 20px;">Upload Card Details</p>
                        <app-digital-business-card-templates-dropdown
                            (onTemplateSelectChange)="onTemplateSelect($event)"
                            [selectedTemplate]="selectedCardTemplate"
                            [showNoneOption]="false"
                        ></app-digital-business-card-templates-dropdown>
                        <div class="mt-4 mb-4 d-flex flex-column bulk-dbc-container">
                            <span class="bulk-message d-flex flex-row mb-2">
                                <i class="fas fa-info-circle"></i>
                                <span class="pl-2">We recommend using our data templates for accurate results</span>
                            </span>
                            <span class="template-download">
                                <b>
                                    <a class="bulk-template-link cursor-pointer" (click)="downloadTemplates($event, 1)" target="_blank"
                                       style="padding-top: 8px;" download> Download CSV template</a>
                                </b>
                            </span>
                            <span class="template-download mt-2 mb-4 pb-2">
                                <b>
                                    <a class="bulk-template-link cursor-pointer" (click)="downloadTemplates($event, 2)" target="_blank"
                                       style="padding-top: 8px;" download> Download XLSX
                                    template</a>
                                </b>
                          </span>
                        </div>
                    </div>
                    <div *ngIf="invalidFileError !== ''" class="mt-3 mb-4 bulkqr-csv-message bulkqr-csv-message-danger"
                         style="max-width: 400px">
                        <b style="color: #dc3444"><i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                            {{invalidFileError}}</b>
                        <br>
                    </div>
                    <p class="mb-2 pl-2 upload-headline">Upload your data</p>
                    <div class="mt-3 custom-upload pl-2">
                        <beaconstac-upload #csvDrop (fileChange)="onDataFileAdded($event)"
                                           [acceptTypes]="['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
                                           [maxSize]="5e+6" [custom]="'Supported extensions: .csv, .xlsx.'"
                                           [disabled]="!hasWriteAccess" (onFileRemove)="resetFilePicker()">
                        </beaconstac-upload>
                    </div>
                    <div class="mt-4 pl-2" *ngIf="errorRows.length > 0">
                        <app-bulk-csv-validation (dataChange)="onCSVChanged($event)" [csvData]="csvData"
                                                 [errorObject]="csvValidationResponse"></app-bulk-csv-validation>
                        <div class="mt-4">
                            <beaconstac-button
                                [type]="BUTTON_TYPES.PRIMARY"
                                [style]="BUTTON_STYLES.FILLED"
                                [label]="'REVALIDATE'"
                                [icon]="'fas fa-sync-alt'"
                                (onClick)="validateCSV()"></beaconstac-button>
                        </div>
                    </div>
                </div>
            </div>
            <div [@collapse] *ngSwitchCase="2" style="padding: 22px 0px 22px 10px">
                <app-digital-business-card-bulk-design [dbcBulk]="dbcBulk" [cardSettings]="cardSettings"
                                                       [orgCardSettings]="cardOrgSettings" [launchDarkleyFlags]="launchDarkleyFlags"
                ></app-digital-business-card-bulk-design>
            </div>
            <div [@collapse] *ngSwitchCase="3" style="padding: 22px 15px">
                <app-digital-business-card-bulk-launch [dbcBulk]="dbcBulk" [cardSettings]="cardSettings"
                                                       [orgCardSettings]="cardOrgSettings" [launchDarkleyFlags]="launchDarkleyFlags"
                ></app-digital-business-card-bulk-launch>
            </div>
        </div>
    </div>
</div>
<app-bottom-sticky-buttons *ngIf="handleBottomStickyButtons()"
                           [primaryCtaName]="getPrimaryCtaName()"
                           [secondaryCtaName]="'Back'"
                           [primaryCtaAction]="goToNext.bind(this, selectedStep + 1)"
                           [secondaryCtaAction]="getSecondaryCtaAction.bind(this)"
                           [showTertiaryButton]="false"
                           [primaryButtonDisabled]="!(isCSVAdded && this.errorRows.length === 0)"
></app-bottom-sticky-buttons>

<div bsModal #mobilePreviewModal="bs-modal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-info m-0">
        <div class="modal-content vw-100">
            <div class="close-frame-button" style="width: 95vw !important">
                <div (click)="mobilePreviewModal.hide()" class="is-mobile-preview">
                    <i class="fa fa-times fa-lg text-white" aria-hidden="true"></i>
                </div>
            </div>
            <div class="px-0 text-center" id="mobilePreview">
                <div class="text-center" id="livePreviewMobile">
                    <iframe style="border: unset;" class="vw-100 vh-100" #mobilePreview></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<app-beaconstac-add-on (dataChange)="reloadPage()" #addOns></app-beaconstac-add-on>


<div bsModal #generatingBatchModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" *ngIf="!isMobileScreen">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div>
                    <h4 class="modal-title">Processing bulk request...</h4>
                </div>
                <div>
                    <div class="mt-3" style="font-size: 15px; color: #5E6980;">
                        <p class="mt-1 mb-0">Sit back and relax! We'll send a confirmation email once the business cards are ready.
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer pb-4 px-4 pt-3 d-flex justify-content-end" style="border: none;">
                <beaconstac-button
                    [type]="BUTTON_TYPES.PRIMARY"
                    [label]="'Close'"
                    [style]="BUTTON_STYLES.FILLED"
                    (onClick)="finishBulkUpload()"
                ></beaconstac-button>
            </div>
        </div>
    </div>
</div>

<beaconstac-mobile-drawer [isFullScreen]="false" [isDrawerOpen]="isGeneratingBatchDrawerOpen" (onOutsideClick)="isGeneratingBatchDrawerOpen = false" *ngIf="isMobileScreen">
    <section>
        <div>
            <h4 class="modal-title">Processing bulk request...</h4>
        </div>
        <div>
            <div class="mt-3" style="font-size: 15px; color: #5E6980;">
                <p class="mt-1 mb-0">Sit back and relax! We'll send a confirmation email once the business cards are ready.
                </p>
            </div>
        </div>
        <div class="pb-4 pt-3 w-100" style="border: none;">
            <beaconstac-button
                [type]="BUTTON_TYPES.PRIMARY"
                [label]="'Close'"
                [style]="BUTTON_STYLES.FILLED"
                (onClick)="finishBulkUpload()"
                [width]="'100%'"
            ></beaconstac-button>
        </div>
    </section>
</beaconstac-mobile-drawer>

<div bsModal #usersLimitReachedModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}" (onShow)="count === 0">
    <div class="modal-dialog modal-dialog-centered" role="document" >
        <div class="modal-content modal-width" style="height: 612px !important;">
            <div class="modal-body p-4 modal-width user-select-none">
            <h4 class="custom-modal-heading">User seat limit reached 🚨</h4>
            <div class="custom-modal-description mt-4">{{ modalSubText }}</div>
            <div class="align-items-center user-seat-change-slider">
                <div class="counter-container">
                    <div class="counter-content d-flex">
                      <div class="increment-decrement-button" id="decrement-button" (mousedown)="decrementButton.disabled || startDecrementLongPress($event)"
    (mouseup)="endDecrementLongPress()"
    (mouseleave)="endDecrementLongPress()">
                        <beaconstac-button
                        #decrementButton
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style] = "BUTTON_STYLES.STROKE"
                        (onClick) = "decrementCounter()"
                        [icon]="'fas fa-minus'"
                        [disabled]="isDisabled">
                        </beaconstac-button>
                      </div>
                        <input type="number" name="user-seats-slider" class="counter" [min]="1" id="user-seats-counter"  [ngModel]="dbcUserSeats" style="width: 186px" (input)="handleUserSeatChange($event.target.value)">
                      <div class="increment-decrement-button" id="increment-button"(mousedown)="startIncrementLongPress($event)"
    (mouseup)="endIncrementLongPress()"
    (mouseleave)="endIncrementLongPress()">
                        <beaconstac-button
                        [type]="BUTTON_TYPES.SECONDARY"
                        [style] = "BUTTON_STYLES.STROKE"
                        (onClick) = "incrementCounter()"
                        [icon]="'fas fa-plus'"
                        [disabled]="isTrialUser ? (minUsersToBuy > dbcUserSeats) : false">
                        </beaconstac-button>
                      </div>
                    </div>
                    <div class="users-text">
                      user seats
                    </div>
                </div>
            </div>

            <div *ngIf="shouldShowSelector" class="selector-container">
                <div class="selector-content mr-0 ml-4 d-flex flex-column">
                    <div class="d-flex justify-flex-start align-items-center">

                        <beaconstac-checkbox
                                      style="height: 23px"
                                      [checked]="dbcUserSeatsChecked"
                                      (checkedChange)="checkboxHandler()">
                                  </beaconstac-checkbox>
                        <label for="myCheckbox" class="mb-0 ml-2 selector-text">Include Business+ features</label>
                    </div>

                    <div class="selector-options">
                        <div class="d-flex mt-3 d-flex flex-wrap selector-options-content">
                            <div *ngFor="let option of checkOptions" class="flex-item d-flex justify-content-start">
                                <i class="fa fa-check check-icon"></i>
                                <div class="option-text pl-2">{{option}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="plan-details-container p-3" >
                <div class="d-flex flex-column justify-content-between" [ngStyle]="{'padding-top' : (selectedDBCPlan === PLAN_TYPES.BusinessPlus) ? '12px' : 'initial'}">
                    <div [ngClass]="planClassName" class="plan text-uppercase text-center d-flex align-items-center justify-content-center" style="width: 120px !important;">{{ readablePlanName }}</div>
                    <div class="plan-value">
                        <div *ngFor="let plan of plans">
                            <div *ngIf="plan.planType === selectedDBCPlan">
                                {{plan.value}} users
                            </div>
                        </div>
                    </div>
                    <div>
                        <div *ngIf="selectedDBCPlan !== PLAN_TYPES.BusinessPlus" [ngStyle]="{ 'display': (selectedDBCPlan === PLAN_TYPES.BusinessPlus) ? 'none' : 'block' }">
                        <div class="plan-value">
                            <div *ngFor="let plan of plans">
                                <div *ngIf="plan.planType === selectedDBCPlan">
                                    {{plan.price}}/user, per month
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-end" [ngStyle]="{'justify-content' : !isTrialUser ? 'space-between' : 'space-around'}">
                    <div class="d-flex flex-row">
                        <div *ngIf="showUpcomingPrice" class="text-center pt-1 pr-2" style="margin-bottom: 11px">
                            <spinner [size]="20"></spinner>
                        </div>
                        <div *ngIf="(selectedDBCPlan !== PLAN_TYPES.BusinessPlus) && isTrialUser" class="plan-details">{{ !isTrialUser &&  selectedDBCPlan !== PLAN_TYPES.BusinessPlus ? '+' : ''}}${{ dbcUserSeatsPrice | number:'1.2-2' }}</div>
                        <div *ngIf="(selectedDBCPlan !== PLAN_TYPES.BusinessPlus) && !isTrialUser" class="plan-details" [ngStyle]="{'color' : fetchingUpcomingPrice ? '#c7cacf' : 'initial'}">${{upcomingPrice | number:'1.2-2'}}</div>
                    </div>
                    <div *ngIf="selectedDBCPlan === PLAN_TYPES.BusinessPlus" class="plan-details">Custom pricing</div>
                    <div class="plan-details-subtext" *ngIf="selectedDBCPlan !== PLAN_TYPES.BusinessPlus && !isTrialUser">
                        pro-rated till {{nextBillingDate}}
                        <beaconstac-tooltip
                        [width]="'250px'"
                        [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                        [caretPosition]="CARET_POSITION.LEFT"
                        [elementClass]="'fas fa-info-circle pl-1'"
                        [color] = "'#979797'"
                        [content]="usersTooltipMessage">
                    </beaconstac-tooltip>
                    </div>
                    <div class="plan-details-subtext" *ngIf="selectedDBCPlan === PLAN_TYPES.BusinessPlus">
                        Talk to our sales
                    </div>
                    <div class="plan-details-subtext" *ngIf="selectedDBCPlan !== PLAN_TYPES.BusinessPlus && isTrialUser">
                        per month, billed annually
                    </div>
                </div>
            </div>

            <div class="float-right d-flex flex-row " [ngStyle]="{
                'padding-top': '80px',
                'margin-top': calculateMarginTop()
              }">
                <beaconstac-button
                    [type]="BUTTON_TYPES.SECONDARY"
                    (onClick)="usersLimitReachedModal.hide(); invalidFileError='' "
                    [label]="'Close'">
                </beaconstac-button>
                <beaconstac-button
                    [disabled]="getBulkUploadCTA"
                    (onClick)="selectedDBCPlan === PLAN_TYPES.BusinessPlus ? onEnterpriseSelected() :  updateDbcUserSeats()"
                    class="text-uppercase pl-3"
                    [label]="selectedDBCPlan === PLAN_TYPES.BusinessPlus ? 'Schedule demo' : isTrialUser ? 'Upgrade to continue' : selectedDBCPlan === currentDBCPlan ? 'Buy user seats': 'Upgrade'">
                </beaconstac-button>
            </div>

            </div>
        </div>
    </div>
</div>
