import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {OverlayService} from '../../global-services/overlay.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
    BULK_ACTION_PRODUCT,
    CARD_BULK_OPERATIONS,
    CARDS_FILTER_OPTIONS,
    DBCCardTypes,
    PRODUCT_STATE,
    PRODUCT_TYPES,
    svgToPng,
    Utils,
    WALLET_PASS_DEFAULT_COLOR
} from '../../shared/utils';
import {DigitalBusinessCard} from '../digital-business-card.model';
import {ListResult, SearchParams} from '../../global-services/base-backend.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {debounceTime, distinctUntilChanged, finalize, takeUntil} from 'rxjs/operators';
import {AuthService, DATADOG_ACTIONS} from '../../global-services/auth.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {
    BUTTON_STYLES,
    BUTTON_TYPES,
    CARET_POSITION,
    DROPDOWN_ALIGNMENT,
    DROPDOWN_STYLES,
    DROPDOWN_TYPES,
    EMPTY_STATE_TYPES,
    ICON_SIZE,
    OPTIONS_POSITION,
    TEXT_FIELD_TYPES,
    TEXT_FIELD_VALIDATIONS,
    TOAST_TYPES,
    TOOLTIP_POSITION
} from 'kaizen-design-system';
import {Animations} from '../../shared/beaconstac-animations';
import {
    DbcBulkFilterHardDeleteService,
    DbcBulkHardDeleteService,
    DbcQRBulkDownloadService,
    DbcQRBulkFilterDownloadService,
    DbcQRDownloadService,
    DigitalBusinessCardsService,
    DigitalBusinessCardTemplateService,
    OwnerTransferService,
    ShareWalletPassFilterService,
    ShareWalletPassService,
} from '../digital-business-cards.service';
import {QRCodeBuilder} from 'mobstac-awesome-qr';
import {CanvasType, QRCodeFrame, QRErrorCorrectLevel} from 'mobstac-awesome-qr/lib/Enums';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../global-services/amplitude.service';
import {QR_CANVAS_TYPES, QR_ERROR_CORRECTION_LEVEL} from '../../qr/qr.model';
import {User} from '../../user-account/user.model';
import {UserService} from '../../user-account/user.service';
import {Organization, ReducedOrganization, WalletPassCustomizations} from '../../user-account/user-organization.model';
import {ProductTransfer} from '../../global-services/product-transfer.service';
import * as moment from 'moment/moment';
import {AnalyticsService} from '../../global-services/analytics.service';
import {environment} from '../../../environments/environment';
import {UpsellService} from 'app/global-services/upsell.service';
import {FEATURES} from '../../user-account/organization-permission-model';
import {ExternalUrlService} from 'app/global-services/external-url.service';
import {FetchOrganizationsService} from 'app/global-services/fetch-organizations.service';
import {UserOrgService} from '../../user-account/user-organization.service';
import {CurrentPageStat} from '../../shared/current-page-stat.model';
import {TEMPLATE_SAVE_REDIRECT_TO} from '../digital-business-card-templates/digital-business-card-templates.component';
import {Media} from '../../places/media.model';
import {MediaGalleryComponent} from '../../global-components/media-gallery/media-gallery.component';
import {ImageCropperComponent} from '../../global-components/image-cropper/image-cropper.component';
import {BeaconstacProductLabelComponent} from '../../global-components/product-label/beaconstac-product-label.component'
import {TagsService} from '../../tags/tags.service';
import {Folder} from '../../media-folders/media-folder.model';
import {FEATURE_FLAGS} from '../../shared/feature-flags';


enum GENERATE_QRCODE_TYPE {
    APPLE_WALLET,
    GOOGLE_WALLET,
    DBC
}

enum WALLET_PASS {
    APPLE_WALLET,
    GOOGLE_WALLET
}

enum WALLET_PASS_CUSTOMIZATION {
    GOOGLE_WALLET_PASS_BACKGROUND,
    APPLE_WALLET_PASS_BACKGROUND,
    APPLE_WALLET_PASS_FOREGROUND
}

enum TOOLBAR_OPTIONS {
    SHARE = 'share',
    MODAL_DBC_PREVIEW = 'modal-dbc-preview',
    MODAL_DBC_DETAILS = 'modal-dbc-details',
    INFO = 'info',
    MODAL = 'modal',
    EDIT = 'edit',
    PREVIEW = 'preview',
    ANALYTICS = 'analytics',
    MORE_OPTIONS = 'more-options'
}

enum SHARE_CARD_EVENTS {
    APPLE_WALLET = 'apple wallet',
    GOOGLE_WALLET = 'google wallet',
    COPY_URL = 'copy url',
    DOWNLOAD_QR = 'download qr',
    DOWNLOAD_QR_MULTISELECT = 'multiselect',
    DIRECT_SHARE_WALLET_PASS = 'direct share wallet pass'
}

enum TOOLBAR_OPTIONS_EVENT {
    INFORMATION = 'information',
    PREVIEW = 'preview'
}

enum DOWNLOAD_SCREENS {
    SHARE_SCREEN,
    PREVIEW_SCREEN,
    DOWNLOADS_SCREEN,
    ADVANCE_DOWNLOAD_SCREEN,
    FILE_TYPE_SCREEN,
    SIZE_SCREEN,
    ERROR_CORRECTION_SCREEN,
    SHARE_VIA_APP_SCREEN,
}

enum MORE_OPTIONS_SCREEN {
    MORE_OPTIONS,
    CHANGE_OWNER,
    CHANGE_ORGANIZATION,
    DELETE_CARD,
    BULK_DELETE,
    BULK_DOWNLOAD,
    BULK_SHARE_WALLET_PASS,
}

enum SWITCH_MOBILE_SCREEN {
    MORE_OPTIONS = 'more-options',
    OWNER = 'owner',
    ORGANIZATION = 'organization',
    DELETE = 'delete',
    BULK_DELETE = 'bulk-delete',
    BULK_DOWNLOAD = 'bulk-download',
    BULK_SHARE_WALLET_PASS = 'bulk-share',
    SHARE = 'share',
    PREVIEW = 'preview',
    DOWNLOADS = 'downloads',
    ADVANCE_DOWNLOAD = 'advance_download',
    FILE_TYPE = 'file_type',
    SIZE = 'size',
    ERROR_CORRECTION = 'error_correction',
}


enum APPLY_LABEL_OPERATION {
    FILTERED_PRODUCTS = 1,
    LIST_PRODUCTS = 2,
}

enum AWP_CUSTOMIZE_OPTION  {
    BACKGROUND_COLOR = 'background',
    TEXT_COLOR = 'text',
    LOGO_IMG = 'logo',
}

interface AppleWalletPassCustomization {
    backgroundColor: string,
    textColor: string
    logo: {
        url: string,
        id: string
    }
}

@Component({
    selector: 'app-digital-business-card-list',
    templateUrl: './digital-business-card-list.component.html',
    styleUrls: ['./digital-business-card-list.component.scss', '../../../scss/product.scss', '../../markdown-cards/markdown-card-detail.scss'],
    animations: [Animations.flyInOut, Animations.toggleScale]
})
export class DigitalBusinessCardListComponent implements OnInit, OnDestroy {

    @ViewChild('sideModalOnInfoClick', { static: false }) sideModalOnInfoClick: ModalDirective;
    @ViewChild('dbcPreview', { static: false }) dbcPreview: ElementRef;
    @ViewChild('appleWalletPassModal', { static: false }) appleWalletPassModal: ModalDirective;
    @ViewChild('googleWalletPassModal', { static: false }) googleWalletPassModal: ModalDirective;
    @ViewChild('deleteCardModal', { static: false }) deleteCardModal: ModalDirective;
    @ViewChild('confirmShareWalletPassModal', { static: false }) confirmShareWalletPassModal: ModalDirective;
    @ViewChild('confirmBulkDeletePermanentlyModal', { static: false }) confirmBulkDeletePermanentlyModal: ModalDirective;
    @ViewChild('changeMaintainer', { static: false }) changeMaintainer: ModalDirective;
    @ViewChild('changeOrganization', { static: false }) changeOrganization: ModalDirective;
    @ViewChild('downloadModal', { static: false }) downloadModal: ModalDirective;
    @ViewChild('selectCardOwnerModal', { static: false }) selectCardOwnerModal: ModalDirective;
    @ViewChild('dbcBulkDownloadModal', { static: false }) dbcBulkDownloadModal: ModalDirective;
    @ViewChild('downloadRequestModal', { static: false }) downloadRequestModal: ModalDirective;
    @ViewChild('bulkCreateTemplateWarnModal', { static: false }) bulkCreateTemplateWarnModal: ModalDirective;
    @ViewChild('bulkCreateOptionsModal', { static: false }) bulkCreateOptionsModal: ModalDirective;
    @ViewChild('imageSelectModalLogo', {static: false}) imageSelectModalLogo: ModalDirective;
    @ViewChild('imageSelectModalHeroImage', {static: false}) imageSelectModalHeroImage: ModalDirective;
    @ViewChild('imageSelectGalleryLogo', {static: false}) imageSelectGalleryLogo: MediaGalleryComponent;
    @ViewChild('imageSelectGalleryHeroImage', {static: false}) imageSelectGalleryHeroImage: MediaGalleryComponent;
    @ViewChild('imageCropperLogo', {static: false}) private imageCropperLogo: ImageCropperComponent;
    @ViewChild('imageCropperHeroImage', {static: false}) private imageCropperHeroImage: ImageCropperComponent;
    @ViewChild('tagUntagModal', {static: false}) tagUntagModal: BeaconstacProductLabelComponent;
    @ViewChild('openPreviewModal', { static: false }) openPreviewModal: ModalDirective;
    @ViewChild('dbcMobileOnePreview', { static: false }) dbcMobileOnePreview: ElementRef
    @ViewChild('dbcMobileTwoPreview', { static: false }) dbcMobileTwoPreview: ElementRef
    @ViewChild('awpLogoImageSelectModal', {static: false}) private awpLogoImageSelectModal: ModalDirective;
    @ViewChild('awpLogoImageSelectMediaGallery', {static: false}) awpLogoImageSelectMediaGallery: MediaGalleryComponent;
    @ViewChild('imageCropperAWPLogoImg', {static: false}) private imageCropperAWPLogoImg: ImageCropperComponent;

    @Input() product: DigitalBusinessCard = null
    @Input() validations: any;
    @Output() productChange: EventEmitter<DigitalBusinessCard> = new EventEmitter();
    @Output() validationsChange: EventEmitter<any> = new EventEmitter();

    tableData = {
        header: [{
            text: '',
        }, {
            text: 'Name',
            mobile: true,
            sort: true
        }, {
            text: 'Labels',
            mobile: true
        }, {
            text: 'Saves',
            sort: true
        }, {
            text: 'Views',
            sort: true
        }],
        data: []
    }
    infoModalActionsOptions = [{ name: 'Duplicate', value: 'Duplicate' },
        { name: 'Edit', value: 'edit' },
        { name: 'Rename', value: 'rename' },
        { name: 'Delete', value: 'Delete', overrideColor: '#FF445E' }]

    mobileActionsOptions = []

    hasDbcBulkCreate: boolean;
    cardType: DBCCardTypes;
    DBCCardTypes = DBCCardTypes
    isListFiltered: boolean = false;
    digitalBusinessCards: ListResult<DigitalBusinessCard>;
    selectedDigitalBusinessCard: DigitalBusinessCard = null;
    isNewDigitalBusinessCard: boolean;
    digitalBusinessCard: DigitalBusinessCard = new DigitalBusinessCard();
    pageAttributes = {
        pageSize: 10,
        pageNumber: 1,
        ordering: '',
        isAscending: true
    }
    searchParams: SearchParams = {};
    pageAttributesChange = new Subject<boolean>();
    pagination: number = 0;
    totalResults: number = 0;
    showLogoUploadModal: boolean = false;
    imageCropperFile: File;
    imageSelectModel = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
    };
    updatedMediaLogo: Media = null;
    updatedMediaHeroImage: Media = null;

    isLogoUploading: boolean = false;
    isIconUploading: boolean = false;
    uploadToFolder = null;
    showTooltip: boolean = false;
    activeTabColour: string = '#2595FF';
    inactiveTabColour: string = '#979797';
    inactiveTabHrColour: string = '#DBDFE3';
    chooseTabComponent: number = 1;

    pages = [{ name: '10', value: '10' }, { name: '20', value: '20' }, { name: '50', value: '50' }, {
        name: '100',
        value: '100'
    }];

    pageSizeMapping = { '10': 0, '20': 1, '50': 2, '100': 3};

    columnsToSort = {
        Name: { name: 'first_name', value: 1 },
        Created: { name: 'created', value: 2 },
        Link: { name: 'url', value: 3 },
        Views: { name: 'views', value: 4 },
        Saves: { name: 'saves', value: 5 },
    }
    currentSortingName: string;
    sideModalHeight: number
    currentSortingValue = -1;
    hasWriteAccess: boolean;
    isAdmin: boolean;
    qrDownloadOptions: any = []
    generatedDBCQRcode: string = '';
    generatedWalletQRcode: string = '';

    googleWalletPassBaseLink: string = '';
    appleWalletPassBaseLink: string = '';
    googleWalletPassLink: string = '';
    appleWalletPassLink: string = '';

    isDefaultWalletPassCustomizationSet: boolean = false;
    currOrgWPTemplate: any;

    appleWalletPassCustomization: AppleWalletPassCustomization = {
        backgroundColor: WALLET_PASS_DEFAULT_COLOR.BACKGROUND_COLOR,
        textColor: WALLET_PASS_DEFAULT_COLOR.FOREGROUND_COLOR,
        logo : {
            url: '',
            id: ''
        }
    };
    googleWalletPassPreviewColor: {
        backgroundColor: string,
        textColor: string
    } = {
            backgroundColor: WALLET_PASS_DEFAULT_COLOR.BACKGROUND_COLOR,
            textColor: WALLET_PASS_DEFAULT_COLOR.FOREGROUND_COLOR
        };
    googleWalletPassPreviewLogo: { logo_url: string} = {
        logo_url: '',
    };
    googleWalletPassPreviewHeroImage: { hero_image_url: string} = {
        hero_image_url: '',
    };
    googleWalletPassPreviewLogoId: { logo_id: number} = {
        logo_id: null,
    }

    googleWalletPassPreviewHeroImageId: { hero_image_id: number} = {
        hero_image_id: null,
    }
    appleWalletEmailModel = {
        email: '',
        isEmailValid: false
    };
    googleWalletEmailModel = {
        email: '',
        isEmailValid: false
    };

    dbc_url: boolean;

    customDownloadOptions: {
        canvasType: QR_CANVAS_TYPES,
        size: number,
        correctLevel: QR_ERROR_CORRECTION_LEVEL
    } = {
            canvasType: QR_CANVAS_TYPES.SVG,
            size: 1024,
            correctLevel: QR_ERROR_CORRECTION_LEVEL.H
        };

    walletPassPreviewColorSelect = new Subject<any>();
    emailChangeSubject = new Subject<any>();

    // Bulk Option varibales
    selectedCards: Array<number> = [];
    isAllCardsSelected: boolean = false;
    bulkOperationsMessage: string = '';
    canvasTypeSelection = [
        { id: 'PDF', value: QR_CANVAS_TYPES.PDF},
        { id: 'PNG', value: QR_CANVAS_TYPES.PNG},
        { id: 'SVG', value: QR_CANVAS_TYPES.SVG},
        { id: 'JPEG', value: QR_CANVAS_TYPES.JPEG},
        { id: 'EPS', value: QR_CANVAS_TYPES.EPS},
        { id: 'ALL', value: ''}
    ];

    defaultCanvasType = { id: 'SVG', value: QR_CANVAS_TYPES.SVG};
    qrSizeSelection = [
        { id: '512 x 512', value: '512'},
        { id: '1024 x 1024', value: '1024'},
        { id: '2048 x 2048', value: '2048'},
        { id: '4096 x 4096', value: '4096'},

    ]
    defaultQrSize = { id: '1024 x 1024', value: '1024'};
    errorCorrectionLevelSelection = [
        { id: 'Low', value: QR_ERROR_CORRECTION_LEVEL.L},
        { id: 'Medium', value: QR_ERROR_CORRECTION_LEVEL.M},
        { id: 'High', value: QR_ERROR_CORRECTION_LEVEL.Q},
        { id: 'Highest', value: QR_ERROR_CORRECTION_LEVEL.H},
    ]
    defaultErrorCorrectionLevel = { id: 'Highest', value: QR_ERROR_CORRECTION_LEVEL.H};



    // Maintainer change
    maintainerList: Array<User> = [];
    originalMaintainerList: Array<User> = [];
    disableTransferOwnerButton: boolean = true;
    currentProductOwner: User;
    changeUserId: number;

    // Organization change
    organizationList: Array<ReducedOrganization> = [];
    masterOrg: Organization;
    isMasterOrganization: boolean;
    disableTransferOrgButton: boolean = true;
    currentProductOrg: object;
    hasOwnerPermissions: boolean = false;
    changeOrganizationId: number;
    showPreviewFrame: boolean = false;
    selectedCardOwnerId = -1;
    QRCodeFrame = QRCodeFrame;

    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS
    BUTTON_TYPES = BUTTON_TYPES
    DROPDOWN_TYPES = DROPDOWN_TYPES
    DROPDOWN_STYLES = DROPDOWN_STYLES
    DROPDOWN_ALIGNMENT = DROPDOWN_ALIGNMENT
    BUTTON_STYLES = BUTTON_STYLES
    ICON_SIZE = ICON_SIZE
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES
    WALLET_PASS = WALLET_PASS;
    WALLET_PASS_CUSTOMIZATION = WALLET_PASS_CUSTOMIZATION;
    BULK_ACTION_PRODUCT = BULK_ACTION_PRODUCT;
    CARD_BULK_OPERATIONS = CARD_BULK_OPERATIONS;
    OPTIONS_POSITION = OPTIONS_POSITION;
    QR_CANVAS_TYPES = QR_CANVAS_TYPES;
    QR_ERROR_CORRECTION_LEVEL_KEYS: any = Utils.ObjectKeys(QR_ERROR_CORRECTION_LEVEL).slice(Utils.ObjectKeys(QR_ERROR_CORRECTION_LEVEL).length / 2);
    QR_ERROR_CORRECTION_LEVEL = QR_ERROR_CORRECTION_LEVEL;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    CARET_POSITION = CARET_POSITION

    ngUnsubscribe: Subject<any> = new Subject<any>();
    user: User;
    cardOwnerEmail: string = 'NA';

    dropdownForSearch = {
        list: [{
            name: 'First name', value: 'First name', searchValue: 'first_name__icontains', placeholder: 'Search card by first name'
        }, {
            name: 'Last name', value: 'Last name', searchValue: 'last_name__icontains', placeholder: 'Search card by last name'
        }, {
            name: 'Owner email', value: 'Owner email', searchValue: 'card_owner__email__icontains', placeholder: 'Search card by card owner email'
        }, {
            name: 'Card link', value: 'Card link', searchValue: 'url__icontains', placeholder: 'Search card by link'
        }],
        label: { name: 'First name', value: 'First name', searchValue: 'first_name__icontains', placeholder: 'Search card by first name' },
    }

    searchPlaceholder = this.dropdownForSearch.label.placeholder
    searchByValue = this.dropdownForSearch.label.searchValue

    dropdowns = [this.dropdownForSearch]
    showDetails: boolean = false;
    orgHasTempaltes: boolean = false;
    hasCardTemplateAccess: boolean = false;
    queryParams: any = {};
    dbcTemplatesList: Array<any> = [];

    filtersChangeSubject: BehaviorSubject<any> = new BehaviorSubject('');

    CARDS_FILTER_OPTIONS = CARDS_FILTER_OPTIONS;
    $resize: Subject<any> = new Subject<void>();
    current_more_option_screen: MORE_OPTIONS_SCREEN = MORE_OPTIONS_SCREEN.MORE_OPTIONS;
    MORE_OPTIONS_SCREEN = MORE_OPTIONS_SCREEN;
    ismoreoptionDrawerOpen: boolean = false;
    SWITCH_MOBILE_SCREEN = SWITCH_MOBILE_SCREEN;

    isDownloadsDrawerOpen: boolean = false;
    isBulkCreateWarnDrawerOpen: boolean = false;
    isAdvanceDownloadOptionsOpen: boolean = false;
    current_mobile_screen: DOWNLOAD_SCREENS = DOWNLOAD_SCREENS.SHARE_SCREEN;
    DOWNLOAD_SCREENS = DOWNLOAD_SCREENS;

    tagList = [];
    isFetchingTags = false;
    selectedCardsForTag = [];
    applyLabelOperationType = APPLY_LABEL_OPERATION.LIST_PRODUCTS
    isMobileScreen: boolean = false;

    selectedFileType: any;
    selectedSize: string;
    selectedErrorCorrection: string;
    isMobileView: boolean = false;
    isPreviewModalopen: boolean = false;
    isMobileScreenSize: boolean;
    isGoogleWalletPassModalOpen: boolean = false;
    isCustomizeGoogleWalletPassOpen: boolean = false;
    isGuidelinesModalOpen: boolean = false;
    isHeroImageGuidelinesModalOpen: boolean = false;
    isBulkCreateOptionsModalOpen: boolean = false;
    launchDarkleyFlags: any = {
        [FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM]: true
    }

    awpCurrentCustomizeOption: AWP_CUSTOMIZE_OPTION = AWP_CUSTOMIZE_OPTION.BACKGROUND_COLOR;

    /* awp logo upload img variables */
    awpLogoImgSelectModel: {media: Media, link: string, alt: string, isUploading: boolean} = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
    };

    awpLogoImgCropperFile: File;
    awpLogoImgUploadToFolder: Folder = null;
    awpUpdatedMediaLogo: Media = null;

    /* awp mobile modal variables */
    isAWPModalOpen: boolean = false;
    isCustomizeAWPOpen: boolean = false;
    currentWalletPassType: WALLET_PASS;

    protected readonly AWP_CUSTOMIZE_OPTION = AWP_CUSTOMIZE_OPTION;
    protected readonly EMPTY_STATE_TYPES = EMPTY_STATE_TYPES;
    protected readonly PRODUCT_TYPES = PRODUCT_TYPES;

    constructor(private overlayService: OverlayService, private router: Router, private route: ActivatedRoute,
        private authService: AuthService, private messageModal: MessageModalService,
        private digitalBusinessCardService: DigitalBusinessCardsService, private shareWalletPassService: ShareWalletPassService,
        private amplitude: AmplitudeService, private shareWalletPassFilterService: ShareWalletPassFilterService,
        private userService: UserService, private ownerTransfer: OwnerTransferService, private productTransfer: ProductTransfer,
        private analyticsService: AnalyticsService, private dbcQRDownloadService: DbcQRDownloadService,
        private externalURLService: ExternalUrlService,
        private  upsellService: UpsellService, private dbcBulkHardDeleteService: DbcBulkHardDeleteService,
        private dbcQRBulkDownloadService: DbcQRBulkDownloadService,
        private dbcQRBulkFilterDownloadService: DbcQRBulkFilterDownloadService,
        private dbcBulkFilterHardDeleteService: DbcBulkFilterHardDeleteService, private fetchOrganizationsService: FetchOrganizationsService, public organizationService: UserOrgService,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private tagService: TagsService) {
        this.route.data.subscribe(res => {
            this.cardType = res.data.team ? DBCCardTypes.TEAM_CARDS : DBCCardTypes.MY_CARDS
        })
        this.user = this.authService.getUser();
        this.hasDbcBulkCreate = this.user.hasDbcPermissionFor(FEATURES.bulk_dbc);
        this.hasCardTemplateAccess = this.user.organization.dbc_feature_permissions.card_template;
        if (this.hasCardTemplateAccess) {
            this.getDigitalBusinessCardTemplateList();
        }

        if (!this.hasCardTemplateAccess) {
            this.CARDS_FILTER_OPTIONS = this.CARDS_FILTER_OPTIONS.filter(option => option.value !== 'card_template');
        }
        this.pageAttributes.ordering = ''
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.sideModalHeight = window.innerHeight;
        this.isMobileScreen = window.innerWidth <= 768; // TODO: Duplication of isMobileScreenSize Need to fix this
        this.$resize.next();
        this.checkScreenSize();
        this.isMobileScreenSize  = window.innerWidth <= 767;
    }

    ngOnInit(): void {
        this.setDeviseType()
        this.$resize.pipe(debounceTime(300)).subscribe(() => {
            this.setDeviseType()
        })
        this.onResize()
        this.checkScreenSize();
        this.checkMobileScreen();
        this.hasWriteAccess = this.authService.getUser().hasWriteAccess(this.authService.getCurrentOrgId());
        this.isAdmin = this.authService.getUser().isAdmin(this.authService.getCurrentOrgId());
        if (this.cardType === DBCCardTypes.TEAM_CARDS && (!this.authService.getUser().isSuperAdmin() && this.authService.getUser().isEditor(this.authService.getCurrentOrgId()))) {
            this.router.navigate(['/overview'], { queryParams: { orgID: this.authService.getCurrentOrgId() } });
        }
        this.handleQueryParams();
        this.pageAttributesChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.overlayService.isLoading(true);
            this.getDigitalBusinessCardList();
        })
        this.currOrgWPTemplate = this.authService.getCurrentOrganization().wallet_pass_template;
        this.isDefaultWalletPassCustomizationSet = this.currOrgWPTemplate.enforce_wallet_pass_template;
        this.appleWalletPassCustomization = this.setDefaultAWPCustomizationValues(this.currOrgWPTemplate);
        this.updateAppleWalletPassQRCode();

        this.walletPassPreviewColorSelect.pipe(debounceTime(1000)).subscribe(event => {
            if (event) {
                if (event['walletPassType'] === WALLET_PASS.GOOGLE_WALLET) {
                    if (event['logo_url'] != null && event['logo_url'] !== undefined && event['logo_url'] !== '') {
                        this.googleWalletPassLink = `${this.googleWalletPassBaseLink}?background=${event['backgroundColor']}&foreground=${event['textColor']}&logourl=${encodeURIComponent(event['logo_url'])}`;
                    } else {
                        this.googleWalletPassLink = `${this.googleWalletPassBaseLink}?background=${event['backgroundColor']}&foreground=${event['textColor']}`;
                    }
                    if (event['hero_image'] !== '' && event['hero_image'] !== undefined) {
                        this.googleWalletPassLink += `&heroimageurl=${encodeURIComponent(event['hero_image'])}`;
                    }
                    if (event['dbc_url'] === false) {
                        this.googleWalletPassLink += '&dbc_logo_url=false';
                    }
                    this.generateQrCode(GENERATE_QRCODE_TYPE.GOOGLE_WALLET);
                } else if (event['walletPassType'] === WALLET_PASS.APPLE_WALLET) {
                    const logoFragment = (event['logo_id']) ? '&logoId=' + event['logo_id'] : '';
                    this.appleWalletPassLink = `${this.appleWalletPassBaseLink}?background=${event['backgroundColor']}&foreground=${event['textColor']}${logoFragment}`;
                    this.generateQrCode(GENERATE_QRCODE_TYPE.APPLE_WALLET);
                }
            }
        })

        this.emailChangeSubject.pipe(debounceTime(500), takeUntil(this.ngUnsubscribe)).subscribe(event => {
            this.checkEmailValidity(event);
        });

        for (const value in QR_CANVAS_TYPES) {
            this.qrDownloadOptions.push({ 'name': value, 'value': QR_CANVAS_TYPES[value] });
        }
        this.qrDownloadOptions.push({ 'name': 'Custom', 'value': 'custom' });
        this.getMaintainerList();
        this.setActionsItems(null);

        this.googleWalletPassPreviewColor.backgroundColor = '#' + this.currOrgWPTemplate.google_wallet_pass_background;
        this.googleWalletPassPreviewLogo.logo_url = this.currOrgWPTemplate.google_wallet_pass_logo;
        this.googleWalletPassPreviewHeroImage.hero_image_url = this.currOrgWPTemplate.google_wallet_pass_hero_image;
        this.googleWalletPassPreviewLogoId.logo_id = this.currOrgWPTemplate.google_wallet_pass_logo_id;
        this.googleWalletPassPreviewHeroImageId.hero_image_id = this.currOrgWPTemplate.google_wallet_pass_hero_image_id;
        this.getGoogleWalletPassTextColor(this.googleWalletPassPreviewColor.backgroundColor);
        this.organizationList = this.authService.getOrganizationsList();

        this.filtersChangeSubject.pipe(takeUntil(this.ngUnsubscribe), distinctUntilChanged(), debounceTime(500)).subscribe(res => {
            this.overlayService.isLoading(true);
            this.queryParams = {
                filters: res
            };
            this.onPageAttributesChange(1)
        })

        this.fetchLabels();

        this.setMobileActionsItems(null);
        this.getLaunchDarkleyFlags();
    }

    setDefaultAWPCustomizationValues(currentWalletPass: WalletPassCustomizations): AppleWalletPassCustomization {
        const {apple_wallet_pass_background, apple_wallet_pass_foreground, apple_wallet_pass_logo, apple_wallet_pass_logo_id} = currentWalletPass;
        let logoURL = '';
        let logoId = '';

        /* Check if template has the logo if not then add the logo of selected dbc */
        if (apple_wallet_pass_logo || apple_wallet_pass_logo_id) {
            logoURL = apple_wallet_pass_logo;
            logoId = apple_wallet_pass_logo_id;
        } else if (this.selectedDigitalBusinessCard?.logo_url) {
            logoURL = this.selectedDigitalBusinessCard.logo_url;
        }

        return {
            backgroundColor: '#' + apple_wallet_pass_background,
            textColor: '#' + apple_wallet_pass_foreground,
            logo: {
                url: logoURL,
                id: logoId
            }
        }
    }

    fetchLabels() {
        const params: SearchParams = {
            'product_type': 'dbc',
            'label_type': 'dbc'
        };
        this.tagService
            .getList(1, 20, params,  true, 'updated')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (result) => {
                    this.tagList = result.objects;
                    this.isFetchingTags = false;
                },
                (error) => {
                    this.isFetchingTags = false;
                    this.messageModal.show('Error fetching labels. Please try refreshing the page.', 'danger');
                },
            );
    }

    getDigitalBusinessCardTemplateList() {
        this.digitalBusinessCardTemplateService.getList(1, 50).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.orgHasTempaltes = res.totalCount > 0;
            this.dbcTemplatesList = res.objects;
            if (res.totalCount === 0) {
                this.CARDS_FILTER_OPTIONS = this.CARDS_FILTER_OPTIONS.filter(option => option.value !== 'card_template');
            }
        })
    }
    checkMobileScreen() {
        this.isMobileScreenSize = window.innerWidth <= 767;
    }
    handleQueryParams() {
        this.route.queryParams.subscribe((params: Params) => {
            this.queryParams = params
        });
        this.onPageLoad();
        const source = this.queryParams.source;
        if (source === AMPLITUDE_EVENTS.DBC_WEEKLY_ANALYTICS) {
            this.amplitude.logEvent(
                AMPLITUDE_EVENT_CATEGORIES.Engagement,
                AMPLITUDE_EVENTS.DBC_WEEKLY_ANALYTICS,
                { source: this.queryParams.subsource }
            );
        }
    }

    handleBulkCardCreateCta() {
        if ( this.user.isOnDBCEnterprisePlan() && this.user.isSuperAdmin()) {
            // Show bulk modal
            if (!this.isMobileScreen) {
                this.bulkCreateOptionsModal.show();
            } else {
                this.isBulkCreateOptionsModalOpen = true;
            }
        } else {
            // Old bulk flow
            this.redirectToBulkAddDBC();
        }
    }

    redirectToMultiOrgUpload() {
        this.router.navigate(['/digital-business-cards/bulk-upload/v2'], { queryParams: { orgID: this.authService.getCurrentOrgId() } });
    }

    redirectToBulkAddDBC() {
        if (!this.orgHasTempaltes) {
            if (!this.isMobileScreen) {
                this.bulkCreateOptionsModal.hide();
            } else {
                this.isBulkCreateOptionsModalOpen = false;
            }
            setTimeout(() => {
                if (!this.isMobileScreen) {
                    this.bulkCreateTemplateWarnModal.show()
                }
                this.isBulkCreateWarnDrawerOpen = true
            }, 1000);
            return;
        }
        const cardRoute = this.cardType === DBCCardTypes.TEAM_CARDS ? 'team-cards' : 'my-cards';
        this.router.navigate(['/digital-business-cards/' + cardRoute + '/design-library'],
            { relativeTo: this.route, queryParams: { destination: 'bulk-upload' } });
    }

    redirectToAddDBC() {
        const cardRoute = this.cardType === DBCCardTypes.TEAM_CARDS ? 'team-cards' : 'my-cards';
        if (this.cardType === DBCCardTypes.MY_CARDS) {
            this.router.navigate(['/digital-business-cards/' + cardRoute + '/design-library'])
        } else {
            this.router.navigate(['/digital-business-cards/' + cardRoute + '/design-library'], { queryParams: { card_owner: this.selectedCardOwnerId } })
        }
    }

    openUpsellModal() {
        if (!this.hasDbcBulkCreate) {
            if (this.user.isOnSoloTrial()) {
                this.upsellService.show('This feature is available on Team plan!', 'Upgrade to our Team plan to create cards in bulk.', null, 'Try Team plan', null, null, null, null, null, 'TM')
            } else {
                this.upsellService.show('This feature is available on Team plan!', 'Upgrade to our Team plan to create cards in bulk.', null, 'Upgrade Plan', 'bulk-create-cta')
            }
        }
    }

    onPageLoad(){

        const pageStat = this.authService.getCurrentPage();
        if (pageStat) {
            this.pageAttributes.pageNumber = pageStat.pageNumber || 1;
            this.pageAttributes.ordering = pageStat.ordering;
            this.pageAttributes.pageSize = pageStat.pageSize;
            this.pageAttributes.isAscending = pageStat.ascending;
        }

        this.pageAttributesChange.next()
    }

    sortTable(sortBy: string) {
        this.currentSortingName = sortBy
        if (this.currentSortingValue === this.columnsToSort[sortBy].value) {
            this.pageAttributes.isAscending = !this.pageAttributes.isAscending
            if (this.pageAttributes.isAscending) {
                this.pageAttributes.ordering = this.columnsToSort[sortBy].name
            } else {
                this.pageAttributes.ordering = '-' + this.columnsToSort[sortBy].name
            }
        } else {
            this.pageAttributes.isAscending = true
            this.currentSortingValue = this.columnsToSort[sortBy].value
            this.pageAttributes.ordering = this.columnsToSort[sortBy].name
        }
        this.updatePageInfo();
        this.pageAttributesChange.next()
    }

    navigateToEdit(event: any) {
        const cardRoute = event.card_owner['id'] === this.authService.getUser().id ? 'my-cards' : 'team-cards';
        this.router.navigate(['/digital-business-cards/' + cardRoute + '/edit/' + event.id])
    }

    changePageSize(pageSize: number) {
        this.pageAttributes.pageSize = pageSize;
        this.pageAttributes.pageNumber = 1;
        this.updatePageInfo();
        this.pageAttributesChange.next()
    }

    onPageAttributesChange(pageNumber: number) {
        this.pageAttributes.pageNumber = pageNumber
        this.updatePageInfo();
        this.pageAttributesChange.next()
    }

    updatePageInfo(){

        const pageStat = new CurrentPageStat();
        pageStat.ordering = this.pageAttributes.ordering;
        pageStat.pageNumber = this.pageAttributes.pageNumber;
        pageStat.ascending = this.pageAttributes.isAscending;
        pageStat.pageSize = this.pageAttributes.pageSize;
        pageStat.type = this.cardType === DBCCardTypes.TEAM_CARDS ? 'team-cards' : 'my-cards';
    }

    handleSearchBoxChange(searchText: any) {
        this.searchParams[this.searchByValue] = searchText
        this.isListFiltered = (searchText && searchText.length > 0) ? true : false;
        this.pageAttributes.pageNumber = 1;
        this.pageAttributesChange.next()
    }

    setupTableHeaders() {
        switch (this.cardType) {
            case DBCCardTypes.MY_CARDS:
                this.tableData = {
                    header: [{
                        text: '',
                    }, {
                        text: 'Name',
                        mobile: true,
                        sort: true
                    }, {
                        text: 'Labels',
                    }, {
                        text: 'Saves',
                        sort: true
                    }, {
                        text: 'Views',
                        sort: true
                    }],
                    data: [],
                }
                break;
            case DBCCardTypes.TEAM_CARDS:
                this.tableData = {
                    header: [{
                        text: '',
                    }, {
                        text: 'Name',
                        mobile: true,
                        sort: true
                    }, {
                        text: 'Owner',
                    }, {
                        text: 'Labels',
                    }, {
                        text: 'Views',
                        sort: true
                    }],
                    data: []
                }
                break;
        }

    }


    private getDigitalBusinessCardList() {
        const user = this.authService.getUser();
        const maintainerQuery = this.cardType === DBCCardTypes.MY_CARDS ? ('&card_owner__is=' + user.id) : '';
        const queryString = this.queryParams?.filters ? maintainerQuery + '&filters=' + this.queryParams.filters : maintainerQuery;
        this.digitalBusinessCardService
            .getList(
                this.pageAttributes.pageNumber,
                this.pageAttributes.pageSize, this.searchParams, true, this.pageAttributes.ordering, null,
                `&state=${PRODUCT_STATE.ACTIVE}` + queryString).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.digitalBusinessCards = res
                this.totalResults = res.totalCount
                // this.fetchDbcViews()
                // this.fetchDbcDownloads()
                this.setupTableData();
                this.resetBulkOperations();
                this.checkIfLinkpageCreatedOrUpdated()
                this.updateUrlWithNewFilters()
                this.overlayService.isLoading(false);
            }, error => {
                this.overlayService.isLoading(false)
                this.messageModal.show('Error fetching digital business cards', 'danger')
            })

    }

    updateUrlWithNewFilters() {
        if (!this.queryParams?.filters) {
            return;
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { filters: this.queryParams.filters },
            queryParamsHandling: 'merge'
        });
    }

    // TODO: We can remove this function in future as views will be coming as views in DBC model itself
    // @ts-ignore
    private fetchDbcViews() {
        const cardsID = []
        for (const digitalBusinessCard of this.digitalBusinessCards.objects) {
            cardsID.push(digitalBusinessCard.id)
        }
        const date = moment().format('x');
        const options = {
            from: 1,
            to: date,
            timezone: this.authService.getUser().timezone,
            product_ids: cardsID,
            organization_ids: [this.authService.getCurrentOrgId()],
            interval: '1d',
            card_type: this.cardType === DBCCardTypes.MY_CARDS ? 0 : -1,
            card_owner: this.authService.getUser().id
        };
        this.analyticsService.getAnalytics('Dbc.getMultiProductViewsV3', options, 'dbc', this.authService.getCurrentOrgId())
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.setViewsForDigitalBusinessCard(res)
                this.setupTableData();
            }, error => {
            });

    }

    private setViewsForDigitalBusinessCard(res) {
        const analyticsDataMap = res['columns'].reduce(function (hashmap, id, scanIndex) {
            const points = res['points'][scanIndex];
            hashmap[id] = points;
            return hashmap;
        }, {});

        for (const dbc of this.digitalBusinessCards.objects) {
            if (dbc.id in analyticsDataMap) {
                const data = analyticsDataMap[dbc.id]
                dbc.__views__ = data
                if (this.selectedDigitalBusinessCard && this.selectedDigitalBusinessCard.id === dbc.id) {
                    this.selectedDigitalBusinessCard.__views__ = data
                }
            }
        }
    }

    // TODO: We can remove this function in future as downloads will be coming as saves in DBC model itself
    // @ts-ignore
    private fetchDbcDownloads() {
        const cardsID = []
        for (const digitalBusinessCard of this.digitalBusinessCards.objects) {
            cardsID.push(digitalBusinessCard.id)
        }
        const date = moment().format('x');
        const options = {
            from: 1,
            to: date,
            timezone: this.authService.getUser().timezone,
            product_ids: cardsID,
            organization_ids: [this.authService.getCurrentOrgId()],
            interval: '1d',
            card_type: this.cardType === DBCCardTypes.MY_CARDS ? 0 : 1,
            card_owner: this.authService.getUser().id
        };
        this.analyticsService.getAnalytics('Dbc.getMultiProductDownloadsV3', options, 'dbc', this.authService.getCurrentOrgId())
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.setDownloadsForDigitalBusinessCard(res)
                this.setupTableData();
            }, error => {
                console.log(error);
            });

    }

    private setDownloadsForDigitalBusinessCard(res) {
        const analyticsDataMap = res['columns'].reduce(function (hashmap, id, scanIndex) {
            const points = res['points'][scanIndex];
            hashmap[id] = points;
            return hashmap;
        }, {});

        for (const dbc of this.digitalBusinessCards.objects) {
            if (dbc.id in analyticsDataMap) {
                const data = analyticsDataMap[dbc.id]
                dbc.__downloads__ = data
                if (this.selectedDigitalBusinessCard && this.selectedDigitalBusinessCard.id === dbc.id) {
                    this.selectedDigitalBusinessCard.__downloads__ = data
                }
            }
        }
    }

    private setupTableData() {
        this.setupTableHeaders();
        this.tableData['allSelectCheckbox'] = { checked: false, disabled: false };
        for (const digitalBusinessCard of this.digitalBusinessCards.objects) {
            let preview = `${environment.imagesEndpoint}vcard_plus/default_vcard_image.png`
            if (digitalBusinessCard.user_image_url) {
                preview = digitalBusinessCard.user_image_url
            }
            const tableObject = {
                preview: preview,
                dataItems: this.getDataItems(digitalBusinessCard),
                dataObject: digitalBusinessCard,
                checkboxItem: { checked: false, disabled: false },
                toolbarIcons: this.getToolbarIcons(digitalBusinessCard),
                threatActive: digitalBusinessCard.threat_active
            };
            this.tableData.data.push(tableObject)
        }
        this.overlayService.isLoading(false);

    }

    generateTagsArray(digitalBusinessCard: any) {
        const arrayOfLabels = [];
        if (!digitalBusinessCard.tags.length) {
            return arrayOfLabels;
        }
        for (const tag of digitalBusinessCard.tags_data) {
            const label = {
                text: tag['name'].length > 10 ? tag['name'].substring(0, 10) + '...' : tag['name'],
                tooltipContent: tag.name,
                tooltipEnabled: tag.name.length > 10 ? true : false,
                background: tag['color'],
                textColor: '#FFFFFF',
            };
            arrayOfLabels.push(label);
        }
        return arrayOfLabels;
    }

    private getDataItems(digitalBusinessCard: DigitalBusinessCard) {
        const digitalBusinessCardName = digitalBusinessCard.name;
        switch (this.cardType) {
            case DBCCardTypes.MY_CARDS:
                return {
                    name: {
                        type: 'single',
                        mobile: true,
                        class: 'title',
                        value: {
                            text: digitalBusinessCardName
                        },
                        values: []
                    },
                    labels: {
                        type: 'multiple',
                        showAddButtonOnHover: true,
                        buttonText: 'Add Label',
                        value: {
                            text: 'label',
                        },
                        values: this.generateTagsArray(digitalBusinessCard)
                    },
                    'saves': {
                        type: 'single',
                        value: {
                            text: digitalBusinessCard.saves
                        },
                        values: []
                    },
                    views: {
                        type: 'single',
                        value: {
                            text: digitalBusinessCard.views
                        },
                        values: []
                    }
                }
            case DBCCardTypes.TEAM_CARDS:
                const data = {
                    name: {
                        type: 'single',
                        mobile: true,
                        class: 'title',
                        value: {
                            text: digitalBusinessCardName
                        },
                        values: []
                    },
                    owner: {
                        type: 'single',
                        value: {
                            text: digitalBusinessCard.card_owner['email'] ? digitalBusinessCard.card_owner['email'] : ''
                        },
                        values: []
                    },
                    labels: {
                        type: 'multiple',
                        showAddButtonOnHover: true,
                        buttonText: 'Add Label',
                        value: {},
                        values: this.generateTagsArray(digitalBusinessCard)
                    },
                    views: {
                        type: 'single',
                        value: {
                            text: digitalBusinessCard.views
                        },
                        values: []
                    }
                }

                if (digitalBusinessCard.card_owner?.earned) {
                    data.owner['iconClass'] = 'fas fa-gift'
                    data.owner['iconBackColor'] = '#F4F5F7'
                    data.owner['contentType'] = 'text/icon'
                    data.owner['tooltipColor'] = '#51AAFF'
                    data.owner['tooltipContent'] = 'Claimed user'
                }
                return data;
        }
    }

    private getToolbarIcons(digitalBusinessCard: DigitalBusinessCard) {
        return {
            desktop: this.getDesktopIconsForToolbar(digitalBusinessCard),
            mobile: this.getMobileIconsForToolbar(digitalBusinessCard)
        }
    }

    private getDesktopIconsForToolbar(digitalBusinessCard: DigitalBusinessCard) {
        let toolBarOptions = []
        if (this.hasWriteAccess) {
            toolBarOptions = [{
                class: 'fas fa-edit',
                color: '#2595FF',
                tooltip: 'Edit',
                event: {
                    type: 'link',
                    operation: 'edit',
                    output: 'function()'
                }
            }]
        }
        switch (this.cardType) {
            case DBCCardTypes.MY_CARDS:
                return toolBarOptions.concat([
                    {
                        class: 'fas fa-share-alt',
                        color: '#2595FF',
                        tooltip: 'Share',
                        event: {
                            type: 'link',
                            operation: 'share',
                            output: 'function()'
                        }
                    }, {
                        class: 'fas fa-external-link',
                        color: '#2595FF',
                        tooltip: 'Link',
                        event: {
                            type: 'link',
                            operation: 'preview',
                            output: 'function()'
                        }
                    }, {
                        class: 'fas fa-info-circle',
                        color: '#2595FF',
                        tooltip: 'Information',
                        event: {
                            type: 'link',
                            operation: 'modal-dbc-details',
                            output: 'function()'
                        }
                    }, {
                        class: 'fas fa-ellipsis-v',
                        color: '#5E6980',
                        event: {
                            type: 'dropdown',
                            content: this.getRoleBasedDropdownItems(digitalBusinessCard)
                        }
                    },
                ]);
            case DBCCardTypes.TEAM_CARDS:
                return toolBarOptions.concat([{
                    class: 'fas fa-share-alt',
                    color: '#2595FF',
                    tooltip: 'Share',
                    event: {
                        type: 'link',
                        operation: 'share',
                        output: 'function()'
                    }
                }, {
                    class: 'fas fa-external-link',
                    color: '#2595FF',
                    tooltip: 'Preview',
                    event: {
                        type: 'link',
                        operation: 'preview',
                        output: 'function()'
                    }
                }, {
                    class: 'fas fa-info-circle',
                    color: '#2595FF',
                    tooltip: 'Information',
                    event: {
                        type: 'link',
                        operation: 'modal-dbc-details',
                        output: 'function()'
                    }
                }, {
                    class: 'fas fa-ellipsis-v',
                    color: '#5E6980',
                    event: {
                        type: 'dropdown',
                        content: this.getRoleBasedDropdownItems(digitalBusinessCard)
                    }
                },
                ]);
        }
    }

    private getMobileIconsForToolbar(digitalBusinessCard: DigitalBusinessCard) {
        return [
            {
                class: 'fas fa-share-alt',
                color: '#5E6980',
                tooltip: 'Info',
                event: {
                    type: 'link',
                    operation: 'modal',
                    output: 'function()'
                }
            },
            {
                class: 'fas fa-ellipsis-v',
                color: '#5E6980',
                tooltip: '.',
                event: {
                    type: 'link',
                    operation: 'more-options',
                    output: 'function()'
                },
            }]
    }
    private getRoleBasedDropdownItems(digitalBusinessCard: DigitalBusinessCard) {
        if (!this.hasWriteAccess) {
            return [ { name: 'View Analytics', value: 'analytics' }, { name: 'Share Wallet Pass', value: 'Share wallet pass' } ];
        }
        const user = this.authService.getUser();
        const orgId = this.authService.getCurrentOrgId();

        let operations: any = [{ name: 'View Analytics', value: 'analytics' },
            { name: 'Share Wallet Pass', value: 'Share wallet pass' },
            { name: 'Duplicate Card', value: 'Duplicate' },
            { name: 'Change Owner', value: 'Change owner' },
            { name: 'Change Organization', value: 'Change organization' }];

        if ( digitalBusinessCard && !digitalBusinessCard.meta['created_via_ad'] ) {
            operations.push({ name: 'Delete Card', value: 'Delete', overrideColor: '#FF445E' });
        }

        switch (this.cardType) {
            case DBCCardTypes.MY_CARDS:
                if (user.isSuperAdmin()) {
                    return operations
                } else if (user.isAdmin(orgId)) {
                    operations = operations.filter(res => res.value !== 'Change organization');
                } else if (user.isEditor(orgId)) {
                    operations = operations.filter(res => res.value !== 'Change organization' && res.value !== 'Change owner');
                }
                break;
            case DBCCardTypes.TEAM_CARDS:
                if (user.isSuperAdmin()) {
                    return operations
                } else if (user.isAdmin(orgId)) {
                    operations = operations.filter(res => res.value !== 'Change organization');
                }
                break;
            default:
                break;
        }

        return operations;
    }

    setActionsItems(digitalBusinessCard: DigitalBusinessCard) {
        if (!this.hasWriteAccess) {
            this.infoModalActionsOptions = [{ name: 'View Analytics', value: 'analytics' }, { name: 'Share Wallet Wass', value: 'Share wallet pass' }];
            return;
        }
        const actionItems = [{ name: 'Edit Card', value: 'edit' }].concat(this.getRoleBasedDropdownItems(digitalBusinessCard));
        this.infoModalActionsOptions = actionItems;
    }

    setMobileActionsItems(digitalBusinessCard: DigitalBusinessCard) {
        this.mobileActionsOptions = this.getRoleBasedDropdownItems(digitalBusinessCard);

        if (this.hasWriteAccess) {
            const options = [{name: 'Edit Card', value: 'edit'}]
            options.push({name: 'Preview Card', value: 'preview'})
            this.mobileActionsOptions = options.concat(this.mobileActionsOptions);
        }
    }

    navigateToAnalytics(event: any) {
        const cardRoute = event.card_owner['id'] === this.authService.getUser().id ? 'my-cards' : 'team-cards';
        this.router.navigate([`digital-business-cards/${cardRoute}/summary/${event.id}`]);
    }

    handleToolBarClick(event, source) {
        switch (source) {
            case 'toolbar':
                switch (event.operation.toLowerCase()) {
                    case TOOLBAR_OPTIONS.SHARE:
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.sideModalOnInfoClick.show();
                        this.loadPreview();
                        this.generateQrCode(GENERATE_QRCODE_TYPE.DBC);
                        break;
                    case TOOLBAR_OPTIONS.MODAL_DBC_PREVIEW:
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.showPreviewFrame = true;
                        this.showDetails = false;
                        this.sideModalOnInfoClick.show();
                        this.loadPreview();
                        this.generateQrCode(GENERATE_QRCODE_TYPE.DBC);
                        this.toolbarOptionsEventToAmplitude(TOOLBAR_OPTIONS_EVENT.PREVIEW)
                        break;
                    case TOOLBAR_OPTIONS.MODAL_DBC_DETAILS:
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.showPreviewFrame = true;
                        this.showDetails = true;
                        this.sideModalOnInfoClick.show();
                        this.loadPreview();
                        this.generateQrCode(GENERATE_QRCODE_TYPE.DBC);
                        this.toolbarOptionsEventToAmplitude(TOOLBAR_OPTIONS_EVENT.INFORMATION);
                        break;
                    case TOOLBAR_OPTIONS.INFO:
                    case TOOLBAR_OPTIONS.MODAL:
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.generateQrCode(GENERATE_QRCODE_TYPE.DBC);
                        if (this.isMobileView === true) {
                            this.current_mobile_screen = DOWNLOAD_SCREENS.SHARE_SCREEN;
                            this.isDownloadsDrawerOpen = true;
                        } else {
                            this.loadPreview();
                            this.sideModalOnInfoClick.show();
                        }
                        break
                    case TOOLBAR_OPTIONS.EDIT:
                        this.navigateToEdit(event.dataObject)
                        break
                    case TOOLBAR_OPTIONS.PREVIEW:
                        this.redirectToPreview(event.dataObject)
                        break
                    case TOOLBAR_OPTIONS.ANALYTICS:
                        this.navigateToAnalytics(event.dataObject)
                        break
                    case TOOLBAR_OPTIONS.MORE_OPTIONS:
                        this.ismoreoptionDrawerOpen = true;
                        this.current_more_option_screen = MORE_OPTIONS_SCREEN.MORE_OPTIONS;
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.setMobileActionsItems(this.selectedDigitalBusinessCard);
                        break
                }
                break
            case 'dropdown':
                switch (event.operation.value.toLowerCase()) {
                    case 'info':
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.sideModalOnInfoClick.show();
                        this.loadPreview();
                        break
                    case 'edit':
                        this.navigateToEdit(event.dataObject)
                        break
                    case 'delete':
                        this.openDeleteCardModal(event.dataObject);
                        break
                    case 'rename':
                        break;
                    case 'duplicate':
                        this.duplicateCard(event.dataObject);
                        break;
                    case 'share wallet pass':
                        this.selectedCards = [];
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.shareSelectedWalletPass(this.selectedDigitalBusinessCard);
                        this.logCardShareEvent(SHARE_CARD_EVENTS.DIRECT_SHARE_WALLET_PASS);
                        break;
                    case 'preview':
                        this.redirectToPreview(event.dataObject)
                        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.DIRECT_PREVIEW, {source : 'dropdown_options'});
                        break;
                    case 'analytics':
                        this.navigateToAnalytics(event.dataObject)
                        break;
                    case 'change owner':
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.changeMaintainer.show();
                        break;
                    case 'change organization':
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.getOrganizationList(this.selectedDigitalBusinessCard);
                        this.changeOrganization.show();
                        break;
                    case 'mark as template':
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.setIsTemplate(this.selectedDigitalBusinessCard, true);
                        break;
                    case 'unmark as template':
                        this.selectedDigitalBusinessCard = new DigitalBusinessCard(event.dataObject)
                        this.setIsTemplate(this.selectedDigitalBusinessCard, false);
                        break;
                    case 'label':
                        this.applyLabelOnSelectedCard({value: true, dataObject: event.dataObject})
                }
                break
            case 'side-modal':
                switch (event.value.toLowerCase()) {
                    case 'edit':
                        this.navigateToEdit(this.selectedDigitalBusinessCard)
                        break
                    case 'duplicate':
                        this.duplicateCard(this.selectedDigitalBusinessCard);
                        break;
                    case 'share wallet pass':
                        this.shareSelectedWalletPass(this.selectedDigitalBusinessCard);
                        break;
                    case 'change owner':
                        this.changeMaintainer.show();
                        break;
                    case 'change organization':
                        this.getOrganizationList(this.selectedDigitalBusinessCard);
                        this.changeOrganization.show();
                        break;
                    case 'mark as template':
                        this.setIsTemplate(this.selectedDigitalBusinessCard, true);
                        break;
                    case 'unmark as template':
                        this.setIsTemplate(this.selectedDigitalBusinessCard, false);
                        break;
                    case 'delete':
                        this.openDeleteCardModal(this.selectedDigitalBusinessCard);
                        break;
                    case 'analytics':
                        this.navigateToAnalytics(this.selectedDigitalBusinessCard);
                        break;
                }
        }
    }

    redirectToPreview(selectedDigitalBusinessCard: DigitalBusinessCard) {
        window.open(selectedDigitalBusinessCard.url + '?preview=true', '_blank');

    }
    toolbarOptionsEventToAmplitude(source: TOOLBAR_OPTIONS_EVENT) {
        const eventProperties = {
            source: source
        }
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.TOOLBAR_OPTIONS, eventProperties);
    }
    logCardShareEvent(source: SHARE_CARD_EVENTS, type ? ) {
        const eventProperties = {
            source: source,
            ...(type) && {type: type}
        }
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.SHARE_CARD, eventProperties);
        this.authService.launchDarklyAddCustomMetrics('share card', eventProperties, 1);
    }

    copyText(content) {
        window.navigator.clipboard.writeText(content || '')
        this.logCardShareEvent(SHARE_CARD_EVENTS.COPY_URL)
        this.messageModal.show('Copied to clipboard', TOAST_TYPES.SUCCESS)
    }


    openAppleWalletPassModal() {
        this.isDownloadsDrawerOpen = false;
        this.overlayService.isLoading(true);
        this.logCardShareEvent(SHARE_CARD_EVENTS.APPLE_WALLET);
        this.appleWalletPassCustomization = this.setDefaultAWPCustomizationValues(this.currOrgWPTemplate);
        this.generateAppleWalletPass();
    }

    openGoogleWalletPassModal() {
        this.isDownloadsDrawerOpen = false;
        this.overlayService.isLoading(true);
        this.logCardShareEvent(SHARE_CARD_EVENTS.GOOGLE_WALLET);
        this.generateGoogleWalletPass()
    }

    getLaunchDarkleyFlags() {
        Object.keys(this.launchDarkleyFlags).forEach(async key => {
            this.launchDarkleyFlags[key] = await this.authService.getFeatureFlag(key, this.launchDarkleyFlags[key])
        });

    }

    updatePreviewConfigurations() {
        /**
         Applies additional checks and updates the product object for preview mode,
         such as configuring visibility of UI elements based on feature flags and the current step.
         **/
        this.selectedDigitalBusinessCard.__show_new_sticky_btn__ = this.launchDarkleyFlags[FEATURE_FLAGS.DBC_NEW_LEAD_CAPTURE_FORM];
    }

    loadPreview(maxAttempt: number = 10) {
        this.updatePreviewConfigurations()
        let preview;

        if (this.isMobileView) {
            if (this.isPreviewModalopen) {
                preview = this.dbcMobileTwoPreview;
            } else {
                preview = this.dbcMobileOnePreview;
            }
        } else {
            preview = this.dbcPreview;
        }

        if (!preview && maxAttempt > 0) {
            setTimeout(() => {
                this.loadPreview(maxAttempt - 1);
            }, 100);
            return;
        }
        const iframe = preview;
        const iframedoc = iframe.nativeElement.contentWindow || iframe.nativeElement.contentDocument.document ||
            iframe.nativeElement.contentDocument;
        iframedoc.document.open();

        const isMultiLanguageContent = this.selectedDigitalBusinessCard.default_language ? true : false;
        const multiLangList: Array<string> = Object.keys(this.selectedDigitalBusinessCard.language_data);
        if (isMultiLanguageContent) {
            iframedoc.document.write(DigitalBusinessCard.getVcardPreviewHTML(this.selectedDigitalBusinessCard, false, true, multiLangList, this.selectedDigitalBusinessCard.default_language));
        } else {
            iframedoc.document.write(DigitalBusinessCard.getVcardPreviewHTML(this.selectedDigitalBusinessCard, false));
        }

        iframedoc.document.close();


    }

    generateQrCode(type: GENERATE_QRCODE_TYPE) {
        let url = '';
        switch (type) {
            case GENERATE_QRCODE_TYPE.APPLE_WALLET:
                url = this.appleWalletPassLink;
                break;
            case GENERATE_QRCODE_TYPE.GOOGLE_WALLET:
                url = this.googleWalletPassLink;
                break;
            case GENERATE_QRCODE_TYPE.DBC:
                url = this.selectedDigitalBusinessCard.url;
                break;
            default:
                break;
        }

        const {
            // @ts-ignore
            logoImage, logoHeight, backgroundColor, colorDark, colorLight, gradientType, frameStyle, frameText,
            // @ts-ignore
            logoBackground, backgroundImage, logoMargin, logoScale, rectangular, margin, eyeFrameColor, eyeBallColor,
            // @ts-ignore
            eyeFrameShape, eyeBallShape, dataPattern, frameTextColor, frameColor, logoWidth } = this.selectedDigitalBusinessCard.template || {};

        const allAttributes = {
            logoImage,
            logoMargin,
            logoScale,
            rectangular,
            logoWidth,
            logoHeight,
            logoBackground,
            backgroundImage,
            backgroundColor,
            colorDark,
            colorLight,
            gradientType,
            frameStyle,
            frameText,
            frameColor,
            frameTextColor,
            dataPattern,
            eyeBallShape,
            eyeFrameShape,
            eyeBallColor,
            eyeFrameColor,
        }
        for (const key in allAttributes) {
            if (!allAttributes[key]) {
                delete allAttributes[key]
            }
        }

        const qRCodeGenerator = new QRCodeBuilder({
            ...allAttributes,
            text: url,
            margin: margin || 20,
            dotScale: 1,
            size: 480,
            canvasType: CanvasType.SVG,
            correctLevel: QRErrorCorrectLevel.H,
            imageServerURL: this.externalURLService.URL,
            imageServerRequestHeaders: {
                authorization: `${this.authService.getTokenType()} ${this.authService.getToken()}`
            }
        });
        qRCodeGenerator
            .build(CanvasType.PNG)
            .then((canvas) => {
                if (type === GENERATE_QRCODE_TYPE.DBC) {
                    this.generatedDBCQRcode = canvas.svg;
                } else {
                    svgToPng(canvas.svg)
                        .then((qrCode) => {
                            this.generatedWalletQRcode = qrCode as string;
                        }).catch(console.error);
                }
            })
            .catch((err) => {
                console.error(err)
                this.messageModal.show(
                    'Failed to generate QR code',
                    TOAST_TYPES.DANGER
                );
            });
    }

    updateAppleWalletPassQRCode() {
        const backgroundColor = this.appleWalletPassCustomization.backgroundColor.slice(1);
        const textColor = this.appleWalletPassCustomization.textColor.slice(1);
        const logoId = this.appleWalletPassCustomization.logo.id;
        const data = {
            'walletPassType': WALLET_PASS.APPLE_WALLET,
            'backgroundColor': backgroundColor,
            'textColor': textColor,
            'logo_id': logoId ? logoId : ''
        }
        this.walletPassPreviewColorSelect.next(data);
    }

    updateGoogleWalletPassQRCode() {
        const backgroundColor = this.googleWalletPassPreviewColor.backgroundColor.slice(1);
        const textColor = this.getGoogleWalletPassTextColor(backgroundColor).slice(1);
        const dbc_url = this.dbc_url
        if (this.googleWalletPassPreviewLogoId.logo_id !== null || this.googleWalletPassPreviewHeroImageId.hero_image_id !== null) {
            const logo = this.googleWalletPassPreviewLogoId.logo_id;
            const heroImage = this.googleWalletPassPreviewHeroImageId.hero_image_id;
            const data = {
                'walletPassType': WALLET_PASS.GOOGLE_WALLET,
                'backgroundColor': backgroundColor,
                'textColor': textColor,
                ...(logo !== null && { 'logo_url': logo }),
                ...(heroImage !== null && { 'hero_image': heroImage }),
                ...(dbc_url === false && { 'dbc_url': dbc_url })
            };
            this.walletPassPreviewColorSelect.next(data);

        } else {
            const data = {
                'walletPassType': WALLET_PASS.GOOGLE_WALLET,
                'backgroundColor': backgroundColor,
                'textColor': textColor,
                'dbc_url': dbc_url,
            };
            this.walletPassPreviewColorSelect.next(data);
        }
    }

    getGoogleWalletPassTextColor(bgColor) {
        const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        const r = parseInt(color.substring(0, 2), 16); // hexToR
        const g = parseInt(color.substring(2, 4), 16); // hexToG
        const b = parseInt(color.substring(4, 6), 16); // hexToB
        return this.googleWalletPassPreviewColor.textColor = (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? '#000000' : '#ffffff';
    }

    resetWalletPassPreviewColor() {
        if (this.isDefaultWalletPassCustomizationSet) {
            return;
        }
        this.googleWalletPassPreviewColor = {
            backgroundColor: '#' + this.currOrgWPTemplate.google_wallet_pass_background,
            textColor: this.getGoogleWalletPassTextColor(this.googleWalletPassPreviewColor.backgroundColor)
        }
        this.googleWalletPassPreviewLogo = {
            logo_url: this.currOrgWPTemplate.google_wallet_pass_logo
        }
        this.googleWalletPassPreviewHeroImage = {
            hero_image_url: this.currOrgWPTemplate.google_wallet_pass_hero_image

        }
        this.googleWalletPassPreviewLogoId = {
            logo_id: this.currOrgWPTemplate.google_wallet_pass_logo_id
        }
        this.googleWalletPassPreviewHeroImageId = {
            hero_image_id: this.currOrgWPTemplate.google_wallet_pass_hero_image_id
        }
        this.dbc_url = true;
    }
    toggleColorPicker(id: string) {
        document.getElementById(id).click();
    }

    handleWalletPassEmail(event: WALLET_PASS) {
        if (event === WALLET_PASS.APPLE_WALLET) {
            this.appleWalletEmailModel.isEmailValid = false;
            this.emailChangeSubject.next({
                email: this.appleWalletEmailModel.email,
                fromWalletPass: WALLET_PASS.APPLE_WALLET,
            });
        } else {
            this.googleWalletEmailModel.isEmailValid = false;
            this.emailChangeSubject.next({
                email: this.googleWalletEmailModel.email,
                fromWalletPass: WALLET_PASS.GOOGLE_WALLET,
            });
        }
    }

    checkEmailValidity(event) {
        if (event.fromWalletPass === WALLET_PASS.APPLE_WALLET) {
            this.appleWalletEmailModel.isEmailValid = Utils.emailRegex.test(event.email);
        } else {
            this.googleWalletEmailModel.isEmailValid = Utils.emailRegex.test(event.email);
        }
    }

    sendAppleWalletLink() {
        if (this.appleWalletEmailModel.email) {
            const postBody = {
                emails: [this.appleWalletEmailModel.email],
                background: this.appleWalletPassCustomization.backgroundColor.slice(1),
                foreground: this.appleWalletPassCustomization.textColor.slice(1)
            };
            const vcardID = this.selectedDigitalBusinessCard.id;
            this.digitalBusinessCardService.post(postBody, `${vcardID}/walletpass/?organization=${this.authService.getCurrentOrgId()}`).pipe(
                takeUntil(this.ngUnsubscribe)).subscribe(
                response => {
                    if (response.detail) {
                        this.messageModal.show(`Email sent to ${this.appleWalletEmailModel.email}`, TOAST_TYPES.SUCCESS);
                        this.appleWalletPassModal.hide();
                        this.appleWalletEmailModel.email = '';
                        this.appleWalletEmailModel.isEmailValid = false;
                        this.resetWalletPassPreviewColor();
                    } else {
                        this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                    }
                },
                error => {
                    this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                }
            )
        }
    }

    sendGoogleWalletLink() {
        if (this.googleWalletEmailModel.email) {
            const postBody = {
                emails: [this.googleWalletEmailModel.email],
                background: this.googleWalletPassPreviewColor.backgroundColor.slice(1),
                foreground: this.googleWalletPassPreviewColor.textColor.slice(1),
                logourl: this.googleWalletPassPreviewLogoId.logo_id,
                heroimageurl: this.googleWalletPassPreviewHeroImageId.hero_image_id,
                dbc_url: this.dbc_url
            };
            const vcardID = this.selectedDigitalBusinessCard.id;
            this.digitalBusinessCardService.post(postBody, `${vcardID}/walletpass/?organization=${this.authService.getCurrentOrgId()}`).pipe(
                takeUntil(this.ngUnsubscribe)).subscribe(
                response => {
                    if (response.detail) {
                        this.messageModal.show(`Email sent to ${this.googleWalletEmailModel.email}`, TOAST_TYPES.SUCCESS);
                        if (this.isMobileScreen) {
                            this.isGoogleWalletPassModalOpen = false;
                        } else {
                            this.googleWalletPassModal.hide();
                        }
                        this.isGoogleWalletPassModalOpen = false;
                        this.googleWalletEmailModel.email = '';
                        this.googleWalletEmailModel.isEmailValid = false;
                        this.resetWalletPassPreviewColor();
                    } else {
                        this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                    }
                },
                error => {
                    this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                }
            )
        }
    }

    generateAppleWalletPass() {
        const vcardId = this.selectedDigitalBusinessCard.id;
        const backgroundColor = this.appleWalletPassCustomization.backgroundColor.slice(1);
        const textColor = this.appleWalletPassCustomization.textColor.slice(1);
        const logoFragment = this.getLogoFragment();

        this.sideModalOnInfoClick.hide();

        this.digitalBusinessCardService.getData(vcardId, `walletpass/?organization=${this.authService.getCurrentOrgId()}`).pipe(
            takeUntil(this.ngUnsubscribe)).subscribe(
            response => {
                if (response['detail']) {
                    this.appleWalletPassBaseLink = response['detail']['apple_wallet_pass_url'];
                    this.appleWalletPassLink = `${response['detail']['apple_wallet_pass_url']}?background=${backgroundColor}&foreground=${textColor}${logoFragment}`;
                    this.generateQrCode(GENERATE_QRCODE_TYPE.APPLE_WALLET);
                    this.appleWalletEmailModel.email = this.selectedDigitalBusinessCard.card_owner.email || this.selectedDigitalBusinessCard.meta['creator_email'];
                    this.sideModalOnInfoClick.hide();
                    this.isMobileScreen ? (this.isAWPModalOpen = true) : this.appleWalletPassModal.show();

                    this.amplitude.logEvent(
                        AMPLITUDE_EVENT_CATEGORIES.Usage,
                        AMPLITUDE_EVENTS.DOWNLOAD_WALLET_PASS,
                        {
                            type: 'apple'
                        }
                    );
                    this.authService.addDatadogAction(DATADOG_ACTIONS.DOWNLOAD_APPLE_WALLET_PASS_DBC);
                } else {
                    this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                }
                this.overlayService.isLoading(false);
            },
            error => {
                this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                this.overlayService.isLoading(false);
            }
        )
    }

    getLogoFragment() {
        let logoFragment = '';
        if (this.appleWalletPassCustomization.logo.id) {
            logoFragment = '&logoId=' + this.appleWalletPassCustomization.logo.id;
        } else if (this.appleWalletPassCustomization.logo.url === this.selectedDigitalBusinessCard?.logo_url) {
            logoFragment = '&showDBCLogo=True'
        }
        return logoFragment;
    }

    generateGoogleWalletPass() {
        const vcardId = this.selectedDigitalBusinessCard.id;
        const backgroundColor = this.googleWalletPassPreviewColor.backgroundColor.slice(1);
        const textColor = this.getGoogleWalletPassTextColor(backgroundColor).slice(1);
        const logo = this.googleWalletPassPreviewLogoId.logo_id


        const heroImage = this.googleWalletPassPreviewHeroImageId.hero_image_id;

        this.digitalBusinessCardService.getData(vcardId, `walletpass/?organization=${this.authService.getCurrentOrgId()}`).pipe(
            takeUntil(this.ngUnsubscribe)).subscribe(
            response => {
                if (response['detail']) {
                    this.googleWalletPassBaseLink = response['detail']['google_wallet_pass_url'];
                    this.googleWalletPassLink = `${response['detail']['google_wallet_pass_url']}?background=${backgroundColor}&foreground=${textColor}`;
                    if (logo !== null) {
                        this.googleWalletPassLink += `&logourl=${logo}`;
                    }
                    if (heroImage !== null) {
                        this.googleWalletPassLink += `&heroimageurl=${heroImage}`;
                    }
                    this.generateQrCode(GENERATE_QRCODE_TYPE.GOOGLE_WALLET);
                    this.googleWalletEmailModel.email = this.selectedDigitalBusinessCard.card_owner.email || this.selectedDigitalBusinessCard.meta['creator_email'];
                    this.sideModalOnInfoClick.hide();
                    if (!this.isMobileScreen) {
                        this.googleWalletPassModal.show();
                    } else {
                        this.isGoogleWalletPassModalOpen = true;
                    }
                    this.amplitude.logEvent(
                        AMPLITUDE_EVENT_CATEGORIES.Usage,
                        AMPLITUDE_EVENTS.DOWNLOAD_WALLET_PASS,
                        {
                            type: 'google'
                        }
                    );
                    this.authService.addDatadogAction(DATADOG_ACTIONS.DOWNLOAD_GOOGLE_WALLET_PASS_DBC);
                } else {
                    this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                }
                this.overlayService.isLoading(false);
            },
            error => {
                this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER);
                this.messageModal.show(error, TOAST_TYPES.DANGER);
                this.overlayService.isLoading(false);
            }
        )
    }

    resetBulkOperations() {
        this.tableData['allSelectCheckbox'] = { checked: false, disabled: false };
        for (let index = 0; index < this.digitalBusinessCards.objects.length; index++) {
            this.tableData.data[index].checkboxItem = { checked: false, disabled: false };
        }
        this.isAllCardsSelected = false;
        this.selectedCards = [];

        setTimeout(() => {
            if (this.searchParams[this.searchByValue]) {
                document.getElementById('beaconstac-search-')['value'] = this.searchParams[this.searchByValue]
            }
        }, 500)

    }

    addSelectedCardsToList() {
        if (this.isAllCardsSelected) {
            this.tableData['allSelectCheckbox'] = { checked: false, disabled: false };
            this.applyLabelOperationType = APPLY_LABEL_OPERATION.FILTERED_PRODUCTS
        }
        for (let index = 0; index < this.digitalBusinessCards.objects.length; index++) {
            this.tableData.data[index].checkboxItem = { checked: true, disabled: false };
        }
        this.selectedCards = [];
        for (const element of this.digitalBusinessCards.objects) {
            this.selectedCards.push(element.id);
        }
    }

    setSelectedBulkOperation(event) {
        if (this.selectedCards.length > 0 && !this.isAllCardsSelected) {
            this.applyLabelOperationType = APPLY_LABEL_OPERATION.LIST_PRODUCTS
            this.selectedCardsForTag = this.selectedCards;
            this.bulkOperationsMessage = `You can only download  <b>${this.selectedCards.length}</b> Cards at a time${this.selectedCards.length > 1 ? 's' : ''}`;

        } else {
            this.applyLabelOperationType = APPLY_LABEL_OPERATION.FILTERED_PRODUCTS
            this.selectedCardsForTag = [];
            this.bulkOperationsMessage = `You can only download <b>${this.digitalBusinessCards.totalCount}</b> Cards at a time${this.digitalBusinessCards.totalCount > 1 ? 's' : ''}`;

        }

        if (event === CARD_BULK_OPERATIONS.LABEL) {
            this.tagUntagModal.show(this.selectedCardsForTag, this.isAllCardsSelected);
        } else if (event === CARD_BULK_OPERATIONS.WALLET_PASS) {
            this.confirmShareWalletPassModal.show();
        } else if (event === CARD_BULK_OPERATIONS.DELETE) {
            if (this.isMobileScreen) {
                this.ismoreoptionDrawerOpen = true;
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.BULK_DELETE;
            } else {
                this.confirmBulkDeletePermanentlyModal.show();
            }
        } else if (event === CARD_BULK_OPERATIONS.BULK_DOWNLOAD) {
            this.dbcBulkDownloadModal.show();
        }
    }


    checkboxEventHandler(event) {
        const card: DigitalBusinessCard = event.dataObject;
        const cardList = this.digitalBusinessCards.objects;
        let cardIndex = 0;

        for (let index = 0; index < cardList.length; index++) {
            if (cardList[index].id === card.id) {
                cardIndex = index;
            }
        }

        if (!event.value) {
            this.tableData.data[cardIndex].checkboxItem = { checked: false, disabled: false };
        } else {
            this.tableData.data[cardIndex].checkboxItem = { checked: true, disabled: false };
        }

        this.toggleCardSelection(card.id);
    }

    selectAllCheckBoxEventHandler(event) {
        this.isAllCardsSelected = false;

        if (!event.value) {
            this.resetBulkOperations();
        } else {
            this.tableData['allSelectCheckbox'] = { checked: true, disabled: false };
            for (const element in this.tableData.data) {
                this.tableData.data[element].checkboxItem = { checked: true, disabled: false };
            }
            this.applyLabelOperationType = APPLY_LABEL_OPERATION.LIST_PRODUCTS
            this.addSelectedCardsToList();
        }
    }

    toggleCardSelection(id) {
        this.isAllCardsSelected = false;
        if (!this.selectedCards.includes(id)) {
            this.selectedCards.push(id);
            if (this.selectedCards.length === this.digitalBusinessCards.objects.length) {
                this.tableData['allSelectCheckbox'] = { checked: true, disabled: false };
            }
        } else {
            this.selectedCards.splice(this.selectedCards.indexOf(id), 1);
            this.tableData['allSelectCheckbox'] = { checked: false, disabled: false };
            this.isAllCardsSelected = false;
        }
        this.applyLabelOperationType = APPLY_LABEL_OPERATION.LIST_PRODUCTS;
    }

    private duplicateCard(card) {
        this.messageModal.show('Duplicating Digital Business Card...', 'warning', 0)
        const cardToPost = new DigitalBusinessCard(card)
        cardToPost.name = cardToPost.name + ' (copy)'
        delete cardToPost.saves;
        delete cardToPost.views;
        // Remove user_agreement_urls with empty values
        cardToPost.lead_user_agreement_attribute.user_agreement_urls = cardToPost.lead_user_agreement_attribute.user_agreement_urls.filter(res => {
            delete res.__valid_url__;
            return (res.url && res.label) ? res : null;
        });
        this.digitalBusinessCardService.post(cardToPost).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.messageModal.show('Digital Business Card duplicated successfully', 'success')
            this.pageAttributes.pageNumber = 1;
            this.pageAttributes.pageSize = 10;
            this.pageAttributes.ordering = '';
            this.updatePageInfo();
            this.pageAttributesChange.next()
        }, error => {
            if (error.status === 426) {
                const errorMessage = Utils.getErrorMessage(error.error);
                this.messageModal.show(`${errorMessage}`, 'danger');
            } else {
                const errorMessage = Utils.getErrorMessage(error.error);
                if ((error.status < 500) && errorMessage) {
                    this.messageModal.show(`${errorMessage}`, 'danger');
                } else {
                    this.messageModal.show('Error duplicating. Please try again', 'danger');
                }
            }
        })
    }


    private setIsTemplate(card: DigitalBusinessCard, isTemplate: boolean) {
        this.messageModal.show('Marking digital business card as template', 'warning', 0)
        card.is_template = isTemplate;
        this.digitalBusinessCardService.put(card, card.id, `?organization=${this.authService.getCurrentOrgId()}`).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.messageModal.show('Marked digital business successfully', 'success')
            this.pageAttributesChange.next()
        }, error => {
            if (error.status === 426) {
            } else {
                const errorMessage = Utils.getErrorMessage(error.error);
                if ((error.status < 500) && errorMessage) {
                    this.messageModal.show(`${errorMessage}`, 'danger');
                } else {
                    this.messageModal.show('Error marking as template. Please try again', 'danger');
                }
            }
        })
    }

    private openDeleteCardModal(card) {
        this.selectedDigitalBusinessCard = card;
        this.deleteCardModal.show()
    }

    deleteCard() {
        this.overlayService.isLoading(true);
        this.digitalBusinessCardService.delete(this.selectedDigitalBusinessCard.id, '?organization=' + this.authService.getCurrentOrgId()).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.overlayService.isLoading(false)
            this.changePageSize(10);
            this.messageModal.show('Digital Business Card deleted successfully', 'success');
            this.sideModalOnInfoClick.hide();
            this.ismoreoptionDrawerOpen = false;
        }, error => {
            this.overlayService.isLoading(false)
            this.messageModal.show('Error deleting Digigtal Business Card', 'danger')
        })
        this.selectedDigitalBusinessCard = null
    }

    handleConfirmShareWalletPassModal() {
        if (this.selectedCards && !this.isAllCardsSelected) {
            this.shareWalletPassService.shareWithArray(this.selectedCards, this.authService.getCurrentOrgId())
                .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
                    this.resetBulkOperations();
                })).subscribe(res => {
                    this.messageModal.show('Wallet passes have been shared successfully to the owner\'s email ID.', TOAST_TYPES.SUCCESS);
                    this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, 'Bulk share DBC', {
                        count: 0
                    })
                    this.confirmShareWalletPassModal.hide();
                    this.ismoreoptionDrawerOpen = false;
                }, error => {
                    console.log(error.error.detail);
                });
        } else if (this.isAllCardsSelected) {
            const user = this.authService.getUser();
            const filters = this.queryParams.filters;
            this.shareWalletPassFilterService.shareWithFilter(filters, this.authService.getCurrentOrgId(), this.cardType, user.id)
                .pipe(takeUntil(this.ngUnsubscribe), finalize(() => {
                    this.resetBulkOperations();
                })).subscribe(res => {
                    this.messageModal.show('Wallet passes have been shared successfully to the owner\'s email ID.', TOAST_TYPES.SUCCESS);
                    this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, 'Bulk share DBC', {
                        count: 0
                    })
                    this.confirmShareWalletPassModal.hide();
                }, error => {
                    console.log(error.error.detail);
                });
        }
    }

    private shareSelectedWalletPass(selectedDigitalBusinessCard: DigitalBusinessCard) {
        const email = selectedDigitalBusinessCard.card_owner.email || selectedDigitalBusinessCard.meta['creator_email']
        const postBody = { emails: [email] };
        const vcardID = selectedDigitalBusinessCard.id;
        const orgId = this.authService.getCurrentOrgId();
        const endpoint = `${vcardID}/walletpass/?organization=${orgId}`;

        this.digitalBusinessCardService.post(postBody, endpoint).pipe(
            takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.messageModal.show('Wallet passes have been shared successfully to the owner\'s email ID.', TOAST_TYPES.SUCCESS);
            this.confirmShareWalletPassModal.hide();
        }, error => {
            this.messageModal.show('Wallet pass could not be generated. Please try again.', TOAST_TYPES.DANGER)
        });
    }

    downloadQRtype(event) {
        if (event.value === 'custom') {
            if (this.isMobileView === false) {
                this.downloadModal.show();
            } else {
                this.current_mobile_screen = DOWNLOAD_SCREENS.ADVANCE_DOWNLOAD_SCREEN;
            }
        } else {
            this.downloadQR(event.value);
            this.isDownloadsDrawerOpen = false;
        }
    }

    downloadCustomQRCode() {
        this.downloadQR(this.customDownloadOptions.canvasType, this.customDownloadOptions.size, this.customDownloadOptions.correctLevel)
        this.downloadModal.hide();
        this.isDownloadsDrawerOpen = false;
    }

    downloadQR(canvasType: QR_CANVAS_TYPES, downloadSize = 1024, correctLevel = QR_ERROR_CORRECTION_LEVEL.H) {

        if (!this.selectedDigitalBusinessCard.id) {
            return;
        }

        // Restrict EPS to max 1024
        if (canvasType === QR_CANVAS_TYPES.EPS && downloadSize > 1024) {
            downloadSize = 1024;
        }

        this.messageModal.show('Your download will start in a few seconds...');

        const org = this.authService.getUser().isOwner() ? 'ALL' : this.authService.getCurrentOrgId();
        this.dbcQRDownloadService.download(this.selectedDigitalBusinessCard.id, canvasType, org, downloadSize, correctLevel)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = res.urls[canvasType];
                a.download = this.selectedDigitalBusinessCard.name.trim() + '.' + canvasType;
                a.click();
                document.body.removeChild(a);

                this.logCardShareEvent(SHARE_CARD_EVENTS.DOWNLOAD_QR, canvasType);
            }, error => {
                if ((error.status < 500) && error.error.detail) {
                    this.messageModal.show(`${error.error.detail}`, 'danger');
                } else {
                    this.messageModal.show('Error downloading. Please try again or contact support', 'danger');
                }
            });
    }

    downloadZipFromResponse(response) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = response.url;
        a.download = 'Bulk_Download.zip';
        a.click();
        document.body.removeChild(a);
    }
    bulkDownloadDbcQRCodes() {
        this.logCardShareEvent(SHARE_CARD_EVENTS.DOWNLOAD_QR_MULTISELECT , this.customDownloadOptions.canvasType);
        this.dbcBulkDownloadModal.hide();
        this.ismoreoptionDrawerOpen = false;
        if (this.selectedCards && !this.isAllCardsSelected) {
            this.messageModal.show('Your download will start in a few seconds...');

            this.dbcQRBulkDownloadService
                .downloadWithArray(
                    this.authService.getCurrentOrgId(),
                    this.selectedCards,
                    this.customDownloadOptions.canvasType,
                    this.customDownloadOptions.size,
                    this.customDownloadOptions.correctLevel
                )
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    finalize(() => {
                        this.resetBulkOperations();
                    })
                )
                .subscribe(
                    (res) => {
                        if (res.type === 1) {
                            this.downloadZipFromResponse(res);
                        } else {
                            this.downloadRequestModal.show();
                        }
                    },
                    (error) => {
                        console.log(error);
                        if (error.status < 500 && error.error.detail) {
                            this.messageModal.show(
                                `${error.error.detail}`,
                                'danger'
                            );
                        } else {
                            this.messageModal.show(
                                'Error downloading. Please try again or contact support',
                                'danger'
                            );
                        }
                    }
                );
        } else if (this.isAllCardsSelected) {
            this.messageModal.show('Your download will start in a few seconds...');

            let encoded_filters = this.queryParams.filters;
            const filters = JSON.parse(atob(encoded_filters));

            if (this.cardType === DBCCardTypes.MY_CARDS) {
                filters['card_owner__is'] = this.authService.getUser().id;
            }
            filters['state'] = 'A';

            encoded_filters = btoa(JSON.stringify(filters));

            this.dbcQRBulkFilterDownloadService
                .downloadQRWithFilter(
                    this.authService.getCurrentOrgId(),
                    encoded_filters,
                    this.customDownloadOptions.canvasType,
                    this.customDownloadOptions.size,
                    this.customDownloadOptions.correctLevel
                )
                .pipe(
                    takeUntil(this.ngUnsubscribe),
                    finalize(() => {
                        this.resetBulkOperations();
                    })
                )
                .subscribe(
                    (res) => {
                        if (res.type === 1) {
                            this.downloadZipFromResponse(res);
                        } else {
                            this.downloadRequestModal.show();
                        }
                    },
                    (error) => {
                        console.log(error);

                        if (error.status < 500 && error.error.detail) {
                            this.messageModal.show(
                                `${error.error.detail}`,
                                'danger'
                            );
                        } else {
                            this.messageModal.show(
                                'Error downloading. Please try again or contact support',
                                'danger'
                            );
                        }
                    }
                );
        }
    }

    bulkDeletePermanentlyCards() {
        this.confirmBulkDeletePermanentlyModal.hide();
        this.ismoreoptionDrawerOpen = false;
        if (this.selectedCards && !this.isAllCardsSelected) {
            this.overlayService.isLoading(true);
            this.dbcBulkHardDeleteService.deleteWithArray(this.authService.getCurrentOrgId(), this.selectedCards)
                .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                    this.overlayService.isLoading(false)
                    this.messageModal.show(res.detail, 'success')
                    this.resetBulkOperations();
                    this.onPageAttributesChange(1);
                }, error => {
                    console.log(error);
                    this.overlayService.isLoading(false);
                    this.messageModal.show('Something went wrong while trying to delete');
                })
        } else if (this.isAllCardsSelected) {
            const user = this.authService.getUser();
            const filters = this.queryParams.filters;

            this.dbcBulkFilterHardDeleteService.deleteWithFilter(this.authService.getCurrentOrgId(), filters, this.cardType, user.id)
                .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                    this.overlayService.isLoading(false)
                    this.messageModal.show(res.detail, 'success')
                    this.resetBulkOperations();
                    this.onPageAttributesChange(1);
                }, error => {
                    console.log(error);
                    this.messageModal.show('Something went wrong while trying to delete');
                })
        }
    }

    // -------Owner Transfer Starts--------
    getMaintainerList(params = {}) {
        this.userService.getList(1, 100, { ...params, permissions: 'cards' }, true)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(usersList => {
                this.maintainerList = usersList.objects;
                if (Object.keys(params).length === 0) {
                    this.originalMaintainerList = this.maintainerList;
                }
                if (!this.maintainerList.length) {
                    this.disableTransferOwnerButton = true;
                }
            })
    }

    checkCurrentMaintainer() {
        for (const user of this.maintainerList) {
            if (this.selectedDigitalBusinessCard.card_owner['id'] === user.id) {
                this.currentProductOwner = user;
            }
        }
    }

    setNewOwner(event) {
        this.changeUserId = event.id;
        if (this.currentProductOwner) {
            this.disableTransferOwnerButton = (this.currentProductOwner['id'] === event.id)
        } else {
            this.disableTransferOwnerButton = false
        }
    }

    fetchUsersByEmail(event){
        this.getMaintainerList({ email__icontains : event });
    }

    changeProductOwner(forceMigrate: boolean) {
        this.ownerTransfer.changeProductOwnerr(forceMigrate, this.selectedDigitalBusinessCard, this.changeUserId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.messageModal.show('Successfully changed ownership');
            this.ismoreoptionDrawerOpen = false;
            this.changeMaintainer.hide();
            this.pageAttributesChange.next();
            this.digitalBusinessCardService.getDetail(res.digitalbusinesscard.id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(dbc => {
                this.selectedDigitalBusinessCard = new DigitalBusinessCard(dbc);
            }, error => {
                console.error(error);
            })
        }, error => {
            if (error.status === 409) {
                this.messageModal.show(error.error.detail);
            } else {
                this.messageModal.show(error?.error?.detail || 'Unable to change card owner. Contact support for help.', TOAST_TYPES.DANGER);
            }
        });
    }

    // -------Owner Transfer Ends--------

    // -------Organization Transfer Starts--------

    getOrganizationList(dbc: DigitalBusinessCard) {
        const isUserReseller = this.authService.getUser().isReseller();
        if (isUserReseller) {
            this.masterOrg = this.authService.getUser().isSuperAdmin() ? this.authService.getUser().organization : this.authService.getOrganization();
        }
        for (const org in this.organizationList) {
            if (this.organizationList[org].id === dbc.organization) {
                this.disableTransferOrgButton = true;
                this.currentProductOrg = this.organizationList[org];
            }
        }
        if (!this.organizationList) {
            this.disableTransferOrgButton = true;
        }
        this.hasOwnerPermissions = this.authService.getUser().hasOwnerPermissions();
    }

    setNewOrg(event) {
        this.disableTransferOrgButton = (this.currentProductOrg['id'] === event.id);
        this.changeOrganizationId = event.id;

        this.fetchOrganizationsByName('')

    }

    transferProductOrg(forceMigrate: boolean) {
        const org = this.changeOrganizationId;
        this.productTransfer.transferProduct(this.digitalBusinessCardService, forceMigrate, this.selectedDigitalBusinessCard, org)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
                this.messageModal.show('Successfully transferred');
                this.changeOrganization.hide();
                this.ismoreoptionDrawerOpen = false;
                this.pageAttributesChange.next();
            }, error => {
                if (error.status === 409) {
                    this.messageModal.show(error.error.detail);
                } else {
                    this.messageModal.show(error?.error?.detail || 'Unable to transfer card to chosen organization. Contact support for help.', TOAST_TYPES.DANGER);
                }
            });
    }

    private checkIfLinkpageCreatedOrUpdated() {
        if (this.digitalBusinessCardService.getDBCCreated().digitalBusinessCard && this.digitalBusinessCardService.getDBCCreated().operation) {
            this.selectedDigitalBusinessCard = this.digitalBusinessCards.objects.filter((dbc) =>
                dbc.id === this.digitalBusinessCardService.getDBCCreated().digitalBusinessCard.id)[0]
            this.isNewDigitalBusinessCard = this.digitalBusinessCardService.getDBCCreated().operation === 'create'
            this.digitalBusinessCardService.setDBCCreated(null, '')
            this.generateQrCode(GENERATE_QRCODE_TYPE.DBC);
            if (this.isMobileScreen) {
                this.current_mobile_screen = DOWNLOAD_SCREENS.SHARE_SCREEN;
                this.isDownloadsDrawerOpen = true;
            } else {
                this.sideModalOnInfoClick.show()
            }
            this.loadPreview();
        }
    }


    togglePreview(isQRpreview: boolean) {
        if ((isQRpreview && !this.showPreviewFrame) || (!isQRpreview && this.showPreviewFrame)) {
            return;
        }
        if (isQRpreview) {
            this.showPreviewFrame = false;
            if (this.isMobileView === true) {
                this.current_mobile_screen = DOWNLOAD_SCREENS.SHARE_SCREEN
            }
        } else {
            this.showPreviewFrame = true;
            this.toolbarOptionsEventToAmplitude(TOOLBAR_OPTIONS_EVENT.PREVIEW);
            if (this.isMobileView === true) {
                this.current_mobile_screen = DOWNLOAD_SCREENS.PREVIEW_SCREEN;
            }
            this.loadPreview();
        }
    }

    createDBC() {
        if (this.cardType === DBCCardTypes.MY_CARDS) {
            this.selectedCardOwnerId = -1;
            this.redirectToAddDBC();
        } else {
            this.maintainerList = this.originalMaintainerList;
            this.selectCardOwnerModal.show();
        }
    }

    setOwner(event) {
        this.selectedCardOwnerId = event.id;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }

    handleDropdownChange(value) {
        const valueStored = this.searchParams[this.searchByValue]
        delete this.searchParams[this.searchByValue]

        this.searchPlaceholder = value.placeholder
        this.searchByValue = value.searchValue
        this.dropdownForSearch.label = value

        this.searchParams[this.searchByValue] = valueStored
        if (valueStored) {
            this.getDigitalBusinessCardList()
        }
    }

    fetchOrganizationsByName(value = '') {
        this.fetchOrganizationsService.fetchOrganizations(value).subscribe(res => {
            const filteredOrgList = res.objects;
            this.organizationList = filteredOrgList;
        }, error => {
            console.error(error)
        })
    }

    onColorPickerChange(event, attribute) {
        switch (attribute) {
            case WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_BACKGROUND:
                this.appleWalletPassCustomization.backgroundColor = event;
                this.updateAppleWalletPassQRCode();
                break;
            case WALLET_PASS_CUSTOMIZATION.APPLE_WALLET_PASS_FOREGROUND:
                this.appleWalletPassCustomization.textColor = event;
                this.updateAppleWalletPassQRCode();
                break;
            case WALLET_PASS_CUSTOMIZATION.GOOGLE_WALLET_PASS_BACKGROUND:
                this.googleWalletPassPreviewColor.backgroundColor = event;
                this.updateGoogleWalletPassQRCode();
                break;
        }
    }

    truncateEmail(email: string, email_type: string) {
        if (email_type === 'owner') {
            return email ? (email.length > 29 ? email.slice(0, 29) + '...' : email) : null;
        } else if (email_type === 'creator') {
            return email ? (email.length > 26 ? email.slice(0, 26) + '...' : email) : null;
        }
    }

    createNewTemplate(gotToBulkAfterCreate = false) {
        const queryParams = { orgID: this.authService.getCurrentOrgId() };

        // Conditionally add the 'bulk' parameter
        if (gotToBulkAfterCreate) {
            queryParams['post_save_redirect_to'] = TEMPLATE_SAVE_REDIRECT_TO.SINGLE_BULK;
        }

        this.router.navigate(['digital-business-cards/templates/design-library'], { queryParams })
    }

    onFilterChange(filterObject: SearchParams) {

        if (filterObject?.filters && filterObject?.filters.toString().length > 4) {
            this.isListFiltered = true;
        } else {
            this.isListFiltered = false
        }
        this.filtersChangeSubject.next(filterObject.filters);
    }

    resetApppliedFilters() {
        document.getElementById('filter-reset-button').click();
    }

    onLabelsApplied(event) {
        this.onPageAttributesChange(1);
        this.fetchLabels();
    }

    onLabelsUpdated(event) {
        this.onPageAttributesChange(1);
        this.fetchLabels();
    }

    deleteLabel(event) {
        this.onPageAttributesChange(1);
        this.fetchLabels();
    }

    handleCellClick(event) {
        const dataItem = event.dataItem;
        const dataObject = event.dataObject
        switch (dataItem.key) {
            case 'name':
                this.navigateToEdit(dataObject);
                break;
            case 'labels':
                this.applyLabelOnSelectedCard({value: true, dataObject: event.dataObject})
                break;
        }
    }

    applyLabelOnSelectedCard(event) {
        this.applyLabelOperationType = APPLY_LABEL_OPERATION.LIST_PRODUCTS
        // this.selectedCards = [event.dataObject.id];
        this.selectedCardsForTag = [event.dataObject.id];
        this.tagUntagModal.show(this.selectedCardsForTag);
    }


    openLogoUploadModal(){
        this.showLogoUploadModal = !this.showLogoUploadModal;
    }

    setDeviseType(): void {
        this.isMobileScreen = (window.innerWidth <= 767);
    }

    openLogoModal(uploadType: string) {
        if (uploadType === 'logo') {
            this.imageSelectModalLogo.show();

        } else if (uploadType === 'heroImage') {
            this.imageSelectModalHeroImage.show();
        }
    }

    setupImageSelectModal(uploadType: string) {
        if (!this.imageCropperFile) {
            this.imageSelectModel = {
                media: null,
                link: '',
                alt: '',
                isUploading: false,
            };

            if (uploadType === 'logo') {
                this.updatedMediaLogo = null;
                this.imageSelectGalleryLogo.fetchFolders();
            } else if (uploadType === 'heroImage') {
                this.updatedMediaHeroImage = null;
                this.imageSelectGalleryHeroImage.fetchFolders();
            }
        }
    }

    loadCropper(file, uploadType: string) {
        this.imageCropperFile = file;
        if (this.imageCropperFile.type !== 'image/gif') {
            if (uploadType === 'logo') {
                this.imageSelectModalLogo.hide();
                this.showImageCropper('logo');
            } else if (uploadType === 'heroImage') {

                this.imageSelectModalHeroImage.hide();
                this.showImageCropper('heroImage');
            }
        }
    }
    showImageCropper(uploadType: string) {
        if (uploadType === 'logo') {
            if (!this.imageCropperLogo) {
                setTimeout(() => {
                    this.showImageCropper('logo');
                }, 100);
                return;
            }
            this.imageCropperLogo.show();
        } else if (uploadType === 'heroImage') {
            if (!this.imageCropperHeroImage) {
                setTimeout(() => {
                    this.showImageCropper('heroImage');
                }, 100);
                return;
            }
            this.imageCropperHeroImage.show();

        }

    }

    setUploadToFolder(folder) {
        this.uploadToFolder = folder
    }

    selectImage(uploadType: string) {
        if (uploadType === 'logo') {
            this.imageSelectModalLogo.hide();
            this.googleWalletPassPreviewLogo.logo_url = this.imageSelectModel.media.url;
            this.googleWalletPassPreviewLogoId.logo_id = this.imageSelectModel.media.id;
            this.dbc_url = true;
            this.updateGoogleWalletPassQRCode();
        } else if (uploadType === 'heroImage') {
            this.imageSelectModalHeroImage.hide();
            this.googleWalletPassPreviewHeroImage.hero_image_url = this.imageSelectModel.media.url;
            this.googleWalletPassPreviewHeroImageId.hero_image_id = this.imageSelectModel.media.id;
            this.updateGoogleWalletPassQRCode();
        }
        this.messageModal.show('Image updated', 'success');
    }

    imageSelectedInGallery({media, clicked}, uploadType: string) {
        if (media) {
            this.imageSelectModel.media = media;
            if (!clicked) {
                if (uploadType === 'logo') {
                    this.imageSelectModalLogo.hide();
                    this.selectImage('logo');
                } else if (uploadType === 'heroImage') {
                    this.imageSelectModalHeroImage.hide();
                    this.selectImage('heroImage');
                }
            }
        } else {
            this.imageSelectModel.media = null;
        }
    }

    closeImageCropperModal() {
        this.setUpImageCropperModal();
    }

    setUpImageCropperModal() {
        this.imageCropperFile = null;
    }

    updateMedia(media, uploadType: string) {
        if (uploadType === 'logo') {
            if (this.imageCropperLogo) {
                this.imageCropperLogo.hide();
                this.setUpImageCropperModal()
            }
            this.updatedMediaLogo = media;
        } else if (uploadType === 'heroImage') {
            if (this.imageCropperHeroImage) {
                this.imageCropperHeroImage.hide();
                this.setUpImageCropperModal()
            }
            this.updatedMediaHeroImage = media;
        }
    }

    onVcardPlusLogoRemoved(uploadType: string) {

        if (uploadType === 'logo') {
            if (this.googleWalletPassPreviewLogo.logo_url !== '') {
                this.googleWalletPassPreviewLogo.logo_url = '';
            } else if (this.selectedDigitalBusinessCard.logo_url !== '') {
                this.selectedDigitalBusinessCard.logo_url = '';
            }
            this.googleWalletPassPreviewLogoId.logo_id = null;

        }

        if (this.googleWalletPassPreviewLogo.logo_url === '' && this.selectedDigitalBusinessCard.logo_url === '') {
            this.dbc_url = false;
        } else if (uploadType === 'heroImage') {
            this.googleWalletPassPreviewHeroImage.hero_image_url = '';
            this.googleWalletPassPreviewHeroImageId.hero_image_id = null;
        }
        this.updateGoogleWalletPassQRCode()
    }


    closeLogoUploadModal(uploadType: string) {
        if (uploadType === 'logo') {
            this.imageSelectModalLogo.hide();
            if (this.updatedMediaLogo) {
                this.showLogoUploadModal = true;
            }
        } else if (uploadType === 'heroImage') {
            this.imageSelectModalHeroImage.hide();
            if (this.updatedMediaLogo) {
                this.showLogoUploadModal = true;
            }
        }


        if (this.selectedDigitalBusinessCard.logo_url) {
            this.showLogoUploadModal = true;
        } else {
            this.showLogoUploadModal = false;
        }
    }

    moveBetweenMobileScreens(value: SWITCH_MOBILE_SCREEN) {
        switch (value) {
            case SWITCH_MOBILE_SCREEN.DOWNLOADS:
                this.current_mobile_screen = DOWNLOAD_SCREENS.DOWNLOADS_SCREEN;
                break;
            case SWITCH_MOBILE_SCREEN.SHARE:
                this.current_mobile_screen = DOWNLOAD_SCREENS.SHARE_SCREEN;
                break;
            case SWITCH_MOBILE_SCREEN.ADVANCE_DOWNLOAD:
                this.current_mobile_screen = DOWNLOAD_SCREENS.DOWNLOADS_SCREEN;
                break;
            case SWITCH_MOBILE_SCREEN.FILE_TYPE:
                this.current_mobile_screen = DOWNLOAD_SCREENS.FILE_TYPE_SCREEN;
                break;
            case SWITCH_MOBILE_SCREEN.SIZE:
                this.current_mobile_screen = DOWNLOAD_SCREENS.SIZE_SCREEN;
                break;
            case SWITCH_MOBILE_SCREEN.ERROR_CORRECTION:
                this.current_mobile_screen = DOWNLOAD_SCREENS.ERROR_CORRECTION_SCREEN;
                break;
            case SWITCH_MOBILE_SCREEN.MORE_OPTIONS:
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.MORE_OPTIONS;
                break;
            case SWITCH_MOBILE_SCREEN.BULK_DOWNLOAD:
                this.ismoreoptionDrawerOpen = true;
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.BULK_DOWNLOAD;
                break;
            case SWITCH_MOBILE_SCREEN.BULK_DELETE:
                this.ismoreoptionDrawerOpen = true;
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.BULK_DELETE;
                break;
            case SWITCH_MOBILE_SCREEN.BULK_SHARE_WALLET_PASS:
                this.ismoreoptionDrawerOpen = true;
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.BULK_SHARE_WALLET_PASS;
                break;
            case SWITCH_MOBILE_SCREEN.OWNER:
                break;

            case SWITCH_MOBILE_SCREEN.ORGANIZATION:
                break;

            case SWITCH_MOBILE_SCREEN.DELETE:
                break;
        }
    }

    checkMoreOptionsDrawerOpen(){
        this.ismoreoptionDrawerOpen = !this.ismoreoptionDrawerOpen;
    }

    handleMoreOptionsMobileClick(value){
        value = value.toLowerCase();
        switch (value) {
            case 'preview':
                this.isDownloadsDrawerOpen = true;
                this.generateQrCode(GENERATE_QRCODE_TYPE.DBC);
                this.ismoreoptionDrawerOpen = false;
                this.current_mobile_screen = DOWNLOAD_SCREENS.PREVIEW_SCREEN;
                this.showPreviewFrame = true;
                this.loadPreview();
                break;
            case 'edit':
                this.navigateToEdit(this.selectedDigitalBusinessCard);
                break;
            case 'delete':
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.DELETE_CARD;
                break;
            case 'rename':
                break;
            case 'duplicate':
                this.duplicateCard(this.selectedDigitalBusinessCard);
                this.checkMoreOptionsDrawerOpen();
                break;
            case 'share wallet pass':
                this.selectedCards = [];
                this.shareSelectedWalletPass(this.selectedDigitalBusinessCard);
                this.checkMoreOptionsDrawerOpen()
                break;
            case 'analytics':
                this.navigateToAnalytics(this.selectedDigitalBusinessCard)
                break;
            case 'change owner':
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.CHANGE_OWNER;
                break;
            case 'change organization':
                this.getOrganizationList(this.selectedDigitalBusinessCard);
                this.current_more_option_screen = MORE_OPTIONS_SCREEN.CHANGE_ORGANIZATION;
                break;
            case 'label':
                this.applyLabelOnSelectedCard({value: true, dataObject: this.selectedDigitalBusinessCard})
                this.ismoreoptionDrawerOpen = false;
                break;
            case 'mark as template':
                this.setIsTemplate(this.selectedDigitalBusinessCard, true);
                break;
            case 'unmark as template':
                this.setIsTemplate(this.selectedDigitalBusinessCard, false);
                break;
        }
    }

    selectFileType(event) {
        this.selectedFileType = event;
        this.customDownloadOptions.canvasType = event.toLowerCase();
        this.current_mobile_screen = DOWNLOAD_SCREENS.ADVANCE_DOWNLOAD_SCREEN;
    }

    selectSize(size) {
        this.selectedSize = size;
        this.customDownloadOptions.size = size.value;
        this.current_mobile_screen = DOWNLOAD_SCREENS.ADVANCE_DOWNLOAD_SCREEN;
    }

    selectErrorCorrection(errorCorrection){
        this.selectedErrorCorrection = errorCorrection;
        this.customDownloadOptions.correctLevel = errorCorrection.value;
        this.current_mobile_screen = DOWNLOAD_SCREENS.ADVANCE_DOWNLOAD_SCREEN;
    }

    checkScreenSize() {
        this.isMobileView = window.innerWidth < 768;
    }

    checkDrawerOpen(){
        this.isDownloadsDrawerOpen = false;
        this.showPreviewFrame = false;
    }

    onShare() {
        if (navigator.share) {
            navigator.share({
                title: 'Share Via',
                text: 'Excited to share my digital business card with you; click the link below to view it.',
                url: this.selectedDigitalBusinessCard?.url
            })
        } else {
            console.error('navigator.share API not supported by the browser!');
        }
    }

    showPreview(){
        this.openPreviewModal.show();
        this.isPreviewModalopen = true;
        this.isDownloadsDrawerOpen = false;
        this.loadPreview();
    }

    closePreview(){
        this.openPreviewModal.hide();
        this.isPreviewModalopen = false;
        this.isDownloadsDrawerOpen = true;
    }


    updateAWPCustomization(currentAWPCustomization: AWP_CUSTOMIZE_OPTION) {
        this.awpCurrentCustomizeOption = currentAWPCustomization;
    }

    onAWPLogoRemoved() {
        this.updateAWPLogoImg();
    }

    updateAWPLogoImg({url, id} = {url: '', id: null}): void {
        const mediaID = id ? id.toString() : '';
        this.appleWalletPassCustomization.logo.url = url;
        this.appleWalletPassCustomization.logo.id = mediaID;
        this.updateAppleWalletPassQRCode()
    }

    resetAWPCustomizations() {
        this.appleWalletPassCustomization = this.setDefaultAWPCustomizationValues(this.currOrgWPTemplate)
    }


    /* app media gallery functions */
    onAWPLogoImgSelect({media, clicked}: {media: Media | null, clicked?: boolean}): void {
        if (media) {
            this.awpLogoImgSelectModel.media = media;
            if (!clicked) {
                this.awpLogoImageSelectModal.hide();
            }
            this.updateAWPLogoImg(media)
        } else {
            this.awpLogoImgSelectModel.media = null;
        }
    }

    setupAWPLogoUploadPath(folder) {
        /* Uppload to folder useful for image cropper */
        this.awpLogoImgUploadToFolder = folder;
    }

    setupAWPLogoImgCropper(file: File) {
        this.awpLogoImgCropperFile = file;
        if (this.awpLogoImgCropperFile.type !== 'image/gif') {
            this.awpLogoImageSelectModal.hide();
            this.showAWPLogoImageCropper();
        }
    }

    showAWPLogoImageCropper() {
        if (!this.imageCropperAWPLogoImg) {
            setTimeout(() => {
                this.showAWPLogoImageCropper();
            }, 100);
            return;
        }
        this.imageCropperAWPLogoImg.show();
    }

    selectAWPLogoImg() {
        this.awpLogoImageSelectModal.hide();
        this.updateAWPLogoImg(this.awpLogoImgSelectModel.media)
    }


    updateAWPLogoMedia(media: Media) {
        if (this.imageCropperAWPLogoImg) {
            this.imageCropperAWPLogoImg.hide();
            this.resetAWPLogoImgCropper()
        }
        this.awpUpdatedMediaLogo = media
        this.messageModal.show('Image updated', 'success');
    }

    resetAWPLogoImgCropper() {
        this.awpLogoImgCropperFile = null;
    }

    closeAWPLogoImgCropperModal() {
        this.resetAWPLogoImgCropper();
    }

    setupAWPLogoImgSelectModal() {
        /* awpLogoImgCropperFile: Used for awp cropper */
        if (!this.awpLogoImgCropperFile) {
            this.imageSelectModel = {
                media: null,
                link: '',
                alt: '',
                isUploading: false,
            };

            this.awpUpdatedMediaLogo = null;
            this.awpLogoImageSelectMediaGallery.fetchFolders();
        }
    }

    updateMobileWalletPassModal({walletPassType, isModalOpen, isCustomizeModalOpen}: {walletPassType: WALLET_PASS, isModalOpen: boolean, isCustomizeModalOpen: boolean}) {
        switch (walletPassType) {
            case WALLET_PASS.APPLE_WALLET:
                this.isAWPModalOpen = isModalOpen;
                this.isCustomizeAWPOpen = isCustomizeModalOpen
                break;
            case WALLET_PASS.GOOGLE_WALLET:
                this.isCustomizeGoogleWalletPassOpen = isModalOpen;
                this.isGoogleWalletPassModalOpen = isCustomizeModalOpen
                break;
        }
    }

    onGuideLineClick(walletPassType: WALLET_PASS) {
        this.currentWalletPassType = walletPassType;
        this.isGuidelinesModalOpen = true;
        this.updateMobileWalletPassModal({walletPassType: walletPassType, isCustomizeModalOpen: false, isModalOpen: false})
    }

}
