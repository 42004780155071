<div class="p-4 d-flex flex-column" *ngIf="currentScreen === CARD_SCREENS.LAYOUT_SELECT; else creationFlowScreen">
    <beaconstac-title style="margin-bottom: 32px;"
        [title]="'Select a design for your card'"
        [subtitle]="'This will help you see how your business card will look'"
    ></beaconstac-title>
    <app-dbc-designs-grid-view [type]="DBC_DESIGN_TYPE.LAYOUTS"
                               (selectedLayout)="selectLayout($event, false)"
                               [cardType]="cardType"
    ></app-dbc-designs-grid-view>
    <div *ngIf="onChangeDesign" class="go-back-container">
        <app-bottom-sticky-buttons
            [secondaryCtaName]="'Go Back'"
            [secondaryCtaAction]="onNoLayoutChanged.bind(this)"
            [showTertiaryButton]="false"
        ></app-bottom-sticky-buttons>
    </div>
</div>

<ng-template #creationFlowScreen>
     <div class="bx--grid stepper-app-body" *ngIf="product">
        <div class="bx--row p-0">

            <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md stepper cursor-pointer"
                 (click)="goToNext(1)"
                 [ngClass]="selectedStep === 1 && 'active'"
            >
                <div class="stepper-count">Step 1</div>
                <div class="text-uppercase mt-2"
                     [ngClass]="selectedStep === 1 ? 'stepper-active-heading' : 'stepper-heading'">
                    Build
                </div>
            </div>
            <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
                 (click)="goToNext(1)"
                 [ngClass]="selectedStep === 1 ? 'active mobile-stepper-active' : ''"
                 (keyup)="goToNext(1)"
                 (keydown)="goToNext(1)"
            >
                <div class="stepper-count mt-5px">Step 1</div>
            </div>

            <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md stepper cursor-pointer"
                 (click)="goToNext(2)"
                 [ngClass]="selectedStep === 2 ? 'active' : ''"
            >
                <div class="stepper-count">Step 2</div>
                <div class="text-uppercase mt-2"
                     [ngClass]="selectedStep === 2 ? 'stepper-active-heading' : 'stepper-heading'">
                    Customize
                </div>

            </div>
            <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
                 (click)="goToNext(2)"
                 [ngClass]="selectedStep === 2 ? 'active mobile-stepper-active' : ''"
                 (keyup)="goToNext(2)"
                 (keydown)="goToNext(2)"
            >
                <div class="stepper-count mt-5px">Step 2</div>
            </div>

            <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md stepper cursor-pointer"
                 (click)="goToNext(3)"
                 [ngClass]="selectedStep === 3 && 'active'"
            >
                <div class="stepper-count">Step 3</div>
                <div class="text-uppercase mt-2"
                     [ngClass]="selectedStep === 3 ? 'stepper-active-heading' : 'stepper-heading'">
                    Set-up
                </div>
            </div>
            <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
                 (click)="goToNext(3)"
                 [ngClass]="selectedStep === 3 && 'active mobile-stepper-active'"
                 (keyup)="goToNext(3)"
                 (keydown)="goToNext(3)"
            >
                <div class="stepper-count mt-5px">Step 3</div>
            </div>

            <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper" style="max-width: 15%;" (click)="showPreview()" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]" (keyup)="showPreview()" (keydown)="showPreview()">
                <i class="stepper-count fa fa-eye mobile-stepper-active"></i>
            </div>

            <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper border-right-0" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
                 (click)="pressBack()"
                 style="max-width: 15%;"
                 [ngClass]="selectedStep === 1 ? 'back-button-disabled' : 'back-button'"
                 (keyup)="pressBack()"
                 (keydown)="pressBack()">
                <img class="text-custom-gray square-24" [src]=" LINK_PAGE_BASE_ASSET_URL + 'angle-left-solid.svg' " />
            </div>
            <div class="bx--col-md-0 bx--col-sm text-center mobile-stepper bg-primary border-right-0" *ngIf="!launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER]"
                 style="max-width: 15%;"
                 (click)="goToNext(selectedStep + 1)"
                 (keydown)="goToNext(selectedStep + 1)"
                 (keyup)="goToNext(selectedStep + 1)">
                <img class="text-white square-24" [src]=" LINK_PAGE_BASE_ASSET_URL + 'angle-double-right-solid.svg'" />
            </div>

            <div class="bx--col-xlg-4 bx--col-lg-4 bx--col-md-8 bx--col-sm-0">
                <div class="bx--row">
                    <div
                        class="bx--col-xlg-6 bx--col-lg-8 bx--col-md-4 bx--col-sm-2 stepper bg-secondary text-center cursor-pointer border-right-0"
                        [ngClass]="selectedStep === 1 ? 'back-button-disabled' : 'back-button'"
                        (click)="pressBack()"
                    >
                        <div class="text-gray" style="margin-top: 9px;">BACK</div>
                    </div>
                    <div
                        (click)="goToNext(selectedStep + 1)"
                        class="bx--col-xlg-10 bx--col-lg-8 bx--col-md-4 bx--col-sm-2 stepper bg-primary text-center cursor-pointer p-3 border-right-0"
                    >
                        <div class="font-weight-600 cursor-next" style="margin-top: 9px">
                            {{ selectedStep === 3 && launchDarkleyFlags[FEATURE_FLAGS.DBC_ACTIVATION_EXPERIMENT_CTA] ? 'SAVE & SHARE' :
                            (selectedStep === 3 ? 'FINISH' : 'NEXT') }}
                            <i style="font-size: 16px;" class="fa fa-angle-right ml-2 font-weight-600"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="stepper-body px-0" [ngStyle]="{'z-index': isPreviewOpen ? 0 : 4}" *ngIf="launchDarkleyFlags[FEATURE_FLAGS.DBC_MOBILE_STEPPER] && product && isMobileScreen">
        <beaconstac-new-dbc-stepper
            [goToNext]="goToNext"
            [selectedStep]="selectedStep">
        </beaconstac-new-dbc-stepper>
    </div>


    <div class="bx--grid mx-3 mx-md-0" style="max-width: -webkit-fill-available;">
        <div class="bx--row my-4">
            <div class="bx--col-xlg-11 bx--col-lg-9 bx--col-md-4 p-0 pb-5" [ngSwitch]="selectedStep">
                <div [@collapse] *ngSwitchCase="1">
                    <app-digital-business-card-setup
                        *ngIf="product"
                        [(product)]="product"
                        (productChange)="loadPreview()"
                        [(isMultiLanguageContent)]="isMultiLanguageContent"
                        [(currentLanguage)] = "currentLanguage"
                        [(multiLangList)]= "multiLangList"
                        [hasWriteAccess]="true"
                        [(cardSetting)]="cardSettings"
                        [(validations)]="validations"
                        [(validationsMultiLang)]="validationsMultiLang"
                        [newVcard]="isNewDigitalBusinessCard"
                        [cardType]="cardType"
                        (cardUserInputChange)="handleUserInputChange($event)"
                        [orgCardSetting]="cardOrgRestrictedFields"
                        [priortizeOrgSettings]="priortizeOrgSettings"
                        [isMobileScreen]="isMobileScreen"
                        [launchDarkleyFlags]="launchDarkleyFlags"
                    ></app-digital-business-card-setup>

                </div>
                <div [@collapse] *ngSwitchCase="2">
                    <app-digital-business-card-design
                        *ngIf="product"
                        [(product)]="product"
                        (productChange)="loadPreview()"
                        [hasWriteAccess]="true"
                        [(cardSetting)]="cardSettings"
                        [cardType]="cardType"
                        [orgCardSetting]="cardOrgRestrictedFields"
                        [priortizeOrgSettings]="priortizeOrgSettings"
                        [launchDarkleyFlags]="launchDarkleyFlags"
                    ></app-digital-business-card-design>

                </div>
                <div *ngSwitchCase="3" style="padding:0px;">
                    <app-digital-business-card-launch
                        *ngIf="product"
                        [(product)]="product"
                        (productChange)="loadPreview()"
                        [isNewDigitalBusinessCard]="isNewDigitalBusinessCard"
                        [hasWriteAccess]="true"
                        [(cardSetting)]="cardSettings"
                        [(customSlugValidations)] = "customSlugValidations"
                        [cardType]="cardType"
                        [orgCardSetting]="cardOrgRestrictedFields"
                        [(validations)]="validations"
                        [priortizeOrgSettings]="priortizeOrgSettings"
                        [launchDarkleyFlags]="launchDarkleyFlags"
                    ></app-digital-business-card-launch>

                </div>

            </div>

            <div class="bx--col-xlg-5 bx--col-lg-7 bx--col-md-4 bx--col-sm-0 text-center">
                <div *ngIf="isFrameLoading" class="iframe-spinner" style="width: 100%;">
                    <spinner class="custom-spinner-analytics" [size]="40" [tickness]="2">
                    </spinner>
                </div>
                <div class="live-preview-iframe-container text-center position-sticky" style="top: 184px; margin-bottom: 30px" id="customPreview">
                    <iframe class="live-preview-iframe mb-3" (load)="onIFrameLoadingStateChange(false)" #digitalBusinessCardPreview></iframe>
                </div>
            </div>
        </div>

    </div>

    <div #mobilePreviewModal class="modal-visible preview" style="display: none;" (click)="hideMobilePreview()" (keyup)="hideMobilePreview()" (keydown)="hideMobilePreview()">
        <iframe class="iframe-styles" #mobilePreview title="Preview of DBC"></iframe>
    </div>

    <app-bottom-sticky-buttons *ngIf="handleStickyButtons()"
        [primaryCtaName]="getPrimaryCtaName()"
        [secondaryCtaName]="getSecondaryCtaName()"
        [tertiaryCtaAction]="showPreview.bind(this)"
        [primaryCtaAction]="getPrimaryCtaAction.bind(this)"
        [secondaryCtaAction]="getSecondaryCtaAction.bind(this)"
        [isTertiaryButtonActive]="isPreviewOpen"
        [showTertiaryButton]="true"
        [primaryButtonDisabled]="!isDataValid()"
        [tertiaryButtonText]="'Preview'"
    ></app-bottom-sticky-buttons>

</ng-template>


<div bsModal #createDuplicateTempalteWarningModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">

            <div class="modal-body p-4">
                <h4 class="modal-heading mb-2">Update template</h4>
                <span class="modal-desc" >This will effect all {{ product?.meta['dbc_count'] ? product.meta['dbc_count'] : '' }} card(s) associated with the template. If you wish you can duplicate this template instead.</span>

            </div>
            <div *ngIf="product && product.meta['is_ad_integration_template']" class="bx--col d-flex mt-4" style="background: #FFF0C8; flex-direction: row; margin-bottom: 26px;">
                <i class="fas fa-info-circle pr-2" style="color: #FCBF49; font-size: 20px; padding-top: 18px;"></i>
                <span class="py-3 pb-2" style="display:inline-block;">This template is currently being used for card creation via Microsoft Entra ID.</span>
            </div>
            <div class="modal-footer pt-0 px-4 pb-4 border-0">
                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     [style]="BUTTON_STYLES.TEXT"
                                     (onClick)="createDuplicateTempalteWarningModal.hide();"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>

                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Duplicate'"
                                     (onClick)="duplicateTemplate()"
                                     [disabled]="!hasWriteAccess"
                                     [style]="BUTTON_STYLES.STROKE"
                  ></beaconstac-button>
                </span>

                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Update'"
                                     (onClick)="updateTemplate()"
                                     [disabled]="!hasWriteAccess"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>
        </div>
    </div>
</div>
