import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkCsvValidationComponent } from './bulk-csv-validation.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';


@NgModule({
    declarations: [BulkCsvValidationComponent],
    imports: [
        CommonModule,
        ModalModule,
        PipesModule,
        FormsModule
    ],
    providers: [
    ],
    exports: [BulkCsvValidationComponent]
})
export class BulkCsvValidationModule { }
