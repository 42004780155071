import {Component, OnInit, ViewChild, HostListener, AfterViewInit} from '@angular/core';
import {DBCCardTypes} from '../../shared/utils';
import {OverlayService} from '../../global-services/overlay.service';
import {Router} from '@angular/router';
import {DigitalBusinessCardTemplateService} from '../digital-business-cards.service';
import {debounceTime, tap} from 'rxjs/operators';
import {DigitalBusinessCard, DigitalBusinessCardTemplateModel} from '../digital-business-card.model';
import {
    DBC_DESIGN_TYPE, TEMPLATE_OPERATIONS
} from '../digital-business-card-global-components/dbc-designs-grid-view/dbc-designs-grid-view.component';
import {BUTTON_STYLES, BUTTON_TYPES, TEXT_FIELD_VALIDATIONS} from 'kaizen-design-system';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {AuthService} from '../../global-services/auth.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';

export enum TEMPLATE_SAVE_REDIRECT_TO {
    SINGLE_BULK = 'bulk',
    MULTI_ORG_BULK = 'bulk-multi-org',
}

@Component({
    selector: 'app-digital-business-card-templates',
    templateUrl: './digital-business-card-templates.component.html',
    styleUrls: ['./digital-business-card-templates.component.scss']
})
export class DigitalBusinessCardTemplatesComponent implements OnInit, AfterViewInit {

    cardType: DBCCardTypes = DBCCardTypes.TEMPLATE_CARDS;
    DigitalBusinessCard = DigitalBusinessCard;
    digitalBusinessCardTemplates: Array<DigitalBusinessCardTemplateModel> = null;
    selectedCardTempalte = null;
    currentPage: number  = 1;
    totalResults: number = null;
    replacementTemplate_id: number = null
    renameErrorMessage: string = '';
    currentCardOperation: 'rename' | 'duplicate';
    newTemplateName: string = ''
    isMobileScreen: boolean = false;
    $resize: Subject<any> = new Subject<void>();

    private pageChangeSubject: Subject<any> = new Subject<{page: 1, pageSize: 11}>();
    protected readonly DBC_DESIGN_TYPE = DBC_DESIGN_TYPE;

    @ViewChild('renameCardTemplateModal', {static: false}) renameCardTemplateModal: ModalDirective;
    @ViewChild('createNewTempalteWarningModal', {static: false}) createNewTempalteWarningModal: ModalDirective;
    @ViewChild('selectReplacementTemplateModal', {static: false}) selectReplacementTemplateModal: ModalDirective;

    constructor(private overlayService: OverlayService, private router: Router,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private authService: AuthService, private messageModal: MessageModalService) {
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setDeviseType();
    }

    ngOnInit(): void {
        this.pageChangeSubject.pipe(debounceTime(200)).subscribe(event => {
            this.overlayService.isLoading(true);
            this.currentPage = event.page;
            this.fetchCardTemplates(this.currentPage, event.pageSize);
        })
        this.setDeviseType();
        this.$resize.pipe(debounceTime(300)).subscribe(() => {
            this.setDeviseType();
        })
        this.pageChangeSubject.next({page: 1, pageSize: 11});

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.setDeviseType();
        }, 500)
    }

    fetchCardTemplates(page: number, pageSize: number) {

        this.digitalBusinessCardTemplateService.getList(page, pageSize).pipe(
            tap(templates => {
                this.digitalBusinessCardTemplates = templates.objects;
                this.totalResults = templates.totalCount;
                this.overlayService.isLoading(false)
            })
        ).subscribe();
    }

    editTemplate(template) {
        if (!template) {
            this.addNewTempalte()
        }
        // Router navigates to card template edit page
        this.router.navigate([`/digital-business-cards/templates/edit/${template.id}`], { queryParams: { orgID: this.authService.getCurrentOrgId() }})
    }

    addNewTempalte() {
        this.router.navigate(['/digital-business-cards/templates/add'], { queryParams: { orgID: this.authService.getCurrentOrgId() }})
    }

    deleteTemplate(template) {
        if (template.meta.is_ad_integration_template) {
            // For AD templates there has to be a template available in AD config object
            this.messageModal.show('Assign a new template for Entra ID under Integrations to delete this one', 'warning', 5000);
        } else if (template.meta?.dbc_count === 0 || (this.digitalBusinessCardTemplates.length === 1 && (template.meta?.dbc_count === 0))) {
            // Delete directly
            this.confirmDeleteTemplate(template.id);
        } else if (this.digitalBusinessCardTemplates.length === 1 && (template.meta?.dbc_count !== 0)) {
            this.selectedCardTempalte = template;
            // Create a new template before deleting
            this.createNewTempalteWarningModal.show();
        } else if (template.meta?.dbc_count !== 0) {
            // Select a replacement template
            this.selectedCardTempalte = template;
            this.replacementTemplate_id = template.id;
            this.selectReplacementTemplateModal.show()
        }
    }

    selectReplaceTempalte(event) {
        this.replacementTemplate_id = event.value;
    }

    handleCardOperation(event) {
        switch (event.type) {
            case TEMPLATE_OPERATIONS.RENAME:
                this.selectedCardTempalte = event.value;
                this.currentCardOperation = 'rename';
                this.renameCardTemplateModal.show();
                break;
            case TEMPLATE_OPERATIONS.EDIT:
                this.editTemplate(event.value);
                break;
            case TEMPLATE_OPERATIONS.DELETE:
                this.deleteTemplate(event.value);
                break;
            case TEMPLATE_OPERATIONS.DUPLICATE:
                this.selectedCardTempalte = event.value;
                this.currentCardOperation = 'duplicate';
                this.renameCardTemplateModal.show();
                break;
        }
    }

    onPageChange(event) {
        this.pageChangeSubject.next({
            page: event,
            pageSize: 11
        })
    }

    protected readonly BUTTON_TYPES = BUTTON_TYPES;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;


    confirmDeleteTemplate(template_id, replacement_template_id: number = null) {
        let custom = '?organization=' + this.authService.getCurrentOrgId();
        if (replacement_template_id) {
            custom += '&replacement_template_id=' + replacement_template_id;
        }
        this.digitalBusinessCardTemplateService.delete(template_id, custom).pipe().subscribe(res => {
            this.selectReplacementTemplateModal.hide();
            this.messageModal.show('Deleted successfully', 'success', 3000);
            this.onPageChange(1);
        }, error => {
            this.messageModal.show('Error deleting template, Please try again later', 'danger', 3000);
        })
    }

    checkForDuplicateName(name) {
        const body = {
            'template_name': name,
            'organization': this.authService.getCurrentOrgId()
        }
        this.digitalBusinessCardTemplateService.post(body, 'check-unique-template-name/').pipe().subscribe(res => {
            this.renameErrorMessage = '';
            if (this.currentCardOperation === 'rename') {
                this.selectedCardTempalte.template_name = name;
                this.saveTemplate()
            } else {
                this.duplicateTemplate(name);
            }
            // Here decide what to do now
        }, error => {
            if (error.status === 409) {
                this.renameErrorMessage = 'Name already exists. Use a different name'
            }
        })
    }

    saveTemplate() {
        this.digitalBusinessCardTemplateService.put(this.selectedCardTempalte, this.selectedCardTempalte.id, '?organization=' + this.selectedCardTempalte.organization).pipe().subscribe(res => {
            this.messageModal.show('Tempalte saved successfully', 'success', 3000);
            this.renameCardTemplateModal.hide()
            this.pageChangeSubject.next({
                page: 1,
                pageSize: 11
            })
            this.overlayService.isLoading(true);
        }, error => {
            this.messageModal.show('Error saving data', 'danger', 3000);
        })
    }

    duplicateTemplate(newName) {
        const duplicateTempalteData = new DigitalBusinessCardTemplateModel(this.selectedCardTempalte);
        delete duplicateTempalteData.id
        duplicateTempalteData.template_name = newName

        // Remove user_agreement_urls with empty values
        duplicateTempalteData.lead_user_agreement_attribute.user_agreement_urls = duplicateTempalteData.lead_user_agreement_attribute.user_agreement_urls.filter(res => {
            delete res.__valid_url__;
            return (res.url && res.label) ? res : null;
        });

        this.digitalBusinessCardTemplateService.post(duplicateTempalteData).pipe().subscribe(res => {
            this.messageModal.show('Duplicated successfully', 'success', 3000);
            this.renameCardTemplateModal.hide()
            this.pageChangeSubject.next({
                page: 1,
                pageSize: 11
            })
            this.overlayService.isLoading(true);
        }, error => {
            this.messageModal.show('Error duplicating template', 'danger', 3000);
        })
    }

    onTemplateNameSave() {
        this.checkForDuplicateName(this.newTemplateName);
    }

    setDeviseType(): void {
        this.isMobileScreen = (window.innerWidth <= 700);
    }

    protected readonly TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
}


