<div class="bx--row">
    <div class="bx--col">
        <div class="bx--row mt-3" [ngClass]="!product.lead_collection ? 'mb-3' : ''">
            <div class="d-flex ml-3">
                <beaconstac-toggle
                    [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2"
                    [checked]="orgCardSetting.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.NO ? false :
                                           orgCardSetting.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES ? true : product.lead_collection"
                    (checkedChange)="toggleLeadCollection($event)">
                    >
                </beaconstac-toggle>
                <div class="toggle-btn-description">
                    <span class="font-weight-400 mr-2 fs-15 description-text">Two way contact sharing</span>
                    <span [ngClass]="cardSetting.lead_collection | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2)"></span>
                    <br>
                    <span class="font-weight-400 subtitle-text">Collect contact information from potential leads</span>
                </div>
            </div>
        </div>

        <div [@collapse] *ngIf="product.lead_collection || orgCardSetting.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES" class="bx--col-lg-8 my-3 px-0 leads-settings-container">
            <div class="bx--row pt-3 mx-0">
                <div class="bx--col-lg-8 bx--col-md-8 bx--col-sm-4 pb-3 share-first-container">
                    <div class="lead-type-option py-3 px-3 h-100" [ngClass]="product.lead_attribute.connection === LEAD_COLLECT_TYPE.COLLECT_FIRST ? 'selected': ''">
                        <beaconstac-radio-button
                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2"
                            (onClick)="toggleLeadCollectType($event, LEAD_COLLECT_TYPE.COLLECT_FIRST)"
                            [options]="[{ name: 'Collect first', state: product.lead_attribute.connection === LEAD_COLLECT_TYPE.COLLECT_FIRST, disabled: !hasWriteAccess }]">
                        </beaconstac-radio-button>
                        <span class="info-text">Collect your lead’s details before your card is displayed to them.</span>
                    </div>
                </div>
                <div class="bx--col-lg-8 bx--col-md-8 bx--col-sm-4 pb-3 collect-first-container">
                    <div class="lead-type-option py-3 px-3 h-100" [ngClass]="product.lead_attribute.connection === LEAD_COLLECT_TYPE.SHARE_FIRST ? 'selected': ''">
                        <beaconstac-radio-button
                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2"
                            (onClick)="toggleLeadCollectType($event, LEAD_COLLECT_TYPE.SHARE_FIRST);"
                            [options]="[{ name: 'Share first', state: product.lead_attribute.connection === LEAD_COLLECT_TYPE.SHARE_FIRST, disabled: !hasWriteAccess }]">
                        </beaconstac-radio-button>
                        <span class="info-text">Share your contact before asking the lead for their details.</span>
                    </div>
                </div>
            </div>

            <div class="pt-2 pb-2">
                            <span class="font-13 line-height-15 pl-3 font-normal font-weight-400 letter-spacing-q text-custom-gray">
                                Select what data you want to collect
                            </span>
            </div>
            <div class="bx--row">
                <div *ngFor="let leadSetting of leadSettings" class="bx--col-lg-7 bx--col-md-3 bx--col-sm-4 ml-3">
                    <beaconstac-checkbox
                        [label]="leadSetting.label"
                        [disabled]="leadSetting.disable" [checked]="leadSetting.default || product.lead_attribute[leadSetting.name]" (checkedChange)="toggleLeadSetting($event, leadSetting)"
                    ></beaconstac-checkbox>
                </div>
            </div>

            <div class="pb-2"></div>
        </div>

        <div class="bx--row mx-0"  *ngIf="product.lead_collection || orgCardSetting.lead_collection_v2 === DBC_ORG_PERMISSION_OPTIONS.YES" [@collapse]>
            <div class="bx--col mb-3 lead-tos-container">
                <div class="bx--row user-agreement-container">
                    <div class="d-flex ml-3">
                        <beaconstac-toggle
                            [checked]="product.lead_user_agreement"
                            [disabled]="hasWriteAccess | disableFieldCheckPipe: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_USER_AGREEMENT"
                            (checkedChange)="toggleTermsOfService($event)"
                            [id]="'tos-toggle'">
                            >
                        </beaconstac-toggle>
                        <div class="toggle-btn-description">
                            <span class="font-weight-400 mr-2 description-text">User Agreement</span>
                            <span [ngClass]="cardSetting.lead_user_agreement | inputIconPipe: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_USER_AGREEMENT: cardSetting: orgCardSetting" (click)="handleIconClick(CARD_PROPERTY_TYPES.LEAD_USER_AGREEMENT)"></span>
                            <br>
                            <span class="font-weight-400 subtitle-text">Seek consent from your leads before reaching out</span>
                        </div>
                    </div>
                </div>

                <div class="bx--row" *ngIf="product.lead_user_agreement">
                    <div class="bx--col">
                        <app-terms-service [hasWriteAccess]="hasWriteAccess" [(termsOfService)]="product.lead_user_agreement_attribute.user_agreement_urls" (termsOfServiceChange)="productChange.emit(product)" [orgCardSetting]="orgCardSetting" [cardSetting]="cardSetting" [cardType]="cardType"></app-terms-service>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
