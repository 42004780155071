import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SalesforceComponent } from './salesforce.component';

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Salesforce'
        },
        component: SalesforceComponent,
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SalesforceRouterModule { }
