<div class="bx--row">
    <div class="bx--col" [ngStyle]="{'max-width': width}">
        <h4 *ngIf="showHeading" class="sectionHeading" style="margin-top:28px">CHOOSE TEMPLATE</h4>
        <beaconstac-filter-dropdown
            [listItems]="templateList"
            [value]="selectedTemplate ? { name: selectedTemplate.template_name, value: selectedTemplate.id} : showNoneOption ? {name: 'None', value: 'none'} : {name: '', value: ''}"
            [title]="'card-template-dropdown-' + id"
            [label]="labelText"
            [optionValueField]="'value'"
            [optionLabelField]="'name'"
            [enableInfiniteScrolling]="true"
            (valueChange) = "onTemplateSelect($event)"
            (inputChangeEvent) = "onTempalteSearch($event)"
            (fetchMoreData)="onFetchMoreTempaltes()"
            [isFetchingData]="fetchingTempaltes"
            [dynamicSearch]="true"
            [width]="width"
            [id]="'cards-template-dropdown-' + id"
            [optionsHeaderText]="!this.hasTemplates && this.allowFetchingMasterOrgTemplates ? 'FROM MASTER-ORG' : null"
            [placeholder]="placeholder"
        ></beaconstac-filter-dropdown>
    </div>
    <div class="px-0 bx--col-sm-0 bx--col-md-0 bx--col-lg-1 d-flex align-items-end" *ngIf="isInitialFetch" style="max-width: 40px;">
        <spinner class="custom-spinner-analytics" [size]="40" [tickness]="2">
        </spinner>
    </div>
</div>
