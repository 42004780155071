import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';

// Routing
import {UserDetailRoutingModule} from './user-detail-routing.module';

import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TablePagingModule} from '../global-components/table-paging/table-paging.module';
import {LowerCaseTextInputModule} from '../shared/lower-case-text-input/lower-case-text-input.module';
import {UserDetailComponent} from './user-detail.component';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import {MessageModalModule} from '../shared/message-modal/message-modal.module';
import {BeaconstacModule} from 'kaizen-design-system';

const CustomSelectOptions: INgxSelectOptions = {
    optionValueField: 'id',
    keepSelectedItems: true,
    noResultsFound: '',
};

@NgModule({
    imports: [
        CommonModule,
        UserDetailRoutingModule,
        FormsModule,
        ModalModule.forRoot(),
        TablePagingModule,
        TooltipModule.forRoot(),
        LowerCaseTextInputModule,
        NgxSelectModule.forRoot(CustomSelectOptions),
        MessageModalModule,
        BeaconstacModule
    ],
    declarations: [
        UserDetailComponent
    ],
    providers: []
})

export class UserDetailModule { }
