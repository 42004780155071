import { DATE_FORMAT, Intervals, linksInMessageExpression } from 'app/shared/utils';
import * as moment from 'moment-timezone';

enum ProductTypes {
    Beacon = 'beacon',
    QR = 'qr',
    NFC = 'nfc',
    Geofence = 'geofence'
}

enum PageType {
    INDIVIDUAL = 'individual',
    AGGREGATE = 'aggregate',
}

enum SortTypes {
    ASC = 'ascending',
    DSC = 'descending',
}

enum TableColumns {
    SCANS = 'SCANS',
    CITY = 'CITY',
    PERCENTAGE = '% OF SCANS',
    COUNTRY = 'COUNTRY',
    STATE = 'STATE/PROVINCE',
}

enum RedirectedFrom {
    BANNER = 'banner',
    CITYCARD = 'City',
    TIMECARD = 'Time',
    LOCATIONCARD = 'Location',
    DEVICECARD = 'Device',
}
const campaignTypesMap = {
    '1': 'Website',
    '2': 'Landing Pages',
    '3': 'Form',
    '4': '',
    '5': 'App Download',
    '6': '',
    '7': 'Digital Business Card (vCard)',
    '8': 'Social Media',
    '9': 'Facebook Page',
    '10': 'Coupon Code',
    '11': 'Business Page',
    '12': '',
    '13': 'PDF',
    '14': 'Restaurant Menu',
    '15': 'Call',
    '16': 'SMS',
    '17': 'Email',
    '18': 'Linkpage',
    '19': 'Image',
    '20': 'MP3',
    '21': 'Location',
    '22': 'Geolocation',
    '23': 'Smart rules'
}

const getFromAndToDates = (timeZone: string) => {
    const toDate = moment().clone().tz(timeZone);
    const fromDate = moment(toDate).clone().tz(timeZone);
    const interval = '1d';
    return { toDate, fromDate, interval };
};

const getFormattedDate = (date) => date.format(DATE_FORMAT.DATE_FORMAT_STRING);

const getFormattedNumber = (num) => num?.toLocaleString();

const getAscendingSortValue = (a, b) => a > b ? -1 : a < b ? 1 : 0;

const getDescendingSortValue = (a, b) => a < b ? -1 : a > b ? 1 : 0;

const getSortedTableValue = (a, b, key, order) => {
    let { text: textA } = a.dataItems[key].value;
    let { text: textB } = b.dataItems[key].value;
    if (key === TableColumns.PERCENTAGE || key === 'percentage') {
        textA = +textA.split('%')[0];
        textB = +textB.split('%')[0];
    }

    // Check for both variables (textA and textB) that they are numbers or not.
    if (!isNaN(textA)) {
        textA = +textA;
    }
    if (!isNaN(textB)) {
        textB = +textB;
    }

    if (order === SortTypes.ASC) {
        return getAscendingSortValue(textA, textB);
    } else {
        return getDescendingSortValue(textA, textB);
    }
}

// Provides the Analytics API payload
const getPayloadBody = (params) => {
    const payloadBody = {};
    for (const key in params) {
        if (params[key] || params[key]?.length) {
            payloadBody[key] = params[key]
        }
    }
    return payloadBody;
};

const getPreviousPayloadBody = (selectedIntervalId: number, { from, to, timezone, with_tags, product_id, interval, format = 'x', product = 'qr' }: any) => {
    let { toDate, fromDate } = getFromAndToDates(timezone);
    let previousDateFormatString = '';
    let dayDifference = 0;

    switch (selectedIntervalId) {
        case Intervals.TODAY:
            dayDifference = to.diff(from, 'days');
            fromDate = moment(from).subtract(dayDifference + 1, 'days');
            toDate = moment(to).subtract(dayDifference + 1, 'days');
            interval = '30m';
            previousDateFormatString = getFormattedDate(fromDate);
            break;
        case Intervals.YESTERDAY:
            dayDifference = to.diff(from, 'days');
            if (product === 'linkpage') {
                fromDate = moment(from).subtract(dayDifference + 1, 'days');
                toDate = moment(to).subtract(dayDifference + 1, 'days');
                interval = '1d';
            } else {
                fromDate = moment(from).subtract(dayDifference, 'days');
                toDate = moment(to).subtract(dayDifference, 'days');
            }
            previousDateFormatString = getFormattedDate(fromDate);
            break;
        case Intervals.CUSTOM:
            dayDifference = to.diff(from, 'days');
            if (dayDifference > 0) {
                fromDate = moment(from).subtract(dayDifference + 1, 'days');
                toDate = moment(to).subtract(dayDifference + 1, 'days');
                interval = '1d';
                previousDateFormatString = `${getFormattedDate(fromDate)} to ${getFormattedDate(toDate)}`;
            } else {
                fromDate = moment(from).subtract(dayDifference + 1, 'days');
                toDate = moment(to).subtract(dayDifference + 1, 'days');
                previousDateFormatString = getFormattedDate(fromDate);
            }
            break;
        default:
            fromDate = fromDate.subtract(selectedIntervalId * 2, 'day').add(1, 'day').startOf('day');
            toDate = toDate.subtract(selectedIntervalId, 'day').endOf('day');
            interval = '1d';
            previousDateFormatString = `${getFormattedDate(fromDate)} to ${getFormattedDate(toDate)}`;
            break;
    }

    fromDate = fromDate.format(format);
    toDate = toDate.format(format);

    const payload = getPayloadBody({ from: fromDate, to: toDate, timezone, interval, with_tags, product_id });
    return { payload, previousDateFormatString }
}

// Processes the interval Data for selected date range
const processIntervalData = (fromDate, toDate, interval, timeZone) => {
    const intervalData = [];
    const notificationData = [];
    const clicksData = [];
    const ctrData = [];

    if (interval === '1d') {
        const date = fromDate.clone().tz(timeZone);
        const difference = toDate.startOf('day').diff(fromDate.startOf('day'), 'days');
        for (let i = 0; i <= difference; i++) {
            const formattedDate = getFormattedDate(date);
            intervalData.push(Number.parseInt(date.format('x')));
            const obj = { name: formattedDate, value: 0 };

            notificationData.push(obj);
            clicksData.push(Object.assign({}, obj));
            ctrData.push(Object.assign({}, obj));
            date.add(1, 'day');
        }
    } else {
        const difference = 48;
        const date = fromDate.clone().tz(timeZone);
        for (let i = 0; i < difference; i++) {
            const formattedDate = date.format('HH:mm');
            intervalData.push(Number.parseInt(date.format('x')));

            const obj = { name: formattedDate, value: 0 };
            notificationData.push(obj);
            clicksData.push(Object.assign({}, obj));
            ctrData.push(Object.assign({}, obj));
            date.add(0.5, 'hour');
        }
    }
    return { intervalData, notificationData, clicksData, ctrData };
};

// Processes the initial API response for analytics API
const processDataResponse = (
    response,
    intervalData,
    notificationData,
    clicksData,
    ctrData
) => {
    const { points } = response;
    points.forEach((point) => {
        const intervalDataIndex = intervalData.indexOf(point[0]);

        notificationData[intervalDataIndex]['value'] = point[1];
        clicksData[intervalDataIndex]['value'] = point[2];

        if (points[1] > 0) {
            const ctr = (point[2] / point[1]) * 100;
            ctrData[intervalDataIndex]['value'] = ctr.toFixed(2);
        }
    });
    return { interval: intervalData, notification: notificationData, clicks: clicksData, ctr: ctrData };
};

const processDataObject = (name, series) => {
    return { name, series };
};

const calculatePercentageChange = (newValue, oldValue) => {
    if (typeof newValue === 'number'){
        newValue = newValue + '';
    }
    if (typeof oldValue === 'number'){
        oldValue = oldValue + '';
    }

    oldValue = Number(oldValue.replace(/,/g, ''));
    newValue = Number(newValue.replace(/,/g, ''));
    if (+oldValue === 0 && +newValue === 0) {
        return 0;
    } else if (+oldValue === 0) {
        return 100;
    } else if (+newValue === 0) {
        return -100;
    }

    return (((newValue - oldValue) / oldValue) * 100).toFixed(2);
};

const getProcessedDayDifference = (intervalId, globalToDate, globalFromDate, product = 'qr') => {
    const dayDifference = globalToDate.diff(globalFromDate, 'days');

    if (product === 'linkpage') {
        return dayDifference + 1;
    } else {
        if (intervalId !== Intervals.YESTERDAY) {
            return dayDifference + 1;
        }
        return dayDifference;
    }
}

const getDatesInRange = ({ startDate, endDate, timeZone, dayDifference, product = 'qr' }) => {
    const type = dayDifference <= 1 ? 'hours' : 'days';
    let dayDiff = moment(endDate).diff(moment(startDate), type);
    const dates = [];
    dayDiff = dayDifference <= 1 ? 48 : dayDiff;
    const date = moment(startDate).tz(timeZone).startOf('day')
    dates.push(+date.format('x'))
    for (let i = 1; i <= dayDiff; i++) {
        dates.push(+date.add(dayDifference > 1 ? 1 : (i === 48 ? .49 : .5), dayDifference > 1 ? 'days' : 'hours').format('x'))

        if (type === 'days' && moment(dates[i]).diff(moment(dates[i - 1]), 'days') === 0) {
            dates[i] = moment(dates[i - 1]).tz(timeZone).add(24, 'hours').valueOf();
            +date.add(24, 'hours').startOf('day').format('x')
        }
    }

    return dates.sort();
}

const shareAnalyticsMessageValidator = (message) => {
    const matchedUrls = message.match(linksInMessageExpression);
    return matchedUrls && matchedUrls.length > 0;
}

const dummyTimeSeries = [
    {
        'name': '12am',
        'value': 0
    },
    {
        'name': '1am',
        'value': 0
    },
    {
        'name': '2am',
        'value': 0
    },
    {
        'name': '3am',
        'value': 0
    },
    {
        'name': '4am',
        'value': 0
    },
    {
        'name': '5am',
        'value': 0
    },
    {
        'name': '6am',
        'value': 0
    },
    {
        'name': '7am',
        'value': 0
    },
    {
        'name': '8am',
        'value': 0
    },
    {
        'name': '9am',
        'value': 0
    },
    {
        'name': '10am',
        'value': 0
    },
    {
        'name': '11am',
        'value': 0
    },
    {
        'name': '12pm',
        'value': 0
    },
    {
        'name': '1pm',
        'value': 0
    },
    {
        'name': '2pm',
        'value': 0
    },
    {
        'name': '3pm',
        'value': 0
    },
    {
        'name': '4pm',
        'value': 0
    },
    {
        'name': '5pm',
        'value': 0
    },
    {
        'name': '6pm',
        'value': 0
    },
    {
        'name': '7pm',
        'value': 0
    },
    {
        'name': '8pm',
        'value': 0
    },
    {
        'name': '9pm',
        'value': 0
    },
    {
        'name': '10pm',
        'value': 0
    },
    {
        'name': '11pm',
        'value': 0
    }
]

const dummyTimeSeriesWithExtremeValues = [
    {
        'name': '12am',
        'value': 1
    },
    {
        'name': '1am',
        'value': 0
    },
    {
        'name': '2am',
        'value': 0
    },
    {
        'name': '3am',
        'value': 0
    },
    {
        'name': '4am',
        'value': 0
    },
    {
        'name': '5am',
        'value': 0
    },
    {
        'name': '6am',
        'value': 0
    },
    {
        'name': '7am',
        'value': 0
    },
    {
        'name': '8am',
        'value': 0
    },
    {
        'name': '9am',
        'value': 0
    },
    {
        'name': '10am',
        'value': 0
    },
    {
        'name': '11am',
        'value': 0
    },
    {
        'name': '12pm',
        'value': 0
    },
    {
        'name': '1pm',
        'value': 0
    },
    {
        'name': '2pm',
        'value': 0
    },
    {
        'name': '3pm',
        'value': 0
    },
    {
        'name': '4pm',
        'value': 0
    },
    {
        'name': '5pm',
        'value': 0
    },
    {
        'name': '6pm',
        'value': 0
    },
    {
        'name': '7pm',
        'value': 0
    },
    {
        'name': '8pm',
        'value': 0
    },
    {
        'name': '9pm',
        'value': 0
    },
    {
        'name': '10pm',
        'value': 0
    },
    {
        'name': '11pm',
        'value': 1
    }
]

const dummyTimeDistribution = [
    {
        'name': 'Mon',
        'series': dummyTimeSeriesWithExtremeValues
    },
    {
        'name': 'Tue',
        'series': dummyTimeSeries
    },
    {
        'name': 'Wed',
        'series': dummyTimeSeries
    },
    {
        'name': 'Thu',
        'series': dummyTimeSeries
    },
    {
        'name': 'Fri',
        'series': dummyTimeSeries
    },
    {
        'name': 'Sat',
        'series': dummyTimeSeries
    },
    {
        'name': 'Sun',
        'series': dummyTimeSeriesWithExtremeValues
    }
]

const dummyCityDistribution = [
    {
        'dataObject': {
            'city': {
                'type': 'single',
                'mobile': true,
                'value': {
                    'text': 'dummy'
                }
            },
            'state': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'country': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'scans': {
                'type': 'single',
                'value': {
                    'text': 0
                }
            },
            'percentage': {
                'type': 'single',
                'value': {
                    'text': '100%'
                }
            }
        },
        'dataItems': {
            'city': {
                'type': 'single',
                'mobile': true,
                'value': {
                    'text': 'dummy'
                }
            },
            'state': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'country': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'scans': {
                'type': 'single',
                'value': {
                    'text': 0
                }
            },
            'percentage': {
                'type': 'single',
                'value': {
                    'text': '100.0%'
                }
            }
        },
        'toolbarIcons': {
            'mobile': [
                {
                    'class': 'fas fa-info-circle',
                    'color': '#5E6980',
                    'tooltip': 'Info',
                    'event': {
                        'type': 'link',
                        'operation': 'modal',
                        'output': 'function()'
                    }
                }
            ]
        }
    },
    {
        'dataObject': {
            'city': {
                'type': 'single',
                'mobile': true,
                'value': {
                    'text': 'Bengaluru'
                }
            },
            'state': {
                'type': 'single',
                'value': {
                    'text': 'Karnataka'
                }
            },
            'country': {
                'type': 'single',
                'value': {
                    'text': 'India'
                }
            },
            'scans': {
                'type': 'single',
                'value': {
                    'text': 1
                }
            },
            'percentage': {
                'type': 'single',
                'value': {
                    'text': '14.3%'
                }
            }
        },
        'dataItems': {
            'city': {
                'type': 'single',
                'mobile': true,
                'value': {
                    'text': 'dummy'
                }
            },
            'state': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'country': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'scans': {
                'type': 'single',
                'value': {
                    'text': 0
                }
            },
            'percentage': {
                'type': 'single',
                'value': {
                    'text': '100.0%'
                }
            }
        },
        'toolbarIcons': {
            'mobile': [
                {
                    'class': 'fas fa-info-circle',
                    'color': '#5E6980',
                    'tooltip': 'Info',
                    'event': {
                        'type': 'link',
                        'operation': 'modal',
                        'output': 'function()'
                    }
                }
            ]
        }
    },
    {
        'dataObject': {
            'city': {
                'type': 'single',
                'mobile': true,
                'value': {
                    'text': 'dummy'
                }
            },
            'state': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'country': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'scans': {
                'type': 'single',
                'value': {
                    'text': 0
                }
            },
            'percentage': {
                'type': 'single',
                'value': {
                    'text': '100%'
                }
            }
        },
        'dataItems': {
            'city': {
                'type': 'single',
                'mobile': true,
                'value': {
                    'text': 'dummy'
                }
            },
            'state': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'country': {
                'type': 'single',
                'value': {
                    'text': 'dummy'
                }
            },
            'scans': {
                'type': 'single',
                'value': {
                    'text': 0
                }
            },
            'percentage': {
                'type': 'single',
                'value': {
                    'text': '100.0%'
                }
            }
        },
        'toolbarIcons': {
            'mobile': [
                {
                    'class': 'fas fa-info-circle',
                    'color': '#5E6980',
                    'tooltip': 'Info',
                    'event': {
                        'type': 'link',
                        'operation': 'modal',
                        'output': 'function()'
                    }
                }
            ]
        }
    }
]

const dummyOsDistribution = [{
    'name': 'dummy1',
    'value': 1,
    'percentage': '50.00',
    'color': '#2595FF'
}, {
    'name': 'dummy2',
    'value': 1,
    'percentage': '50.00',
    'color': '#26B969'
}]

const dummyMapCoordinates = [
    {
        'lat': 28.7041,
        'lng': 77.1025
    }
];

enum BannerConfigTypes {
    GenerateQrCodes = 'generateQrCodes',
    GenerateDynamicQrCodes = 'generateDynamicQrCodes',
    NoScansYet = 'noScansYet',
    Trial = 'trial',
    StepAwayFrom5Scans = 'stepAwayFrom5Scans',
    GetAttention = 'getAttention',
    Location = 'location',
    Time = 'time',
    DeviceOs = 'deviceOs'
}

const generateBannerConfigs = ({ type, data }: { type: BannerConfigTypes,  data?: number | string }) => {
    const bannerConfigs: Record<BannerConfigTypes, { header: string; subText: string; emoji: string; buttonText: string; }> = {
        generateQrCodes: {
            header: 'Generate QR Codes easily with Uniqode’s FREE QR Codes generator',
            subText: 'What are you waiting for? Create custom QR Codes in few clicks!',
            emoji: '👑',
            buttonText: 'Create your first QR Code',
        },
        generateDynamicQrCodes: {
            header: 'Generate trackable QR Codes with Uniqode’s FREE QR Codes Generator',
            subText: 'Start creating custom QR Codes in just a few clicks and track their performance with ease!',
            emoji: '👑',
            buttonText: 'Generate dynamic QR Codes',
        },
        noScansYet: {
            header: 'No scans on your QR Codes yet?',
            subText: 'Scan your QR Codes and double-check if it’s working fine.',
            emoji: '👀',
            buttonText: 'Go to QR Codes',
        },
        trial: {
            header: 'Congrats! You’ve unlocked our premium features with the Trial plan',
            subText: 'Ready to keep using them? Upgrade to Pro and higher plans.',
            emoji: '👑',
            buttonText: 'Upgrade Plan',
        },
        stepAwayFrom5Scans: {
            header: 'You are a step away from getting your first 5 scans',
            subText: 'Download your QR Codes and share to get more scans.',
            emoji: '📈',
            buttonText: 'Go to QR Codes',
        },
        getAttention: {
            header: 'Curious about why your QR Codes are not getting the attention it deserves?',
            subText: 'Download your QR Codes and share them to get more scans.',
            emoji: '👀',
            buttonText: 'Go to QR Codes',
        },
        location: {
            header: `Your QR Codes have been scanned in ${data} different locations. Need more info?`,
            subText: 'In last 30 days,',
            emoji: '📍',
            buttonText: 'Upgrade Plan',
        },
        time: {
            header: `${data}% of your scans happen during a specific time of the day`,
            subText: 'Upgrade your plan to know the exact time when your QR Codes are getting scanned.',
            emoji: '🕒',
            buttonText: 'Upgrade Plan',
        },
        deviceOs: {
            header: `Android vs iPhone! ${data}% of your scanners prefer a specific device`,
            subText: 'Upgrade your plan and see if it’s Android users or iPhone users driving the most engagement.',
            emoji: '📱',
            buttonText: 'Upgrade Plan',
        }
    };

    return bannerConfigs[type];
}


export {
    ProductTypes,
    BannerConfigTypes,
    SortTypes,
    TableColumns,
    PageType,
    RedirectedFrom,
    campaignTypesMap,
    dummyTimeDistribution,
    dummyCityDistribution,
    dummyOsDistribution,
    dummyMapCoordinates,
    generateBannerConfigs,
    getFromAndToDates,
    getDatesInRange,
    getFormattedDate,
    getFormattedNumber,
    getPayloadBody,
    getPreviousPayloadBody,
    processIntervalData,
    processDataResponse,
    processDataObject,
    calculatePercentageChange,
    getAscendingSortValue,
    getDescendingSortValue,
    getProcessedDayDifference,
    getSortedTableValue,
    shareAnalyticsMessageValidator
};
