import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {LanguageService} from './language.service';
import {BUTTON_TYPES} from 'kaizen-design-system';
import {NotificationListType} from '../../shared/utils';
import {LocationService} from './location.service';

@Component({
    selector: 'beaconstac-notification-language',
    templateUrl: './notification-language.component.html',
    styleUrls: ['./notification-language.scss']
})


export class NotificationLanguageComponent implements OnInit, OnChanges {

    @Output() onLanguageAdded: EventEmitter<object> = new EventEmitter();
    @Input() disabledLanguages: Array<string> = [];
    @Input() disabledLanguagesLength: number = 0;
    @Input() noTitle: boolean = false;
    @Input() listType: NotificationListType = NotificationListType.LANGUAGE;

    NotificationListType = NotificationListType
    allItems: Array<string>;
    selectedItem: string = 'English';
    title: string = '';
    description: string = '';
    allLanguagesList: Array<any> = [];

    BUTTON_TYPES = BUTTON_TYPES;

    @ViewChild('languageModal', {static: false}) private modal: ModalDirective;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabledLanguagesLength) {
            this.onDisabledLanguagesChange();
        }
    }

    constructor(private languageService: LanguageService, private locationService: LocationService) {
    }

    ngOnInit(): void {

        if (this.listType === NotificationListType.LANGUAGE) {
            this.allItems = this.languageService.getAllLanguages();
            this.selectedItem = 'English'
        } else {
            this.allItems = this.locationService.getAllCountries();
            this.selectedItem = 'United States'
        }

        this.convertToDropDownList();
    }

    convertToDropDownList() {
        this.allLanguagesList = this.allItems.map(val => {
            return { 'name': val, 'value': val };
        })
    }
    addLanguage() {
        const code = this.listType === NotificationListType.LANGUAGE ? this.languageService.getLanguageCode(this.selectedItem) :
            this.locationService.getCountryCode(this.selectedItem)
        const translation = {
            code: code,
            title: this.title,
            description: this.description,
            language: this.selectedItem
        };
        this.onLanguageAdded.emit(translation);
        this.modal.hide();
        this.resetLanguage();
    }

    show(): void {
        this.modal.show();
    }

    resetLanguage(): void {
        this.modal.hide();
        this.selectedItem = this.allItems[0];
        this.title = '';
        this.description = '';
    }

    onDisabledLanguagesChange(): void {
        const items = this.listType === NotificationListType.LANGUAGE
            ? this.languageService.getAllLanguages() : this.locationService.getAllCountries();
        for (const disabled of this.disabledLanguages) {
            const disabledLanguage =
                this.listType === NotificationListType.LANGUAGE ? this.languageService.getLanguageByCode(disabled)
                    : this.languageService.getLanguageByCode(disabled)
            items.splice(items.indexOf(disabledLanguage), 1);
        }
        this.allItems = items;
        this.convertToDropDownList();
        this.selectedItem = this.allItems[0];
    }
}
