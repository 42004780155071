import {Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    BUTTON_STYLES,
    BUTTON_TYPES,
    DROPDOWN_STYLES,
    DROPDOWN_TYPES,
    ICON_SIZE,
    TEXT_FIELD_TYPES,
    TEXT_FIELD_VALIDATIONS,
} from 'kaizen-design-system';
import {Filter, FILTER_OPERATOR_TYPE, FilterOperator} from './filter.model';
import {SearchParams} from '../../global-services/base-backend.service';
import {BehaviorSubject, fromEvent, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, map, takeUntil} from 'rxjs/operators';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import * as moment from 'moment-timezone';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService, } from '../../global-services/amplitude.service';
import {decodeBase64EncodedString, PRODUCT_TYPES, QR_FILTER_OPTIONS} from '../../shared/utils';
import { UserService } from 'app/user-account/user.service';


@Component({
    selector: 'beaconstac-filter-products',
    templateUrl: './filter-products.component.html',
    styleUrls: ['./filter-products.component.scss'],
})
export class FilterProductsComponent implements OnInit, OnChanges, OnDestroy {
    BUTTON_STYLES = BUTTON_STYLES;
    DROPDOWN_STYLES = DROPDOWN_STYLES;
    BUTTON_TYPES = BUTTON_TYPES;
    DROPDOWN_TYPES = DROPDOWN_TYPES;
    ICON_SIZE = ICON_SIZE;
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;

    appliedFilters: Array<Filter> = [];
    selectedFilter: Filter;
    filterToBeApplied: any = undefined;
    filterToBeAppliedForMobile: any = undefined;
    FILTER_OPERATOR_TYPE = FILTER_OPERATOR_TYPE;
    filterValueSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    defaultValueEmitted = false;
    isExpanded: boolean = false;
    hasChangedFilterOperator: boolean = false;
    dateUpdated: boolean;
    validationErrors = {};
    @Input() isUsedInAnalyticsComponent: boolean = false;

    ngUnsubscribe: Subject<any> = new Subject();

    dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    isMobileScreenSize: boolean;
    isShowFilterMobileDrawer: boolean = false;
    isShowFiltersComponent: boolean = false;

    activeUsersPageNumber: number = 1;
    isFetchingActiveUserList: boolean = false;
    activeUsersList: Array<any> = [];
    activeUsersSearchParams: any = {};
    activeUsersSearchText: string = '';

    isApplyButtonDisabled: boolean = false;

    @Input() filterOptions: Array<Filter>;
    localFilterOptions: Array<Filter>;

    @Input() filterQueryParams: any;
    @Input() isFetachingTags: boolean;
    @Input() qrCodeCount: number;
    @Input() tagList: Array<any>;
    @Input() dbcTemplatesList: Array<any>;
    @Input() productType = PRODUCT_TYPES.QR
    @Input() mobileDrawer: boolean = false;

    @Output() filterChange: EventEmitter<SearchParams> = new EventEmitter();
    @Output() onTagSearchInput: EventEmitter<string> = new EventEmitter();

    constructor(private amplitude: AmplitudeService, private userService: UserService) {}
    resizeSubject = new Subject();
    ngOnInit() {
        this.isMobileScreen();
        fromEvent(window, 'resize')
            .pipe(
                map(() => window.innerWidth),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe((width: number) => {
                this.resizeSubject.next(width);
            });

        this.resizeSubject.subscribe((width: number) => {
            this.isMobileScreenSize = width <= 767;
        });
        this.filterValueSubject
            .pipe(
                debounceTime(500),
                distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
            )
            .subscribe((params) => {
                if (this.defaultValueEmitted) {
                    const event_data = {};
                    for (const filter of QR_FILTER_OPTIONS) {
                        event_data[filter.value] = this.appliedFilters.some(
                            (appliedFilter) => appliedFilter.value === filter.value,
                        );
                    }
                    if (this.productType === PRODUCT_TYPES.QR) {
                        this.amplitude.logEvent(
                            AMPLITUDE_EVENT_CATEGORIES.Usage,
                            AMPLITUDE_EVENTS.QR_FILTERS_COMBINATION,
                            event_data,
                        );
                        if (!this.isUsedInAnalyticsComponent){
                            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.QR_VIEW, {});
                        }
                    }
                    this.filterChange.emit(params);
                } else {
                    this.defaultValueEmitted = true;
                }
            });
    }

    isMobileScreen() {
        this.isMobileScreenSize = window.innerWidth <= 767;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.filterQueryParams?.currentValue) {
            // convert base64 to json
            this.generateFiltersUsingQueryParams();
        }

        if (changes?.tagList?.currentValue) {
            this.updateTagList(changes.tagList.currentValue);
        }
        if (changes.qrCodeCount?.currentValue === 0 && this.appliedFilters.length) {
            const event_data = { hasResults: false };
            if (this.productType === PRODUCT_TYPES.QR) {
                this.amplitude.logEvent(
                    AMPLITUDE_EVENT_CATEGORIES.Usage,
                    AMPLITUDE_EVENTS.QR_FILTER_NO_RESULTS,
                    event_data,
                );
            }
        }
        if (changes?.dbcTemplatesList?.currentValue) {
            this.updateCustomDropdownList(changes.dbcTemplatesList.currentValue);
        }
    }

    updateTagList(tagList: Array<any>) {
        let elements;
        if (this.filterToBeApplied?.value === 'tags') {
            elements = document.querySelectorAll(`.btn.dropdown-toggle.btn-filter#toBeApplied-dropdown-${this.filterToBeApplied.id}-input`);
        } else if (this.selectedFilter?.value === 'tags') {
            elements = document.querySelectorAll(`.btn.dropdown-toggle.btn-filter#applied-dropdown-${this.selectedFilter.id}-input`);
        }
        elements?.[0]?.nextElementSibling?.classList.add('active');

        if (!tagList.length) {
            return;
        }

        this.filterOptions.forEach((filter) => {
            if (filter.value === 'tags') {
                filter.dropdownOptions = tagList.map((tag) => {
                    return { text: tag.name, value: tag.id };
                });
            }
        });

        const tagListOptions = tagList.map(tag => ({ text: tag.name, value: tag.id }));
        if (this.filterToBeApplied?.value === 'tags' && this.filterToBeApplied?.dropdownOptions) {
            this.filterToBeApplied.dropdownOptions = tagListOptions;
            document.querySelectorAll('.dropdown-menu .dropdown-options')?.[2]?.classList.toggle('active');
        }

        const filterToAdd = this.appliedFilters
            .filter(filter => filter.value === 'tags')
            .map(filter => {
                const newFilter = new Filter(filter);
                newFilter.dropdownOptions = tagListOptions;

                if (!tagListOptions.some(option => option.value === filter.dropDownChoice?.value)) {
                    newFilter.dropDownChoice = filter.dropDownChoice;
                } else {
                    newFilter.dropDownChoice = tagListOptions.find(option => option.value === filter.dropDownChoice.value);
                }
                return newFilter;
            });

        this.appliedFilters = this.appliedFilters.filter(filter => filter.value !== 'tags').concat(filterToAdd);
    }

    updateCustomDropdownList(dbcTemplatesList: Array<any>) {
        let elements;
        if (this.filterToBeApplied?.value === 'card_template') {
            elements = document.querySelectorAll(`.btn.dropdown-toggle.btn-filter#toBeApplied-dropdown-${this.filterToBeApplied.id}-input`);
        } else if (this.selectedFilter?.value === 'card_template') {
            elements = document.querySelectorAll(`.btn.dropdown-toggle.btn-filter#applied-dropdown-${this.selectedFilter.id}-input`);
        }
        elements?.[0]?.nextElementSibling?.classList.add('active');

        if (!dbcTemplatesList.length) {
            return;
        }

        this.filterOptions.forEach((filter) => {
            if (filter.value === 'card_template') {
                filter.dropdownOptions = dbcTemplatesList.map((template) => {
                    return { text: template.template_name, value: template.id };
                });
            }
        });

        const templateListOptions = dbcTemplatesList.map(template => ({ text: template.template_name, value: template.id }));
        if (this.filterToBeApplied?.value === 'card_template' && this.filterToBeApplied?.dropdownOptions) {
            this.filterToBeApplied.dropdownOptions = templateListOptions;
        }

        const filterToAdd = this.appliedFilters
            .filter(filter => filter.value === 'card_template')
            .map(filter => {
                const newFilter = new Filter(filter);
                newFilter.dropdownOptions = templateListOptions;

                if (!templateListOptions.some(option => option.value === filter.dropDownChoice?.value)) {
                    newFilter.dropDownChoice = filter.dropDownChoice;
                } else {
                    newFilter.dropDownChoice = templateListOptions.find(option => option.value === filter.dropDownChoice.value);
                }
                return newFilter;
            });

        this.appliedFilters = this.appliedFilters.filter(filter => filter.value !== 'card_template').concat(filterToAdd);
        this.generateFiltersUsingQueryParams()
    }

    createFilter(filter: string, filterValue: string, filtersJSON?: any) {
        const filterName = filter.split('__')[0];
        const filterInput = filterValue;
        if (!filterInput) {
            return;
        }
        const filterOption = this.filterOptions.find((option) => option.value === filterName);
        if (filterOption) {
            const filterToApply: any = new Filter(filterOption);
            filterToApply.id = this.generateFilterId();
            filterToApply.input = filterInput;

            if (filterToApply.type === FILTER_OPERATOR_TYPE.Dropdown) {
                if (filterToApply.value === 'tags') {
                    if (!this.tagList.length) {
                        const index = filtersJSON['tags__id__all_in']?.indexOf(filterValue);
                        filterToApply.dropDownChoice = { text: filtersJSON.tags[index] || '', value: filterInput };
                    } else {
                        filterToApply.dropDownChoice = filterToApply.dropdownOptions.filter(
                            (option) => option.value === Number.parseInt(filterInput),
                        )[0];
                    }
                } else if (filterToApply.name === 'Source') {
                    filterToApply.dropDownChoice = filterToApply.dropdownOptions.filter(
                        (option) => option.value === filterInput,
                    )[0];
                } else if (filterToApply.value === 'card_template') {
                    if (!this.dbcTemplatesList.length) {
                        filterToApply.dropDownChoice = {text: filterInput, value: filterInput};
                    } else {
                        filterToApply.dropDownChoice = filterToApply.dropdownOptions.filter(
                            (option) => option.value === Number.parseInt(filterInput),
                        )[0];
                    }
                } else {
                    filterToApply.dropDownChoice = filterToApply.dropdownOptions.filter(
                        (option) => option.value === Number.parseInt(filterInput),
                    )[0];
                }
            }
            if (filterToApply.operators && filterToApply.operators.length > 0) {
                filterToApply.selectedOperator = filterToApply.operators.filter(
                    (operator) => operator.value === filter.replace(filterName, ''),
                )[0];
            }
            if (filterToApply.type === FILTER_OPERATOR_TYPE.Date) {
                let dateRange = false;
                let startDate = null;
                let endDate = null;
                let secondDate = null;
                if (filter.includes(filterToApply.value + '__lte')) {
                    secondDate = filterToApply.value + '__gte';
                    endDate = filterInput;
                    startDate = filtersJSON[secondDate];
                    dateRange = Object.keys(filtersJSON).filter((k) => k.includes(secondDate)).length > 0;
                }
                if (filter.includes(filterToApply.value + '__gte')) {
                    secondDate = filterToApply.value + '__lte';
                    startDate = filterInput;
                    endDate = filtersJSON[secondDate];
                    dateRange = Object.keys(filtersJSON).filter((k) => k.includes(secondDate)).length > 0;
                }
                if (dateRange) {
                    filterToApply.input = 'Custom';
                    filterToApply.date = { __startDate__: startDate, __endDate__: endDate };
                    filterToApply.selectedRadioOption = filterToApply.radioOptions.filter(
                        (option) => option.name === 'Custom',
                    )[0];
                    filterToApply.selectedRadioOption.state = true;
                } else {
                    filterToApply.input = filterToApply.radioOptions.filter(
                        (option) => option.value === filtersJSON[filterToApply.value + '__days'],
                    )[0].name;
                    filterToApply.selectedRadioOption = filterToApply.radioOptions.filter(
                        (option) => option.value === filtersJSON[filterToApply.value + '__days'],
                    )[0];
                    filterToApply.selectedRadioOption.state = true;
                    if (
                        startDate !==
                        moment().subtract(Number(filterToApply.selectedRadioOption.value), 'days').format('YYYY-MM-DD')
                    ) {
                        this.dateUpdated = true;
                        filterToApply.date.__startDate__ = moment()
                            .subtract(Number(filterToApply.selectedRadioOption.value), 'days')
                            .format('YYYY-MM-DD');
                    }
                }
                filterToApply.radioOptions.forEach((option) => {
                    option.state = option.value === filterToApply.selectedRadioOption.value;
                });
            }
            if (filterToApply.useOnce) {
                this.filterOptions.forEach((f: any) => {
                    if (f.value === filterToApply.value) {
                        f.show = false;
                    }
                });
            }
            this.addItemToFilters(filterToApply, true);
        }
    }

    closeDropdowns() {
        const elements = document.querySelectorAll('.dropdown-menu.dropdown-options.active');
        elements.forEach((element) => element.classList.remove('active'));
    }

    onFilterDropwdownChange(filter, $event) {
        filter.dropDownChoice = $event;
        this.onTagSearchInput.emit($event);
        this.closeDropdowns();
    }

    onDropdownInputChange(filter, $event) {
        filter.dropDownChoice = $event;
        this.closeDropdowns();
    }

    generateFiltersUsingQueryParams() {
        this.dateUpdated = false;
        const filterInQueryParam = this.filterQueryParams?.filters;
        if (!filterInQueryParam) {
            this.updateFilterOptions();
            this.appliedFilters = [];
            return;
        }
        const filtersJSON = decodeBase64EncodedString(filterInQueryParam);
        const filtersToIgnore = ['created__days', 'updated__days'];
        for (const filter in filtersJSON) {
            if (filtersToIgnore.includes(filter) || filter === 'tags') {
                continue;
            }
            if (Array.isArray(filtersJSON[filter]) && filter !== 'product_id__in') {
                filtersJSON[filter].forEach((filterValue) => {
                    this.createFilter(filter, filterValue, filtersJSON);
                });
            } else {
                this.createFilter(filter, filtersJSON[filter], filtersJSON);
            }
        }

        this.updateFilterOptions();
        if (this.dateUpdated) {
            this.dateUpdated = false;
        }
        this.defaultValueEmitted = true;
        this.onFilterChange();
    }

    onResetFiltersClicked() {
        this.filterToBeApplied = undefined;
        this.filterToBeAppliedForMobile = undefined;
        this.isShowFiltersComponent = false;
        this.appliedFilters = [];
        this.filterOptions.forEach((f) => {
            f.show = true;
        });
        this.updateFilterOptions();
        this.onFilterChange();
    }

    onAddFilterClicked(filter: Filter | any) {
        this.closeAddFilterDropdown();
        this.isShowFilterMobileDrawer = false;
        const item = new Filter(filter);
        item.id = this.generateFilterId();
        item.input = '';
        if (item.operators && item.operators.length > 0) {
            item.selectedOperator = item.operators[0];
        }

        if (item.type === FILTER_OPERATOR_TYPE.Date) {
            item.input = item.radioOptions[0].name;
            item.selectedRadioOption = item.radioOptions[0];
            item.selectedRadioOption.state = true;
            item.date = { __startDate__: '', __endDate__: '' };
            item.radioOptions.forEach(option => {
                option.state = option.name === item.selectedRadioOption.name;
            });
        }
        if (this.mobileDrawer) {
            if (this.isMobileScreenSize) {
                this.filterToBeAppliedForMobile = item;
            } else {
                this.filterToBeApplied = item;
            }
        } else {
            this.filterToBeApplied = item;
        }

    }
    onAddFilterClickedForMobile() {
        this.isShowFiltersComponent = false;
        this.isShowFilterMobileDrawer = true;
    }
    handleClickGoBack() {
        this.isShowFilterMobileDrawer  = false;
        if (this.appliedFilters.length > 0 ) {
            this.isShowFiltersComponent = true;
        }
    }
    addItemToFilters(item: Filter, usingQueryParams = false) {
        const isFilterAdded = this.appliedFilters.filter((filter) => {
            return JSON.stringify(filter.toKeyValue(filter)) === JSON.stringify(item.toKeyValue(item));
        });

        if (isFilterAdded && isFilterAdded.length) {
            return;
        }

        this.appliedFilters.push(item);
        if (!usingQueryParams) {
            const event_data = { 'QR Filter Type': item.value };
            if (this.productType === PRODUCT_TYPES.QR) {
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.QR_FILTERS, event_data);
            }
            this.onFilterChange();
        }
    }

    onRemoveFilterClicked(item: Filter, isYetToBeApplied: boolean = false) {
        if (item.useOnce) {
            this.filterOptions.forEach((f) => {
                if (f.value === item.value) {
                    f.show = true;
                }
            });
        }
        this.updateFilterOptions();
        if (isYetToBeApplied) {
            this.filterToBeApplied = undefined;
            return;
        }
        if (this.selectedFilter && this.selectedFilter.id === item.id) {
            this.selectedFilter = null;
        }
        this.appliedFilters = this.appliedFilters.filter((filter) => filter.id !== item.id);
        this.onFilterChange();
        if (this.appliedFilters.length === 0) {
            this.isShowFiltersComponent = false;
        }
    }

    extractUsersInfo(users: Array<any>) {
        return users.map((user) => {
            return {
                name: `${user.first_name} ${user.last_name} (${user.email})`,
                value: user.email,
                id: user.id,
            };
        });
    }

    handleChangesActiveUsersInput($event: string){
        this.activeUsersSearchParams = {
            first_name__icontains: $event,
            last_name__icontains: $event,
            email__icontains: $event,
        };
        this.activeUsersPageNumber = 1;
        this.activeUsersList = [];
        this.handleFetchActiveUsers(this.activeUsersPageNumber);
    }

    handleFetchActiveUsers(page: number = 1){
        if (!this.isFetchingActiveUserList){
            this.isFetchingActiveUserList = true;
            this.userService.getList(page, 10, this.activeUsersSearchParams, false)
                .pipe(takeUntil(this.ngUnsubscribe), finalize(() => this.isFetchingActiveUserList = false))
                .subscribe(({ objects }) => {
                    this.activeUsersList = [...this.activeUsersList, ...this.extractUsersInfo(objects)];
                    this.activeUsersPageNumber = page + 1;
                });
        }
    }

    onSelectedFilterTypeChange(operator: FilterOperator | any, isYetToBeApplied: boolean = false) {
        if (isYetToBeApplied) {
            if (!this.isMobileScreenSize || !this.mobileDrawer) {
                this.filterToBeApplied.selectedOperator = { ...operator };
            }
            if (this.isMobileScreenSize && this.mobileDrawer) {
                this.filterToBeAppliedForMobile.selectedOperator = { ...operator};
            }
        } else {
            this.selectedFilter.selectedOperator = { ...operator };
            this.hasChangedFilterOperator = true;
        }
        if ((this.filterToBeApplied?.name === 'Owner' || this.selectedFilter?.name === 'Owner') && operator?.name === 'is'){
            this.handleFetchActiveUsers(this.activeUsersPageNumber);
        }
        const elements = document.querySelectorAll('.dropdown-menu.dropdown-options.active');
        elements.forEach((element) => element.classList.remove('active'));
    }

    private generateFilterId(): string {
        let id = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return id;
    }

    generateFilterParams(): SearchParams {
        const params = {};
        const searchParams = [];
        this.appliedFilters.forEach((filter) => {
            searchParams.push(filter.toKeyValue(filter));
        });
        searchParams.forEach((param) => {
            for (const key in param) {
                if (params.hasOwnProperty(key)) {
                    if (Array.isArray(params[key])) {
                        params[key] = params[key].concat(param[key]);
                    } else {
                        params[key] = param[key];
                    }
                } else {
                    const singleValueKeys = ['scans', 'created', 'updated', 'creation_source', 'views', 'saves', 'card_template', 'product_id'];
                    if (singleValueKeys.some(singleKey => key.includes(singleKey))) {
                        params[key] = param[key];
                    } else {
                        params[key] = [param[key]];
                    }
                }
            }
        });

        const jsonStr = JSON.stringify(params);
        const utf8Array = new TextEncoder().encode(jsonStr);
        const base64String = btoa(String.fromCharCode.apply(null, utf8Array));
        const filterParam = { filters: base64String };
        return filterParam;
    }

    onDateAdded(filter: Filter, event) {
        filter.date.__startDate__ = moment(event[0]).startOf('day').format('YYYY-MM-DD');
        filter.date.__endDate__ = moment(event[1]).startOf('day').format('YYYY-MM-DD');
    }

    onFilterChange(event?: any) {
        if (this.selectedFilter && this.selectedFilter.type === FILTER_OPERATOR_TYPE.Date && event === 'Custom') {
            if (!this.selectedFilter.date.__startDate__ && !this.selectedFilter.date.__endDate__) {
                return;
            }
        } else {
            this.appliedFilters = this.appliedFilters.map((filter) => {
                return filter.id === this.selectedFilter?.id ? this.selectedFilter : filter;
            });
        }
        this.filterValueSubject.next(this.generateFilterParams());
    }

    validateInput(filter: Filter): boolean {
        let isValid = true;
        if (filter.type !== FILTER_OPERATOR_TYPE.Dropdown && !filter.input) {
            this.validationErrors[filter.id] = 'Please enter a valid input';
            isValid = false;
        } else if (filter.type === FILTER_OPERATOR_TYPE.Dropdown) {
            if (filter.name === 'Owner'){
                if (filter.selectedOperator?.name === 'is' && !filter.dropDownChoice){
                    this.validationErrors[filter.id] = 'Please enter a valid input';
                    isValid = false;
                } else if (filter.selectedOperator?.name !== 'is' && !filter.input?.length){
                    this.validationErrors[filter.id] = 'Please enter a valid input';
                    isValid = false;
                }
            } else if (!filter.dropDownChoice){
                this.validationErrors[filter.id] = 'Please enter a valid input';
                isValid = false;
            }
        }
        return isValid;
    }



    onInputChange(filterToBeApplied, $event) {
        filterToBeApplied.input = $event.trim();
        filterToBeApplied.dropDownChoice = undefined;
        if (filterToBeApplied.type === FILTER_OPERATOR_TYPE.Dropdown){
            const { name, selectedOperator } = filterToBeApplied;
            if (name === 'Owner' && selectedOperator.name !== 'is'){
                this.isApplyButtonDisabled = !filterToBeApplied.input.length;
            } else {
                this.isApplyButtonDisabled = !filterToBeApplied.dropDownChoice;
            }
        } else {
            this.isApplyButtonDisabled = this.validationErrors[filterToBeApplied.id] ?  true : false;
        }
        delete this.validationErrors[filterToBeApplied.id];
    }

    onApplyFilterClicked(filter: Filter, isYetToBeApplied: boolean = false) {
        const event_data_dbcfilter = {'source': 'dbc list filter', 'type': filter.name};
        if (this.productType === PRODUCT_TYPES.DBC) {
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.DBC_LIST_FILTER, event_data_dbcfilter);
        }
        const isValid = this.validateInput(filter);
        if (!isValid) {
            return;
        }
        if (filter.type === FILTER_OPERATOR_TYPE.Date && filter.input !== 'Custom') {
            filter.date = {
                __startDate__: moment().subtract(Number(filter.selectedRadioOption.value), 'days').format('YYYY-MM-DD'),
                __endDate__: moment().format('YYYY-MM-DD'),
            };
        }
        if (isYetToBeApplied) {
            if (!this.validateFilter(filter)) {
                return;
            }

            this.addItemToFilters(filter);
            this.filterToBeApplied = undefined;
            this.filterToBeAppliedForMobile = undefined;
            if (filter.useOnce) {
                this.filterOptions.forEach((f) => {
                    if (f.value === filter.value) {
                        f.show = false;
                    }
                });
                this.updateFilterOptions();
            }
            return;
        }
        const event_data = { 'QR Filter Type': filter.value };
        if (this.productType === PRODUCT_TYPES.QR) {
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.QR_FILTERS, event_data);
        }
        this.onFilterChange();
        this.selectedFilter = null;
    }

    onDateRadioOptionClicked(event: any, isYetToBeApplied: boolean = false) {
        if (isYetToBeApplied) {
            this.filterToBeApplied.selectedRadioOption.state = false;
            this.filterToBeApplied.input = event;
            this.filterToBeApplied.selectedRadioOption = this.filterToBeApplied.radioOptions.filter(
                (option) => option.name === event,
            )[0];
            this.filterToBeApplied.selectedRadioOption.state = true;
            this.filterToBeApplied.radioOptions.forEach((option) => {
                option.state = option.value === this.filterToBeApplied.selectedRadioOption.value;
            });
            return;
        }
        this.selectedFilter.selectedRadioOption.state = false;
        this.selectedFilter.input = event;
        this.selectedFilter.selectedRadioOption = this.selectedFilter.radioOptions.filter(
            (option) => option.name === event,
        )[0];
        this.selectedFilter.selectedRadioOption.state = true;
        this.selectedFilter.radioOptions.forEach((option) => {
            option.state = option.value === this.selectedFilter.selectedRadioOption.value;
        });
    }
    onDateRadioOptionClickedForMobile(event: any, isYetToBeApplied: boolean = false) {
        if (isYetToBeApplied) {
            this.filterToBeAppliedForMobile.selectedRadioOption.state = false;
            this.filterToBeAppliedForMobile.input = event;
            this.filterToBeAppliedForMobile.selectedRadioOption = this.filterToBeAppliedForMobile.radioOptions.filter(
                (option) => option.name === event,
            )[0];
            this.filterToBeAppliedForMobile.selectedRadioOption.state = true;
            this.filterToBeAppliedForMobile.radioOptions.forEach((option) => {
                option.state = option.value === this.filterToBeAppliedForMobile.selectedRadioOption.value;
            });

            return;
        }
        this.selectedFilter.selectedRadioOption.state = false;
        this.selectedFilter.input = event;
        this.selectedFilter.selectedRadioOption = this.selectedFilter.radioOptions.filter(
            (option) => option.name === event,
        )[0];
        this.selectedFilter.selectedRadioOption.state = true;
        this.selectedFilter.radioOptions.forEach((option) => {
            option.state = option.value === this.selectedFilter.selectedRadioOption.value;
        });
    }

    updateFilterOptions() {
        this.localFilterOptions = this.filterOptions.filter((option) => Boolean(option.show) && option.type !== FILTER_OPERATOR_TYPE.NUMBER_OF_PRODUCTS_SELECTED);
    }

    validateFilter(filter: Filter): boolean {
        switch (filter.type) {
            case FILTER_OPERATOR_TYPE.String:
            case FILTER_OPERATOR_TYPE.Number:
                if (!filter.input || !filter.input.length) {
                    return false;
                }
                break;
            case FILTER_OPERATOR_TYPE.Dropdown:
                if (filter.name === 'Owner' && filter.selectedOperator?.name !== 'is'){
                    if (!filter.input.length) {
                        return false;
                    }
                } else if (!filter.dropDownChoice) {
                    return false;
                }
                break;
            case FILTER_OPERATOR_TYPE.Date:
                if (filter.input === 'Custom') {
                    if (!filter.date.__startDate__ || !filter.date.__endDate__) {
                        return false;
                    }
                } else {
                    if (!filter.selectedRadioOption) {
                        return false;
                    }
                }
                break;
        }
        return true;
    }

    closeAddFilterDropdown() {
        document.getElementById('Add Filters')?.classList.remove('active');
    }

    onSelectFilter(filter: Filter) {
        this.selectedFilter = new Filter(filter);
        this.isShowFiltersComponent = false;
        this.closeAddFilterDropdown();
    }

    closeExistingDropdowns(elements: NodeListOf<Element>) {
        elements.forEach((element) => {
            const isAriaExpanded = element.getAttribute('aria-expanded') === 'true';
            if (isAriaExpanded) {
                const dropdowns = document.querySelectorAll('.dropdown-options .active');
                dropdowns?.forEach((dropdown: any) => dropdown.classList.remove('active'));
            }
        });
    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        const prefix = 'dropdownMenuButton-';
        const elements = document.querySelectorAll('[id^="' + prefix + '"]');
        this.closeExistingDropdowns(elements);

        if (!event.target.closest('beaconstac-dropdown') && !event.target.closest('#filterToBeApplied') && !event.target.closest('mat-datepicker-content') && !event.target.closest('#filterToBeAppliedButton')){
            this.filterToBeApplied = null;
        }
        if (!event.target.closest('.filter-chip') && !event.target.closest('mat-datepicker-content') && !event.target.closest('#filterToBeAppliedButton')) {
            this.selectedFilter = null;
        }
    }

    onDateRangeSelected(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement, filterToBeApplied) {
        const momentStartDate = moment(dateRangeStart.value, 'MM-DD-YYYY');
        const momentEndDate = moment(dateRangeEnd.value, 'MM-DD-YYYY');
        this.onDateAdded(filterToBeApplied, [momentStartDate, momentEndDate]);
    }

    onDateRangeEdit(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement, filterToBeApplied) {
        const momentStartDate = moment(dateRangeStart.value, 'MM-DD-YYYY');
        const momentEndDate = moment(dateRangeEnd.value, 'MM-DD-YYYY');
        this.onDateAdded(filterToBeApplied, [momentStartDate, momentEndDate]);
        this.onApplyFilterClicked(filterToBeApplied)
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
