import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationLanguageComponent} from './notification-language.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {FormsModule} from '@angular/forms';
import {LanguageService} from './language.service';
import {PipesModule} from '../pipes/pipes.module';
import { BeaconstacModule } from 'kaizen-design-system';
import {LocationService} from './location.service';

@NgModule({
    imports: [
        CommonModule,
        ModalModule,
        FormsModule,
        PipesModule,
        BeaconstacModule
    ],
    declarations: [NotificationLanguageComponent],
    exports: [NotificationLanguageComponent],
    providers: [LanguageService, LocationService]
})
export class NotificationLanguageModule {
}
