import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayService } from '../global-services/overlay.service';
import { User } from '../user-account/user.model';
import { IntercomService } from '../global-services/intercom.service';
import { UserDetailService } from './user-detail.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Animations } from '../shared/beaconstac-animations';
import { AuthService } from '../global-services/auth.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AMPLITUDE_EVENT_CATEGORIES, AmplitudeService } from '../global-services/amplitude.service';
import * as moment from 'moment-timezone';
import { TEXT_FIELD_TYPES, TEXT_FIELD_VALIDATIONS } from 'kaizen-design-system';
import { COMPANY_SIZE_VALUES, DASHBOARD_MENU, pinterestDBCTag, PRODUCT_TYPES, Utils } from '../shared/utils';
import { DashboardService } from '../global-services/dashboard.service';
import { environment } from 'environments/environment';
import { UserService } from 'app/user-account/user.service';

declare const dataLayer: any;
@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
    styleUrls: ['./user-detail.component.scss'],
    animations: [Animations.collapse],
})
export class UserDetailComponent implements OnInit, OnDestroy {

    industryName: string;
    companySize: string;
    industryOther: boolean = false;
    userSegmentPhoneNumber: string;
    isUserSegmentPhoneNumberValid: boolean = true;
    user: User;
    firstName: string;
    reloadHack = true;
    nextRoute: string = '/qr-codes/add';
    backgroundImage: string = '../../assets/img/user-detail-blur-dashboard.png'
    showModal: boolean = false;
    // kaizen
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;

    brandLogo = '';
    userCountryDialingCode: {name: string, value: string} = {name: '+1', value: '+1'};
    countryDialingCodes: string[] = Utils.countryDialingCodes;

    public industries: string[] = ['Apparel & Fashion', 'Arts & Entertainment', 'Consumer goods', 'Education',
        'Events & Services', 'Finance', 'Food & Beverages', 'Government', 'Health, Wellness & Fitness', 'Hospital & Healthcare',
        'Hospitality', 'Legal services', 'Leisure & Travel', 'Marketing & Advertising', 'Music', 'Packaging & Containers',
        'Print', 'Real estate', 'Religious institutions', 'Research', 'Restaurants', 'Retail',
        'Sports', 'Transportation', 'Other'];
    public companySizes: string[] = ['Individual', '2-50 employees', '51-250 employees', '251-1,000 employees', 'Over 1,000 employees']

    public industryList: any[] = [];
    public companySizeList: any[] = [];
    public countryDialingCodesList: any[] = [];
    isFromTQRCG: boolean = false;
    showPhoneNumberField: boolean = false;


    ngUnsubscribe: Subject<any> = new Subject();

    constructor(private authService: AuthService,
        private overlay: OverlayService, private intercom: IntercomService,
        private userDetail: UserDetailService, private route: ActivatedRoute,
        private router: Router,
        private amplitude: AmplitudeService, private dashboardService: DashboardService,
        private userService: UserService) {
        this.user = this.authService.getUser();
        this.attachRoute();
        this.setupLogo();
        if (this.nextRoute.includes('linkpage_template')) {
            this.cancelPressed();
        } else {
            this.showModal = true;
        }
    }

    setupLogo() {
        if (this.user.organization.whitelabel_logo_small) {
            this.brandLogo = this.user.organization.whitelabel_logo_small;
        } else {
            this.brandLogo = '/assets/img/uniqode-header-logo.svg';
        }
    }

    ngOnInit() {
        const { first_name } = this.user;
        this.firstName = first_name;

        if (this.user.meta.company_size && this.user.meta.industry && this.user.meta.company_size !== 'Opted out') {
            this.router.navigate(['overview'], { queryParams: { orgID: this.authService.getCurrentOrgId() } });
            return;
        } else {
            if (this.user.isOnTrialPlan(PRODUCT_TYPES.QR) || this.user.isOnTrialPlan(PRODUCT_TYPES.DBC)) {
                this.updateHashEmailGAEvent();
            }
        }
        this.segmentationTimeForTrialFirstVisit();
        for (const item of this.industries) {
            this.industryList.push({ name: item, value: item })
        }
        for (const item of this.companySizes) {
            this.companySizeList.push({ name: item, value: item })
        }
        for (const item of this.countryDialingCodes) {
            this.countryDialingCodesList.push({ name: item, value: item });

        }
        this.dashboardService.currentDashboard$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.backgroundImage = res === DASHBOARD_MENU.CARDS ? '../../assets/img/dbc-blur-background.svg' : this.backgroundImage;
        })
        this.userService.getCountryCallingCode().subscribe(countryCodeResponse => {
            const countryCode = '+' + countryCodeResponse['country_calling_code'];
            this.userCountryDialingCode = {name: countryCode, value: countryCode};
        })
    }

    attachRoute(): void {
        this.route.queryParams.subscribe((params: Params) => {
            const nextParam = params?.next;
            if (nextParam) {
                const queryParams = nextParam.split('&');
                let sourceValue = null;
                // Loop through the queryParams array to find the utm_product parameter
                queryParams.forEach(param => {
                    const [key, value] = param.split('=');
                    if (key === 'utm_product') {
                        sourceValue = value;
                    }
                });

                if (sourceValue) {
                    this.isFromTQRCG = sourceValue === 'tqrcg';
                }
            }
            for (const key in params) {
                if (key === 'next') {
                    this.nextRoute = params[key];
                }
            }
            this.overlay.isLoading(false);
        }
        );
    }

    cancelPressed() {
        this.companySize = 'Opted out';
        this.industryName = 'Opted out';
        this.submitDetails();
    }

    submitDetails() {
        if (this.industryOther) {
            this.industryName = this.industryName.charAt(0).toUpperCase() + this.industryName.slice(1);
        }

        this.userSegmentPhoneNumber = this.userSegmentPhoneNumber ? this.userCountryDialingCode.value + this.userSegmentPhoneNumber : 'Opted out';
        const user = {
            'email': this.user.email,
            'industry': this.industryName,
            'company_size': this.companySize,
            'phone': this.userSegmentPhoneNumber
        };
        this.user.meta['company_size'] = this.companySize;
        this.user.meta['industry'] = this.industryName;
        this.user.meta['phone'] = this.userSegmentPhoneNumber;
        this.user.meta['segmentation_submitted_at'] = moment();
        this.authService.setUser(this.user);
        this.router.navigateByUrl(this.nextRoute);

        this.authService.setSatisMeterIndustryAttributes(this.industryName, this.companySize);
        this.authService.setDatadogUserProperties(this.industryName, this.companySize);
        this.amplitude.setUserProperties({
            'industry': this.industryName,
            'company_size': this.companySize,
            'phone': this.userSegmentPhoneNumber
        });

        this.userDetail.post(user)
            .pipe(takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.ngUnsubscribe.next();
                    this.ngUnsubscribe.complete();
                })).subscribe(() => {
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Onboarding, 'submit segmentation', {
                    filled: this.industryName !== 'Opted out'
                });
            }, err => {
                // Skip
            });
    }

    selectIndustry(industry) {
        this.industryName = industry.value;
        if (industry.value === 'Other') {
            this.industryName = '';
            this.industryOther = true;
        } else {
            this.industryOther = false;
        }
    }

    selectCountryCode(countryCode) {
        this.userCountryDialingCode = countryCode;
    }

    selectCompanySize(companySize) {
        if (COMPANY_SIZE_VALUES.MediumLargeCompanySize === companySize.value || COMPANY_SIZE_VALUES.LargeCompanySize === companySize.value) {
            this.showPhoneNumberField = true;
        } else {
            this.showPhoneNumberField = false;
            this.userSegmentPhoneNumber = '';
        }
        this.companySize = companySize.value;
    }

    checkPhoneValidation(phoneNo) {
        this.isUserSegmentPhoneNumberValid = Utils.phoneRegex.test(this.userSegmentPhoneNumber);
    }

    updateHashEmailGAEvent() {
        Utils.digestMessageSHA256(this.authService.getUser().email)
            .then((res) => {
                try {
                    const gtmData = {
                        'event': this.user.subscription?.qr ? 'Successful Trial Signup' : 'DBC Successful Trial Signup',
                        'startTrialEmail': res,
                        'successfulTrialSignup': 'true'
                    };
                    if (this.isFromTQRCG) {
                        gtmData['event'] = 'TQRCG Successful Trial Signup';
                    }
                    dataLayer.push(gtmData);
                } catch (err) {
                    console.log('Error in pushing to dataLayer in GTM', err);
                }

                // Pinterest Ads tracking
                pinterestDBCTag(this.user.subscription?.dbc, res);

            })
            .catch(e => console.error(e));

        // linkedin conversion tracking
        try {
            if (window.lintrk) {
                window.lintrk('track', { conversion_id: environment.linkedinConversionTrackingStartTrial });
            }
        } catch (error) {
            console.error('Error in LinkedIn tracking:', error);
        }

    }

    segmentationTimeForTrialFirstVisit() {
        if (window.location.search.indexOf('trial_first') !== -1) {
            const segmentationViewedAt = Math.floor(new Date().getTime() / 1000);
            setTimeout(() => {
                this.intercom.update({
                    segmentation_viewed_at: segmentationViewedAt
                });
            }, 1000);
        }
    }

    ngOnDestroy(): void {
    }
}
