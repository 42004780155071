<div class="stepper-container bg-white">
    <div class="stepper-main d-flex justify-content-between align-items-center">
        <div class="tab-container" [ngClass]="selectedStep === 1 && 'active'">
            <div class="tab" (click)="goToNext(1)" (keyup)="goToNext(1)" (keydown)="goToNext(1)">
                Build
            </div>
        </div>

        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M12.581 8.51814L6.64714 14.452C6.36096 14.7382 5.89698 14.7382 5.61082 14.452L4.91873 13.7599C4.63304 13.4742 4.63249 13.0112 4.91751 12.7248L9.62023 7.99996L4.91751 3.27514C4.63249 2.98877 4.63304 2.52574 4.91873 2.24004L5.61082 1.54795C5.89701 1.26177 6.36099 1.26177 6.64714 1.54795L12.581 7.48182C12.8672 7.76797 12.8672 8.23195 12.581 8.51814Z" fill="#DBDFE3"/>
            </svg>
        </span>

        <div class="tab-container" [ngClass]="selectedStep === 2 && 'active'">
            <div class="tab" (click)="goToNext(2)" (keyup)="goToNext(2)" (keydown)="goToNext(2)">
                Customize
            </div>
        </div>

        <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M12.581 8.51814L6.64714 14.452C6.36096 14.7382 5.89698 14.7382 5.61082 14.452L4.91873 13.7599C4.63304 13.4742 4.63249 13.0112 4.91751 12.7248L9.62023 7.99996L4.91751 3.27514C4.63249 2.98877 4.63304 2.52574 4.91873 2.24004L5.61082 1.54795C5.89701 1.26177 6.36099 1.26177 6.64714 1.54795L12.581 7.48182C12.8672 7.76797 12.8672 8.23195 12.581 8.51814Z" fill="#DBDFE3"/>
            </svg>
        </span>

        <div class="tab-container" [ngClass]="selectedStep === 3 && 'active'">
            <div class="tab" (click)="goToNext(3)" (keyup)="goToNext(3)" (keydown)="goToNext(3)">
                Setup
            </div>
        </div>
    </div>
</div>
