import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DigitalBusinessCard, DigitalBusinessCardTemplateModel} from '../../digital-business-card.model';

@Component({
    selector: 'app-cards-multi-org-bulk-setup',
    templateUrl: './cards-multi-org-bulk-setup.component.html',
    styleUrls: ['./cards-multi-org-bulk-setup.component.scss']
})
export class CardsMultiOrgBulkSetupComponent {

    @Input() organizationsList: Array<{name: string, id: number}> = [];
    @Input() orgTemplateMap: Map<number, DigitalBusinessCardTemplateModel> = new Map();
    @Output() orgTemplateMapChange: EventEmitter<Map<number, DigitalBusinessCardTemplateModel>> = new EventEmitter();

    @ViewChild('digitalBusinessCardPreview', { static: false }) digitalBusinessCardPreview: ElementRef;


    previewTemplate: DigitalBusinessCardTemplateModel = null;
    showNoTemplateWarning: boolean = false;

    onTemplateSelected($event, orgId) {
        this.orgTemplateMap.set(orgId, $event.template)
        this.orgTemplateMapChange.emit(this.orgTemplateMap);
        this.loadPreview(orgId);
    }

    writeOnIframe(element, maxRetries = 10) {
        if (!element) {
            setTimeout(() => {
                this.writeOnIframe(element, maxRetries - 1);
            }, 100);
            return;
        }

        const iframe = element;
        const iframedoc = iframe.nativeElement.contentWindow || iframe.nativeElement.contentDocument.document ||
            iframe.nativeElement.contentDocument;

        iframedoc.document.open();
        iframedoc.document.write(DigitalBusinessCard.getVcardPreviewHTML(this.previewTemplate, false, false));
        iframedoc.document.close();
    }

    loadPreview(orgId: number) {
        if (this.orgTemplateMap.get(orgId)) {
            this.previewTemplate = this.orgTemplateMap.get(orgId)
            this.writeOnIframe(this.digitalBusinessCardPreview);
        } else {
            this.previewTemplate = null;
        }
    }

    setOrgHasTemplates(hasTemplates, id) {
        if (!hasTemplates) {
            const index = this.organizationsList.findIndex(org => org.id === id)
            this.organizationsList[index]['hasTemplates'] = false;
            this.showNoTemplateWarning = true;
        }

        // this.organizationsList = [...this.organizationsList]
    }
}
