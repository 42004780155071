import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { BehaviorSubject, combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {getAuth0Domain, Utils} from '../shared/utils';
import {BEACONSTAC_HOSTS} from '../app.constants';

@Injectable({
    providedIn: 'root'
})

export class SsoAuthService {
    // Create an observable of Auth0 instance of client
    auth0Client$ = (from(
        createAuth0Client({
            domain: getAuth0Domain(),
            client_id: environment.authOClientId,
            redirect_uri: `${window.location.origin}`,
            audience: 'beaconstac-api',
            scope: 'enroll read:authenticators remove:authenticators verify mfa-otp https://authqa.beaconstac.com/mfa/ https://authqa.uniqode.com/mfa/ http://auth0.com/oauth/grant-type/mfa-otp create:guardian_enrollment_tickets',
            useRefreshTokens: true,
            cacheLocation: 'localstorage'
        })
    ) as Observable<Auth0Client>).pipe(
        shareReplay(1), // Every subscription receives the same shared value
        catchError(err => throwError(err))
    );

    // Define observables for SDK methods that return promises by default
    // For each Auth0 SDK method, first ensure the client instance is ready
    // concatMap: Using the client instance, call SDK method; SDK returns a promise
    // from: Convert that resulting promise into an observable
    public isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap(res => {
            this.loggedIn = res;
            this.isAuthenticatedUserSubject.next(res);
        })
    );

    private isAuthenticatedUserSubject = new BehaviorSubject<boolean | null>(null);
    public isAuthenticatedUser$: Observable<boolean | null> = this.isAuthenticatedUserSubject.asObservable().pipe(filter(val => val !== null));

    private targetRouteSubject = new BehaviorSubject<string>(null);
    public targetRoute$: Observable<string> = this.targetRouteSubject.asObservable().pipe(filter(val => val !== null));

    handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
    );

    // Create subject and public observable of user profile data
    private userProfileSubject$ = new BehaviorSubject<any>(null);
    userProfile$ = this.userProfileSubject$.asObservable();

    // Create subject and public observable of user token data
    private tokenSubject$ = new BehaviorSubject<any>(null);
    token$ = this.tokenSubject$.asObservable();

    // Create subject and public observable of error
    errorSubject$ = new BehaviorSubject<any>(null);
    error$ = this.errorSubject$.asObservable();

    // Create a local property for login status
    loggedIn: boolean = null;

    constructor() {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
        this.localAuthSetup();
        // Handle redirect from Auth0 login
        this.handleAuthCallback();

    }

    async getConnectionId() {
        const host = window.location.hostname.toLowerCase();
        if ((host.endsWith('beaconstac.com') || host.endsWith('uniqode.com')) && BEACONSTAC_HOSTS.indexOf(host) === -1) {
            try {
                const config: any = await Utils.getJSON(`https://beaconstac-cname-content.s3.amazonaws.com/${host}.json`);
                return config.connection;
            } catch (e) {
                return null;
            }

        }
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getUser$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getUser(options))),
            tap(user => {
                this.userProfileSubject$.next(user);
            })
        );
    }

    // When calling, options can be passed if desired
    // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
    getToken$(options?): Observable<any> {
        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
            tap(token => {
                this.tokenSubject$.next(token)
            })
        )
    }

    private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
        const checkAuth$ = this.isAuthenticated$.pipe(
            concatMap((loggedIn: boolean) => {
                if (loggedIn) {
                    // If authenticated, get user and set in app
                    // NOTE: you could pass options here if needed
                    return combineLatest([
                        this.getUser$(),
                        this.getToken$()
                    ]);
                }
                // If not authenticated, return stream that emits 'false'
                return of(loggedIn);
            })
        );
        checkAuth$.subscribe();
    }

    login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe(async (client: Auth0Client) => {
            // Call method to log in
            const loginOptions = {
                redirect_uri: `${window.location.origin + '/sso'}`,
                appState: { target: redirectPath }
            };
            const connection = await this.getConnectionId();
            if (connection) {
                loginOptions['connection'] = connection;
            }
            client.loginWithRedirect(loginOptions);
        });
    }

    private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
        const params = window.location.search;
        if (params.includes('code=') && params.includes('state=')) {
            let targetRoute: string; // Path to redirect to after login processsed
            const authComplete$ = this.handleRedirectCallback$.pipe(
                // Have client, now call method to handle auth callback redirect
                tap(cbRes => {
                    // Get and set target redirect route from callback results
                    targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
                    this.targetRouteSubject.next(targetRoute);
                }),
                concatMap(() => {
                    // Redirect callback complete; get user and login status
                    return combineLatest([
                        this.getUser$(),
                        this.getToken$(),
                        this.isAuthenticated$
                    ]);
                })
            );
            // Subscribe to authentication completion observable
            // Response will be an array of user and login status
            authComplete$.subscribe(res => {

            }, error => {
                console.error(error);
                this.errorSubject$.next({ error: 'Invalid URL' });
            });
        } else if (window.location.hash.includes('access_token=')) {
            // Redirect back to origin if access_token is passed instead of code from idp
            // reason: authentication callback fails for idp initiated sso through okta
            this.login();
            return;
        } else if (window.location.search.includes('error=')) {
            // Redirect back to origin if access_token is passed instead of code from idp
            // reason: authentication callback fails for idp initiated sso through okta
            this.errorSubject$.next(Utils.getQueryMap(window.location.search));
            return;
        }
    }

    logout(logoutURL?: string) {
    // Ensure Auth0 client instance exists
        this.auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log out
            client.logout({
                client_id: environment.authOClientId,
                returnTo: `${logoutURL || window.location.origin}`
            });
        });
    }

    getMfaAccessToken(): Observable<any> {
        const differentAudienceOptions = {
            audience: environment.mfaAudienceURL,
            scope: 'enroll read:authenticators delete:authenticators',
            redirect_uri: window.location.href
        };

        return this.auth0Client$.pipe(
            concatMap((client: Auth0Client) => from(client.getTokenSilently(differentAudienceOptions)))
        );
    }

    requestMFAGrant() {
        const auth0Client$ = (from(
            createAuth0Client({
                domain: getAuth0Domain(),
                client_id: environment.authOClientId,
                redirect_uri: `${window.location.href}`,
                audience: environment.mfaAudienceURL,
                scope: 'enroll read:authenticators delete:authenticators'
            })
        ) as Observable<Auth0Client>).pipe(
            shareReplay(1), // Every subscription receives the same shared value
            catchError(err => throwError(err))
        );


        // A desired redirect path can be passed to login method
        // (e.g., from a route guard)
        // Ensure Auth0 client instance exists
        auth0Client$.subscribe((client: Auth0Client) => {
            // Call method to log in
            client.loginWithRedirect({
                redirect_uri: `${window.location.origin + '/account'}`,
                appState: { target: '/account' }
            });
        });

    }


}
