import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TablePagingComponent} from './table-paging.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [TablePagingComponent],
    exports: [TablePagingComponent]
})
export class TablePagingModule {
}
