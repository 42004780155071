import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { VisitorsAnalytics } from '../analytics/visitors-analytics/visitors-analytics.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isUniqodeDomainRequest } from '../shared/utils';
import { BaseBackendService } from './base-backend.service';
import { MessageModalService } from 'app/shared/message-modal/message-modal.service';

@Injectable()
export class AnalyticsService {
    private analyticsUrl =
        environment.reportingBaseURL + environment.reportingEndpoint + environment.reportingVersion + '/?organization=';

    private visitorSubject = new BehaviorSubject<VisitorsAnalytics>(null);

    constructor(private authService: AuthService, private http: HttpClient) {
        if (isUniqodeDomainRequest()) {
            this.analyticsUrl = this.analyticsUrl.replace(environment.reportingBaseURL, environment.apiBaseURL);
        }
    }

    protected buildHeaders(): object {
        const options = {};
        options['headers'] = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `${this.authService.getTokenType()} ${this.authService.getToken()}`,
        });
        return options;
    }

    private createAnalyticsURL(suffix: string, productType = 'beacon', organization?: number, fromDate?: any): string {
        // default url
        let url = `${this.analyticsUrl}${this.authService.getCurrentOrgId()}&method=${suffix}`;

        // default url if specific organization is provided
        if (organization) {
            url = `${this.analyticsUrl}${organization}&method=${suffix}`;
        }

        const { reportingEndpoint, qrV3ReportingVersion, dbcReportingVersion, linkpageReportingVersion } = environment;
        let reportingBaseURL = environment.reportingBaseURL;
        // url if productType is Linkpage (here we are using v3 of analytics for linkpage)

        if (isUniqodeDomainRequest()) {
            reportingBaseURL = reportingBaseURL.replace(reportingBaseURL, environment.apiBaseURL);
        }

        if (productType === 'linkpage') {
            url = `${reportingBaseURL}${reportingEndpoint}${linkpageReportingVersion}/?organization=`;
            if (organization) {
                url += `${organization}&method=${suffix}`;
            } else {
                url += `${this.authService.getCurrentOrgId()}&method=${suffix}`;
            }

            return url;
        }
        // url if productType is DBC (here we are using v3 of analytics)
        if (productType === 'dbc') {
            url = `${reportingBaseURL}${reportingEndpoint}${dbcReportingVersion}/?organization=`;
            if (organization) {
                url += `${organization}&method=${suffix}`;
            } else {
                url += `${this.authService.getCurrentOrgId()}&method=${suffix}`;
            }

            return url;
        }

        // url if request is of export type
        if (suffix.includes('Csv.') || suffix.includes('Exports.')) {
            return url;
        }

        // url if productType is QR (here we are using v3 of analytics)
        if (productType === 'qr') {
            // will return the v3 URL for QR
            url = `${reportingBaseURL}${reportingEndpoint}${qrV3ReportingVersion}/?organization=`;
            if (organization) {
                url += `${organization}&method=${suffix}V3`;
            } else {
                url += `${this.authService.getCurrentOrgId()}&method=${suffix}V3`;
            }

            return url;
        }

        return url;
    }

    public getAnalytics(suffix: string, body: object, productType = 'beacon', organization?: number): Observable<any> {
        const url = this.createAnalyticsURL(suffix, productType, organization, body['from']);

        // adding custom payload field when productType is Linkpage
        if (productType !== 'linkpage') {
            body['product_type'] = productType;
        }

        return this.http.post(url, JSON.stringify(body), this.buildHeaders()).pipe(
            map((response) => {
                return response;
            }),

            catchError(this.handleError),
        );
    }

    public getFilteredProductIds(params: string): Observable<any> {
        const { baseURL, apiEndpoint, apiVersion } = environment;
        const headerOptions = this.buildHeaders();
        const url = `${baseURL}${apiEndpoint}${apiVersion}/qr/analytics-filter/?${params}`;
        return this.http.get(url, headerOptions).pipe(
            map((response) => response),
            catchError(this.handleError),
        );
    }

    public getFilteredDBCIds(params: string): Observable<any> {
        const { baseURL, apiEndpoint, apiVersion } = environment;
        const headerOptions = this.buildHeaders()
        const url = `${baseURL}${apiEndpoint}${apiVersion}/dbc/analytics-filter/?${params}`;
        return this.http.get(url, headerOptions)
            .pipe( map(response => response), catchError(this.handleError));
    }

    protected handleError(error: Response | any) {
        switch (error.status) {
            case 401:
                this.authService.logout();
                break;
        }
        // Manage error into something
        return observableThrowError(error);
    }

    setVisitor(visitor: VisitorsAnalytics) {
        this.visitorSubject.next(visitor);
    }

    getVisitor(): VisitorsAnalytics {
        return this.visitorSubject.getValue();
    }
}

export class AnalyticsShareModal {
    email_ids: string[] = [];
    organization: number;
    analytics_url: string;
    analytics_type: 'individual' | 'aggregate';
    qrcode_id?: string | number;
    message?: string;

    constructor(json: any) {
        this.email_ids = json['email_ids'] || [];
        this.organization = json['organization'];
        this.analytics_url = json['analytics_url'] || '';
        this.analytics_type = json['analytics_type'];
        this.qrcode_id = json['qrcode_id'];
        this.message = json['message'] || '';
    }
}

@Injectable()
export class AnalyticsShareService extends BaseBackendService<AnalyticsShareModal> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, AnalyticsShareModal, 'qrcodes/analytics/share', messageModal);
    }

    shareAnalytics(payload: AnalyticsShareModal): Observable<AnalyticsShareModal> {
        return this.post(payload);
    }

    shareAnalyticsInvite(payload: AnalyticsShareModal): Observable<AnalyticsShareModal> {
        return this.post(payload, 'invite/', { observeResponse: true });
    }
}
