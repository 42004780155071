import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, finalize, map, takeUntil} from 'rxjs/operators';
import {
    DBCCardTypes, getDefaultForBio, getDefaultForButton,
    getDefaultForCompanyDetails,
    getDefaultForContactDetails,
    getDefaultForPersonalInfo,
    Utils
} from 'app/shared/utils';
import {UntypedFormControl} from '@angular/forms';
import {socialMediaMetaData} from 'app/shared/social-media';
import {CustomFontsService} from 'app/global-services/custom-fonts.service';
import {
    BUTTON_STYLES,
    BUTTON_TYPES,
    CARET_POSITION,
    ICON_POSITION,
    MESSAGE_TYPES,
    TEXT_FIELD_TYPES,
    TEXT_FIELD_VALIDATIONS,
    TOOLTIP_POSITION
} from 'kaizen-design-system';
import {Animations} from '../../../shared/beaconstac-animations';
import {Media} from '../../../places/media.model';
import {environment} from 'environments/environment';
import {AuthService} from '../../../global-services/auth.service';
import {DBC_BACKGROUND_TYPE, DigitalBusinessCard} from '../../digital-business-card.model';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../../digital-business-card-setting/digital-business-card-setting.model';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../../user-account/user-organization.model';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {PLAN_TYPES} from 'app/user-account/user.model';
import {CustomFontObject} from 'app/global-services/customfont';
import {FEATURES} from 'app/user-account/organization-permission-model';
import {MessageModalService} from '../../../shared/message-modal/message-modal.service';
import {MediaService} from '../../../places/media-service';
import {MediaFolderService} from '../../../media-folders/media-folder.service';
import {ImageCropperComponent} from '../../../global-components/image-cropper/image-cropper.component';
import {MediaGalleryComponent} from '../../../global-components/media-gallery/media-gallery.component';
import {UpsellService} from '../../../global-services/upsell.service';
import {TYPOGRAPHY_CUSTOMIZATIONS} from '../../digital-business-card-utils';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../../global-services/amplitude.service';

enum IMAGE_TYPE {
    Logo,
    Background,
    VCardLogo,
    VCardUserImage
}
enum PRODUCT_CUSTOMIZATION {
    PrimaryColor,
    UserInfoColor,
    SecondaryColor,
    ButtonColor,
    IconColor,
}

@Component({
    selector: 'app-digital-business-card-design',
    templateUrl: './digital-business-card-design.component.html',
    styleUrls: ['./digital-business-card-design.component.scss', './../../../link-page/link-page-detail/link-page-detail.component.scss'],
    animations: [Animations.collapse]
})

export class DigitalBusinessCardDesignComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() product: DigitalBusinessCard = null
    @Input() hasWriteAccess = true;
    @Input() cardSetting: DigitalBusinessCardSetting;
    @Input() cardType: DBCCardTypes;
    @Input() orgCardSetting: CardsOrganizationRestrictedFields;
    @Input() priortizeOrgSettings: boolean = false;
    @Input() launchDarkleyFlags: any = {}

    @Output() cardSettingChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() productChange: EventEmitter<DigitalBusinessCard> = new EventEmitter();

    @ViewChild('handleShowModalForEnterprisePlan', { static: false }) handleShowModalForEnterprisePlan: ModalDirective;
    @ViewChild('bgImgSelectorModal', {static: false}) bgImgSelectorModal: ModalDirective;
    @ViewChild('imageCropper', {static: false}) private imageCropper: ImageCropperComponent;
    @ViewChild('bgImgSelectGallery', {static: false}) bgImgSelectGallery: MediaGalleryComponent;

    available_custom_fonts: CustomFontObject[] = [
        {
            name: 'Custom Fonts',
            value: 'Custom Fonts',
            isHeader: true
        },
        {
            name: 'No fonts added',
            value: 'No fonts added',
            isClickable: true
        },
        {
            name: 'Default fonts',
            value: 'Default fonts',
            isHeader: true
        }
    ];

    Utils: any = Utils;
    IMAGE_TYPE: any = IMAGE_TYPE;
    PRODUCT_CUSTOMIZATION = PRODUCT_CUSTOMIZATION;

    disableNextButtonVcard: boolean;
    contentChangeSubject = new Subject<null>();
    isBackgroundUploading: boolean = false;
    imageSelectModel = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
        type: this.IMAGE_TYPE.Logo
    };
    updatedMedia: Media = null;
    isLogoUploading: boolean = false;

    isFrameLoadingSubject = new Subject<boolean>();
    isFrameLoading: boolean = false;
    searchControl: UntypedFormControl = new UntypedFormControl();
    ngUnsubscribe: Subject<any> = new Subject();
    product_type: string = 'qr';
    // uploadToFolder = null;
    isOnEnterprisePlan: boolean;
    socialFieldDetails: Array<any> = [];
    socialMediaMetaData: Array<any> = socialMediaMetaData
    socialModels: any[] = [];
    isFacebook: boolean;
    isInstagram: boolean;
    isTwitter: boolean;
    isLinkedIn: boolean;
    isTikTok: boolean;
    isSnapChat: boolean;
    isWhatsapp: boolean;
    isTelegram: boolean;
    isVimeo: boolean;
    isYoutube: boolean;
    isWistia: boolean;
    isTwitch: boolean;
    isDiscord: boolean;
    isPinterest: boolean;
    isYelp: boolean;
    isPaypal: boolean;
    isVenmo: boolean;
    isCashApp: boolean;
    isCalendly: boolean;
    isShopify: boolean;
    isGithub: boolean;
    isDribbble: boolean;
    isBehance: boolean;
    isCustomUrl: boolean;
    isPreviewButtonClicked: boolean = false;
    geocoder = null;
    environment: any = environment;
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
    BUTTON_TYPES = BUTTON_TYPES;
    ICON_POSITION = ICON_POSITION;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    showLayoutSelectStep: boolean;
    BUTTON_STYLES = BUTTON_STYLES;
    CARET_POSITION = CARET_POSITION;


    // Layout Data
    layoutData: Array<Object> = Utils.DBC_LAYOUTS;
    fontList: CustomFontObject[] = Utils.available_fonts;
    fontOptionsListProfileInfo: Array<Object> = Utils.available_google_fonts;
    fontOptionsListCompanyDetails: Array<Object> = Utils.available_google_fonts
    fontOptionsListContactDetails: Array<Object> = Utils.available_google_fonts;
    fontOptionsListButton: Array<Object> = Utils.available_google_fonts;
    fontOptionsListBio: Array<Object> = Utils.available_google_fonts;
    vCardFontStyle: String = '';
    isMobileScreenSize: boolean = false;
    isComputerScreenSize: boolean = false;
    sections = { color: false, fontStyle: false, layout: false }
    notOnEnterprisePlan: boolean = false;
    // isOnSoloOrSoloTrialPlan: boolean;
    isManageCustomFontVisibleFlag: boolean;
    typographyList: TYPOGRAPHY_CUSTOMIZATIONS[] = [TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO,
        TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS,
        TYPOGRAPHY_CUSTOMIZATIONS.BUTTON, TYPOGRAPHY_CUSTOMIZATIONS.BIO];


    /* Asset Library variables for bgImgUpload */
    bgImgUpload: {
        media: Media,
        mediaUrl: string,
        isUploading: boolean,
    } = {
            media: null,
            mediaUrl: '',
            isUploading: false,
        }

    uploadToFolder = null;
    imageCropperFile: File;

    DBC_BACKGROUND_TYPE = DBC_BACKGROUND_TYPE;
    dbcBackgroundIsImg: boolean = false;
    hasDBCAdvancedCustomization: boolean = false;
    teamSoloInfoMsg: string = 'The button color for this card design will now use your chosen primary color. This change will appear on your card(s) once you finish editing.';
    showInfoMessage: boolean = false;
    previousFontType: string = 'google';

    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;
    protected readonly DBCCardTypes = DBCCardTypes;
    protected readonly TYPOGRAPHY_CUSTOMIZATIONS = TYPOGRAPHY_CUSTOMIZATIONS;


    constructor(private authService: AuthService, private router: Router, private customFontService: CustomFontsService, private messageModal: MessageModalService,
        private mediaService: MediaService,
        private mediaFolderService: MediaFolderService,
        private upsellService: UpsellService,
        private amplitude: AmplitudeService, ) {
        this.isOnEnterprisePlan = this.authService.getUser().isOnEnterprisePlan();
    }
    resizeSubject = new Subject();

    ngOnInit() {
        this.previousFontType = this.product.customizations.typography.font_type
        this.dbcBackgroundIsImg = this.isDBCBackgroundAnImg();
        if (this.authService.getUser().hasDbcPermissionFor(FEATURES.custom_font)) {
            this.getFonts();
        }
        this.showInfoMessage = this.checkIfMigratedUser();
        this.isManageCustomfontVisible();
        this.isMobileScreen();
        this.isComputerScreen();
        this.checkForMobileView();
        fromEvent(window, 'resize')
            .pipe(
                map(() => window.innerWidth),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe((width: number) => {
                this.resizeSubject.next(width);
            });

        this.resizeSubject.subscribe((width: number) => {
            this.isMobileScreenSize = width <= 767;
            this.isComputerScreenSize = width >= 768;
            this.checkForMobileView();
        });
        if (this.product.customizations.typography.font_type === 'custom') {
            const pageNumber = 1;
            const pageSize = 20;
            const params = { content_type: 'font', family : this.product.customizations.typography.font_family};
            this.customFontService.getList(pageNumber, pageSize, params).subscribe(res => {
                this.selectedListForFontFamily = res.objects;
                this.fontOptionsListProfileInfo = this.selectedListForFontFamily.map(customFont => {
                    return {
                        name: customFont['style'],
                        value: customFont['style'],
                        url : customFont['url']
                    };
                });
                this.fontOptionsListBio = this.fontOptionsListCompanyDetails = this.fontOptionsListContactDetails = this.fontOptionsListButton = this.fontOptionsListProfileInfo;
            });
        }

        this.contentChangeSubject.pipe(debounceTime(1000)).subscribe(event => {
            this.onVCardPlusUpdated();
        });
        this.isFrameLoadingSubject.pipe(debounceTime(100)).subscribe(event => {
            this.isFrameLoading = event;
        });
        this.disableNextButtonVcard = false;
        this.notOnEnterprisePlan = !this.authService.getUser().isOnHigherDBCPlan(PLAN_TYPES.DBCEnterprise);
        this.handleBackgroundType();
        this.checkDBCAdvancedCustomizationOptions();
    }

    checkIfMigratedUser(): boolean {
        const currentPlan = this.authService.getUser().customer_plan;
        const currentLayout = this.product.layout;
        const isOnSoloOrTeamPlan = [PLAN_TYPES.Solo, PLAN_TYPES.Team].includes(currentPlan);
        const { button_color, background_color } = this.product.customizations;
        const sameButtonBackgroundColor = (button_color === background_color);

        if ( isOnSoloOrTeamPlan && currentLayout === '4' && !sameButtonBackgroundColor ) {
            return true;
        }
    }

    checkIfCustomFont(): boolean {
        return (this.product.customizations.typography.font_type === 'custom')
    }

    isDBCBackgroundAnImg() {
        return (this.product.customizations.background.type === DBC_BACKGROUND_TYPE.Image)
    }

    checkDBCAdvancedCustomizationOptions(): void {
        this.hasDBCAdvancedCustomization = this.authService.getUser().hasDbcPermissionFor(FEATURES.advanced_customization_options);
    }

    getFonts() {

        const custom: any = `families/?organization=${this.authService.getCurrentOrgId()}`;
        this.customFontService.getFontFamilies(custom).subscribe(res => {
            this.SetFontsList(res.font_families);
        } , error => {
            console.log(error);
        })

    }
    SetFontsList(fontsList: any[]) {
        if (fontsList.length === 0) {
            this.available_custom_fonts = [
                {
                    name: 'Custom Fonts',
                    value: 'Custom Fonts',
                    isHeader: true
                },
                {
                    name: 'No fonts added',
                    value: 'No fonts added',
                    isClickable: false,
                },
                {
                    name: 'Default fonts',
                    value: 'Default fonts',
                    isHeader: true
                }
            ]
        } else {
            this.available_custom_fonts = [
                {
                    name: 'Custom Fonts',
                    value: 'Custom Fonts',
                    isHeader: true
                },
                ...fontsList.map((font) => {
                    return {
                        name: font,
                        value: font,
                        type: 'custom',

                    };
                }),
                {
                    name: 'Default fonts',
                    value: 'Default fonts',
                    isHeader: true
                }
            ];
        }
        if (this.notOnEnterprisePlan) {
            this.available_custom_fonts = [];
        }
        this.fontList = [...this.available_custom_fonts, ...Utils.available_fonts]
    }
    isManageCustomfontVisible(){
        if (!this.notOnEnterprisePlan) {
            if (this.authService.getUser().isAdmin) {
                this.isManageCustomFontVisibleFlag = true
            }
            this.isManageCustomFontVisibleFlag = false;
        }
        this.isManageCustomFontVisibleFlag = true;
    }


    ngAfterViewInit(): void {
        if (!this.hasWriteAccess) {
            this.Utils.disableUserInteraction()
        }
    }

    onVCardContentChange() {
        this.productChange.emit(this.product)
    }

    onIFrameLoadingStateChange(value: boolean) {
        this.isFrameLoadingSubject.next(value);
    }

    onVCardPlusUpdated() {
        this.productChange.emit(this.product)
    }

    // TODO : Make scrolling dynamic
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        const title = document.getElementById('customize-title');
        const tabs = document.getElementById('tabs');
        if (title && tabs) {
            if (window.pageYOffset >= 90 && window.innerWidth <= 414) {
                title.classList.add('sticky');
                tabs.classList.add('tabs-sticky');
            } else {
                title.classList.remove('sticky');
                tabs.classList.remove('tabs-sticky');
            }
        }
    }

    selectLayout(layout, isNew) {
        this.showLayoutSelectStep = false;
        const previous_layout = this.product.layout;
        this.product.layout = layout.name;
        if (isNew) {
            this.product.customizations = layout['customizations'];
        }
        if (['3', '5'].includes(previous_layout) && !['3', '5'].includes(this.product.layout)) {
            const white_color = ['#fff', '#FFF', '#ffffff', '#FFFFFF']
            if (white_color.includes(this.product.customizations.user_info_color)) {
                this.product.customizations.user_info_color = '#000000'
            }
        }
        this.vCardFontStyle = this.product.customizations.font_style;
        this.onVCardContentChange();
    }

    ngOnDestroy(): void {
        if (this.contentChangeSubject) {
            this.contentChangeSubject.unsubscribe();
        }
        if (this.isFrameLoadingSubject) {
            this.isFrameLoadingSubject.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isMobileScreen() {
        this.isMobileScreenSize =  window.innerWidth <= 767;
    }
    isComputerScreen() {
        this.isComputerScreenSize =  window.innerWidth >= 768;
    }
    selectedListForFontFamily: Array<Object> ;
    setFontFamily(event) {
        if (event.type === 'custom') {

            const pageNumber = 1;
            const pageSize = 20;
            const params = { content_type: 'font', family : event.value};
            this.customFontService.getList(pageNumber, pageSize, params).subscribe(res => {
                this.selectedListForFontFamily = res.objects;
                this.fontOptionsListProfileInfo = this.selectedListForFontFamily.map(customFont => {
                    return {
                        name: customFont['style'],
                        value: customFont['style'],
                        url : customFont['url']
                    };
                });
                this.fontOptionsListBio = this.fontOptionsListCompanyDetails = this.fontOptionsListContactDetails = this.fontOptionsListButton = this.fontOptionsListProfileInfo;
                this.previousFontType = this.product.customizations.typography.font_type;
                this.product.customizations.typography.font_type = 'custom';
                this.product.customizations.typography.font_family = event.value;
                this.setDefaultTypography();
            });
        } else {
            this.previousFontType = this.product.customizations.typography.font_type;
            this.product.customizations.typography.font_type = 'google';
            this.product.customizations.typography.font_family = event.value;
            this.fontOptionsListBio = this.fontOptionsListCompanyDetails = this.fontOptionsListContactDetails = this.fontOptionsListButton = this.fontOptionsListProfileInfo  = Utils.available_google_fonts;
            this.setDefaultTypography();
        }
    }

    setTypographyForAttribute(defaultTypography, attribute: TYPOGRAPHY_CUSTOMIZATIONS) {
        const {google_font_colour: fontColour , google_font_size: fontSize} = defaultTypography;

        if ( this.previousFontType !== this.product.customizations.typography.font_type )  {
            /* google and custom font have the same default font size and colour */
            this.setFontColorForTypography(fontColour, attribute);
            this.setFontSizeForTypography(fontSize, attribute);
        }

        this.setFontStyleForTypography(this.fontOptionsListProfileInfo[0], attribute)

    }

    setDefaultTypography() {
        const layout: string = this.product.layout;
        let defaultTypography: {google_font_size: number, google_font_colour: string, google_font_style: string};

        this.typographyList.forEach((typographyItem: TYPOGRAPHY_CUSTOMIZATIONS)  => {
            switch (typographyItem) {
                case TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO:
                    defaultTypography = getDefaultForPersonalInfo(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS:
                    defaultTypography = getDefaultForCompanyDetails(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS:
                    defaultTypography = getDefaultForContactDetails(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.BIO:
                    defaultTypography = getDefaultForBio(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.BIO);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.BUTTON:
                    defaultTypography = getDefaultForButton(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON);
                    break;
            }

        })
    }

    setFontSizeForTypography(event, attribute: TYPOGRAPHY_CUSTOMIZATIONS): void {
        if (this.product.customizations.typography.font_type === 'custom') {
            this.product.customizations.typography[attribute].custom_font_size = event;
            this.product.customizations.typography[attribute].google_font_size = null;
        } else {
            this.product.customizations.typography[attribute].google_font_size = event;
            this.product.customizations.typography[attribute].custom_font_size = null;
        }
        this.onVCardContentChange();
    }

    setFontStyleForTypography(event, attribute: TYPOGRAPHY_CUSTOMIZATIONS): void {
        if (this.product.customizations.typography.font_type === 'custom') {
            this.product.customizations.typography[attribute].custom_font_style = event.value;
            this.product.customizations.typography[attribute].custom_font_url = event.url;
            this.product.customizations.typography[attribute].google_font_style = '';
        } else {
            this.product.customizations.typography[attribute].google_font_style = event.value;
            this.product.customizations.typography[attribute].custom_font_style = '';
        }
        this.onVCardContentChange();
    }

    setFontColorForTypography(event, attribute: TYPOGRAPHY_CUSTOMIZATIONS) {
        if (this.product.customizations.typography.font_type === 'custom') {
            this.product.customizations.typography[attribute].custom_font_colour = event;
            this.product.customizations.typography[attribute].google_font_colour = '';
        } else {
            this.product.customizations.typography[attribute].google_font_colour = event;
            this.product.customizations.typography[attribute].custom_font_colour = ''
        }
        this.onVCardContentChange();
    }

    changeFontList(event) {
        if (this.notOnEnterprisePlan) {
            this.available_custom_fonts = [];
        }
        if (event && event.length) {
            this.fontList = [...this.available_custom_fonts, ...Utils.new_available_fonts];
            const filteredFonts = this.fontList.filter(font => font.name.toLowerCase().includes(event.toLowerCase()));
            this.fontList = [...filteredFonts, ...this.available_custom_fonts, ...Utils.new_available_fonts];
        } else {
            this.fontList = [...this.available_custom_fonts, ...Utils.available_fonts];
        }
    }


    handleClickForManageCustomFontsModal() {
        if (this.notOnEnterprisePlan) {
            if (!this.isMobileScreenSize) {
                this.upsellService.showV2({feature: 'custom_fonts', source: 'customfont'});
            } else {
                this.upsellService.showUpsellModalOnMobile({feature: 'custom_fonts', source: 'customfont'});
            }
        } else{
            const navigatePromise = new Promise<void>((resolve, reject) => {
                this.router.navigate(['/digital-business-cards/settings'], {
                    queryParams: { orgID: this.authService.getCurrentOrgId() },
                    queryParamsHandling: 'merge',
                    skipLocationChange: false
                }).then(() => resolve()).catch(() => reject());
            });
            navigatePromise.then(() => {
                this.customFontService.setScrollToManageCustomFonts(true);
            });

        }

    }
    routeToChangePlan() {
        const queryParams = {
            source: 'customfont',
            orgID: this.authService.getCurrentOrgId()
        };
        const url = this.router.createUrlTree(['/account/upgrade/'], { queryParams }).toString();
        window.open(url, '_blank');
    }

    toggleColorPicker(element) {
        document.getElementById(element).click();
    }
    checkForMobileView() {
        if (this.isMobileScreenSize) {
            const checkSectionKey = 'color';
            Object.keys(this.sections).forEach(key => {
                this.sections[key] = true;
            });
            this.sections[checkSectionKey] = false;
        } else {
            Object.keys(this.sections).forEach(key => {
                this.sections[key] = false;
            });
        }
    }
    collapseSection(section) {
        if (this.isMobileScreenSize) {
            if (this.sections[section]) {
                Object.keys(this.sections).forEach(key => {
                    if (this.sections[key] !== this.sections[section]) {
                        this.sections[key] = true;
                    }
                });
            }
            this.sections[section] = !this.sections[section];
        } else {
            this.sections[section] = !this.sections[section];
        }
    }

    onColorPickerChange(event, attribute) {
        switch (attribute) {
            case PRODUCT_CUSTOMIZATION.PrimaryColor:
                this.product.customizations.background_color = event;
                this.setButtonIconColorForSoloTeamPlan(event);
                break;
            case PRODUCT_CUSTOMIZATION.UserInfoColor:
                this.product.customizations.user_info_color = event;
                this.setProfileAndCompanyColorForSoloTeamPlan(event);
                break;
            case PRODUCT_CUSTOMIZATION.SecondaryColor:
                this.product.customizations.secondary_color = event;
                this.setBioAndContactInfoColorForSoloTeamPlan(event);
                break;
            case PRODUCT_CUSTOMIZATION.ButtonColor:
                this.product.customizations.button_color = event;
                break;
            case PRODUCT_CUSTOMIZATION.IconColor:
                this.product.customizations.icon_color = event;
                break;
        }
        this.onVCardContentChange();
    }



    setBioAndContactInfoColorForSoloTeamPlan(event: string): void {
        if (!this.hasDBCAdvancedCustomization) {
            this.product.customizations.typography.bio.google_font_colour = event;
            this.product.customizations.typography.contact_details.google_font_colour = event;
        }
    }

    setProfileAndCompanyColorForSoloTeamPlan(event: string): void {
        if (!this.hasDBCAdvancedCustomization) {
            this.product.customizations.typography.company_details.google_font_colour = event;
            this.product.customizations.typography.personal_info.google_font_colour = event;
        }
    }

    setButtonIconColorForSoloTeamPlan(event: string): void {
        if (!this.hasDBCAdvancedCustomization) {
            this.product.customizations.button_color = event;
            this.product.customizations.icon_color = event;
        }
    }

    handleIconClick(property: CARD_PROPERTY_TYPES) {
        if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
            switch (property) {
                case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                case CARD_PROPERTY_TYPES.EMAIL_WALLET_PASS:
                case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                    this.cardSetting[property] = this.cardSetting[property] === DBC_ORG_PERMISSION_OPTIONS.YES ? DBC_ORG_PERMISSION_OPTIONS.NO : DBC_ORG_PERMISSION_OPTIONS.YES;
                    break;
                default:
                    this.cardSetting[property] = !this.cardSetting[property]
                    break;
            }
            this.cardSettingChange.emit(this.cardSetting);
            this.onVCardContentChange();
        }
    }

    setBackgroundType({type}: {type: DBC_BACKGROUND_TYPE}): void {
        if (type === this.DBC_BACKGROUND_TYPE.Color) {
            this.dbcBackgroundIsImg = false;
            this.setupBgImgSelectModal();
            this.updateBackgroundImg('')
            this.updateBackgroundColor('#FFFFFF')
        } else {
            this.dbcBackgroundIsImg = true;
            this.updateBackgroundColor('#FFFFFF')
            this.updateBackgroundImg('')
        }
    }

    onBackgroundColorChange($event): void {
        this.updateBackgroundColor($event)
    }

    updateBackgroundColor(color: string): void {
        this.product.customizations.background.type = this.DBC_BACKGROUND_TYPE.Color;
        this.product.customizations.background.value = color
        this.onVCardContentChange();
    }

    updateBackgroundImg(mediaUrl: string): void {
        this.product.customizations.background.type = this.DBC_BACKGROUND_TYPE.Image;
        this.product.customizations.background.value = mediaUrl;
        this.onVCardContentChange();
    }

    onBgImgAdded(file: File): void {
        this.bgImgUpload.isUploading = true;
        this.mediaFolderService.getList(1, 1).pipe(
            takeUntil(this.ngUnsubscribe)
        ).subscribe((folder) => {
            this.mediaService.uploadFile(file, 'image', null, null, folder.objects[0].id).pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => {
                    this.bgImgUpload.isUploading = false;
                })).subscribe(media => {
                Object.assign(this.bgImgUpload, { media, mediaUrl: media.url });
                this.updateBackgroundImg(this.bgImgUpload.mediaUrl);
            }, error => {
                console.error(error);
                this.messageModal.show('Image upload failed', 'danger');
            });
        });
    }

    onBgImgRemoved(): void {
        this.updateBackgroundImg('')
    }

    loadCropper(file: File): void {
        this.imageCropperFile = file;
        if (this.imageCropperFile.type !== 'image/gif') {
            this.bgImgSelectorModal.hide();
            this.showImageCropper();
        }
    }

    showImageCropper(): void {
        if (!this.imageCropper) {
            setTimeout(() => {
                this.showImageCropper();
            }, 100);
            return;
        }
        this.imageCropper.show();
    }

    closeImageCropperModal(): void {
        this.setUpImageCropperModal();
    }

    setUpImageCropperModal(): void {
        this.imageCropperFile = null;
    }

    imageSelectedInGallery({media}: {media: Media}): void {
        Object.assign(this.bgImgUpload, { media, mediaUrl: media.url });
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl);
    }

    onSelectBgImg(): void {
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl)
        this.bgImgSelectorModal.hide()
    }

    onCloseBgImgSelectGallery(): void {
        this.bgImgUpload.media = null;
        this.bgImgUpload.mediaUrl = '';
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl)
        this.bgImgSelectorModal.hide()
    }

    setUploadToFolder(folder): void {
        this.uploadToFolder = folder
    }

    setupBgImgSelectModal(): void {

        if (!this.imageCropperFile) {
            this.bgImgUpload = {
                media: null,
                mediaUrl: '',
                isUploading: false,
            };
            this.bgImgSelectGallery.fetchFolders();
        }
    }

    updateMedia(media: Media): void {
        if (this.imageCropper) {
            this.imageCropper.hide();
            this.setUpImageCropperModal()
        }

        Object.assign(this.bgImgUpload, { media, mediaUrl: media.url });
        this.updateBackgroundImg(this.bgImgUpload.mediaUrl);
    }

    handleBackgroundType(): void {
        if (this.product.customizations.background.type === DBC_BACKGROUND_TYPE.Image) {
            this.dbcBackgroundIsImg = true;
            this.bgImgUpload.mediaUrl = this.product.customizations.background.value;
        } else {
            this.dbcBackgroundIsImg = false;
        }
    }

    showAdvancedCustomizationUpsell(): void {
        const user = this.authService.getUser();
        const isOnTrial = user.isOnSoloTrial() || user.isOnTeamTrial();
        const upsellFeature = { feature: 'advanced_customization', source: 'advanced_customization_upsell' };
        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, AMPLITUDE_EVENTS.ADVANCED_CUSTOMIZATION, {
            source: 'card creation flow'})

        if ( isOnTrial ) {
            this.upsellService.isTrialSwitchModal.next(PLAN_TYPES.Business);
        } else {
            this.upsellService.eventSource.next('customisations-upgrade-cta')
        }

        if (!this.isMobileScreenSize) {
            this.upsellService.showV2(upsellFeature)
        } else {
            this.upsellService.showUpsellModalOnMobile(upsellFeature)
        }
    }

    protected readonly MESSAGE_TYPES = MESSAGE_TYPES;
}

