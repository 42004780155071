import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalBusinessCardAnalyticsComponent } from './digital-business-card-analytics.component';
import { BeaconstacModule } from 'kaizen-design-system';
import { DigitalBusinessCardAnalyticsRoutingModule } from './digital-business-card-analytics-routing.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
    BsLocaleService,
} from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapModule } from 'app/global-components/map/map.module';
import { INgxSelectOptions, NgxSelectModule } from 'ngx-select-ex';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'app/global-components/pipes/pipes.module';
import {BeaconstacDatepickerModule} from '../../global-components/beaconstac-datepicker/beaconstac-datepicker.module';
import { FilterProductModule } from 'app/global-components/filter-products/filter-products.module';


const CustomSelectOptions: INgxSelectOptions = {
    optionValueField: 'id',
    keepSelectedItems: true,
    noResultsFound: '',
};

@NgModule({
    declarations: [DigitalBusinessCardAnalyticsComponent],
    imports: [
        CommonModule,
        PipesModule,
        BeaconstacModule,
        ModalModule,
        FormsModule,
        GoogleMapsModule,
        MapModule,
        DigitalBusinessCardAnalyticsRoutingModule,
        NgxChartsModule,
        NgxSelectModule.forRoot(CustomSelectOptions),
        BeaconstacDatepickerModule,
        FilterProductModule
    ],
    exports: [DigitalBusinessCardAnalyticsComponent],
    providers: [
        BsLocaleService,
    ],
})
export class DigitalBusinessCardAnalyticsModule {}
