import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {Subject , fromEvent} from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
    DBCCardTypes, getDefaultForBio, getDefaultForButton,
    getDefaultForCompanyDetails,
    getDefaultForContactDetails,
    getDefaultForPersonalInfo,
    Utils
} from 'app/shared/utils';
import {UntypedFormControl} from '@angular/forms';
import {socialMediaMetaData} from 'app/shared/social-media';
import { CustomFontsService } from 'app/global-services/custom-fonts.service';
import {
    BUTTON_STYLES,
    BUTTON_TYPES,
    CARET_POSITION,
    TEXT_FIELD_TYPES,
    TEXT_FIELD_VALIDATIONS,
    TOOLTIP_POSITION
} from 'kaizen-design-system';
import { Animations } from 'app/shared/beaconstac-animations';
import { Media } from 'app/places/media.model';
import { environment } from 'environments/environment';
import { AuthService } from 'app/global-services/auth.service';
import { DigitalBusinessCard, DigitalBusinessCardBulkModel } from 'app/digital-business-card/digital-business-card.model';
import { CARD_PROPERTY_TYPES, DigitalBusinessCardSetting } from 'app/digital-business-card/digital-business-card-setting/digital-business-card-setting.model';
import { CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS } from 'app/user-account/user-organization.model';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PLAN_TYPES } from 'app/user-account/user.model';
import { CustomFontObject } from 'app/global-services/customfont';
import { FEATURES } from 'app/user-account/organization-permission-model';
import {TYPOGRAPHY_CUSTOMIZATIONS} from '../../digital-business-card-utils';

enum IMAGE_TYPE {
    Logo,
    Background,
    VCardLogo,
    VCardUserImage
}

enum PRODUCT_CUSTOMIZATION {
    PrimaryColor,
    UserInfoColor,
    SecondaryColor
}

@Component({
    selector: 'app-typography-fonts',
    templateUrl: './typography-fonts.component.html',
    styleUrls: ['./typography-fonts.component.scss'],
    animations: [Animations.collapse]
})
export class TypographyFontsComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() product: DigitalBusinessCard | DigitalBusinessCardBulkModel = null
    @Input() hasWriteAccess = true;
    @Input() cardSetting: DigitalBusinessCardSetting;
    @Input() cardType: DBCCardTypes;
    @Input() orgCardSetting: CardsOrganizationRestrictedFields;
    @Input() priortizeOrgSettings: boolean = false;

    @Output() cardSettingChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() productChange: EventEmitter<any> = new EventEmitter();

    @ViewChild('handleShowModalForEnterprisePlan', { static: false }) handleShowModalForEnterprisePlan: ModalDirective;


    available_custom_fonts: CustomFontObject[] = [
        {
            name: 'Custom Fonts',
            value: 'Custom Fonts',
            isHeader: true
        },
        {
            name: 'No fonts added',
            value: 'No fonts added',
            isClickable: true
        },
        {
            name: 'Google Fonts',
            value: 'Google Fonts',
            isHeader: true
        }
    ];

    Utils: any = Utils;
    IMAGE_TYPE: any = IMAGE_TYPE;
    PRODUCT_CUSTOMIZATION = PRODUCT_CUSTOMIZATION;

    disableNextButtonVcard: boolean;
    contentChangeSubject = new Subject<null>();
    isIconUploading: boolean = false;
    isBackgroundUploading: boolean = false;
    imageSelectModel = {
        media: <Media>null,
        link: '',
        alt: '',
        isUploading: false,
        type: this.IMAGE_TYPE.Logo
    };
    imageCropperFile: File;
    updatedMedia: Media = null;
    isLogoUploading: boolean = false;

    isFrameLoadingSubject = new Subject<boolean>();
    isFrameLoading: boolean = false;
    searchControl: UntypedFormControl = new UntypedFormControl();
    ngUnsubscribe: Subject<any> = new Subject();
    product_type: string = 'qr';
    uploadToFolder = null;
    isOnEnterprisePlan: boolean;
    socialFieldDetails: Array<any> = [];
    socialMediaMetaData: Array<any> = socialMediaMetaData
    socialModels: any[] = [];
    isFacebook: boolean;
    isInstagram: boolean;
    isTwitter: boolean;
    isLinkedIn: boolean;
    isTikTok: boolean;
    isSnapChat: boolean;
    isWhatsapp: boolean;
    isTelegram: boolean;
    isVimeo: boolean;
    isYoutube: boolean;
    isWistia: boolean;
    isTwitch: boolean;
    isDiscord: boolean;
    isPinterest: boolean;
    isYelp: boolean;
    isPaypal: boolean;
    isVenmo: boolean;
    isCashApp: boolean;
    isCalendly: boolean;
    isShopify: boolean;
    isGithub: boolean;
    isDribbble: boolean;
    isBehance: boolean;
    isCustomUrl: boolean;
    isPreviewButtonClicked: boolean = false;
    geocoder = null;
    environment: any = environment;
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
    BUTTON_TYPES = BUTTON_TYPES;
    TOOLTIP_POSITION = TOOLTIP_POSITION;
    showLayoutSelectStep: boolean;
    BUTTON_STYLES = BUTTON_STYLES;
    CARET_POSITION = CARET_POSITION;


    // Layout Data
    layoutData: Array<Object> = Utils.DBC_LAYOUTS;
    fontList: CustomFontObject[] = Utils.available_fonts;
    fontOptionsListProfileInfo: Array<Object> = Utils.available_google_fonts;
    fontOptionsListCompanyDetails: Array<Object> = Utils.available_google_fonts
    fontOptionsListContactDetails: Array<Object> = Utils.available_google_fonts;
    fontOptionsListButton: Array<Object> = Utils.available_google_fonts;
    fontOptionsListBio: Array<Object> = Utils.available_google_fonts;
    vCardFontStyle: String = '';
    isMobileScreenSize: boolean = false;
    isComputerScreenSize: boolean = false;
    sections = { color: false, fontStyle: false, layout: true }
    notOnEnterprisePlan: boolean = false;
    isProfileInfoFontSizeScreen: boolean = false;
    isOnSoloOrSoloTrialPlan: boolean;
    hasDBCAdvancedCustomization: boolean = false; typographyList: TYPOGRAPHY_CUSTOMIZATIONS[] = [TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO,
        TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS,
        TYPOGRAPHY_CUSTOMIZATIONS.BUTTON, TYPOGRAPHY_CUSTOMIZATIONS.BIO];

    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;
    protected readonly DBCCardTypes = DBCCardTypes;
    protected readonly TYPOGRAPHY_CUSTOMIZATIONS = TYPOGRAPHY_CUSTOMIZATIONS;

    constructor(private authService: AuthService, private router: Router, private customFontService: CustomFontsService) {
        this.isOnEnterprisePlan = this.authService.getUser().isOnEnterprisePlan();
    }
    resizeSubject = new Subject();
    ngOnInit() {

        if (this.authService.getUser().hasDbcPermissionFor(FEATURES.custom_font)) {
            this.getFonts();
        }
        this.isManageCustomfontVisible();
        this.isMobileScreen();
        this.isComputerScreen();
        fromEvent(window, 'resize')
            .pipe(
                map(() => window.innerWidth),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe((width: number) => {
                this.resizeSubject.next(width);
            });

        this.resizeSubject.subscribe((width: number) => {
            this.isMobileScreenSize = width <= 767;
            this.isComputerScreenSize = width >= 768;
        });
        this.isOnSoloOrSoloTrialPlan = this.authService.getUser().isOnSoloPlan() || this.authService.getUser().isOnSoloTrial();
        if (this.product.customizations.typography.font_type === 'custom') {
            const pageNumber = 1;
            const pageSize = 20;
            const params = { content_type: 'font', family : this.product.customizations.typography.font_family};
            this.customFontService.getList(pageNumber, pageSize, params).subscribe(res => {
                this.selectedListForFontFamily = res.objects;
                this.fontOptionsListProfileInfo = this.selectedListForFontFamily.map(customFont => {
                    return {
                        name: customFont['style'],
                        value: customFont['style'],
                        url : customFont['url']
                    };
                });
                this.fontOptionsListBio = this.fontOptionsListCompanyDetails = this.fontOptionsListContactDetails = this.fontOptionsListButton = this.fontOptionsListProfileInfo;
            });
        }

        this.contentChangeSubject.pipe(debounceTime(1000)).subscribe(event => {
            this.onVCardPlusUpdated();
        });
        this.isFrameLoadingSubject.pipe(debounceTime(100)).subscribe(event => {
            this.isFrameLoading = event;
        });
        this.disableNextButtonVcard = false;
        this.notOnEnterprisePlan = !this.authService.getUser().isOnHigherDBCPlan(PLAN_TYPES.DBCEnterprise);
        this.checkDBCAdvancedCustomizationOptions();
    }

    checkDBCAdvancedCustomizationOptions(): void {
        this.hasDBCAdvancedCustomization = this.authService.getUser().hasDbcPermissionFor(FEATURES.advanced_customization_options);
    }

    getFonts() {

        const custom: any = `families/?organization=${this.authService.getCurrentOrgId()}`;
        this.customFontService.getFontFamilies(custom).subscribe(res => {
            this.SetFontsList(res.font_families);
        } , error => {
            console.log(error);
        })

    }
    SetFontsList(fontsList: any[]) {
        if (fontsList.length === 0) {
            this.available_custom_fonts = [
                {
                    name: 'Custom Fonts',
                    value: 'Custom Fonts',
                    isHeader: true
                },
                {
                    name: 'No fonts added',
                    value: 'No fonts added',
                    isClickable: false,
                },
                {
                    name: 'Google Fonts',
                    value: 'Google Fonts',
                    isHeader: true
                }
            ]
        } else {
            this.available_custom_fonts = [
                {
                    name: 'Custom Fonts',
                    value: 'Custom Fonts',
                    isHeader: true
                },
                ...fontsList.map((font) => {
                    return {
                        name: font,
                        value: font,
                        type: 'custom',

                    };
                }),
                {
                    name: 'Google Fonts',
                    value: 'Google Fonts',
                    isHeader: true
                }
            ];
        }
        if (this.notOnEnterprisePlan) {
            this.available_custom_fonts = [];
        }
        this.fontList = [...this.available_custom_fonts, ...Utils.available_fonts]
    }
    isManageCustomfontVisible(){
        if (!this.notOnEnterprisePlan) {
            if (this.authService.getUser().isAdmin) {
                return true
            }
            return false;
        }
        return true;
    }

    ngAfterViewInit(): void {
        if (!this.hasWriteAccess) {
            this.Utils.disableUserInteraction()
        }
    }

    onVCardContentChange() {
        this.productChange.emit(this.product)
    }

    onIFrameLoadingStateChange(value: boolean) {
        this.isFrameLoadingSubject.next(value);
    }

    onVCardPlusUpdated() {
        this.productChange.emit(this.product)
    }

    // TODO : Make scrolling dynamic
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
        const title = document.getElementById('customize-title');
        const tabs = document.getElementById('tabs');
        if (title && tabs) {
            if (window.pageYOffset >= 90 && window.innerWidth <= 414) {
                title.classList.add('sticky');
                tabs.classList.add('tabs-sticky');
            } else {
                title.classList.remove('sticky');
                tabs.classList.remove('tabs-sticky');
            }
        }
    }


    ngOnDestroy(): void {
        if (this.contentChangeSubject) {
            this.contentChangeSubject.unsubscribe();
        }
        if (this.isFrameLoadingSubject) {
            this.isFrameLoadingSubject.unsubscribe();
        }
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    isMobileScreen() {
        this.isMobileScreenSize = window.innerWidth <= 767;
    }
    isComputerScreen() {
        this.isComputerScreenSize =  window.innerWidth >= 768;
    }
    selectedListForFontFamily: Array<Object> ;
    setFontFamily(event) {
        if (event.type === 'custom') {

            const pageNumber = 1;
            const pageSize = 20;
            const params = { content_type: 'font', family : event.value};
            this.customFontService.getList(pageNumber, pageSize, params).subscribe(res => {
                this.selectedListForFontFamily = res.objects;
                this.fontOptionsListProfileInfo = this.selectedListForFontFamily.map(customFont => {
                    return {
                        name: customFont['style'],
                        value: customFont['style'],
                        url : customFont['url']
                    };
                });
                this.fontOptionsListBio = this.fontOptionsListCompanyDetails = this.fontOptionsListContactDetails = this.fontOptionsListButton = this.fontOptionsListProfileInfo;
                this.product.customizations.typography.font_type = 'custom';
                this.product.customizations.typography.font_family = event.value;
                this.product.customizations.typography.personal_info.custom_font_size = 24;
                this.setDefaultTypography();
            });
        } else {
            this.product.customizations.typography.font_type = 'google';
            this.product.customizations.typography.font_family = event.value;
            this.product.customizations.typography.personal_info.google_font_size = 24;
            this.fontOptionsListBio = this.fontOptionsListCompanyDetails = this.fontOptionsListContactDetails = this.fontOptionsListButton = this.fontOptionsListProfileInfo  = Utils.available_google_fonts;
            this.setDefaultTypography();

        }
    }

    setTypographyForAttribute(defaultTypography, attribute: TYPOGRAPHY_CUSTOMIZATIONS) {
        const {google_font_colour: fontColour , google_font_size: fontSize} = defaultTypography;

        /* google and custom font have the same default font size and colour */
        this.setFontColorForTypography(fontColour, attribute);
        this.setFontSizeForTypography(fontSize, attribute);

        this.setFontStyleForTypography(this.fontOptionsListProfileInfo[0], attribute)

    }

    setDefaultTypography() {
        const layout: string = this.product.layout;
        let defaultTypography: {google_font_size: number, google_font_colour: string, google_font_style: string};

        this.typographyList.forEach((typographyItem: TYPOGRAPHY_CUSTOMIZATIONS)  => {
            switch (typographyItem) {
                case TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO:
                    defaultTypography = getDefaultForPersonalInfo(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.PERSONAL_INFO);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS:
                    defaultTypography = getDefaultForCompanyDetails(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.COMPANY_DETAILS);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS:
                    defaultTypography = getDefaultForContactDetails(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.CONTACT_DETAILS);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.BIO:
                    defaultTypography = getDefaultForBio(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.BIO);
                    break;
                case TYPOGRAPHY_CUSTOMIZATIONS.BUTTON:
                    defaultTypography = getDefaultForButton(layout);
                    this.setTypographyForAttribute(defaultTypography, TYPOGRAPHY_CUSTOMIZATIONS.BUTTON);
                    break;
            }
        })
    }

    changeFontList(event) {
        if (this.notOnEnterprisePlan) {
            this.available_custom_fonts = [];
        }
        if (event && event.length) {
            this.fontList = [...this.available_custom_fonts, ...Utils.new_available_fonts];
            const filteredFonts = this.fontList.filter(font => font.name.toLowerCase().includes(event.toLowerCase()));
            this.fontList = [...filteredFonts, ...this.available_custom_fonts, ...Utils.new_available_fonts];
        } else {
            this.fontList = [...this.available_custom_fonts, ...Utils.available_fonts];
        }
    }


    handleClickForManageCustomFontsModal() {
        if (this.notOnEnterprisePlan) {
            this.handleShowModalForEnterprisePlan.show();
        } else {
            this.router.navigate(['/digital-business-cards/settings'], {
                queryParams: { orgID: this.authService.getCurrentOrgId() }
            });
        }

    }
    routeToChangePlan() {
        const queryParams = {
            source: 'customfont',
            orgID: this.authService.getCurrentOrgId()
        };
        const url = this.router.createUrlTree(['/account/upgrade/'], { queryParams }).toString();
        window.open(url, '_blank');
    }


    collapseSection(section) {
        this.sections[section] = !this.sections[section]
    }

    handleIconClick(property: CARD_PROPERTY_TYPES) {
        if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
            switch (property) {
                case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                case CARD_PROPERTY_TYPES.EMAIL_WALLET_PASS:
                case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                    this.cardSetting[property] = this.cardSetting[property] === DBC_ORG_PERMISSION_OPTIONS.YES ? DBC_ORG_PERMISSION_OPTIONS.NO : DBC_ORG_PERMISSION_OPTIONS.YES;
                    break;
                default:
                    this.cardSetting[property] = !this.cardSetting[property]
                    break;
            }
            this.cardSettingChange.emit(this.cardSetting);
        }
    }

    setFontStyleForTypography(event, attribute: TYPOGRAPHY_CUSTOMIZATIONS): void {
        if (this.product.customizations.typography.font_type === 'custom') {
            this.product.customizations.typography[attribute].custom_font_style = event.value;
            this.product.customizations.typography[attribute].custom_font_url = event.url;
            this.product.customizations.typography[attribute].google_font_style = '';
        } else {
            this.product.customizations.typography[attribute].google_font_style = event.value;
            this.product.customizations.typography[attribute].custom_font_style = '';
        }
        this.onVCardContentChange();
    }

    setFontSizeForTypography(event, attribute: TYPOGRAPHY_CUSTOMIZATIONS): void {
        if (this.product.customizations.typography.font_type === 'custom') {
            this.product.customizations.typography[attribute].custom_font_size = event;
            this.product.customizations.typography[attribute].google_font_size = null;
        } else {
            this.product.customizations.typography[attribute].google_font_size = event;
            this.product.customizations.typography[attribute].custom_font_size = null;
        }
        this.onVCardContentChange();
    }

    setFontColorForTypography(event, attribute: TYPOGRAPHY_CUSTOMIZATIONS) {
        if (this.product.customizations.typography.font_type === 'custom') {
            this.product.customizations.typography[attribute].custom_font_colour = event;
            this.product.customizations.typography[attribute].google_font_colour = '';
        } else {
            this.product.customizations.typography[attribute].google_font_colour = event;
            this.product.customizations.typography[attribute].custom_font_colour = ''
        }
        this.onVCardContentChange();
    }

}
