import {finalize, takeUntil} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../global-services/auth.service';
import {PLAN_INTERVALS, UserSubscriptionService} from '../../global-services/user-subscription-service';
import {Card, PLAN_TYPE, UserService} from 'app/user-account/user.service';
import {environment} from '../../../environments/environment';
import {OverlayService} from 'app/global-services/overlay.service';
import {MessageModalService} from 'app/shared/message-modal/message-modal.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Animations} from 'app/shared/beaconstac-animations';
import {DBC_MINM_USER_SEATS, DBC_PLANS_FEATURES_DATA, DBC_PRICE_RATES, PLAN_TYPES, SUBSCRIPTION_STATE, User} from 'app/user-account/user.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggedInGuard} from 'app/guards/logged-in.guard';
import {IntercomService} from 'app/global-services/intercom.service';
import {Subject} from 'rxjs';
import { debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {AMPLITUDE_EVENT_CATEGORIES, AmplitudeService} from 'app/global-services/amplitude.service';
import {RouterReloadService} from 'app/global-services/router-reload.service';
import {DASHBOARD_MENU, getCookie, Utils, PRODUCT_TYPES, CONFIG_VALUES} from 'app/shared/utils';
import * as moment from 'moment-timezone';
import {BUTTON_STYLES, BUTTON_TYPES, TEXT_FIELD_TYPES} from 'kaizen-design-system';
import {GoogleAnalyticsService} from 'app/global-services/google-analytics.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { DBC_FEATURE_DETAILS_DATA } from 'app/user-account/dbc-feature-details-data';
import {SearchParams} from '../../global-services/base-backend.service';
import {USER_SEATS_ADD_LIMIT} from '../beaconstac-add-on/beaconstac-add-on.component';

enum UPGRADE_SECTION {
    Plan,
    Duration,
    Payment
}

// Black Friday
// @ts-ignore
enum BLACK_FRIDAY_COUNPONS {
    LT= 'SUB_BLK_LT_2022_40',
    PO= 'SUB_BLK_PO_2022_30',
    PL= 'SUB_BLK_PL_2022_25'
}


@Component({
    selector: 'app-dbc-renew-component',
    templateUrl: './dbc-renew-component.component.html',
    styleUrls: ['./dbc-renew-component.component.scss'],
    animations: [Animations.collapse],

})
export class DbcRenewComponentComponent  implements AfterViewInit, OnInit, OnDestroy {
    private static stripeCardBaseURL = `${environment.baseURL}${environment.apiEndpoint}${environment.apiVersion}/users/`;

    UPGRADE_SECTION: any = UPGRADE_SECTION;
    PLAN_INTERVALS: any = PLAN_INTERVALS;
    PLAN_TYPES: any = PLAN_TYPES;
    subscription$: any;

    // plan duration
    planYearly: boolean = true;
    // plan type
    planType: PLAN_TYPES = PLAN_TYPES.Pro;

    cards: Array<Card> = [];
    useExistingCard: boolean = false;
    selectedCard: Card;

    monthlyPrice: string = '15';
    yearlyPrice: string = '5';
    currentPlanInterval: PLAN_INTERVALS;
    currentPlanId: string;
    eventSourceQueryParams: string;

    user: User;

    upgradeModel = {
        name: '',
        price: 0,
        duration: ''
    };

    totalPrice = {
        monthly: '',
        yearly: ''
    };

    couponCodeModel = {
        couponCode: '',
        error: false,
        loading: false,
        success: false,
        coupon: null
    };

    plansFullName = {
        SL: 'solo',
        TM: 'team',
        BN: 'business',
    };

    dbcPlansYearlyPrice =  {
        SL: '',
        TM: '',
        BN: '',
    }

    showDbcPlans = {
        Solo: true,
        Team: true,
        Business: true,
        Enterprise: true
    }
    // initialized by LoggedInGuard
    previousPage;

    currentSection: UPGRADE_SECTION = UPGRADE_SECTION.Plan;
    hasSelectedPlan: boolean = false;
    hasSelectedDuration: boolean = false;

    infoSectionPlan: number = 1;
    ngUnsubscribe: Subject<any> = new Subject();
    private userSeatChangeSubject = new Subject<number>();

    plansViewLogged: boolean = false;

    // coupon code campaigns
    planFromQueryParams: string;
    couponFromQueryParams: string;
    discountApplied: boolean;
    couponDiscountPercentage: number;
    couponDiscountAmount: number;
    planPrice: number;
    utils = Utils
    nextBillingDate: string;
    lastBillingDate: string;
    isOnTrial: boolean
    userSelectedPlan: PLAN_TYPES;
    childUsersCount: number;
    readablePlan: string = '';

    // Kaizen Enums
    BUTTON_TYPES = BUTTON_TYPES
    BUTTON_STYLES = BUTTON_STYLES
    TEXT_FIELD_TYPES = TEXT_FIELD_TYPES

    priceSubscription: any;

    isDBCPlanExpired: boolean = false;
    isOnDBCRenewPage: boolean = false;


    selectedDBCPlan: PLAN_TYPES;
    DASHBOARD_MENU = DASHBOARD_MENU;
    currentDashboard = DASHBOARD_MENU.CARDS;

    dbcUserSeatsSubject: Subject<String> = new Subject<String>();
    dbcUserSeats: string | number = 0;
    dbcUserSeatsPrice: number;
    DBC_FEATURE_DETAILS_DATA = DBC_FEATURE_DETAILS_DATA
    dbcTrialPlan: PLAN_TYPES;
    dbcUserLimit: number;
    planTypeIndex: number = 0;
    isFullCollapsed: boolean = true;
    showSliderErrorMessage: boolean = false;
    currentActiveDBCUsers: number = 0;
    searchParams: SearchParams = {};
    minUsersToShow: number = 0;

    DBC_PRICE_RATES = DBC_PRICE_RATES;
    DBC_PLANS_FEATURES_DATA = DBC_PLANS_FEATURES_DATA;
    incrementPressTimer: any;
    decrementPressTimer: any;
    longPressInterval: number = 100;
    private longPressThreshold: number = 500;
    pressStartTime: number;
    showBusinessPlusFrame: boolean = false;


    @ViewChild('childUserWarningModal', {static: false}) childUserWarningModal: ModalDirective;

    // Black friday sale
    date: any;
    now: any;
    difference: number;
    @ViewChild('days', { static: false }) days: ElementRef;
    @ViewChild('hours', { static: false }) hours: ElementRef;
    @ViewChild('minutes', { static: false }) minutes: ElementRef;
    @ViewChild('seconds', { static: false }) seconds: ElementRef;
    blackFridayBannerWidth: string
    showBlackFridayPrice: boolean

    constructor(private http: HttpClient, private authService: AuthService, private overlay: OverlayService,
        private messageModal: MessageModalService, private userSubscriptionService: UserSubscriptionService,
        private userService: UserService, private intercom: IntercomService, private amplitude: AmplitudeService,
        private router: Router, private route: ActivatedRoute,
        private googleAnalyticsService: GoogleAnalyticsService,
        private routerReloadService: RouterReloadService) {
        this.subscription$ = this.userSubscriptionService.subscription$;
        this.authService.addDatadogAction('UpgradeAttempted');
        this.overlay.isLoading(false);
        this.user = this.authService.getUser();

        this.isOnTrial = this.user.isOnTrialPlan(PRODUCT_TYPES.DBC);

        this.dbcUserSeatsSubject.pipe(
            debounceTime(250),
            distinctUntilChanged())
            .subscribe(value => {
                this.handleUserSeatChange(value);
            });

        if (this.user.subscription.dbc?.state === SUBSCRIPTION_STATE.Expired) {
            this.isDBCPlanExpired = true;
        }
        if (this.router.url.includes('/renew-plan')) {
            this.isOnDBCRenewPage = true;
        }

        if (this.user.isOwner()) {
            this.userSubscriptionService.getSubscriptionDetails();
            this.subscription$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(subscription => {
                // @ts-ignore
                this.currentPlanInterval = subscription && subscription.plan && subscription.plan.id.indexOf('YEAR') !== -1
                    ? this.PLAN_INTERVALS.Yearly : this.PLAN_INTERVALS.Monthly;
                // @ts-ignore
                this.currentPlanId = subscription?.plan?.id ? subscription.plan.id : '';
            });
        }
    }

    ngOnInit(): void {
        this.dbcUserLimit = this.user.organization.account_limits?.dbc_user_seats;
        if (!this.user.isOwner()) {
            this.router.navigate(['/overview'], {queryParams: {orgID: this.authService.getCurrentOrgId()}});
            return;
        }
        this.previousPage = LoggedInGuard.previousPage;
        this.route.fragment.subscribe(fragment => {
            this.overlay.isLoading(false);
            switch (fragment) {
                case UPGRADE_SECTION[this.UPGRADE_SECTION.Plan].toString().toLowerCase():
                case null:
                    this.currentSection = this.UPGRADE_SECTION.Plan;
                    if ( this.dbcUserSeats ) {
                        this.sliderColorRange(Number(this.dbcUserSeats))
                    }
                    break;
                case this.UPGRADE_SECTION[this.UPGRADE_SECTION.Payment].toString().toLowerCase():
                    this.currentSection = this.UPGRADE_SECTION.Payment;
                    setTimeout(() => {
                        this.getToken();
                    }, 1000);
                    this.fetchUpcomingPrice();
                    break;
            }
        });
        this.fetchDBCUsers();
        this.handleQueryParams();
        this.userSeatChangeSubject
            .pipe(debounceTime(3000))
            .subscribe(value => {
                this.handleMinValueOfUserSlider(value);
            });
    }

    fetchDBCUsers() {
        this.searchParams['permissions'] = this.currentDashboard === DASHBOARD_MENU.QRCODES ? 'qr' : 'cards';
        this.userService.getList(1, 1, this.searchParams).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result: any) => {
            this.currentActiveDBCUsers = result.totalCount;
            this.minUsersToShow = this.currentActiveDBCUsers
            this.handleDbcPlansToShow(this.currentActiveDBCUsers);
        });
    }

    handleQueryParams(): void {
        this.route.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            if (!this.plansViewLogged) {
                this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'view plans', {
                    source: params['source'] || 'unknown'
                });
                this.plansViewLogged = true;
            }
            if (params['plan'] && !(params['skip_user_segmentation'] && params['user_seats'])) {
                const plansAvailable = Object.values(this.plansFullName)
                let planFromParams = params['plan'].toLowerCase()
                if (plansAvailable.includes(planFromParams)) {
                    const index = Object.values(this.plansFullName).indexOf(planFromParams);
                    planFromParams = Object.keys(this.plansFullName)[index]
                    if (!this.user.isOnHigherPlan(planFromParams) && this.user.customer_plan !== planFromParams) {
                        this.planFromQueryParams = planFromParams;
                        this.onPlanTypeClicked(planFromParams);
                    } else {
                        return
                    }
                }
            }
            if (params['coupon']) {
                this.couponFromQueryParams = params['coupon'];
                if (this.planFromQueryParams) {
                    this.couponCodeModel.couponCode = this.couponFromQueryParams;
                    this.onCouponApplied();
                }
            }
            if (params['skip_user_segmentation'] && params['user_seats']) {
                const plansAvailable = Object.values(this.plansFullName)
                let dbcPlanFromParams = params['plan'].toLowerCase()
                if (plansAvailable.includes(dbcPlanFromParams)) {
                    const index = plansAvailable.indexOf(dbcPlanFromParams);
                    dbcPlanFromParams = Object.keys(this.plansFullName)[index]
                    if (this.dbcTrialPlan || (!this.user.isOnHigherDBCPlan(dbcPlanFromParams) && this.user.customer_plan !== dbcPlanFromParams)) {
                        this.planFromQueryParams = dbcPlanFromParams;
                        this.onDBCPlanTypeClicked(dbcPlanFromParams, params['user_seats']);
                    } else {
                        return
                    }
                }
            }
            this.eventSourceQueryParams = params['source'] || 'unknown';
        })
    }


    switchMobilePlans(planType: string) {
        switch (planType) {
            case PLAN_TYPES.Solo:
                this.planTypeIndex = 0;
                break;

            case PLAN_TYPES.Team:
                this.planTypeIndex = 1;
                break;

            case PLAN_TYPES.Business:
                this.planTypeIndex = 2;
                break;

            case PLAN_TYPES.Enterprise:
                this.planTypeIndex = 3;
                break;

        }
    }

    sliderColorRange(value) {
        const rangeValue = (value - this.minUsersToShow) / (250 - this.minUsersToShow) * 100;
        if (document.getElementById('user-seats-slider')) {
            document.getElementById('user-seats-slider').style.background = 'linear-gradient(to right, #24A3FF 0%, #24A3FF ' + rangeValue + '%, #E4E5E7  ' + rangeValue + '%, #E4E5E7 100%)';
        }
    }

    handleUserSeatChange(value) {
        this.sliderColorRange(value);
        this.dbcUserSeats = value;
        if (Number(this.dbcUserSeats) < 10) {
            this.selectedDBCPlan = PLAN_TYPES.Solo;
            this.dbcUserSeatsPrice = Number(this.dbcUserSeats) * DBC_PRICE_RATES.Solo;
        } else if (Number(this.dbcUserSeats) >= 10 && Number(this.dbcUserSeats) < 50) {
            this.selectedDBCPlan = PLAN_TYPES.Team;
            this.dbcUserSeatsPrice = Number(this.dbcUserSeats) * DBC_PRICE_RATES.Team;
        } else if (Number(this.dbcUserSeats) >= 50 && Number(this.dbcUserSeats) < 2500 && !this.showBusinessPlusFrame) {
            this.selectedDBCPlan = PLAN_TYPES.Business;
            this.dbcUserSeatsPrice = Number(this.dbcUserSeats) * DBC_PRICE_RATES.Business;
        } else if (Number(this.dbcUserSeats) >= 2500 || this.showBusinessPlusFrame) {
            this.selectedDBCPlan = PLAN_TYPES.Enterprise; ;
        }
        this.handleMaxValueOfUsers(this.dbcUserSeats)
        this.userSeatChangeSubject.next(Number(this.dbcUserSeats));
    }

    handleMinValueOfUserSlider(value) {
        if (value < this.minUsersToShow) {
            this.showSliderErrorMessage = true;
            setTimeout(() => {
                this.dbcUserSeats = String(this.minUsersToShow);
                this.sliderColorRange(this.dbcUserSeats);
                const dbcPrice = this.selectedDBCPlan === PLAN_TYPES.Solo ? DBC_PRICE_RATES.Solo : this.selectedDBCPlan === PLAN_TYPES.Team ? DBC_PRICE_RATES.Team : DBC_PRICE_RATES.Business;
                this.dbcUserSeatsPrice = Number(this.dbcUserSeats) * dbcPrice;
            }, 1000);
            setTimeout(() => {
                this.showSliderErrorMessage = false
            }, 5000)
        }
    }

    handleMaxValueOfUsers(value) {
        if (value >= 100000){
            setTimeout(() => {
                this.dbcUserSeats = 100000;
                const dbcPrice = this.selectedDBCPlan === PLAN_TYPES.Solo ? DBC_PRICE_RATES.Solo : this.selectedDBCPlan === PLAN_TYPES.Team ? DBC_PRICE_RATES.Team : DBC_PRICE_RATES.Business;
                this.dbcUserSeatsPrice = this.dbcUserSeats * dbcPrice;
            }, 1000);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.handleUserSeatChange(this.dbcUserLimit);
        }, 0);
        this.userService.getCards().pipe(takeUntil(this.ngUnsubscribe)).subscribe(cards => {
            this.useExistingCard = true;
            this.cards = cards;
            this.selectedCard = cards.find(c => c.default === true) || cards[0];
        }, err => {
            // handle error
            console.error(err);
        });
        this.userService.getSubscription()
            .pipe().subscribe(subscriptionResult => {
                if (subscriptionResult !== null) {
                    for (const subscription of subscriptionResult) {
                        if (subscription.type === PLAN_TYPE.SUBSCRIPTION) {
                            const subscriptionDetail = subscription;
                            const options = {year: 'numeric', month: 'long', day: 'numeric'} as Intl.DateTimeFormatOptions;
                            this.nextBillingDate = subscriptionDetail.expires.toLocaleDateString('en-US', options)
                            if (subscriptionDetail.interval === 'year') {
                                this.lastBillingDate = moment(subscriptionDetail.expires).subtract(1, 'years').toDate().toLocaleDateString('en-US', options)
                            } else {
                                this.lastBillingDate = moment(subscriptionDetail.expires).subtract(1, 'months').toDate().toLocaleDateString('en-US', options)
                            }
                        }
                    }
                } else {
                    this.messageModal.show('error fetching subscription', 'danger');
                }
            }, error => {
                // TODO error handling
                console.error(error);
            });

    }


    private buildHeaders(argsObject: object): object {
        if (!argsObject) {
            // throw new Error('RequestOptionsArgs not passed to buildHeaders');
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });
        argsObject['headers'] = headers;
        return argsObject;
    }


    getToken() {

        const _this = this;

        const stripe = (<any>window).Stripe(environment.stripeKey);
        const elements = stripe.elements();
        const card = elements.create('card');

        card.mount('#card-element');

        card.addEventListener('change', function (event) {
            _this.useExistingCard = false;
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        const form = document.getElementById('payment-form');
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            _this.overlay.isLoading(true);
            // @ts-ignore
            const name = document.getElementById('name').value;
            stripe.createToken(card, {name: name}).then(function (result) {
                if (result.error) {
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    _this.messageModal.show('Failed to update your card', 'danger');
                    _this.overlay.isLoading(false);
                } else {
                    _this.updateCard(result.token.id);
                }
            });
        });
    }

    selectDBCPlan(dbcPlan: PLAN_TYPES) {
        switch (dbcPlan) {
            case PLAN_TYPES.Solo:
                this.handleUserSeatChange(DBC_MINM_USER_SEATS.Solo);
                break;
            case PLAN_TYPES.Team:
                this.handleUserSeatChange(DBC_MINM_USER_SEATS.Team);
                break;
            case PLAN_TYPES.Business:
                this.showBusinessPlusFrame = false;
                if (Number(this.dbcUserSeats) < CONFIG_VALUES.minLimitCheck || Number(this.dbcUserSeats) >= CONFIG_VALUES.maxLimitCheck) {
                    this.handleUserSeatChange(DBC_MINM_USER_SEATS.Business)
                } else if (Number(this.dbcUserSeats) >= CONFIG_VALUES.minLimitCheck) {
                    this.handleUserSeatChange(this.dbcUserSeats);
                }
                break;
            case PLAN_TYPES.Enterprise:
                this.showBusinessPlusFrame = true;
                if (Number(this.dbcUserSeats) < CONFIG_VALUES.minLimitCheck){
                    this.handleUserSeatChange(DBC_MINM_USER_SEATS.Enterprise)
                } else if (Number(this.dbcUserSeats) >= CONFIG_VALUES.minLimitCheck) {
                    this.handleUserSeatChange(this.dbcUserSeats);
                }
                break;
        }
    }

    switchSection(section: UPGRADE_SECTION): void {
        switch (section) {
            case UPGRADE_SECTION.Plan:
                this.currentSection = UPGRADE_SECTION.Plan;
                this.router.navigate(['/renew-plan'], {relativeTo: this.route, fragment: 'plan'});
                this.hasSelectedPlan = false;
                this.hasSelectedDuration = false;
                if (this.couponCodeModel.couponCode) {
                    this.onCouponRemoved();
                }
                break;
            case UPGRADE_SECTION.Payment:
                if (this.hasSelectedPlan) {

                    this.currentSection = UPGRADE_SECTION.Payment;
                    this.discountApplied = this.couponCodeModel.couponCode.split('_').indexOf(this.planType) !== -1;
                    this.router.navigate(['/renew-plan'], {
                        relativeTo: this.route,
                        fragment: 'payment',
                        queryParamsHandling: 'preserve'
                    });
                }
                break;
        }
    }

    setUpUpgradeModel() {
        switch (this.planType) {
            case PLAN_TYPES.Solo:
                this.upgradeModel.price = this.planYearly ? DBC_PRICE_RATES.Solo * Number(this.dbcUserSeats) * 12 : DBC_PRICE_RATES.Solo * Number(this.dbcUserSeats);
                this.upgradeModel.duration = this.planYearly ? 'year' : 'month';
                this.upgradeModel.name = 'Solo ' + this.upgradeModel.duration + 'ly';
                break;
            case PLAN_TYPES.Team:
                this.upgradeModel.price = this.planYearly ? DBC_PRICE_RATES.Team * Number(this.dbcUserSeats) * 12 : DBC_PRICE_RATES.Team * Number(this.dbcUserSeats);
                this.upgradeModel.duration = this.planYearly ? 'year' : 'month';
                this.upgradeModel.name = 'Team ' + this.upgradeModel.duration + 'ly';
                break;
            case PLAN_TYPES.Business:
                this.upgradeModel.price = this.planYearly ? DBC_PRICE_RATES.Business * Number(this.dbcUserSeats) * 12 : DBC_PRICE_RATES.Business * Number(this.dbcUserSeats);
                this.upgradeModel.duration = this.planYearly ? 'year' : 'month';
                this.upgradeModel.name = 'Business ' + this.upgradeModel.duration + 'ly';
                break;
        }
    }

    setupTotalPrice() {
        switch (this.planType) {
            case PLAN_TYPES.Solo:
                this.totalPrice.yearly = String(DBC_PRICE_RATES.Solo * Number(this.dbcUserSeats) * 12);
                break;
            case PLAN_TYPES.Team:
                this.totalPrice.yearly = String(DBC_PRICE_RATES.Team * Number(this.dbcUserSeats) * 12);
                break;
            case PLAN_TYPES.Business:
                this.totalPrice.yearly = String(DBC_PRICE_RATES.Business * Number(this.dbcUserSeats) * 12);
                break;
        }
    }

    upgradeWithCard() {
        if (!this.selectedCard) {
            this.messageModal.show('Please add a card before proceeding', 'danger');
            return;
        }
        this.userService.markCardAsDefault(this.selectedCard).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
            this.updateSubscription(this.planType);
        }, error => {
            console.error(error);
            this.messageModal.show('An error occurred while change your card', 'danger');
        });
    }

    updateSubscriptionWithCheckout(plan: PLAN_TYPES, userSeats?: number) {
        this.overlay.isLoading(true);
        this.intercom.update({
            upgrade_attempted: true
        });
        this.userService.subscribeWithCheckout(plan, this.planYearly, this.eventSourceQueryParams, null, userSeats).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            window.location.href = res.redirectUrl;
        }, errorResponse => {
            this.overlay.isLoading(false);
            this.authService.addDatadogAction('SubscriptionUpgradeFailed');
            this.messageModal.show('Failed to upgrade. Please contact support@uniqode.com.\n' + errorResponse.error.error.error, 'danger', 10000);
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'purchase plan', {
                plan: User.getReadablePlan(plan),
                success: false,
                source: this.eventSourceQueryParams
            });
        });
    }

    updateSubscription(plan: PLAN_TYPES) {
        this.overlay.isLoading(true);

        this.intercom.update({
            upgrade_attempted: true
        });
        const isTrial = this.userSubscriptionService.subscriptionSubject.getValue()['isStarterTrial'];
        let requiredNoOfDBCUserSeats;
        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            requiredNoOfDBCUserSeats = this.dbcUserSeats;
            switch (plan) {
                case PLAN_TYPES.Solo:
                    requiredNoOfDBCUserSeats = Number(this.dbcUserSeats) - DBC_MINM_USER_SEATS.Solo;
                    break;
                case PLAN_TYPES.Team:
                    requiredNoOfDBCUserSeats = Number(this.dbcUserSeats) - DBC_MINM_USER_SEATS.Team;
                    break;
                case PLAN_TYPES.Business:
                    requiredNoOfDBCUserSeats = Number(this.dbcUserSeats) - DBC_MINM_USER_SEATS.Business;
                    break;
                default:
                    break;
            }
        }

        const couponCode = this.couponCodeModel.success ? this.couponCodeModel.couponCode : null;
        this.userService.updateSubscription(plan, this.planYearly, couponCode, this.eventSourceQueryParams, requiredNoOfDBCUserSeats).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
            this.messageModal.show('Subscription upgraded successfully');
            this.userSubscriptionService.getSubscriptionDetails();

            if (couponCode && /^SUB_BLK_.._2022_..$/gm.test(couponCode)) {
                this.googleAnalyticsService.eventEmitter('BlackFridayUpgrade', 'conversion', 'Black friday upgrade');
            }
            if (isTrial) {
                this.intercom.update({trial_upgrade: true});
                this.googleAnalyticsService.eventEmitter('TrialUpgrade', 'conversion', 'Trial Upgrade');
            }
            this.pollUserUntilPlanChanged(plan);
            this.authService.addDatadogAction('SubscriptionUpgraded');

            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'purchase plan', {
                plan: User.getReadablePlan(plan),
                success: true,
                source: this.eventSourceQueryParams
            });
        }, errorResponse => {
            this.overlay.isLoading(false);
            this.authService.addDatadogAction('SubscriptionUpgradeFailed');
            this.messageModal.show('Failed to upgrade. Please contact support@uniqode.com.\n' + errorResponse.error.error, 'danger', 10000);

            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'purchase plan', {
                plan: User.getReadablePlan(plan),
                success: false,
                source: this.eventSourceQueryParams
            });
        });
    }

    private pollUserUntilPlanChanged(plan: PLAN_TYPES, count = 10) {
        this.userService.getDetail().pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
            if (user.customer_plan !== plan && count > 0) {
                setTimeout(() => {
                    this.pollUserUntilPlanChanged(plan, count - 1);
                }, 500);
                return;
            } else {
                this.overlay.isLoading(false);
                this.routerReloadService.reload();
                const queryParams = {'subscription-upgrade': 'success'};
                const couponCode = this.couponCodeModel.success ? this.couponCodeModel.couponCode : null;
                if (couponCode) {
                    queryParams['coupon'] = couponCode;
                }
                if (this.previousPage) {
                    this.router.navigate([this.previousPage], {queryParams: queryParams});
                } else {
                    this.router.navigate(['/account/billing'], {queryParams: queryParams});
                }
            }
        });
    }

    updateCard(token: string): void {
        const body = {
            stripe_card_token: token,
            default: true
        };
        const url = DbcRenewComponentComponent.stripeCardBaseURL + this.authService.getUser().id + '/cards/';
        this.http.post(url, body, this.buildHeaders({})).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
            })).subscribe(res => {
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'add card');
            this.updateSubscription(this.planType);
        }, error => {
            // TODO: Handle card errors
            this.authService.addDatadogAction('CardUpdateFailed');
            this.messageModal.show('There was an error while updating your card', 'danger');
        })
    }


    setUseExistingCard(card) {
        this.useExistingCard = true;
        this.selectedCard = card;
    }


    onDBCPlanTypeClicked(plan: PLAN_TYPES, userSeats?: number) {
        this.dbcUserSeats = userSeats ? String(userSeats) : this.dbcUserSeats;
        if (!userSeats) {
            switch (plan) {
                case PLAN_TYPES.Solo:
                    this.dbcUserSeats = String(Number(this.dbcUserSeats) < DBC_MINM_USER_SEATS.Solo ? DBC_MINM_USER_SEATS.Solo : this.dbcUserSeats);
                    break;
                case PLAN_TYPES.Team:
                    this.dbcUserSeats = String(Number(this.dbcUserSeats) < DBC_MINM_USER_SEATS.Team ? DBC_MINM_USER_SEATS.Team : this.dbcUserSeats);
                    break;
                case PLAN_TYPES.Business:
                    this.dbcUserSeats = String(Number(this.dbcUserSeats) < DBC_MINM_USER_SEATS.Business ? DBC_MINM_USER_SEATS.Business : this.dbcUserSeats);
                    break;
            }
        }
        if (Number(this.dbcUserSeats) < this.currentActiveDBCUsers) {
            return;
        }
        this.planType = plan;
        this.monthlyPrice = String(this.planType === PLAN_TYPES.Solo ? DBC_PRICE_RATES.Solo * Number(this.dbcUserSeats) : this.planType === PLAN_TYPES.Team ? DBC_PRICE_RATES.Team * Number(this.dbcUserSeats) : this.planType === PLAN_TYPES.Business ? DBC_PRICE_RATES.Business * Number(this.dbcUserSeats) : '');
        this.yearlyPrice = this.monthlyPrice;
        this.setupTotalPrice();
        this.hasSelectedPlan = true;

        if (!getCookie('source')){
            const cookie = `source=${this.eventSourceQueryParams}; expires=${moment().add(1, 'day')}`;
            document.cookie = cookie;
        }
        let requiredNoOfDBCUserSeats: number;
        this.eventSourceQueryParams = getCookie('source');
        if (!userSeats) {
            switch (plan) {
                case PLAN_TYPES.Solo:
                    requiredNoOfDBCUserSeats = Number(this.dbcUserSeats) - DBC_MINM_USER_SEATS.Solo
                    break;
                case PLAN_TYPES.Team:
                    requiredNoOfDBCUserSeats = Number(this.dbcUserSeats) - DBC_MINM_USER_SEATS.Team
                    break;
                case PLAN_TYPES.Business:
                    requiredNoOfDBCUserSeats = Number(this.dbcUserSeats) - DBC_MINM_USER_SEATS.Business
                    break;
                default:
                    break;
            }
        } else {
            requiredNoOfDBCUserSeats = Number(this.dbcUserSeats);
        }
        return this.updateSubscriptionWithCheckout(plan, requiredNoOfDBCUserSeats)

    }

    onPlanTypeClicked(plan: PLAN_TYPES) {
        this.planType = plan;
        this.setupTotalPrice();
        this.hasSelectedPlan = true;

        if (this.user.isOnTrialPlan(PRODUCT_TYPES.DBC)) {
            if (!getCookie('source')){
                const cookie = `source=${this.eventSourceQueryParams}; expires=${moment().add(1, 'day')}`;
                document.cookie = cookie;
            }

            this.eventSourceQueryParams = getCookie('source');
            return this.updateSubscriptionWithCheckout(plan)
        }

        this.switchSection(UPGRADE_SECTION.Payment);

        this.fetchUpcomingPrice();

        // Black Friday
        if (!this.user.isOnTrialPlan(PRODUCT_TYPES.DBC) && this.showBlackFridayPrice) {
            this.couponCodeModel.couponCode = BLACK_FRIDAY_COUNPONS[plan];
            this.onCouponApplied();
        }

        this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Subscription, 'select plan', {
            plan: User.getReadablePlan(plan),
            source: this.eventSourceQueryParams
        });
    }

    onEnterpriseSelected() {
        window.open('https://www.uniqode.com/schedule-demo?utm_source=Dashboard&utm_medium=Enterprise&utm_campaign=Talktous', '_blank');
    }

    upgrade() {
        if (this.useExistingCard) {
            this.upgradeWithCard();
        } else {
            document.getElementById('upgrade-submit-button').click();
        }
    }

    onCouponApplied() {
        this.couponCodeModel.success = false;
        this.couponCodeModel.error = false;
        this.couponCodeModel.loading = true;
        this.couponCodeModel.coupon = null;
        this.userService.verifyPromoCode(this.couponCodeModel.couponCode).pipe(
            takeUntil(this.ngUnsubscribe),
            finalize(() => {
                this.couponCodeModel.loading = false;
                this.fetchUpcomingPrice();
            })
        ).subscribe(coupon => {
            this.couponCodeModel.error = false;
            this.couponCodeModel.success = true;
            this.couponCodeModel.coupon = coupon;
            if (this.couponCodeModel.success) {
                this.discountApplied = true;
                this.couponDiscountAmount = coupon['amount_off'];
                this.couponDiscountPercentage = coupon['percent_off'];
            }
            this.messageModal.show(`Coupon code ${this.couponCodeModel.couponCode} applied successfully`);
        }, error => {
            this.messageModal.show('Invalid coupon code', 'danger')
            this.couponCodeModel.error = true;
            this.couponCodeModel.success = false;
        })
    }

    onCouponRemoved() {
        this.couponCodeModel.success = false;
        this.couponCodeModel.error = false;
        this.couponCodeModel.loading = false;
        this.couponCodeModel.couponCode = '';
        this.couponCodeModel.coupon = null;
        this.discountApplied = false;
        this.couponDiscountAmount = 0;
        this.couponDiscountPercentage = 0;

        this.fetchUpcomingPrice();
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    redirectToStore() {
        window.open('https://www.uniqode.com/digital-business-card/solution', '_blank');
    }

    redirectToPreviousPage() {
        this.router.navigateByUrl(this.previousPage);
    }

    fetchUpcomingPrice() {
        let requiredNoOfDBCUserSeats = Number(this.dbcUserSeats);
        if (this.selectedDBCPlan) {
            switch (this.selectedDBCPlan) {
                case PLAN_TYPES.Solo:
                    requiredNoOfDBCUserSeats = requiredNoOfDBCUserSeats - DBC_MINM_USER_SEATS.Solo;
                    break;
                case PLAN_TYPES.Team:
                    requiredNoOfDBCUserSeats = requiredNoOfDBCUserSeats - DBC_MINM_USER_SEATS.Team;
                    break;
                case PLAN_TYPES.Business:
                    requiredNoOfDBCUserSeats = requiredNoOfDBCUserSeats - DBC_MINM_USER_SEATS.Business;
                    break;
            }
        }

        const data = {
            plan: this.planType,
            yearly: this.planYearly,
            coupon: this.couponCodeModel.couponCode && this.couponCodeModel.success ? this.couponCodeModel.couponCode : '',
        };

        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            data['additionalUserSeats'] = Number(requiredNoOfDBCUserSeats);
        }

        if (this.priceSubscription){
            this.priceSubscription.unsubscribe();
        }

        this.priceSubscription = this.userService.getUpcomingInvoicePrice(data).subscribe(res => {
            this.planPrice = res.upcomingPrice
        })
    }

    IsOnHigherDBCPlanCheck(user: User, plan: PLAN_TYPES, planId: string, planYearly: boolean): any {
        const yearly = planId.indexOf('YEAR') !== -1;
        const isOnHigherDBCPlan = user.isOnHigherDBCPlan(plan) || !user.isOnDBCPlan();
        if (yearly) {
            return isOnHigherDBCPlan;
        } else {
            if (isOnHigherDBCPlan && (user.customer_plan === plan)) {
                return isOnHigherDBCPlan && !planYearly && planId.indexOf('TRIAL') === -1;
            } else {
                return isOnHigherDBCPlan && planId.indexOf('TRIAL') === -1;
            }
        }
    }

    handleDbcPlansToShow(currentActiveUsers) {
        if (currentActiveUsers > USER_SEATS_ADD_LIMIT.SOLO) {
            this.showDbcPlans.Solo = false;
        }
        if (currentActiveUsers > USER_SEATS_ADD_LIMIT.TEAM) {
            this.showDbcPlans.Team = false;
        }
        if (currentActiveUsers > USER_SEATS_ADD_LIMIT.BUSINESS) {
            this.showDbcPlans.Business = false;
        }
    }

    incrementCounter() {
        this.dbcUserSeats = Number(this.dbcUserSeats) + 1;
        this.handleUserSeatChange(this.dbcUserSeats);

        if (this.dbcUserSeats >= CONFIG_VALUES.maxLimitCheck){
            this.showBusinessPlusFrame = true;
        }
    }

    decrementCounter() {
        if (Number(this.dbcUserSeats) > this.minUsersToShow){
            if (Number(this.dbcUserSeats) > 0) {
                this.dbcUserSeats = Number(this.dbcUserSeats) - 1;
                this.handleUserSeatChange(this.dbcUserSeats);
            }

            if (Number(this.dbcUserSeats) < CONFIG_VALUES.minLimitCheck){
                this.showBusinessPlusFrame = false;
            }
        }
    }

    dbcplan(){
        if (this.selectedDBCPlan === PLAN_TYPES.Solo || (!this.dbcTrialPlan && this.user.subscription.dbc.plan === PLAN_TYPES.Solo)){
            this.onDBCPlanTypeClicked(PLAN_TYPES.Solo);
        } else if (this.selectedDBCPlan === PLAN_TYPES.Team || (!this.dbcTrialPlan && this.user.subscription.dbc.plan === PLAN_TYPES.Team)){
            this.onDBCPlanTypeClicked(PLAN_TYPES.Team);
        } else if (this.selectedDBCPlan === PLAN_TYPES.Business || (!this.dbcTrialPlan && this.user.subscription.dbc.plan === PLAN_TYPES.Business)){
            this.onDBCPlanTypeClicked(PLAN_TYPES.Business);
        }
    }

    togglePreview(previewBool: boolean) {
        /* If either of them is false then return false */
        if ((previewBool && !this.showBusinessPlusFrame) || (!previewBool && this.showBusinessPlusFrame)) {
            return;
        }
        if (previewBool) {
            this.selectedDBCPlan = PLAN_TYPES.Business;
            this.showBusinessPlusFrame = false;
            this.handleUserSeatChange(this.dbcUserSeats);
        } else {
            this.selectedDBCPlan = PLAN_TYPES.Enterprise;
            this.showBusinessPlusFrame = true;
            this.handleUserSeatChange(this.dbcUserSeats);
        }
    }

    startIncrementLongPress(event: any) {
        this.pressStartTime = Date.now();
        if (Number(this.dbcUserSeats) >= CONFIG_VALUES.maxLimitCheck){
            this.showBusinessPlusFrame = true;
        }
        this.incrementPressTimer = setTimeout(() => {
            this.incrementCounter();
            this.startIncrementLongPressInterval();
        }, this.longPressThreshold);
    }

    // Method to start long press for decrementCounter
    startDecrementLongPress(event: any) {
        this.pressStartTime = Date.now();
        this.decrementPressTimer = setTimeout(() => {
            this.decrementCounter();
            this.startDecrementLongPressInterval();
        }, this.longPressThreshold);
    }

    // Method to start interval for continuous incrementCounter
    startIncrementLongPressInterval() {
        this.incrementPressTimer = setInterval(() => {
            this.incrementCounter();
        }, this.longPressInterval);
    }

    // Method to start interval for continuous decrementCounter
    startDecrementLongPressInterval() {
        this.decrementPressTimer = setInterval(() => {
            this.decrementCounter();
        }, this.longPressInterval);
    }

    // Method to clear long press timer for incrementCounter
    endIncrementLongPress() {
        clearTimeout(this.incrementPressTimer);
        clearInterval(this.incrementPressTimer);
    }

    // Method to clear long press timer for decrementCounter
    endDecrementLongPress() {
        clearTimeout(this.decrementPressTimer);
        clearInterval(this.decrementPressTimer);
    }
}
