import {Component, OnInit, HostListener} from '@angular/core';
import {DBCCardTypes, Utils} from '../../shared/utils';
import {OverlayService} from '../../global-services/overlay.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {DigitalBusinessCardSettingService, DigitalBusinessCardTemplateService} from '../digital-business-cards.service';
import {debounceTime, finalize, tap} from 'rxjs/operators';
import {DigitalBusinessCard, DigitalBusinessCardTemplateModel} from '../digital-business-card.model';
import {forkJoin, Subject} from 'rxjs';
import {AuthService} from '../../global-services/auth.service';
import {AMPLITUDE_EVENT_CATEGORIES, AMPLITUDE_EVENTS, AmplitudeService} from '../../global-services/amplitude.service';
import {
    DBC_DESIGN_TYPE
} from '../digital-business-card-global-components/dbc-designs-grid-view/dbc-designs-grid-view.component';
import {CardsOrganizationRestrictedFields} from '../../user-account/user-organization.model';
import { FEATURES } from 'app/user-account/organization-permission-model';
import { UpsellService } from 'app/global-services/upsell.service';
@Component({
    selector: 'app-digital-business-card-design-library',
    templateUrl: './digital-business-card-design-library.component.html',
    styleUrls: ['./digital-business-card-design-library.component.scss']
})
export class DigitalBusinessCardDesignLibraryComponent implements OnInit {

    layoutData = Utils.DBC_LAYOUTS
    previewImageType: string = null;
    cardType: DBCCardTypes;
    bulk: boolean;
    orgCardSettings: CardsOrganizationRestrictedFields;
    digitalBusinessCardTemplates: Array<DigitalBusinessCardTemplateModel> = null;
    designType = DBC_DESIGN_TYPE.TEMPLATES
    currentPage: number  = 1;
    totalResults: number = null;
    hasCardTemplateAccess: boolean = false;
    trialSignUp = false
    isMobileScreen: boolean = false;
    $resize: Subject<any> = new Subject<void>();
    hasDBCAdvancedCustomization: boolean = false;
    private pageChangeSubject: Subject<any> = new Subject<{page: 1, pageSize: 11}>();
    protected readonly DBC_DESIGN_TYPE = DBC_DESIGN_TYPE;

    constructor(private overlayService: OverlayService, private router: Router, private route: ActivatedRoute,
        private authService: AuthService,
        private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private amplitude: AmplitudeService,
        private upsellService: UpsellService,
        private orgCardsSettingService: DigitalBusinessCardSettingService) {
        this.hasCardTemplateAccess = this.authService.getUser().organization.dbc_feature_permissions.card_template;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setDeviseType();
    }

    ngOnInit(): void {
        this.hasDBCAdvancedCustomization = this.authService.getUser().hasDbcPermissionFor(FEATURES.advanced_customization_options);
        this.route.data.subscribe(res => {
            if (this.router.url.indexOf('my-cards') > -1) {
                this.cardType = DBCCardTypes.MY_CARDS
            } else if (this.router.url.indexOf('team-cards') > -1) {
                this.cardType = DBCCardTypes.TEAM_CARDS
            } else {
                this.cardType = DBCCardTypes.TEMPLATE_CARDS
            }

            this.orgCardSettings = new CardsOrganizationRestrictedFields();

            const dbcSettingsRequest = this.orgCardsSettingService.getDetail(this.authService.getCurrentOrgId()).pipe(tap(settings => {
                this.orgCardSettings = new CardsOrganizationRestrictedFields(settings);
            }));

            const templatesRequest = this.digitalBusinessCardTemplateService.getList(1, 11).pipe(
                tap(templates => {
                    this.digitalBusinessCardTemplates = templates.objects;
                    this.setupPreview();
                    this.totalResults = templates.totalCount;
                })
            );

            const allRequests: Array<any> = [dbcSettingsRequest];

            if (this.hasCardTemplateAccess) {
                allRequests.push(templatesRequest);
            }

            forkJoin(allRequests).pipe(finalize(() => {
                this.overlayService.isLoading(false)
            })).subscribe();

        })

        this.route.queryParamMap.subscribe((map: ParamMap) => {
            this.bulk = map['params']['destination'] === 'bulk-upload';
            if (this.bulk) {
                this.cardType = DBCCardTypes.BULK_CARDS;
            }

            if (map.keys.length > 0) {
                this.trialSignUp = map.get('TrialSignUp') === 'true';

                if (this.trialSignUp) {
                    this.designType = DBC_DESIGN_TYPE.LAYOUTS;
                } else {
                    this.designType = DBC_DESIGN_TYPE.TEMPLATES;
                }
            }
        });

        this.pageChangeSubject.pipe(debounceTime(200)).subscribe(event => {
            this.overlayService.isLoading(true);
            this.currentPage = event.page;
            this.fetchCardTemplates(this.currentPage, event.pageSize);
        })

        if ( !this.hasCardTemplateAccess || this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
            this.showLayoutsSection()
        }

        this.setDeviseType()
        this.$resize.pipe(debounceTime(300)).subscribe(() => {
            this.setDeviseType()
        })

    }

    fetchCardTemplates(page: number, pageSize: number) {
        this.digitalBusinessCardTemplateService.getList(page, pageSize).pipe(
            tap(templates => {
                this.digitalBusinessCardTemplates = templates.objects
                this.setupPreview();
                this.totalResults = templates.totalCount;
                this.overlayService.isLoading(false)
            })
        ).subscribe();
    }

    getCurrentCardRoute() {
        switch (this.cardType) {
            case DBCCardTypes.MY_CARDS:
                return 'my-cards';
            case DBCCardTypes.TEAM_CARDS:
                return 'team-cards';
            case DBCCardTypes.TEMPLATE_CARDS:
                return 'templates';
        }
    }

    selectLayout(layout) {
        const layoutNumber = Number(layout.name);
        if (!this.hasDBCAdvancedCustomization && Utils.PREMIUM_LAYOUTS_LIST.includes(layoutNumber))  {
            const upsellFeature = { feature: 'advanced_customizations_new_card_designs', source: 'advanced_customization_upsell' };
            const user = this.authService.getUser();
            const isOnTrial = user.isOnSoloTrial() || user.isOnTeamTrial();

            if ( isOnTrial ) {
                this.upsellService.show('Upgrade to a Business trial for free!', 'Your chosen premium card design is available from the Business plan. Upgrade your trial to use this design and enjoy more features!', null, 'Try Business Plan', null, null, null, null, null, 'BN', false, null)
                return;
            } else {
                this.upsellService.eventSource.next('premium_layouts_upsell');
            }
            if (!this.isMobileScreen) {
                this.upsellService.showV2(upsellFeature);
            } else {
                this.upsellService.showUpsellModalOnMobile(upsellFeature);
            }
            return;
        }
        if (this.bulk) {
            this.selectTemplate(new DigitalBusinessCardTemplateModel(layout));
        } else {
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.SELECT_NEW_CARD_TEMPLATE, {
                type: 'beaconstac'
            });
            const cardRoute = this.getCurrentCardRoute();

            this.router.navigate(['/digital-business-cards/' + cardRoute + '/add'],
                {relativeTo: this.route, queryParams: {layout: layout.name}, queryParamsHandling: 'merge'})
        }
    }

    setupPreview() {
        for (const template of this.digitalBusinessCardTemplates) {
            const iframe = document.getElementById(`preview-template-frame-${template.id}`);
            if (!iframe) {
                setTimeout(() => {
                    this.setupPreview();
                }, 100);
                return;
            }
            // @ts-ignore
            const iframedoc = iframe.contentWindow || iframe.contentDocument.document

            iframedoc.document.open();

            iframedoc.document.write(DigitalBusinessCard.getVcardPreviewHTML(template, false));
            iframedoc.document.close();
        }
    }

    selectTemplate(template: DigitalBusinessCardTemplateModel) {
        const templateId: number = template.id;
        this.digitalBusinessCardTemplateService.setDBCTemplate(template);
        if (this.bulk) {
            this.router.navigate(['/digital-business-cards/bulk-upload'],
                {relativeTo: this.route, queryParams: {layout: template.layout, template: templateId}});
        } else {
            this.amplitude.logEvent(AMPLITUDE_EVENT_CATEGORIES.Usage, AMPLITUDE_EVENTS.SELECT_NEW_CARD_TEMPLATE, {
                type: 'organization'
            });
            const cardRoute = this.getCurrentCardRoute();
            this.router.navigate(['/digital-business-cards/' + cardRoute + '/add'],
                {relativeTo: this.route, queryParams: {layout: template.layout, template: templateId},  queryParamsHandling: 'merge'});
        }
    }

    selectDesign(event, designType: DBC_DESIGN_TYPE) {
        if (!event) {
            this.showLayoutsSection();
        }

        // Design can be of type template || layout
        switch (designType) {
            case DBC_DESIGN_TYPE.TEMPLATES:
                this.selectTemplate(event);
                break;
            case DBC_DESIGN_TYPE.LAYOUTS:
                this.selectLayout(event);
                break;
        }
    }

    showLayoutsSection() {
        this.designType = DBC_DESIGN_TYPE.LAYOUTS;
    }

    onPageChange(event) {
        this.pageChangeSubject.next({
            page: event,
            pageSize: 11
        })
    }

    setDeviseType(): void {
        this.isMobileScreen = (window.innerWidth <= 700);
    }
}

