export class DbcAnalyticsUtils {
    public static TableColumns = {
        CITY: 'CITY',
        STATE: 'STATE/PROVINCE',
        COUNTRY: 'COUNTRY',
        VIEWS: 'CARD VIEWS',
        DOWNLOADS: 'CONTACT SAVED',
        PERCENTAGE: 'PERCENTAGE'
    }

    public static dayOption: any = [
        { id: 0, name: 'Today' },
        { id: 1, name: 'Yesterday' },
        { id: 7, name: 'Last 7 days' },
        { id: 30, name: 'Last 30 days' },
        { id: 90, name: 'Last 90 days' },
        { id: -1, name: 'Custom' }
    ];

    public static cardSeggregationsOptions = [
        { id: '0', name: 'My Cards' },
        { id: '1', name: 'Organization Cards' }
    ];

    public static cityDataTableHeaders = [
        { text: 'CITY', sort: true, mobile: true },
        { text: 'STATE/PROVINCE', sort: true },
        { text: 'COUNTRY', sort: true },
        { text: 'CARD VIEWS', sort: true },
        { text: 'CONTACT SAVED', sort: true },
        { text: 'PERCENTAGE', sort: true },
    ];

    public static lineChartOptions = {
        xAxis: true,
        yAxis: true,
        view: [900, 300],
        animations: true,
        timeline: true,
        colorScheme: {
            domain: ['#2595FF', '#26B969']
        },
        barChartColorScheme: {
            domain: ['#2595FF']
        }
    };
    public static colorScheme = {
        domain: ['#DDEFFF', '#A8D5FF', '#7CBFFF', '#51AAFF', '#2595FF'].reverse(),
    };

    public static deviceChartOptions = {
        xAxis: false,
        yAxis: true,
        view: [400, 300],
        mobileView: [200, 100],
        gradient: false,
        showXAxisLabel: true,
        barPadding: 14,
        colorScheme: {
            domain: ['#DDEFFF', '#A8D5FF', '#7CBFFF', '#51AAFF', '#2595FF'].reverse(),
        }
    };

    public static userTimeOfWeekChartOptions = {
        xAxis: false,
        yAxis: true,
        view: [700, 450],
        legend: true,
        showLabels: true,
        animations: true,
        colorScheme: {
            domain: ['#DDEFFF', '#A8D5FF', '#7CBFFF', '#51AAFF', '#2595FF'],
        },
    };

    public static agmClusterStyleGPS: Array<any> = [
        {
            url: '../../../assets/img/map-marker/m1.png',
            width: 53,
            height: 53,
            fontFamily: 'Arial',
            textSize: 15,
            textColor: '#fff',
            fontWeight: 'bold',
            anchorText: [18, 0]
        }];

    public static agmClusterStyleIP: Array<any> = [
        {
            url: '../../../assets/img/map-marker/m2.png',
            width: 53,
            height: 53,
            fontFamily: 'Arial',
            textSize: 15,
            textColor: '#fff',
            fontWeight: 'bold',
            anchorText: [18, 0]
        }];

    public static defaultMobileIcons = [{
        class: 'fas fa-info-circle',
        color: '#5E6980',
        tooltip: 'Info',
        event: {
            type: 'link',
            operation: 'modal',
            output: 'function()'
        }
    }];
}
