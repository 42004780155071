<div class="mt-2 mb-3" [@collapse]>
    <div class="mb-2 py-1">
        <span style="color: red;">We found {{errorObject.error_count}} errors in the uploaded sheet. </span>
        <br>
        <span>You can use edit option to update correct values or consider uploading a corrected sheet.</span>
    </div>
    <div class="mt-2 mb-3">
        <table class="table table-bordered csv-editor-table"
               [ngClass]="errorObject.qr_data_type === 5 || errorObject.qr_data_type === 11 || errorObject.qr_data_type === 12 ? '' : 'csv-editor-fit-content'">
            <thead>
            <tr>
                <th *ngFor="let header of headers" scope="col">{{header}}</th>
            </tr>
            </thead>
            <tbody style="max-height: 500px; display: block;">
            <tr *ngFor="let data of csvErrorData">
                <td *ngFor="let key of headers; let column = index">
                  <span
                      *ngIf="({'row': data['row'] + 2, 'column': column + 1, 'errors': errors, 'error_rows': error_rows} | isErrorCSVField); else validData">
                    <span *ngIf="!(editField.row === data['row'] && editField.column === column); else editCSV"
                          data-toggle="tooltip" data-placement="right" container="body"
                          [title]="getErrorMessage(data['row'] + 2, column + 1)"
                          id="csv-field-{{data['row']}}-{{column}}" style="color: red">
                      {{getErrorFieldData(data['row'] + 2, column + 1, data[key])}}
                        <i class="fas fa-pencil" aria-hidden="true" style="cursor:pointer; margin-left: 1px"
                           (click)="setEditField(data['row'], column)"></i>
                    </span>
                    <ng-template #editCSV>
                      <input id="csv-{{data['row']}}-{{column}}"
                             [(ngModel)]="csvData[data['row']][key]" class="form-control csv-error-field py-1"
                             type="text" (input)="onFieldUpdated($event.target.value, data['row'], key)" (focusout)="resetEditField()">
                    </ng-template>
                  </span>
                    <ng-template #validData>
                      <span data-toggle="tooltip" data-placement="right" container="body"
                            [title]="data[key]">{{data[key].length <= 25 ? data[key] : data[key].slice(0, 25) + '...'}}
                      </span>
                    </ng-template>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
