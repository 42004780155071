import {Component, EventEmitter, Output, Input, AfterViewInit, OnInit} from '@angular/core';
import {AgreementLinksModal} from './terms-service.modal';
import {
    CARD_PROPERTY_TYPES, DigitalBusinessCardSetting
} from '../../digital-business-card/digital-business-card-setting/digital-business-card-setting.model';
import {BUTTON_STYLES, TEXT_FIELD_VALIDATIONS} from 'kaizen-design-system';
import {Animations} from '../../shared/beaconstac-animations';
import {DBCCardTypes, TERMS_OF_SERVICE_TEXT, Utils} from '../../shared/utils';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CardsOrganizationRestrictedFields} from '../../user-account/user-organization.model';

@Component({
    selector: 'app-terms-service',
    templateUrl: './terms-service.component.html',
    styleUrls: ['./terms-service.component.scss'],
    animations: [Animations.collapse]
})

export class TermsServiceComponent implements AfterViewInit, OnInit {

    @Input()
        termsOfService: Array<AgreementLinksModal>
    @Input()
        orgCardSetting: CardsOrganizationRestrictedFields;
    @Input()
        cardSetting: DigitalBusinessCardSetting;
    @Input()
        hasWriteAccess: boolean = false;
    @Input()
        cardType: DBCCardTypes;

    @Input()
        disclaimerText: string = TERMS_OF_SERVICE_TEXT;

    @Output()
        termsOfServiceChange: EventEmitter<Array<AgreementLinksModal>> = new EventEmitter();

    textSubjectChange: Subject<string> = new Subject();
    priortizeOrgSettings = true
    constructor() {
    }

    ngOnInit() {
        this.textSubjectChange.pipe(debounceTime(500)).subscribe(res => {
            this.termsOfServiceChange.emit(this.termsOfService);
        })
    }

    ngAfterViewInit() {
        if (this.termsOfService) {
            this.termsOfService.map(tos => {
                if (!tos?.__valid_url__) {
                    tos['__valid_url__'] = true;
                }
            })
        }
    }

    inputChange(type, event, index) {
        if (type === 'url') {
            this.termsOfService[index].__valid_url__ = Utils.urlRegex.test(event);
        }
        if (!event) {
            this.termsOfService[index].__valid_url__ = true;
        }
        this.termsOfService[index][type] = event;
        this.textSubjectChange.next(event);
    }

    removeField(type, index) {
        this.termsOfService.splice(index, 1);
        this.termsOfServiceChange.emit(this.termsOfService);
    }

    addNewField() {
        this.termsOfService.push(new AgreementLinksModal());
        this.termsOfServiceChange.emit(this.termsOfService);
    }

    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;
    protected readonly TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
}
