import {Component, OnInit} from '@angular/core';
import {BUTTON_STYLES, BUTTON_TYPES, MESSAGE_TYPES} from 'kaizen-design-system';
import {OverlayService} from '../global-services/overlay.service';
import {UserService} from '../user-account/user.service';
import {AuthService} from '../global-services/auth.service';
import {User} from '../user-account/user.model';
import {UpsellService} from '../global-services/upsell.service';
import {FEATURES} from '../user-account/organization-permission-model';
import {MessageModalService} from '../shared/message-modal/message-modal.service';
import {Router} from '@angular/router';
import {DASHBOARD_MENU, PRODUCT_TYPES} from '../shared/utils';
import {DashboardService} from '../global-services/dashboard.service';

export enum COPY_TEXT_TYPES {
    TOKEN_KEY,
    ORGANIZATION_ID,
    SCIM_URL,
    SALESFORCE_CALLBACK_URL
}

@Component({
    selector: 'app-api',
    templateUrl: './api.component.html',
    styleUrls: ['./api.component.scss']
})
export class ApiComponent implements OnInit {

    BUTTON_TYPES = BUTTON_TYPES
    BUTTON_STYLES = BUTTON_STYLES
    MESSAGE_TYPES = MESSAGE_TYPES
    FEATURES = FEATURES
    COPY_TEXT_TYPES = COPY_TEXT_TYPES
    user: User;
    hasApiAccess: boolean;
    isOnEnterprisePlan: boolean;
    authToken: string;
    currentOrganizationId: number;
    isOnTrialPlan: boolean;
    currentDashboard = DASHBOARD_MENU.QRCODES;
    trialPlanMessage: string;
    pageTitle: string = 'API';
    pageSubtitle: string;
    constructor(private overlay: OverlayService, private userService: UserService,
        private authService: AuthService, private upsellService: UpsellService,
        private messageModal: MessageModalService, private router: Router, private dashboardService: DashboardService) { }

    ngOnInit(): void {
        this.overlay.isLoading(false)
        this.user = this.authService.getUser()
        this.userService.getToken().subscribe(tokenResponse => {
            this.authToken = tokenResponse['token'];
        });
        this.authService.currentOrgId$.subscribe((orgID) => {
            this.currentOrganizationId = orgID;
        });
        this.dashboardService.currentDashboard$.subscribe(res => {
            if (this.currentDashboard === res) {
                return;
            }
            this.currentDashboard = res;
        });
        if (this.currentDashboard === DASHBOARD_MENU.QRCODES) {
            this.isOnTrialPlan = this.authService.getUser().isOnTrialPlan(PRODUCT_TYPES.QR);
            this.hasApiAccess = this.isOnTrialPlan || this.upsellService.isSupportedOnPlan(FEATURES.api)
            this.pageSubtitle = 'Our QR Code REST API lets you integrate your app and create QR Codes campaigns as needed';
            this.trialPlanMessage = 'This is a Pro feature. Access to this feature will be disabled after your trial period is over.';
            this.isOnEnterprisePlan = this.user.isOnEnterprisePlan();
        } else if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            this.isOnTrialPlan = this.authService.getUser().isOnTrialPlan(PRODUCT_TYPES.DBC);
            this.hasApiAccess = this.isOnTrialPlan || this.upsellService.isSupportedOnDBCPlan(FEATURES.api_dbc)
            this.pageSubtitle = 'Our REST API for Cards lets you integrate your app and create digital business cards as needed';
            this.trialPlanMessage = 'This feature is available on Team plan and above. Access to this feature will be disabled after your trial period is over.';
            this.isOnEnterprisePlan = this.user.isOnDBCEnterprisePlan();
        }
    }

    copyText(type) {
        const textArea = document.createElement('textarea');
        textArea.style.display = 'hidden';
        if (type === COPY_TEXT_TYPES.ORGANIZATION_ID) {
            textArea.value = this.currentOrganizationId + '';
        } else if (type === COPY_TEXT_TYPES.TOKEN_KEY) {
            textArea.value = this.authToken;
        }
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('Copy');
        textArea.remove();
        this.messageModal.show('Copied to clipboard', 'success');
    }

    redirectToAPIDocs() {
        if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            window.open('https://apidocs.beaconstac.com/#d631da62-b420-41d9-b0f6-d094455991ef', '_blank');
        } else {
            window.open('https://apidocs.beaconstac.com/', '_blank');
        }
    }

    routeToChangePlan() {
        if (this.user.isOwner()) {
            this.router.navigate(['/account/upgrade/'], { queryParams: { source: 'api-key-upsell', orgID: this.currentOrganizationId}});
        } else {
            this.messageModal.show('This is a restricted feature, please contact your account owner.', 'warning');
        }
    }

    openUpsellModal(feature) {
        if (this.currentDashboard === DASHBOARD_MENU.QRCODES) {
            this.upsellService.show('This feature is available on Pro!', 'Integrate our QR Code Generator API to your application and create QR Codes at scale', null, 'Upgrade Plan', 'api-key-upsell')
        } else if (this.currentDashboard === DASHBOARD_MENU.CARDS) {
            this.upsellService.show('This feature is available on Team', 'API integration is available for Team plan and above. Upgrade to integrate our Cards API and seamlessly create digital business cards at scale.', null, 'Upgrade Plan', 'api-key-upsell')
        }
    }

}
