import { Injectable } from '@angular/core';
import { MyOrganizationsService } from 'app/user-account/my-organizations.service';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { ReducedOrganization } from '../user-account/user-organization.model';


export interface ListResult<ReducedOrganization> {
    objects: Array<ReducedOrganization>;
    pageCount: number;
    totalCount: number;
    next: any;
    previous: any;
}

@Injectable()
export class FetchOrganizationsService {
    filteredOrgList: Array<ReducedOrganization>;

    constructor(private myOrganizationsService: MyOrganizationsService) { }
    fetchOrganizations(value = '', pageNumber = 1): Observable<ListResult<ReducedOrganization>> {
        this.filteredOrgList = [];
        const searchParams = {'name__icontains': value.toLowerCase()}
        return this.myOrganizationsService.
            getList(pageNumber, 20, searchParams, false, 'name').pipe(map((response) => {
                const jsonResponse = response;

                for (const org of jsonResponse.objects) {
                    this.filteredOrgList.push({
                        id: org.id,
                        name: org.name.length <= 18 ? org.name : org.name.substr(0, 18) + '...',
                        master: !org.parent,
                        beacon_count: org.beacon_count,
                    })
                }

                return {
                    objects: this.filteredOrgList,
                    pageCount: jsonResponse['pageCount'],
                    totalCount: jsonResponse['totalCount'],
                    next: jsonResponse['next'],
                    previous: jsonResponse['previous']
                }
            }), catchError((error: any) => {
                return throwError(() => error);
            }));
    }
}
