import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../global-services/auth.service';

@Injectable()
export class BeaconNFCGuard implements CanActivate {

    constructor(private authService: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const user = this.authService.getUser();
        return user && !user.organization.hasOnlyQrAccess;
    }
}
