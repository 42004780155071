import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProductTransfer {

    ngUnsubscribe: Subject<any> = new Subject();

    constructor() { }

    transferProduct(service, forceMigrate: boolean, product, selectedOrg: number) {
        let migrate = '';
        if (forceMigrate) {
            migrate = '?force_migrate=true';
        }
        return service.postToId({ 'organization': selectedOrg }, product.id + '/transfer/' + migrate)
    }

}
