<div bsModal #upsellModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
  <div class="modal-dialog upsell-modal-parent" role="document">
    <div class="modal-content">
      <div class="p-0 modal-body" *ngIf="service.imageShow$ | async">
        <img [src]="BASE_IMAGE_URL + imageEndpoint">
      </div>
      <div class="p-4 modal-body">

        <h4 class="upsell-heading" [innerHTML]="service.title$ | async">
        </h4>

        <div class="mt-4 upsell-description" [innerHTML]="service.message$ | async">
        </div>

        <div style="padding-top: 32px;">
          <span *ngIf="service.redirectLink$ | async" class="float-left mt-3 d-flex align-items-center">
            <beaconstac-button [type]="BUTTON_TYPES.STROKE" [style]="BUTTON_STYLES.TEXT" (onClick)="redirectTo()" [label]="'Learn more'"></beaconstac-button>
          </span>
          <span style="float: right; display: flex; flex-direction: row">
            <span>
              <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" (onClick)="onHide()" [label]="'Cancel'"></beaconstac-button>
            </span>
            <span style="padding-left: 16px">
              <beaconstac-button (onClick)="routeToChangePlan()" [label]="service.primaryCta$ | async"></beaconstac-button>
            </span>
          </span>
        </div>

      </div>
    </div>
  </div>
</div>

<div bsModal #upsellModalV2="bs-modal" class="modal fade modal-v2" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="flex-row px-0 py-0 modal-body d-flex">
                <div class="left-container">
                    <div class="title">{{modalV2Data?.title.main}}<span class="title-plan">{{modalV2Data?.title.plan}}</span> {{modalV2Data?.title.icon}}</div>
                    <div class="description">{{modalV2Data?.description}}</div>
                    <div class="plan-detail" [ngClass]="{
                        'mb-3': (modalV2Data?.redirectCTA || modalV2Data?.buyWithAddon),
                        'mb-4': !modalV2Data?.detail?.save?.tooltipIcon,
                        'margin-bottom-64': !modalV2Data?.redirectCTA,
                        }">
                        <div class="mb-2 d-flex justify-content-between align-items-center">
                            <div class="plan-title">{{modalV2Data?.detail.title}}</div>
                            <div *ngIf="modalV2Data?.detail?.save" class="d-flex justify-content-center align-items-center" [ngClass]="{
                                'save': modalV2Data?.detail?.save?.tooltipIcon,
                                'save-spu': !modalV2Data?.detail?.save?.tooltipIcon,
                                }">
                                <span [ngClass]="{
                                    'save-text-spu': !modalV2Data?.detail?.save?.tooltipIcon,
                                    'save-text': modalV2Data?.detail?.save?.tooltipIcon,
                                }">{{modalV2Data?.detail.save.text}}</span>
                                <beaconstac-tooltip
                                    *ngIf="modalV2Data?.detail.save?.tooltipIcon"
                                    [color] = "'#FFFFFF'"
                                    [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                    [caretPosition]="CARET_POSITION.RIGHT"
                                    [elementClass]="modalV2Data?.detail.save?.tooltipIcon"
                                    [content]="modalV2Data?.detail.save.tooltipContent">
                                </beaconstac-tooltip>
                            </div>
                        </div>
                        <div *ngIf="modalV2Data?.detail?.price?.originalValue" class="original-price">{{modalV2Data.detail.price.originalValue}}</div>
                        <div class="mb-3 d-flex flex-column align-items-start">
                            <div class="flex-row d-flex justify-content-start align-items-center">
                                <div class="price-custom" *ngIf="modalV2Data?.detail?.customPricing; ">
                                    {{modalV2Data?.detail?.customPricing}}
                                </div>
                                <div *ngIf="modalV2Data?.detail?.price" class="d-flex">
                                    <div class="mr-2 price-value">
                                        {{modalV2Data?.detail?.price?.value}}
                                    </div>
                                    <div class="price-description">
                                        {{modalV2Data?.detail?.price?.message}}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="modalV2Data?.detail?.price?.subMessage" class="d-flex align-items-center sub-message">
                                <span>{{modalV2Data.detail.price.subMessage}}</span>
                                <beaconstac-tooltip
                                    [color] = "'#16212F'"
                                    class = "mt-1"
                                    [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                    [caretPosition]="CARET_POSITION.CENTER"
                                    [elementClass]="modalV2Data?.detail.price?.tooltipIcon"
                                    [content]="modalV2Data?.detail.price.tooltipContent"
                                    [width]="'390px'">
                                </beaconstac-tooltip>
                            </div>
                        </div>
                        <div class="includes-heading">{{modalV2Data?.detail.featureHeading}}</div>
                        <div *ngFor="let data of modalV2Data?.detail.features; let i = index">
                            <i *ngIf="!modalV2Data?.detail.featureIcons" class="mr-2 far fa-check" style="color: #26B969; font-size: 12px"></i>
                            <span *ngIf="modalV2Data?.detail.featureIcons" class="mr-2" >{{ modalV2Data?.detail.featureIcons[i] }}</span>
                            <span [style.font-weight]="i === 0 && modalV2Data?.detail.highlightFirstItem ? '600' : 'normal'" class="includes-option">{{data}}</span>
                        </div>
                    </div>
                    <div
                        *ngIf="modalV2Data?.redirectCTA"
                        class="cursor-pointer redirect-text"
                        style="margin-bottom: 32px"
                        (click)="handleUpsellRedirect()">
                        {{modalV2Data?.redirectCTA}}
                    </div>
                    <div *ngIf="modalV2Data?.buyWithAddon" class="mb-5">
                        <beaconstac-highlighted-checkbox
                            [checked]="modalV2Data.buyWithAddon.checked"
                            [title]="modalV2Data.buyWithAddon.title"
                            (checkedChange)="onToggleCustomDomainAddon($event)"
                            [subtitle]="modalV2Data.buyWithAddon.subtitle">
                        </beaconstac-highlighted-checkbox>
                    </div>
                    <div class="gap-3 d-flex w-100 footer">
                        <beaconstac-button
                            [width]="modalV2Data?.redirectCTA ? '50%' : '100%'"
                            (onClick)="modalV2Data?.directToPricing ? handleUpsellRedirect() : handleDirectUpgrade()"
                            [label]="modalV2Data?.upgradeCTA">
                        </beaconstac-button>
                        <beaconstac-button
                            *ngIf="modalV2Data?.closeCTA"
                            [type]="BUTTON_TYPES.SECONDARY"
                            [style]="BUTTON_STYLES.FILLED"
                            [width]="modalV2Data?.redirectCTA ? '50%' : '100%'"
                            (onClick)="onHideV2()"
                            [label]="modalV2Data?.closeCTA">
                        </beaconstac-button>
                        <beaconstac-button
                            [width]="'100%'"
                            *ngIf="modalV2Data?.redirectButton"
                            [type]="BUTTON_TYPES.PRIMARY"
                            [style]="BUTTON_STYLES.STROKE"
                            (onClick)="handleUpsellRedirect()"
                            [label]="modalV2Data?.redirectButton?.redirectCTA">
                        </beaconstac-button>
                    </div>
                </div>
                <div class="d-none d-md-flex justify-content-center align-items-center right-container" [style.background]="modalV2Data?.imageBackground">
                    <i *ngIf="!modalV2Data?.closeCTA" (click)="onHideV2()" class="cursor-pointer fas fa-times fa-lg position-absolute cancel-button" aria-hidden="true"></i>
                    <img [alt]="modalV2Data?.description" [src]="modalV2Data?.imageURL">
                </div>
            </div>
        </div>
    </div>
</div>

<div bsModal #upsellModalV3="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-lg" role="document" style="max-width: 586px">
        <div class="modal-content modal-v3">
            <div class="p-4 modal-body d-flex flex-column">
                <div class="mb-4 d-flex align-items-center justify-content-between top-container" (click)="onHideV3()">
                    <span class="title">{{modalV3Data?.heading}}</span>
                    <i (click)="onHideV3()" class="cursor-pointer fas fa-times fa-lg cancel-button" aria-hidden="true"></i>
                </div>
                <div class="flex-row mb-3 d-flex main-container">
                    <div class="current-plan">
                        <div class="mb-3 title">{{modalV3Data?.currentPlan.title}}</div>
                        <div class="mb-3 description">{{modalV3Data?.currentPlan.description}}</div>
                        <div class="flex-row mb-3 d-flex justify-content-start align-items-center">
                            <div class="mr-2 price-value">
                                {{modalV3Data?.currentPlan.price.value}}
                            </div>
                            <div class="price-description">
                                {{modalV3Data?.currentPlan.price.message}}
                            </div>
                        </div>
                        <div class="mb-3 upgrade-btn">
                            <beaconstac-button
                                [type]="BUTTON_TYPES.SECONDARY"
                                [width]="'100%'"
                                [style]="BUTTON_STYLES.FILLED"
                                [label]="modalV3Data?.currentPlan.upgradeCTA"
                                [disabled]="true"
                            ></beaconstac-button>
                        </div>

                        <!--section division line-->
                        <hr class="mt-0 mb-3" style="border-top: 1px solid #E4E5E7">
                        <div class="mb-2 includes-heading">{{modalV3Data?.currentPlan.featureHeading}}</div>
                        <div *ngFor="let data of modalV3Data?.currentPlan.features; let i = index" class="d-flex align-items-center" style="gap: 8px">
                            <i class="far fa-check" style="color: #16212f; font-size: 12px"></i>
                            <span class="includes-text">{{data.text}}</span>
                            <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                <ng-template #popTemplate>
                                    <div class="tooltip-content">{{data.tooltip.description}}</div>
                                </ng-template>
                                <button type="button"
                                        class="btn tooltip-button"
                                        [tooltip]="popTemplate"
                                >
                                    <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="recommended-plan">
                        <div class="mb-3 title">{{modalV3Data?.recommendedPlan.title}}</div>
                        <div class="mb-3 description">{{modalV3Data?.recommendedPlan.description}}</div>
                        <div class="flex-row mb-3 d-flex justify-content-start align-items-center">
                            <div class="mr-2 price-value">
                                {{modalV3Data?.recommendedPlan.price.value}}
                            </div>
                            <div class="price-description">
                                {{modalV3Data?.recommendedPlan.price.message}}
                            </div>
                        </div>
                        <div class="mb-3 upgrade-btn">
                            <beaconstac-button
                                [type]="BUTTON_TYPES.PRIMARY"
                                [width]="'100%'"
                                [style]="BUTTON_STYLES.FILLED"
                                [label]="modalV3Data?.recommendedPlan.upgradeCTA"
                                [disabled]="false"
                                (onClick)="handleDirectUpgrade()"
                            ></beaconstac-button>
                        </div>

                        <!--section division line-->
                        <hr class="mt-0 mb-3" style="border-top: 1px solid #E4E5E7">
                        <div class="mb-2 includes-heading">All Starter features, plus</div>
                        <div *ngFor="let data of modalV3Data?.recommendedPlan.features; let i = index" class="d-flex align-items-center" style="gap: 8px">
                            <i class="far fa-check" style="color: #FFFFFF; font-size: 12px"></i>
                            <span class="includes-text">{{data.text}}</span>
                            <div class="tooltip-parent" *ngIf="!isObjectEmpty(data.tooltip)">
                                <ng-template #popTemplateRecommended>
<!--                                    <div class="tooltip-content">{{data.tooltip.description}}</div>-->
                                    <div *ngIf = "data.tooltip.media.type === TOOLTIP_MEDIA.VIDEO" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <video src="{{data.tooltip.media?.src}}"
                                               width="100%"
                                               height="100%"
                                               autoplay
                                               style="border-radius: 16px"
                                        >
                                        </video>
                                        <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                    </div>
                                    <div *ngIf = "data.tooltip.media?.type === TOOLTIP_MEDIA.IMAGE" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <img src="{{data.tooltip.media.src}}"
                                             width="100%"
                                             height="100%"
                                             style="border-radius: 16px"
                                        >
                                        <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                    </div>
                                    <div *ngIf = "isObjectEmpty(data.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                         style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                        <div class="tooltip-content-text">{{data.tooltip.description}}</div>
                                    </div>
                                </ng-template>
                                <button type="button"
                                        class="btn tooltip-button"
                                        [tooltip]="popTemplateRecommended"
                                >
                                    <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cursor-pointer bottom-container" (click)="handleUpsellRedirect()">{{modalV3Data?.redirectCTA}}</div>
            </div>
        </div>
    </div>
</div>

<div #upsellModalMobile>
    <beaconstac-mobile-drawer class="analytics-drawer" [isDrawerOpen]="isMobileUpsellModalOpen" (onOutsideClick)="onHideMobileModal()">
        <div class="upsell-modal-mobile">
            <div class="title">{{mobileUpsellModalData?.title.main}}<span class="title-plan">{{mobileUpsellModalData?.title.plan}}</span></div>
            <div class="description">{{mobileUpsellModalData?.description}}</div>
            <div class="plan-detail" style="margin-bottom: 32px">
                <div class="mb-2 d-flex justify-content-between align-items-center">
                    <div class="plan-title">{{mobileUpsellModalData?.detail.title}}</div>
                    <div *ngIf="mobileUpsellModalData?.detail?.save" class="d-flex justify-content-center align-items-center save">
                        <span class="save-text">{{mobileUpsellModalData?.detail.save.text}}</span>
                    </div>
                </div>
                <div class="flex-row mb-3 d-flex justify-content-start align-items-center">
                    <div class="price-custom" *ngIf="mobileUpsellModalData?.detail?.customPricing; else showPrice">
                        {{mobileUpsellModalData?.detail?.customPricing}}
                    </div>
                    <ng-template #showPrice>
                        <div class="mr-2 price-value">
                            {{mobileUpsellModalData?.detail?.price?.value}}
                        </div>
                        <div class="price-description">
                            {{mobileUpsellModalData?.detail?.price?.message}}
                        </div>
                    </ng-template>
                </div>
                <div class="includes-heading">{{mobileUpsellModalData?.detail.featureHeading}}</div>
                <div *ngFor="let data of mobileUpsellModalData?.detail.features; let i = index">
                    <i class="mr-1 far fa-check" style="color: #26B969; font-size: 12px"></i>
                    <span [style.font-weight]="i === 0 ? '600' : 'normal'" class="includes-option">{{data}}</span>
                </div>
            </div>
            <div class="gap-3 d-flex flex-column w-100 footer">
                <beaconstac-button
                    [width]="'100%'"
                    (onClick)="handleDirectUpgrade()"
                    [label]="mobileUpsellModalData?.upgradeCTA">
                </beaconstac-button>
                <beaconstac-button
                    [width]="'100%'"
                    *ngIf="mobileUpsellModalData?.redirectButton"
                    [type]="BUTTON_TYPES.PRIMARY"
                    [style]="BUTTON_STYLES.STROKE"
                    (onClick)="handleUpsellRedirect()"
                    [label]="mobileUpsellModalData?.redirectButton?.redirectCTA">
                </beaconstac-button>
            </div>
        </div>
    </beaconstac-mobile-drawer>
</div>

<div bsModal #upsellModalV4="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog upsell-modal-v4" role="document">
      <div *ngIf="modalV4Data" class="modal-content">
        <div class="p-4 modal-body">
          <h4 class="title" [innerHTML]="modalV4Data.title"></h4>
          <p class="pt-2 description" [innerHTML]="modalV4Data.description"></p>
          <div class="pt-4 d-flex buttons-container justify-content-end align-items-center">
            <beaconstac-button
                [type]="BUTTON_TYPES.SECONDARY"
                (onClick)="onHideUpsellV4()"
                [label]="'Cancel'">
            </beaconstac-button>
            <beaconstac-button
                (onClick)="handleUpsellRedirect()"
                [label]="'Upgrade Plan'">
            </beaconstac-button>
          </div>
        </div>
      </div>
    </div>
  </div>

<div #discountUpsellModalMobile>
    <beaconstac-mobile-drawer class="analytics-drawer" [isDrawerOpen]="isDiscountUpsellModalMobileOpen" (onOutsideClick)="onHideDiscountModal()">
        <div class="discount-upsell-modal-mobile">
            <div class="mb-4 title" [innerHTML]="discountModalData?.update.title"></div>
            <div class="mb-4 description">
                <div class="mb-4"[innerHTML]="discountModalData?.update.description"></div>
                <ul class="pl-4">
                    <li class="mb-1" *ngFor="let feature of discountModalData?.update.features">{{ feature }}</li>
                </ul>
            </div>
            <div class="mb-4 note d-flex justify-content-center align-items-center">{{discountModalData?.update.note}}</div>
            <div class="mb-4 warning">
                <div class="px-3 py-3 message-modal">
                    <i class="fas fa-info-circle" style="color: #fcbf49;"></i>
                    <span class="text" [innerHTML]="discountModalData?.update.warning"></span>
                </div>
            </div>
            <div class="gap-3 d-flex flex-column w-100 footer">
                <beaconstac-button
                    [width]="'100%'"
                    (onClick)="handleDirectUpgrade()"
                    [label]="discountModalData?.update.primaryCTA">
                </beaconstac-button>
                <beaconstac-button class="secondary-cta"
                    [width]="'100%'"
                    [type]="BUTTON_TYPES.PRIMARY"
                    [style]="BUTTON_STYLES.STROKE"
                    (onClick)="onHideDiscountModal()"
                    [label]="discountModalData?.update.secondaryCTA">
                </beaconstac-button>
            </div>
        </div>
    </beaconstac-mobile-drawer>
</div>

<div bsModal #discountUpsellModal="bs-modal" class="modal fade discount-modal" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="flex-row px-0 py-0 modal-body d-flex">
                <div class="left-container">
                    <div class="mb-4 title" [innerHTML]="discountModalData?.update.title"></div>
                    <div class="mb-4 description">
                        <div class="mb-4"[innerHTML]="discountModalData?.update.description"></div>
                        <ul class="pl-4">
                            <li class="mb-1" *ngFor="let feature of discountModalData?.update.features">{{ feature }}</li>
                        </ul>
                    </div>
                    <div class="mb-4 note d-flex justify-content-center align-items-center">{{discountModalData?.update.note}}</div>
                    <div class="warning">
                        <div class="px-3 py-3 message-modal">
                            <i class="fas fa-info-circle" style="color: #fcbf49;"></i>
                            <span class="text" [innerHTML]="discountModalData?.update.warning"></span>
                        </div>
                    </div>
                    <div class="gap-3 d-flex w-100 footer">
                        <beaconstac-button
                            [width]="'100%'"
                            (onClick)="handleDirectUpgrade()"
                            [label]="discountModalData?.update.primaryCTA">
                        </beaconstac-button>
                        <beaconstac-button class="secondary-cta"
                            [width]="'100%'"
                            [type]="BUTTON_TYPES.PRIMARY"
                            [style]="BUTTON_STYLES.STROKE"
                            (onClick)="onHideDiscountModal()"
                            [label]="discountModalData?.update.secondaryCTA">
                        </beaconstac-button>
                    </div>
                </div>
                <div class="right-container">
                    <div class="mb-4 title">
                        <span class="plan">{{discountModalData?.offer.title.plan}}</span>
                        <span class="description">{{discountModalData?.offer.title.description}}</span>
                        <span class="percent">{{discountModalData?.offer.title.percent}}</span>
                    </div>
                    <div class="mb-4 price">
                        <s class="original">{{discountModalData?.offer.price.original}}</s>
                        <div class="flex-row d-flex justify-content-start align-items-center ">
                            <div class="d-flex">
                                <div class="mr-2 value">
                                    {{discountModalData?.offer.price.discounted}}
                                </div>
                                <div class="description d-flex justify-content-center align-items-center">
                                    {{discountModalData?.offer.price.billingCycle}}
                                </div>
                            </div>
                        </div>
                        <div class="flex-row d-flex justify-content-start align-items-center" style="column-gap: 8px">
                            <span class="message">{{discountModalData?.offer.price.message}}</span>
                            <beaconstac-tooltip
                                [color] = "'#FFFFFF'"
                                [tooltipPosition]="TOOLTIP_POSITION.BOTTOM"
                                [caretPosition]="CARET_POSITION.RIGHT"
                                [elementClass]="'fal fa-info-circle'"
                                [content]="'Enjoy advanced features at a discounted first-year rate! Your plan will automatically renew next year at $49/month'">
                            </beaconstac-tooltip>
                        </div>
                    </div>
                    <div class="includes">
                        <div class="heading">{{discountModalData?.offer.includes.heading}}</div>
                        <div class="feature" *ngFor="let data of discountModalData?.offer.includes.features; let i = index">
                            <i class="mr-2 far fa-check" style="color: #26B969; font-size: 12px"></i>
                            <span class="feature-name">{{data}}</span>
                        </div>
                    </div>
                    <hr style="border: 0.5px solid white;">
                    <div>
                        <img [src]="discountModalData?.offer.imagePath">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
