import {Component, EventEmitter, OnDestroy, OnInit, Output, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {DigitalBusinessCardTemplateService} from '../../digital-business-cards.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {SearchParams} from '../../../global-services/base-backend.service';
import {DigitalBusinessCard, DigitalBusinessCardTemplateModel} from '../../digital-business-card.model';
import {AuthService} from '../../../global-services/auth.service';

@Component({
    selector: 'app-digital-business-card-templates-dropdown',
    templateUrl: './digital-business-card-templates-dropdown.component.html',
    styleUrls: ['./digital-business-card-templates-dropdown.component.scss']
})
export class DigitalBusinessCardTemplatesDropdownComponent implements OnInit, OnDestroy {
    templateList: Array<any> = [{name: 'None', value: 'none'}];

    templatesScrollChangeSubject = new Subject<boolean>();
    ngUnsubscribe: Subject<any> = new Subject();

    templatePage = 1;
    fetchingTempaltes = false;
    searchedTempalteName = ''
    fetchedAllTempaltes = false;
    hasTemplates: boolean = true;
    isInitialFetch = true;
    initialFetchTemplateSubject = new Subject<boolean>();

    @Input() labelText: string = '';
    @Input() showHeading: boolean = true;
    @Input() width: string = '200px';
    @Input() selectedTemplate: DigitalBusinessCardTemplateModel = null;
    @Input() product: DigitalBusinessCard | DigitalBusinessCardTemplateModel = null;
    @Input() showNoneOption: boolean = true;
    @Input() organizationID: number;
    @Input() id: number = 1;
    @Input() placeholder: string = '';
    /**
        allowFetchingMasterOrgTemplates: This is a flag to allow fetching master org templates in case there are no templates in the child org.
    * */
    @Input() allowFetchingMasterOrgTemplates: boolean = false;
    /**
     *  Select template as default if only single template is available in org
     */
    @Input() selectSingleTemplateAsDefault: boolean = false;

    @Output() onTemplateSelectChange: EventEmitter<any> = new EventEmitter();
    @Output() orgHasTemplates: EventEmitter<any> = new EventEmitter<any>()

    constructor(private digitalBusinessCardTemplateService: DigitalBusinessCardTemplateService,
        private authService: AuthService) {
    }

    ngOnInit() {
        this.templatesScrollChangeSubject.pipe(debounceTime(100)).subscribe(event => {
            this.templatePage++;
            this.fetchingTempaltes = true;
            this.fetchTempaltes(event);
        })

        this.initialFetchTemplateSubject.pipe(debounceTime(100)).subscribe(event => {
            this.isInitialFetch = event;
        });

        this.checkIfOrgHasTemplates();
    }

    /**
     * This function is required because there could be a case when there are no tempaltes in the child organization
     * So if that is the case we can check for master org tempaltes if allowFetchingMasterOrgTemplates is true
     * **/
    checkIfOrgHasTemplates() {
        const params: SearchParams = {};
        const orgID = this.organizationID ? this.organizationID : this.authService.getCurrentOrgId();
        params['organization'] = orgID;
        this.initialFetchTemplateSubject.next(true);
        this.digitalBusinessCardTemplateService.getList(1, 1, params).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            this.hasTemplates = result.totalCount > 0;
            if (this.hasTemplates) {
                this.orgHasTemplates.emit(true);
            } else {
                this.orgHasTemplates.emit(false);
            }
            this.fetchTempaltes(false);
        });
    }

    fetchTempaltes(onScroll: boolean) {
        const params: SearchParams = {};
        const orgID = this.organizationID ? this.organizationID : this.authService.getCurrentOrgId();
        params['title__icontains'] = this.searchedTempalteName;
        params['organization'] = orgID;
        if (!this.hasTemplates && this.allowFetchingMasterOrgTemplates) {
            params['organization'] = this.authService.getUser().mainOrganization;
        }

        this.digitalBusinessCardTemplateService.getList(this.templatePage, 10, params).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
            this.initialFetchTemplateSubject.next(false);
            if ( result.pageCount === this.templatePage ) {
                this.fetchedAllTempaltes = true;
            }
            if ( onScroll ) {
                this.templateList =  this.templateList.concat(this.convertToDropdownList(result.objects));
            } else {
                this.templateList = Object.assign([], this.convertToDropdownList(result.objects));
            }
            this.fetchingTempaltes = false;
            this.checkForAttachedTemplate();
            this.checkIfToSelectDefaultTemplate();

        }, error => {
            this.fetchingTempaltes = false;
            console.log(error);
        });
    }

    checkIfToSelectDefaultTemplate() {
        if (this.selectSingleTemplateAsDefault && this.templateList.length === 1) {
            this.selectedTemplate = this.showNoneOption ? this.templateList[1].template : this.templateList[0].template;
            this.onTemplateSelectChange.emit({
                name: this.selectedTemplate.name,
                value: this.selectedTemplate.id,
                template: this.selectedTemplate
            });
        }
    }

    checkForAttachedTemplate() {
        if (!this.product) {
            return;
        }
        this.selectedTemplate = this.digitalBusinessCardTemplateService.getDBCTemplate();
        if (this.product?.card_template) {
            this.digitalBusinessCardTemplateService.getData(this.product.card_template).pipe(takeUntil(this.ngUnsubscribe)).subscribe((result) => {
                this.selectedTemplate = result;
                this.onTemplateSelectChange.emit({
                    name: this.selectedTemplate.name,
                    value: this.selectedTemplate.id,
                    template: this.selectedTemplate
                })
            });
        }
        if (this.selectedTemplate) {
            this.product.card_template = this.selectedTemplate?.id;
        }
    }

    convertToDropdownList(list: Array<any>) {
        const newList = list.map(res => {
            return {
                name: res.template_name,
                value: res.id,
                template: res
            };
        });
        if ( this.showNoneOption ) {
            newList.unshift({name: 'None', value: 'none', template: null})
        }
        return newList;
    }

    onTemplateSelect(event) {
        this.onTemplateSelectChange.emit(event);
    }

    onTempalteSearch(event) {
        this.searchedTempalteName = event;
        this.templatePage = 0;
        this.templatesScrollChangeSubject.next(false)
    }

    onFetchMoreTempaltes() {
        if ( this.fetchedAllTempaltes || this.fetchingTempaltes ) {
            return;
        }
        this.templatesScrollChangeSubject.next(true);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
