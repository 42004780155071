<div *ngIf="loading$ | async" class="beaconstac-overlay" style="width: 100%;">
  <spinner class="custom-spinner-overlay" style="" [size]="60" [tickness]="3">
  </spinner>
</div>
<div>
  <beaconstac-message-modal></beaconstac-message-modal>
</div>
<div class="d-flex align-items-center justify-content-between">
  <img class="login-page-logo mx-4" [src]="brandLogo">
  <div class="mx-4">
    Hi {{firstName}} |
      <a href="Javascript:void(0)" (click)="switchCurrentProduct()">Switch to {{currentDashboard === DASHBOARD_MENU.CARDS ? 'QR Code' : ' Digital Business Cards'}}</a> |
    <a href="Javascript:void(0)" (click)="logout()">Logout</a>
  </div>
</div>
<div *ngIf="currentDashboard == DASHBOARD_MENU.QRCODES">
  <div class="purchase-background">

    <div *ngIf="user && user.isOwner()">
      <ul class="d-sm-down-none product-breadcrumb pl-0">
        <li><span [ngClass]="currentSection === PURCHASE_SECTION.Plan ? 'active' : ''"
                  (click)="switchSection(PURCHASE_SECTION.Plan)">1. Select plan</span></li>
        <li><span [ngClass]="currentSection === PURCHASE_SECTION.Payment ? 'active' : ''"
                  (click)="switchSection(PURCHASE_SECTION.Payment)">2. Payment</span></li>
      </ul>

      <ul class="d-md-none product-breadcrumb pl-0">
        <li><span [ngClass]="currentSection === PURCHASE_SECTION.Plan ? 'active' : ''"
                  (click)="switchSection(PURCHASE_SECTION.Plan)">1</span></li>
        <li><span [ngClass]="currentSection === PURCHASE_SECTION.Payment ? 'active' : ''"
                  (click)="switchSection(PURCHASE_SECTION.Payment)">2</span></li>
      </ul>

      <div class="upgrade-container">
        <form #cardForm="ngForm" method="post" id="payment-form">
          <div [ngSwitch]="currentSection">
            <section class="mx-0" *ngSwitchCase="PURCHASE_SECTION.Plan" [@collapse] style="text-align: center;">
<!--              <h4 style="width: 100%; margin: 1rem 0 2rem 0;">Your subscription has expired. Please choose a plan to continue service</h4>-->
              <div style="width: 100%;">
                <div *ngIf="showBlackFridayPrice" class="text-center d-flex" style="justify-content: center;">
                  <div class="black-friday-sale-background d-lg-down-w-100" [ngStyle]="{width: blackFridayBannerWidth}">
                    <span class="black-friday-sale-text">
                      BLACK FRIDAY OFFER!
                    </span>
                    <span style="margin-inline: 24px">
                        <div class="d-inline-flex">
                          <i style="font-size: 24px; width: 16px; height: 16px; margin-top: 8px" class="fas fa-caret-right"></i>
                          <div style="width: 40px" class="mx-2 p-2 times-background"><span class="times-text" #days></span></div>
                          <span class="times-colon mx-1">:</span>
                          <div style="width: 40px" class="mx-2 p-2 times-background"><span class="times-text" #hours></span></div>
                          <span class="times-colon mx-1">:</span>
                          <div style="width: 40px" class="mx-2 p-2 times-background"><span class="times-text" #minutes></span></div>
                          <span class="times-colon mx-1">:</span>
                          <div style="width: 40px" class="mx-2 p-2 times-background"><span class="times-text" #seconds></span></div>
                          <i style="font-size: 24px; width: 16px; height: 16px; margin-top: 8px" class="fas fa-caret-left"></i>
                        </div>
                      </span>
                  </div>
                </div>
              </div>

            <div *ngIf="currentDashboard === DASHBOARD_MENU.QRCODES">
              <!-- renew qr plan component -->
              <div class="qr-pricing-heading mb-4">
                {{(user | isOnHigherPlan : PLAN_TYPES.Trial) ? 'Your plan has expired.': 'Your free trial has expired.'}}
              </div>
                <div class="qr-pricing-subheading mb-4">
                    {{qrUserCount > 1 ? 'Upgrade your trial with multi-user access.' : ((user | isOnHigherPlan : PLAN_TYPES.Trial) ? 'Your QR Codes are safe but currently inactive.' : 'Your QR Codes are now inactive and will not work on scan.')}}
                </div>
                <div class="qr-choose-a-plan">
                    {{qrUserCount > 1 ? 'Choose a QR Code pricing plan that best suits your business.': 'Choose a plan to re-activate them.'}}
                </div>
                <div class="d-flex justify-content-center mt-4" *ngIf="showSwitcher()">
                    <div class="d-flex justify-content-between align-items-center plans-switcher-btngroup">
                        <input type="radio" id="first-2" class="first" [checked]="!showBusinessPlans">
                        <label class="d-flex align-items-center justify-content-center mx-3 my-2" for="first-2" (click)="togglePlans(true)" [ngClass]="!showBusinessPlans? 'active-preview': ''">
                            <img *ngIf="!showBusinessPlans" src="../../assets/img/user-upgrade/user-dark.svg">
                            <img *ngIf="showBusinessPlans" src="../../assets/img/user-upgrade/user-light.svg">
                            <span class="switcher-text ml-2">For Individuals</span>
                        </label>
                        <input type="radio" id="second-2" class="second" [checked]="showBusinessPlans">
                        <label class="d-flex align-items-center justify-content-center mx-4 my-2" for="second-2" (click)="togglePlans(false)" [ngClass]="showBusinessPlans? 'active-preview': ''">
                            <img *ngIf="showBusinessPlans" src="../../assets/img/user-upgrade/business-dark.svg">
                            <img *ngIf="!showBusinessPlans" src="../../assets/img/user-upgrade/business-light.svg">
                            <span class="switcher-text ml-2">For Businesses</span>
                        </label>
                        <div class="slider"></div>
                    </div>
                </div>
                <beaconstac-qr-pricing
                    (onEnterpriseSelected)="onEnterpriseSelected($event)"
                    (onQRPlanTypeClicked)="onPlanTypeClicked($event)"
                    [user]="user"
                    [qrPlansData]="showBusinessPlans ? qrPlansData.qr.businessPlans : qrPlansData.qr.individualPlans"
                    [showBusinessPlans] = "showBusinessPlans"
                    [pageType]="'renew-plan'"
                    [exchangeRates] = "exchangeRates"
                >
                </beaconstac-qr-pricing>
                <beaconstac-feature-details
                    (onEnterpriseSelected)="onEnterpriseSelected($event)"
                    (subscribeWithCheckout)="subscribeWithCheckout($event)"
                    [pageType]="'renew-plan'"
                    [showBusinessPlans] = "showBusinessPlans"
                    [qrPlansData]= "qrPlansData.qr"
                ></beaconstac-feature-details>
            </div>

          </section>

            <section class="mx-0" *ngSwitchCase="PURCHASE_SECTION.Payment" [@collapse] style="text-align: center;">
              <h4 style="width: 100%;">Purchase {{plansFullName[planType]}} plan ${{plansYearlyPrice[planType]}}/yr</h4>
              <div style="display: flex; justify-content: center; width: 100%;">
                <div
                  style="width: 45rem; padding: 1rem 1.5rem; box-shadow: -3px 4px 15px #e7ebee; border-radius: 5px; text-align: left; margin-top: 1rem; background: #fff">
                  <div class="upgrade-payment" id="upgrade-payment">
                    <div class="upgrade-payment-child">
                      <h4 *ngIf="cards.length > 0">Select a payment method</h4>
                      <div *ngIf="cards.length > 0" class="existing-cards"
                          style="display: flex; flex-wrap: wrap;  text-align: left; margin-left: -8px; margin-top: -5px;">
                        <div *ngFor="let item of cards"
                            style="padding: 2rem 1rem; display: flex; line-height: 2; border: 1px solid #eee; border-radius: 5px; width: max-content; margin: 0.5rem;">
                          <div style="margin-right: 10px;">
                            <input type="radio" [checked]="item.id === selectedCard.id" (click)="setUseExistingCard(item)">
                          </div>
                          <div>
                            <div class="card-image">
                              <img src="../../assets/img/user-upgrade/{{item.brand | lowercase}}.png" style="height: 20px;">
                            </div>
                            <div class="card-number" style="font-weight:500; color: #4e4e4e;">
                              XXXX-XXXX-XXXX-{{item.last4}}</div>
                            <div class="card-type" style="color: #999da5;">{{item.funding | uppercase}} CARD | VALID
                              TILL {{item.exp_month}}/{{item.exp_year}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="padding-bottom: 2rem;" class="upgrade-payment-child">
                      <h4 *ngIf="cards.length > 0">Or add a new card</h4>
                      <h4 *ngIf="cards.length == 0">Add a payment method</h4>

                      <div class="form-row stripe-container" style="padding: 0.7rem;">
                        <input id="name" name="name" placeholder="Name on card" required value="">
                      </div>
                      <div class="form-row stripe-container">

                        <!-- a Stripe Element will be inserted here. -->
                        <div id="card-element" class="stripe-card-container">
                        </div>
                        <!-- Used to display Element errors -->
                        <div id="card-errors" class="stripe-card-error" role="alert"></div>
                      </div>
                      <div *ngIf="planYearly">
                        <h4 class="mt-5">Apply a coupon code</h4>
                        <div class="form-row">
                          <div class="input-group">
                            <input class="form-control" id="coupon" name="coupon" placeholder="Add a coupon code" [disabled]="couponCodeModel.success || couponCodeModel.loading"
                                  [(ngModel)]="couponCodeModel.couponCode">
                            <button *ngIf="!couponCodeModel.success" class="btn btn-primary" style="width: 100px"
                                    (click)="onCouponApplied()"
                                    [disabled]="couponCodeModel.loading || !couponCodeModel.couponCode">
                              <span *ngIf="!couponCodeModel.loading">Apply</span>
                              <span *ngIf="couponCodeModel.loading"><i class="fas fa-spinner"></i></span>
                            </button>
                            <button *ngIf="couponCodeModel.success" class="btn btn-danger" style="width: 100px"
                                    (click)="onCouponRemoved()">
                              Remove
                            </button>
                          </div>
                          <div class="alert alert-danger w-100" *ngIf="couponCodeModel.error">
                            Invalid coupon code
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="upgrade-footer"
                      style="margin-top: 2px;">
                    <button class="upgrade-page-button button-cancel upgrade-page-button-payment" type="button"
                            (click)="switchSection(PURCHASE_SECTION.Plan)">Back
                    </button>
                    <button class="upgrade-page-button upgrade-button upgrade-page-button-payment" type="button"
                            value="PURCHASE"
                            (click)="purchaseModal.show()">PURCHASE
                    </button>
                    <button class="d-none" id="purchase-submit-button" value="submit" type="submit"></button>
                  </div>

                </div>
              </div>
            </section>
          </div>
        </form>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-center flex-column" style="height: 70vh;" *ngIf="(user && !user.isOwner())">
      <h2>Your subscription has expired!</h2>
      <div>
        <p>Please contact account owner to continue the service.</p>
      </div>
    </div>
  </div>

  <div bsModal #purchaseModal="bs-modal" class="modal fade" role="dialog" (onShow)="setUpPurchaseModel()">
    <div class="modal-dialog modal-primary" role="document">
      <div class="modal-content change-plan-view">

        <div class="modal-header">
          <h5 class="modal-title">Change Plan</h5>
          <button type="button" class="close" (click)="purchaseModal.hide()"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>You're about to subscribe to the <b>{{purchaseModel.name}} plan
            <span *ngIf="couponCodeModel.success">(${{purchaseModel.price}}/{{purchaseModel.duration}})</span></b>
            <span *ngIf="!couponCodeModel.success">and your card will be charged <b>${{purchaseModel.price}}/{{purchaseModel.duration}}</b></span>
          </p>

          <p *ngIf="couponCodeModel.success">
            <i><b>Promo code applied ({{couponCodeModel.couponCode}}): {{couponCodeModel.coupon.name}}</b></i>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="purchaseModal.hide()">
            Cancel
          </button>
          <button class="btn btn-primary" type="button"
                  (click)="purchase(); purchaseModal.hide()">Confirm
          </button>
        </div>
      </div>
    </div>
  </div>


  <div bsModal #infoModal="bs-modal" class="modal fade modal info-modal" role="dialog">
    <div class="modal-dialog modal-primary modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" (click)="infoModal.hide()"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <table class="learn-more-big-screen" style="background: white; margin-top: 30px;">
            <thead>
            <tr>
              <th class="table-heading-contact-us">

              </th>

              <th class="plan-heading default">
                <div class="plan-description">
                  <h4>STARTER</h4>
                </div>
              </th>
              <th class="plan-heading">
                <div class="plan-description">
                  <h4>LITE</h4>
                </div>
              </th>
              <th class="plan-heading">
                <div class="plan-description">
                  <h4>PRO</h4>
                </div>
              </th>
              <th class="plan-heading">
                <div class="plan-description">
                  <h4>PLUS</h4>
                </div>
              </th>
              <th class="plan-heading">
                <div class="plan-description">
                  <h4>ENTERPRISE</h4>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="6" class="sep">FEATURES</td>
            </tr>
            <tr>
              <td>Dynamic QR Codes
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Change/update the campaigns (URL) behind the QR Code even after printing and distribution'></i>
                </div>
              </td>
              <td class="default">3</td>
              <td>50</td>
              <td>250</td>
              <td>500</td>
              <td>Monthly volume pricing based on scale</td>
            </tr>
            <tr>
              <td>Static QR Codes
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='A single fixed URL linked to the QR Code'></i></div>
              </td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Annual scans
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Maximum number of scans at the account level'></i></div>
              </td>
              <td class="default">25,000</td>
              <td>75,000</td>
              <td>250,000</td>
              <td>500,000</td>
              <td>Custom</td>
            </tr>
            <tr>
              <td colspan="6" class="sep">ANALYTICS
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Available only on dynamic QR Codes'></i></div>
              </td>
            </tr>
            <tr>
              <td>
                Scan tracking
              </td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>
                Scans by GPS location
              </td>
              <td class="default"></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>
                Google Analytics integration
              </td>
              <td class="default"></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>
                Email reports
              </td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <!--          <tr class="identifier">-->
            <!--            <td>Reports (CSV and PDF)</td>-->
            <!--            <td class="default"></td>-->
            <!--            <td></td>-->
            <!--            <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"-->
            <!--                     data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>-->
            <!--            <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"-->
            <!--                     data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>-->
            <!--          </tr>-->
            <tr>
              <td colspan="6" class="sep">CUSTOMISATION AND PRINT FORMATS</td>
            </tr>
            <tr>
              <td>Update colors, background &amp; add logo</td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Digital formats (PNG, JPG)</td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Print-ready formats (EPS, SVG)</td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td colspan="6" class="sep">QR CODE TYPES</td>
            </tr>
            <tr>
              <td>URL, SMS, Call, Email, vCard, Plain text</td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Location, PDF, MP3, Image, App download</td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Multi-lingual QR Codes</td>
              <td class="default"></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td colspan="6" class="sep">BRANDING</td>
            </tr>
            <tr>
              <td>Editable short URL
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Modify URLs to keep them short and reflect your brand campaigns'></i>
                </div>
              </td>
              <td class="default"></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Use your own domain
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Allow users to scan and redirect to landing pages with your domain'></i>
                </div>
              </td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>White-label
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Drop the Uniqode branding from the dashboard &amp; campaings'></i>
                </div>
              </td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td colspan="6" class="sep">SUPPORT FOR OTHER TECHNOLOGIES</td>
            </tr>
            <tr>
              <td>NFC Tags</td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Beacons</td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td colspan="6" class="sep">API ACCESS</td>
            </tr>
            <tr>
              <td>Static QR Code</td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Dynamic QR Code</td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Bulk QR Codes (static &amp; dynamic)</td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Integration</td>
              <td class="default"></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td colspan="6" class="sep">ENTERPRISE FEATURES</td>
            </tr>
            <tr>
              <td>Multi-user access
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Add/delete users; Manage access rights for team members'></i>
                </div>
              </td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Retarget users on Google &amp; Facebook
                <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                data-placement="top" container="body" data-toggle="tooltip"
                                                title='Recapture the attention of users who scanned your QR codes, with Google and FB ads'></i>
                </div>
              </td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Single sign on (SSO)
              </td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td colspan="6" class="sep">TECH SUPPORT</td>
            </tr>
            <tr>
              <td>Email</td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Live chat &amp; help center</td>
              <td class="default"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            <tr>
              <td>Phone</td>
              <td class="default"></td>
              <td></td>
              <td></td>
              <td></td>
              <td><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
            </tr>
            </tbody>
          </table>

          <article class="mt-md-1 mt-sm-3 learn-more-mobile" style="margin-top: 30px;">
            <div class="software-plan-button">

              <div class="border-left border-right" (click)="switchInfoModalPlan(1)"
                  [ngClass]="infoSectionPlan === 1 ? 'active' : ''">
                STARTER
              </div>

              <div class="border-left border-right" (click)="switchInfoModalPlan(2)"
                  [ngClass]="infoSectionPlan === 2 ? 'active' : ''">
                LITE
              </div>

              <div class="border-left border-right" (click)="switchInfoModalPlan(3)"
                  [ngClass]="infoSectionPlan === 3 ? 'active' : ''">
                Pro
              </div>


              <div class="border-left border-right" (click)="switchInfoModalPlan(4)"
                  [ngClass]="infoSectionPlan === 4 ? 'active' : ''">
                ENTERPRISE
              </div>

            </div>

            <table style="background: white;" id="table-mobile">
              <thead>
              </thead>
              <tbody>
              <tr>
                <td colspan="2" class="sep">FEATURES</td>
              </tr>
              <tr>
                <td>Dynamic QR Codes
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Change/update the campaigns (URL) behind the QR Code even after printing and distribution'></i>
                  </div>
                </td>
                <td class="default" style="display: table-cell;">3</td>
                <td style="display: none;">50</td>
                <td style="display: none;">250</td>
                <td style="display: none;">Volume pricing based on scale</td>
              </tr>
              <tr>
                <td>Static QR Codes
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='A single fixed URL linked to the QR Code'></i></div>
                </td>
                <td class="default" style="display: table-cell;"><img class="check-icon"
                                                                      src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg">
                </td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Annual Scans
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Number of times a QR code can be scanned'></i></div>
                </td>
                <td class="default" style="display: table-cell;">25,000</td>
                <td style="display: none;">75,000</td>
                <td style="display: none;">250,000</td>
                <td style="display: none;">Custom</td>
              </tr>
              <tr>
                <td colspan="5" class="sep">ANALYTICS
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Available only on dynamic QR Codes'></i></div>
                </td>
              </tr>
              <tr>
                <td>
                  Scan tracking
                </td>
                <td class="default" style="display: table-cell;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>
                  Scans by GPS location
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>
                  Google Analytics integration
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>
                  Email reports
                </td>
                <td class="default" style="display: table-cell;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <!--            <tr class="identifier">-->
              <!--              <td>Reports (CSV and PDF)</td>-->
              <!--              <td class="default" style="display: table-cell;"></td>-->
              <!--              <td style="display: none;"></td>-->
              <!--              <td style="display: none;"><img class="check-icon"-->
              <!--                                              src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"-->
              <!--                                              data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>-->
              <!--            </tr>-->
              <tr>
                <td colspan="2" class="sep">CUSTOMISATION AND PRINT FORMATS</td>
              </tr>
              <tr>
                <td>Update colors, background &amp; add logo</td>
                <td class="default" style="display: table-cell;"><img class="check-icon"
                                                                      src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg">
                </td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Digital formats (PNG, JPG)</td>
                <td class="default" style="display: table-cell;"><img class="check-icon"
                                                                      src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg">
                </td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Print-ready formats (EPS, SVG)</td>
                <td class="default" style="display: table-cell;"><img class="check-icon"
                                                                      src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg">
                </td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td colspan="2" class="sep">QR CODE TYPES</td>
              </tr>
              <tr>
                <td>URL, SMS, Call, Email, vCard, Plain text</td>
                <td class="default" style="display: table-cell;"><img class="check-icon"
                                                                      src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg">
                </td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Location, PDF, MP3, Image, App download</td>
                <td class="default" style="display: table-cell;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Multi-lingual QR Codes</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td colspan="2" class="sep">BRANDING</td>
              </tr>
              <tr>
                <td>Editable short URL
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Modify URLs to keep them short and reflect your brand campaigns'></i>
                  </div>
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Use your own domain
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Allow users to scan and redirect to landing pages with your domain'></i>
                  </div>
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>White-label
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Drop the Uniqode branding from the dashboard'></i></div>
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td colspan="2" class="sep">SUPPORT FOR OTHER TECHNOLOGIES</td>
              </tr>
              <tr>
                <td>NFC Tags</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Beacons</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td colspan="2" class="sep">API ACCESS</td>
              </tr>
              <tr>
                <td>Static QR Code</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Dynamic QR Code</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Bulk QR Codes (static &amp; dynamic)</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Integrations</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td colspan="2" class="sep">ENTERPRISE FEATURES</td>
              </tr>
              <tr>
                <td>Multi-user access
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Add/delete users; Manage access rights for team members'></i>
                  </div>
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Retarget users on Google &amp; Facebook
                  <div class="tooltip-pricing"><i class="fas fa-info-circle" aria-hidden="true"
                                                  data-placement="top" container="body" data-toggle="tooltip"
                                                  title='Recapture the attention of users who scanned your QR codes, with Google and FB ads'></i>
                  </div>
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Single sign on (SSO)
                </td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon" src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td colspan="2" class="sep">TECH SUPPORT</td>
              </tr>
              <tr>
                <td>Email</td>
                <td class="default" style="display: table-cell;"><img class="check-icon"
                                                                      src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg">
                </td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Live chat &amp; help center</td>
                <td class="default" style="display: table-cell;"><img class="check-icon"
                                                                      src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                                      data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg">
                </td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              <tr>
                <td>Phone</td>
                <td class="default" style="display: table-cell;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"></td>
                <td style="display: none;"><img class="check-icon"
                                                src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"
                                                data-src="../../assets/img/user-upgrade/qr-store-check-symbol.svg"></td>
              </tr>
              </tbody>
            </table>
          </article>
        </div>
      </div>
    </div>
  </div>


  <div bsModal #paymentSuccess="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog" role="document"
        style="position: absolute;top: 0;bottom: 40px;left: 0;right: 0;margin: auto;height: fit-content;">
      <div class="modal-content" style="width: 400px;">

        <div class="modal-body p-4">

          <h4>
            Payment successful <i class="fas fa-check-circle" style="color: #41764f;"></i>
          </h4>

          <div class="mt-4">
            Payment successful. Please wait while we set up your account.
          </div>
        </div>
      </div>
    </div>
  </div>


  <div bsModal #paymentFailure="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog" role="document"
        style="position: absolute;top: 0;bottom: 40px;left: 0;right: 0;margin: auto;height: fit-content;">
      <div class="modal-content" style="width: 400px;">

        <div class="modal-body p-4">

          <h4 [innerHTML]="'Payment failed'">
          </h4>

          <div class="mt-4" [innerHTML]="'Your account upgrade was unsuccessful. Please try again'">
          </div>

          <div style="padding-top: 32px">
            <span style="float: right; display: flex; flex-direction: row">
              <span>
                <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [label]="'Continue'" (onClick)="paymentFailure.hide()"></beaconstac-button>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-dbc-renew-component *ngIf="currentDashboard === DASHBOARD_MENU.CARDS"></app-dbc-renew-component>

<div bsModal #trialSelectModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="width: 400px;">
            <div class="modal-body p-0" style="background: #A8D5FF;">
                <img style="width: 400px;"
                     [src]="TrialInfo.Product === 'QR Codes'
                     ? '../../assets/img/upsell-images/qr-discovery-image.png'
                     : '../../assets/img/upsell-images/dbc-discovery-image.png'" alt="">
            </div>
            <div class="modal-body p-4">

                <h4 class="trial-modal-heading">
                    <strong style="font-style: italic">{{TrialInfo.Heading}}</strong>
                </h4>

                <div class="trial-modal-description mt-3">
                    {{TrialInfo.Description}}
                </div>

                <div class="d-flex flex-column mt-2">
                    <span class="pb-2" *ngFor="let val of TrialInfo.Details; let i = index">
                        <i class="fas fa-check pr-4" style="color: #26B969"></i>
                        <span class="dbc-trial-options">{{val}} </span>
                    </span>
                </div>

                <div style="padding-top: 32px;">
                <span style="float: right; display: flex; flex-direction: row">
                    <span style="padding-top: 6px; margin-right: 8px;">
                      <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [style]="BUTTON_STYLES.TEXT"
                                         (onClick)="trialSelectModal.hide()" [label]="'Skip'"></beaconstac-button>
                    </span>
                    <span style="padding-left: 16px">
                      <beaconstac-button [width]="'140px'" (onClick)="handleTrialCreateModal()" [label]="'Start Trial'"></beaconstac-button>
                    </span>
                </span>
                </div>

            </div>
        </div>
    </div>
</div>

<div bsModal #invoiceModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog modal-dialog-centered modal-info modal-md" role="document">
        <div class="modal-content">

            <div class="modal-body" style="max-height: 70vh; overflow: scroll; padding: 30px;">
                <h4 style="color: #16212F; font-size: 20px; margin-bottom: 24px">Your invoice is due for payment</h4>

                <span class="d-flex justify-content-center align-items-center py-2"
                      *ngIf="pendingInvoices.length === 0">
                    <spinner class="custom-spinner-analytics" [size]="30" [tickness]="2">
                    </spinner>
                </span>

                <span *ngIf="pendingInvoices.length > 0">
                    <div class="mb-3">
                    Your invoice number <b>{{pendingInvoices.length > 0 ? pendingInvoices[0]['number'] : ''}}</b> is past due. Your dashboard access has been temporarily blocked.
                    Please pay the invoice to continue using your QR Codes.
                </div>

                <div class="mb-3">
                    If you have any questions, feel free to reach out to support@uniqode.com.
                </div>

                <div style="padding-top: 24px">
                    <div class="d-flex flex-row justify-content-end">
                  <span style="padding-top: 8px">
                    <beaconstac-button (onClick)="copyInvoicePaymentLink()"
                                       [style]="BUTTON_STYLES.TEXT"
                                       [label]="'Copy Payment Link'"></beaconstac-button>
                  </span>
                        <span style="padding-left: 16px">
                    <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" (onClick)="payInvoice();" [label]="'Pay Now'">
                    </beaconstac-button>
                  </span>
                    </div>
                </div>
                </span>
            </div>
        </div>
    </div>
</div>
