import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    AfterContentInit
} from '@angular/core';
import {debounceTime } from 'rxjs/operators';
import {Subject} from 'rxjs';
import {AuthService} from '../../global-services/auth.service';
import {User} from '../../user-account/user.model';
import {Animations} from '../../shared/beaconstac-animations';

@Component({
    selector: 'app-bulk-csv-validation',
    templateUrl: './bulk-csv-validation.component.html',
    styleUrls: ['./bulk-csv-validation.component.scss'],
    animations: [Animations.collapse]
})
export class BulkCsvValidationComponent implements OnInit, AfterContentInit, OnDestroy {

    @Input() errorObject: any = {};
    @Input() csvData: Array<any> = [];
    @Output() dataChange: EventEmitter<Array<object>> = new EventEmitter();

    ngUnsubscribe: Subject<any> = new Subject();
    user: User;

    headers: Array<string>;
    body: Array<any>;
    error_rows: Array<number>;
    errors: Array<object>;

    fieldInputSubject = new Subject<any>();

    editField: {row: number, column: number} = {row: null, column: null};

    csvErrorData: Array<object> = [];

    constructor(private authService: AuthService) {
        this.user = this.authService.getUser();
    }

    ngOnInit() {
        this.csvErrorData = []
        this.setupErrorData()
    }

    ngAfterContentInit(): void {
        this.fieldInputSubject.pipe(debounceTime(800)).subscribe(value => {
            this.dataChange.emit(this.csvData);
        });
    }

    setupErrorData() {
        // @ts-ignore
        this.headers = Object.keys(this.csvData[0]);
        this.headers = this.headers.filter(val => val !== 'row');
        // @ts-ignore
        this.error_rows = this.errorObject.error_rows
        // @ts-ignore
        this.errors = this.errorObject.errors

        for (const [index, data] of this.csvData.entries()) {
            if (this.error_rows.includes(index + 2)) {
                data['row'] = index;
                this.csvErrorData.push(data);
            }
        }
    }

    onFieldUpdated(value, row, key) {
        this.fieldInputSubject.next(this.csvData[row][key]);
    }

    getErrorFieldData(row, column, data) {
        if (data) {
            return data.length <= 25 ? data : data.slice(0, 25) + '...';
        }
        for (const err of this.errors) {
            if (err['row'] === row && err['column'] === column) {
                return err['message'].length <= 25 ? err['message'] : err['message'].slice(0, 25) + '...';
            }
        }
    }

    getErrorMessage(row, column) {
        for (const err of this.errors) {
            if (err['row'] === row && err['column'] === column) {
                return err['message'];
            }
        }
    }

    setEditField(row, column) {
        this.editField = {row: row, column: column};
        setTimeout(() => {
            document.getElementById(`csv-${row}-${column}`).focus();
        }, 0);
    }

    resetEditField() {
        const editedFieldId = `csv-field-${this.editField.row}-${this.editField.column}`;
        this.editField = {row: null, column: null};
        // Mark edited fields differently
        setTimeout(() => {
            const editedFieldElement = document.getElementById(editedFieldId);
            editedFieldElement.style.color = '#A2A2A2';
        }, 0);
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        if (this.fieldInputSubject) {
            this.fieldInputSubject.unsubscribe();
        }
    }
}

