import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
})
export class MapComponent implements OnInit {
    constructor() {}

    @Input() center: google.maps.LatLngLiteral;
    @Input() markerOptions: google.maps.MarkerOptions;
    @Input() agmClusterStyleGPS: Array<any>;
    @Input() agmClusterStyleIP: Array<any>;
    @Input() gpsLocationData: Array<any>;
    @Input() ipLocationData: Array<any>;
    @Input() locationData: Object;
    @Input() addMarkerCluster: boolean;
    @Input() radius: number;
    @Input() addCircle: boolean = false;
    @Input() height: string = '24.4rem';
    @Output() onMapDragEnd = new EventEmitter<object>();
    @Output() onMapClick = new EventEmitter<object>();

    ngOnInit(): void {}

    onMapDragEnded($event) {
        const newCoordinates = {
            lat: $event.latLng.lat(),
            lng: $event.latLng.lng(),
        };
        this.onMapDragEnd.emit(newCoordinates);
    }

    onMapClicked($event) {
        const newCoordinates = {
            lat: $event.latLng.lat(),
            lng: $event.latLng.lng(),
        };
        this.onMapClick.emit(newCoordinates);
    }
}
