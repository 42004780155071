import { environment } from '../../environments/environment';
import { socialMediaNamesArray , socialIconsFontClasses} from 'app/shared/social-media';
import {LanguageService} from '../global-components/notification-language/language.service';
import { getInvertedColor, getCustomColoredSocialIconSvg } from 'app/shared/utils';
import { getAddToContactIconColor, getLeadFormHtml, getStickyShareContactBtn, setTypography } from './templateUtils';
import {CONTACT_DETAIL} from '../digital-business-card/digital-business-card-utils';
// import { Utils } from 'app/shared/utils';
function isHexColorCode(hex) {
    return /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);
}

function hexToRGBA(hex, opacity) {
    hex = hex.trim();
    if (!isHexColorCode(hex)) {
        return hex;
    }
    return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16)
    }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
}
function mixWhite(hex) {
    // Convert hex to RGB
    const rgb = hexToRgb(hex);

    // Mix 20% white
    const mixedRgb = {
        r: Math.round(rgb.r * 0.9 + 255 * 0.1),
        g: Math.round(rgb.g * 0.9 + 255 * 0.1),
        b: Math.round(rgb.b * 0.9 + 255 * 0.1)
    };

    // Return the result in rgba format
    return `rgba(${mixedRgb.r}, ${mixedRgb.g}, ${mixedRgb.b}, 1)`;
}
function hexToRgb(hex) {
    // Remove the hash if present
    hex = hex.replace('#', '');

    // Parse the r, g, b values
    const bigint = parseInt(hex, 16);
    /* eslint-disable no-bitwise */
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    /* eslint-enable no-bitwise */

    return { r, g, b };
}

function populateDropdown(card, multiLangList, currentLanguage) {
    let dropDownHTML = `<div class="language-dropdown"><div class="beaconstac-dbc-language-dropdown-container" id="language-dropdown-container">
    <select class="beaconstac-dbc-language-dropdown"  name="language-dropdown" id="language-selector-1" autocomplete="off" disabled>`

    const languageService = new LanguageService();
    const languages = languageService.getLanguagesByCodes(multiLangList);
    const currentLanguageName = languageService.getLanguageByCode(currentLanguage);
    languages.forEach((language) => {
        const isSelected = language === currentLanguageName ? 'selected' : '';

        dropDownHTML += `<option value="${language}" ${isSelected}>${language}</option>`;
    });
    dropDownHTML += `</select>
    <svg class="arrow" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
        <path d="M6.41843 8.87328L1.92793 4.42286C1.71136 4.20822 1.71136 3.86023 1.92793 3.64562L2.45168 3.12655C2.66788 2.91228 3.01828 2.91187 3.23499 3.12563L6.81056 6.65267L10.3861 3.12563C10.6028 2.91187 10.9532 2.91228 11.1694 3.12655L11.6932 3.64562C11.9097 3.86026 11.9097 4.20824 11.6932 4.42286L7.20269 8.87328C6.98612 9.0879 6.635 9.0879 6.41843 8.87328Z" fill="white"/>
    </svg>
</div></div>`;
    return dropDownHTML;
}

// @ts-ignore
function buildSocialIcons(card, isFewLinks) {
    const iconColor = getInvertedColor(card.customizations.icon_color);
    const style = isFewLinks
        ? 'style="display: flex; justify-content: space-around;"' : 'style=""';
    let html = `<div class="social-icons" ${style}>`;
    for (const name of socialMediaNamesArray) {
        if (!card.social_links[name]) {
            continue;
        }

        if (socialIconsFontClasses[name]) {
            html += `<div class="svg-container" style="background-color: ${card.customizations.icon_color}"><i class="${socialIconsFontClasses[name]}" style="color: ${iconColor}; margin-top:12px; font-size:20px;"></i></div>`;
        } else {
            const svgIconCode = getCustomColoredSocialIconSvg(name, card.customizations.icon_color, iconColor);
            html += `<div class="svg-container" style="background-color: ${card.customizations.icon_color}; padding-top:15px;">`;
            html += svgIconCode;
            html += '</div>';

        }
    }
    html += '</div>';

    return html;
}
export function layout9TemplateHtml(card, isMobile, isMultiLanguageContent = false, multiLangList?, currentLanguage?) {

    let name = card.first_name;
    const isMobileDevice = window.innerWidth <= 672;
    const addToContactIconColor = getAddToContactIconColor(card.customizations.typography);

    if (card.last_name) {
        name += ' '
        name += card.last_name;
    }
    if (card.prefix) {
        name = card.prefix + ' ' + name
    }
    if (card.suffix) {
        name += ', ' + card.suffix
    }
    const socialLinkCount = Object.values(card.social_links).filter(link => link).length;
    const isFewLinks = socialLinkCount <= 4;

    let multiLangDropDownHTML = '';
    console.log(multiLangDropDownHTML);
    if (isMultiLanguageContent) {
        multiLangDropDownHTML = populateDropdown(card, multiLangList, currentLanguage);
    } else {
        multiLangDropDownHTML = '';
    }


    let headHtmlCode =
        `<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta http-equiv="X-UA-Compatible" content="ie=edge">
  <title>${name}</title>
  <link rel="stylesheet" href="${environment.vCardPlusCssEndpoint}/vcard_plus_layout-9.css?v=4.0" />
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
  integrity="sha512-1ycn6IcaQQ40/MKBW2W4Rhis/DbILU74C1vSrLJxCq57o941Ym01SwNsOMqvEBFlcgUa6xLiPY/NS5R+E6ztJQ=="
  crossorigin="anonymous" referrerpolicy="no-referrer" />
  <link rel="stylesheet" href="${environment.multiLanguageDropdownCssEndpoint}styles.css?v=3.0" />
  <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Work+Sans">
  <script src="https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js"></script>`


    if (card.logo_url) {
        headHtmlCode +=
            `<link rel="icon" type="image/x-icon" href="${card.logo_url}" id="fav_icon" />
    <link rel="shortcut icon" type="image/x-icon" href="${card.logo_url}" />`
    } else {
        headHtmlCode +=
            `<link rel="icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" id="fav_icon" />
    <link rel="shortcut icon" type="image/x-icon" href="https://d3nvy39jvu7woe.cloudfront.net/static/images/generic/favicon.ico" />`
    }

    let styleCode = '';
    styleCode += setTypography(card);

    headHtmlCode += `<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
    <style>
    html{
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .main-container {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    html::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .main-container::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .secondary_data_label {
        opacity:0.5;
    }
    #language-selector-1: disabled{
        border-radius: 4px;
        background-color: rgba(0,0,0,0.4);
        border: 0px;
        color: #FFF;
        text-align: center;
        font-family: Work Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px;
        padding-right: 16px;
        position: relative;
        top: 16px;
    }
    .logo_resize {
      display: flex;
      justify-content: center;
      margin-top:-12%;
      margin-bottom:24px;
    }

    .initials_custom_styles {
        background-color: ${hexToRGBA(card.customizations.background_color, 0.2)};
        color: ${card.customizations.background_color};
        font-family: ${card.customizations.font_style};
    }
    .main_container{
        background-color:${hexToRGBA('#ffffff', 0.3)};
        backdrop-filter:blur(13px);
        -webkit-backdrop-filter: blur(13px);
        width:100%;
        border:5px solid ${mixWhite(card.customizations.background_color)};
        border-top-left-radius:8px;
        border-top-right-radius:8px;
    }
    .main_container_child {
        position: static;
        width: 100%;
    }
    .container-padding{
        padding-left:10px;
        padding-right:10px;
        position:relative;
        display:flex;
        flex-direction:column;
        // height:auto !important;
        scrollbar-width:none;
    }
    .main_content{
        padding:24px 16px 1px 16px;
        height:auto;
        overflow-y:visible;
    }
    .contact_container{
        width:91%;
        margin:0px 12px;
    }
    .contact_info{
        border:1px solid ${hexToRGBA(card.customizations.icon_color, 0.2)};
       border-radius:4px;
       padding:8px 16px;
       margin-bottom:8px;
    }
    #leadModalShareBtn {
        width: 50px;
        height: 50px;
        margin: 30px;
        position: ${isMobileDevice ? 'absolute' : 'fixed'};
        bottom: ${isMobileDevice ? 'calc(0px - 6%);' : 'calc(0px - 1%)'};
        right:${isMobileDevice ? 'calc(0px - 4%);' : 'calc(0px - 6%)'};
        border-radius: 99px;
        background-color:white;
        cursor: pointer;
    }
    .social-icons {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
        column-gap: 1rem;
        row-gap: 1rem;
        margin-top: 24px;
        margin-bottom: 24px;
        max-width: calc(100% - 1.4rem);
        margin-left: auto;
        margin-right: auto;

        .svg-icon-container {
            height: 40px;
            width: 40px;
            min-width: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    #IconSizeandMargin {
        margin-top:10px;
        font-size:25px;
    }
    #content-container {
        background-size:cover;
        background-position:center;
    }
    .btn-contact {
        color:unset;
    }

    .fixed-btn-container {
        left: 0;
    }
    </style>
  </head>`

    headHtmlCode += styleCode;
    let html = `<!DOCTYPE html>
  <html lang="en">
  ${headHtmlCode}

  <body style="margin: 0;" id="body">

    <div class="content" style="height: 100%;">
      <div id="content-container" class="container overflow-x-hidden container-padding" style="${isMobile ? '' : 'zoom: 0.7'} ; height : calc(160vh - 42px);">`

    html += `<div style="background-color:transparent; margin-top: ${card.logo_url ? '58px' : '20px'}; padding-top:${ (card.logo_url && isMobileDevice) ? '30px' : '0px'}">`
    if (card.logo_url && !card.logo_size) {
        html += `<span class="logo_resize">
                <img style="max-height: 56px; max-width: 115px ;'};" src="${card.logo_url}">
             </span>`
    } else if ( card.logo_url && card.logo_size >= 0) {
        html += `<div class="logo_resize">
                  <img style="width: ${card.logo_size}%; height: 100%" src="${card.logo_url}">
                </div>`
    }

    html += '</div>'
    html += '<div class="main_container">'
    html += `<div class="main_container_child" style="background-color: ${hexToRGBA(card.customizations.background_color, 0.6)};">`;
    html += '<div class="main_content">'


    html += `<div style="display: flex; flex-direction: column; padding:2px 24px; justify-content:center;">
              <div class="user_name personal_info" style="line-height: 33px; text-align: center; word-break:break-word;">${name}
              </div>`

    if (card.pronouns_v2) {
        html += `<div style="text-align:center; line-height:16px;">
                    <span class="user_name personal_info pronunciation"
                      style="text-align: center;">(${card.pronouns_v2})
                    </span>
                 </div>`
    }
    html += '</div>'
    html += '<div style="margin-top:24px;">'
    if (card.designation) {
        html += `<div class="user_designation company_details" style="margin-top: 16px; text-align: center;">${card.designation}</div>`
    }

    if (card.company) {
        if (card.department) {
            html += `<div class="user_designation company_details" style=" margin-top:4px; text-align: center;">
            <div>${card.company}</div>
            <div style="margin-top:4px;">${card.department}</div>
         </div>`;
        } else {
            html += `<div class="user_designation company_details" style="margin-top: 4px; text-align: center;">${card.company}</div>`
        }
    } else if (card.department) {
        html += `<div class="user_designation company_details" style="margin-top:4px; text-align: center; center;">${card.department}</div>`
    }

    html += '</div>'

    html += '<div class="contact-container position-relative" style="margin-top:24px !important;">'

    if (card.summary) {
        html += `<div class="flex-center summary-container" style="justify-content:center;">
  <div style="text-align: center; white-space: pre-line ; word-break: break-word;
  padding: unset !important;"
  class="summary bio secondary_data">${card.summary}</div>
  </div>`
    }

    html += '</div>'

    html += buildSocialIcons(card, isFewLinks);

    html += '<div style="margin-top:24px">'

    card.__contact_info_list__.forEach((contact) => {
        if (contact.value.length > 0) {
            switch (contact.key) {
                case CONTACT_DETAIL.PHONE_V2:
                    contact.value.forEach(phone => {
                        if (phone.value.trim() !== '') {
                            html += `<div class="d-flex word-break contact_info">
                                           <div class="flex-center">
                                               <div style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; " class="svg-container d-flex flex-center">
                                                   <svg width="22" height="22" viewBox="0 0 22 22"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21.4296 16.1327L17.0546 14.2577C16.8677 14.178 16.6599 14.1612
                                            16.4627 14.2098C16.2654 14.2585 16.0893 14.3698 15.9608 14.5272L14.0233
                                            16.8944C10.9825 15.4607 8.53535 13.0136 7.10165 9.97283L9.46888 8.0353C9.62657
                                            7.90704 9.73818 7.73091 9.78681 7.53355C9.83545 7.33619 9.81846 7.12837 9.73842
                                            6.94153L7.86338 2.56645C7.77554 2.36505 7.62016 2.2006 7.42406 2.10148C7.22796
                                            2.00236 7.00341 1.97477 6.78915 2.02347L2.72658 2.96099C2.52 3.00869 2.33569
                                            3.12501 2.20373 3.29095C2.07177 3.45689 1.99995 3.66266 2 3.87468C2 13.8944
                                            10.1212 22 20.1253 22C20.3374 22.0001 20.5433 21.9284 20.7093 21.7964C20.8753
                                            21.6644 20.9917 21.4801 21.0394 21.2734L21.9769 17.2108C22.0253 16.9955 21.9971
                                            16.7701 21.8972 16.5733C21.7973 16.3765 21.632 16.2207 21.4296 16.1327Z"
                                                      fill="${card.customizations.icon_color}"/>
                                            </svg>
                                               </div>
                                           </div>
                                       <div class="pl-12 d-flex align-items-center">
                                            <div class="d-flex flex-column">`
                            if (phone.label) {
                                html += `<p class="sub-title contact_details secondary_data_label mt-5">${phone.label.charAt(0).toUpperCase() + phone.label.slice(1)}</p>`
                            }
                            html += `<a class="secondary_data contact_details">
                                                ${phone.value}
                                              </a>`
                            html += '</div></div></div>'
                        }
                    })
                    break;
                case CONTACT_DETAIL.EMAIL_V2:
                    contact.value.forEach(email => {
                        if (email.value.trim() !== '') {
                            html += `
                                    <div class="d-flex word-break contact_info">
                                        <div class="flex-center">
                                             <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                <svg width="24" height="24" viewBox="0 0 24 24"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path xmlns="http://www.w3.org/2000/svg" d="M21.6211 9.45312C21.7734 9.33203
                                                    22 9.44531 22 9.63672V17.625C22 18.6602 21.1602 19.5 20.125
                                                    19.5H3.875C2.83984 19.5 2 18.6602 2 17.625V9.64062C2 9.44531
                                                    2.22266 9.33594 2.37891 9.45703C3.25391 10.1367 4.41406 11 8.39844
                                                    13.8945C9.22266 14.4961 10.6133 15.7617 12 15.7539C13.3945 15.7656
                                                    14.8125 14.4727 15.6055 13.8945C19.5898 11 20.7461 10.1328 21.6211
                                                    9.45312ZM12 14.5C12.9062 14.5156 14.2109 13.3594 14.8672 12.8828C20.0508
                                                    9.12109 20.4453 8.79297 21.6406 7.85547C21.8672 7.67969 22 7.40625
                                                    22 7.11719V6.375C22 5.33984 21.1602 4.5 20.125 4.5H3.875C2.83984
                                                    4.5 2 5.33984 2 6.375V7.11719C2 7.40625 2.13281 7.67578 2.35938
                                                    7.85547C3.55469 8.78906 3.94922 9.12109 9.13281 12.8828C9.78906
                                                    13.3594 11.0938 14.5156 12 14.5Z" fill="${card.customizations.icon_color}"/>
                                                </svg>
                                             </div>
                                        </div>
                                        <div class="pl-12 d-flex align-items-center">
                                            <div class="d-flex flex-column">`
                            if (email.label && email.label.length) {
                                html += `<p class="sub-title contact_details secondary_data_label mt-5">${email.label.charAt(0).toUpperCase() + email.label.slice(1)}</p>`
                            }
                            html += `<a class="secondary_data contact_details">
                                    ${email.value}
                                  </a>`
                            html += '</div></div></div>'
                        }
                    });
                    break;
                case CONTACT_DETAIL.WEBSITE_V2:
                    contact.value.forEach(website => {
                        if (website.value.trim() !== '') {
                            html += `<div class="d-flex word-break contact_info">
                                        <div class="flex-center">
                                            <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                <svg width="24" height="24" viewBox="0 0 20 21"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path xmlns="http://www.w3.org/2000/svg" d="M13.5706 6.12903C12.9859
                                    2.52823 11.6069 0 10.0021 0C8.39724 0 7.0182 2.52823 6.43353
                                    6.12903H13.5706ZM6.13111 10C6.13111 10.8952 6.17949 11.754 6.26417
                                    12.5806H13.7359C13.8206 11.754 13.869 10.8952 13.869 10C13.869 9.10484
                                    13.8206 8.24597 13.7359 7.41935H6.26417C6.17949 8.24597 6.13111 9.10484
                                    6.13111 10ZM19.2239 6.12903C18.0706 3.39113 15.7359 1.27419 12.8529
                                    0.419355C13.8368 1.78226 14.5142 3.83468 14.869 6.12903H19.2239ZM7.14724
                                    0.419355C4.2682 1.27419 1.92949 3.39113 0.780301 6.12903H5.13514C5.48595
                                    3.83468 6.16337 1.78226 7.14724 0.419355ZM19.6553 7.41935H15.0303C15.115
                                    8.26613 15.1634 9.13306 15.1634 10C15.1634 10.8669 15.115 11.7339 15.0303
                                    12.5806H19.6513C19.873 11.754 19.998 10.8952 19.998 10C19.998 9.10484 19.873
                                    8.24597 19.6553 7.41935ZM4.84079 10C4.84079 9.13306 4.88917 8.26613 4.97385
                                    7.41935H0.348849C0.131107 8.24597 0.0020752 9.10484 0.0020752 10C0.0020752
                                    10.8952 0.131107 11.754 0.348849 12.5806H4.96982C4.88917 11.7339 4.84079 10.8669
                                    4.84079 10ZM6.43353 13.871C7.0182 17.4718 8.39724 20 10.0021 20C11.6069 20 12.9859
                                    17.4718 13.5706 13.871H6.43353ZM12.8569 19.5806C15.7359 18.7258 18.0747 16.6089
                                    19.2279 13.871H14.873C14.5182 16.1653 13.8408 18.2177 12.8569 19.5806ZM0.780301
                                    13.871C1.93353 16.6089 4.2682 18.7258 7.15127 19.5806C6.1674 18.2177 5.48998
                                    16.1653 5.13514 13.871H0.780301Z" fill="${card.customizations.icon_color}"/>
                                    </svg>

                                            </div>
                                        </div>
                                        <div class="pl-12 d-flex align-items-center">
                                            <div class="d-flex flex-column">`
                            if (website.label) {
                                html += `<p class="sub-title contact_details secondary_data_label mt-5">${website.label.charAt(0).toUpperCase() + website.label.slice(1)}</p>`
                            }
                            html += `<a class="secondary_data contact_details">
                                    ${website.value}
                                  </a>`
                            html += '</div></div></div>'
                        }
                    });
                    break;
                case CONTACT_DETAIL.CUSTOM_FIELDS:
                    card.custom_fields.forEach(custom_field => {
                        if (custom_field.value.trim() !== '') {
                            html += `<div class="d-flex word-break contact_info">
                                          <div class="flex-center">
                                              <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                                <svg style="padding-left: 2px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M9.03125 18.5714H9.8125V12.9286H9.03125C8.59977 12.9286 8.25 12.5788 8.25 12.1473V10.2812C8.25 9.84977 8.59977 9.5 9.03125 9.5H13.4062C13.8377 9.5 14.1875 9.84977 14.1875 10.2812V18.5714H14.9688C15.4002 18.5714 15.75 18.9212 15.75 19.3527V21.2188C15.75 21.6502 15.4002 22 14.9688 22H9.03125C8.59977 22 8.25 21.6502 8.25 21.2188V19.3527C8.25 18.9212 8.59977 18.5714 9.03125 18.5714ZM12 2C10.4467 2 9.1875 3.25918 9.1875 4.8125C9.1875 6.36582 10.4467 7.625 12 7.625C13.5533 7.625 14.8125 6.36582 14.8125 4.8125C14.8125 3.25918 13.5533 2 12 2Z" fill="${card.customizations.icon_color}" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                              </div>
                                          </div>
                                          <div class="pl-12 d-flex align-items-center">
                                              <div class="d-flex flex-column">`
                            if (custom_field.label) {
                                html += `<p class="sub-title contact_details secondary_data_label mt-5">${custom_field.label.charAt(0).toUpperCase() + custom_field.label.slice(1)}</p>`
                            }
                            html += `<a class="secondary_data contact_details">
                                    ${custom_field.value}
                                  </a>`
                            html += '</div></div></div>'
                        }
                    });
                    break;
            }
        }
    })

    if (card.address_v2) {
        const tempAddress = card.address_v2.replace(/ +\n/g, '\n').replace(/\n +/g, '\n').trim();
        html += `<div class="d-flex word-break contact_info">
            <div class="flex-center">
            <div class="svg-container d-flex flex-center" style="background-color : ${hexToRGBA(card.customizations.icon_color, 0.2)} ; ">
                                <svg width="21" height="25" style="margin-right: 2px;"
                                     viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path xmlns="http://www.w3.org/2000/svg" d="M11.2292 21.5965C5.55352 13.3684
                                    4.5 12.5239 4.5 9.5C4.5 5.35785 7.85785 2 12 2C16.1421 2 19.5 5.35785
                                    19.5 9.5C19.5 12.5239 18.4465 13.3684 12.7708 21.5965C12.3983 22.1345
                                    11.6016 22.1345 11.2292 21.5965ZM12 12.625C13.7259 12.625 15.125 11.2259
                                    15.125 9.5C15.125 7.7741 13.7259 6.375 12 6.375C10.2741 6.375 8.875 7.7741 8.87
                                    9.5C8.875 11.2259 10.2741 12.625 12 12.625Z" fill="${card.customizations.icon_color}"/>
                                </svg>
            </div>
            </div>
            <div class="pl-12">
                <p class="sub-title contact_details secondary_data_label">Address</p>
              <span style="word-break: break-word;" class="secondary_data contact_details">
                ${tempAddress}
              </span>

            </div>
          </div>`
    }

    if (!card.__show_new_sticky_btn__) {
        if (card.lead_collection) {
            html += `<div id="leadModalBtn" class="flex-center" style="border: 2px solid ${card.customizations.button_color};">
                        <div class="flex-center button" style="cursor: pointer; color: ${card.customizations.button_color};">
                            Share your contact
                        </div>
                    </div>
            `
        }
    }



    html += '</div>'

    html += `
    </div>
    </div>
    </div>`;

    if (!card.__show_new_sticky_btn__) {
        html += `<div id="leadModalShareBtn" class="flex-center" style="background-color: ${card.customizations.button_color}; border: 2px solid ${card.customizations.button_color};">
        <svg class="button" width="100" height="100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left:8px; margin-top:8px;">
            <path id="Vector" d="M10.2766 10.383C9.51554 10.383 9.14959 10.8006 7.9006 10.8006C6.65161 10.8006 6.28832 10.383 5.5246 10.383C3.55698 10.383 1.9606 11.9544 1.9606 13.8913V14.5595C1.9606 15.2513 2.53074 15.8125 3.23346 15.8125H12.5677C13.2705 15.8125 13.8406 15.2513 13.8406 14.5595V13.8913C13.8406 11.9544 12.2442 10.383 10.2766 10.383ZM12.5677 14.5595H3.23346V13.8913C3.23346 12.6488 4.26235 11.6359 5.5246 11.6359C5.91176 11.6359 6.54024 12.0536 7.9006 12.0536C9.27157 12.0536 9.88679 11.6359 10.2766 11.6359C11.5389 11.6359 12.5677 12.6488 12.5677 13.8913V14.5595ZM7.9006 9.96532C10.0088 9.96532 11.7192 8.28165 11.7192 6.20642C11.7192 4.13119 10.0088 2.44751 7.9006 2.44751C5.79243 2.44751 4.08203 4.13119 4.08203 6.20642C4.08203 8.28165 5.79243 9.96532 7.9006 9.96532ZM7.9006 3.70048C9.3034 3.70048 10.4463 4.82554 10.4463 6.20642C10.4463 7.58729 9.3034 8.71235 7.9006 8.71235C6.49781 8.71235 5.35489 7.58729 5.35489 6.20642C5.35489 4.82554 6.49781 3.70048 7.9006 3.70048Z" fill="${addToContactIconColor}"/>
            <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M16.0681 7.84302C16.0681 7.73367 15.9794 7.64502 15.8701 7.64502H15.1523C15.043 7.64502 14.9543 7.73367 14.9543 7.84302V8.7464C14.9543 8.85575 14.8657 8.9444 14.7563 8.9444H13.853C13.7436 8.9444 13.655 9.03304 13.655 9.1424L13.655 9.86015C13.655 9.9695 13.7436 10.0581 13.853 10.0581H14.7563C14.8657 10.0581 14.9543 10.1468 14.9543 10.2561V11.1595C14.9543 11.2689 15.043 11.3575 15.1523 11.3575H15.8701C15.9794 11.3575 16.0681 11.2689 16.0681 11.1595V10.2561C16.0681 10.1468 16.1567 10.0581 16.2661 10.0581H17.1695C17.2788 10.0581 17.3675 9.9695 17.3675 9.86015V9.1424C17.3675 9.03304 17.2788 8.9444 17.1695 8.9444H16.2661C16.1567 8.9444 16.0681 8.85575 16.0681 8.7464V7.84302Z" fill="${addToContactIconColor}"/>
        </svg>
        </div>`
    }
    html += getLeadFormHtml(card);

    if (card.__show_new_sticky_btn__) {
        html += getStickyShareContactBtn(card);
    }

    html += `</div>
    </div>
    </body>
      </html>
      <script>

            (async function() {
                setCardBackground()
            })();
            function setCardBackground() {
                const container = document.getElementById('content-container');
                const backgroundType = '${card.customizations.background.type}';
                const backgroundValue = '${card.customizations.background.value}';

                if (backgroundType === 'color') {
                    container.style.backgroundColor = backgroundValue;
                } else {
                    container.style.backgroundImage = "url('" + backgroundValue + "')";
                }
            }
            document.addEventListener('DOMContentLoaded', function() {
                function adjustHeights() {
                    const container = document.querySelector('.container-padding');
                    const mainContainerChild = document.querySelector('.main_container_child');
                    const mainContainer = document.querySelector('.main_container');
                    const mainContent = document.querySelector('.main_content');
                    const containerHeight = container.offsetHeight;
                    const contentHeight = mainContent.scrollHeight;
                    if (containerHeight > contentHeight) {
                        mainContainer.style.height = 'inherit';
                        mainContainerChild.style.height = 'inherit';
                    } else {
                        mainContainer.style.height = '';
                        mainContainerChild.style.height = '';
                        container.style.height = 'auto';
                    }
                }

                adjustHeights();
                window.addEventListener('resize', adjustHeights);
            });
        </script>


  `


    return html;
}
