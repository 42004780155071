import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DigitalBusinessCardListComponent } from './digital-business-card-list/digital-business-card-list.component';
import { DigitalBusinessCardDetailComponent } from './digital-business-card-detail/digital-business-card-detail.component';
// import {ProductGuard} from '../guards/product.guard';
import {DigitalBusinessCardSettingComponent
} from './digital-business-card-setting/digital-business-card-setting.component';
import {DigitalBusinessCardBulkUploadComponent} from './digital-business-card-bulk-upload/digital-business-card-bulk-upload.component';
import {DBCProductGuard} from '../guards/dbc-product.guard';
import {DiscardChangesGuardV2} from '../guards/discard-changes-v2.guard';
import {
    DigitalBusinessCardTemplatesComponent
} from './digital-business-card-templates/digital-business-card-templates.component';
import {
    DigitalBusinessCardDesignLibraryComponent
} from './digital-business-card-design-library/digital-business-card-design-library.component';
import {CardsMultiOrgBulkUploadComponent} from './cards-multi-org-bulk-upload/cards-multi-org-bulk-upload.component';

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Digital Business Cards'
        },
        canActivateChild: [DBCProductGuard],
        children: [
            {
                path: '',
                data: {
                    title: ''
                },
                redirectTo: 'my-cards',
                pathMatch: 'full'
            },
            {
                path: 'my-cards',
                data: {
                    title: '',
                    data: { team: false }
                },
                component: DigitalBusinessCardListComponent,
            },
            {
                path: 'team-cards',
                data: {
                    title: '',
                    data: { team: true }
                },
                component: DigitalBusinessCardListComponent,
            },
            {
                path: 'add',
                data: {
                    title: 'Create Digital Business Card',
                },
                redirectTo: 'my-cards/add',
                pathMatch: 'full'
            },
            {
                path: 'my-cards/add',
                data: {
                    title: 'Create Digital Business Card',
                    data: { team: false }
                },
                component: DigitalBusinessCardDetailComponent,
                canDeactivate: [DiscardChangesGuardV2]
            },
            {
                path: 'team-cards/add',
                data: {
                    title: 'Create Digital Business Card',
                    data: { team: true }
                },
                component: DigitalBusinessCardDetailComponent,
                canDeactivate: [DiscardChangesGuardV2]
            },
            {
                path: 'layout',
                data: {
                    title: 'Select a layout'
                },
                redirectTo: 'my-cards/design-library',
                pathMatch: 'full'
            },
            {
                path: 'my-cards/layout',
                data: {
                    title: 'Select a layout',
                    data: { team: false }
                },
                redirectTo: 'my-cards/design-library'
            },
            {
                path: 'team-cards/layout',
                data: {
                    title: 'Select a layout',
                    data: { team: true }
                },
                redirectTo: 'team-cards/design-library'
            },
            {
                path: 'my-cards/edit/:id',
                data: {
                    title: '',
                    data: { team: false }
                },
                component: DigitalBusinessCardDetailComponent,
                canDeactivate: [DiscardChangesGuardV2]
            },
            {
                path: 'team-cards/edit/:id',
                data: {
                    title: '',
                    data: { team: true }
                },
                component: DigitalBusinessCardDetailComponent,
                canDeactivate: [DiscardChangesGuardV2]
            },

            {
                path: 'my-cards/summary/:id',
                data: {
                    title: 'Summary',
                    data: {
                        team: false
                    }
                },
                loadChildren: () => import('../digital-business-card/digital-business-card-analytics/digital-business-card-analytics.module').then(m => m.DigitalBusinessCardAnalyticsModule)
            },
            {
                path: 'team-cards/summary/:id',
                data: {
                    title: 'Summary',
                    data: {
                        team: true
                    }
                },
                loadChildren: () => import('../digital-business-card/digital-business-card-analytics/digital-business-card-analytics.module').then(m => m.DigitalBusinessCardAnalyticsModule)
            },
            {
                path: 'settings',
                data: {
                    title: 'Settings',
                    data: { team: false }
                },
                component: DigitalBusinessCardSettingComponent
            },
            {
                path: 'bulk-upload',
                data: {
                    title: 'Bulk Create'
                },
                component: DigitalBusinessCardBulkUploadComponent
            },
            {
                path: 'bulk-upload/v2',
                data: {
                    title: 'Multi Org Bulk Create'
                },
                component: CardsMultiOrgBulkUploadComponent
            },
            {
                path: 'templates/design-library',
                data: {
                    title: 'Select a design',
                    templates: true
                },
                component: DigitalBusinessCardDesignLibraryComponent,
            },
            {
                path: 'my-cards/design-library',
                data: {
                    title: 'Select a design',
                    templates: true
                },
                component: DigitalBusinessCardDesignLibraryComponent,
            },
            {
                path: 'team-cards/design-library',
                data: {
                    title: 'Select a design',
                    templates: true
                },
                component: DigitalBusinessCardDesignLibraryComponent,
            },
            {
                path: 'templates/add',
                data: {
                    title: 'Cards templates',
                    templates: true
                },
                component: DigitalBusinessCardDetailComponent,
                canDeactivate: [DiscardChangesGuardV2]
            },
            {
                path: 'templates/edit/:id',
                data: {
                    title: 'Cards templates',
                    templates: true
                },
                component: DigitalBusinessCardDetailComponent,
                canDeactivate: [DiscardChangesGuardV2]
            },
            {
                path: 'templates',
                data: {
                    title: 'Cards templates'
                },
                component: DigitalBusinessCardTemplatesComponent
            }
        ]
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class DigitalBusinessCardRoutingModule { }
