<div class="dbc-renew-component" >
<!-- dbc-pricing -->
<div [ngSwitch]="currentSection" *ngIf="currentDashboard == DASHBOARD_MENU.CARDS" class="w-100">
    <div *ngSwitchCase="UPGRADE_SECTION.Plan" style="width: 90%; margin: auto;" class="mb-3 bx--grid">
      <!-- header -->
      <div class="bx--row justify-content-center text-center mb-3">
        <div class="users-quantity" >How many people are on your team?
        </div>
      </div>
      <!-- slider contents -->
      <div class="bx--row slider-container" [ngClass]="showSliderErrorMessage ? 'mb-0' : 'mb-4'">
        <div class="d-flex align-items-center user-seat-change-slider justify-content-center">
          <div class="counter-container">
            <div class="counter-content d-flex">
              <div class="increment-decrement-button-planspage" id="decrement-button" (mouseup)="endDecrementLongPress()"
              (mouseleave)="endDecrementLongPress()" (mousedown)="startDecrementLongPress($event)">
                <beaconstac-button
                [type]="BUTTON_TYPES.SECONDARY"
                [style] = "BUTTON_STYLES.STROKE"
                (onClick) = "decrementCounter()"
                [icon]="'fas fa-minus'"
                [disabled]="dbcUserSeats <= minUsersToShow">
                </beaconstac-button>
              </div>
              <input id="user-seats-counter" type="number" name="counterInput" [(ngModel)]="dbcUserSeats" (input)="handleUserSeatChange($event.target.value)" class="counter" [min]="minUsersToShow">
              <div class="increment-decrement-button-planspage" id="increment-button" (mousedown)="startIncrementLongPress($event)"
              (mouseup)="endIncrementLongPress()"
              (mouseleave)="endIncrementLongPress()">
                <beaconstac-button
                [type]="BUTTON_TYPES.SECONDARY"
                [style] = "BUTTON_STYLES.STROKE"
                (onClick) = "incrementCounter()"
                [icon]="'fas fa-plus'">
                </beaconstac-button>
              </div>
            </div>
            <div class="users-text">
              users
            </div>
          </div>
        </div>
        <div class="py-4 d-flex flex-column flex-grow-1 align-items-center user-seats-price-month background-fafafa">
          <div *ngIf="dbcUserSeats > 49 && dbcUserSeats < 2500" class="qrcode-preview-btngroup d-flex justify-content-between align-items-center p-1">
            <input type="radio" id="first-2" class="first" name="section-2" value="0" [checked]="!showBusinessPlusFrame">
            <label class="d-flex align-items-center justify-content-center" for="first-2" (click)="togglePreview(true)" [ngClass]="!showBusinessPlusFrame? 'active-preview': ''" >
                <span class="toggle-text">BUSINESS</span>
            </label>
            <input type="radio" id="second-2" class="second" name="section-2" value="1" [checked]="showBusinessPlusFrame">
            <label class="d-flex align-items-center justify-content-center" for="second-2" (click)="togglePreview(false);" [ngClass]="showBusinessPlusFrame? 'active-preview': ''" >
                <span class="toggle-text">BUSINESS+</span>
            </label>
            <div class="slider" style="width: 99px; height: 34px;"></div>
          </div>
          <div>
            <span *ngIf="selectedDBCPlan != PLAN_TYPES.Enterprise"
              class="dbc-user-seat-price">${{dbcUserSeatsPrice}}</span>
            <span *ngIf="selectedDBCPlan === PLAN_TYPES.Enterprise" class="dbc-user-seat-price">Custom pricing</span>
          </div>
          <div>
            <span class="dbc-price-subtext" *ngIf="selectedDBCPlan != PLAN_TYPES.Enterprise">per month, billed annually</span>
          </div>
          <div class="pt-3">
          <beaconstac-button
                  [type]="BUTTON_TYPES.PRIMARY"
                  [label]="selectedDBCPlan === PLAN_TYPES.Enterprise ? 'CONTACT US' : 'BUY NOW'"
                  [disabled]="false"
                  (onClick)="onDBCPlanTypeClicked(selectedDBCPlan)" [width]="'200px'"
                  [style]="BUTTON_STYLES.FILLED">
          </beaconstac-button>
        </div>
        </div>
      </div>
      <div *ngIf="showSliderErrorMessage" class="bx--row alert alert-warning mb-4" [@collapse]>
            You currently have {{minUsersToShow}} user{{minUsersToShow > 1 ? 's ': ' '}} in your account.
      </div>

      <div class="bx--row mb-5 justify-content-center">
        <div class="bx--col-xlg-4 p-0" *ngIf="showDbcPlans.Solo">
          <div style="height:100%;" [ngClass]="selectedDBCPlan === PLAN_TYPES.Solo ? 'selected-plan-background' : ''" >
            <div style="height:100%;" class="background-fafafa plan-card-container p-4" [ngClass]="selectedDBCPlan === PLAN_TYPES.Solo
              ? 'selected-plan-padding-top min-height-480px bg-white' : ''">
              <div class="mb-4 text-center" style="position: relative; margin-top: 2rem;">
              </div>
              <div class="mb-4">
                <span class="dbc-solo-plan-tag">
                  Solo
                </span>
              </div>
              <div class="d-flex mb-4">
                <span class="dbc-plan-rate">
                  ${{DBC_PRICE_RATES.Solo}}
                </span>
                <span class="dbc-plan-subtext">
                  /user per month
                </span>
              </div>

              <div class="mb-4">
                <beaconstac-button
                  *ngIf="selectedDBCPlan != PLAN_TYPES.Solo"
                  (onClick)="selectDBCPlan(PLAN_TYPES.Solo)" [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'"
                  [style]="BUTTON_STYLES.STROKE" [label]="'SELECT'"></beaconstac-button>
                <beaconstac-button
                  *ngIf="selectedDBCPlan === PLAN_TYPES.Solo"
                  [type]="BUTTON_TYPES.PRIMARY"
                  [label]="'BUY NOW'"
                  [disabled]="false"
                  (onClick)="onDBCPlanTypeClicked(PLAN_TYPES.Solo)" [width]="'100%'"
                  [style]="BUTTON_STYLES.STROKE"></beaconstac-button>
              </div>

              <div class="mb-4">
                <i class="fas fa-user-friends user-friends-icon"></i> {{DBC_PLANS_FEATURES_DATA.Solo.noOfUsers}}
              </div>

              <div>
                <div class="mb-2 feature-include">
                  Includes
                </div>
                <div class="pl-1 bold-first-child">
                  <ng-container *ngFor="let includeData of DBC_PLANS_FEATURES_DATA.Solo.includes;">
                    <div class="mb-1 d-flex feature-include-data">
                      <i class="fas fa-check check-icon-blue"></i> <span>
                        {{includeData}}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bx--col-xlg-4 p-0" *ngIf="showDbcPlans.Team">
          <div style="height:100%;" [ngClass]="selectedDBCPlan === PLAN_TYPES.Team ? 'selected-plan-background' : ''">
            <div style="height:100%;" class="background-fafafa plan-card-container p-4" [ngClass]="selectedDBCPlan === PLAN_TYPES.Team
              ? 'selected-plan-padding-top min-height-480px bg-white' : ''">
              <div class="mb-4 text-center" style="position: relative; margin-top: 2rem;">
              </div>
              <div class="mb-4">
                <span class="dbc-team-plan-tag">
                  Team
                </span>
              </div>
              <div class="d-flex mb-4">
                <span class="dbc-plan-rate">
                  ${{DBC_PRICE_RATES.Team}}
                </span>
                <span class="dbc-plan-subtext">
                  /user per month
                </span>
              </div>

              <div class="mb-4">
                <beaconstac-button
                  *ngIf="selectedDBCPlan != PLAN_TYPES.Team"
                  (onClick)="selectDBCPlan(PLAN_TYPES.Team)" [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'"
                  [style]="BUTTON_STYLES.STROKE" [label]="'SELECT'"></beaconstac-button>
                <beaconstac-button
                  *ngIf="selectedDBCPlan === PLAN_TYPES.Team"
                  [type]="BUTTON_TYPES.PRIMARY"
                  [label]="'BUY NOW'"
                  [disabled]="false"
                  (onClick)="onDBCPlanTypeClicked(PLAN_TYPES.Team)" [width]="'100%'"
                  [style]="BUTTON_STYLES.STROKE"></beaconstac-button>
              </div>

              <div class="mb-4">
                <i class="fas fa-user-friends user-friends-icon"></i> {{DBC_PLANS_FEATURES_DATA.Team.noOfUsers}}
              </div>

              <div>
                <div class="mb-2 feature-include">
                  Includes
                </div>
                <div class="pl-1 bold-first-child">
                  <ng-container *ngFor="let includeData of DBC_PLANS_FEATURES_DATA.Team.includes;">
                    <div class="mb-1 d-flex feature-include-data">
                      <i class="fas fa-check check-icon-blue"></i> <span>
                        {{includeData}}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bx--col-xlg-4 p-0" *ngIf="showDbcPlans.Business">
          <div style="height:100%;" [ngClass]="selectedDBCPlan === PLAN_TYPES.Business ? 'selected-plan-background' : ''">
            <div style="height:100%;" class="background-fafafa plan-card-container p-4" [ngClass]="selectedDBCPlan === PLAN_TYPES.Business
              ? 'selected-plan-padding-top min-height-480px bg-white' : ''">
              <div class="mb-4 text-center" style="position: relative; margin-top: 2rem;">
              </div>
              <div class="mb-4">
                <span class="dbc-business-plan-tag">
                  Business
                </span>
              </div>
              <div class="d-flex mb-4">
                <span class="dbc-plan-rate">
                  ${{DBC_PRICE_RATES.Business}}
                </span>
                <span class="dbc-plan-subtext">
                  /user per month
                </span>
              </div>

              <div class="mb-4">
                <beaconstac-button
                  *ngIf="selectedDBCPlan != PLAN_TYPES.Business"
                  (onClick)="selectDBCPlan(PLAN_TYPES.Business)" [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'"
                  [style]="BUTTON_STYLES.STROKE" [label]="'SELECT'"></beaconstac-button>
                <beaconstac-button
                  *ngIf="selectedDBCPlan === PLAN_TYPES.Business"
                  [type]="BUTTON_TYPES.PRIMARY"
                  [label]="'BUY NOW'"
                  [disabled]="false"
                  (onClick)="onDBCPlanTypeClicked(PLAN_TYPES.Business)" [width]="'100%'"
                  [style]="BUTTON_STYLES.STROKE"></beaconstac-button>
              </div>

              <div class="mb-4">
                <i class="fas fa-user-friends user-friends-icon"></i> {{DBC_PLANS_FEATURES_DATA.Business.noOfUsers}}
              </div>

              <div>
                <div class="mb-2 feature-include">
                  Includes
                </div>
                <div class="pl-1 bold-first-child">
                  <ng-container *ngFor="let includeData of DBC_PLANS_FEATURES_DATA.Business.includes;">
                    <div class="mb-1 d-flex feature-include-data">
                      <i class="fas fa-check check-icon-blue"></i> <span>
                        {{includeData}}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bx--col-xlg-4 p-0">
          <div style="height:100%;" [ngClass]="selectedDBCPlan === PLAN_TYPES.Enterprise ? 'selected-plan-background' : ''">
            <div style="height:100%;" class="background-fafafa plan-card-container p-4" [ngClass]="selectedDBCPlan === PLAN_TYPES.Enterprise
              ? 'selected-plan-padding-top min-height-480px bg-white' : ''">
              <div class="mb-4" style="margin-top: 32px;">
                <span class="dbc-enterprise-plan-tag">
                  Business+
                </span>
              </div>

              <div class="d-flex mb-4" style="margin-top: 33px;">
                <span style="font-weight: 500;
              font-size: 24px;
              line-height: 28px;
              display: flex;
              align-items: flex-end;
              color: #16212F;
              ">
                  Talk to sales
                </span>
              </div>

              <div class="mb-4">
                <beaconstac-button *ngIf="selectedDBCPlan != PLAN_TYPES.Enterprise"
                  (onClick)="selectDBCPlan(PLAN_TYPES.Enterprise)" [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'"
                  [style]="BUTTON_STYLES.STROKE" [label]="'SELECT'"></beaconstac-button>
                <beaconstac-button *ngIf="selectedDBCPlan === PLAN_TYPES.Enterprise" (onClick)="onEnterpriseSelected()"
                  [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'" [style]="BUTTON_STYLES.STROKE"
                  [label]="'CONTACT US'"></beaconstac-button>
              </div>

              <div class="mb-4">
                <i class="fas fa-user-friends user-friends-icon"></i> {{DBC_PLANS_FEATURES_DATA.BusinessPlus.noOfUsers}}
              </div>

              <div>
                <div class="mb-2 feature-include">
                  Includes
                </div>
                <div class="pl-1 bold-first-child">
                  <ng-container *ngFor="let includeData of DBC_PLANS_FEATURES_DATA.BusinessPlus.includes;">
                    <div class="mb-1 d-flex feature-include-data">
                      <i class="fas fa-check check-icon-blue"></i> <span>
                        {{includeData}}
                      </span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- view more plans -->
      <div>
        <div class="bx--row justify-content-center align-items-center mb-5 view-more-plans-header">
          <span class="cursor-pointer" (click)="redirectToStore(DASHBOARD_MENU.CARDS)" [attr.aria-expanded]="!isFullCollapsed"
            aria-controls="isFullCollapsed">Learn more
            <i *ngIf="!isFullCollapsed" class="pl-2 fas fa-caret-up fa-lg" aria-hidden="true"></i>
          </span>

        </div>
        <div id="isFullCollapsed" [collapse]="isFullCollapsed" [@collapse]>
          <div class="d-lg-down-none">
            <div class="bx--row bg-white" style="padding: 2rem;">
              <div class="bx--col-lg-4"></div>
              <div class="bx--col-lg-3">
                <div class="mb-3">
                  <span class="dbc-solo-plan-tag">
                    Solo
                  </span>
                </div>
                <div class="mb-2">
                  <b>${{DBC_PRICE_RATES.Solo}}</b> <span>/user/month</span>
                </div>
                <div *ngIf="
              isOnHigherDBCPlanSolo" class="d-flex align-items-start">
                  <beaconstac-button
                    *ngIf="(selectedDBCPlan != PLAN_TYPES.Solo) && (dbcTrialPlan || (!(!dbcTrialPlan && user.subscription.dbc.plan == PLAN_TYPES.Solo)))"
                    (onClick)="selectDBCPlan(PLAN_TYPES.Solo)" [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'"
                    [style]="'text'" [label]="'Select Plan'"></beaconstac-button>
                  <beaconstac-button
                    *ngIf="(selectedDBCPlan === PLAN_TYPES.Solo || (!dbcTrialPlan && user.subscription.dbc.plan == PLAN_TYPES.Solo))"
                    [type]="!dbcTrialPlan && (user.subscription.dbc.plan == PLAN_TYPES.Solo) ? BUTTON_TYPES.SECONDARY : BUTTON_TYPES.PRIMARY"
                    [label]="dbcTrialPlan || (user.subscription.dbc.plan == PLAN_TYPES.Trial) ? 'Buy now' : user.subscription.dbc.plan == PLAN_TYPES.Solo ? 'Current' : 'Upgrade'"
                    [disabled]="!dbcTrialPlan && (user.subscription.dbc.plan == PLAN_TYPES.Solo) ? true : false"
                    (onClick)="onDBCPlanTypeClicked(PLAN_TYPES.Solo)" [width]="'100%'"
                    [style]="'text'"></beaconstac-button>
                </div>
              </div>
              <div class="bx--col-lg-3">
                <div class="mb-3">
                  <span class="dbc-team-plan-tag">
                    Team
                  </span>
                </div>
                <div class="mb-2">
                  <b>${{DBC_PRICE_RATES.Team}}</b> <span>/user/month</span>
                </div>
                <div *ngIf="
              isOnHigherDBCPlanTeam" class="d-flex align-items-start">
                  <beaconstac-button
                    *ngIf="(selectedDBCPlan != PLAN_TYPES.Team) && (dbcTrialPlan || (!(!dbcTrialPlan && user.subscription.dbc.plan == PLAN_TYPES.Team)))"
                    (onClick)="selectDBCPlan(PLAN_TYPES.Team)" [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'"
                    [style]="'text'" [label]="'Select Plan'"></beaconstac-button>
                  <beaconstac-button
                    *ngIf="(selectedDBCPlan === PLAN_TYPES.Team || (!dbcTrialPlan && user.subscription.dbc.plan == PLAN_TYPES.Team))"
                    [type]="!dbcTrialPlan && (user.subscription.dbc.plan == PLAN_TYPES.Team) ? BUTTON_TYPES.SECONDARY : BUTTON_TYPES.PRIMARY"
                    [label]="dbcTrialPlan || (user.subscription.dbc.plan == PLAN_TYPES.Trial) ? 'Buy now' : user.subscription.dbc.plan == PLAN_TYPES.Team ? 'Current' : 'Upgrade'"
                    [disabled]="!dbcTrialPlan && (user.subscription.dbc.plan == PLAN_TYPES.Team) ? true : false"
                    (onClick)="onDBCPlanTypeClicked(PLAN_TYPES.Team)" [width]="'100%'"
                    [style]="'text'"></beaconstac-button>
                </div>
              </div>
              <div class="bx--col-lg-3">
                <div class="mb-3">
                  <span class="dbc-business-plan-tag">
                    Business
                  </span>
                </div>
                <div class="mb-2">
                  <b>${{DBC_PRICE_RATES.Business}}</b> <span>/user/month</span>
                </div>
                <div *ngIf="
              isOnHigherDBCPlanBusiness" class="d-flex align-items-start">
                  <beaconstac-button
                    *ngIf="(selectedDBCPlan != PLAN_TYPES.Business) && (dbcTrialPlan || (!(!dbcTrialPlan && user.subscription.dbc.plan == PLAN_TYPES.Business)))"
                    (onClick)="selectDBCPlan(PLAN_TYPES.Business)" [type]="BUTTON_TYPES.PRIMARY" [width]="'100%'"
                    [style]="'text'" [label]="'Select Plan'"></beaconstac-button>
                  <beaconstac-button
                    *ngIf="(selectedDBCPlan === PLAN_TYPES.Business || (!dbcTrialPlan && user.subscription.dbc.plan == PLAN_TYPES.Business))"
                    [type]="!dbcTrialPlan && (user.subscription.dbc.plan == PLAN_TYPES.Business) ? BUTTON_TYPES.SECONDARY : BUTTON_TYPES.PRIMARY"
                    [label]="dbcTrialPlan || (user.subscription.dbc.plan == PLAN_TYPES.Trial) ? 'Buy now' : user.subscription.dbc.plan == PLAN_TYPES.Business ? 'Current' : 'Upgrade'"
                    [disabled]="!dbcTrialPlan && (user.subscription.dbc.plan == PLAN_TYPES.Business) ? true : false"
                    (onClick)="onDBCPlanTypeClicked(PLAN_TYPES.Business)" [width]="'100%'"
                    [style]="'text'"></beaconstac-button>
                </div>
              </div>
              <div class="bx--col-lg-3">
                <div class="mb-3">
                  <span class="dbc-enterprise-plan-tag">
                    Business+
                  </span>
                </div>
                <div class="mb-2">
                  Custom
                </div>
                <div class="d-flex align-items-start">
                  <beaconstac-button *ngIf="selectedDBCPlan != PLAN_TYPES.Enterprise"
                    (onClick)="selectDBCPlan(PLAN_TYPES.Enterprise)" [style]="'text'" [width]="'100%'"
                    [label]="'Select Plan'"></beaconstac-button>
                  <beaconstac-button *ngIf="selectedDBCPlan === PLAN_TYPES.Enterprise" (onClick)="onEnterpriseSelected()"
                    [width]="'100%'" [style]="'text'" [label]="'Contact us'"></beaconstac-button>
                </div>
              </div>
            </div>

            <div class="bx--row p-3" style="background: #FAFAFA;">
              <ng-container *ngFor="let data of DBC_FEATURE_DETAILS_DATA;">
                <div class="bx--row w-100 mb-3 pl-4">
                  <div class="bx--col-lg-4">
                    <div class="feature-type">
                      {{data?.type}}
                    </div>
                    <div class="feature-description">
                      {{data?.description}}
                    </div>
                  </div>
                  <ng-container *ngFor="let item of data.plans;">
                    <div class="bx--col-lg-3">
                      <div *ngIf="item?.value == 'true'; else falseBlock">
                        <i class="fas fa-check fa-lg tick-mark-color"></i>
                      </div>
                      <ng-template #falseBlock>
                        {{ item?.value }}
                      </ng-template>
                    </div>
                  </ng-container>
                  <hr class="horizontal-line" />
                </div>
              </ng-container>
            </div>
          </div>

          <!-- for mobile screens pricing comparison -->
          <div class="d-xl-none">
            <!-- header -->
            <div class="bx--row bg-white pb-4 d-flex position-sticky" style="padding: 15px 0 15px 15px; top: 3.5rem; z-index: 2;">
              <div class="plan-type-mobile" [ngClass]="planTypeIndex == 0 ? 'plan-active' : ' '"
                (click)="switchMobilePlans(PLAN_TYPES.Solo)">
                <div class="mb-2">
                  <span class="dbc-solo-plan-tag">
                    Solo
                  </span>
                </div>
                <div class="plan-heading-value font-fw-400 font-fs-16"><b>${{DBC_PRICE_RATES.Solo}}</b>/user/month</div>
              </div>
              <div class="plan-type-mobile" [ngClass]="planTypeIndex == 1 ? 'plan-active' : ' '"
                (click)="switchMobilePlans(PLAN_TYPES.Team)">
                <div class="mb-2">
                  <span class="dbc-team-plan-tag">
                    Team
                  </span>
                </div>
                <div class="plan-heading-value font-fw-400 font-fs-16"><b>${{DBC_PRICE_RATES.Team}}</b>/user/month</div>
              </div>
              <div class="plan-type-mobile" [ngClass]="planTypeIndex == 2 ? 'plan-active' : ' '"
                (click)="switchMobilePlans(PLAN_TYPES.Business)">
                <div class="mb-2">
                  <span class="dbc-business-plan-tag">
                    Business
                  </span>
                </div>
                <div class="plan-heading-value font-fw-400 font-fs-16"><b>${{DBC_PRICE_RATES.Business}}</b>/user/month</div>
              </div>
              <div class="plan-type-mobile" [ngClass]="planTypeIndex == 3 ? 'plan-active' : ' '"
                (click)="switchMobilePlans(PLAN_TYPES.Enterprise)">
                <div class="mb-2">
                  <span class="dbc-enterprise-plan-tag">
                    Business+
                  </span>
                </div>
                <div class="plan-heading-value font-fw-400 font-fs-16">Talk to us</div>
              </div>
            </div>

            <!-- feature-types -->
            <div>
              <div class="bx--row p-3" style="background: #FAFAFA;">
                <ng-container *ngFor="let data of DBC_FEATURE_DETAILS_DATA;">
                  <div class="bx--row w-100 mb-3">
                    <div style="width: 60%;
                  padding: 0 2rem 0 1.5rem;">
                      <div class="feature-type">
                        {{data?.type}}
                      </div>
                      <div class="feature-description">
                        {{data?.description}}
                      </div>
                    </div>
                    <div>
                      <div>
                        <div *ngIf="data.plans[planTypeIndex]?.value == 'true'; else falseBlock">
                          <i class="fas fa-check fa-lg tick-mark-color"></i>
                        </div>
                        <ng-template #falseBlock>
                          {{ data.plans[planTypeIndex]?.value }}
                        </ng-template>
                      </div>
                    </div>
                    <hr class="horizontal-line" />
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <form #cardForm="ngForm" method="post" id="payment-form">
    <div [ngSwitch]="currentSection">
      <section class="row mx-0 text-center" *ngSwitchCase="UPGRADE_SECTION.Payment" [@collapse]>
        <h4 class="w-100">
          Purchase {{ plansFullName[planType] | titlecase }} plan
          <span *ngIf="currentDashboard == DASHBOARD_MENU.CARDS"> ${{dbcPlansYearlyPrice[planType]}}</span>
          /yr
        </h4>
        <div class="d-flex justify-content-center w-100">
          <div class="bg-white text-left upgrade-payment-container">
            <div class="upgrade-payment" id="upgrade-payment">
              <div class="upgrade-payment-child">
                <h4 *ngIf="cards.length > 0">Select a payment method</h4>
                <div *ngIf="cards.length > 0" class="existing-cards d-flex text-left flex-wrap"
                  style="margin-left: -8px; margin-top: -5px">
                  <div class="d-flex upgrade-payment-card" *ngFor="let item of cards">
                    <div style="margin-right: 10px">
                      <input type="radio" [checked]="item.id === selectedCard.id" (click)="setUseExistingCard(item)" />
                    </div>
                    <div>
                      <div class="card-image">
                        <img src="../../assets/img/user-upgrade/{{
                        item.brand | lowercase
                      }}.png" style="height: 20px" />
                      </div>
                      <div class="card-number" style="font-weight: 500; color: #4e4e4e">
                        XXXX-XXXX-XXXX-{{ item.last4 }}
                      </div>
                      <div class="card-type" style="color: #999da5">
                        {{ item.funding | uppercase }} CARD | VALID TILL
                        {{ item.exp_month }}/{{ item.exp_year }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding-bottom: 2rem" class="upgrade-payment-child">
                <h4 *ngIf="cards.length > 0">Or add a new card</h4>
                <h4 *ngIf="cards.length == 0">Add a payment method</h4>
                <div class="form-row stripe-container" style="padding: 0.7rem">
                  <input id="name" name="name" placeholder="Name on card" required value="" />
                </div>
                <div class="form-row stripe-container">
                  <!-- a Stripe Element will be inserted here. -->
                  <div id="card-element" class="stripe-card-container"></div>
                  <!-- Used to display Element errors -->
                  <div id="card-errors" class="stripe-card-error" role="alert"></div>
                </div>
                <div *ngIf="planYearly">
                  <h4 class="mt-5">Have a coupon?</h4>
                  <div class="form-row">
                    <div class="input-group">
                      <div style="margin-left: -10px" class="row w-100">
                        <span class="col-10">
                          <beaconstac-textfield id="coupon" name="coupon" [(text)]="couponCodeModel.couponCode"
                            [placeholder]="'Add a coupon code'" [maxWidth]="'100%'" [disabled]="
                          couponCodeModel.success ||
                          couponCodeModel.loading
                        "></beaconstac-textfield>
                        </span>

                        <span *ngIf="!couponCodeModel.success" class="col-2 apply-button" style="padding: 0">
                          <beaconstac-button [disabled]="
                          couponCodeModel.loading ||
                          !couponCodeModel.couponCode
                        " (onClick)="onCouponApplied()" [label]="'APPLY'"></beaconstac-button>
                        </span>

                        <span *ngIf="couponCodeModel.success" class="col-2" style="padding: 0">
                          <beaconstac-button [type]="BUTTON_TYPES.DANGER" [disabled]="
                          couponCodeModel.loading ||
                          !couponCodeModel.couponCode
                        " (onClick)="onCouponRemoved()" [label]="'REMOVE'"></beaconstac-button>
                        </span>
                      </div>
                    </div>
                    <div class="alert alert-danger w-100" *ngIf="couponCodeModel.error">
                      Invalid coupon code
                    </div>

                    <div class="alert alert-success w-100 mt-2 my-1" *ngIf="couponCodeModel.success">
                      Coupon code applied
                      <i class="fas fa-check-circle text-success text-center float-right" style="font-size: 18px"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="subscription$ | async; let subscription" class="upgrade-footer" style="margin-top: 2px">
              <span class="d-flex flex-row float-right">
                <span class="mr-3">
                  <beaconstac-button [style]="BUTTON_STYLES.FILLED" (onClick)="redirectToPreviousPage()"
                    [type]="BUTTON_TYPES.SECONDARY" [label]="'CANCEL'"></beaconstac-button>
                </span>

                <span>
                  <beaconstac-button *ngIf="currentDashboard == DASHBOARD_MENU.CARDS" [disabled]="user
                      | isOnHigherDBCPlan
                       : planType
                       : 'YEAR'
                       : planYearly" [type]="BUTTON_TYPES.PRIMARY" (onClick)="upgradeModal.show()" [label]="'UPGRADE'"
                    [style]="BUTTON_STYLES.FILLED"></beaconstac-button>
                </span>
              </span>
              <button class="d-none" id="upgrade-submit-button" value="submit" type="submit"></button>
            </div>
          </div>
        </div>
      </section>
    </div>

  </form>
</div>

<div bsModal #upgradeModal="bs-modal" class="modal fade" role="dialog" (onShow)="setUpUpgradeModel()">
  <div class="modal-dialog" role="document">
    <div class="modal-content change-plan-view upgrade-modal" *ngIf="subscription$ | async; let subscription">
      <div class="modal-body" style="padding: 24px 24px 16px 24px">
        <h4 style="color: #16212f; font-size: 20px; margin-bottom: 16px">
          Change Plan Confirmation
        </h4>

        <p class="mt-4">
          You're about to subscribe to the <b>{{ upgradeModel.name }} plan.</b>
        </p>

        <p *ngIf="couponCodeModel.success">
          <i><b>Coupon code applied ({{ couponCodeModel.couponCode }}):
              {{ couponCodeModel.coupon.name }}</b></i>
        </p>

        You will be charged <b>${{ planPrice | decimalPrice | number }}</b>
        <span *ngIf="!isOnTrial || !dbcTrialPlan">
          on a pro-rated basis, for the remaining duration of your plan from
          {{ lastBillingDate }} to {{ nextBillingDate }}</span>.
      </div>

      <div style="margin: 32px">
        <span class="d-flex float-right flex-row">
          <span>
            <beaconstac-button [style]="BUTTON_STYLES.FILLED" [type]="BUTTON_TYPES.SECONDARY" [label]="'Cancel'"
              (onClick)="upgradeModal.hide()"></beaconstac-button>
          </span>
          <span style="padding-left: 16px">
            <beaconstac-button *ngIf="currentDashboard == DASHBOARD_MENU.CARDS" [disabled]="
            user | isOnHigherDBCPlan
            : planType
            : 'YEAR'
            : planYearly
          " [type]="BUTTON_TYPES.PRIMARY" (onClick)="upgrade(); upgradeModal.hide()" [label]="'Confirm'"
              [style]="BUTTON_STYLES.FILLED"></beaconstac-button>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>

