import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import * as moment from 'moment-timezone';
import {DATE_FORMAT} from '../../shared/utils';
import {getFormattedDate} from '../common-analytics/common-analytics-utils';

@Component({
    selector: 'beaconstac-datepicker',
    templateUrl: './beaconstac-datepicker.component.html',
    styleUrls: ['./beaconstac-datepicker.component.scss']
})
export class BeaconstacDatepickerComponent implements OnInit, OnChanges {
    // beaconstac text-field inputs and outputs
    @Input() placeholder: string = 'DD/MM/YYYY';
    @Input() maxWidth: string = '100%';
    @Input() icon: string = 'fas fa-calendar-alt';
    @Input() label: boolean = false;
    @Input() title: string = '';
    @Input() type: string = 'current';

    // mat-datepicker inputs and outputs
    @Input() dateSelectedText: string = '';
    @Input() disabled: boolean = false;
    @Output() onDateChange = new EventEmitter();

    _dateSelected: Date | null = null;

    _maxDate: Date | null = null;

    _startDate: Date | null = null;

    DATE_FORMAT = DATE_FORMAT;
    @Input() set dateSelected(value: moment.Moment | Date) {
        this._dateSelected = value ? moment(value).toDate() : null;
        this.dateSelectedText = this._dateSelected ? getFormattedDate(moment(this._dateSelected)) : this.dateSelectedText;
    }
    get dateSelected(): Date {
        return this._dateSelected;
    }
    @Input() set maxDate(value: moment.Moment | Date) {
        this._maxDate = value ? moment(value).toDate() : null;
    }
    get maxDate(): Date {
        return this._maxDate;
    }
    @Input() set startDate(value: moment.Moment | Date) {
        this._startDate = value ? moment(value).toDate() : null;
    }
    get startDate(): Date {
        return this._startDate;
    }

    constructor() {
    }

    ngOnChanges() {
    }

    ngOnInit() {
        this.dateSelectedText = this._dateSelected ? getFormattedDate(moment(this._dateSelected)) : this.dateSelectedText;
        if (this._dateSelected) {
            this.onDateChange.emit(this._dateSelected);
        }
    }

    onDateChangeHandler(event) {
        // add current time(hours, minutes and seconds) to the selected date
        const _ = moment();
        let updatedDateSelected;
        if (this.type === 'start') {
            updatedDateSelected = moment(event.value).toDate();
        } else if (this.type === 'current') {
            updatedDateSelected = moment(event.value).add({hours: _.hour(), minutes: _.minute() , seconds: _.second()}).toDate();
        } else if (this.type === 'end') {
            updatedDateSelected = moment(event.value).endOf('day').toDate();
        }
        // get text corresponding to selected date
        this.dateSelectedText = getFormattedDate(moment(updatedDateSelected));
        // emit the selected date in JS Date format(not moment)
        this.onDateChange.emit(updatedDateSelected);
    }

    resetDate() {
        // reset start date to current date and selected date to null
        this.startDate = moment().toDate();
        this._dateSelected = null;
        this.dateSelectedText = '';
    }
}
