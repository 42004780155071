import {BaseModel} from '../../global-services/base.model';
import {Media} from '../../places/media.model';
import * as moment from 'moment';
import {DataPattern, EyeBallShape, EyeFrameShape, GradientType, QRCodeFrame} from 'mobstac-awesome-qr/lib/Enums';

export enum QR_TYPES {
    Static = 1,
    Dynamic = 2
}

export enum QR_DATA_TYPES {
    URL = 1,
    SMS = 2,
    PHONE = 3,
    EMAIL = 4,
    VCARD = 5,
    TEXT = 6,
    DYNAMIC_URL = 7,
    DYNAMIC_SMS = 8,
    DYNAMIC_PHONE = 9,
    DYNAMIC_EMAIL = 10,
    DYNAMIC_APP_LINKS = 11
}

export class BulkQRV2 extends BaseModel {

    id: number;
    media: Media;
    organization: number;
    name: string;
    media_data: object;
    attributes = {
        color: '#000000',
        colorDark: '#000000',
        colorLight: '#8f00ff',
        gradientType: GradientType.NONE,
        backgroundImage: '',
        backgroundColor: '',
        logoImage: '',
        frameStyle: QRCodeFrame.NONE,
        frameColor: '#000000',
        frameText: '',
        frameTextColor: '#000000',
        dataPattern: DataPattern.SQUARE,
        eyeBallShape: EyeBallShape.SQUARE,
        eyeFrameShape: EyeFrameShape.SQUARE,
        eyeBallColor: '#000000',
        eyeFrameColor: '#000000',
        margin: 80,
        logoMargin: 10,
        logoBackground: true,
        logoScale: 0.2,
        isVCard: false,
        dotScale: 1
    };
    qr_type: number;
    qr_data_type: number;
    error_correction_level: number = 1;
    size: number = 1024;
    storage_url: string;
    template: number;
    created: string;
    updated: string;
    locationEnabled: boolean = false;

    generatedData;

    vcard_plus = {
        logo_url: '',
        layout: '3',
        auto_download: false,
        auto_email_pass: false,
        customizations: {
            background_color: '#252a7c',
            user_info_color: '#FFFFFF',
            secondary_color: '#000000',
            font_style: 'Roboto',
            title_font_size: 30
        }
    }

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.id = json['id'];
        this.media = json['media'];
        this.organization = json['organization'];
        this.media_data = json['media_data'];
        this.created = json['created'];
        this.updated = json['updated'];
        if (this.attributes) {
            this.attributes = json['attributes'];
        }
        if (this.vcard_plus){
            this.vcard_plus = json['vcard_plus'];
        }

        this.qr_type = json['qr_type'];
        this.qr_data_type = json['qr_data_type'];
        this.storage_url = json['storage_url'];
        this.attributes.logoBackground  = this.attributes.logoBackground ? this.attributes.logoBackground : false,
        this.attributes.color = this.attributes.color ? this.attributes.color : '#000000';
        this.attributes.colorLight = this.attributes.colorLight ? this.attributes.colorLight : '#8f00ff';
        this.attributes.colorDark = this.attributes.colorDark ? this.attributes.colorDark : '#000000';
        this.attributes.gradientType = this.attributes.gradientType ? this.attributes.gradientType : GradientType.NONE;
        this.attributes.backgroundImage = this.attributes.backgroundImage ? this.attributes.backgroundImage : '';
        this.attributes.backgroundColor = this.attributes.backgroundColor ? this.attributes.backgroundColor : '';
        this.attributes.logoImage = this.attributes.logoImage ? this.attributes.logoImage : '';
        this.attributes.frameStyle = this.attributes.frameStyle ? this.attributes.frameStyle : QRCodeFrame.NONE;
        this.attributes.frameColor = this.attributes.frameColor ? this.attributes.frameColor : '#000000';
        this.attributes.frameText = this.attributes.frameText ? this.attributes.frameText : '';
        this.attributes.frameTextColor = this.attributes.frameTextColor || '#ffffff';
        this.attributes.dataPattern = this.attributes.dataPattern ? this.attributes.dataPattern : DataPattern.SQUARE;
        this.attributes.eyeBallShape = this.attributes.eyeBallShape ? this.attributes.eyeBallShape : EyeBallShape.SQUARE;
        this.attributes.eyeFrameShape = this.attributes.eyeFrameShape ? this.attributes.eyeFrameShape : EyeFrameShape.SQUARE;
        this.attributes.eyeFrameColor = this.attributes.eyeFrameColor ? this.attributes.eyeFrameColor : '';
        this.attributes.eyeBallColor = this.attributes.eyeBallColor ? this.attributes.eyeBallColor : '';
        this.attributes.margin = this.attributes.margin ? this.attributes.margin : 80;
        this.attributes.logoMargin = this.attributes.logoMargin ? this.attributes.logoMargin : ( this.attributes.logoMargin === 0 ) ? 0 : 10;
        this.attributes.logoScale = this.attributes.logoScale ? this.attributes.logoScale : 0.2;
        this.attributes.isVCard = this.attributes.isVCard ? this.attributes.isVCard : false;
        this.attributes.dotScale = 1
        this.template = json['template'] || null;
        if (!this.attributes.eyeBallColor) {
            if (!this.attributes.colorDark) {
                this.attributes.eyeBallColor = '#000000'
            } else {
                this.attributes.eyeBallColor = this.attributes.colorDark
            }
        }
        if (!this.attributes.eyeFrameColor) {
            if (!this.attributes.colorDark) {
                this.attributes.eyeFrameColor = '#000000'
            } else {
                this.attributes.eyeFrameColor = this.attributes.colorDark
            }
        }

        this.vcard_plus.auto_download = this.vcard_plus.auto_download ? this.vcard_plus.auto_download : false;
        this.vcard_plus.auto_email_pass = this.vcard_plus.auto_email_pass ? this.vcard_plus.auto_email_pass : false;
        this.vcard_plus.logo_url = this.vcard_plus.logo_url ? this.vcard_plus.logo_url : '';
        this.vcard_plus.customizations.font_style = this.vcard_plus.customizations.font_style ? this.vcard_plus.customizations.font_style : 'Roboto';
        this.vcard_plus.layout = this.vcard_plus.layout ? this.vcard_plus.layout : '3';
        this.vcard_plus.customizations.background_color = this.vcard_plus.customizations.background_color ? this.vcard_plus.customizations.background_color : '#252a7c';
        this.vcard_plus.customizations.user_info_color = this.vcard_plus.customizations.user_info_color ? this.vcard_plus.customizations.user_info_color : '#FFFFFF';
        this.vcard_plus.customizations.secondary_color = this.vcard_plus.customizations.secondary_color ? this.vcard_plus.customizations.secondary_color : '#000000';
        this.vcard_plus.customizations.title_font_size = this.vcard_plus.customizations.title_font_size ? this.vcard_plus.customizations.title_font_size : 30;
    }


    getFormData(isVCardPlusSelected: boolean): FormData {
        const formData: FormData = new FormData();
        if (this.id) {
            formData.append('id', this.id.toString());
        }
        formData.append('organization', this.organization.toString());
        formData.append('attributes', JSON.stringify(this.attributes));
        formData.append('qr_type', this.qr_type.toString());
        formData.append('qr_data_type', this.qr_data_type.toString());
        formData.append('storage_url', this.storage_url);
        formData.append('location_enabled', this.locationEnabled.toString());
        if (this.template) {
            formData.append('template', this.template.toString());
        }

        if (isVCardPlusSelected){
            formData.append('auto_download', this.vcard_plus.auto_download.toString());
            formData.append('auto_email_pass', this.vcard_plus.auto_email_pass.toString());
            formData.append('company_logo', this.vcard_plus.logo_url.toString());
            formData.append('font_style', this.vcard_plus.customizations.font_style.toString());
            formData.append('layout', this.vcard_plus.layout.toString());
            formData.append('primary_color', this.vcard_plus.customizations.background_color.toString());
            formData.append('profile_text_color', this.vcard_plus.customizations.user_info_color.toString());
            formData.append('secondary_color', this.vcard_plus.customizations.secondary_color.toString());
            formData.append('title_font_size', this.vcard_plus.customizations.title_font_size.toString());
        }

        return formData;
    }

    getReadableCreatedDate(): string {
        return moment(this.created).format('LL');
    }

    resetAttributes() {
        this.attributes.backgroundColor = '';
        this.attributes.backgroundImage = '';
        this.attributes.logoBackground  = true,
        this.attributes.colorDark = '#000000';
        this.attributes.colorLight = '#8f00ff';
        this.attributes.dataPattern = DataPattern.SQUARE;
        this.attributes.eyeBallColor = '#000000';
        this.attributes.eyeBallShape = EyeBallShape.SQUARE;
        this.attributes.eyeFrameColor = '#000000';
        this.attributes.eyeFrameShape = EyeFrameShape.SQUARE;
        this.attributes.frameColor = '#000000';
        this.attributes.frameStyle = QRCodeFrame.NONE;
        this.attributes.frameText = '';
        this.attributes.frameTextColor = '';
        this.attributes.gradientType = GradientType.NONE;
        this.attributes.logoImage = '';
        this.attributes.logoMargin = 10;
        this.attributes.logoScale = 0.2;
        this.attributes.margin = 80;
    }
}
