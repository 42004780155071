import {Injectable} from '@angular/core';
import {BaseBackendService} from '../../global-services/base-backend.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthService} from '../../global-services/auth.service';
import {MessageModalService} from '../../shared/message-modal/message-modal.service';
import {BulkQRV2} from './bulk-qr-v2.model';
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

interface FileDownloadResponse {
    data: Blob,
    fileName: string
}

@Injectable()
export class BulkQrV2Service extends BaseBackendService<BulkQRV2> {
    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, BulkQRV2, 'bulkqrcodesv2', messageModal);
    }


    /**
   *
   * @param body
   * @param custom
   */
    public post(body: FormData, custom?: string): Observable<any> {
        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }
        let url = this.apiURL;
        if (custom) {
            url += custom;
        }

        const argsObject = {};
        argsObject['headers'] = new HttpHeaders({
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });

        return this.http.post(url, body, argsObject).pipe(
            map(response => {
                return response;
            }),
            catchError(error => {
                return this.handleError(error);
            }));
    }

    public getCSV(url: string): Observable<FileDownloadResponse> {
        return this.http.get(url, {responseType: 'blob', observe: 'response'}).pipe(map(res => {
            return {
                data: new Blob([res.body], {type: res.headers.get('Content-Type')}),
                fileName: res.headers.get('File-Name') || 'Unknown file name.csv'
            };
        }));
    }

    validateCSV(body: FormData) {
        const validationURL = this.apiURL + 'csv-validation/';

        if (this.authService.isReAuthenticationRequired()) {
            return observableThrowError(null);
        }

        const argsObject = {};
        argsObject['headers'] = new HttpHeaders({
            'Authorization': `${this.authService.getTokenType()} ${this.authService.getToken()}`
        });

        return this.http.post(validationURL, body, argsObject).pipe(
            map((response) => {
                return response;
            }), catchError(this.handleError));
    }

}
