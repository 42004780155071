import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
// Global services
import {BaseBackendService} from '../global-services/base-backend.service';
import {AuthService} from '../global-services/auth.service';
import {QRCodeTemplate} from './qr-template.model';
import {MessageModalService} from '../shared/message-modal/message-modal.service';

@Injectable()
export class QRTemplateService extends BaseBackendService<QRCodeTemplate> {

    constructor(http: HttpClient, authService: AuthService, messageModal: MessageModalService) {
        super(http, authService, QRCodeTemplate, 'qrtemplates', messageModal);
    }
}
