<div class="bg-white p-4 salesforce-background">

    <div class="mt-1 mb-4" style="color: #3ea1ff; word-break: break-all;">
        <beaconstac-button
            (onClick)="onBackClicked()"
            class="ml-2" [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.TEXT"
            [label]="isSalesforceConnected ? 'Integrations' : 'Back'" [icon]="'far fa-arrow-left'"
            iconSize="16px">
        </beaconstac-button>
    </div>

    <div class="d-flex salesforce-heading" style="margin-bottom: 24px">
        <div class="salesforce-header">
            Connect with Salesforce
        </div>
        <div>
            <span class="d-inline-block pt-1 pb-1 pl-2 pr-2 ml-3 connected-box" *ngIf="isSalesforceConnected" style="margin-top: 3px;">
                Connected
            </span>
            <span class="d-inline-block pt-1 pb-1 pl-2 pr-2 ml-3 not-connected-box" *ngIf="!isSalesforceConnected" style="margin-top: 3px;">
                Not Connected
            </span>
        </div>
    </div>

    <div class="bx--row">
        <div class="bx--col salesforce-content">
            <p style="margin-bottom: 24px; color: #5E6980;" class="subtitle2">Integrate Salesforce for seamless tracking
                of leads to follow up and nurture them towards conversion.</p>

            <div class="mb-4" *ngIf='!isSalesforceConnected'>
                <div [ngSwitch]="currentStep">
                    <section class="row mx-0" *ngSwitchCase="SALESFORCE_STEP.One">
                        <div class="bx--col" *ngIf="salesforceConnectError" style="margin-bottom: 24px; padding-left: 0px; padding-right: 0px;" [@collapse]>
                            <beaconstac-message [type]="MESSAGE_TYPES.DANGER" [message]="salesforceConnectErrorMessage">
                            </beaconstac-message>
                        </div>
                        <div class="d-flex" style="border: 1px solid #E4E5E7; border-radius: 2px;">
                            <div class="bx--col-lg-3 bx--col-md-1 d-flex align-items-center justify-content-center d-sm-down-none"
                                style="background-color: #F4F5F7;">
                                <img style="object-fit: contain;"
                                    src="../../../assets/img/integrations/salesforce_logo.svg"
                                    alt="Uniqode-saleforce integration" />
                            </div>
                            <div class="bx--col-lg-13 bx--col-md-7">
                                <div class="p-2 mt-3" style="font-size: 20px;">
                                    Step 1: Configure Uniqode app in Salesforce
                                </div>
                                <div class="p-2 mt-2 text-secondary d-inline-block"
                                    style="border: 1px solid #E4E5E7; border-radius: 2px;">
                                    <a style="text-decoration: none; color: #5E6980;" target="_blank" href="http://docs.beaconstac.com/en/articles/8061521-export-your-collected-leads-from-beaconstac-to-salesforce-crm#h_3628087a4b">
                                        <i class="far fa-external-link mr-1"></i> Instructions to configure Salesforce
                                    </a>
                                </div>
                                <div class="mt-3 p-2 text-secondary">
                                    Copy your callback URL and add it to the Uniqode app in Salesforce.
                                </div>
                                <div class="mt-1 p-2 mb-4" style="color: #3ea1ff; word-break: break-all;">
                                    {{callbackURL}}
                                    <beaconstac-button
                                        (onClick)="copyCallbackURL(COPY_TEXT_TYPES.SALESFORCE_CALLBACK_URL)"
                                        class="ml-2" [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.TEXT"
                                        [label]="''" [icon]="'far fa-copy'">
                                    </beaconstac-button>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 32px; display: flex;" class="integration-button">
                            <span>
                                <beaconstac-button [label]="'Next'" (onClick)="onNextStepClicked()"></beaconstac-button>
                            </span>
                            <span class="integration-secondary-button">
                                <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="'Documentation'"
                                    (onClick)="onDocumentationClicked()"></beaconstac-button>
                            </span>
                        </div>

                    </section>
                    <section class="row mx-0" *ngSwitchCase="SALESFORCE_STEP.Two">
                        <div class="d-flex" style="border: 1px solid #E4E5E7; border-radius: 2px;">
                            <div class="bx--col-lg-3 bx--col-md-1 d-flex align-items-center justify-content-center d-sm-down-none"
                                style="background-color: #F4F5F7;">
                                <img style="object-fit: contain;"
                                    src="../../../assets/img/integrations/beaconstac_logo.svg"
                                    alt="Uniqode-saleforce integration" />
                            </div>
                            <div class="bx--col-lg-13 bx--col-md-7">
                                <div class="p-2 mt-3" style="font-size: 20px;">
                                    Step 2: Connect Uniqode with Salesforce
                                </div>
                                <div class="pl-2 pr-2 text-secondary">
                                    Enter your Consumer key and Consumer secret from Salesforce.
                                    <a style="text-decoration: none; color: #5E6980;" target="_blank" href="http://docs.beaconstac.com/en/articles/8061521-export-your-collected-leads-from-beaconstac-to-salesforce-crm#h_553a3a0e0f">
                                        <span class="learn-more" style="color: #3ea1ff;">Learn more</span>
                                    </a>
                                </div>
                                <div class="mt-2 p-2 mb-4">
                                    <beaconstac-textfield [(text)]="consumer_key" [label]="true"
                                        [placeholder]="'Enter your Consumer Key'" [title]="'Consumer key'"
                                        [type]="TEXT_FIELD_TYPES.TEXT" [maxWidth]="'400px'"></beaconstac-textfield>
                                    <beaconstac-textfield [(text)]="consumer_secret" [label]="true"
                                        [placeholder]="'Enter your Consumer Secret'" [title]="'Consumer secret'"
                                        [type]="TEXT_FIELD_TYPES.TEXT" [maxWidth]="'400px'"></beaconstac-textfield>
                                    <beaconstac-textfield [(text)]="lead_source" [label]="true"
                                        [placeholder]="'Uniqode-Cards'" [title]="'Lead Source'"
                                        [type]="TEXT_FIELD_TYPES.TEXT" [maxWidth]="'400px'"></beaconstac-textfield>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 32px; display: flex;" class="integration-button">
                            <span>
                                <beaconstac-button [disabled]="!consumer_key || !consumer_secret || !lead_source" [label]="'Connect'" (onClick)="onSalesforceConnectClicked()">
                                </beaconstac-button>
                            </span>
                            <span class="integration-secondary-button">
                                <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="'Documentation'"
                                    (onClick)="onDocumentationClicked()"></beaconstac-button>
                            </span>
                        </div>

                    </section>
                </div>
            </div>
            <div class="mb-4" *ngIf='isSalesforceConnected'>
                <div *ngIf="!salesforceConnectError" style="margin-bottom: 24px;" [@collapse]>
                    <beaconstac-message [type]="MESSAGE_TYPES.PRIMARY" [message]="salesforceConnectSuccessMessage">
                    </beaconstac-message>
                </div>
                <div class="p-4" style="border: 1px solid #E4E5E7; border-radius: 2px;">
                    <p class="text-secondary text-uppercase leads-synced">
                        LEADS SYNCED
                    </p>
                    <p class="leads-number">
                        {{leadsSynced}}
                    </p>
                </div>
                <div style="margin-top: 32px; display: flex;" class="integration-button">
                    <span>
                        <beaconstac-button [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.STROKE"
                            [label]="'DISCONNECT'" (onClick)="disconnectSalesforceModal.show()"></beaconstac-button>
                    </span>
                    <span class="integration-secondary-button">
                        <beaconstac-button [type]="BUTTON_TYPES.SECONDARY" [label]="'Documentation'"
                            (onClick)="onDocumentationClicked()"></beaconstac-button>
                    </span>
                </div>
            </div>
        </div>
        <img style="object-fit: contain; width: 400px; height: 300px;"
            src="../../../assets/img/integrations/salesforce-integration.png" class="bx--col d-sm-down-none"
            alt="Beaconstac-salesforce integration" />
    </div>
</div>

<div bsModal #disconnectSalesforceModal="bs-modal" class="modal fade" role="dialog" [config]="{'backdrop':'static'}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-body" style="padding: 24px">

                <h4 class="create-card-heading">
                    Disconnect Salesforce
                </h4>

                <div class="create-linkpage-description mt-4 mb-4">
                    Do you want to disconnect your Salesforce account?
                </div>

            </div>
            <div class="modal-footer" style="padding: 0 24px 24px 24px; border: none">

                <span>
                  <beaconstac-button [type]="BUTTON_TYPES.SECONDARY"
                                     (onClick)="disconnectSalesforceModal.hide();"
                                     [label]="'Cancel'"
                  ></beaconstac-button>
                </span>
                <span class="ml-3">
                  <beaconstac-button [type]="BUTTON_TYPES.DANGER" [label]="'Disconnect'"
                                     (onClick)="onSalesforceDisconnectClicked(); disconnectSalesforceModal.hide()"
                                     [style]="BUTTON_STYLES.FILLED"
                  ></beaconstac-button>
                </span>
            </div>

        </div>
    </div>
</div>
