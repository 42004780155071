<!-- for desktop screens -->
<div class="desktop-container">
    <!--option to collapse table-->
    <div
    (click)="isFullCollapsed = !isFullCollapsed"
    class="section-heading text-center cursor-pointer"
    [ngStyle]="{'margin-top': setDividerTopMargin()}"
  >
    See all features and compare plans
  </div>
    <!--white box - sticky header + set of dropdowns-->
    <div class="mb-4" *ngIf="!isFullCollapsed" [@collapse]>
        <ng-container *ngIf="!showBusinessPlans">
            <!--sticky header-->
            <div class="d-flex flex-row justify-content-center align-items-center plan-headers sticky mb-4" [ngStyle]="pageType === 'renew-plan' ? {'top':'0rem'} : {'top':'3rem'}">
                <!--empty-->
                <div class="d-flex justify-content-start align-items-end plan-header p-3">
                    <div class="header-heading">Features</div>
                </div>
                <!--starter-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3">
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2">{{qrPlansData.individualPlans[0].name}}</div>
                    <div class="plan-price mb-3">{{qrPlansData.individualPlans[0].price['usd'].symbol + qrPlansData.individualPlans[0].price['usd'].value}}/month</div>
                    <div class="buy-btn" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Starter) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(0)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY NOW'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn" *ngIf = "pageType == 'upgrade-now'"
                    [ngStyle]="showBuyOption(PLAN_TYPES.Starter) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(0)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Starter
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Starter
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Starter
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Starter ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--lite-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3"
                     style="background: #182D44; border-radius: 16px 16px 0px 0px; border-bottom: 1px solid #52565b">
                    <div class="d-flex justify-content-center align-items-center recommended-plan-heading mb-3"
                         style="color: #FFFFFF; column-gap: 4px;">
                        <i class="far fa-bolt" style="font-size: 20px"></i>
                        <span class="">Best Value</span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2"
                         style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                        {{qrPlansData.individualPlans[1].name}}
                    </div>
                    <div class="plan-price mb-3"
                         style="color: #FFFFFF">
                        {{qrPlansData.individualPlans[1].price['usd'].symbol + qrPlansData.individualPlans[1].price['usd'].value}}/month
                    </div>
                    <div class="buy-btn-recommended" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY NOW'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Lite
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Lite
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Lite
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Lite ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--pro-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3">
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2"
                         style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%); color: #16212f">
                        {{qrPlansData.individualPlans[2].name}}
                    </div>
                    <div class="plan-price mb-3">{{qrPlansData.individualPlans[2].price['usd'].symbol + qrPlansData.individualPlans[2].price['usd'].value}}/month</div>
                    <div class="buy-btn" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY NOW'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Pro
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Pro
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Pro
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Pro ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--plus-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3">
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2"
                         style="background: linear-gradient(90deg, #2EE3EA 0%, #8AE962 100%); color: #16212f">
                        {{qrPlansData.individualPlans[3].name}}
                    </div>
                    <div class="plan-price mb-3">{{qrPlansData.individualPlans[3].price['usd'].symbol + qrPlansData.individualPlans[3].price['usd'].value}}/month</div>
                    <div class="buy-btn" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY NOW'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Plus
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Plus
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Plus
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Plus ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>
            <!--dropdowns-->
            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-container mx-auto">
                <ng-container *ngFor="let dropdown of qrPlansData.individualPlanFeatures; let i = index" class="">
                    <div [@collapse] (click)="onDropdownToggle(dropdown)" class="d-flex flex-row justify-content-between feature-dropdown-heading cursor-pointer">
                        <div class="d-flex flex-row justify-content-center align-items-center" style="column-gap: 16px">
                            <i [class]="iconClasses[i]" aria-hidden="true" style="color: #2595ff; font-size: 18px"></i>
                            <span class="feature-dropdown-heading-text">{{ dropdown.name }}</span>
                        </div>
                        <div>
                            <i [@rotate]="isDropdownOpen(dropdown) ? 'open':'closed' " class="far fa-angle-down" style="color: #16212f; font-size: 20px"></i>
                        </div>
                    </div>
                    <ng-container *ngIf="isDropdownOpen(dropdown)" class="">
                        <div [@collapse] *ngFor="let feature of dropdown.features" class="d-flex flex-row justify-content-between align-items-center feature-dropdown-row" [@collapse]>
                            <div class="d-flex flex-row justify-content-between align-items-center feature-dropdown-row-key">
                                <div>{{ feature.name }}</div>
                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.tooltip)">
                                    <ng-template #popTemplate>
                                        <div *ngIf = "feature.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{feature.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "feature.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{feature.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isMediaEmpty(feature.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplate"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value">
                                <ng-container *ngIf="feature.starter === true; else starterFalseBlock">
                                    <i class="far fa-check" style="color: #2595ff;"></i>
                                </ng-container>
                                <ng-template #starterFalseBlock>
                                    <ng-container *ngIf="feature.starter === false; else starterTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #starterTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.starter)">
                                            {{feature.starter}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.starter)">
                                            <div>{{feature.starter.value}}</div>
                                            <div *ngIf=feature.starter.limit class="feature-dropdown-row-value-subtext">{{feature.starter.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.starter.description class="feature-dropdown-row-value-subtext">{{feature.starter.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.starter.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.starter.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.starter.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.starter.tooltip.prices">
                                                                {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value feature-dropdown-row-value-recommended">
                                <ng-container *ngIf="feature.lite === true; else liteFalseBlock">
                                    <i class="far fa-check" style="color: #FFFFFF;"></i>
                                </ng-container>
                                <ng-template #liteFalseBlock>
                                    <ng-container *ngIf="feature.lite === false; else liteTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #liteTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.lite)">
                                            {{feature.lite}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.lite)">
                                            <div>{{feature.lite.value}}</div>
                                            <div *ngIf=feature.lite.limit class="feature-dropdown-row-value-subtext" style="color: #A8D5FF;">{{feature.lite.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.lite.description class="feature-dropdown-row-value-subtext" style="color: #A8D5FF;">{{feature.lite.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.lite.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.lite.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.lite.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.lite.tooltip.prices">
                                                                    {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value">
                                <ng-container *ngIf="feature.pro === true; else proFalseBlock">
                                    <i class="far fa-check" style="color: #2595ff;"></i>
                                </ng-container>
                                <ng-template #proFalseBlock>
                                    <ng-container *ngIf="feature.pro === false; else proTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #proTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.pro)">
                                            {{feature.pro}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.pro)">
                                            <div>{{feature.pro.value}}</div>
                                            <div *ngIf=feature.pro.limit class="feature-dropdown-row-value-subtext">{{feature.pro.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.pro.description class="feature-dropdown-row-value-subtext">{{feature.pro.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.pro.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.pro.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.pro.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.pro.tooltip.prices">
                                                                {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value">
                                <ng-container *ngIf="feature.plus === true; else plusFalseBlock">
                                    <i class="far fa-check" style="color: #2595ff;"></i>
                                </ng-container>
                                <ng-template #plusFalseBlock>
                                    <ng-container *ngIf="feature.plus === false; else plusTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #plusTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.plus)">
                                            {{feature.plus}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.plus)">
                                            <div>{{feature.plus.value}}</div>
                                            <div *ngIf=feature.plus.limit class="feature-dropdown-row-value-subtext">{{feature.plus.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.plus.description class="feature-dropdown-row-value-subtext">{{feature.plus.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.plus.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.plus.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.plus.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.plus.tooltip.prices">
                                                                {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="showBusinessPlans">
            <!--sticky header-->
            <div class="d-flex flex-row justify-content-center align-items-center plan-headers sticky mb-4" [ngStyle]="pageType === 'renew-plan' ? {'top':'0rem'} : {'top':'3rem'}">
                <!--empty-->
                <div class="d-flex justify-content-start align-items-end plan-header p-3">
                    <div class="header-heading">Features</div>
                </div>
                <!--lite-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3">
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2"
                         style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                        {{qrPlansData.businessPlans[0].name}}
                    </div>
                    <div class="plan-price mb-3">{{qrPlansData.businessPlans[0].price['usd'].symbol + qrPlansData.businessPlans[0].price['usd'].value}}/month</div>
                    <div class="buy-btn" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Lite
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Lite
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Lite
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Lite ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--pro-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3"
                     style="background: #182D44; border-radius: 16px 16px 0px 0px; border-bottom: 1px solid #52565b">
                    <div class="d-flex justify-content-center align-items-center recommended-plan-heading mb-3"
                         style="color: #FFFFFF; column-gap: 4px;">
                        <i class="far fa-bolt" style="font-size: 20px"></i>
                        <span class="">Best Value</span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2"
                         style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%); color: #16212f">
                        {{qrPlansData.businessPlans[1].name}}
                    </div>
                    <div class="plan-price mb-3"
                         style="color: #FFFFFF">
                        {{qrPlansData.businessPlans[1].price['usd'].symbol + qrPlansData.businessPlans[1].price['usd'].value}}/month
                    </div>
                    <div class="buy-btn-recommended" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Pro
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Pro
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Pro
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Pro ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--plus-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3">
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2"
                         style="background: linear-gradient(90deg, #2EE3EA 0%, #8AE962 100%); color: #16212f">
                        {{qrPlansData.businessPlans[2].name}}
                    </div>
                    <div class="plan-price mb-3">{{qrPlansData.businessPlans[2].price['usd'].symbol + qrPlansData.businessPlans[2].price['usd'].value}}/month</div>
                    <div class="buy-btn" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Plus
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Plus
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Plus
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Plus ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--business+-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header p-3">
                    <div class="d-flex justify-content-center align-items-center plan-name mb-2"
                         style="background: linear-gradient(90deg, #6A52FF 0%, #A96CE6 100%); color: #FFFFFF">
                        {{qrPlansData.businessPlans[3].name}}
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-center plan-price mb-3" style="">
                        {{qrPlansData.businessPlans[3].details.widget.pricing}}
                    </div>
                    <div class="buy-btn" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Enterprise) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(4)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'TALK TO US'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Enterprise) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                       <beaconstac-button (onClick)="handleSelectPlanButton(4)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Enterprise
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Enterprise
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="'TALK TO US'"
                                           [disabled]="user.customer_plan == PLAN_TYPES.Enterprise ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>
            <!--dropdowns-->
            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-container mx-auto">
                <ng-container *ngFor="let dropdown of qrPlansData.businessPlanFeatures; let i = index" class="">
                    <div [@collapse] (click)="onDropdownToggle(dropdown)" class="d-flex flex-row justify-content-between feature-dropdown-heading cursor-pointer">
                        <div class="d-flex flex-row justify-content-center align-items-center" style="column-gap: 16px">
                            <i [class]="iconClasses[i]" aria-hidden="true" style="color: #2595ff; font-size: 18px"></i>
                            <span class="feature-dropdown-heading-text">{{ dropdown.name }}</span>
                        </div>
                        <div>
                            <i [@rotate]="isDropdownOpen(dropdown) ? 'open':'closed' " class="far fa-angle-down" style="color: #16212f; font-size: 20px"></i>
                        </div>
                    </div>
                    <ng-container *ngIf="isDropdownOpen(dropdown)" class="">
                        <div [@collapse] *ngFor="let feature of dropdown.features" class="d-flex flex-row justify-content-between align-items-center feature-dropdown-row" [@collapse]>
                            <div class="d-flex flex-row justify-content-between align-items-center feature-dropdown-row-key">
                                <div>{{ feature.name }}</div>
                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.tooltip)">
                                    <ng-template #popTemplate>
                                        <div *ngIf = "feature.tooltip.media?.type === 'video'" class="tooltip-content-video d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{feature.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "feature.tooltip.media?.type === 'image'" class="tooltip-content-image d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{feature.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isMediaEmpty(feature.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplate"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value">
                                <ng-container *ngIf="feature.lite === true; else liteFalseBlock">
                                    <i class="far fa-check" style="color: #2595ff;"></i>
                                </ng-container>
                                <ng-template #liteFalseBlock>
                                    <ng-container *ngIf="feature.lite === false; else liteTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #liteTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.lite)">
                                            {{feature.lite}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.lite)">
                                            <div>{{feature.lite.value}}</div>
                                            <div *ngIf=feature.lite.limit class="feature-dropdown-row-value-subtext">{{feature.lite.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.lite.description class="feature-dropdown-row-value-subtext">{{feature.lite.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.lite.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.lite.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.lite.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.lite.tooltip.prices">
                                                                {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value feature-dropdown-row-value-recommended">
                                <ng-container *ngIf="feature.pro === true; else proFalseBlock">
                                    <i class="far fa-check" style="color: #FFFFFF;"></i>
                                </ng-container>
                                <ng-template #proFalseBlock>
                                    <ng-container *ngIf="feature.pro === false; else proTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #proTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.pro)">
                                            {{feature.pro}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.pro)">
                                            <div>{{feature.pro.value}}</div>
                                            <div *ngIf=feature.pro.limit class="feature-dropdown-row-value-subtext" style="color: #A8D5FF;">{{feature.pro.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.pro.description class="feature-dropdown-row-value-subtext"
                                                style="color: #A8D5FF;">{{feature.pro.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.pro.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.pro.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.pro.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.pro.tooltip.prices">
                                                                {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value">
                                <ng-container *ngIf="feature.plus === true; else plusFalseBlock">
                                    <i class="far fa-check" style="color: #2595ff;"></i>
                                </ng-container>
                                <ng-template #plusFalseBlock>
                                    <ng-container *ngIf="feature.plus === false; else plusTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #plusTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.plus)">
                                            {{feature.plus}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.plus)">
                                            <div>{{feature.plus.value}}</div>
                                            <div *ngIf=feature.plus.limit class="feature-dropdown-row-value-subtext">{{feature.plus.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.plus.description class="feature-dropdown-row-value-subtext">{{feature.plus.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.plus.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.plus.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.plus.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.plus.tooltip.prices">
                                                                {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value">
                                <ng-container *ngIf="feature.businessPlus === true; else businessPlusFalseBlock">
                                    <i class="far fa-check" style="color: #2595ff;"></i>
                                </ng-container>
                                <ng-template #businessPlusFalseBlock>
                                    <ng-container *ngIf="feature.businessPlus === false; else businessPlusTextBlock">
                                        <i class="far fa-times" style="color: #c0c5cb;"></i>
                                    </ng-container>
                                    <ng-template #businessPlusTextBlock>
                                        <ng-container *ngIf="!isFeatureValueAnObject(feature.businessPlus)">
                                            {{feature.businessPlus}}
                                        </ng-container>
                                        <ng-container *ngIf="isFeatureValueAnObject(feature.businessPlus)">
                                            <div>{{feature.businessPlus.value}}</div>
                                            <div *ngIf=feature.businessPlus.limit class="feature-dropdown-row-value-subtext">{{feature.businessPlus.limit}}</div>
                                            <div class="d-flex flex-row justify-content-center align-items-center" 
                                            style="column-gap: 4px;">
                                                <div *ngIf=feature.businessPlus.description class="feature-dropdown-row-value-subtext">{{feature.businessPlus.description}}</div>
                                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.businessPlus.tooltip)">
                                                    <ng-template #popTemplate>
                                                        <div *ngIf = "isMediaEmpty(feature.businessPlus.tooltip?.media)" class="tooltip-content-description d-flex flex-column justify-content-center align-items-start"
                                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                                            <div class="tooltip-content-text" 
                                                            style="margin-bottom: 16px; font-weight: bold;">{{feature.businessPlus.tooltip.description}}
                                                            </div>
                                                            <div *ngFor="let price of feature.businessPlus.tooltip.prices">
                                                                {{price}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <button type="button"
                                                            class="btn tooltip-button"
                                                            [tooltip]="popTemplate"
                                                            placement="auto"
                                                            triggers="hover"
                                                            container="body"
                                                    >
                                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </ng-template>
                            </div>

                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
<!-- for mobile screens -->
<div class="mobile-container">
    <!--option to collapse table-->
    <div
        (click)="isFullCollapsed = !isFullCollapsed"
        class="section-heading-mobile"
        [ngStyle]="((user.subscription.qr.plan === PLAN_TYPES.Plus || (qrUserCount > 1)) && !showBusinessPlans) ? {'margin-top': '-32px'} : {'margin-top': '0px'}"
    >
        See all features and compare plans
    </div>
    <!--white box - sticky header + set of dropdowns-->
    <div class="mb-4" *ngIf="!isFullCollapsed" [@collapse]>
        <ng-container *ngIf="!showBusinessPlans">
            <!--sticky header-->
            <div class="d-flex flex-row justify-content-center align-items-center plan-headers-mobile sticky mb-4" [ngStyle]="pageType === 'renew-plan' ? {'top':'0rem'} : {'top':'3rem'}">
                <!--empty-->
<!--                <div class="d-flex justify-content-start align-items-end plan-header p-3">-->
<!--                    <div class="header-heading">Features</div>-->
<!--                </div>-->
                <!--starter-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2">
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2">{{qrPlansData.individualPlans[0].name}}</div>
                    <div class="plan-price-mobile mb-2">{{qrPlansData.individualPlans[0].price['usd'].symbol + qrPlansData.individualPlans[0].price['usd'].value}}/month</div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Starter) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(0)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Starter) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(0)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Starter
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Starter
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY'
                                                    : user.customer_plan == PLAN_TYPES.Starter
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Starter ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--lite-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2"
                     style="background: #182D44; border-radius: 8px 8px 0px 0px">
                    <div class="d-flex justify-content-center align-items-center recommended-plan-heading-mobile mb-2"
                         style="color: #FFFFFF; column-gap: 4px;">
                        <i class="far fa-bolt" style="font-size: 12px"></i>
                        <span class="">Best Value</span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2"
                         style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                        {{qrPlansData.individualPlans[1].name}}
                    </div>
                    <div class="plan-price-mobile mb-2"
                         style="color: #FFFFFF">
                        {{qrPlansData.individualPlans[1].price['usd'].symbol + qrPlansData.individualPlans[1].price['usd'].value}}/month
                    </div>
                    <div class="buy-btn-recommended-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Lite
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Lite
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY'
                                                    : user.customer_plan == PLAN_TYPES.Lite
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Lite ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--pro-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2">
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2"
                         style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%); color: #16212f">
                        {{qrPlansData.individualPlans[2].name}}
                    </div>
                    <div class="plan-price-mobile mb-2">{{qrPlansData.individualPlans[2].price['usd'].symbol + qrPlansData.individualPlans[2].price['usd'].value}}/month</div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Pro
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Pro
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY'
                                                    : user.customer_plan == PLAN_TYPES.Pro
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Pro ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--plus-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2">
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2"
                         style="background: linear-gradient(90deg, #2EE3EA 0%, #8AE962 100%); color: #16212f">
                        {{qrPlansData.individualPlans[3].name}}
                    </div>
                    <div class="plan-price-mobile mb-2">{{qrPlansData.individualPlans[3].price['usd'].symbol + qrPlansData.individualPlans[3].price['usd'].value}}/month</div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Plus
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Plus
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY'
                                                    : user.customer_plan == PLAN_TYPES.Plus
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Plus ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>
            <!--dropdowns-->
            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-container-mobile mx-auto">
                <ng-container *ngFor="let dropdown of qrPlansData.individualPlanFeatures; let i = index" class="">
                    <div (click)="onDropdownToggle(dropdown)" class="d-flex flex-row justify-content-between align-items-center feature-dropdown-heading-mobile">
                        <div class="d-flex flex-row justify-content-center align-items-center" style="column-gap: 16px">
                            <i [class]="iconClasses[i]" aria-hidden="true" style="color: #2595ff; font-size: 16px"></i>
                            <span class="feature-dropdown-heading-text-mobile">{{ dropdown.name }}</span>
                        </div>
                        <div>
                            <i [@rotate]="isDropdownOpen(dropdown) ? 'open':'closed' " class="far fa-angle-down" style="color: #16212f; font-size: 20px"></i>
                        </div>
                    </div>
                    <ng-container *ngIf="isDropdownOpen(dropdown)" class="">
                        <div *ngFor="let feature of dropdown.features" class="d-flex flex-column justify-content-between align-items-center feature-dropdown-row" [@collapse]>
                            <div class="d-flex flex-row justify-content-start align-items-center feature-dropdown-row-key-mobile">
                                <div>{{ feature.name }}</div>
                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.tooltip)">
                                    <ng-template #popTemplate>
                                        <div *ngIf = "feature.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{feature.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "feature.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{feature.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isMediaEmpty(feature.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                            <div *ngIf="feature.lite.tooltip" style="font-weight: bold;">{{feature.lite.tooltip.description}}</div>
                                            <ng-container *ngIf="toolTipHasPrices(feature.lite?.tooltip)">
                                                <div *ngFor="let price of feature.lite.tooltip.prices">
                                                    {{price}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplate"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797; font-size: 13px"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="d-flex flex-row feature-dropdown-row-values-mobile">
                                <div class="d-flex justify-content-center align-items-center flex-column feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.starter === true; else starterFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #starterFalseBlock>
                                        <ng-container *ngIf="feature.starter === false; else starterTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #starterTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.starter)">
                                                {{feature.starter}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.starter)">
                                                <div>{{feature.starter.value}}</div>
                                                <div *ngIf=feature.starter.limit class="feature-dropdown-row-value-subtext">{{feature.starter.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>

                                <div class="d-flex justify-content-center align-items-center flex-column feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.lite === true; else liteFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #liteFalseBlock>
                                        <ng-container *ngIf="feature.lite === false; else liteTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #liteTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.lite)">
                                                {{feature.lite}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.lite)">
                                                <div>{{feature.lite.value}}</div>
                                                <div *ngIf=feature.lite.limit class="feature-dropdown-row-value-subtext">   {{feature.lite.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>

                                <div class="d-flex justify-content-center align-items-center flex-column feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.pro === true; else proFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #proFalseBlock>
                                        <ng-container *ngIf="feature.pro === false; else proTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #proTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.pro)">
                                                {{feature.pro}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.pro)">
                                                <div>{{feature.pro.value}}</div>
                                                <div *ngIf=feature.pro.limit class="feature-dropdown-row-value-subtext">{{feature.pro.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>

                                <div class="d-flex justify-content-center align-items-center flex-column feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.plus === true; else plusFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #plusFalseBlock>
                                        <ng-container *ngIf="feature.plus === false; else plusTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #plusTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.plus)">
                                                {{feature.plus}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.plus)">
                                                <div>{{feature.plus.value}}</div>
                                                <div *ngIf=feature.plus.limit class="feature-dropdown-row-value-subtext">{{feature.plus.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="showBusinessPlans">
            <!--sticky header-->
            <div class="d-flex flex-row justify-content-center align-items-center plan-headers-mobile sticky mb-4" [ngStyle]="pageType === 'renew-plan' ? {'top':'0rem'} : {'top':'3rem'}">
                <!--empty-->
<!--                <div class="d-flex justify-content-start align-items-end plan-header p-3">-->
<!--                    <div class="header-heading">Features</div>-->
<!--                </div>-->
                <!--lite-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2">
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2"
                         style="background: linear-gradient(90deg, #FF5757 0%, #FF914D 100%);">
                        {{qrPlansData.businessPlans[0].name}}
                    </div>
                    <div class="plan-price-mobile mb-2">{{qrPlansData.businessPlans[0].price['usd'].symbol + qrPlansData.businessPlans[0].price['usd'].value}}/month</div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Lite) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(1)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Lite
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Lite
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Lite
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Lite ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--pro-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2"
                     style="background: #182D44; border-radius: 8px 8px 0px 0px">
                    <div class="d-flex justify-content-center align-items-center recommended-plan-heading-mobile mb-2"
                         style="color: #FFFFFF; column-gap: 4px;">
                        <i class="far fa-bolt" style="font-size: 12px"></i>
                        <span class="">Best Value</span>
                    </div>
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2"
                         style="background: linear-gradient(90deg, #FFCC00 0%, #FFDE59 100%); color: #16212f">
                        {{qrPlansData.businessPlans[1].name}}
                    </div>
                    <div class="plan-price-mobile mb-2"
                         style="color: #FFFFFF">
                        {{qrPlansData.businessPlans[1].price['usd'].symbol + qrPlansData.businessPlans[1].price['usd'].value}}/month
                    </div>
                    <div class="buy-btn-recommended-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-recommended-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Pro) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(2)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Pro
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Pro
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Pro
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Pro ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--plus-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2">
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2"
                         style="background: linear-gradient(90deg, #2EE3EA 0%, #8AE962 100%); color: #16212f">
                        {{qrPlansData.businessPlans[2].name}}
                    </div>
                    <div class="plan-price-mobile mb-2">{{qrPlansData.businessPlans[2].price['usd'].symbol + qrPlansData.businessPlans[2].price['usd'].value}}/month</div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'BUY'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Plus) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(3)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Plus
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Plus
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="
                                                  user.customer_plan == PLAN_TYPES.Trial
                                                    ? 'BUY NOW'
                                                    : user.customer_plan == PLAN_TYPES.Plus
                                                    ? 'CURRENT PLAN'
                                                    : 'UPGRADE'
                                                "
                                           [disabled]="user.customer_plan == PLAN_TYPES.Plus ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
                <!--business+-->
                <div class="d-flex flex-column justify-content-end align-items-start plan-header-mobile p-2">
                    <div class="d-flex justify-content-center align-items-center plan-name-mobile mb-2"
                         style=" background: linear-gradient(90deg, #6A52FF 0%, #A96CE6 100%); color: #FFFFFF">
                        {{qrPlansData.businessPlans[3].name}}
                    </div>
                    <div class="d-flex flex-row justify-content-center align-items-center plan-price-mobile mb-2">
                        {{qrPlansData.businessPlans[3].details.widget.pricing}}
                    </div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'renew-plan'"
                         [ngStyle]="showBuyOptionOnRenewPage(PLAN_TYPES.Enterprise) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(4)"
                                           [type]="BUTTON_TYPES.PRIMARY"
                                           [width]="'100%'"
                                           [style]="BUTTON_STYLES.STROKE"
                                           [label]="'TALK TO US'"
                        ></beaconstac-button>
                    </div>
                    <div class="buy-btn-mobile" *ngIf = "pageType == 'upgrade-now'"
                         [ngStyle]="showBuyOption(PLAN_TYPES.Enterprise) ? {'visibility': 'visible'} : {'visibility': 'hidden'}">
                        <beaconstac-button (onClick)="handleSelectPlanButton(4)"
                                           [type]="
                                                       user.customer_plan == PLAN_TYPES.Enterprise
                                                       ? BUTTON_TYPES.SECONDARY
                                                       : BUTTON_TYPES.PRIMARY
                                                  "
                                           [width]="'100%'"
                                           [style]="
                                                        user.customer_plan == PLAN_TYPES.Enterprise
                                                        ? BUTTON_STYLES.FILLED
                                                        : BUTTON_STYLES.STROKE
                                                    "
                                           [label]="'TALK TO US'"
                                           [disabled]="user.customer_plan == PLAN_TYPES.Enterprise ? true : false"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>
            <!--dropdowns-->
            <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-container-mobile mx-auto">
                <ng-container *ngFor="let dropdown of qrPlansData.businessPlanFeatures; let i = index" class="">
                    <div (click)="onDropdownToggle(dropdown)" class="d-flex flex-row justify-content-between align-items-center feature-dropdown-heading-mobile">
                        <div class="d-flex flex-row justify-content-center align-items-center" style="column-gap: 16px">
                            <i [class]="iconClasses[i]" aria-hidden="true" style="color: #2595ff; font-size: 16px"></i>
                            <span class="feature-dropdown-heading-text-mobile">{{ dropdown.name }}</span>
                        </div>
                        <div>
                            <i [@rotate]="isDropdownOpen(dropdown) ? 'open':'closed' " class="far fa-angle-down" style="color: #16212f; font-size: 20px"></i>
                        </div>
                    </div>
                    <ng-container *ngIf="isDropdownOpen(dropdown)" class="">
                        <div *ngFor="let feature of dropdown.features" class="d-flex flex-column justify-content-between align-items-center feature-dropdown-row" [@collapse]>
                            <div class="d-flex flex-row justify-content-start align-items-center feature-dropdown-row-key-mobile">
                                <div>{{ feature.name }}</div>
                                <div class="tooltip-parent" *ngIf="!isMediaEmpty(feature.tooltip)">
                                    <ng-template #popTemplate>
                                        <div *ngIf = "feature.tooltip.media?.type === 'video'" class="tooltip-content-video-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <video src="{{feature.tooltip.media?.src}}"
                                                   width="100%"
                                                   height="100%"
                                                   autoplay
                                                   style="border-radius: 16px"
                                            >
                                            </video>
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "feature.tooltip.media?.type === 'image'" class="tooltip-content-image-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <img src="{{feature.tooltip.media.src}}"
                                                 width="100%"
                                                 height="100%"
                                                 style="border-radius: 16px"
                                            >
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                        </div>
                                        <div *ngIf = "isMediaEmpty(feature.tooltip?.media)" class="tooltip-content-description-mobile d-flex flex-column justify-content-center align-items-start"
                                             style="pointer-events: auto; row-gap: 8px; opacity: 1">
                                            <div class="tooltip-content-text">{{feature.tooltip.description}}</div>
                                            <div *ngIf="feature.lite.tooltip" style="font-weight: bold;">{{feature.lite.tooltip.description}}</div>
                                            <ng-container *ngIf="toolTipHasPrices(feature.lite?.tooltip)">
                                                <div *ngFor="let price of feature.lite.tooltip.prices">
                                                    {{price}}
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-template>
                                    <button type="button"
                                            class="btn tooltip-button"
                                            [tooltip]="popTemplate"
                                            placement="auto"
                                            triggers="hover"
                                            container="body"
                                    >
                                        <i class="fal fa-info-circle" aria-hidden="true" style="color: #979797; font-size: 13px"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="d-flex flex-row feature-dropdown-row-values-mobile">
                                <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.lite === true; else liteFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #liteFalseBlock>
                                        <ng-container *ngIf="feature.lite === false; else liteTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #liteTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.lite)">
                                                {{feature.lite}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.lite)">
                                                <div>{{feature.lite.value}}</div>
                                                <div *ngIf=feature.lite.limit class="feature-dropdown-row-value-subtext">{{feature.lite.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>

                                <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.pro === true; else proFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #proFalseBlock>
                                        <ng-container *ngIf="feature.pro === false; else proTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #proTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.pro)">
                                                {{feature.pro}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.pro)">
                                                <div>{{feature.pro.value}}</div>
                                                <div *ngIf=feature.pro.limit class="feature-dropdown-row-value-subtext">{{feature.pro.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>

                                <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.plus === true; else plusFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #plusFalseBlock>
                                        <ng-container *ngIf="feature.plus === false; else plusTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #plusTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.plus)">
                                                {{feature.plus}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.plus)">
                                                <div>{{feature.plus.value}}</div>
                                                <div *ngIf=feature.plus.limit class="feature-dropdown-row-value-subtext">{{feature.plus.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>

                                <div class="d-flex flex-column justify-content-center align-items-center feature-dropdown-row-value-mobile">
                                    <ng-container *ngIf="feature.businessPlus === true; else businessPlusFalseBlock">
                                        <i class="far fa-check" style="color: #2595ff;"></i>
                                    </ng-container>
                                    <ng-template #businessPlusFalseBlock>
                                        <ng-container *ngIf="feature.businessPlus === false; else businessPlusTextBlock">
                                            <i class="far fa-times" style="color: #c0c5cb;"></i>
                                        </ng-container>
                                        <ng-template #businessPlusTextBlock>
                                            <ng-container *ngIf="!isFeatureValueAnObject(feature.businessPlus)">
                                                {{feature.businessPlus}}
                                            </ng-container>
                                            <ng-container *ngIf="isFeatureValueAnObject(feature.businessPlus)">
                                                <div>{{feature.businessPlus.value}}</div>
                                                <div *ngIf=feature.businessPlus.limit class="feature-dropdown-row-value-subtext">{{feature.businessPlus.limit}}
                                                </div>
                                            </ng-container>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
