import {Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, ViewChild, OnDestroy} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {BUTTON_STYLES, BUTTON_TYPES, TEXT_FIELD_TYPES, TEXT_FIELD_VALIDATIONS} from 'kaizen-design-system';
import {Animations} from '../../../shared/beaconstac-animations';
import {DigitalBusinessCard, DigitalBusinessCardTemplateModel} from '../../digital-business-card.model';
import {debounceTime, finalize, takeUntil, map, distinctUntilChanged} from 'rxjs/operators';
import {DBCCardTypes, PRODUCT_TYPES, Utils} from '../../../shared/utils';
import {Subject, fromEvent, Subscription} from 'rxjs';
import {CustomSlugValidationService} from '../../digital-business-cards.service';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../../digital-business-card-setting/digital-business-card-setting.model';
import {UpsellService} from '../../../global-services/upsell.service';
import {User} from '../../../user-account/user.model';
import {AuthService} from '../../../global-services/auth.service';
import {FEATURES} from '../../../user-account/organization-permission-model';
import {QRCodeTemplate} from 'app/qr-template/qr-template.model';
import {QRTemplateService} from 'app/qr-template/qr-template.service';
import {MessageModalService} from 'app/shared/message-modal/message-modal.service';
import {CustomDomain} from 'app/user-account/custom-domain.model';
import {CustomDomainService} from 'app/user-account/custom-domain.service';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../../user-account/user-organization.model';
import {TagsService} from '../../../tags/tags.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {SearchParams} from '../../../global-services/base-backend.service';
import {FEATURE_FLAGS} from '../../../shared/feature-flags';

@Pipe({ name: 'getSlugFromUrl' })
export class GetSlugFromUrl implements PipeTransform {
    transform(url: string): string {
        return url.split('/')[url.split('/').length - 1];
    }
}

@Component({
    selector: 'app-digital-business-card-launch',
    templateUrl: './digital-business-card-launch.component.html',
    providers: [CustomSlugValidationService],
    styleUrls: [
        './digital-business-card-launch.component.scss',
        './../../../link-page/link-page-detail/link-page-detail.component.scss',
    ],
    animations: [Animations.collapse],
})
export class DigitalBusinessCardLaunchComponent implements OnInit, OnDestroy {
    @Input() product: DigitalBusinessCard | DigitalBusinessCardTemplateModel = null;
    @Output() productChange: EventEmitter<DigitalBusinessCard> = new EventEmitter();
    @Input() hasWriteAccess = true;
    @Input() isNewDigitalBusinessCard: boolean = true;
    @Input() customSlugValidations: any;
    @Input() cardSetting: DigitalBusinessCardSetting;
    @Input() orgCardSetting: CardsOrganizationRestrictedFields;
    @Input() cardType: DBCCardTypes;
    @Input() validations: {
        first_name: boolean,
        phone: boolean,
        email_v2: boolean,
        website_v2: boolean,
        phone_v2: boolean,
        template_name: boolean };
    @Input() priortizeOrgSettings: boolean = false
    @Input() launchDarkleyFlags: any = {}
    @Input() isSalesforceConnected: boolean = false;

    @Output() cardSettingChange: EventEmitter<any> = new EventEmitter<any>();

    @Output() customSlugValidationsChange: EventEmitter<any> = new EventEmitter();
    @Output() validationsChange: EventEmitter<any> = new EventEmitter();

    ngUnsubscribe: Subject<any> = new Subject();
    templates: Map<number, QRCodeTemplate> = new Map<number, QRCodeTemplate>();
    Templates: any;

    customSlugDisabled: boolean = true;
    domains = [{ name: environment.dbcDomain, domain: environment.dbcDomain, value: 1 }];
    BUTTON_TYPES = BUTTON_TYPES;
    BUTTON_STYLES = BUTTON_STYLES;
    sections = { advancedSettings: false , urlDomain : false , qrCodeTemplates : false  , templateProperties : false, labels: false, lead_generation: false};
    slugValidation: TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS.VALID;
    slugValidationError = '';
    customSlugChanged = new Subject<string>();
    Utils = Utils;
    hasShortUrlAccess: boolean;
    user: User;
    isOnDbcTrial: boolean;
    isFrameLoadingSubject = new Subject<boolean>();
    isFrameLoading: boolean = false;
    contentChangeSubject = new Subject<null>();
    isFetchingTemplates: boolean = false;
    fetchedAllTemplates: boolean = false;
    currentTemplatePage: number = 1;
    selectedTemplate: number | null = null;
    leadSettings: any[];
    leadSettingDisable: boolean;
    isMobileScreenSize: boolean;
    isComputerScreenSize: boolean;

    tagList = []
    isFetchingTags = false;

    @ViewChild('tagUntagModal', { static: true }) tagUntagModal: ModalDirective;

    constructor(
        private customSlugValidationService: CustomSlugValidationService,
        private upsellService: UpsellService,
        private authService: AuthService,
        private templateService: QRTemplateService,
        private messageModal: MessageModalService,
        private customDomainService: CustomDomainService,
        private tagService: TagsService,
    ) {
        this.setUpFeatures();
        this.isNewDigitalBusinessCard = this.authService.getUser().hasWriteAccess(this.authService.getCurrentOrgId());
        if (this.authService.isSafeSuperUser()) {
            this.isNewDigitalBusinessCard = false;
        }
    }
    resizeSubject = new Subject();
    resizeSubscription: Subscription;
    ngOnInit(): void {
        this.isMobileScreen();
        this.isComputerScreen();
        this.checkForMobileView();
        this.resizeSubscription = fromEvent(window, 'resize')
            .pipe(
                map(() => window.innerWidth),
                debounceTime(100),
                distinctUntilChanged()
            )
            .subscribe((width: number) => {
                this.resizeSubject.next(width);
            });

        this.resizeSubject.subscribe((width: number) => {
            this.isMobileScreenSize = width <= 767;
            this.isComputerScreenSize = width >= 768;
            this.checkForMobileView();
        });
        this.customSlugChanged.pipe(debounceTime(1000)).subscribe((event) => {
            if (!this.Utils.alphaNumericRegex.test(event)) {
                this.slugValidation = TEXT_FIELD_VALIDATIONS.ERROR;
                this.slugValidationError = 'Invalid characters in short URL';
                this.customSlugValidations['isValidDomain'] = false;
                this.customSlugValidationsChange.emit(this.customSlugValidations);
            } else if (event.toString().length > 60) {
                this.slugValidation = TEXT_FIELD_VALIDATIONS.ERROR;
                this.slugValidationError = 'Short URL length cannot exceed 60';
                this.customSlugValidations['isValidDomain'] = false;
                this.customSlugValidationsChange.emit(this.customSlugValidations);
            } else {
                this.checkIfCustomSlugExists(event);
            }
        });

        const customDomainId = this.authService.getCurrentOrganization().domains;
        if (customDomainId.length && this.authService.getUser().isSuperAdmin()) {
            this.customDomainService
                .getData(customDomainId[0])
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((res) => {
                    const { is_dbc_configured, domain, id } = new CustomDomain(res);
                    if (is_dbc_configured) {
                        const name = environment.production ? `smartcards.${domain}` : `q.smartcards.${domain}`;
                        this.domains = [{ name, value: id, domain: environment.dbcDomain }];
                    }
                });
        }

        this.contentChangeSubject.pipe(debounceTime(1000)).subscribe((event) => {
            this.onVCardContentChange();
        });
        this.isFrameLoadingSubject.pipe(debounceTime(100)).subscribe((event) => {
            this.isFrameLoading = event;
        });
        this.fetchTemplates();
        this.leadSettingDisable = !this.hasWriteAccess || (this.cardSetting.lead_collection && this.cardType !== DBCCardTypes.TEMPLATE_CARDS);
        this.leadSettings = [
            { name: 'name', label: 'Name', disable: true, default: true },
            { name: 'email', label: 'Email address', disable: true, default: true },
            { name: 'phone', label: 'Phone', disable: this.leadSettingDisable, default: false },
            { name: 'company', label: 'Company', disable: true, default: false },
            { name: 'designation', label: 'Job title', disable: this.leadSettingDisable, default: false },
            { name: 'notes', label: 'Message', disable: this.leadSettingDisable, default: false },
        ];

        this.authService.storageChange$.pipe(takeUntil(this.ngUnsubscribe), debounceTime(200)).subscribe((res) => {
            this.setUpFeatures();
        });

        this.fetchLabels()
    }

    fetchLabels() {
        const params: SearchParams = {
            'product_type': 'dbc',
            'label_type': 'dbc'
        };
        this.tagService
            .getList(1, 20, params,  true, 'updated')
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (result) => {
                    this.tagList = result.objects;
                    this.isFetchingTags = false;
                },
                (error) => {
                    this.isFetchingTags = false;
                    this.messageModal.show('Error fetching labels. Please try refreshing the page.', 'danger');
                },
            );
    }
    checkForMobileView() {
        if (this.isMobileScreenSize) {
            let checkSectionKey = 'urlDomain';
            if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
                checkSectionKey = 'templateProperties';
            }
            Object.keys(this.sections).forEach(key => {
                this.sections[key] = true;
            });
            this.sections[checkSectionKey] = false;
        } else {
            Object.keys(this.sections).forEach(key => {
                this.sections[key] = false;
            });
        }
    }
    isMobileScreen() {
        this.isMobileScreenSize =  window.innerWidth <= 767;
    }
    isComputerScreen() {
        this.isComputerScreenSize = window.innerWidth >= 768;
    }
    setUpFeatures() {
        this.user = this.authService.getUser();
        this.hasShortUrlAccess = this.user.hasDbcPermissionFor(FEATURES.custom_slug);
        this.isOnDbcTrial = this.user.isOnTrialPlan(PRODUCT_TYPES.DBC);
    }

    toggleCustomSlugDisabled() {
        this.customSlugDisabled = !this.customSlugDisabled;
        if (this.customSlugDisabled) {
            this.product.slug = '';
            this.slugValidation = TEXT_FIELD_VALIDATIONS.VALID;
            this.customSlugValidations['autoToggled'] = true;
            this.customSlugValidations['isValidDomain'] = true;
        } else {
            this.customSlugValidations['autoToggled'] = false;
            if (this.product.slug && this.product.slug.length > 0) {
                this.customSlugChanged.next(this.product.slug);
            } else {
                this.customSlugValidations['isValidDomain'] = false;
            }
        }
        this.productChange.emit(this.product);
    }

    resetAutoSlug() {
        setTimeout(() => {
            this.customSlugDisabled = true;
        }, 100);
    }

    openUpsellModal(showUpgrade: boolean) {
        if (!showUpgrade) {
            this.upsellService.show(
                'This feature is available on Team plan!',
                'Upgrade to our Team plan to add personal branding and organize your cards by editing the short URL.',
                null,
                'Try Team plan',
                null,
                null,
                null,
                null,
                null,
                'TM',
            );
        } else {
            this.upsellService.show(
                'This feature is available on Team plan!',
                'Upgrade to our Team plan to add personal branding and organize your cards by editing the short URL.',
                null,
                'Upgrade Plan',
                'custom-slug-cta'
            );
        }
    }

    collapseSection(section) {
        if (this.isMobileScreenSize) {
            if (this.sections[section]) {
                Object.keys(this.sections).forEach(key => {
                    if (this.sections[key] !== this.sections[section]) {
                        this.sections[key] = true;
                    }
                });
            }
            this.sections[section] = !this.sections[section];
        } else {
            this.sections[section] = !this.sections[section];
        }
    }

    checkIfCustomSlugExists(value) {
        this.customSlugValidationService
            .post({ url: `https://${this.domains[0].domain}/${value}` })
            .pipe()
            .subscribe(
                () => {
                    this.slugValidation = TEXT_FIELD_VALIDATIONS.VALID;
                    this.customSlugValidations['isValidDomain'] = true;
                    this.customSlugValidationsChange.emit(this.customSlugValidations);
                },
                () => {
                    this.slugValidation = TEXT_FIELD_VALIDATIONS.ERROR;
                    this.slugValidationError = 'This URL is already taken';
                    this.customSlugValidations['isValidDomain'] = false;
                    this.customSlugValidationsChange.emit(this.customSlugValidations);
                },
            );
    }

    onCustomSlugContentChange(value) {
        this.customSlugValidations['autoToggled'] = false;
        this.customSlugChanged.next(value);
        this.product.slug = value;
    }

    leadCollectionToggle() {
        this.onVCardContentChange();
    }

    toggleLeadSetting(event: boolean, leadSetting: any) {
        this.product.lead_attribute[leadSetting.name] = event;
        this.onVCardContentChange();
    }

    onVCardContentChange() {
        this.productChange.emit(this.product);
    }

    fetchTemplates(page: number = 1) {
        this.isFetchingTemplates = true;
        this.templateService
            .getList(page, 11, { org_templates: 'true' }, true, '-updated')
            .pipe(
                takeUntil(this.ngUnsubscribe),
                finalize(() => (this.isFetchingTemplates = false)),
            )
            .subscribe(
                (response) => {
                    this.fetchedAllTemplates = page === response.pageCount;
                    this.currentTemplatePage = page;
                    this.insertTemplatesIntoMap(response.objects);
                },
                (error) => {
                    this.messageModal.show('Error fetching templates', 'danger');
                    console.error(error);
                },
            );
    }

    insertTemplatesIntoMap(templateArray: Array<QRCodeTemplate>) {
        templateArray.forEach((template) => this.templates.set(template.id, template));
        this.Templates = Array.from(this.templates.values());
    }

    onTemplateScroll() {
        if (this.fetchedAllTemplates || this.isFetchingTemplates) {
            return;
        }
        this.fetchTemplates(this.currentTemplatePage + 1);
    }

    onSelectTemplate(item) {
        if ((this.cardType !== DBCCardTypes.TEMPLATE_CARDS) && this.cardSetting.template) {
            return;
        }
        if (this.product.template?.id !== item.id) {
            this.product.template = item;
            this.selectedTemplate = item.id;
        } else {
            this.product.template = {};
            this.selectedTemplate = null;
        }
        this.onVCardContentChange();
    }

    scrollTemplate(id: string) {
        let sign = 1;
        if (id === 'scroll-left-template') {
            sign = -1;
        }
        document.getElementById('template-list').scrollLeft += sign * 300;
    }

    hideScroller() {
        const template = document.getElementById('template-list');
        if (template.scrollLeft === 0) {
            document.getElementById('scroll-left-template').classList.remove('d-flex');
        } else {
            document.getElementById('scroll-left-template').classList.add('d-flex');
        }

        if (template.offsetWidth + template.scrollLeft >= template.scrollWidth - 5) {
            document.getElementById('scroll-right-template').classList.remove('d-flex');
            document.getElementById('scroll-right-template').classList.add('d-none');
        } else {
            document.getElementById('scroll-right-template').classList.add('d-flex');
        }
    }

    handleShortUrlToggle() {
        if (this.hasShortUrlAccess) {
            this.toggleCustomSlugDisabled();
        } else {
            this.resetAutoSlug();
            if (this.user.isOnSoloTrial()) {
                this.openUpsellModal(false);
            } else {
                this.openUpsellModal(true);
            }
        }
    }

    handleIconClick(property: CARD_PROPERTY_TYPES) {
        if (this.cardType === DBCCardTypes.TEMPLATE_CARDS) {
            switch (property) {
                case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                    if (this.orgCardSetting[property] === DBC_ORG_PERMISSION_OPTIONS.OPTIONAL) {
                        this.cardSetting[CARD_PROPERTY_TYPES.AUTODOWNLOAD] = !this.cardSetting[CARD_PROPERTY_TYPES.AUTODOWNLOAD]
                    }
                    break;
                case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                    if (this.orgCardSetting[property] === DBC_ORG_PERMISSION_OPTIONS.OPTIONAL) {
                        this.cardSetting[CARD_PROPERTY_TYPES.LEAD_COLLECTION] = !this.cardSetting[CARD_PROPERTY_TYPES.LEAD_COLLECTION]
                    }
                    break;
                case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                    if (this.orgCardSetting[property] === DBC_ORG_PERMISSION_OPTIONS.OPTIONAL) {
                        this.cardSetting[CARD_PROPERTY_TYPES.LOCATION_ENABLED] = !this.cardSetting[CARD_PROPERTY_TYPES.LOCATION_ENABLED]
                    }
                    break;
                case CARD_PROPERTY_TYPES.EMAIL_WALLET_PASS:
                    break;
                case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                    if (this.orgCardSetting[property] === DBC_ORG_PERMISSION_OPTIONS.OPTIONAL) {
                        this.cardSetting[CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED] = !this.cardSetting[CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED]
                    }
                    break;
                default:
                    if (!this.orgCardSetting[property]) {
                        this.cardSetting[property] = !this.cardSetting[property]
                    }
                    break;
            }
            this.cardSettingChange.emit(this.cardSetting);
            this.onVCardContentChange();
        }
    }

    handleAutoDownloadToggle(event) {
        this.product.autodownload_v2 = event;
        this.onVCardContentChange();
    }

    openTagModal() {
        this.tagUntagModal.show();
    }

    attachSelectedLabels(event) {
        this.product.tags_data = event;
        this.product.tags = event.map((tag) => tag.id);
        this.onVCardContentChange();
    }

    toggleLeadCollection(event) {
        this.product.lead_collection = event;
        this.onVCardContentChange();
    }

    ngOnDestroy(): void {
        if (this.contentChangeSubject) {
            this.contentChangeSubject.unsubscribe();
        }
        if (this.isFrameLoadingSubject) {
            this.isFrameLoadingSubject.unsubscribe();
        }
        if (this.resizeSubscription) {
            this.resizeSubscription.unsubscribe();
        }
    }

    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;
    protected readonly DBCCardTypes = DBCCardTypes;
    protected readonly TEXT_FIELD_TYPES = TEXT_FIELD_TYPES;
    protected readonly DBC_ORG_PERMISSION_OPTIONS = DBC_ORG_PERMISSION_OPTIONS;
    protected readonly TEXT_FIELD_VALIDATIONS = TEXT_FIELD_VALIDATIONS;
    protected readonly FEATURE_FLAGS = FEATURE_FLAGS;
}
