import {Component, Input, OnInit} from '@angular/core';
import {DigitalBusinessCardBulkModel} from '../../digital-business-card.model';
import {
    CARD_PROPERTY_TYPES,
    DigitalBusinessCardSetting
} from '../../digital-business-card-setting/digital-business-card-setting.model';
import {finalize, takeUntil} from 'rxjs/operators';
import {QRCodeTemplate} from '../../../qr-template/qr-template.model';
import { QRTemplateService } from '../../../qr-template/qr-template.service';
import {Subject} from 'rxjs';
import {User} from '../../../user-account/user.model';
import {AuthService} from '../../../global-services/auth.service';
import {MessageModalService} from '../../../shared/message-modal/message-modal.service';
import {Animations} from '../../../shared/beaconstac-animations';
import {DBCCardTypes} from '../../../shared/utils';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../../user-account/user-organization.model';
import {FEATURE_FLAGS} from '../../../shared/feature-flags';
import {BUTTON_STYLES, BUTTON_TYPES} from 'kaizen-design-system';

@Component({
    selector: 'app-digital-business-card-bulk-launch',
    templateUrl: './digital-business-card-bulk-launch.component.html',
    styleUrls: ['./digital-business-card-bulk-launch.component.scss',
        './../../digital-business-card-detail/digital-business-card-launch/digital-business-card-launch.component.scss'],
    animations: [Animations.collapse]
})
export class DigitalBusinessCardBulkLaunchComponent implements OnInit {

    @Input() hasWriteAccess: boolean = true;
    @Input() cardSettings: DigitalBusinessCardSetting;
    @Input() orgCardSettings: CardsOrganizationRestrictedFields;
    @Input() priortizeOrgSettings: boolean = false;
    @Input() launchDarkleyFlags: {};
    @Input() isSalesforceConnected: boolean = false;

    @Input() dbcBulk: DigitalBusinessCardBulkModel;

    fetchedAllTemplates: boolean = false;
    isFetchingTemplates: boolean = false;
    currentTemplatePage: number = 1;
    templates: Map<number, QRCodeTemplate> = new Map<number, QRCodeTemplate>();
    Templates: any;
    selectedTemplate: QRCodeTemplate;
    user: User;
    userIsAdmin: boolean;

    ngUnsubscribe: Subject<any> = new Subject();
    leadSettings: any[];
    leadSettingDisable: boolean;

    cardType: DBCCardTypes = DBCCardTypes.BULK_CARDS;

    constructor(private templateService: QRTemplateService, private messageModal: MessageModalService,
        private authService: AuthService) { }

    ngOnInit(): void {
        this.user = this.authService.getUser();
        const orgId = this.authService.getCurrentOrgId()
        this.userIsAdmin = this.user.isAdmin(orgId)
        if (this.userIsAdmin) {
            this.dbcBulk.send_user_invite_email = true
        }
        this.fetchTemplates(1);
        this.leadSettingDisable = !this.hasWriteAccess || this.cardSettings.lead_collection;
        this.leadSettings = [
            {name: 'name', label: 'Name', disable: true, default: true},
            {name: 'email', label: 'Email address', disable: true, default: true},
            {name: 'phone', label: 'Phone', disable: this.leadSettingDisable, default: false},
            {name: 'company', label: 'Company', disable: true, default: false},
            {name: 'designation', label: 'Job title', disable: this.leadSettingDisable, default: false},
            {name: 'notes', label: 'Message', disable: this.leadSettingDisable, default: false}
        ];
    }

    fetchTemplates(page: number = 1) {
        this.isFetchingTemplates = true;
        this.templateService.getList(page, 11, { org_templates: 'true' }, true, '-updated')
            .pipe(takeUntil(this.ngUnsubscribe),
                finalize(() => this.isFetchingTemplates = false)
            )
            .subscribe(response => {
                this.fetchedAllTemplates = page === response.pageCount;
                this.currentTemplatePage = page;
                this.insertTemplatesIntoMap(response.objects);
            }, error => {
                this.messageModal.show('Error fetching templates', 'danger');
                console.error(error)
            });
    }

    insertTemplatesIntoMap(templateArray: Array<QRCodeTemplate>) {
        templateArray.forEach((template) => this.templates.set(template.id, template));
        this.Templates = Array.from(this.templates.values());
    }

    onTemplateScroll() {
        if (this.fetchedAllTemplates || this.isFetchingTemplates) {
            return;
        }
        this.fetchTemplates(this.currentTemplatePage + 1);
    }

    onSelectTemplate(item) {
        if (this.dbcBulk.template?.id !== item.id) {
            this.dbcBulk.template = item;
            this.selectedTemplate = item.id;
        } else {
            this.dbcBulk.template = {};
            this.selectedTemplate = null;
        }
    }

    scrollTemplate(id: string) {
        let sign = 1;
        if (id === 'scroll-left-template') {
            sign = -1;
        }
        document.getElementById('template-list').scrollLeft += (sign * 300);
    }

    hideScroller() {
        const template = document.getElementById('template-list')
        if (template.scrollLeft === 0) {
            document.getElementById('scroll-left-template').classList.remove('d-flex');
        } else {
            document.getElementById('scroll-left-template').classList.add('d-flex');
        }

        if (template.offsetWidth + template.scrollLeft >= template.scrollWidth - 5) {
            document.getElementById('scroll-right-template').classList.remove('d-flex');
            document.getElementById('scroll-right-template').classList.add('d-none');
        } else {
            document.getElementById('scroll-right-template').classList.add('d-flex');
        }
    }

    toggleLeadSetting(event, leadSetting) {
        this.dbcBulk.lead_attribute[leadSetting.name] = event;
    }

    protected readonly CARD_PROPERTY_TYPES = CARD_PROPERTY_TYPES;
    protected readonly DBC_ORG_PERMISSION_OPTIONS = DBC_ORG_PERMISSION_OPTIONS;
    protected readonly FEATURE_FLAGS = FEATURE_FLAGS;
    protected readonly BUTTON_TYPES = BUTTON_TYPES;
    protected readonly BUTTON_STYLES = BUTTON_STYLES;
}
