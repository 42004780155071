<div>
  <beaconstac-title
    [title]="pageTitle"
    [subtitle]="pageSubtitle"
    [primaryAction]="!isOnEnterprisePlan"
    [primaryActionTitle]="'Upgrade'"
    (onClickPrimaryAction)="routeToChangePlan()"
    [secondaryAction]="true"
    [secondaryActionTitle]="'API documentation'"
    [secondaryActionButtonType]="BUTTON_TYPES.SECONDARY"
    (onClickSecondaryAction)="redirectToAPIDocs()"
  ></beaconstac-title>
  <div *ngIf = 'isOnTrialPlan' style="margin-top: 24px; margin-bottom: 24px;" [@collapse]>
    <beaconstac-message [type]="MESSAGE_TYPES.PRIMARY" [message]="trialPlanMessage"></beaconstac-message>
  </div>
  <div style="background: #FFFFFF; border: 1px solid #E4E5E7; margin-top: 32px; padding: 32px 32px 8px 32px">
    <div class="bx--row">

      <div class="bx--col-lg-5 bx--col-sm-4 bx--col-md-4 mb-4">
        <p class="text-secondary text-uppercase"
           style="font-size: 13px; font-style: normal; font-weight: 700; line-height: 15px;
              letter-spacing: 1px; text-align: left; flex: auto">
          YOUR API KEY
        </p>
        <p style="font-size: 20px;font-style: normal;font-weight: 400;line-height: 23px;
        letter-spacing: 0;text-align: left;" [ngStyle]="{'color': !hasApiAccess ? '#C0C5CB' : ''}">
          *****************
          <beaconstac-button class="ml-2" *ngIf="!hasApiAccess" (onClick)="openUpsellModal(FEATURES.api)" [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.TEXT" [label]="'Get API key'"></beaconstac-button>
          <beaconstac-button (onClick)="copyText(COPY_TEXT_TYPES.TOKEN_KEY)" class="ml-2" *ngIf="hasApiAccess" [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.TEXT" [label]="''" [icon]="'far fa-copy'"></beaconstac-button>
        </p>

      </div>
      <div class="bx--col-lg-3 bx--col-sm-4 bx--col-md-4 mb-4">
        <p class="text-secondary text-uppercase"
           style="font-size: 13px; font-style: normal; font-weight: 700; line-height: 15px;
              letter-spacing: 1px; text-align: left; flex: auto">
          ORGANIZATION ID
        </p>
        <p style="font-size: 20px;font-style: normal;font-weight: 400;line-height: 23px;
        letter-spacing: 0;text-align: left;" [ngStyle]="{'color': !hasApiAccess ? '#C0C5CB' : ''}">
          {{currentOrganizationId}}
          <beaconstac-button (onClick)="copyText(COPY_TEXT_TYPES.ORGANIZATION_ID)" class="ml-2" [type]="BUTTON_TYPES.PRIMARY" [style]="BUTTON_STYLES.TEXT" [label]="''" [icon]="'far fa-copy'"></beaconstac-button>
        </p>

      </div>
      <div class="bx--col-lg-4 bx--col-sm-4 bx--col-md-4 mb-4">
        <p class="text-secondary text-uppercase"
           style="font-size: 13px; font-style: normal; font-weight: 700; line-height: 15px;
              letter-spacing: 1px; text-align: left; flex: auto">
          RATE LIMIT
        </p>
        <p style="font-size: 20px;font-style: normal;font-weight: 400;line-height: 23px;
        letter-spacing: 0;text-align: left;" [ngStyle]="{'color': !hasApiAccess ? '#C0C5CB' : ''}">
          {{isOnEnterprisePlan ? '100 requests/second' : '10 requests/second'}}
        </p>
      </div>
      <div class="bx--col-lg-4 bx--col-sm-4 bx--col-md-4 mb-4">
        <p class="text-secondary text-uppercase"
           style="font-size: 13px; font-style: normal; font-weight: 700; line-height: 15px;
              letter-spacing: 1px; text-align: left; flex: auto">
          MONTHLY USAGE LIMIT
        </p>
        <p style="font-size: 20px;font-style: normal;font-weight: 400;line-height: 23px;
        letter-spacing: 0;text-align: left;" [ngStyle]="{'color': !hasApiAccess ? '#C0C5CB' : ''}">
          {{isOnEnterprisePlan ? '2,000,000 requests' : '250,000 requests'}}
        </p>
      </div>

    </div>
  </div>
</div>
