import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesforceComponent } from './salesforce.component';
import { SalesforceRouterModule } from './salesforce.routing.module';
import { BeaconstacModule } from 'kaizen-design-system';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SalesforceService } from './salesforce.service';
@NgModule({
    imports: [
        CommonModule,
        SalesforceRouterModule,
        BeaconstacModule,
        ModalModule
    ],
    declarations: [
        SalesforceComponent
    ],
    exports: [],
    providers: [
        SalesforceService
    ]
})

export class SalesforceModule{

}
