import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeaconstacModule } from 'kaizen-design-system';
import { BeaconstacMobileDrawerModule } from '../beaconstac-mobile-drawer/beaconstac-mobile-drawer.module';
import { FormsModule } from '@angular/forms';
import {TermsServiceComponent} from './terms-service.component';
import {PipesModule} from '../pipes/pipes.module';

@NgModule({
    declarations: [TermsServiceComponent],
    exports: [TermsServiceComponent],
    imports: [
        CommonModule,
        FormsModule,
        BeaconstacModule,
        BeaconstacMobileDrawerModule,
        PipesModule
    ]
})
export class TermsServiceModule {}
