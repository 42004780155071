<div class="bx--row">
    <div class="bx--col px-0">
        <div [@collapse] class="bx--col">
            <div class="bx--row">
                <div class="bx--col-lg">
                    <beaconstac-textfield
                        [disabled]="true"
                        [label]="true"
                        [title]="'User Consent'"
                        [text]="disclaimerText"
                    ></beaconstac-textfield>
                </div>
            </div>

            <div class="bx--row mt-3">
                <div class="bx--col-lg">
                    <div class="w-100">

                        <span class="tos-label" *ngIf="termsOfService.length">
                            User Agreement Link(s)
                        </span>

                        <beaconstac-input-group *ngIf="termsOfService.length" [childComponent]="tosComp">
                        </beaconstac-input-group>

                        <ng-template #tosComp>
                            <div class="d-flex flex-column" *ngFor="let tos of this.termsOfService ; let i = index">

                                <div [ngClass]="i === 0 ? 'mt-2': ''" class="d-flex flex-nowrap mb-2 input-group-border">
                                    <beaconstac-textfield
                                        [ngStyle]="{'flex':'3'}"
                                        [maxWidth]="'100%'"
                                        [isInputGroup]="true"
                                        [class]="'input-group-selector'"
                                        type="text"
                                        [(text)]="tos.label"
                                        [placeholder]="'Label'"
                                        [changeButtonVisible]="false"
                                        (textChange)="inputChange('label',$event,i)"
                                        [disabled]="hasWriteAccess | disableFieldCheckPipeV2: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_USER_AGREEMENT"
                                    ></beaconstac-textfield>

                                    <beaconstac-textfield
                                        [ngStyle]="{'flex':'6'}"
                                        [maxWidth]="'100%'"
                                        [isInputGroup]="true"
                                        [class]="'input-group-selector'"
                                        type="text"
                                        [(text)]="tos.url"
                                        [placeholder]="'Enter a link to your document'"
                                        [changeButtonVisible]="false"
                                        (textChange)="inputChange('url',$event,i)"
                                        [valid]="tos.hasOwnProperty('__valid_url__') ? (tos.__valid_url__ ? TEXT_FIELD_VALIDATIONS.VALID : TEXT_FIELD_VALIDATIONS.ERROR) : TEXT_FIELD_VALIDATIONS.VALID"
                                        [disabled]="hasWriteAccess | disableFieldCheckPipeV2: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_USER_AGREEMENT"
                                    ></beaconstac-textfield>

                                    <beaconstac-button
                                        [class]="'input-group-selector'"
                                        [ngStyle]="{'flex':'1'}"
                                        [isInputGroup]="true"
                                        [style]="BUTTON_STYLES.STROKE"
                                        type="danger"
                                        [disabled]="(this.termsOfService.length === 1) || hasWriteAccess | disableFieldCheckPipeV2: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_USER_AGREEMENT"
                                        label=""
                                        icon="fas fa-trash-alt"
                                        iconPosition="left"
                                        (onClick)="removeField('tos',i)"
                                    ></beaconstac-button>
                                </div>

                                <div class="text-field-error-msg">
                                    <div class="err-text" *ngIf="tos.hasOwnProperty('__valid_url__') ? (!tos.__valid_url__) : false"> Please enter a valid url
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <beaconstac-button *ngIf="!termsOfService || (termsOfService.length < 3)"
                            [style]="BUTTON_STYLES.TEXT"
                            type="primary"
                            [disabled]="hasWriteAccess | disableFieldCheckPipeV2: cardSetting: orgCardSetting: cardType: priortizeOrgSettings: CARD_PROPERTY_TYPES.LEAD_USER_AGREEMENT"
                            label="Add Link"
                            icon="fa fa-plus"
                            [iconSize]="'12px'"
                            iconPosition="left"
                            (onClick)="addNewField()"
                        ></beaconstac-button>
                    </div>
                </div>
            </div>
            <div class="pb-2"></div>
        </div>
    </div>
</div>
